const devHosts = ['development.getfractal.xyz', 'localhost'];
const localHosts = ['localhost'];

export const isLocal = () => {
  return (
    // eslint-disable-next-line no-restricted-globals
    typeof location !== 'undefined' &&
    // eslint-disable-next-line no-restricted-globals
    localHosts.includes(location.hostname)
  );
};
export const isDev = () => {
  return (
    // eslint-disable-next-line no-restricted-globals
    typeof location !== 'undefined' &&
    // eslint-disable-next-line no-restricted-globals
    devHosts.includes(location.hostname)
  );
};

export const isProd = () => {
  return (
    // eslint-disable-next-line no-restricted-globals
    typeof location !== 'undefined' &&
    // eslint-disable-next-line no-restricted-globals
    location.hostname === 'app.getfractal.xyz'
  );
};
