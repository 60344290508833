import axios from 'axios';
import { useCallback } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useCurrentUser } from 'entities/user/model';
import { useAuth0 } from '@auth0/auth0-react';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/invoices/recurrence-rule';

const fetchRecurenceRules = async (getToken, organizationId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/list`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const useRecurringInvoices = () => {
  // const queryClient = useQueryClient();
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: userData } = useCurrentUser();
  const organizationId = userData?.selected_organization?._id;
  const { data: orders, isFetching } = useQuery({
    queryKey: ['recurrent-invoices'],
    queryFn: () => fetchRecurenceRules(getToken, organizationId),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  return {
    items: orders || [],
    isFetching,
  };
};

const fetchRecurrenceRuleDetails = async (getToken, organizationId, ruleId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/${ruleId}`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

const deleteRecurrenceRule = async (getToken, organizationId, ruleId) => {
  const token = await getToken();

  try {
    const response = await axios.delete(`${RESOURCE_URL}/${ruleId}`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const useRecurringInvoiceDetails = (ruleId) => {
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: userData } = useCurrentUser();
  const { invalidateRecurringInvoices } = useInvalidateRecurringInvoices();
  const organizationId = userData?.selected_organization?._id;
  const { data, isLoading } = useQuery({
    queryKey: ['recurrent-invoice-details', ruleId],
    queryFn: () => fetchRecurrenceRuleDetails(getToken, organizationId, ruleId),
    enabled: !!ruleId && !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  const { mutateAsync } = useMutation({
    mutationKey: ['delete-recurrent-invoice', ruleId],
    mutationFn: ({ ruleId }) =>
      deleteRecurrenceRule(getToken, organizationId, ruleId).then(() =>
        invalidateRecurringInvoices()
      ),
  });

  return {
    data,
    isLoading,
    deleteRecurrence: mutateAsync,
  };
};

export const useInvalidateRecurringInvoices = () => {
  const queryClient = useQueryClient();

  const invalidateRecurringInvoices = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['recurrent-invoices'] });
  }, [queryClient]);

  return {
    invalidateRecurringInvoices,
  };
};
