import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'shared/ui/Spinner';
import GeneratePaymentRequestForm from 'widgets/Bills/GeneratePaymentRequestForm';
import { fetchLink } from 'entities/link/model/actions';

const PageCreatePaymentRequest = ({ urlid }) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['url', urlid],
    queryFn: () => fetchLink(urlid),
  });

  if (isError) {
    return (
      <div className="-mt-6 flex h-full flex-col items-center justify-center text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-200 sm:text-5xl">
          This link is no longer valid.
        </h1>
        <p className="mt-6 max-w-md text-base leading-7 text-gray-400">
          Please check that you are using the correct link. If you are having
          trouble accessing the form, please contact the person who sent you the
          link.
        </p>
      </div>
    );
  }

  return isLoading || !data._id ? (
    <div className="-mt-6 flex h-full items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="payment-request-page-container mx-auto mb-40 max-w-4xl px-4 sm:px-6 md:px-8">
      <GeneratePaymentRequestForm url={data} />
    </div>
  );
};

export default PageCreatePaymentRequest;
