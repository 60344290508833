import '@rainbow-me/rainbowkit/styles.css';
import App from 'app';
import React from 'react';
import { createRoot } from 'react-dom/client';
// Safe App Provider
import SafeProvider from '@gnosis.pm/safe-apps-react-sdk';
import { ConfigProvider } from 'antd';
import { theme } from './shared/ui/ant/theme';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SafeProvider>
      <ConfigProvider theme={theme}>
        <App />
      </ConfigProvider>
    </SafeProvider>
  </React.StrictMode>
);
