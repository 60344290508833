import { useState } from 'react';
import { useCurrentUser } from 'entities/user/model';
import { useUserActions } from 'entities/user/model';

const UserChangePasswordComponent = () => {
  const { data: user } = useCurrentUser();
  const { changeUserPasswordAction } = useUserActions();

  const [userPasswordUpdatedSuccessfully, setUserPasswordUpdatedSuccessfully] =
    useState(false);

  const handlePasswordReset = async () => {
    try {
      const response = await changeUserPasswordAction({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user.email,
        connection: 'Username-Password-Authentication',
      });

      if (response.status === 200) {
        setUserPasswordUpdatedSuccessfully(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="">
      <div className="mt-10 sm:mt-0">
        <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-white">
                Change password
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                Update your password associated with your account.
              </p>
            </div>

            <div className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-6 sm:col-span-6">
                  <h2 className="block text-sm font-medium text-[#CBD5E1]">
                    Request secure password reset link
                  </h2>
                  {userPasswordUpdatedSuccessfully ? (
                    <p className="text-sm italic text-green-500">
                      We have sent you a password reset link to your email. You
                      should receive it within 10 minutes.
                    </p>
                  ) : (
                    <button
                      onClick={handlePasswordReset}
                      className="mt-2 rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800">
                      Get Link
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserChangePasswordComponent;
