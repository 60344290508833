import { isValidIban } from "shared/lib/string";

export const validateForm = (values, isAuthenticated) => {
  const errors = {};
  if (!values.yourname) {
    errors.yourname = "Your name is required";
  }
  if (!values.youremail) {
    errors.youremail = "Your email is required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.youremail)
  ) {
    errors.youremail = "Invalid email address";
  }
  if (!values.paymentreference) {
    errors.paymentreference = "Payment reference is required";
  }
  if (!values.payment_request_date) {
    errors.payment_request_date = "Payment request date is required";
  }
  if (!values.payment_due_date) {
    errors.payment_due_date = "Payment due date is required";
  }
  if (!values.services) {
    errors.services = "Services is required";
  } else if (values.services.length < 5 || values.services.length > 65) {
    errors.services = "Services should be between 5 and 65 characters";
  }
  if (!values.price) {
    errors.price = "Price is required";
  }
  if (!values.total) {
    errors.total = "Total is required";
  }
  if (!values.crypto_payment && !values.fiat_payment) {
    errors.crypto_payment = "Payment method is required";
  }
  if (values.fiat_payment && !values.bank_account_name_beneficiary) {
    errors.bank_account_name_beneficiary =
      "Account beneficiary name is required";
  }
  if (!values.wallet && values.crypto_payment) {
    errors.wallet = "Wallet address is required";
  }

  if (isAuthenticated && values.paymentDetails === "no-value") {
    errors.paymentDetails = "Please choose a payment method";
  }

  if (values.wallet && values.crypto_payment) {
    const ethereumRegex = /^(0x)?[0-9a-fA-F]{40}$/;

    if (!ethereumRegex.test(values.wallet) && !values.wallet.endsWith(".eth")) {
      errors.wallet = "Invalid Ethereum address";
    }
  }

  // Fiat bank details validation

  if (values.fiat_payment) {
    if (!values.bank_account_name_beneficiary) {
      errors.bank_account_name_beneficiary =
        "Bank account name beneficiary is required";
    }

    if (!values.bank_name) {
      errors.bank_name = "Bank name is required";
    }

    if (!values.youraddress) {
      errors.youraddress = "Address is required";
    }
    if (!values.yourcity) {
      errors.yourcity = "City is required";
    }
    if (!values.yourcountry) {
      errors.yourcountry = "Country is required";
    }
    if (!values.yourpostcode) {
      errors.yourpostcode = "Zip code is required";
    }

    if (values.isACHTransfer) {
      if (!values.account_number) {
        errors.account_number = "Account number is required";
      } else {
        const accountNumberRegex = /^\d{6,25}$/; // or /^[0-9]{22}$/ // TODO: check if this is correct -> I put less restrctive one for now
        if (!accountNumberRegex.test(values.account_number)) {
          errors.account_number =
            "Valid account number is required. It must be between 6 and 25 digits. Example: 1234567890";
        }
      }

      if (!values.ach_routing_number) {
        errors.ach_routing_number = "Routing number is required";
      } else {
        const routingNumberRegex = /^\d{9}$/;
        if (!routingNumberRegex.test(values.ach_routing_number)) {
          errors.ach_routing_number =
            "Valid routing number is required. It must be 9 digits. Example: 110000000";
        }
      }
    } else {
      if (!values.iban) {
        errors.iban = "IBAN is required";
      } else {
        if (!isValidIban(values.iban)) {
          errors.iban =
            "Valid IBAN is required. Example: GB33BUKB20201555555555";
        }
      }

      if (!values.bic) {
        errors.bic = "SWIFT/BIC is required";
      } else {
        const swiftBicRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        if (!swiftBicRegex.test(values.bic)) {
          errors.bic = "Valid SWIFT/BIC is required. Example: DEUTDEFFXXX";
        }
      }
    }
  }

  // Fiat bank details validation - end

  if (values.file && values.file.type !== "application/pdf") {
    errors.file = "File must be a PDF";
  }
  if (values.network === "Polygon" && values.token === "ANT") {
    errors.token = "ANT is not supported on Polygon";
    errors.network = "Please change network to Ethereum";
  }

  if (values.network === "Polygon" && values.token === "ETH") {
    errors.token = "ETH is not supported on Polygon";
    errors.network = "Please change network to Ethereum";
  }

  return errors;
};
