import { LinkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export default function EmptyLinksComponent() {
  return (
    <>
      <div className="mt-20 text-center">
        <LinkIcon
          className="mx-auto h-12 w-12 text-gray-400"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
        />
        <h3 className="mt-2 text-sm font-semibold text-gray-400">
          No Pay Links
        </h3>
        <p className="mx-auto mt-1 max-w-md text-center text-sm text-gray-500">
          Pay Links allow your vendors and employees to <br /> seamlessly send
          payment requests and invoices.
        </p>
        <div className="mt-6 flex justify-center gap-4">
          <Link to="/dashboard/bills/links/create-link">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <LinkIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Create Pay Link
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
