import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Actions = ({ children }) => (
  <div className="ml-3 flex items-center justify-end">{children}</div>
);

const Title = ({ children }) => (
  <Dialog.Title
    as="div"
    className="text-lg font-semibold leading-6 text-slate-200">
    {children}
  </Dialog.Title>
);

const Header = ({ children }) => (
  <div className="bg-slate-800 px-4 py-6 sm:px-6">
    <div className="grid grid-cols-2 items-center">{children}</div>
  </div>
);

function SlideOver({ show, onClose, children }) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden bg-slate-900 bg-opacity-75 transition-opacity">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute left-0 top-0 -ml-12 flex pt-2">
                      <button
                        type="button"
                        className=" mr-1 flex h-10 w-10 items-center justify-center rounded-full bg-slate-900 outline-none ring-2 ring-inset ring-white"
                        onClick={onClose}>
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-slate-800 pb-6 shadow-xl">
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SlideOver.Header = Header;
SlideOver.Title = Title;
SlideOver.Actions = Actions;

export default SlideOver;
