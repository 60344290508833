import { ShieldCheckIcon, WalletIcon } from '@heroicons/react/24/outline';
import AboutPage from 'pages/AboutPage';
import CreatePaymentRequestPage from 'pages/bill/CreatePaymentRequestPage';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Header } from 'shared/ui/Header';
import logoPurple2 from 'shared/images/logoPurpleBlackBackground2.png';
import { UpdatesBanner } from '../../shared/ui/layouts/MainLayout';

export default function DashboardPublicGeneratePaymentRequest() {
  const location = useLocation();
  const navigation_links = [
    {
      name: 'Payment Requests',
      href: location.pathname,
      icon: WalletIcon,
      current: true,
    },
    {
      name: 'About Fractal',
      href: '/links/about',
      icon: ShieldCheckIcon,
      current: false,
    },
  ];
  const userNavigation = [{ name: 'Sign out', href: '#' }];
  const [, setNavigation] = useState(navigation_links);

  // get pathname after second "/"
  const companyname = location.pathname.split('/')[2];
  const urlid = location.pathname.split('/')[3];
  const path = '/' + companyname + '/' + urlid;

  useEffect(() => {
    const currentLocation = window.location.pathname + window.location.search;
    setNavigation((prevNav) =>
      prevNav.map((item) => {
        if (item.href === currentLocation) {
          return {
            ...item,
            current: true,
          };
        }
        return {
          ...item,
          current: false,
        };
      })
    );
  }, [location]);

  return (
    <div className="h-full overflow-hidden bg-gray-900">
      <UpdatesBanner className={'lg:pl-0'} />
      <div className="sticky pl-8 items-center flex top-0 z-10  h-16 flex-shrink-0 border-b border-[#1b2431] shadow-2xl">
        <a href="https://getfractal.xyz/">
          <img className="w-[132px]" src={logoPurple2} alt="Fractal" />
        </a>

        <Header navigation={userNavigation} />
      </div>

      <main className="h-[calc(100%-theme(space.16))] flex-1 overflow-auto py-6">
        <Routes>
          <Route
            path={path}
            element={<CreatePaymentRequestPage urlid={urlid} />}
          />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </main>
    </div>
  );
}
