import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from '@solana/web3.js';
import { TOKEN_TYPES } from '../bill/lib';
import {
  createTransferInstruction,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { useMutation } from '@tanstack/react-query';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { splTokensConfig } from './config';
import BigNumber from 'bignumber.js';

/** @param {CryptoPayoutDetails} payout */
export const useTransferPayout = (payout) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const mutation = useMutation({
    mutationKey: ['solana-transfer-payout'],
    mutationFn: async () => {
      const receiverPublicKey = new PublicKey(payout.walletAddress);
      const transaction = await _buildTransaction({
        publicKey,
        payout,
        receiverPublicKey,
      });
      const signature = await sendTransaction(transaction, connection, {
        skipPreflight: false,
        preflightCommitment: 'confirmed',
      });
      await connection.confirmTransaction({
        signature,
        commitment: 'processed',
      });

      return { signature };
    },
  });

  return {
    transferPayout: mutation.mutateAsync,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
    data: mutation.data,
  };
};

/** @param {{ payout: CryptoPayoutDetails }} params */
const _buildTransaction = async ({ payout, publicKey, receiverPublicKey }) => {
  let transaction = new Transaction();

  if (payout.currency === TOKEN_TYPES.SOL) {
    const lamports = LAMPORTS_PER_SOL * payout.amountNumber;
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: receiverPublicKey,
        lamports,
      })
    );
  } else {
    const tokenConfig = splTokensConfig[payout.currency];

    if (!tokenConfig) {
      throw new Error('No tokenConfig');
    }

    const mintAddress = new PublicKey(tokenConfig.splAddress);
    const sourceTokenAddress = await getAssociatedTokenAddress(
      mintAddress,
      publicKey
    );
    const destinationTokenAddress = await getAssociatedTokenAddress(
      mintAddress,
      receiverPublicKey
    );
    const amountInSmallestUnit = new BigNumber(10)
      .exponentiatedBy(tokenConfig.decimals)
      .multipliedBy(payout.amount)
      .toNumber();

    transaction.add(
      createTransferInstruction(
        sourceTokenAddress,
        destinationTokenAddress,
        publicKey,
        amountInSmallestUnit,
        [],
        TOKEN_PROGRAM_ID
      )
    );

    return transaction;
  }
};
