import React from 'react';
import PayETHButton from 'widgets/Payments/PayETHButton';
import PayERC20Button from 'widgets/Payments/PayERC20Button';
import erc20TokensData from '../../shared/constants/erc20TokensData';
import {
  getTokenHumanReadableNameByChain,
  upperCaseNetworkType,
} from '../../features/bill/lib';

const PayBillButton = ({
  bill,
  disabled,
  payButtonStyles,
  onClick,
  onUserApproval,
  handleSuccess,
  setTransactionSuccessful,
  setTransactionHash,
  setTransactionInitiated,
}) => {
  const tokenAddress =
    erc20TokensData[bill?.token]?.erc20Address?.[
      upperCaseNetworkType(bill?.network)
    ];

  return (
    <div className="w-full text-left" onClick={onClick}>
      {bill?.token === 'ETH' ? (
        <PayETHButton
          addressTo={bill?.walletAddress}
          ethAmount={bill?.amount || '0'}
          handleSuccess={handleSuccess}
          multisigEnabled={true}
          network={bill?.network}
          disabled={disabled}
          successMessage={{
            cta: 'View All Bills',
            link: '/dashboard/bills/allbills',
          }}
          payButtonStyles={payButtonStyles}
          onUserApproval={onUserApproval}
          setTransactionSuccessful={setTransactionSuccessful}
          setTransactionHash={setTransactionHash}
        />
      ) : (
        <PayERC20Button
          addressTo={bill?.walletAddress}
          amountERC20={bill?.amount || '0'}
          tokenName={getTokenHumanReadableNameByChain(
            bill?.token,
            bill?.network
          )}
          tokenAddress={tokenAddress}
          handleSuccess={handleSuccess}
          multisigEnabled={true}
          network={bill?.network}
          disabled={disabled}
          successMessage={{
            cta: 'View All Bills',
            link: '/dashboard/bills/allbills',
          }}
          payButtonStyles={payButtonStyles}
          onUserApproval={onUserApproval}
          setTransactionSuccessful={setTransactionSuccessful}
          setTransactionHash={setTransactionHash}
          setTransactionInitiated={setTransactionInitiated}
        />
      )}
    </div>
  );
};

export default PayBillButton;
