import { PaperClipIcon } from '@heroicons/react/20/solid';
import useToggle from 'shared/hooks/useToggle';
import {
  InvoicePaymentModal,
  MarkInvoiceAsPaidModal,
} from 'widgets/Payments/InvoicePaymentModal';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import {
  getTokenHumanReadableNameByChain,
  NetworkLogoMap,
  TokenLogoMap,
  upperCaseNetworkType,
} from 'features/bill/lib';
import { useLocation } from 'react-router-dom';
import { useInvoiceViaPaymentAuthCode } from 'features/invoice/useInvoiceViaPaymentAuthCode';
import { ContentLoadingState } from 'shared/ui/layouts/PageContent';
import { formatDollarAmount } from '../../shared/lib/string';
import { Button } from '../../shared/ui/Button/Button';
import { Badge } from 'flowbite-react';

export default function InvoicePay() {
  const location = useLocation();
  const [paymentModalOpened, togglePaymentModal] = useToggle(false);
  const [markAsPaidModalOpened, toggleMarkAsPaidModalOpened] = useToggle(false);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const pac = searchParams.get('pac');
  const { invoiceData, isLoading, isError } = useInvoiceViaPaymentAuthCode(
    id,
    pac
  );
  const { isConnected } = useAccount();

  if (isLoading) {
    return <ContentLoadingState />;
  }

  if (isError) {
    return (
      <div className="text-lg text-white">
        We could not find the invoice you were looking for.
        <br />
        Please revisit the link that was sent to you.
      </div>
    );
  }

  // if (invoiceData.paymentStatus === 'paid') {
  //   return (
  //     <div className="text-lg text-white">
  //       This invoice <span className="text-emerald-500"> has been paid</span>,
  //       and for security reasons, access is now restricted. <br /> To obtain a
  //       copy of the invoice, please log into your Fractal Payments account or
  //       reach out to the issuer directly <br />
  //       <span className="text-slate-400">{invoiceData.issuerEmail}</span>
  //     </div>
  //   );
  // }

  const renderPaymentButton = () => {
    return isConnected ? (
      <button
        className="min-w-[150px] rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        disabled={!isConnected}
        onClick={togglePaymentModal}>
        Pay
      </button>
    ) : (
      <ConnectButton />
    );
  };

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-white">
          Make Payment
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-400">
          Please connect your wallet to make a payment. The receiver will be
          notified once the payment is made.
        </p>
      </div>
      <div className="mt-6 border-t border-white/10">
        <dl className="divide-y divide-white/10">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              Payment Request From
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {invoiceData.issuerName}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              Issuer Email Address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {invoiceData.issuerEmail}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              Services Provided
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {invoiceData.lineItems[0].description}
            </dd>
          </div>

          {!!invoiceData.note && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">
                Invoice Memo
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
                {invoiceData.note}
              </dd>
            </div>
          )}

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              {invoiceData.paymentNetwork === 'fiat'
                ? 'Bank Details'
                : 'Wallet Address'}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {invoiceData.paymentNetwork === 'fiat'
                ? 'Please check the invoice for payment details'
                : invoiceData.paymentDetails.address}
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              {invoiceData.paymentNetwork === 'fiat'
                ? 'Payment Method'
                : 'Network'}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              <div className="flex items-center">
                {invoiceData.paymentNetwork !== 'fiat' && (
                  <div className="mr-1 h-4 w-4">
                    <img
                      src={
                        NetworkLogoMap[
                          upperCaseNetworkType(invoiceData.paymentNetwork)
                        ]
                      }
                      alt=""
                      aria-hidden="true"
                    />
                  </div>
                )}
                {invoiceData.paymentNetwork === 'fiat'
                  ? 'Bank Transfer'
                  : upperCaseNetworkType(invoiceData.paymentNetwork)}
              </div>
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Amount</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              <div className="flex items-center">
                {invoiceData.paymentNetwork !== 'fiat' && (
                  <div className="mr-1 w-4">
                    <img
                      src={TokenLogoMap[invoiceData.currency]}
                      alt=""
                      aria-hidden="true"
                    />
                  </div>
                )}
                {formatDollarAmount(invoiceData.totalAmount)}{' '}
                {getTokenHumanReadableNameByChain(
                  invoiceData.currency,
                  invoiceData.paymentNetwork
                )}
              </div>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              Attachments
            </dt>
            <dd className="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                className="divide-y divide-white/10 rounded-md border border-white/20">
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">invoice.pdf</span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-400 hover:text-indigo-300"
                      onClick={() => {
                        const buffer = new Uint8Array(
                          invoiceData.invoicePdfContent.data
                        );
                        const blob = new Blob([buffer], {
                          type: 'application/pdf',
                        });
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                      }}>
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              Make Payment Now
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {invoiceData.paymentStatus === 'paid' ? (
                <Badge className="inline !bg-slate-700 !text-emerald-400">
                  Paid
                </Badge>
              ) : (
                <div className="flex items-center">
                  {renderPaymentButton()} <span className="ml-3 mr-3">or</span>{' '}
                  <Button onClick={toggleMarkAsPaidModalOpened} color={'dark'}>
                    Mark as paid
                  </Button>
                </div>
              )}
            </dd>
          </div>
        </dl>
      </div>
      <InvoicePaymentModal
        onClose={togglePaymentModal}
        show={paymentModalOpened}
        invoice={invoiceData}
        invoiceId={id}
        pac={pac}
      />

      <MarkInvoiceAsPaidModal
        onClose={toggleMarkAsPaidModalOpened}
        show={markAsPaidModalOpened}
        invoice={invoiceData}
        invoiceId={id}
        pac={pac}
      />
    </div>
  );
}
