import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useCurrentUser } from "entities/user/model";

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + "/urls";

async function createLink(token, data, values) {
  try {
    const response = await axios.post(
      `${RESOURCE_URL}/create-url`,
      { data, values },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

// NOTE: This function makes a call to public API. No Auth Token required.
export async function fetchLink(urlId) {
  try {
    const { data } = await axios.get(`${RESOURCE_URL}/get-url/${urlId}`);
    return data;
  } catch (error) {
    throw error;
  }
}

async function fetchAllLinks(token, userId, organization = null) {
  const params = { id: userId };

  if (organization) {
    params.organization = organization;
  }

  const { data } = await axios.get(
    `${RESOURCE_URL}/get-urls`,
    { params },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
}

async function deleteLink(token, urlId) {
  try {
    const response = await axios.delete(
      `${RESOURCE_URL}/delete-url?id=${urlId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function updatePaymentLink(token, urlId, data, values) {
  try {
    const response = await axios.put(
      `${RESOURCE_URL}/update-url/${urlId}`,
      { data, values },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

async function grantAccessToPaymentLink(password, urlid) {
  try {
    const response = await axios.post(`${RESOURCE_URL}/check-password`, {
      password,
      urlid,
    });

    return response.status === 200 ? response.data : false;
  } catch (error) {
    throw error;
  }
}

export const useLinkActions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data } = useCurrentUser();

  const createAction = useCallback(
    async (values) => {
      const token = await getAccessTokenSilently();
      return createLink(token, data, values);
    },
    [data, getAccessTokenSilently]
  );

  const fetchAction = useCallback(
    async (urlId) => {
      return fetchLink(urlId);
    },
    [data, getAccessTokenSilently]
  );

  const fetchAllAction = useCallback(async () => {
    if (!data.nickname) {
      return null;
    }
    const token = await getAccessTokenSilently();
    return fetchAllLinks(token, data.user_id, data?.selected_organization);
  }, [data]);

  const updateAction = useCallback(
    async (urlId, values) => {
      const token = await getAccessTokenSilently();
      return updatePaymentLink(token, urlId, data, values);
    },
    [data, getAccessTokenSilently]
  );

  const deleteAction = useCallback(
    async (urlId) => {
      const token = await getAccessTokenSilently();
      return deleteLink(token, urlId);
    },

    [data]
  );

  const grantAccessToPaymentLinkAction = useCallback(
    async (password, urlid) => {
      return grantAccessToPaymentLink(password, urlid);
    },
    []
  );

  return {
    createAction,
    fetchAction,
    fetchAllAction,
    updateAction,
    deleteAction,
    grantAccessToPaymentLinkAction,
  };
};
