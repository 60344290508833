import {
  useNotDiscardedBillsNotifications,
  useNotDiscardedVendorsNotifications,
  useNotDiscardedExpensesNotifications,
} from './useUserNotifications';

export const MenuItemBadge = () => {
  const { getNotifications } = useNotDiscardedBillsNotifications();
  const { getNotifications: getVendorNotifications } =
    useNotDiscardedVendorsNotifications();
  const { getNotifications: getExpenseNotifications } =
    useNotDiscardedExpensesNotifications();
  const hasNotifications = !!getNotifications().length;
  const hasVendorNotifications = !!getVendorNotifications().length;
  const hasExpenseNotifications = !!getExpenseNotifications().length;

  if (
    !hasNotifications &&
    !hasVendorNotifications &&
    !hasExpenseNotifications
  ) {
    return null;
  }

  return (
    <span className="bg-amber-300 h-1.5 w-1.5 ml-1 mt-[2px] rounded-full" />
  );
};

export const SubMenuItemBadge = ({ notifications }) => {
  if (!notifications?.length) {
    return null;
  }

  return (
    <span className="ml-2 flex items-center justify-center h-5 w-5 text-amber-300 bg-[#293648] text-xs font-medium rounded-full">
      {notifications.length}
    </span>
  );
};

export const BillsSubMenuItemBadge = () => {
  const { getNotifications } = useNotDiscardedBillsNotifications();
  return <SubMenuItemBadge notifications={getNotifications()} />;
};

export const VendorsSubMenuItemBadge = () => {
  const { getNotifications } = useNotDiscardedVendorsNotifications();
  return <SubMenuItemBadge notifications={getNotifications()} />;
};

export const ExepesesSubMenuItemBadge = () => {
  const { getNotifications } = useNotDiscardedExpensesNotifications();
  return <SubMenuItemBadge notifications={getNotifications()} />;
};
