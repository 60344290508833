import { useRef, useState, useEffect } from 'react';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser, useCurrentUserDetails } from 'entities/user/model';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';
import axios from 'axios';
import dayjs from 'dayjs';
import { ModalStyles } from './PaymentModal.ModalStyles';
import { ConfirmInWalletOverlay } from './PaymentModal.ConfirmInWalletOverlay';
import { ConfirmationSection } from './PaymentModal.ConfirmationSection';
import { useSignMessage, useAccount } from 'wagmi';
import { FiatPaymentInformation } from './CryptoToFiatPaymentModal.PaymentInformation';
import { SuccessConfirmation } from './CryptoToFiatPaymentModal.SuccessConfirmation';
import { useBillMutations, useBill } from 'entities/bill/model';
import { useSafeWallet } from 'shared/hooks/safeWallet';
import { Spinner } from 'shared/ui/Spinner';
import { sendTransactionLog } from 'features/analytics/sendTransactionLog';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Button } from 'shared/ui/Button/Button';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/monerium/authenticate-user';

const authenticateUser = async (getToken, payload) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        organizationId: payload.organizationId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getUserBalances = async (getToken, payload) => {
  const token = await getToken();

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/monerium/balances`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          organizationId: payload.organizationId,
        },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const useMonerium = () => {
  const queryClient = useQueryClient();
  const { data: userData } = useCurrentUser();
  const { hasMoneriumIntegration } = useCurrentUserDetails();
  const organizationId = userData?.selected_organization?._id;
  const { getAccessTokenSilently: getToken } = useAuth0();

  const [, setCodeCookie] = useCookies(['codeVerifier']);

  const authenticateMutation = useMutation({
    mutationKey: ['authenticate-user-monerium'],
    mutationFn: () =>
      authenticateUser(getToken, {
        organizationId,
      }).then((data) => {
        if (!hasMoneriumIntegration()) {
          queryClient.invalidateQueries({ queryKey: ['users'] });
        }

        return data;
      }),
  });

  const balancesQuery = useQuery({
    queryKey: ['monerium-balances'],
    queryFn: () =>
      getUserBalances(getToken, {
        organizationId,
      }),
    staleTime: 1000 * 60,
    enabled: hasMoneriumIntegration() && !!organizationId,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (authenticateMutation.data?.codeVerifier) {
      setCodeCookie('codeVerifier', authenticateMutation.data?.codeVerifier, {
        path: '/',
      });
    }
  }, [organizationId, setCodeCookie, authenticateMutation.data]);

  return {
    authenticateUser: authenticateMutation.mutateAsync,
    isAuthenticatingUser: authenticateMutation.isLoading,
    authFlowUrl: authenticateMutation.data?.authFlowUrl,
    isAuthenticated: authenticateMutation.data?.isAuthenticated,
    balances: balancesQuery.data || [],
    isFetchingBalances: balancesQuery.isFetching,
  };
};

const useBillStatus = (billId) => {
  const { getAccessTokenSilently } = useAuth0();
  const res = useMutation({
    mutationKey: ['monerium-update-bill-payment-status', billId],
    mutationFn: async ({
      multisigUsed,
      signatureMessage,
      safeTxSignature,
      safeContractAddress,
      safeContractChainName,
      transactionSignature,
    }) => {
      const token = await getAccessTokenSilently();
      const res = await axios.put(
        process.env.REACT_APP_SERVER_URL + '/bills/update-bill/' + billId,
        {
          multisigUsed,
          signatureMessage,
          transactionSignature,
          safeTxSignature,
          safeContractAddress,
          safeContractChainName,
          paymentStatus: 'processingPayment',
          paymentDate: new Date().toISOString().slice(0, 10),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    },
  });

  return res;
};

const buildSignMessage = (bill) => {
  return `Send ${bill.token} ${bill.amount} to ${
    bill.invoice.paymentDetails?.bankAccount?.accountIBAN
  } at ${dayjs().add(1, 'hour').toISOString()}`;
};

const useMoneriumConnectedWalletBalance = (
  balances,
  payeeToken,
  payeeAmount
) => {
  const { chain, address } = useAccount();
  const balance = balances.find(({ address: monAddress, chain: monChain }) => {
    return address === monAddress && chain.name.toLowerCase() === monChain;
  });

  const res = balance?.balances.find(
    (b) => b.currency === payeeToken.toLowerCase()
  );

  return {
    balance: res,
    isLowBalance: res && Number(res.amount) < Number(payeeAmount),
  };
};

export const MoneriumPaymentModal = ({ show, onClose, bill }) => {
  const { data: enrichedBill, isLoading: isEnrichedBillLoading } = useBill(
    bill._id
  );
  const { getAccessTokenSilently } = useAuth0();
  const { address, connector, isConnected } = useAccount();
  const { chain } = useAccount();
  const { data, isSuccess, signMessage, isError } = useSignMessage();
  const cancelButtonRef = useRef(null);
  const message = useRef(null);
  const {
    authenticateUser,
    authFlowUrl,
    isAuthenticatingUser,
    balances,
    isFetchingBalances,
  } = useMonerium();
  const { invalidate } = useBillMutations();
  const { isConnectedWalletSafe } = useSafeWallet();
  const { balance: moneriumBalance, isLowBalance } =
    useMoneriumConnectedWalletBalance(balances, bill.token, bill.amount);

  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [waitingForUserApproval, setWaitingForUserApproval] = useState(false);

  const updateBillStatus = useBillStatus(bill._id);
  const recipientBankAccount =
    enrichedBill?.invoice?.paymentDetails?.bankAccount;
  const token = bill.token;

  const _handleConfirm = (val) => {
    setIsConfirmedByUser(val);
  };

  const _handlePay = () => {
    authenticateUser().then((data) => {
      if (data.isAuthenticated) {
        const msg = buildSignMessage(enrichedBill);
        message.current = msg;
        setWaitingForUserApproval(true);
        sendTransactionLog(getAccessTokenSilently, {
          billId: bill._id,
          moneriumBalance,
          msg,
          multisigUsed: isConnectedWalletSafe(),
          address,
          connector: {
            id: connector.id,
            name: connector.name,
          },
        });
        signMessage({
          message: msg,
        });
      }
    });
  };

  const _handleClose = () => {
    if (!updateBillStatus.isPending && !waitingForUserApproval) {
      onClose();
    }
  };

  // Renderers

  const _renderBalanceWarning = () => {
    if (!isLowBalance) {
      return null;
    }

    return (
      <>
        <div className="my-4 border-b border-slate-600" />

        <div className="text-center ">
          <div
            as="h3"
            className="mb-5 text-base font-semibold leading-6 text-slate-400">
            Warnings
          </div>

          {isLowBalance && (
            <div className="grid grid-cols-2 items-center pt-0 text-left text-sm font-medium">
              <span className="text-red-400">
                {' '}
                <span className="font-semibold text-red-400">
                  <ExclamationTriangleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
                </span>
                Not Enough Tokens
              </span>

              <span
                className={`col-span-2 text-xs font-normal text-slate-400 `}>
                You do not have enough tokens to pay this bill. Your balance is{' '}
                {moneriumBalance.amount}{' '}
                {moneriumBalance.currency.toUpperCase()}.
              </span>
            </div>
          )}
        </div>
      </>
    );
  };

  const _renderPayButton = () => {
    if (authFlowUrl) {
      return (
        <a
          href={authFlowUrl}
          className={classnames(
            'inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          )}>
          Authenticate Monerium
        </a>
      );
    }

    return (
      <button
        onClick={_handlePay}
        disabled={!isConfirmedByUser || isAuthenticatingUser || isLowBalance}
        className={classnames(
          'inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
          {
            'cursor-not-allowed opacity-50':
              !isConfirmedByUser || isAuthenticatingUser || isLowBalance,
          }
        )}>
        {isAuthenticatingUser ? 'Loading' : 'Pay'}
      </button>
    );
  };

  useEffect(() => {
    if (
      isSuccess &&
      !updateBillStatus.isPending &&
      !updateBillStatus.isSuccess
    ) {
      setWaitingForUserApproval(false);
      updateBillStatus
        .mutateAsync({
          multisigUsed: isConnectedWalletSafe(),
          safeContractAddress: isConnectedWalletSafe() ? address : undefined,
          safeContractChainName:
            isConnectedWalletSafe() && chain?.name ? chain?.name : undefined,
          safeTxSignature: isConnectedWalletSafe() ? data : undefined,
          transactionSignature: isConnectedWalletSafe() ? undefined : data,
          signatureMessage: message.current,
        })
        .then(() => {
          invalidate();
          setShowSuccessMessage(true);
        });
    }
  }, [
    isSuccess,
    data,
    address,
    chain,
    updateBillStatus,
    invalidate,
    isConnectedWalletSafe,
  ]);

  useEffect(() => {
    if (isError) {
      setWaitingForUserApproval(false);
    }
  }, [isError]);

  return (
    <ModalStyles
      show={show}
      cancelButtonRef={cancelButtonRef}
      onClose={_handleClose}>
      <ConfirmInWalletOverlay
        show={waitingForUserApproval || updateBillStatus.isPending}
      />
      <SuccessConfirmation onClose={_handleClose} show={showSuccessMessage} />

      {isFetchingBalances || isEnrichedBillLoading ? (
        <>
          <div className="flex items-center justify-center px-6 py-4">
            <Spinner />
          </div>
        </>
      ) : (
        <>
          <FiatPaymentInformation
            title="Payment details"
            beneficiaryName={recipientBankAccount?.accountBeneficiary}
            bankSwiftCode={recipientBankAccount?.bankBIC}
            bankIban={recipientBankAccount?.accountIBAN}
            moneriumBalance={moneriumBalance}
          />

          <div className=" text-center ">
            <div className="flex justify-between">
              <div className="text-sm text-slate-300">Amount to pay:</div>
              <div className="text-sm text-slate-300">
                {bill.amount} <span className="text-slate-300">{token}</span>
              </div>
            </div>
          </div>

          {_renderBalanceWarning()}

          <ConfirmationSection
            isConfirmed={isConfirmedByUser}
            setIsConfirmed={() => {}}
            onConfirmationChange={_handleConfirm}
          />

          {isConnected ? (
            <>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <div className="w-full text-left">{_renderPayButton()}</div>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:order-first sm:mt-0"
                  onClick={() => {
                    _handleClose();
                  }}>
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <div className="mt-5">
              <ConnectButton.Custom>
                {({ openConnectModal }) => (
                  <Button
                    onClick={openConnectModal}
                    fullSized
                    cl
                    color="indigo">
                    Connect Your Wallet
                  </Button>
                )}
              </ConnectButton.Custom>
            </div>
          )}
        </>
      )}
    </ModalStyles>
  );
};
