import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCurrentUserDetails } from '../../entities/user/model';
import { useCallback } from 'react';

export const useTransfers = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const query = useQuery({
    queryKey: ['org-transfers'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/public/asset-transfers/list`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            organizationId: getCurrentOrganizationId(),
          },
        }
      );
      return res.data?.result;
    },
    enabled: hasAnyOrganization,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });

  const _invalidate = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['org-transfers'] });
  }, [queryClient]);

  return {
    transfers: query.data || [],
    isPending: query.isPending,
    isFetching: query.isFetching,

    invalidate: _invalidate,
  };
};

export const useDeleteTransfer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const deleteMutation = useMutation({
    mutationKey: ['delete-transfer'],
    mutationFn: async (transferId) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/api/public/asset-transfers/${transferId}`,
          {
            params: {
              organizationId: getCurrentOrganizationId(),
            },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  });

  return {
    deleteTransfer: deleteMutation.mutateAsync,
  };
};
