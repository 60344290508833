import { AbstractPaymentProvidersEvaluatorStrategy } from './AbstractPaymentProvidersEvaluatorStrategy';
import { PayoutTypes } from './PayoutDetails';
import { createPaymentProvider } from './PaymentProvider';

export class BankTransferPaymentProvidersEvaluatorStrategy extends AbstractPaymentProvidersEvaluatorStrategy {
  #bankPayouts;

  constructor(
    paymentProvidersConfig,
    bankPayouts,
    platformIntegrations,
    organizationSettings
  ) {
    super(paymentProvidersConfig, platformIntegrations, organizationSettings);
    this.#bankPayouts = bankPayouts;
  }

  isBatchPayout() {
    return this.#bankPayouts.length > 1;
  }

  getAllPossiblePaymentProviders() {
    if (this.isPayoutEligible()) {
      const configuredProviders = this.paymentProvidersConfig.get(
        'providers',
        []
      );

      return configuredProviders
        .filter((providerConfig) => {
          const isSuitableForPayouts =
            providerConfig.enabled &&
            providerConfig.suitableForPayouts?.includes(
              PayoutTypes.BankTransfer
            ) &&
            this.#bankPayouts.every((p) =>
              providerConfig.suitableForCurrencies?.includes(p.currency)
            );

          if (this.isBatchPayout()) {
            return isSuitableForPayouts && providerConfig.batchAvailable;
          }

          return isSuitableForPayouts;
        })
        .map((providerConfig) => createPaymentProvider(providerConfig));
    }

    return [];
  }

  getPayouts() {
    return this.#bankPayouts;
  }

  isPayoutEligible() {
    if (
      this.#bankPayouts.length &&
      this.#bankPayouts.every(
        (payout) => payout.currency === this.#bankPayouts[0].currency
      )
    ) {
      return true;
    }

    return false;
  }
}
