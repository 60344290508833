import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCurrentUserDetails } from '../../entities/user/model';
import { features, ROLES, useFeatureFlags } from '../../shared/hooks';

export const useCustomers = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { getCurrentOrganizationId, getCurrentOrgRole } =
    useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const query = useQuery({
    queryKey: ['quickbooks-customers'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await axios.get<string, any>(
        `${process.env.REACT_APP_SERVER_URL}/api/public/quick-books-online/customer/list`,
        {
          params: { organizationId: getCurrentOrganizationId() },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return resp.data?.result;
    },
    enabled:
      !!getCurrentOrganizationId() &&
      [ROLES.ADMIN, ROLES.ACCOUNTANT].includes(getCurrentOrgRole()) &&
      isFeatureEnabled(features.QUICK_BOOKS_ONLINE),
  });

  return {
    customers: query.data || [],
  };
};
