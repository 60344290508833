import { useCurrentUser } from 'entities/user/model';
import { Alert } from 'shared/ui/Alert/Alert';
import { WithOrgRole } from 'shared/ui/WithOrgPermissions';
import CompanyCreateForm from 'widgets/Organization/CompanyCreateForm';
import CompanyMultipleOrganizations from 'widgets/Organization/CompanyMultipleOrganizations';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

const CompanyDetailsPage = () => {
  const { data } = useCurrentUser();

  return (
    <div className="py-8">
      <WithOrgRole
        admin={
          <div className="mb-8">
            <CompanyCreateForm />
          </div>
        }
        member={null}
        accountant={null}
      />

      {data?.organizations?.length > 1 ? (
        <>
          <Alert
            icon={InformationCircleIcon}
            color={'slate'}
            className="text-sm !text-slate-300">
            <p>You are a member of multiple organizations.</p>
            <p className="my-0.5">
              Please select an organization that you would like to manage.
            </p>
            <p>
              If you do not see an organization, please ask an organization
              owner to add you.
            </p>
          </Alert>
          <CompanyMultipleOrganizations />
        </>
      ) : null}
    </div>
  );
};

export default CompanyDetailsPage;
