import * as Sentry from "@sentry/react";

import { sendSentryEvent } from "features/analytics/sendTransactionLog";

export function initSentry() {
  Sentry.init({
    dsn: "https://3bfe1b49cb3747f5b722c370481e3694@o4505369148063744.ingest.sentry.io/4505369155993600",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://app.getfractal.xyz/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event, hint) => {
      sendSentryEvent({
        level: event.level,
        exception: event.exception,
      });
      return event;
    },
  });
}
