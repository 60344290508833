import InvoiceGenerateForm from "widgets/Invoices/InvoiceGenerateForm";

const PageCreateInvoice = () => {
  return (
    <div className="mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <InvoiceGenerateForm />
    </div>
  );
};

export default PageCreateInvoice;
