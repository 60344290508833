import { Switch } from '@headlessui/react';
import dayjs from 'dayjs';
import { useBillActions } from 'entities/bill/model';
import { useUserActions } from 'entities/user/model';
import { emailRegex } from 'entities/user/lib';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { classNames } from 'shared/lib';
import { Alert } from 'shared/ui';
import { Button as FButton } from 'shared/ui/Button/Button';
import { Spinner } from 'shared/ui/Spinner';
import { useDebouncedCallback } from 'use-debounce';
import { useAccount } from 'wagmi';
import { useAuth0 } from '@auth0/auth0-react';
import { useInvoices } from 'entities/invoice/model';
import {
  PaymentTypeCrypto,
  PaymentTypeFiat,
} from './CreatePaymentRequestForm.PaymentTypeSelection';
import { usePaymentDetailsMutations } from 'entities/organization/';
import { useCurrentUser } from 'entities/user/model';

import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Tooltip } from 'flowbite-react';

import { PaymentDetails } from './CreatePaymentRequestForm.PaymentDetails';

import { v4 as uuidv4 } from 'uuid';
import { CountrySelect } from './CreatePaymentRequestForm.CountrySelect';
import { PayeeInformationAlert } from './CreatePaymentRequestForm.PayeeInformationAlert';
import { ErrorInformationAlert } from './CreatePaymentRequestForm.ErrorInformationAlert';
import { validateForm } from './CreatePaymentRequestForm.validateForm';
import { Form as AForm, Radio, Typography } from 'antd';
import { CreatePaymentRequestFormUSTaxForm } from './CreatePaymentRequestForm.USTaxForm';

const { Text } = Typography;

const useUserPrevInvoiceForm = (url) => {
  const { data: invoices = [], isFetching } = useInvoices();
  const lastInvoice = invoices[invoices.length - 1];
  const invoiceForSameClient = invoices.find(
    (i) => i.client_name === url.companyname
  );
  const invoice = invoiceForSameClient || lastInvoice;

  return {
    isFetchingFormDetails: isFetching,
    formDetails: {
      yourname: invoice?.name || '',
      youremail: invoice?.user_email || '',
      youraddress: invoice?.user_first_line_address || '',
      yourcity: invoice?.user_city || '',
      yourcountry: invoice?.user_country || '',
      yourpostcode: invoice?.user_postal_code || '',
      yourvat: invoice?.user_vat_number || '',
    },
  };
};

const preparePaymentDetailsPayload = (userData, formData) => {
  return {
    name:
      formData.paymentDetailsName ||
      (formData.crypto_payment ? 'My Wallet' : 'My Bank Account'),
    type: formData.crypto_payment ? 'crypto_wallet' : 'bank',
    businessRelation: 'client',
    details: formData.crypto_payment
      ? {
          cryptoWallet: {
            address: formData.wallet,
          },
        }
      : {
          bankAccount: {
            accountBeneficiary: formData.bank_account_name_beneficiary,
            bankAccountBeneficiaryType: formData.bankAccountBeneficiaryType,
            accountNumber: formData.account_number,
            accountIBAN: formData.iban,
            accountACHRoutingNumber: formData.ach_routing_number,
            bankName: formData.bank_name,
            bankBIC: formData.bic,
          },
        },
    m_ownerUserId: userData.user_id,
    m_ownerOrganisationId: userData.selected_organization?._id,
  };
};

export const CreatePaymentRequstForm = ({ url, password, onSubmit }) => {
  const { address, isConnected } = useAccount();
  const { isAuthenticated } = useAuth0();
  const { data: currentUser } = useCurrentUser();
  const walletAddress = useMemo(() => (isConnected ? address : ''), []);
  const { createBillFromPaymentRequestAction } = useBillActions();
  const { userEmailExistsAction } = useUserActions();
  const { isFetchingFormDetails, formDetails } = useUserPrevInvoiceForm(url);
  const { createPaymentDetails } = usePaymentDetailsMutations();

  const [emailExist, setEmailExist] = useState(null);
  const [isRecurringBillsEabled, setRecurringBillsEabled] = useState(false);

  const handleEmailChange = useDebouncedCallback(
    async (email, setFieldValue) => {
      setFieldValue('youremail', email);

      if (!emailRegex.test(email)) {
        console.warn('Invalid email format');
        return;
      }

      setEmailExist(undefined);
      const doesExist = await userEmailExistsAction(email);
      setEmailExist(doesExist);
    },
    500
  );

  function generateInvoiceId() {
    const date = new Date();
    const datePart = `${String(date.getDate()).padStart(2, '0')}${String(
      date.getMonth() + 1
    ).padStart(2, '0')}${String(date.getFullYear()).slice(-2)}`;
    const randomPart = uuidv4().substr(0, 5);
    return `INV-${datePart}-${randomPart}`.toUpperCase();
  }

  function updateDueDateBasedOnInvoiceDate(
    invoiceDateValue,
    paymentTermsValue,
    setFieldValue
  ) {
    if (!paymentTermsValue) {
      paymentTermsValue = 0;
    }

    if (!dayjs(invoiceDateValue).isValid()) {
      return;
    }

    const invoiceDate = new Date(invoiceDateValue);
    invoiceDate.setDate(invoiceDate.getDate() + Number(paymentTermsValue));

    const dueDate = invoiceDate.toISOString().split('T')[0];
    setFieldValue('payment_due_date', dueDate);
  }

  function setRequestDateToDueDateIfEarlier(
    paymentDueDateValue,
    paymentRequestDateValue,
    setFieldValue
  ) {
    const dueDate = new Date(paymentDueDateValue);
    const requestDate = new Date(paymentRequestDateValue);

    if (dueDate < requestDate) {
      setFieldValue('payment_request_date', paymentDueDateValue);
    }
  }

  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    const formData = new FormData();

    formData.append('urlid', values.urlid);
    formData.append('password', password);
    formData.append('companyid', values.companyid);
    formData.append('companyname', values.companyname);
    formData.append('companyemail', values.companyemail);
    formData.append('companyaddress', values.companyaddress);
    formData.append('companycity', values.companycity);
    formData.append('companycountry', values.companycountry);
    formData.append('companypostcode', values.companypostcode);
    formData.append('companyvat', values.companyvat);
    formData.append('approval_required', values.approval_required);
    formData.append('approver', values.approver);
    formData.append('yourname', values.yourname);
    formData.append('youremail', values.youremail);
    formData.append('youraddress', values.youraddress);
    formData.append('yourcity', values.yourcity);
    formData.append('yourcountry', values.yourcountry);
    formData.append('yourpostcode', values.yourpostcode);
    formData.append('yourvat', values.yourvat);
    formData.append('paymentreference', values.paymentreference);
    formData.append('payment_request_date', values.payment_request_date);
    formData.append('payment_due_date', values.payment_due_date);
    formData.append('services', values.services);
    formData.append('crypto_payment', values.crypto_payment);
    formData.append('fiat_payment', values.fiat_payment);
    formData.append('token', values.token);
    formData.append('network', values.network);
    formData.append('price', values.price);
    formData.append('tax', values.tax);
    formData.append('total', values.total);
    formData.append('wallet', values.wallet);
    formData.append('payment_status', values.payment_status);
    formData.append(
      'bank_account_name_beneficiary',
      values.bank_account_name_beneficiary
    );
    formData.append('bank_name', values.bank_name);
    formData.append('account_number', values.account_number);
    formData.append('ach_routing_number', values.ach_routing_number);
    formData.append('bic', values.bic);
    formData.append('iban', values.iban);
    formData.append('file', values.file);
    formData.append('urlname', values.urlname);
    formData.append('isAuthenticated', isAuthenticated);
    formData.append('isSalaryRequest', url.isForEmployee);
    formData.append(
      'bankAccountBeneficiaryType',
      values.bankAccountBeneficiaryType
    );

    if (isRecurringBillsEabled) {
      const issueDay = Number(new Date(values.payment_request_date).getDate());

      formData.append('recurring[recurrenceInterval]', 'MONTH');
      formData.append(
        'recurring[recurrenceDuration]',
        values.recurring.recurrenceDuration
      );
      formData.append('recurring[issueDay]', issueDay);
    }

    if (values.vendorSignatureBase64) {
      formData.append(
        'additionalDetailsRequest[vendorSignatureBase64]',
        values.vendorSignatureBase64
      );
    }

    try {
      if (values.paymentDetails === 'new') {
        await createPaymentDetails.mutateAsync(
          preparePaymentDetailsPayload(currentUser, values)
        );
      }

      const response = await createBillFromPaymentRequestAction(formData);
      onSubmit(response, {
        billId: response.data.bill._id,
        company_name: values.companyname,
        email: values.youremail,
        emailExist,
      });
    } catch (error) {
      console.error('Error', error);
      toast.error(`Something went wrong: ${error.response.data.error}`);
    }
  }

  const _getDefaultCryptoCurrency = () => {
    return {
      token: url?.tokens?.length > 0 ? url.tokens[0] : 'USDC',
      network: url?.networks?.length > 0 ? url.networks[0] : 'Ethereum',
    };
  };

  const _resetPaymentDetails = (setFieldValue) => {
    setFieldValue('paymentDetails', 'no-value');
    setFieldValue('wallet', '');
    setFieldValue('bank_account_name_beneficiary', '');
    setFieldValue('bank_name', '');
    setFieldValue('bic', '');
    setFieldValue('iban', '');
    setFieldValue('ach_routing_number', '');
    setFieldValue('account_number', '');
    setFieldValue('paymentDetailsName', '');
  };

  const formAddress = (formValues) => {
    let res = '';

    if (formValues.companyaddress) {
      res += formValues.companyaddress;
    }

    if (formValues.companycity) {
      res += ', ' + formValues.companycity;
    }

    if (formValues.companycountry) {
      res += ', ' + formValues.companycountry;
    }

    if (formValues.companypostcode) {
      res += ', ' + formValues.companypostcode;
    }

    return res;
  };

  if (isFetchingFormDetails) {
    return (
      <div className="flex h-full items-center justify-center pt-12">
        <Spinner />
      </div>
    );
  }

  const _canNotEditEmail = () => {
    return !!(isAuthenticated && currentUser?.email);
  };

  return (
    <Formik
      initialValues={{
        urlid: url._id,
        urlname: url.name ? url.name : url.companyname,
        password: password,
        companyid: url.owner_id,
        companyname: url.companyname,
        companyemail: url.companyemail,
        companyaddress: url.companyaddress,
        companycity: url.companycity,
        companycountry: url.companycountry,
        companypostcode: url.companypostcode,
        companyvat: url.companyvat,
        approval_required: url?.approval_required,
        approver: url?.approver,
        // your details
        yourname: formDetails.yourname,
        youremail: currentUser?.email || formDetails.youremail,
        youraddress: formDetails.youraddress,
        yourcity: formDetails.yourcity,
        yourcountry: formDetails.yourcountry,
        yourpostcode: formDetails.yourpostcode,
        yourvat: formDetails.yourvat,
        // payment details
        paymentreference: generateInvoiceId(),
        payment_request_date: new Date().toISOString().slice(0, 10),
        payment_due_date: new Date().toISOString().slice(0, 10),
        services: '',
        crypto_payment: true,
        fiat_payment: false,
        token: _getDefaultCryptoCurrency().token,
        network: _getDefaultCryptoCurrency().network,
        price: '',
        tax: '0',
        total: '',
        wallet: walletAddress,
        // payment status set default to unpaid
        payment_status: 'unpaid',
        // bank details
        bank_account_name_beneficiary: '',
        bank_name: '',
        ach_routing_number: '',
        account_number: '',
        bankAccountBeneficiaryType: 'business',
        bic: '',
        iban: '',
        paymentDetails: 'no-value',
        paymentDetailsName: '',
        // file upload
        file: null,
        create_account: true,
        recurring: {
          recurrenceDuration: 3,
        },

        isACHTransfer: false,
      }}
      validate={(values) => validateForm(values, isAuthenticated)}
      onSubmit={handleSubmit}>
      {({
        isSubmitting,
        values,
        errors,
        handleChange,
        validateForm,
        setFieldValue,
      }) => (
        <Form className="mt-5 md:col-span-2 md:mt-0">
          <div className="mt-10 sm:mt-0">
            <div className="rounded-lg bg-white px-4 py-5 shadow dark:bg-slate-800 sm:p-6">
              <h2 className="text-base font-semibold leading-7 text-white">
                Send Payment Request To {values.companyname}
              </h2>
              <text className="mt-1 text-sm leading-6 text-slate-400">
                Complete the form to request a payment from {values.companyname}{' '}
                and get an accounting-friendly invoice. For questions, contact{' '}
                {values.companyemail}.
              </text>
            </div>
          </div>

          {/****************************  YOUR DETAILS SECTION ***********************************/}
          <div className="mt-5 rounded-lg bg-white px-4 py-5 shadow dark:bg-slate-800 sm:p-6">
            <h2 className="text-base font-semibold leading-7 text-white">
              Your Company Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-slate-400">
              Please enter the details of your company here. These details will
              appear in the payment request.
            </p>

            <div className="mt-10">
              <div className="mb-5 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3 ">
                  <label className="block text-sm text-[#CBD5E1] ">
                    {url?.isForEmployee
                      ? 'Your Name *'
                      : 'Company Legal Name *'}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="yourname"
                      id="yourname"
                      autoComplete="off"
                      value={values.yourname}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(
                          'bank_account_name_beneficiary',
                          e.target.value
                        );
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>

                  <ErrorMessage
                    name="yourname"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label className="flex items-center text-sm text-[#CBD5E1] ">
                    {url?.isForEmployee ? 'Your Email *' : 'Company Email *'}{' '}
                    {_canNotEditEmail() && (
                      <Tooltip
                        content={
                          <div className="w-[200px] text-slate-200">
                            For security reasons, email editing is disabled. To
                            use a different email, please sing up with another
                            email.
                          </div>
                        }>
                        <InformationCircleIcon className="ml-2 w-4 cursor-pointer" />
                      </Tooltip>
                    )}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="email"
                      name="youremail"
                      disabled={_canNotEditEmail()}
                      id="youremail"
                      autoComplete="off"
                      value={values.youremail}
                      onChange={(e) => {
                        handleChange(e);
                        return handleEmailChange(e.target.value, setFieldValue);
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] disabled:bg-slate-800 disabled:text-slate-400 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <ErrorMessage
                    name="youremail"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>
              </div>
              <div className="mb-5 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm text-[#CBD5E1] ">
                    {url?.isForEmployee ? 'Address' : 'Company Address'}{' '}
                    {values.fiat_payment && '*'}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="youraddress"
                      id="youraddress"
                      autoComplete="off"
                      value={values.youraddress}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <ErrorMessage
                    name="youraddress"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label className="block text-sm text-[#CBD5E1] ">
                    {url?.isForEmployee ? 'City ' : 'Company City '}
                    {values.fiat_payment && '*'}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="yourcity"
                      id="yourcity"
                      autoComplete="off"
                      value={values.yourcity}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="yourcity"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>
              </div>
              <div className="mb-5 grid grid-cols-6 gap-6">
                <div className="sm:col-span-2">
                  <CountrySelect
                    url={url}
                    formValues={values}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div className="col-span-6 sm:col-span-2">
                  <label className="block text-sm text-[#CBD5E1] ">
                    {url?.isForEmployee ? 'Zip Code ' : 'Company Zip Code '}
                    {values.fiat_payment && '*'}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="yourpostcode"
                      id="yourpostcode"
                      autoComplete="off"
                      value={values.yourpostcode}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <ErrorMessage
                    name="yourpostcode"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>
                <div className="col-span-6 sm:col-span-2 ">
                  <label className="block text-sm text-[#CBD5E1] ">
                    VAT / EIN
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="yourvat"
                      id="yourvat"
                      autoComplete="off"
                      value={values.yourvat}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <ErrorMessage
                    name="yourvat"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {/****************************  PAYMENTS DETAILS SECTION ***********************************/}
          <div className="mt-5 rounded-lg bg-white px-4 py-5 shadow dark:bg-slate-800 sm:p-6">
            <h2 className="text-base font-semibold leading-7 text-white">
              Payment Details
            </h2>
            <p className="mt-1 text-sm leading-6 text-slate-400">
              Fill in the payment details to create a payment request for{' '}
              {url.companyname}.
            </p>

            <div className="mt-10">
              <div className="mb-5 grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3 ">
                  <label className="block text-sm text-[#CBD5E1] ">
                    Invoice ID *
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="paymentreference"
                      id="paymentreference"
                      autoComplete="off"
                      value={values.paymentreference}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <p className="mt-2 text-sm text-[#94A3B8] ">
                    Autogenerated payment reference. You can change it if you
                    require a custom reference.
                  </p>

                  <ErrorMessage
                    name="paymentreference"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 ">
                  <label className="block text-sm text-[#CBD5E1] ">
                    Payment Terms *
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      component="select"
                      name="paymentTerms"
                      id="paymentTerms"
                      autoComplete="off"
                      onChange={(event) => {
                        const invoiceDate = new Date(
                          values.payment_request_date
                        );
                        invoiceDate.setDate(
                          invoiceDate.getDate() + Number(event.target.value)
                        );

                        const dueDate = invoiceDate.toISOString().split('T')[0];

                        setFieldValue('payment_due_date', dueDate);
                        setFieldValue('paymentTerms', event.target.value);
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                      <option value="">Select Payment Terms</option>
                      <option value="0">Due on Receipt</option>
                      <option value="7">7 Days</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                    </Field>
                  </div>
                  <p className="mt-2 text-sm text-[#94A3B8] ">
                    Number of days before the payment is due in.
                  </p>
                </div>
              </div>

              <div className="mb-5 grid grid-cols-6 gap-6">
                <div className="col-span-6 ">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between">
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium leading-6 text-[#CBD5E1]"
                        passive>
                        Repeating Bills
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-[#94A3B8]">
                        Enable to repeat this payment request every month
                      </Switch.Description>
                    </span>
                    <Switch
                      checked={isRecurringBillsEabled}
                      onChange={setRecurringBillsEabled}
                      className={classNames(
                        isRecurringBillsEabled
                          ? 'bg-primary-600'
                          : 'bg-gray-500',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2'
                      )}>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          isRecurringBillsEabled
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>

                <div
                  className={`col-span-6 flex-1 ${
                    isRecurringBillsEabled ? 'sm:col-span-2' : 'sm:col-span-3'
                  }`}>
                  <label className="block text-sm text-[#CBD5E1] ">
                    Invoice Date *
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="date"
                      name="payment_request_date"
                      id="payment_request_date"
                      autoComplete="off"
                      value={values.payment_request_date}
                      onChange={(event) => {
                        handleChange(event);
                        updateDueDateBasedOnInvoiceDate(
                          event.target.value,
                          values.paymentTerms,
                          setFieldValue
                        );
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <p className="mt-2 text-sm text-[#94A3B8] ">
                    This is a date when you want to issue a payment request.
                  </p>
                  <ErrorMessage
                    name="payment_request_date"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                </div>

                <div
                  className={`col-span-6 flex-1 ${
                    isRecurringBillsEabled ? 'sm:col-span-2' : 'sm:col-span-3'
                  }`}>
                  <label className="block text-sm text-[#CBD5E1] ">
                    Payment Due Date *
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      type="date"
                      name="payment_due_date"
                      id="payment_due_date"
                      autoComplete="off"
                      value={values.payment_due_date}
                      onChange={(event) => {
                        handleChange(event);
                        setRequestDateToDueDateIfEarlier(
                          event.target.value,
                          values.payment_request_date,
                          setFieldValue
                        );
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <ErrorMessage
                    name="payment_due_date"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                  <p className="mt-2 text-sm text-[#94A3B8] ">
                    This is a date when you want to get paid.
                  </p>
                </div>

                {isRecurringBillsEabled && (
                  <div className="col-span-6 flex-none sm:col-span-2">
                    <label className="block text-sm font-medium text-[#CBD5E1] ">
                      Repeat For *
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <Field
                        as="select"
                        name="recurring[recurrenceDuration]"
                        id="recurring[recurrenceDuration]"
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option value="1">1 Month</option>
                        <option value="2">2 Months</option>
                        <option value="3">3 Months</option>
                        <option value="4">4 Months</option>
                        <option value="5">5 Months</option>
                        <option value="6">6 Months</option>
                        <option value="7">7 Months</option>
                        <option value="8">8 Months</option>
                        <option value="9">9 Months</option>
                        <option value="10">10 Months</option>
                        <option value="11">11 Months</option>
                        <option value="12">12 Months</option>
                      </Field>
                    </div>
                    <ErrorMessage
                      name="recurring[recurrenceDuration]"
                      component="div"
                      className="mt-2 text-xs italic text-rose-500"
                    />
                  </div>
                )}
              </div>

              <div className="my-5">
                {isRecurringBillsEabled && (
                  <Alert>
                    <div className="space-y-0.5 text-sm">
                      <p>
                        A new bill will be created every month. The first bill
                        will be due on
                        <span className="mx-1 font-semibold">
                          {dayjs(values.payment_due_date).format('DD/MM/YYYY')}.
                        </span>
                      </p>

                      {values.companyname && (
                        <p>
                          <span className="mr-1 font-semibold">
                            {values.companyname}
                          </span>
                          will keep receiving your payment requests every month
                          until
                          <span className="mx-1 font-semibold">
                            {dayjs(values.payment_due_date)
                              .add(
                                values.recurring['recurrenceDuration'],
                                'month'
                              )
                              .format('DD/MM/YYYY')}
                            .
                          </span>
                        </p>
                      )}
                    </div>
                  </Alert>
                )}
              </div>

              <div className="mb-5 grid grid-cols-6 space-y-6 sm:gap-6 sm:space-y-0">
                <div className="col-span-6 sm:col-span-6">
                  <label className="block text-sm text-[#CBD5E1] ">
                    Services *
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <Field
                      component="textarea"
                      columns="3"
                      name="services"
                      id="services"
                      maxLength={65}
                      autoComplete="off"
                      value={values.services}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                    />
                  </div>
                  <ErrorMessage
                    name="services"
                    component="div"
                    className="mt-2 text-xs italic text-rose-500"
                  />
                  <p className="mt-2 text-sm text-[#94A3B8] ">
                    A short description of the services provided. Min 5
                    charachters. Max 65 characters.
                  </p>
                </div>

                <div className="col-span-6 sm:col-span-6 pb-6 pt-6">
                  <AForm.Item
                    label="How would you like to be paid?"
                    help={
                      <Text
                        className={'!text-slate-400 mt-2 block'}
                        italic={true}>
                        {values.crypto_payment &&
                          'Select if you want to be paid in ETH or USDC.'}
                        {values.fiat_payment &&
                          'Select if you want to be paid in USD, EUR or GBP.'}
                      </Text>
                    }
                    layout={'vertical'}>
                    <Radio.Group
                      value={values.crypto_payment ? 'crypto' : 'fiat'}
                      onChange={(evt) => {
                        if (evt.target.value === 'crypto') {
                          setFieldValue('fiat_payment', false);
                          setFieldValue('crypto_payment', true);
                          setFieldValue(
                            'network',
                            _getDefaultCryptoCurrency().network
                          );
                          setFieldValue(
                            'token',
                            _getDefaultCryptoCurrency().token
                          );
                          _resetPaymentDetails(setFieldValue);
                        } else {
                          setFieldValue('crypto_payment', false);
                          setFieldValue('fiat_payment', true);
                          setFieldValue('network', 'Fiat');
                          setFieldValue('token', 'EUR');
                          _resetPaymentDetails(setFieldValue);
                        }
                      }}>
                      <Radio.Button value="crypto">Crypto Payment</Radio.Button>
                      <Radio.Button value="fiat">Fiat Payment</Radio.Button>
                    </Radio.Group>
                  </AForm.Item>
                </div>

                <PayeeInformationAlert formValues={values} />

                <>
                  {values.crypto_payment ? (
                    <PaymentTypeCrypto
                      payLink={url}
                      formValues={values}
                      onValueChange={setFieldValue}
                    />
                  ) : (
                    <PaymentTypeFiat
                      formValues={values}
                      onValueChange={setFieldValue}
                    />
                  )}

                  <PaymentDetails
                    formValues={values}
                    validateForm={validateForm}
                    onValueChange={setFieldValue}
                  />

                  <div className="col-span-6 sm:col-span-2 lg:col-span-2">
                    <label className="block text-sm text-[#CBD5E1] ">
                      Price *
                    </label>
                    <Field
                      type="number"
                      step="any"
                      onWheel={(event) => event.currentTarget.blur()}
                      name="price"
                      id="price"
                      value={values.price}
                      onChange={(e) => {
                        handleChange(e);
                        values.price = e.target.value;
                        if (values.token === 'ETH') {
                          const total = values.price * (1 + values.tax / 100);
                          setFieldValue('total', total.toFixed(4));
                        } else {
                          const total = values.price * (1 + values.tax / 100);
                          setFieldValue('total', total.toFixed(2));
                        }
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className="mt-2 text-xs italic text-rose-500"
                    />
                    <p className="mt-2 text-sm text-[#94A3B8] ">
                      Price of your services.
                    </p>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label className="block text-sm text-[#CBD5E1] ">
                      Tax (%)
                    </label>
                    <Field
                      type="number"
                      step="any"
                      onWheel={(event) => event.currentTarget.blur()}
                      name="tax"
                      id="tax"
                      value={values.tax}
                      onChange={(e) => {
                        handleChange(e);
                        values.tax = e.target.value;
                        if (values.token === 'ETH') {
                          const total = values.price * (1 + values.tax / 100);
                          setFieldValue('total', total.toFixed(4));
                        } else {
                          const total = values.price * (1 + values.tax / 100);
                          setFieldValue('total', total.toFixed(2));
                        }
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <p className="mt-2 text-sm text-[#94A3B8] ">
                      {values.crypto_payment
                        ? 'Tax is normally set to 0% in crypto invoices.'
                        : 'Normally set to 0%. If you are paying VAT, you can set it to 20%.'}
                    </p>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label className="block text-sm text-[#CBD5E1] ">
                      Total Amount *
                    </label>
                    <Field
                      name="total"
                      id="total"
                      value={values.total + ' ' + values.token}
                      onChange={handleChange}
                      type="text"
                      step="any"
                      readOnly
                      onWheel={(event) => event.currentTarget.blur()}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d]  text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <p className="mt-2 text-sm text-[#94A3B8] ">
                      Total amount of your invoice. Automatically calculated.
                    </p>
                  </div>

                  {/* Allow a user to attach a file */}

                  <div className="col-span-6 pt-6">
                    <label className="block text-sm font-medium text-[#CBD5E1] ">
                      Attach a PDF invoice
                    </label>
                    <p className="mb-2 mt-1 text-sm text-[#94A3B8] ">
                      We will auto generate an invoice for you, but you can also
                      attach your own invoice.
                    </p>
                    <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-[#4E6180]  px-6 pb-6 pt-5">
                      {values.file ? (
                        <div className="space-y-1 text-center">
                          {/* Checkmark svg */}
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400 "
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true">
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <div className="flex justify-center text-sm text-gray-600 ">
                            <label className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-500  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-300 dark:bg-slate-800">
                              <span>Remove File</span>
                              <button
                                onClick={(e) => {
                                  setFieldValue('file', null);
                                }}
                                id="file"
                                name="file"
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs  text-white">
                            {values.file.name} uploaded successfully.
                          </p>
                          <ErrorMessage
                            name="file"
                            component="div"
                            className="mt-2 text-xs italic text-rose-500"
                          />
                        </div>
                      ) : (
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400 "
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true">
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <div className="flex text-sm text-gray-600 ">
                            <label className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-500  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-300 dark:bg-slate-800">
                              <span>Upload a file</span>
                              <input
                                onChange={(e) => {
                                  setFieldValue(
                                    'file',
                                    e.currentTarget.files[0]
                                  );
                                }}
                                id="file"
                                name="file"
                                type="file"
                                className="sr-only"
                              />
                            </label>
                            <p className="pl-1 text-[#94A3B8]">
                              or drag and drop
                            </p>
                          </div>
                          <p className="text-xs text-[#94A3B8]">
                            PDF up to 10MB
                          </p>
                        </div>
                      )}
                    </div>
                    <ErrorInformationAlert show errors={errors} />
                  </div>
                </>
              </div>
            </div>
          </div>

          <CreatePaymentRequestFormUSTaxForm
            paymentLink={url}
            vendorEmail={values.youremail}
            onFieldChange={(fieldName, fieldValue) => {
              setFieldValue('vendorSignatureBase64', fieldValue);
            }}
          />

          <div className="mt-5 flex justify-end">
            <FButton
              type="submit"
              color="indigo"
              disabled={isSubmitting}
              isProcessing={isSubmitting}>
              Send Payment Request
            </FButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
