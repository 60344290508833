import { useState } from 'react';
import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';

const paymentProviderLogosMap = {
  'aragon-app': require('shared/images/aragonLogo.png'),
  'gnosis-safe': require('shared/images/gnosisSafe.webp'),
  'wallet-connect': require('shared/images/eth.png'),
  'solana-wallet-connect': require('shared/images/solana-sol-logo.png'),
  monerium: require('shared/images/moneriumIcon.png'),
  payso: require('shared/images/usdc.png'),
  'bridge-offramp': require('shared/images/usdc.png'),
  'payso-onramp': require('shared/images/usd.png'),
  'bridge-onramp': require('shared/images/usd.png'),
  'easy-pay-onramp': require('shared/images/usd.png'),
  'red-envelope-onramp': require('shared/images/usd.png'),
  'fractal-abstract-bank-account': require('shared/images/logo_triangle.png'),
};

export const PaymentProviderSelection = ({
  title = 'Select Payment Method',
  show,
  onClose,
  paymentProvidersEvaluator,
  onSelectProvider,
}) => {
  const [showOnboardingMsg, setShowOnboardingMsg] = useState(false);

  const _handleSelectProvider = (provider) => {
    if (
      paymentProvidersEvaluator.getProviderEligibilityStateForPayout(
        provider
      ) === 'requiresCustomerServiceOnboarding'
    ) {
      setShowOnboardingMsg(true);
      return;
    }

    onSelectProvider(provider);
  };

  const _renderOnboardingRequiredMessage = () => {
    return (
      <div className="flex flex-col gap-6 items-center">
        <p className="text-slate-300">
          Using this method requires you to go through onboarding. Please
          contact <span className="underline">pavel@getfractal.xyz</span> to
          start the onboarding process.
        </p>

        <Button
          className="px-8"
          color="slate"
          onClick={() => setShowOnboardingMsg(false)}>
          Back To Payment Methods
        </Button>
      </div>
    );
  };

  const _renderNoProvidersMessage = () => {
    return (
      <div className="flex flex-col gap-6 items-center">
        <p className="text-slate-300">
          No payment methods are available for this bill.
        </p>

        <Button className="px-8" color="slate" onClick={onClose}>
          Back to Bills
        </Button>
      </div>
    );
  };

  if (!paymentProvidersEvaluator) {
    return null;
  }

  const providerList =
    paymentProvidersEvaluator.getAllPossiblePaymentProviders();

  return (
    <Modal size="lg" popup dismissible show={show} onClose={onClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>
      <Modal.Body>
        {showOnboardingMsg && _renderOnboardingRequiredMessage()}
        {!showOnboardingMsg &&
          !providerList.length &&
          _renderNoProvidersMessage()}
        {!showOnboardingMsg && !!providerList.length && (
          <div className="flex flex-col gap-4 items-center">
            <h3 className=" text-base font-semibold leading-6 text-slate-300">
              {title}
            </h3>
            {providerList.map((p, i) => (
              <Button
                key={i}
                color="slate"
                className="w-[280px]"
                onClick={() => {
                  _handleSelectProvider(p);
                }}>
                {paymentProviderLogosMap[p.id] && (
                  <img
                    data-testid={p.iconPath}
                    className="w-5 mr-2"
                    src={paymentProviderLogosMap[p.id]}
                    alt=""
                    aria-hidden="true"
                  />
                )}

                {p.name}
              </Button>
            ))}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
