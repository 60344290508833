import erc20TokensData from 'shared/constants/erc20TokensData';
import EthIcon from 'shared/images/eth.svg';
import UsdcIcon from 'shared/images/usdc.svg';
import UsdtIcon from 'shared/images/usdt.svg';
import BusdIcon from 'shared/images/busd.svg';
import DaiLogo from 'shared/images/dai.svg';
import AntLogo from 'shared/images/ant.svg';
import OptimismIcon from 'shared/images/optimism.png';
import VelaIcon from 'shared/images/vela.png';
import EsVelaIcon from 'shared/images/esvela.webp';
import ArbIcon from 'shared/images/arb.png';
import PolygonIcon from 'shared/images/polygon.svg';
import ArbitrumIcon from 'shared/images/arbitrum.png';
import BaseIcon from 'shared/images/base.webp';
import SolanaIcon from 'shared/images/solana-sol-logo.png';
import GraphLogo from '../../shared/images/graph.png';
import EursLogo from '../../shared/images/eurs.png';
import UsdLogo from '../../shared/images/usd.png';
import EurLogo from '../../shared/images/eur.png';

export const CURRENCY_TYPES = {
  USD: 'USD',
  EUR: 'EUR',
  GBP: 'GBP',
  CHF: 'CHF',
};

export const TOKEN_TYPES = {
  ETH: 'ETH',
  USDC: 'USDC',
  /** USDC postfix does not reflect world known tokens names, but it is rather an ID for us
   * to understand that in a couple with Chain it can be narrowed to the specific CURRENCY/TOKEN
   * in the real world by our own configuration */
  'USDC.native_polygon': 'USDC.native_polygon',
  USDT: 'USDT',
  BUSD: 'BUSD',
  DAI: 'DAI',
  ANT: 'ANT',
  EURS: 'EURS',
  GRT: 'GRT',
  OP: 'OP',
  VELA: 'VELA',
  esVELA: 'esVELA',
  ARB: 'ARB',
  SOL: 'SOL',
};

export const NETWORK_TYPES = {
  Ethereum: 'Ethereum',
  Polygon: 'Polygon',
  Optimism: 'Optimism',
  Arbitrum: 'Arbitrum',
  Base: 'Base',
  Solana: 'Solana',
};

export const upperCaseNetworkType = (lowercased) =>
  lowercased[0].toUpperCase() + lowercased.slice(1);

export const BLOCKSCAN_URLS = {
  [NETWORK_TYPES.Ethereum]: 'https://etherscan.io',
  [NETWORK_TYPES.Polygon]: 'https://polygonscan.com',
  [NETWORK_TYPES.Optimism]: 'https://optimistic.etherscan.io',
  [NETWORK_TYPES.Arbitrum]: 'https://arbiscan.io',
  [NETWORK_TYPES.Base]: 'https://basescan.org',
  [NETWORK_TYPES.Solana]: 'https://solscan.io',
};

export const ETHEREUM_CHAIN_ID = 1;
export const POLYGON_CHAIN_ID = 137;
export const OPTIMISM_CHAIN_ID = 10;
export const ARBITRUM_CHAIN_ID = 42161;
export const BASE_CHAIN_ID = 8453;

export const GOERLI_CHAIN_ID = 5;

export const NETWORK_TO_CHAIN_ID_MAP = {
  [NETWORK_TYPES.Ethereum]: ETHEREUM_CHAIN_ID,
  [NETWORK_TYPES.Polygon]: POLYGON_CHAIN_ID,
  [NETWORK_TYPES.Arbitrum]: ARBITRUM_CHAIN_ID,
  [NETWORK_TYPES.Optimism]: OPTIMISM_CHAIN_ID,
  [NETWORK_TYPES.Base]: BASE_CHAIN_ID,
  Goerli: GOERLI_CHAIN_ID,
};

export const CHAIN_ID_TO_TYPE = {
  [ETHEREUM_CHAIN_ID]: NETWORK_TYPES.Ethereum,
  [POLYGON_CHAIN_ID]: NETWORK_TYPES.Polygon,
  [OPTIMISM_CHAIN_ID]: NETWORK_TYPES.Optimism,
  [ARBITRUM_CHAIN_ID]: NETWORK_TYPES.Arbitrum,
  [BASE_CHAIN_ID]: NETWORK_TYPES.Base,
};

export const NETWORKS = [
  NETWORK_TYPES.Ethereum,
  NETWORK_TYPES.Polygon,
  NETWORK_TYPES.Optimism,
  NETWORK_TYPES.Arbitrum,
  NETWORK_TYPES.Base,
  NETWORK_TYPES.Solana,
];
export const TOKENS = [
  TOKEN_TYPES.ETH,
  TOKEN_TYPES.USDC,
  TOKEN_TYPES['USDC.native_polygon'],
  TOKEN_TYPES.USDT,
  TOKEN_TYPES.BUSD,
  TOKEN_TYPES.DAI,
  TOKEN_TYPES.ANT,
  TOKEN_TYPES.EURS,
  TOKEN_TYPES.GRT,
  TOKEN_TYPES.OP,
  TOKEN_TYPES.VELA,
  TOKEN_TYPES.esVELA,
  TOKEN_TYPES.ARB,
  TOKEN_TYPES.SOL,
];
export const CURRENCIES = [
  CURRENCY_TYPES.USD,
  CURRENCY_TYPES.EUR,
  CURRENCY_TYPES.GBP,
  CURRENCY_TYPES.CHF,
];

export const TOKENS_BY_NETWORK = {
  [NETWORK_TYPES.Ethereum]: [
    TOKEN_TYPES.USDC,
    TOKEN_TYPES.USDT,
    TOKEN_TYPES.BUSD,
    TOKEN_TYPES.DAI,
    TOKEN_TYPES.ANT,
    TOKEN_TYPES.EURS,
    TOKEN_TYPES.ETH,
    TOKEN_TYPES.GRT,
  ],
  [NETWORK_TYPES.Polygon]: [
    TOKEN_TYPES['USDC.native_polygon'],
    TOKEN_TYPES.USDC,
    TOKEN_TYPES.USDT,
    TOKEN_TYPES.DAI,
  ],
  [NETWORK_TYPES.Optimism]: [
    TOKEN_TYPES.USDC,
    TOKEN_TYPES.OP,
    TOKEN_TYPES.USDT,
    TOKEN_TYPES.DAI,
    TOKEN_TYPES.ETH,
  ],
  [NETWORK_TYPES.Arbitrum]: [
    TOKEN_TYPES.USDC,
    TOKEN_TYPES.USDT,
    TOKEN_TYPES.DAI,
    TOKEN_TYPES.VELA,
    TOKEN_TYPES.esVELA,
    TOKEN_TYPES.ARB,
  ],
  [NETWORK_TYPES.Base]: [TOKEN_TYPES.USDC, TOKEN_TYPES.ETH],
  [NETWORK_TYPES.Solana]: [TOKEN_TYPES.USDC, TOKEN_TYPES.SOL],
};

export const getTokensByNetwork = (network) => {
  const res = TOKENS_BY_NETWORK[network];

  if (!res) {
    throw new Error(`No tokens for network ${network}`);
  }

  return res;
};

export function getTokensByPayLink(url, network) {
  const optimismExcludedTokens = [
    TOKEN_TYPES.ANT,
    TOKEN_TYPES.EURS,
    TOKEN_TYPES.GRT,
    TOKEN_TYPES.BUSD,
    TOKEN_TYPES.ETH,
    TOKEN_TYPES.VELA,
    TOKEN_TYPES.esVELA,
    TOKEN_TYPES.ARB,
  ];

  const arbitrumExcludedTokens = [
    TOKEN_TYPES.ANT,
    TOKEN_TYPES.EURS,
    TOKEN_TYPES.GRT,
    TOKEN_TYPES.BUSD,
    TOKEN_TYPES.ETH,
    TOKEN_TYPES.OP,
  ];

  const baseExcludedTokens = [
    TOKEN_TYPES.ANT,
    TOKEN_TYPES.EURS,
    TOKEN_TYPES.GRT,
    TOKEN_TYPES.BUSD,
    TOKEN_TYPES.OP,
    TOKEN_TYPES.USDT,
    TOKEN_TYPES.DAI,
    TOKEN_TYPES.VELA,
    TOKEN_TYPES.esVELA,
    TOKEN_TYPES.ARB,
  ];

  // Define tokens not supported on Ethereum, such as the OP token
  const ethereumExcludedTokens = [
    TOKEN_TYPES.OP,
    TOKEN_TYPES.VELA,
    TOKEN_TYPES.esVELA,
    TOKEN_TYPES.ARB,
  ];

  if (!url || !url.tokens || url.tokens.length === 0) {
    switch (network) {
      case 'Ethereum':
        return TOKENS.filter(
          (token) => !ethereumExcludedTokens.includes(token)
        );
      case NETWORK_TYPES.Polygon:
        return TOKENS_BY_NETWORK[NETWORK_TYPES.Polygon];
      case 'Optimism':
        return TOKENS.filter(
          (token) => !optimismExcludedTokens.includes(token)
        );
      case 'Arbitrum':
        return TOKENS.filter(
          (token) => !arbitrumExcludedTokens.includes(token)
        );
      case 'Base':
        return TOKENS.filter((token) => !baseExcludedTokens.includes(token));
      case NETWORK_TYPES.Solana:
        return TOKENS_BY_NETWORK[NETWORK_TYPES.Solana];
      default:
        return [];
    }
  }

  switch (network) {
    case 'Ethereum':
      return url.tokens.filter(
        (token) => !ethereumExcludedTokens.includes(token)
      );
    case NETWORK_TYPES.Polygon:
      return url.tokens.filter((token) =>
        TOKENS_BY_NETWORK[NETWORK_TYPES.Polygon].includes(token)
      );
    case 'Optimism':
      return url.tokens.filter(
        (token) => !optimismExcludedTokens.includes(token)
      );
    case 'Arbitrum':
      return url.tokens.filter(
        (token) => !arbitrumExcludedTokens.includes(token)
      );
    case 'Base':
      return url.tokens.filter((token) => !baseExcludedTokens.includes(token));
    case NETWORK_TYPES.Solana:
      return url.tokens.filter((token) =>
        TOKENS_BY_NETWORK[NETWORK_TYPES.Solana].includes(token)
      );
    default:
      return [];
  }
}

export function getNetworksByPayLink(payLink, values) {
  if (payLink?.networks?.length > 0) {
    return payLink.networks;
  }
  return NETWORKS;
}

export const TokenLogoMap = {
  [TOKEN_TYPES.ETH]: EthIcon,
  [TOKEN_TYPES.USDC]: UsdcIcon,
  [TOKEN_TYPES['USDC.native_polygon']]: UsdcIcon,
  [TOKEN_TYPES.USDT]: UsdtIcon,
  [TOKEN_TYPES.BUSD]: BusdIcon,
  [TOKEN_TYPES.DAI]: DaiLogo,
  [TOKEN_TYPES.ANT]: AntLogo,
  [TOKEN_TYPES.OP]: OptimismIcon,
  [TOKEN_TYPES.VELA]: VelaIcon,
  [TOKEN_TYPES.esVELA]: EsVelaIcon,
  [TOKEN_TYPES.ARB]: ArbIcon,
  [TOKEN_TYPES.SOL]: SolanaIcon,
  [TOKEN_TYPES.GRT]: GraphLogo,
  [TOKEN_TYPES.EURS]: EursLogo,
};

export const NetworkLogoMap = {
  [NETWORK_TYPES.Ethereum]: EthIcon,
  [NETWORK_TYPES.Polygon]: PolygonIcon,
  [NETWORK_TYPES.Optimism]: OptimismIcon,
  [NETWORK_TYPES.Arbitrum]: ArbitrumIcon,
  [NETWORK_TYPES.Base]: BaseIcon,
  [NETWORK_TYPES.Solana]: SolanaIcon,
};

export const CurrencyLogoMap = {
  [CURRENCY_TYPES.USD]: UsdLogo,
  [CURRENCY_TYPES.EUR]: EurLogo,
};

export const TokenCurrencyLogoMap = {
  ...TokenLogoMap,
  ...CurrencyLogoMap,
};

export const getTokenHumanReadableNameByChain = (token, chain) => {
  chain = chain && upperCaseNetworkType(chain);

  if (token === TOKEN_TYPES['USDC.native_polygon']) {
    return 'USDC';
  }

  if (token === TOKEN_TYPES.USDC) {
    switch (chain) {
      case NETWORK_TYPES.Polygon:
      case NETWORK_TYPES.Arbitrum:
        return 'USDC.e';
      default:
        return 'USDC';
    }
  }

  return token;
};

/** this fn when we don't have info about network */
export const getTokenHumanReadableName = (token) => {
  if (token === TOKEN_TYPES['USDC.native_polygon']) {
    return 'USDC';
  }

  return token;
};

export const getTokenContractAddressByChainId = (token, chainId) => {
  const erc20TokenConfig = erc20TokensData[token];
  return erc20TokenConfig.erc20Address[CHAIN_ID_TO_TYPE[chainId]];
};

export const isCryptoCurrency = (currency) => {
  return TOKENS.includes(currency);
};

export const erc20ABI_USDT = [
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_value',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
