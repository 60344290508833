import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

export const ScrollToError: React.FC = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.formik-error-message, [data-error]');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
  }, [submitting]);
  return null;
};
