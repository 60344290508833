import { BuildingLibraryIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Button } from 'shared/ui';

export const OrgRequiredWall = () => {
  return (
    <div className="pt-6 flex h-full flex-col justify-center text-center">
      <svg className="mx-auto h-12 w-12 ">
        <BuildingLibraryIcon
          className="mx-auto h-12 w-12 text-gray-400"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-400">
        No Organization
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new organization.
      </p>
      <div className="mt-6 flex justify-center gap-4">
        <Link to="/dashboard/company">
          <Button size="lg">
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Create Organization
          </Button>
        </Link>
      </div>
    </div>
  );
};
