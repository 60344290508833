import React, { useEffect, useMemo, useState } from 'react';
import { useEmployees } from './useEmployees';
import { Button } from 'shared/ui';
import { Table } from 'shared/ui/Table/TableBlocks';
import { toast } from 'react-toastify';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { useDialog } from 'shared/components/Dialog';
import { Form as AForm, Modal as AModal, Input } from 'antd';

export const EmployeesPage = () => {
  const {
    employeesEnabled,
    employees,
    isEditingEmployee,
    editEmployee,
    isFetchingEmployees,
    isDeletingEmployee,
    isCreatingEmployee,
    createEmployee,
    deletePayments,
  } = useEmployees();
  const [modalOpened, toggleModalOpened] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const dialog = useDialog();

  const _handleDelete = (item) => {
    const confirmationText = 'Are you sure you want to delete Employee?';

    dialog.confirm({
      title: 'Delete Employee',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await deletePayments(item.userId);
          toast.success('Employee Deleted!');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const _getRowActions = (row) => {
    return [
      {
        label: 'Edit Employee Details',
        onClick: () => {
          setEmployeeToEdit(row);
        },
      },
      {
        label: 'Delete Employee',
        onClick: () => {
          _handleDelete(row);
        },
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: 'Employee Name',
        accessorKey: 'firstName',
        enableSorting: false,
        cell: ({ row: { original: item } }) => (
          <div className="font-medium text-white">
            {item.firstName} {item.lastName}
          </div>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        enableSorting: false,
        cell: ({ row: { original: item } }) => item.email,
      },
      {
        header: 'Job Title',
        accessorKey: 'jobTitle',
        enableSorting: false,
        cell: ({ row: { original: item } }) => item.jobTitle,
      },
      {
        header: 'Employee Code',
        accessorKey: 'employeeCode',
        enableSorting: false,
        cell: ({ row: { original: item } }) => item.employeeCode,
      },
      {
        header: 'actions',
      },
    ],
    []
  );

  const _onClose = () => {
    toggleModalOpened(false);
    setEmployeeToEdit(null);
  };

  const _handleCreateEditEmployee = async (formData) => {
    try {
      if (
        !employeeToEdit &&
        employees.some((e) => e.email === formData.email)
      ) {
        toast.error('You already have an employee with this email');
        return;
      }

      if (employeeToEdit) {
        await editEmployee(formData);
      } else {
        await createEmployee(formData);
      }
      _onClose();
      toast.success('Successfully added Employee.');
    } catch (error) {
      console.error(error);
    }
  };

  if (!employeesEnabled) {
    return null;
  }

  return (
    <div className={'pt-6'}>
      {!isFetchingEmployees && employees.length === 0 ? (
        <div className="mt-20 text-center">
          <h3 className="mt-2 text-sm font-semibold text-slate-400">
            No Employees
          </h3>
          <p className="mt-1 text-sm text-slate-500">
            Get started by adding a new employee.
          </p>
          <div className="mt-6 flex justify-center gap-4">
            <Button
              onClick={() => toggleModalOpened(true)}
              LeadingIcon={PlusCircleIcon}>
              New Employee
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Table
            title={'Your Employees'}
            description={'Access and manage your Employees here'}
            primaryActionText="Add Employee"
            onPrimaryAction={() => {
              toggleModalOpened(true);
            }}
            manualPagination
            data={employees}
            columns={columns}
            isDataLoading={isFetchingEmployees || isDeletingEmployee}
            onRowClick={() => {}}
            getRowActions={_getRowActions}
          />
        </>
      )}

      <AddEmployeeModal
        open={modalOpened || !!employeeToEdit}
        employeeToEdit={employeeToEdit}
        loading={isCreatingEmployee || isEditingEmployee}
        onCancel={_onClose}
        onSubmit={_handleCreateEditEmployee}
      />
    </div>
  );
};

const AddEmployeeModal = ({
  open,
  loading,
  onCancel,
  employeeToEdit,
  onSubmit,
}) => {
  const isEditMode = !!employeeToEdit;
  const [form] = AForm.useForm();

  const _handleSubmit = () => {
    onSubmit(form.getFieldsValue());
  };

  const _handleCancel = () => {
    form.resetFields(['email', 'firstName', 'lastName', 'jobTitle']);
    onCancel();
  };

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue({
        email: employeeToEdit?.email || '',
        firstName: employeeToEdit?.firstName || '',
        lastName: employeeToEdit?.lastName || '',
        jobTitle: employeeToEdit?.jobTitle || '',
      });
    } else {
      form.setFieldsValue({
        email: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
      });
    }
  }, [open, isEditMode]);

  return (
    <AModal
      centered
      title={isEditMode ? 'Edit Employee' : 'Add New Employee'}
      open={open}
      destroyOnClose
      loading={loading}
      footer={null}
      onCancel={_handleCancel}>
      <AForm
        name="basic"
        form={form}
        requiredMark={false}
        style={{
          paddingTop: 20,
        }}
        initialValues={{
          email: employeeToEdit?.email || '',
          firstName: employeeToEdit?.firstName || '',
          lastName: employeeToEdit?.lastName || '',
          jobTitle: employeeToEdit?.jobTitle || '',
        }}
        layout="vertical"
        autoComplete="off">
        <AForm.Item
          rules={[
            {
              required: true,
              message: 'Please enter email',
              type: 'email',
            },
          ]}
          name="email"
          layout="vertical"
          label="Employee Email">
          <Input disabled={isEditMode} />
        </AForm.Item>

        <AForm.Item
          rules={[
            {
              required: true,
              message: 'Please enter first name',
            },
          ]}
          name="firstName"
          layout="vertical"
          label="First Name">
          <Input />
        </AForm.Item>

        <AForm.Item
          rules={[
            {
              required: true,
              message: 'Please enter last name',
            },
          ]}
          name="lastName"
          layout="vertical"
          label="Last Name">
          <Input />
        </AForm.Item>

        <AForm.Item
          rules={[
            {
              required: true,
              message: 'Please enter last name',
            },
          ]}
          name="jobTitle"
          layout="vertical"
          label="Job Title">
          <Input />
        </AForm.Item>
        <div className="!mt-12 flex flex-row-reverse gap-x-4">
          <Button onClick={_handleSubmit} className="flex-1 justify-center">
            {isEditMode ? 'Edit' : 'Create'}
          </Button>
          <Button
            ui="secondary"
            className="flex-1 justify-center"
            onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </AForm>
    </AModal>
  );
};
