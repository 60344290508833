import axios from 'axios';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';
import {
  LinkIcon,
  XCircleIcon,
  ArrowPathIcon,
  ClockIcon,
  DocumentIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { Badge } from 'flowbite-react';
import { formatDataForCSV } from 'entities/bill/lib';
import { useBill, useBillMutations, useBills } from 'entities/bill/model';
import { compareDates } from 'entities/bill/lib';
import { useOrgPermissions } from 'shared/hooks';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserBills } from 'entities/bill/model/useUserBills';
import { _useReconciledBill } from 'features/bill/ui/BillsTable.ReconciliationCell';
import EmptyBillsComponent from 'widgets/Bills/EmptyBillsComponent';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { useCurrentUserDetails } from 'entities/user/model';
import { BillDetailsSlideOver } from 'widgets/Bills/BillDetailsSlideOver';
import { AddNewBillModal } from './ui/AddNewBillModal';
import {
  useNotDiscardedBillsNotifications,
  createTableRowStyleVariantsByNotifications,
} from 'features/userNotifications/useUserNotifications';
import { Table } from 'shared/ui/Table/TableBlocks';
import { useDialog } from 'shared/components/Dialog';
import {
  BillsTableBatchPayments,
  BATCH_PAYMENT_PROVIDER_TYPES,
} from './ui/BillsTable.BatchPayments';
import { AddFilterModal } from './ui/AddFilterModal';
import { tokenToImageV1 } from 'shared/lib';
import { usePendingChainActions } from 'features/bill/usePendingChainActions';
import { formatDollarAmount } from 'shared/lib/string';
import { PaymentProviderSelection } from 'features/payments/PaymentProviderSelection';
import { getPaymentProvidersEvaluatorStrategy } from 'features/payments/getPaymentProvidersEvaluatorStrategy';
import { createPlatformIntegrations } from 'features/platformIntegrations/PlatformIntegration';
import { MoneriumPaymentModal } from 'widgets/Payments/MoneriumPaymentModal';
import { FiatCryptoBillPaymentModal } from 'features/payments/paymentModals/PaysoOnrampPaymentModal';
import PaymentModal from 'widgets/Payments/PaymentModal';
import { ROLES } from 'shared/hooks';
import { MeshPaymentModal } from 'features/payments/mesh/MeshPaymentModal';
import { SolanaBillPaymentModal } from '../payments/paymentModals/SolanaPaymentModal';
import { FractalBankAccountBillPaymentModal } from '../payments/paymentModals/FractalBankAccount';
import { BillPaymentViaOfframpModal } from '../payments/paymentModals/OfframpModal';
import { getTokenHumanReadableNameByChain } from './lib';
import { createOrgSettingsFactory } from '../organization/orgSettings/OrgSettings';
import { UpdatePaymentStatusModal } from './UpdatePaymentStatusModal';
import { PaymentProviderIdTypes } from '../payments/PaymentProviderIdTypes';
import { PageHeader } from '../../shared/ui/layouts/PageHeader';
import { customFlowBiteTheme, TabItem, Tabs } from '../../shared/ui/Tabs/Tabs';
import { StandingOrdersPage } from './standingOrders/StandingOrdesrPage';

export const INIT_PAGINATION = { pageIndex: 0, pageSize: 25 };

const BillsPage = () => {
  const {
    bills,
    isLoading,
    billsTotal,
    updateQueryOptions,
    hasFilterOptions,
    isFetching,
    queryOptions,
  } = useUserBills({
    pageNumber: INIT_PAGINATION.pageIndex + 1,
    pageLimit: INIT_PAGINATION.pageSize,
  });
  const { hasPermissions } = useOrgPermissions();
  const discardTimerRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  const { data, getCurrentOrgRole } = useCurrentUserDetails();
  const [viewBillDetails, setViewBillDetails] = useState(null);
  const [billForPay, setBillForPay] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = useDialog();
  const { remove } = useBillMutations();
  const code = searchParams.get('code');
  const [cookies] = useCookies(['codeVerifier']);
  const { getNotifications, getNotificationsByEntityIds, dicardNotifications } =
    useNotDiscardedBillsNotifications();
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [showCreateNewBillModal, setShowCreateNewBillModal] = useState(false);
  const [showAddFilterModal, setShowAddFilterModal] = useState(false);
  const [billToUpdatePaymentStatus, setBillToUpdatePaymentStatus] =
    useState(null);
  const [showPaymentProviderSelection, setShowPaymentProviderSelection] =
    useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const { isPaymentAvailable, paymentProvidersEvaluator } = usePaymentProviders(
    billForPay
      ? [bills.find((b) => b._id === billForPay._id)] // can be undefined with pagination
      : Object.keys(rowSelection).map((idx) => bills[idx])
  );

  // Modification starts here
  const InvoiceReference = ({ billId }) => {
    const { data: billDetails, isLoading, error } = useBill(billId);

    if (isLoading) return <span>Loading...</span>;
    if (error) return <span>Error fetching invoice reference</span>;
    return (
      <span>
        {billDetails && billDetails.invoice && billDetails.invoice.referenceId
          ? billDetails.invoice.referenceId
          : 'Reference ID not found'}
      </span>
    );
  };

  const columns = useMemo(() => {
    const res = [
      {
        header: 'selection',
      },
      {
        header: 'Receiver',
        cell: ({ row: { original: bill } }) => (
          <div className="flex flex-col">
            <span className="flex text-white font-medium text-sm">
              {bill.receiverName}
            </span>

            <span className="flex items-center mt-0.5 text-xs font-normal">
              {bill.billRecurrenceId && <ArrowPathIcon className="mr-1 w-4 " />}
              <LinkIcon className="mr-1 inline h-3 w-3" aria-hidden />
              {bill.urlName || 'N/A'}
            </span>
          </div>
        ),
      },

      {
        header: 'Amount',
        cell: ({ row: { original: item } }) => (
          <>
            <div className="flex items-center">
              <span className="mr-1">{tokenToImageV1(item.token)}</span>
              <span className="mr-2 dark:text-white">
                {formatDollarAmount(item.amount)}
              </span>
            </div>

            <div className="leading-none">
              <span className="text-[10px] xl:text-xs">
                {getTokenHumanReadableNameByChain(item.token, item.network)} /{' '}
                {item.network}
              </span>
            </div>
          </>
        ),
      },
      {
        header: 'Due Date',

        cell: ({ row: { original: bill } }) => {
          const today = new Date().toISOString().slice(0, 10);
          const isOverdue =
            bill.paymentStatus === 'unpaid' &&
            compareDates(bill.dueDate, today);

          return (
            <div className="flex items-center">
              {isOverdue && (
                <ClockIcon className={classnames('w-4 mr-1 text-amber-400')} />
              )}

              {dayjs(bill.dueDate).format('DD MMM YY')}
            </div>
          );
        },
      },
      {
        header: 'Accounting Status',
        cell: ({ row: { original: bill } }) => (
          <>
            <ReconciliationCell bill={bill} />
          </>
        ),
      },
      {
        header: 'Status',
        cell: ({ row: { original: bill } }) => <StatusCell bill={bill} />,
      },
      {
        header: 'actions',
      },
    ];

    if (getCurrentOrgRole() === ROLES.ACCOUNTANT) {
      res.splice(5, 0, {
        header: 'Invoice ID',
        cell: ({ row: { original: bill } }) => (
          <InvoiceReference billId={bill._id} />
        ),
      });
    }

    return res;
  }, [data]);

  const _handleRowClick = (row) => {
    setViewBillDetails(row);
    const notificationsToDiscard = getNotificationsByEntityIds([row._id]);

    if (notificationsToDiscard.length) {
      dicardNotifications(notificationsToDiscard.map(({ id }) => id));
    }
  };

  const _handleFilterPopupClose = () => {
    setShowAddFilterModal(false);
  };

  const handleExportToCSV = async () => {
    setCsvLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
    setCsvLoading(false);
  };

  const handlePaginationChange = (state) => {
    updateQueryOptions({
      pageNumber: state.pageIndex + 1,
      pageLimit: state.pageSize,
    });
  };

  const handleBatchDelete = useCallback(async () => {
    const confirmationText =
      'Are you sure you want to delete this bill(s)? This action cannot be undone and you and your team members will not able to see this bill anymore.';

    dialog.confirm({
      title: 'Delete Bill(s)',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          setDeleteLoading(true);
          await Promise.all(
            Object.keys(rowSelection).map((idx) =>
              remove.mutate(bills[idx]._id)
            )
          );
        } catch (error) {
          console.error(error);
        } finally {
          setRowSelection({});
          setDeleteLoading(false);
        }
      },
    });
  }, [bills, dialog, remove, rowSelection]);

  const csvExportData = formatDataForCSV(
    Object.keys(rowSelection).map((idx) => bills[idx])
  );

  const handleFilterPopupSearchChange = (searchState) => {
    updateQueryOptions(searchState);
  };

  const _handleAddNewBill = () => {
    setShowCreateNewBillModal(true);
  };

  const _handleTableSearchChange = (change) => {
    updateQueryOptions({
      generalSearchString: change.searchString,
    });
  };

  const _formSecondaryActionList = () => {
    const res = [];

    if (isPaymentAvailable()) {
      res.push({
        label: 'Pay Bills',
        icon: () => <CreditCardIcon className="w-6 mr-2" />,
        onClick: () => setShowPaymentProviderSelection(true),
      });
    }

    if (!!Object.keys(rowSelection).length) {
      res.push({
        label: (
          <CSVLink data={csvExportData} filename={today + '-bills.csv'}>
            Export to CSV
          </CSVLink>
        ),
        icon: () => <DocumentIcon className="w-6 mr-2" />,
        onClick: () => handleExportToCSV(),
      });

      if (hasPermissions()) {
        res.push({
          label: <span className="text-rose-500">Delete Selected Bills</span>,
          icon: () => <XCircleIcon className="text-rose-500 w-6 mr-2" />,
          onClick: () => handleBatchDelete(),
        });
      }
    }

    return res;
  };

  const _getRowActions = (row) => {
    const actions = [];

    if (row.paymentStatus !== 'processingPayment') {
      actions.push({
        label: 'Update Payment Status',
        onClick: () => {
          setBillToUpdatePaymentStatus(row);
        },
      });
    }

    actions.push({
      label: 'Download Invoice',
      onClick: () => {
        const newWindow = window.open(
          '/public/bills/invoices?id=' + row._id,
          '_blank',
          'noopener,noreferrer'
        );
        if (newWindow) newWindow.opener = null;
      },
    });

    return actions;
  };

  const _handleSelectPaymentProvider = (provider) => {
    setShowPaymentProviderSelection(false);
    setPaymentProvider(provider);
  };

  const _handleTableFilterChange = () => {
    setShowAddFilterModal(true);
  };

  useEffect(() => {
    const organizationId = data?.selected_organization?._id;

    if (code && organizationId && cookies.codeVerifier) {
      getAccessTokenSilently()
        .then((token) => {
          return axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/public/monerium/complete-user-authentication`,
            {
              headers: { Authorization: `Bearer ${token}` },
              params: {
                code,
                codeVerifier: cookies.codeVerifier,
                organizationId,
              },
            }
          );
        })
        .then(() => {
          searchParams.delete('code');
          setSearchParams(searchParams);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    code,
    searchParams,
    cookies.codeVerifier,
    data,
    setSearchParams,
    getAccessTokenSilently,
  ]);

  useEffect(() => {
    discardTimerRef.current = setTimeout(() => {
      discardTimerRef.current = 'fulfilled';
    }, 1000);

    return () => {
      if (discardTimerRef.current === 'fulfilled') {
        const ids = getNotifications().map((n) => n.id);

        if (ids.length) {
          dicardNotifications(ids);
        }
      } else {
        discardTimerRef.current && clearTimeout(discardTimerRef.current);
      }
    };
  }, []);

  if (isLoading) {
    return <ContentLoadingState />;
  }

  return (
    <ContentCotainer className="bills-page-container">
      {!hasFilterOptions() && bills.length === 0 && !isFetching ? (
        <EmptyBillsComponent onAddNewBill={_handleAddNewBill} />
      ) : (
        <>
          <PageHeader
            title="Payments Out"
            subtitle="Overview of your completed and pending payments"
          />
          <Table
            filterControlType="external"
            primaryActionText="Add New Bill"
            onPrimaryAction={_handleAddNewBill}
            secondaryActionList={_formSecondaryActionList()}
            onRowClick={_handleRowClick}
            onSearchChange={_handleTableSearchChange}
            data={bills}
            manualPagination
            filterState={{
              type: 'external',
            }}
            onFilterChange={_handleTableFilterChange}
            columns={columns}
            rowSelection={rowSelection}
            onRowSelectionChange={setRowSelection}
            onPaginationChange={handlePaginationChange}
            rowStyleVariants={createTableRowStyleVariantsByNotifications(
              bills.map((bill) => bill._id),
              getNotifications()
            )}
            // rowStyleVariants={{
            //   0: 'active',
            // }}
            isDataLoading={isFetching || deleteLoading || csvLoading}
            initPagination={{
              pageIndex: queryOptions.pageNumber - 1,
              pageSize: queryOptions.pageLimit,
            }}
            totalCount={billsTotal}
            getRowActions={_getRowActions}
          />
        </>
      )}

      <BillDetailsSlideOver
        show={!!viewBillDetails}
        bill={viewBillDetails}
        onPayBill={(bill) => {
          setBillForPay(bill);
          setShowPaymentProviderSelection(true);
        }}
        onClose={() => setViewBillDetails(null)}
      />

      <PaymentsModalManager
        paymentProvider={paymentProvider}
        paymentProvidersEvaluator={paymentProvidersEvaluator}
        bills={
          billForPay
            ? [bills.find((b) => b._id === billForPay._id)]
            : Object.keys(rowSelection).map((idx) => bills[idx])
        }
        onRowSelectionChange={setRowSelection}
        onClose={() => {
          setPaymentProvider(null);
          setBillForPay(null);
        }}
      />

      <AddNewBillModal
        showModal={showCreateNewBillModal}
        onModalClose={() => setShowCreateNewBillModal(false)}
      />

      {showAddFilterModal && (
        <AddFilterModal
          show={showAddFilterModal}
          queryOptions={queryOptions}
          onSearchChange={handleFilterPopupSearchChange}
          onClose={_handleFilterPopupClose}
        />
      )}

      <UpdatePaymentStatusModal
        bill={billToUpdatePaymentStatus}
        onClose={() => {
          setBillToUpdatePaymentStatus(null);
        }}
      />

      <PaymentProviderSelection
        show={showPaymentProviderSelection}
        onSelectProvider={_handleSelectPaymentProvider}
        paymentProvidersEvaluator={paymentProvidersEvaluator}
        onClose={() => {
          setShowPaymentProviderSelection(false);
          setBillForPay(null);
        }}
      />
    </ContentCotainer>
  );
};

export default BillsPage;

const StatusCell = ({ bill }) => {
  const { getPendingActionByBillId } = usePendingChainActions();
  const pendingAction = getPendingActionByBillId(bill._id);

  const _getPaymentStatus = () => {
    if (pendingAction) {
      return 'Action required';
    }

    if (bill.paymentStatus === 'processingPayment') {
      return 'Processing';
    }

    if (bill.paymentStatus === 'paid') {
      return 'Paid';
    }

    return 'Unpaid';
  };

  const _getBgColorClassName = () => {
    if (pendingAction) {
      return '!bg-slate-700 !text-orange-400';
    }

    if (bill.paymentStatus === 'paid') {
      return '!bg-slate-700 !text-emerald-400';
    }

    if (bill.paymentStatus === 'processingPayment') {
      return '!bg-slate-700 !text-indigo-300';
    }

    return '!bg-slate-700 !text-slate-200';
  };

  return (
    <Badge className={classnames('inline', _getBgColorClassName())}>
      {_getPaymentStatus()}
    </Badge>
  );
};

const ReconciliationCell = ({ bill }) => {
  const { isBillDataFetching, reconciledCategory } = _useReconciledBill(
    bill?.accountingStatus?.reconciliationId
  );

  if (isBillDataFetching) {
    return <>...</>;
  }

  if (bill?.accountingStatus?.reconciled) {
    return (
      <div className="flex flex-col">
        <span>Reconciled</span>
        {!!reconciledCategory && (
          <span className="text-xs text-slate-500">{reconciledCategory}</span>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <span>Not Reconciled</span>
      {!!bill?.accountingStatus?.selectedCategory && (
        <span className="text-xs text-slate-500">
          {bill.accountingStatus?.selectedCategory?.name}
        </span>
      )}
    </div>
  );
};

const usePaymentProviders = (bills) => {
  const { getOrgIntegrations, getOrgSettings } = useCurrentUserDetails();

  /** Enrich bills data */
  const { data: enrichedBills } = useBills(bills.map((b) => b._id));
  const evaluator = getPaymentProvidersEvaluatorStrategy(
    bills.length ? enrichedBills || bills : [],
    createPlatformIntegrations(getOrgIntegrations()),
    createOrgSettingsFactory(getOrgSettings())
  );

  return {
    isPaymentAvailable: () =>
      !!evaluator && evaluator.getAllPossiblePaymentProviders().length > 0,

    paymentProvidersEvaluator: evaluator,
  };
};

// IMPORTANT, PaymentsModalManager should be generic and work with payouts
// but our current Modals contain coupled logic inside
const PaymentsModalManager = ({
  bills,
  paymentProvider,
  paymentProvidersEvaluator,
  onRowSelectionChange,
  onClose,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { invalidate } = useBillMutations();

  if (!paymentProvider) {
    return null;
  }

  // Old approach, we might need to move it inside modal logic
  // we dont need to do side effects here
  const handlePaymentSuccess = async (
    txHash,
    isMultisig,
    { paymentStatus } = {}
  ) => {
    const token = await getAccessTokenSilently();
    const billId = bills && bills.length && bills[0]?._id;
    await axios.put(
      process.env.REACT_APP_SERVER_URL + '/bills/update-bill/' + billId,
      {
        multisigUsed: isMultisig,
        paymentStatus:
          paymentStatus || (isMultisig ? 'processingPayment' : 'paid'),
        transactionHash: isMultisig ? undefined : txHash,
        safeTxHash: isMultisig ? txHash : undefined,
        paymentDate: new Date().toISOString().slice(0, 10),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    invalidate();
  };

  const renderModal = () => {
    if (
      paymentProvider.id === 'gnosis-safe' ||
      paymentProvider.id === 'aragon-app'
    ) {
      return (
        <BillsTableBatchPayments
          paymentProvider={
            paymentProvider.id === 'gnosis-safe'
              ? BATCH_PAYMENT_PROVIDER_TYPES.GnosisSafe
              : BATCH_PAYMENT_PROVIDER_TYPES.Aragon
          }
          showModal
          onModalClose={onClose}
          onDeselectRows={() => onRowSelectionChange({})}
          selectedBills={bills}
        />
      );
    }

    if (paymentProvider.id === 'monerium') {
      return <MoneriumPaymentModal onClose={onClose} show bill={bills[0]} />;
    }

    if (paymentProvider.id === 'payso') {
      return (
        <BillPaymentViaOfframpModal
          payouts={paymentProvidersEvaluator?.getPayouts()}
          bills={bills}
          show
          onClose={onClose}
        />
      );
    }

    if (paymentProvider.id === 'wallet-connect') {
      return (
        <PaymentModal
          bill={bills[0]}
          onClose={onClose}
          show
          handleSuccess={handlePaymentSuccess}
        />
      );
    }

    if (
      [
        PaymentProviderIdTypes.PaysoOnramp,
        PaymentProviderIdTypes.EasyPayOnramp,
        PaymentProviderIdTypes.RedEnvelopeOnramp,
      ].includes(paymentProvider?.id)
    ) {
      return (
        <FiatCryptoBillPaymentModal
          billId={bills[0]._id}
          payouts={paymentProvidersEvaluator?.getPayouts()}
          onClose={onClose}
          paymentProviderConfig={paymentProvider}
          onOnrampInited={() => {
            invalidate();
          }}
        />
      );
    }

    if (paymentProvider.id === 'solana-wallet-connect') {
      return (
        <SolanaBillPaymentModal
          onTransactionSuccess={({ signature }) => {
            handlePaymentSuccess(signature, false);
          }}
          payouts={paymentProvidersEvaluator?.getPayouts()}
          onClose={onClose}
        />
      );
    }

    if (paymentProvider.id === 'fractal-abstract-bank-account') {
      return (
        <FractalBankAccountBillPaymentModal
          billId={bills[0]._id}
          onTransactionSuccess={({ hash, scheduledToPaymentProvider }) => {
            handlePaymentSuccess(hash, false, {
              paymentStatus: scheduledToPaymentProvider
                ? 'processingPayment'
                : undefined,
            });
          }}
          payouts={paymentProvidersEvaluator?.getPayouts()}
          onClose={onClose}
        />
      );
    }

    if (paymentProvider.id === 'mesh') {
      return (
        <MeshPaymentModal
          payouts={paymentProvidersEvaluator?.getPayouts()}
          onClose={onClose}
          handlePaymentSuccess
          onPaymentSuccess={handlePaymentSuccess}
        />
      );
    }

    return null;
  };

  return <>{renderModal()}</>;
};

export const BillsDashboard = () => {
  return (
    <Tabs
      theme={{
        ...customFlowBiteTheme,
        base: `${customFlowBiteTheme.base} h-full`,
        tablist: {
          ...customFlowBiteTheme.tablist,
          styles: {
            ...customFlowBiteTheme.tablist.styles,
            default: `${customFlowBiteTheme.tablist.styles.default} mx-4 sm:mx-6 md:mx-8`,
          },
        },
        tabitemcontainer: {
          ...customFlowBiteTheme.tabitemcontainer,
          base: `${customFlowBiteTheme.tabitemcontainer.base} h-full`,
        },
        tabpanel: `${customFlowBiteTheme.tabpanel} h-full`,
      }}
      aria-label="Invoices tabs"
      variant="default">
      <TabItem active title="Bills">
        <BillsPage />
      </TabItem>
      <TabItem active title="Recurring Bills" icon={ArrowPathIcon}>
        <StandingOrdersPage />
      </TabItem>
    </Tabs>
  );
};
