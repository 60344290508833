import BigNumber from 'bignumber.js';

import { CURRENCIES, TOKENS, upperCaseNetworkType } from 'features/bill/lib';

export const PayoutTypes = {
  CryptoTransfer: 'cryptoTransfer',
  BankTransfer: 'bankTransfer',
};

class PayoutDetails {
  /**
   * @type {BigNumber} amount
   * */
  #amount;
  #currency;
  #type;

  constructor(amount, currency, type) {
    this.#amount = new BigNumber(amount);
    this.#currency = currency;
    this.#type = type;
  }

  /**
   * @return {string}
   * */
  get type() {
    return this.#type;
  }

  get currency() {
    return this.#currency;
  }

  /**
   * @return {BigNumber} amount
   * */
  get amount() {
    return this.#amount;
  }

  get amountStr() {
    return this.#amount.toString();
  }

  get amountNumber() {
    return this.#amount.toNumber();
  }
}

class CryptoPayoutDetails extends PayoutDetails {
  #network;
  #walletAddress;

  constructor(amount, currency, destinationDetails = {}) {
    super(amount, currency, PayoutTypes.CryptoTransfer);
    this.#network = destinationDetails.network;
    this.#walletAddress = destinationDetails.walletAddress;
  }

  get network() {
    return this.#network;
  }

  get walletAddress() {
    return this.#walletAddress;
  }
}

class BankTransferPayoutDetails extends PayoutDetails {
  #accountNumber;
  #accountBeneficiary;
  #bankBIC;
  #accountIBAN;
  #accountACHRoutingNumber;
  #bankAccountBeneficiaryType;
  #bankName;

  constructor(amount, currency, destinationDetails = {}) {
    super(amount, currency, PayoutTypes.BankTransfer);
    this.#accountNumber = destinationDetails.accountNumber;
    this.#accountBeneficiary = destinationDetails.accountBeneficiary;
    this.#bankBIC = destinationDetails.bankBIC;
    this.#accountIBAN = destinationDetails.accountIBAN;
    this.#accountACHRoutingNumber = destinationDetails.accountACHRoutingNumber;
    this.#bankAccountBeneficiaryType =
      destinationDetails.bankAccountBeneficiaryType;
    this.#bankName = destinationDetails.bankName;
  }

  get accountNumber() {
    return this.#accountNumber;
  }

  get accountBeneficiary() {
    return this.#accountBeneficiary;
  }

  get bankBIC() {
    return this.#bankBIC;
  }

  get accountIBAN() {
    return this.#accountIBAN;
  }

  get accountACHRoutingNumber() {
    return this.#accountACHRoutingNumber;
  }

  get bankAccountBeneficiaryType() {
    return this.#bankAccountBeneficiaryType;
  }

  get bankName() {
    return this.#bankName;
  }
}

export const createPayoutDetailsFromBill = (bill) => {
  if (!bill) {
    return null;
  }

  if (CURRENCIES.includes(bill.token)) {
    return new BankTransferPayoutDetails(
      new BigNumber(bill.amount),
      bill.token,
      bill?.invoice?.paymentDetails?.bankAccount
    );
  }

  if (TOKENS.includes(bill.token)) {
    return new CryptoPayoutDetails(new BigNumber(bill.amount), bill.token, {
      network: bill.network,
      walletAddress: bill.walletAddress,
    });
  }

  return null;
};

export const createPayoutDetailsFromTransfer = (transfer) => {
  if (!transfer) {
    return null;
  }

  if (CURRENCIES.includes(transfer.destinationCurrency)) {
    return new BankTransferPayoutDetails(
      new BigNumber(transfer.transferAmount),
      transfer.destinationCurrency,
      transfer?.destinationPaymentDetails
    );
  }

  if (TOKENS.includes(transfer.destinationCurrency)) {
    return new CryptoPayoutDetails(
      new BigNumber(transfer.transferAmount),
      transfer.destinationCurrency,
      {
        network: upperCaseNetworkType(transfer.destinationNetwork),
        walletAddress: transfer.destinationPaymentDetails.address,
      }
    );
  }

  return null;
};

export const createPayoutDetailsFromExpense = (expense) => {
  if (!expense) {
    return null;
  }

  if (CURRENCIES.includes(expense.paymentCurrency)) {
    return new BankTransferPayoutDetails(
      new BigNumber(expense.paymentAmount),
      expense.paymentCurrency,
      expense?.recipientPaymentDetails?.bankAccount
    );
  }

  if (TOKENS.includes(expense.paymentCurrency)) {
    return new CryptoPayoutDetails(
      new BigNumber(expense.paymentAmount),
      expense.paymentCurrency,
      {
        network: upperCaseNetworkType(expense.paymentNetwork),
        walletAddress: expense?.recipientPaymentDetails?.cryptoWallet?.address,
      }
    );
  }

  return null;
};

export const createCryptoPayoutDetails = (amount, token, network, address) => {
  return new CryptoPayoutDetails(new BigNumber(amount), token, {
    network,
    walletAddress: address,
  });
};
