import UserChangePasswordComponent from './UserChangePasswordComponent';
import UserUpdatePersonalInfo from './UserUpdatePersonalInfo';
import UserBusinessTypeSetting from './UserBusinessTypeSetting';

export default function UserChangeDetails() {
  return (
    <div className="space-y-8 px-4 py-16 sm:px-6 lg:px-8">
      <UserUpdatePersonalInfo />
      <UserChangePasswordComponent />
      <UserBusinessTypeSetting />
    </div>
  );
}
