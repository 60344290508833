import axios from 'axios';
import { isLocal } from '../../shared/lib/env';

export const sendTransactionLog = async (getToken, payload) => {
  const token = await getToken();

  if (isLocal()) {
    return;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/public/analytics/transaction-log`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendSentryEvent = async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/public/analytics/client/sentry`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
