import { useState } from 'react';
import { useSendTransaction } from 'wagmi';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { useCurrentUser } from 'entities/user/model';
import { useSafeWallet } from 'shared/hooks/safeWallet';
import { sendTransactionLog } from '../../features/analytics/sendTransactionLog';
import { useAuth0 } from '@auth0/auth0-react';
import { NETWORK_TO_CHAIN_ID_MAP } from '../../features/bill/lib';
import { parseEther } from 'viem';

export default function PayETHButton({
  addressTo,
  ethAmount,
  handleSuccess,
  network,
  disabled,
  tokenName,
  payButtonStyles,
  onUserApproval,
  setTransactionSuccessful,
  setTransactionHash,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { chain } = useAccount();
  const [transactionSent, setTransactionSent] = useState(false);
  const { isConnectedWalletSafe } = useSafeWallet();

  const { data: userData, setData: setUserData } = useCurrentUser();

  const updateLastPaymentInfoInUserContext = () => {
    setUserData({
      ...userData,
      last_payment: {
        amount: ethAmount,
        addressTo: addressTo,
      },
    });
  };

  useEffect(() => {
    setNetworkMatch(network === chain?.name);
  }, [network, chain]);

  const chainId = NETWORK_TO_CHAIN_ID_MAP[network];

  const [networkMatch, setNetworkMatch] = useState(chainId === chain?.id);

  const {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
    sendTransactionAsync,
    sendTransaction,
  } = useSendTransaction({
    chainId,
    to: addressTo,
    value: parseEther(String(ethAmount)),
  });
  useEffect(() => {
    if (isSuccess) {
      sendTransactionLog(getAccessTokenSilently, {
        title: '🟢[CRYPTO <-> CRYPTO][Contract Write Success] 🟢',
        hash: data,
        addressTo,
        ethAmount,
      });

      toast.success('Transaction successful.');
      setTransactionHash(data);
      setTransactionSuccessful && setTransactionSuccessful(true);

      updateLastPaymentInfoInUserContext();
      setTransactionSent(false);
      handleSuccess && handleSuccess(data, isConnectedWalletSafe());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if (error.message.startsWith('User rejected')) {
        toast.error('User rejected the request.');
      } else {
        toast.error('Transaction failed. Please try again later.');
      }

      sendTransactionLog(getAccessTokenSilently, {
        title: '🔸 [CRYPTO <-> CRYPTO][Execution Error] 🔸',
        error: error,
      });

      onUserApproval(false);
    }
  }, [isError]);

  const handleClick = (event) => {
    event.preventDefault();
    sendTransaction({});
    onUserApproval(true);
  };

  return (
    <>
      <button
        className={
          !sendTransactionAsync ||
          isLoading ||
          isSuccess ||
          !networkMatch ||
          transactionSent ||
          disabled
            ? `${payButtonStyles} cursor-not-allowed opacity-50`
            : payButtonStyles
        }
        onClick={handleClick}
        disabled={
          isLoading || isSuccess || !networkMatch || transactionSent || disabled
        }>
        {isLoading
          ? 'Please check wallet'
          : `Pay ${ethAmount} ` + `${tokenName}`}
      </button>
    </>
  );
}
