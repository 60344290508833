import React from 'react';
import { useCurrentUser } from 'entities/user/model';
import { useUserMutations } from 'entities/user/model';
import { ROLES } from 'shared/hooks';
import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';
import { Select } from 'shared/ui/form/Select/Select';
import { Form, Formik } from 'formik';

const ModalUserManagement = ({ user, show, onClose }) => {
  const { removeUser, changeUserRole } = useUserMutations();
  const { data } = useCurrentUser();

  const selectedOrganizationId = data?.selected_organization?._id;

  const handleDisableAccess = async (id) => {
    return removeUser.mutateAsync(id, {
      onSuccess: () => {
        onClose();
      },
      onError: (error) => {
        console.error('Error: ', error);
      },
    });
  };

  const _handleSubmit = async (data) => {
    return changeUserRole.mutateAsync(
      {
        newData: data,
        user: user,
        selectedOrganizationId: selectedOrganizationId,
      },
      {
        onSuccess: async () => {
          onClose();
        },
        onError: (error) => {
          console.error('Error updating user permissions', error);
        },
      }
    );
  };

  return (
    <Modal dismissible show={show} onClose={onClose}>
      <Modal.Header>User Management</Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          organization_id: data.organization_id,
          role: user?.role,
          emailNotifications: user?.email_notifications,
        }}
        onSubmit={_handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Modal.Body>
              <input type="hidden" name="organization_id" />
              <div className="space-y-6 ">
                <p className="text-left leading-relaxed text-gray-500 dark:text-slate-200">
                  <span className="text-slate-400">Email: </span>
                  {user.email}
                </p>
                <div className="flex flex-col">
                  <label
                    htmlFor="role"
                    className="mb-2 text-left dark:text-slate-400">
                    Role:
                  </label>

                  <Select
                    name="role"
                    id="role"
                    sizing="sm"
                    color="slate"
                    value={values.role}
                    onChange={(evt) => {
                      setFieldValue('role', evt.target.value);
                    }}>
                    <option value={ROLES.MEMBER}>Member</option>
                    <option value={ROLES.ACCOUNTANT}>Accountant</option>
                    <option value={ROLES.ADMIN}>Admin</option>
                  </Select>
                </div>
                <div className="text-left">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="emailNotifications"
                      id="emailNotifications"
                      checked={values.emailNotifications}
                      onChange={(evt) => {
                        setFieldValue('emailNotifications', evt.target.checked);
                      }}
                      className="rounded"
                    />
                    <label
                      htmlFor="emailNotifications"
                      className="ml-2  text-slate-200">
                      Enable Notifications For All Payments
                    </label>
                  </div>

                  <p className="mt-2 text-xs text-gray-500 dark:text-slate-400">
                    If you enable email notifications, this user will be
                    notified about <strong className="underline">all</strong>{' '}
                    bills, invoices and payments. <br /> If you disable email
                    notifications, this user will only be notified about bills,
                    invoices and payments that require their approval.
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-end">
              {user.email !== data.email ? (
                <Button
                  type="button"
                  color="warning"
                  isProcessing={removeUser.isPending}
                  disabled={removeUser.isPending}
                  onClick={(event) => {
                    event.preventDefault();
                    handleDisableAccess(user.id);
                  }}>
                  Disable Access
                </Button>
              ) : (
                <Button color="gray" type="button" disabled>
                  Cannot Self-Disable
                </Button>
              )}

              <Button
                isProcessing={changeUserRole.isLoading}
                disabled={changeUserRole.isLoading}
                color="indigo"
                type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default ModalUserManagement;
