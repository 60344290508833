import { useMemo, useState } from 'react';
import { useStandingOrders } from './useStandingOrders';
import EmptyOrders from './EmptyOrders';
import { Badge } from 'flowbite-react';
import { tokenToImage } from 'shared/lib';
import { dateFormatter } from 'entities/bill/lib';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { Table } from 'shared/ui/Table/TableBlocks';
import { StandingOrderDetailsSlideOver } from './StandingOrderDetailsSlideOver';
import { formatDollarAmount } from 'shared/lib/string';
import { getTokenHumanReadableNameByChain } from '../lib';
import classNames from 'classnames';

export const StandingOrdersPage = () => {
  const { orders, isFetchingOrders } = useStandingOrders();
  const [orderId, setOrderId] = useState();
  const columns = useMemo(
    () => [
      {
        header: 'Receiver',
        cell: ({ row: { original } }) => (
          <div className="flex flex-col text-left">
            <span className="text-sm font-semibold text-white">
              {original.receiverName}
            </span>
            <span className="mt-0.5 flex items-center text-xs font-normal text-gray-400">
              {original.receiverEmail}
            </span>
          </div>
        ),
      },
      {
        header: 'Amount',
        cell: ({ row: { original } }) => (
          <>
            <div className="flex items-center">
              <span className="mr-1">{tokenToImage(original.token)}</span>
              <span className="mr-2 dark:text-white">
                {formatDollarAmount(original.amount)}
              </span>
            </div>

            <div className="leading-none">
              <span className="text-[10px] xl:text-xs">
                {getTokenHumanReadableNameByChain(
                  original.token,
                  original.network
                )}{' '}
                {' / ' + original.network}
              </span>
            </div>
          </>
        ),
      },
      {
        header: 'Next Issue Date',
        cell: ({ row: { original } }) => dateFormatter(original.paymentDate),
      },
      {
        header: 'Next Due Date',
        cell: ({ row: { original } }) => dateFormatter(original.dueDate),
      },
      {
        header: 'Issued / Expected',
        cell: ({ row: { original } }) => (
          <>
            {original.generatedBillsCount} {' / '}
            {original.expectedBillsCount}
          </>
        ),
      },

      {
        header: 'Status',
        cell: ({ row: { original } }) => (
          <>
            <Badge
              className={classNames('inline', {
                '!bg-slate-700 !text-emerald-400': original.status === 'ACTIVE',
                '!bg-slate-700 !text-indigo-300': original.status === 'EXPIRED',
                '!bg-slate-700 !text-slate-200':
                  original.status === 'CANCELLED',
              })}>
              {original.status === 'ACTIVE' && 'Active'}
              {original.status === 'EXPIRED' && 'Fulfilled'}
              {original.status === 'CANCELLED' && 'Cancelled'}
            </Badge>
          </>
        ),
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    setOrderId(row.id);
  };

  if (isFetchingOrders) {
    return <ContentLoadingState />;
  }

  if (!isFetchingOrders && orders.length === 0) {
    return (
      <ContentCotainer>
        <EmptyOrders />
      </ContentCotainer>
    );
  }

  return (
    <ContentCotainer>
      <>
        <Table
          title="Recurring Bills"
          description="Access your standing orders and manage them."
          onRowClick={handleRowClick}
          data={orders}
          columns={columns}
        />

        <StandingOrderDetailsSlideOver
          show={!!orderId}
          orderId={orderId}
          onClose={() => setOrderId(null)}
        />
      </>
    </ContentCotainer>
  );
};
