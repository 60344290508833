import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';
import { useAccountingData } from '../../pages/bill/BillReconcilePage';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useFeatureFlags, features } from 'shared/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useInvoiceMutations } from 'entities/invoice/model';
import {
  filterIncomeAccounts,
  findCustomer,
  filterTaxRatesForBills,
} from '../../widgets/Bills/BillReconcileForm';
import classNames from 'classnames';
import { useCurrentUserDetails } from 'entities/user/model';

export const ReconciliationModal = ({ invoice, show, onClose }) => {
  const { invalidate: invalidateInvoices } = useInvoiceMutations();
  const { updateInvoiceAccountingCategory } = useUpdateAccountingCategory();
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const {
    taxRates,
    zeroTaxRate,
    customers,
    accounts,
    baseCurrency,
    supportedCurrencies,
  } = useAccountingData();
  const { isFeatureEnabled } = useFeatureFlags();

  const incomeAccounts = filterIncomeAccounts(accounts);
  const { sorted, top5, remaining, matched } = findCustomer(customers, invoice);
  const filteredTaxRates = filterTaxRatesForBills(taxRates);

  const determineCurrency = () => {
    if (
      invoice.token === baseCurrency ||
      supportedCurrencies.some((currency) => currency === invoice.token)
    ) {
      return invoice.token;
    }

    return 'USD';
  };

  const _handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const token = await getAccessTokenSilently();

    try {
      await axios.post(
        process.env.REACT_APP_SERVER_URL + '/codat/reconcile-invoice',
        {
          organizationId: getCurrentOrganizationId(),
          invoiceId: invoice._id,
          customerId: values.customerId,
          customerEmail: values.customerEmail,
          customerName: values.customerName,
          accountId: values.accountId,
          accountName: values.accountName,

          taxName: values.taxName,
          taxRate: values.taxRate,
          taxId: values.taxId,
          attachTrxHash: values.attachTrxHash,
          baseCurrency: baseCurrency,
          currency: determineCurrency(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success('Successfully Reconciled Invoice', {
        autoClose: 3000,
      });
      invalidateInvoices();
      onClose();
    } catch (error) {
      console.error(error);
      toast.error(
        'Was not able to reconcile a Bill, please contact support: pavel@getfractal.xyz',
        {
          autoClose: 6000,
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const _renderSupplierHint = () => {
    if (matched) {
      return null;
    }

    return (
      <p className="mt-2 text-sm  text-[#CBD5E1]">
        We could not find the customer for this invoice. Please choose a
        customer yourself or add a new customer.
      </p>
    );
  };

  return (
    <Modal dismissible show={show} onClose={onClose}>
      <Modal.Header>Reconcile Invoice</Modal.Header>

      <Formik
        enableReinitialize
        onSubmit={_handleSubmit}
        initialValues={{
          customerId: matched?.id || '',
          newCustomer: false,
          customerName: invoice.client_name,
          customerEmail: invoice.client_email,
          accountId: invoice?.accountingStatus?.accountId || '',
          accountName: invoice?.accountingStatus?.accountName || '',
          taxName:
            Number(invoice.totalTaxAmount) === 0 && zeroTaxRate
              ? zeroTaxRate.name
              : '',
          taxRate: 0,
          taxId:
            Number(invoice.totalTaxAmount) === 0 && zeroTaxRate
              ? zeroTaxRate.id
              : '',
          attachTrxHash: isFeatureEnabled(features.RECONCILE_ATTACH_TRX_HASH),
        }}
        validate={(values) => {
          const errors = {};
          if (!values.customerId && values.newCustomer === false) {
            errors.customerId = 'Required';
          }
          if (!values.customerName) {
            errors.customerName = 'Required';
          }
          if (!values.customerEmail) {
            errors.customerEmail = 'Valid email required';
          }
          if (!values.taxId) {
            errors.taxId = 'Required';
          }
          if (!values.taxName) {
            errors.taxName = 'Required';
          }
          if (!values.accountId) {
            errors.accountId = 'Required';
          }

          return errors;
        }}>
        {({ isSubmitting, values, setFieldValue, submitForm }) => {
          return (
            <>
              <Modal.Body>
                <Form className="space-y-6">
                  <div className="col-span-8 sm:col-span-6">
                    <label className="block text-sm font-medium text-gray-600 dark:text-[#CBD5E1]">
                      Choose Customer *{' '}
                    </label>
                    <Field
                      as="select"
                      id="customer"
                      name="customer"
                      value={values.customerId}
                      onChange={(e) => {
                        const c = sorted.find((s) => e.target.value === s.id);

                        setFieldValue('customerId', c.id);
                        setFieldValue('customerName', c.customerName);
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                      <option value="">Select Customer</option>
                      <optgroup label="Top 5 Matches">
                        {top5.map((customer) => (
                          <option id={customer.id} value={customer.id}>
                            {customer.customerName}
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label="Other Customers">
                        {remaining.map((c) => (
                          <option id={c.id} value={c.id}>
                            {c.customerName}
                          </option>
                        ))}
                      </optgroup>
                    </Field>
                    {_renderSupplierHint()}
                    <ErrorMessage
                      name="supplierId"
                      component="div"
                      className="mt-2 text-xs italic text-red-500"
                    />
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Switch.Group as="div" className="items-left flex ">
                        <Switch.Label
                          as="span"
                          className="mr-5 text-sm font-medium leading-6 text-secondary-200"
                          passive>
                          Add New Customer Manually
                        </Switch.Label>
                        <Switch
                          checked={values.newCustomer}
                          onChange={() => {
                            setFieldValue('newCustomer', !values.newCustomer);
                            setFieldValue('customerId', '');
                          }}
                          className={classNames(
                            values.newCustomer
                              ? 'bg-indigo-600'
                              : 'bg-gray-500',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                          )}>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              values.newCustomer
                                ? 'translate-x-5'
                                : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                    </div>
                  </div>
                  {values.newCustomer && (
                    <>
                      <div className="col-span-6">
                        <label className="block text-sm font-medium  text-[#CBD5E1]">
                          Customer Name *{' '}
                        </label>
                        <Field
                          id="customerName"
                          name="customerName"
                          type="text"
                          value={values.customerName}
                          onChange={(event) => {
                            setFieldValue('customerName', event.target.value);
                          }}
                          className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <p className="mt-2 text-sm  text-[#CBD5E1]">
                          Name of the customer the bill was received from.
                          Please use a unique name.
                        </p>
                        <ErrorMessage
                          name="customerName"
                          component="div"
                          className="mt-2 text-xs italic text-red-500"
                        />
                      </div>

                      <div className="col-span-6">
                        <label className="block text-sm font-medium  text-[#CBD5E1]">
                          Customer Email *{' '}
                        </label>
                        <Field
                          id="customerEmail"
                          name="customerEmail"
                          type="text"
                          value={values.customerEmail}
                          onChange={(event) => {
                            setFieldValue('customerEmail', event.target.value);
                          }}
                          className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {/* <p className="mt-2 text-sm  text-[#CBD5E1]">
                    Email of the customer the bill was received from. Email
                    must be valid.
                  </p> */}
                        <ErrorMessage
                          name="customerEmail"
                          component="div"
                          className="mt-2 text-xs italic text-red-500"
                        />
                      </div>
                    </>
                  )}

                  <div className="col-span-6">
                    <label className="block text-sm font-medium  text-[#CBD5E1]">
                      Income Category *
                    </label>
                    <Field
                      as="select"
                      type="select"
                      name="accountId"
                      value={values.accountId}
                      onChange={(e) => {
                        const account = incomeAccounts.find(
                          (a) => a.id === e.target.value
                        );
                        setFieldValue('accountId', account.id);
                        setFieldValue('accountName', account.name);

                        updateInvoiceAccountingCategory({
                          invoiceId: invoice._id,
                          categoryId: account.id,
                          categoryName: account.name,
                        })
                          .then(() => {
                            invalidateInvoices();
                            toast.success('Saved Invoice Category!');
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                      <option value="">Please Select Account</option>
                      {incomeAccounts
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.name}
                          </option>
                        ))}
                    </Field>

                    <p className="mt-2 text-sm  text-[#CBD5E1]">
                      {values.accountId === ''
                        ? 'Select the nominal account to be used for the invoice.'
                        : incomeAccounts.find(
                            (a) => a.id === values.nominalAccountId
                          )?.description}
                    </p>
                    <ErrorMessage
                      name="accountId"
                      component="div"
                      className="mt-2 text-xs italic text-red-500"
                    />
                  </div>

                  <div className="col-span-6">
                    <label className="block text-sm font-medium  text-[#CBD5E1]">
                      Tax Rate *{' '}
                    </label>
                    <Field
                      as="select"
                      id="taxName"
                      name="taxName"
                      type="select"
                      value={values.lineItemTaxId}
                      onChange={(e) => {
                        const taxRate = filteredTaxRates.find(
                          (s) => e.target.value === s.id
                        );

                        setFieldValue('taxName', taxRate.name);
                        setFieldValue('taxRate', taxRate.effectiveTaxRate);
                        setFieldValue('taxId', taxRate.id);
                      }}
                      className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                      <option value="">Select Tax Rate</option>

                      {filteredTaxRates.map((taxRate) => {
                        return (
                          <option key={taxRate.id} value={taxRate.id}>
                            <span className="font-medium text-gray-900">
                              {'\u200BName: '}
                            </span>
                            {taxRate.name}. <br />
                            <span className="font-medium text-gray-900">
                              {'\u200BTax Rate: '}
                            </span>
                            {taxRate.effectiveTaxRate}%
                          </option>
                        );
                      })}
                    </Field>
                    <p className="mt-2 text-sm  text-[#CBD5E1]">
                      Amount of tax for the line.
                    </p>
                    <ErrorMessage
                      name="taxName"
                      component="div"
                      className="mt-2 text-xs italic text-red-500"
                    />
                  </div>
                </Form>
              </Modal.Body>

              <Modal.Footer className="justify-end">
                <Button color="slate" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isProcessing={isSubmitting}
                  color="indigo"
                  onClick={submitForm}
                  type="submit">
                  Reconcile
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const useUpdateAccountingCategory = () => {
  const { getAccessTokenSilently } = useAuth0();
  const mut = useMutation({
    mutationKey: ['update-invoice-accounting-category'],
    mutationFn: async ({ invoiceId, categoryId, categoryName }) => {
      const token = await getAccessTokenSilently();
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/public/invoices/${invoiceId}/accounting-category`,
        {
          categoryId,
          categoryName,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return res.data;
    },
  });

  return {
    updateInvoiceAccountingCategory: mut.mutateAsync,
  };
};
