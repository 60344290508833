import { useMemo, useCallback } from 'react';
import { useAccount } from 'wagmi';
import { useEthersSigner } from './useEtherSigner';
import {
  ProposalCreationSteps,
  Context,
  Client,
  MultisigClient,
} from '@aragon/sdk-client/dist/index.js';
import { SupportedNetwork } from '@aragon/sdk-client-common';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { useMutation } from '@tanstack/react-query';
import { TokenType } from '@aragon/sdk-client-common';
import { ethers } from 'ethers';
import { CHAIN_ID_TO_TYPE } from 'features/bill/lib';
import erc20TokensData from 'shared/constants/erc20TokensData';

const chainToNetworkMap = {
  1: SupportedNetwork.MAINNET,
  137: SupportedNetwork.POLYGON,
};

const useAragonContext = () => {
  const signer = useEthersSigner();
  const { chain } = useAccount();

  return useMemo(
    () =>
      signer
        ? new Context({
            network: chainToNetworkMap[chain?.id],
            signer,
          })
        : null,
    [signer, chain?.id]
  );
};

export const useAragonClient = () => {
  const context = useAragonContext();
  return useMemo(() => (context ? new Client(context) : null), [context]);
};

export const useMultisigAragonClient = () => {
  const context = useAragonContext();
  return useMemo(
    () => (context ? new MultisigClient(context) : null),
    [context]
  );
};

export const useAragonAppDetails = (appAddress) => {
  const client = useAragonClient();
  const dataQuery = useQuery({
    queryKey: ['aragon-app-details', appAddress],
    queryFn: async () => {
      try {
        const d = await client.methods.getDao(appAddress);
        return d;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    enabled: !!client && !!appAddress,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 20,
  });

  const getMultisigAddress = useCallback(() => {
    return dataQuery?.data?.plugins?.find(
      (p) => p.id === 'multisig.plugin.dao.eth'
    )?.instanceAddress;
  }, [dataQuery.data]);

  const getAddress = useCallback(() => {
    return dataQuery?.data?.address;
  }, [dataQuery.data]);

  return {
    getMultisigAddress,
    getAddress,
    isLoading: dataQuery.isFetching,
    isSuccess: dataQuery.isFetched,
  };
};

export const useAragonAppBalance = (appAddress) => {
  const client = useAragonClient();
  const dataQuery = useQuery({
    queryKey: ['aragon-app-balance', appAddress],
    queryFn: async () => {
      try {
        const daoBalances = await client.methods.getDaoBalances({
          daoAddressOrEns: appAddress,
        });
        return daoBalances;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    enabled: !!client && !!appAddress,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 30,
  });

  const getBalanceByToken = useCallback(
    (token) => {
      const balance = dataQuery?.data?.find((item) => item.symbol === token);

      return balance
        ? new BigNumber(balance.balance).div(
            new BigNumber(10).exponentiatedBy(balance.decimals)
          )
        : new BigNumber(0);
    },
    [dataQuery.data]
  );

  return {
    getBalanceByToken,
    isFetching: dataQuery.isFetching,
    isFetched: dataQuery.isFetched,
  };
};

export const useAragonMultisigBatchPayment = (pluginAddress) => {
  const multisigClient = useMultisigAragonClient();
  const client = useAragonClient();
  const { chain } = useAccount();

  const initTransactionMutation = useMutation({
    mutationKey: ['init-aragon-batch-transaction'],
    mutationFn: async ({ recipients }) => {
      let proposalId;
      const metadataUri = await multisigClient.methods.pinMetadata({
        title: 'Fractal batch payment',
        summary:
          'Batch payment was initiated via Fractal platform, please approve',
        description: recipients
          .map(({ to, token, amount }) => `Send ${amount} ${token} to ${to}`)
          .join('\n\n'),
        resources: [],
      });

      const actions = await Promise.all(
        recipients.map(({ to, token, amount }) => {
          const erc20TokenConfig = erc20TokensData[token];
          const amountBig = ethers.utils.parseUnits(
            amount,
            erc20TokenConfig.decimalsInteger
          );
          const withdrawParams = {
            type: TokenType.ERC20,
            // eslint-disable-next-line no-undef
            amount: BigInt(amountBig.toString()),
            tokenAddress:
              erc20TokenConfig.erc20Address[CHAIN_ID_TO_TYPE[chain.id]],
            recipientAddressOrEns: to,
          };
          return client.encoding.withdrawAction(withdrawParams);
        })
      );

      const proposalParams = {
        pluginAddress,
        metadataUri,
        actions,
        endDate: new Date(Date.now() + 60 * 60 * 24 * 1000 * 5),
      };
      const steps = multisigClient.methods.createProposal(proposalParams);

      for await (const step of steps) {
        try {
          // eslint-disable-next-line default-case
          switch (step.key) {
            case ProposalCreationSteps.CREATING:
              console.log({ txHash: step.txHash });
              break;
            case ProposalCreationSteps.DONE:
              proposalId = step.proposalId;
              break;
          }
        } catch (err) {
          console.error(err);
        }
      }

      return proposalId;
    },
  });

  return {
    initTransaction: initTransactionMutation.mutateAsync,
    isTransferTransactionLoading: initTransactionMutation.isPending,
  };
};
