import { useCurrentUser } from 'entities/user/model';
import { useBillMutations } from 'entities/bill/model';
import { useOrganizations } from 'entities/organization';
import { Button } from 'shared/ui/Button/Button';
import { twMerge } from 'tailwind-merge';

import { useCurrentOrganizationCache } from 'widgets/Authentication/WithAuthentication';
import { useInvalidatePendingChainActions } from 'features/bill/usePendingChainActions';
import { useInvalidateNotifications } from 'features/userNotifications/useUserNotifications';
import { ContentLoadingState } from '../../shared/ui/layouts/PageContent';

const CompanyMultipleOrganizations = () => {
  const { data, setData } = useCurrentUser();
  const { data: organizations } = useOrganizations();
  const { invalidate } = useBillMutations();
  const { saveCurrentOrganizationId } = useCurrentOrganizationCache();
  const { invalidatePendingChainActions } = useInvalidatePendingChainActions();
  const { invalidateNotifications } = useInvalidateNotifications();
  const selectedOrg = organizations?.length
    ? organizations.find((org) => org._id === data?.selected_organization?._id)
    : null;

  const handleSelectOrganization = (org) => {
    saveCurrentOrganizationId(org._id);
    setData((prev) => ({ ...prev, selected_organization: org }));
    invalidate();
    invalidatePendingChainActions();
    invalidateNotifications();
  };

  return (
    <div className="my-8 bg-slate-800 p-6 shadow sm:rounded-lg">
      {organizations ? (
        <div className="my-4 grid grid-flow-row grid-cols-3 gap-4 sm:rounded-lg">
          {organizations.map((org) => {
            const isSelectedOrg = selectedOrg?._id === org._id;
            return (
              <div
                key={org._id}
                className={twMerge(
                  'overflow-hidden rounded-xl border bg-slate-800',
                  isSelectedOrg ? 'border-slate-500' : 'border-slate-500'
                )}>
                <div className="flex items-center justify-between gap-x-4 border-b border-gray-900/5 bg-[#18222d] p-6">
                  <h3 className="text-sm font-medium leading-6 text-slate-300">
                    {org.name}
                  </h3>

                  {isSelectedOrg ? (
                    <Button
                      color={'emerald'}
                      size="sm"
                      onClick={() => handleSelectOrganization(org)}>
                      Selected
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color={'slate'}
                      onClick={() => handleSelectOrganization(org)}>
                      Select
                    </Button>
                  )}
                </div>
                <dl className="-my-3 divide-y divide-slate-700 px-6 py-4 text-sm leading-6">
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-slate-400">Role</dt>
                    <dd className="text-slate-200">
                      <span className="capitalize">{org.userRole}</span>
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-slate-400">Email</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-slate-200">
                        {org.email}
                      </div>
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-slate-400">Address</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-slate-200">
                        {org.address}
                      </div>
                    </dd>
                  </div>

                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-slate-400">City</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-slate-200">
                        {org.city}
                      </div>
                    </dd>
                  </div>

                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-slate-400">Country</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-gray-200">
                        {org.country}
                      </div>
                    </dd>
                  </div>

                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-slate-400">VAT/EIN</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-gray-200">{org.vat}</div>
                    </dd>
                  </div>
                </dl>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex min-h-[200px] items-center justify-center">
          <ContentLoadingState />
        </div>
      )}
    </div>
  );
};

export default CompanyMultipleOrganizations;
