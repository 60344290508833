import { useCurrentUser } from 'entities/user/model';
import { OrgRequiredWall } from 'features/organization/ui';
import CompanyCodatIntegration from 'widgets/Organization/CompanyCodatIntegration';

const AccountingIntegrationsPage = () => {
  const { data } = useCurrentUser();

  if (!data?.selected_organization?._id) {
    return <OrgRequiredWall />;
  }

  return (
    <div className="py-8">
      <CompanyCodatIntegration />
    </div>
  );
};

export default AccountingIntegrationsPage;
