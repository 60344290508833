import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useBillActions } from './actions';
import { QUERY_KEY, USER_BILLS_QUERY_KEY } from './constants';

export const useBillMutations = () => {
  const queryClient = useQueryClient();
  const { deleteAction, updateAction } = useBillActions();

  const remove = useMutation({
    mutationFn: async (invoiceId) => {
      const response = await deleteAction(invoiceId);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [USER_BILLS_QUERY_KEY] });
    },
  });

  const update = useMutation({
    mutationFn: async ({ billId, params }) => {
      const response = await updateAction(billId, params);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [USER_BILLS_QUERY_KEY] });
    },
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [USER_BILLS_QUERY_KEY] });
  }, [queryClient]);

  return { remove, update, invalidate };
};
