import {
  ContentCotainer,
  ContentLoadingState,
} from '../../shared/ui/layouts/PageContent';
import { useTransfers } from './useTransfers';
import { EmptyTransfers } from './EmptyTransfers';
import { Table } from 'shared/ui/Table/TableBlocks';
import { useMemo, useState } from 'react';
import { AddNewTransferModal } from './AddNewTransferModal';
import { extractLastChar, formatDollarAmount } from '../../shared/lib/string';
import dayjs from 'dayjs';
import { PaymentDetailsTypes } from '../payments/paymentDetails/PaymentDetailsTypes';
import { Badge } from 'flowbite-react';
import { tokenToImageV1 } from '../../shared/lib';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { getTransfersPaymentProvidersEvaluatorStrategy } from '../payments/getPaymentProvidersEvaluatorStrategy';
import { createPlatformIntegrations } from '../platformIntegrations/PlatformIntegration';
import { useCurrentUserDetails } from '../../entities/user/model';
import { PaymentProviderSelection } from '../payments/PaymentProviderSelection';
import { FiatCryptoTransfersPaymentModal } from '../payments/paymentModals/PaysoOnrampPaymentModal';
import { TransferOverviewSlideOver } from './TransferOverviewSlideOver';
import { createOrgSettingsFactory } from '../organization/orgSettings/OrgSettings';
import { TransferPaymentViaOfframpModal } from '../payments/paymentModals/OfframpModal';
import { PaymentsOnboardingWidget } from '../payments/paymentModals/PaymentsOnboardingModal';

export const TransferStatusBadge = ({ transfer }) => {
  if (['success'].includes(transfer?.payablePaymentStatus)) {
    return (
      <Badge className="inline !bg-slate-700 !text-emerald-400">Paid</Badge>
    );
  }

  if (
    ['executed', 'awaiting_execution'].includes(transfer?.payablePaymentStatus)
  ) {
    return (
      <Badge className="inline !bg-slate-700 !text-indigo-300">
        Processing
      </Badge>
    );
  }

  return <Badge className="inline !bg-slate-700 !text-slate-200">Unpaid</Badge>;
};

export const TransfersPage = () => {
  const { getOrgIntegrations, getOrgSettings } = useCurrentUserDetails();
  const [transfersToPay, setTransfersToPay] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const { transfers, isFetching, isPending } = useTransfers();
  const [showCreateNewTransferModal, setShowCreateNewTransferModal] =
    useState(false);
  const [transferToOverview, setTransferToOverview] = useState(null);

  const evaluator = getTransfersPaymentProvidersEvaluatorStrategy(
    transfersToPay.length
      ? transfersToPay
      : Object.keys(rowSelection).map((idx) => transfers[idx]),
    createPlatformIntegrations(getOrgIntegrations()),
    createOrgSettingsFactory(getOrgSettings())
  );

  const columns = useMemo(() => {
    const res = [
      {
        header: 'selection',
      },
      {
        header: 'Date',
        cell: ({ row: { original: item } }) => {
          return dayjs(item.createdAt).format('DD MMM YY');
        },
      },
      {
        header: 'Amount',
        cell: ({ row: { original: item } }) => (
          <>
            <div className="flex items-center">
              <span className="mr-1">
                {tokenToImageV1(item.destinationCurrency)}
              </span>
              <span className="mr-2 dark:text-white">
                {formatDollarAmount(item.transferAmount)}{' '}
                {item.destinationCurrency}
              </span>
            </div>
          </>
        ),
      },
      {
        header: 'From',
        cell: ({ row: { original: item } }) => {
          if (item.payablePaymentMeta?.methodPaymentDetails) {
            throw new Error('Render is missing');
          }

          if (item.destinationNetwork === 'fiat') {
            return 'External Wallet';
          }

          return 'External Bank';
        },
      },
      {
        header: 'To',
        cell: ({ row: { original: item } }) => {
          if (
            item.destinationPaymentDetails.type ===
            PaymentDetailsTypes.CryptoWallet
          ) {
            return (
              <>
                Wallet: ***{' '}
                {extractLastChar(item.destinationPaymentDetails.address, 6)}
                {item.destinationPaymentDetailsMeta?.pdsName &&
                  ` (${item.destinationPaymentDetailsMeta?.pdsName})`}
              </>
            );
          }

          if (
            item.destinationPaymentDetails.type ===
            PaymentDetailsTypes.BankAccount
          ) {
            return (
              <>
                Bank Account: ***{' '}
                {extractLastChar(
                  item.destinationPaymentDetails.accountIBAN ||
                    item.destinationPaymentDetails.accountNumber,
                  4
                )}{' '}
                ({item.destinationPaymentDetails.accountBeneficiary})
              </>
            );
          }

          return 'Bank Account';
        },
      },
      {
        header: 'Status',
        cell: ({ row: { original: item } }) => (
          <TransferStatusBadge transfer={item} />
        ),
      },
    ];

    return res;
  }, []);

  const _handleOpenNewTransferModal = () => {
    setShowCreateNewTransferModal(true);
  };

  const _handleCloseNewTransferModal = () => {
    setShowCreateNewTransferModal(false);
  };

  const _handleRowClick = (row) => {
    setTransferToOverview(row);
  };

  const _formSecondaryActionList = () => {
    const res = [];

    if (evaluator?.getAllPossiblePaymentProviders().length > 0) {
      res.push({
        label: 'Complete Transfer',
        icon: () => <CreditCardIcon className="w-6 mr-2" />,
        onClick: () => {
          setTransfersToPay(
            Object.keys(rowSelection).map((idx) => transfers[idx])
          );
        },
      });
    }

    return res;
  };

  const _handleRowSelectionChange = (getUpdate) => {
    if (transfersToPay.length) {
      setTransfersToPay([]);
    }
    setRowSelection(getUpdate(rowSelection));
  };

  if (isPending) {
    return <ContentLoadingState />;
  }

  return (
    <ContentCotainer>
      <PaymentsOnboardingWidget />
      {transfers.length === 0 && !isFetching ? (
        <EmptyTransfers onAddNewTransfer={_handleOpenNewTransferModal} />
      ) : (
        <Table
          title="Transfers"
          description="Buy & sell stablecoins by creating a new transfer."
          primaryActionText="Add New Transfer"
          onRowClick={_handleRowClick}
          secondaryActionList={_formSecondaryActionList()}
          onPrimaryAction={_handleOpenNewTransferModal}
          columns={columns}
          data={transfers}
          isDataLoading={isFetching}
          rowSelection={rowSelection}
          onRowSelectionChange={_handleRowSelectionChange}
        />
      )}
      <PaymentsModalManager
        transfers={transfersToPay}
        paymentProvidersEvaluator={evaluator}
        onCancelPayment={() => {
          setTransfersToPay([]);
        }}
      />

      <AddNewTransferModal
        onTransferCreated={(tr) => {
          setTransfersToPay([tr]);
        }}
        show={showCreateNewTransferModal}
        onClose={_handleCloseNewTransferModal}
      />

      <TransferOverviewSlideOver
        transfer={transferToOverview}
        onClose={() => {
          setTransferToOverview(null);
        }}
      />
    </ContentCotainer>
  );
};

const PaymentsModalManager = ({
  transfers,
  paymentProvidersEvaluator,
  onCancelPayment,
}) => {
  const [paymentProvider, setPaymentProvider] = useState(null);

  const _handleSelectPaymentProvider = (provider) => {
    setPaymentProvider(provider);
  };

  const _handleClose = () => {
    onCancelPayment();
    setPaymentProvider(null);
  };

  if (!transfers.length) {
    return null;
  }

  const renderModal = () => {
    if (
      ['easy-pay-onramp', 'red-envelope-onramp', 'bridge-onramp'].includes(
        paymentProvider?.id
      )
    ) {
      return (
        <FiatCryptoTransfersPaymentModal
          transfers={transfers}
          payouts={paymentProvidersEvaluator?.getPayouts()}
          onClose={_handleClose}
          paymentProviderConfig={paymentProvider}
        />
      );
    }

    if (['bridge-offramp'].includes(paymentProvider?.id)) {
      return (
        <TransferPaymentViaOfframpModal
          transfers={transfers}
          onClose={_handleClose}
          show={true}
          payouts={paymentProvidersEvaluator?.getPayouts()}
          paymentProviderConfig={paymentProvider}
        />
      );
    }

    return null;
  };

  return (
    <>
      <PaymentProviderSelection
        title="Select Payment Method"
        show={!paymentProvider}
        onSelectProvider={_handleSelectPaymentProvider}
        paymentProvidersEvaluator={paymentProvidersEvaluator}
        onClose={_handleClose}
      />

      {renderModal()}
    </>
  );
};
