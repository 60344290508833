import { Button } from 'shared/ui/Button/Button';
import { toast } from 'react-toastify';
import { Table as SimpleTable, Badge } from 'flowbite-react';
import {
  CurrencyDollarIcon,
  BriefcaseIcon,
  WalletIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/solid';
import { useDescribeVendor, useDownloadVendorTaxForm } from './useVendors';
import { formatDollarAmount } from '../../../shared/lib/string';
import { Flex, Modal as AModal, Typography } from 'antd';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

const { Link } = Typography;
function formatWalletAddress(address) {
  if (!address) return '';

  if (address.startsWith('0x')) {
    return address.slice(0, 6) + '...' + address.slice(-4);
  }

  return address;
}

export const resolveName = (vendor) => {
  if (!vendor) {
    return 'Vendor Details';
  }

  if (vendor.baseInfo.legalName) {
    return vendor.baseInfo.legalName;
  }

  if (vendor.baseInfo.firstName) {
    return (
      vendor.baseInfo.firstName +
      (vendor.baseInfo.lastName ? ` ${vendor.baseInfo.lastName}` : '')
    );
  }

  if (vendor?.employeeInfo?.employeeFirstName) {
    return (
      vendor?.employeeInfo?.employeeFirstName +
      (vendor?.employeeInfo?.employeeLastName
        ? ` ${vendor?.employeeInfo?.employeeLastName}`
        : '')
    );
  }

  return 'N/A';
};

export const VendorDetailsModal = ({ userId, onClose }) => {
  const { vendor, isFetching } = useDescribeVendor(userId);
  const { downloadTaxForm, isDownloading } = useDownloadVendorTaxForm();

  const resolveVendorType = () => {
    if (vendor?.roles.includes(4)) {
      return 'Employee';
    }

    return 'Vendor';
  };

  const resolveAddress = () => {
    let res = '';

    if (!vendor) {
      return '';
    }

    if (vendor.baseInfo.primaryAddress.streetAddress) {
      res = res + vendor.baseInfo.primaryAddress.streetAddress;
    }

    if (vendor.baseInfo.primaryAddress.city) {
      res = res + `${res ? ', ' : ''}${vendor.baseInfo.primaryAddress.city}`;
    }

    if (vendor.baseInfo.primaryAddress.country) {
      res = res + `${res ? ', ' : ''}${vendor.baseInfo.primaryAddress.country}`;
    }

    if (vendor.baseInfo.primaryAddress.postalCode) {
      res =
        res + `${res ? ', ' : ''}${vendor.baseInfo.primaryAddress.postalCode}`;
    }

    return res;
  };

  const _handleDownloadVendorTaxForm = () => {
    downloadTaxForm({ vendorId: vendor?.vendorInfo?.vendorId })
      .then((pdfData) => {
        const buffer = new Uint8Array(pdfData);
        const blob = new Blob([buffer], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(() => {
        toast.error(
          'Was not able to download a tax form, please contact support: pavel@getfractal.xyz',
          {
            autoClose: 6000,
          }
        );
      });
  };

  const renderPaymentDetailsRow = (paymentMethod) => {
    return (
      <SimpleTable.Row className="bg-white !text-slate-400 dark:border-slate-700 dark:bg-slate-900/30">
        <SimpleTable.Cell>
          <div className="flex items-center">
            {paymentMethod.type === 'crypto_wallet' ? (
              <>
                <WalletIcon className="mr-2 h-4 w-4 text-white" /> Crypto Wallet
              </>
            ) : (
              <>
                <BuildingLibraryIcon className="mr-2 h-4 w-4 text-white" /> Bank
                Account
              </>
            )}
          </div>
        </SimpleTable.Cell>
        <SimpleTable.Cell>
          {paymentMethod.type === 'crypto_wallet' ? (
            <>
              <div className="group relative inline-block items-center">
                <span className="absolute inset-0 z-10 cursor-text overflow-hidden transition-all duration-300 group-hover:max-w-0 group-hover:opacity-0">
                  {formatWalletAddress(
                    paymentMethod?.details?.cryptoWallet?.address
                  )}
                </span>
                <span className="cursor-text whitespace-nowrap opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  {paymentMethod?.details?.cryptoWallet?.address}
                </span>
              </div>

              {!!paymentMethod.details.cryptoWallet.ensAddress && (
                <>
                  <br /> <span className="mr-1">ENS Address:</span>{' '}
                  <span>{paymentMethod.details.cryptoWallet.ensAddress}</span>
                </>
              )}
            </>
          ) : (
            <>
              {!!paymentMethod.details.bankAccount.accountBeneficiary && (
                <>
                  <span className="mr-1">Beneficiary: </span>
                  <span>
                    {paymentMethod.details.bankAccount.accountBeneficiary}
                  </span>
                </>
              )}
              {!!paymentMethod.details.bankAccount.accountNumber && (
                <>
                  <br /> <span className="mr-1">Account Number: </span>
                  <span>{paymentMethod.details.bankAccount.accountNumber}</span>
                </>
              )}
              {!!paymentMethod.details.bankAccount.accountIBAN && (
                <>
                  <br /> <span className="mr-1">IBAN: </span>
                  <span>{paymentMethod.details.bankAccount.accountIBAN}</span>
                </>
              )}
              {!!paymentMethod.details.bankAccount.accountACHRoutingNumber && (
                <>
                  <br /> <span className="mr-1">Routing Number: </span>
                  <span>
                    {paymentMethod.details.bankAccount.accountACHRoutingNumber}
                  </span>
                </>
              )}
              {!!paymentMethod.details.bankAccount.bankName && (
                <>
                  <br /> <span className="mr-1">Bank Name: </span>
                  <span>{paymentMethod.details.bankAccount.bankName}</span>
                </>
              )}
            </>
          )}
        </SimpleTable.Cell>
      </SimpleTable.Row>
    );
  };

  const renderPaymentDetailsTable = () => {
    if (!vendor?.outgoingPayments?.paymentMethods?.length) {
      return null;
    }

    return (
      <>
        <h4 className="mb-4 dark:text-slate-400">Payments Details</h4>
        <div className="overflow-x-auto">
          <SimpleTable>
            <SimpleTable.Head>
              <SimpleTable.HeadCell className="dark:bg-slate-700">
                Type
              </SimpleTable.HeadCell>
              <SimpleTable.HeadCell className="dark:bg-slate-700">
                Details
              </SimpleTable.HeadCell>
            </SimpleTable.Head>
            <SimpleTable.Body className="divide-y">
              {vendor?.outgoingPayments?.paymentMethods.map((m) =>
                renderPaymentDetailsRow(m)
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <br />
      </>
    );
  };

  const renderLastBillsTable = () => {
    if (!vendor?.outgoingPayments?.lastBills?.length) {
      return null;
    }

    return (
      <>
        <h4 className="mb-4 dark:text-slate-400">Last Payments</h4>
        <div className="overflow-x-auto">
          <SimpleTable>
            <SimpleTable.Head className="dark:bg-slate-700">
              <SimpleTable.HeadCell className="dark:bg-slate-700">
                Issue Date
              </SimpleTable.HeadCell>

              <SimpleTable.HeadCell className="dark:bg-slate-700">
                Amount
              </SimpleTable.HeadCell>
              <SimpleTable.HeadCell className="dark:bg-slate-700">
                Status
              </SimpleTable.HeadCell>
            </SimpleTable.Head>
            <SimpleTable.Body className="divide-y">
              {vendor?.outgoingPayments?.lastBills.slice(0, 3).map((bill) => (
                <SimpleTable.Row className="bg-white !text-slate-400 dark:border-slate-700 dark:bg-slate-900/30">
                  <SimpleTable.Cell>{bill.billDate}</SimpleTable.Cell>
                  <SimpleTable.Cell>
                    {bill.amount} {bill.token}
                  </SimpleTable.Cell>
                  <SimpleTable.Cell>
                    {bill.paymentStatus === 'paid' ? (
                      <Badge className="inline !bg-emerald-600 !text-white">
                        Paid
                      </Badge>
                    ) : (
                      <Badge className="inline !bg-slate-500 !text-white">
                        Unpaid
                      </Badge>
                    )}
                  </SimpleTable.Cell>
                </SimpleTable.Row>
              ))}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
      </>
    );
  };

  const renderInner = () => {
    const formNamesMap = {
      fw8ben_form: 'W-8BEN Form',
      fw9_form: 'W-9 Form',
    };

    return (
      <>
        <div className={'pt-4 pb-4'}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <dl>
              <dt className="mb-2 leading-none text-gray-500 dark:text-slate-400">
                Email Address
              </dt>
              <dd className="mb-4 text-gray-900 sm:mb-5 dark:text-white">
                {vendor?.baseInfo.email}
              </dd>

              {!!vendor?.baseInfo.primaryAddress && (
                <>
                  <dt className="mb-2 leading-none text-gray-500 dark:text-slate-400">
                    Address
                  </dt>
                  <dd className="mb-4  text-gray-900 sm:mb-5 dark:text-white">
                    {resolveAddress()}
                  </dd>
                </>
              )}

              {!!vendor?.vendorInfo?.taxForm && (
                <>
                  <dt className="mb-2 leading-none text-gray-500 dark:text-slate-400">
                    {formNamesMap[vendor?.vendorInfo?.taxForm?.formType] ||
                      'Form'}
                  </dt>
                  <dd className="mb-4 underline text-gray-900 sm:mb-5 dark:text-white">
                    <Link
                      className={'!text-slate-300'}
                      disabled={isDownloading}
                      onClick={_handleDownloadVendorTaxForm}>
                      <Flex gap={'small'}>
                        <ArrowDownTrayIcon className="w-4" aria-hidden="true" />
                        <span>Download</span>
                      </Flex>
                    </Link>
                  </dd>
                </>
              )}
            </dl>
            <dl>
              <dt className="mb-2 flex items-center leading-none text-gray-500 dark:text-slate-400">
                <BriefcaseIcon className="w-4 mr-1" />
                Type
              </dt>
              <dd className="mb-4  text-gray-900 sm:mb-5 dark:text-white">
                {resolveVendorType()}
              </dd>

              <dt className="mb-2 flex items-center leading-none text-gray-500 dark:text-slate-400">
                <CurrencyDollarIcon className="w-4 mr-1" /> Total Paid
              </dt>
              <dd className="mb-4  text-gray-900 sm:mb-5 dark:text-white">
                ${' '}
                {formatDollarAmount(
                  vendor?.outgoingPayments?.totalPaidUsd || 0,
                  {
                    maximumFractionDigits: 2,
                  }
                )}
              </dd>
            </dl>
          </div>

          {renderPaymentDetailsTable()}
          {renderLastBillsTable()}
        </div>

        <Flex justify={'flex-end'}>
          <Button color="slate" onClick={onClose}>
            Close
          </Button>
        </Flex>
      </>
    );
  };

  return (
    <AModal
      width={620}
      centered
      title={resolveName(vendor)}
      open={!!userId}
      destroyOnClose
      loading={isFetching}
      footer={null}
      onCancel={onClose}>
      {renderInner()}
    </AModal>
  );
};
