import * as amplitude from '@amplitude/analytics-browser';
import { Cog6ToothIcon, HomeIcon } from '@heroicons/react/24/outline';
import { useCurrentUser } from 'entities/user/model';
import NotFoundPage from 'pages/404';
import OrganizationSettingsPage from 'pages/OrganizationSettingsPage';
import UserSettingsPage from 'pages/UserSettingsPage';
import { BillsDashboard } from 'features/bill/BillsPage';
import BillReconcilePage from 'pages/bill/BillReconcilePage';
import BillingLinksPage from 'pages/bill/BillingLinksPage';
import { ExpensesPage } from 'features/organization/expenses/ExpensesPage';
import { VendorsPage } from 'features/organization/vendors/VendorsPage';
import { InvoicesDashboard } from 'features/invoice/InvoicesPage';
import { DashBoardPage } from 'features/dashboard/DashBoardPage';
import PageCreateInvoice from 'pages/invoice/CreateInvoicePage';
import MyClientsPage from 'features/organization/clients/MyClients.Page';
import InvoiceEditPage from 'pages/invoice/InvoiceEditPage';
import CreateLinkPage from 'pages/link/CreateLinkPage';
import { PaymentLinkPage } from 'pages/link/PaymentLinkPage';
import { useMemo, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ROLES } from 'shared/hooks';
import { Sidebar } from 'shared/ui//Sidebar';
import { Header } from 'shared/ui/Header';
import MainLayout from 'shared/ui/layouts/MainLayout';
import PaymentIn from 'shared/images/paymentIn.svg';
import PaymentOut from 'shared/images/paymentOut.svg';
import {
  MenuItemBadge,
  BillsSubMenuItemBadge,
  VendorsSubMenuItemBadge,
  ExepesesSubMenuItemBadge,
} from 'features/userNotifications/MenuItemBadge';
import { CashOutline } from '../../shared/ui/icons/ArrowsRepeat';
import { TransfersPage } from '../../features/transfers/TransfersPage';

const ALL_ROLLES = [ROLES.ADMIN, ROLES.MEMBER, ROLES.ACCOUNTANT];
const LIMITED_ACCESS_ROLES = [ROLES.ADMIN, ROLES.MEMBER];

const navigation_links = [
  {
    name: 'Home',
    icon: HomeIcon,
    current: false,
    roles: ALL_ROLLES,
    href: '/dashboard',
  },
  {
    name: 'Transfers',
    icon: CashOutline,
    current: false,
    roles: ALL_ROLLES,
    href: '/dashboard/transfers',
  },
  {
    name: 'Payments In',
    icon: () => (
      <span
        style={{ width: '24px', display: 'flex', justifyContent: 'center' }}>
        <img src={PaymentIn} alt="" aria-hidden="true" />
      </span>
    ),
    current: false,
    children: [
      {
        name: 'Invoices',
        href: '/dashboard/invoices',
        current: false,
      },
      {
        name: 'Clients',
        href: '/dashboard/clients',
        current: false,
        roles: LIMITED_ACCESS_ROLES,
      },
    ],
    roles: ALL_ROLLES,
  },
  {
    name: 'Payments Out',
    notificationBadge: MenuItemBadge,
    icon: () => (
      <span
        style={{ width: '24px', display: 'flex', justifyContent: 'center' }}>
        <img src={PaymentOut} alt="" aria-hidden="true" />
      </span>
    ),
    current: false,
    children: [
      {
        name: 'Bills',
        href: '/dashboard/bills/allbills',
        current: false,
        notificationBadge: BillsSubMenuItemBadge,
      },
      {
        name: 'Recipients',
        href: '/dashboard/bills/vendors',
        current: false,
        roles: LIMITED_ACCESS_ROLES,
        notificationBadge: VendorsSubMenuItemBadge,
      },
      {
        name: 'Expenses',
        href: '/dashboard/bills/expenses',
        current: false,
        roles: LIMITED_ACCESS_ROLES,
        notificationBadge: ExepesesSubMenuItemBadge,
      },
      {
        name: 'Pay Links',
        href: '/dashboard/bills/links',
        current: false,
        roles: LIMITED_ACCESS_ROLES,
      },
    ],
    roles: ALL_ROLLES,
  },
  {
    name: 'Settings',
    icon: Cog6ToothIcon,
    current: false,
    children: [
      {
        name: 'Organization',
        href: '/dashboard/company',
        current: false,
      },
      {
        name: 'Profile',
        href: '/dashboard/settings/user',
        current: false,
      },
    ],
    roles: ALL_ROLLES,
  },
];
const userNavigation = [
  {
    name: 'Organization Settings',
    href: '/dashboard/company',
  },
  { name: 'Sign out', href: '#' },
];

export default function PrivateRoutes() {
  const location = useLocation();
  const { data: userData } = useCurrentUser();

  // add amplitude tracking for user navigation
  useEffect(() => {
    const userEmail = userData?.email;

    if (userEmail) {
      amplitude.setUserId(userEmail);
    }
  }, [userData?.email]);

  const navigation = useMemo(() => {
    const currentLocation = location.pathname;
    const userRole = userData?.selected_organization?.userRole;

    return navigation_links
      .filter(({ roles = [] }) => (userRole ? roles.includes(userRole) : true))
      .map((item) => ({
        ...item,
        children: item.children
          ?.filter(({ roles = ALL_ROLLES }) =>
            userRole ? roles.includes(userRole) : true
          )
          .map((child) => ({
            ...child,
            current:
              child.name === 'Organization'
                ? child.href && currentLocation.startsWith(child.href)
                : child.href === currentLocation,
          })),
        current: !!item.children
          ? item.children?.some((child) =>
              child.name === 'Organization'
                ? child.href && currentLocation.startsWith(child.href)
                : child.href === currentLocation
            )
          : item.href === currentLocation,
      }));
  }, [userData?.selected_organization?.userRole, location.pathname]);

  return (
    <MainLayout
      header={<Header navigation={userNavigation} />}
      sidebar={<Sidebar navigation={navigation} />}>
      <Routes>
        <Route path="/" element={<DashBoardPage />} />
        <Route path="/transfers" element={<TransfersPage />} />
        {/* ************************** INVOICES ROUTES ***************************/}
        <Route path="/invoices" element={<InvoicesDashboard />} />
        <Route path="/create-invoice" element={<PageCreateInvoice />} />
        <Route path="/invoices/edit" element={<InvoiceEditPage />} />
        <Route path="/clients" element={<MyClientsPage />} />

        {/* ************************** LINKS ROUTES ***************************/}
        <Route path="/bills/links/:id" element={<PaymentLinkPage />} />
        <Route path="/bills/links/create-link" element={<CreateLinkPage />} />

        {/* ************************** BILLS ROUTES ***************************/}
        <Route path="/bills/links" element={<BillingLinksPage />} />
        <Route path="/bills/allbills" element={<BillsDashboard />} />
        <Route path="/bills/expenses" element={<ExpensesPage />} />
        <Route path="/bills/vendors" element={<VendorsPage />} />
        <Route path="/bills/accounting/*" element={<BillReconcilePage />} />

        {/* ************************** COMPANY ROUTES ***************************/}
        <Route path="/company/*" element={<OrganizationSettingsPage />} />

        {/* ************************** USER SETTINGS ROUTES ***************************/}
        <Route path="/settings/user" element={<UserSettingsPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </MainLayout>
  );
}
