import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCurrentUser, useCurrentUserDetails } from 'entities/user/model';
import { useCallback } from 'react';

const ORG_RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/organization';
const USER_RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/user';

async function createUserInOrganization(token, user) {
  try {
    const response = await axios.post(
      `${ORG_RESOURCE_URL}/add-users`,
      {
        data: user.data,
        email: user.email,
        role: user.role,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function fetchOrganizationUsers(token, userId, organizationId) {
  try {
    const response = await axios.get(
      `${ORG_RESOURCE_URL}/get-organization-users?id=${userId}&organization_id=${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  } catch (error) {
    throw error;
  }
}

async function removeUserFromOrganization(token, data, id, organizationId) {
  try {
    const response = await axios.post(
      `${ORG_RESOURCE_URL}/delete-user-from-org`,
      {
        user_data: data,
        user_id_to_delete: id,
        organizationId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function changeUserRoleInOrganization(
  token,
  data,
  userId,
  organizationId
) {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.put(
      `${ORG_RESOURCE_URL}/update-user-role/${userId}`,
      {
        organizationId: organizationId,
        role: data.role,
        emailNotifications: data.emailNotifications,
      },
      { headers }
    );

    if (response.data.message === 'User permissions updated successfully') {
      return response.data;
    } else {
      throw new Error('Error updating user permissions');
    }
  } catch (error) {
    throw error;
  }
}

async function checkUserExists(email) {
  try {
    const response = await axios.get(`${USER_RESOURCE_URL}/exists`, {
      params: {
        email: email,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateUser(token, values) {
  try {
    const response = await axios.put(
      `${USER_RESOURCE_URL}/update-user`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function changeUserPassword(data) {
  try {
    return await axios.post(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      data,
      {
        headers: {
          'content-type': 'application/json',
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export const useOrganizationUserActions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data } = useCurrentUser();
  const { getCurrentOrganizationId } = useCurrentUserDetails();

  const createUserAction = useCallback(
    async (user) => {
      if (!data.nickname) {
        return null;
      }

      const token = await getAccessTokenSilently();
      return createUserInOrganization(token, user);
    },
    [data]
  );

  const fetchUsersAction = useCallback(async () => {
    if (!data.selected_organization) {
      return null;
    }

    const token = await getAccessTokenSilently();
    return fetchOrganizationUsers(
      token,
      data.user_id,
      data?.selected_organization?._id
    );
  }, [data]);

  const removeUserAction = useCallback(
    async (id) => {
      if (typeof data !== 'object' || typeof id !== 'string') {
        return null;
      }

      const token = await getAccessTokenSilently();
      return removeUserFromOrganization(
        token,
        data,
        id,
        getCurrentOrganizationId()
      );
    },
    [data]
  );

  const changeUserRoleAction = useCallback(
    async ({ newData, user, selectedOrganizationId }) => {
      if (typeof newData !== 'object') {
        return null;
      }

      const token = await getAccessTokenSilently();
      return changeUserRoleInOrganization(
        token,
        newData,
        user.id,
        selectedOrganizationId
      );
    },
    [data]
  );

  return {
    createUserAction,
    fetchUsersAction,
    removeUserAction,
    changeUserRoleAction,
  };
};

export const useUserActions = () => {
  const { getAccessTokenSilently } = useAuth0();

  const updateUserAction = useCallback(
    async (values) => {
      const token = await getAccessTokenSilently();
      return updateUser(token, values);
    },
    [getAccessTokenSilently]
  );

  const userEmailExistsAction = useCallback(async (email) => {
    if (typeof email !== 'string') {
      return null;
    }

    return checkUserExists(email);
  }, []);

  const changeUserPasswordAction = useCallback(
    async (data) => changeUserPassword(data),
    []
  );

  return {
    updateUserAction,
    userEmailExistsAction,
    changeUserPasswordAction,
  };
};
