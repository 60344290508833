import { CheckWalletAmlWidget } from './CheckWalletAmlWidget';
import { CheckWalletInteractionWidget } from './CheckWalletInteractionWidget';
import { EnsWidget } from './EnsWidget';

export function TransactionValidation({ bill, address, isConnected }) {
  return (
    <div className="text-center">
      <div className="mb-3 text-base font-semibold leading-6 text-slate-300">
        Transaction Validation
      </div>

      <CheckWalletInteractionWidget
        address={address}
        bill={bill}
        isConnected={isConnected}
      />

      <CheckWalletAmlWidget bill={bill} />

      <EnsWidget walletAddress={bill?.walletAddress} />

      <div className="my-4 border-b border-slate-500"></div>
    </div>
  );
}
