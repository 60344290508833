import { useAccount } from 'wagmi';
import { erc20Abi } from 'viem';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SafeApiKit from '@safe-global/api-kit';
import Safe, { EthersAdapter } from '@safe-global/protocol-kit';
import { ethers } from 'ethers';
import erc20TokensData from 'shared/constants/erc20TokensData';
import { useEthersSigner } from './useEtherSigner';
import {
  ETHEREUM_CHAIN_ID,
  CHAIN_ID_TO_TYPE,
  POLYGON_CHAIN_ID,
  TOKEN_TYPES,
  ARBITRUM_CHAIN_ID,
  BASE_CHAIN_ID,
  OPTIMISM_CHAIN_ID,
} from 'features/bill/lib';

const ConnectorTypes = {
  Safe: 'safe',
};

const chainToTxServiceUrl = {
  [ETHEREUM_CHAIN_ID]: 'https://safe-transaction-mainnet.safe.global',
  [POLYGON_CHAIN_ID]: 'https://safe-transaction-polygon.safe.global',
  [ARBITRUM_CHAIN_ID]: 'https://safe-transaction-arbitrum.safe.global',
  [BASE_CHAIN_ID]: 'https://safe-transaction-base.safe.global',
  [OPTIMISM_CHAIN_ID]: 'https://safe-transaction-optimism.safe.global',
};

const useSafeWallet = () => {
  const { connector, isConnected } = useAccount();

  const _isConnectedWalletSafe = useCallback(
    () => isConnected && connector?.id === ConnectorTypes.Safe,
    [connector, isConnected]
  );

  return {
    isConnectedWalletSafe: _isConnectedWalletSafe,
  };
};

export const useSafeApiKit = () => {
  const signer = useEthersSigner();
  const { chain } = useAccount();
  return useMemo(
    () =>
      !!chain?.id && !!signer
        ? new SafeApiKit({
            txServiceUrl: chainToTxServiceUrl[chain?.id],
            ethAdapter: new EthersAdapter({
              ethers,
              signerOrProvider: signer,
            }),
          })
        : undefined,
    [signer, chain]
  );
};

const _parepareSafeTransactionData = (chainId, signer, recipient) => {
  const { to, token, amount } = recipient;

  if (token === TOKEN_TYPES.ETH) {
    // For ETH to is actial addres and value is amount but data is empty '0x'
    return {
      to,
      value: ethers.utils.parseEther(amount),
      data: '0x',
    };
  }

  const erc20TokenConfig = erc20TokensData[token];
  const contract = new ethers.Contract(
    erc20TokenConfig.erc20Address[CHAIN_ID_TO_TYPE[chainId]],
    erc20Abi,
    signer
  );

  return {
    to: contract.address,
    value: '0',
    data: contract.interface.encodeFunctionData('transfer', [
      to,
      ethers.utils.parseUnits(amount, erc20TokenConfig.decimalsInteger),
    ]),
  };
};

const _initAndSendSafeTransferTransaction = async ({
  safeContractAddress,
  signer,
  chainId,
  recipients,
}) => {
  const safeApiKit = new SafeApiKit({
    txServiceUrl: chainToTxServiceUrl[chainId],
    ethAdapter: new EthersAdapter({
      ethers,
      signerOrProvider: signer,
    }),
  });
  const safeSdk = await Safe.create({
    safeAddress: safeContractAddress,
    ethAdapter: new EthersAdapter({
      ethers,
      signerOrProvider: signer,
    }),
  });

  let nextNonce;

  try {
    nextNonce = await safeApiKit.getNextNonce(safeContractAddress);
  } catch (err) {
    console.log(err);
  }

  const safeTransaction = await safeSdk.createTransaction({
    options:
      nextNonce !== undefined
        ? {
            nonce: nextNonce,
          }
        : undefined,
    safeTransactionData: recipients.map((recipient) =>
      _parepareSafeTransactionData(chainId, signer, recipient)
    ),
  });
  const safeTxHash = await safeSdk.getTransactionHash(safeTransaction);
  const signature = await safeSdk.signTransactionHash(safeTxHash);
  const senderAddress = await signer.getAddress();
  await safeApiKit.proposeTransaction({
    safeAddress: await safeSdk.getAddress(),
    safeTransactionData: safeTransaction.data,
    safeTxHash,
    senderAddress,
    senderSignature: signature.data,
  });

  return {
    safeTxHash,
  };
};

const useSafeTransaction = ({ safeContractAddress }) => {
  const { chain } = useAccount();
  const signer = useEthersSigner();
  const initSafeTransferTransactionMutation = useMutation({
    mutationKey: ['init-send-transaction'],
    mutationFn: ({ recipients }) =>
      _initAndSendSafeTransferTransaction({
        signer,
        safeContractAddress,
        chainId: chain?.id,
        recipients,
      }),
  });

  return {
    initSafeTransferTransaction:
      initSafeTransferTransactionMutation.mutateAsync,
    isTransferTransactionLoading: initSafeTransferTransactionMutation.isPending,
  };
};

const useApproveSafeTransaction = (safeTrxHash, safeContractAddress) => {
  const queryClient = useQueryClient();
  const signer = useEthersSigner();
  const apiKit = useSafeApiKit();
  const mutation = useMutation({
    mutationKey: ['approve-save-transaction', safeTrxHash],
    mutationFn: async () => {
      const protocolKit = await Safe.create({
        safeAddress: safeContractAddress,
        ethAdapter: new EthersAdapter({
          ethers,
          signerOrProvider: signer,
        }),
      });
      const signature = await protocolKit.signTransactionHash(safeTrxHash);
      await apiKit.confirmTransaction(safeTrxHash, signature.data);
      await queryClient.invalidateQueries({
        queryKey: ['bills-pending-chain-actions'],
      });
    },
  });

  return {
    approveSafeTransaction: mutation.mutateAsync,
    approveSafeTransactionLoading: mutation.isPending,
  };
};

export { useSafeWallet, useSafeTransaction, useApproveSafeTransaction };
