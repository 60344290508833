import axios from 'axios';
import SlideOver from 'shared/ui/SlideOver';
import { Spinner } from 'shared/ui/Spinner';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser } from 'entities/user/model';
import { WithOrgPermissions } from 'shared/ui/WithOrgPermissions';
import { DetailSection } from 'shared/components/DetailSection';
import { useDialog } from 'shared/components/Dialog';
import { toast } from 'react-toastify';
import { tokenToImage } from 'shared/lib';
import classNames from 'classnames';
import { Badge } from 'flowbite-react';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/bills/recurrence';

const fetchStandingOrder = async (getToken, organizationId, orderId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/${orderId}`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

const cancelStandingOrder = async (getToken, organizationId, orderId) => {
  const token = await getToken();

  try {
    const response = await axios.put(
      `${RESOURCE_URL}/${orderId}`,
      {
        status: 'CANCELLED',
      },
      {
        params: {
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useStandingOrderDetails = (orderId) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: userData } = useCurrentUser();
  const organizationId = userData?.selected_organization?._id;
  const { data: standingOrder, isLoading: isOrderLoading } = useQuery({
    queryKey: ['standing-order-detail', orderId],
    queryFn: () => fetchStandingOrder(getToken, organizationId, orderId),
    enabled: !!orderId && !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  const cancelOrderMutation = useMutation({
    mutationKey: ['cancel-order'],
    mutationFn: (id) =>
      cancelStandingOrder(getToken, organizationId, id).then(() => {
        return Promise.all([
          queryClient.resetQueries({ queryKey: ['standing-order-detail', id] }),
          queryClient.resetQueries({ queryKey: ['standing-orders'] }),
        ]);
      }),
  });

  return {
    standingOrder,
    isOrderLoading,
    cancelOrder: cancelOrderMutation.mutateAsync,
  };
};

const paymentStatusCopyMap = {
  paid: 'Paid',
  unpaid: 'Not Paid',
  processingPayment: 'Processing Payment',
};

export const StandingOrderDetailsSlideOver = ({ show, orderId, onClose }) => {
  const { standingOrder, isOrderLoading, cancelOrder } =
    useStandingOrderDetails(orderId);
  const dialog = useDialog();

  const handleClose = () => {
    onClose();
  };

  const handleCancel = async () => {
    const confirmationText =
      'Are you sure you want to cancel this bill standing order? ';

    dialog.confirm({
      title: 'Cancel Standing Order',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await cancelOrder(orderId);
          toast.success('Bill Order Cancelled!');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return (
    <SlideOver show={show} onClose={handleClose}>
      {standingOrder && !isOrderLoading ? (
        <>
          <SlideOver.Header>
            <SlideOver.Title>
              <h2>Standing Order Overview</h2>
            </SlideOver.Title>
          </SlideOver.Header>

          <div className="relative mb-40 flex-1 space-y-6 px-6 pt-6">
            <DetailSection header="General">
              <DetailSection.Item term="Name">
                {standingOrder?.receiverName}
              </DetailSection.Item>

              <DetailSection.Item term="Email">
                {standingOrder?.receiverEmail}
              </DetailSection.Item>

              <DetailSection.Item term="Amount">
                {tokenToImage(standingOrder?.token)}
                <span>
                  {standingOrder?.amount} {standingOrder?.token}
                </span>
              </DetailSection.Item>

              <DetailSection.Item term="Network">
                <span>{standingOrder?.network}</span>
              </DetailSection.Item>

              <DetailSection.Item term="Status">
                <div className="flex flex-col">
                  <Badge
                    className={classNames('inline', {
                      '!bg-slate-700 !text-emerald-400':
                        standingOrder.status === 'ACTIVE',
                      '!bg-slate-700 !text-indigo-300':
                        standingOrder.status === 'EXPIRED',
                      '!bg-slate-700 !text-slate-200':
                        standingOrder.status === 'CANCELLED',
                    })}>
                    {standingOrder.status === 'ACTIVE' && 'Active'}
                    {standingOrder.status === 'EXPIRED' && 'Fulfilled'}
                    {standingOrder.status === 'CANCELLED' && 'Cancelled'}
                  </Badge>
                </div>
              </DetailSection.Item>
            </DetailSection>

            <DetailSection
              header={`Issued Bills (${standingOrder.generatedBillsCount} out of ${standingOrder.expectedBillsCount})`}>
              <table className="w-[100%] bg-[#232e40]">
                <thead className="text-sm  text-gray-300">
                  <tr className="">
                    <th className="py-2 pl-3 text-left font-normal">
                      Issue Date
                    </th>
                    <th className="text-center font-normal">Due Date</th>
                    <th className="pr-3 font-normal">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {standingOrder.bills.map((bill) => (
                    <tr
                      key={bill?._id?.toString()}
                      className=" text-sm text-gray-300">
                      <td className=" py-2 pl-3 text-left font-normal">
                        {bill.billDate}
                      </td>
                      <td className="text-center">{bill.dueDate}</td>
                      <td className=" pr-3">
                        <span
                          className={classNames(
                            'rounded  px-2 py-0.5 text-white',
                            {
                              'bg-emerald-600': bill.paymentStatus === 'paid',
                              'bg-slate-500': bill.paymentStatus === 'unpaid',
                            }
                          )}>
                          {paymentStatusCopyMap[bill.paymentStatus]}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </DetailSection>

            {standingOrder.status !== 'CANCELLED' && (
              <WithOrgPermissions>
                <DetailSection header="Other Actions">
                  <DetailSection.Item term="Cancel Order">
                    <button
                      onClick={handleCancel}
                      type="button"
                      className="font-medium text-rose-500  hover:text-rose-400">
                      Cancel
                    </button>
                  </DetailSection.Item>
                </DetailSection>
              </WithOrgPermissions>
            )}
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    </SlideOver>
  );
};
