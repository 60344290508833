import GeneratePaymentLinkForm from "widgets/Bills/GeneratePaymentLinkForm";

const CreateLinkPage = () => {
  return (
    <div className="create-pay-link-page-container mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <GeneratePaymentLinkForm />
    </div>
  );
};

export default CreateLinkPage;
