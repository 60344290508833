import { useRef, useMemo, useState } from 'react';
import { ModalStyles } from './PaymentModal.ModalStyles';
import { useAccount, useBalance } from 'wagmi';
import PayETHButton from 'widgets/Payments/PayETHButton';
import PayERC20Button from 'widgets/Payments/PayERC20Button';
import { ConfirmInWalletOverlay } from './PaymentModal.ConfirmInWalletOverlay';
import { SuccessConfirmation } from './PaymentModal.SuccessConfirmation';
import { ConfirmationSection } from './PaymentModal.ConfirmationSection';
import useExchangeRate from './useExchangeRate';
import { WarningsSection } from './PaymentModal.WarningsSection';
import { PaymentInformation } from './PaymentModal.PaymentInformation';
import erc20TokensData from 'shared/constants/erc20TokensData';
import { useExpenses } from 'features/organization/expenses/useExpenses';

const NETWORK_TO_CHAIN_ID = {
  Ethereum: 1,
  Polygon: 137,
  Arbitrum: 42161,
  Optimism: 10,
  Base: 8453,
};

export const ExpensePaymentModal = ({ show, onClose, expense }) => {
  const cancelButtonRef = useRef(null);

  const { updateExpense, refreshExpenses, isExpenseUpdating } = useExpenses();
  const { chain } = useAccount();
  const { address } = useAccount();
  const [transactionInitiated, setTransactionInitiated] = useState(false);
  const [waitingForUserApproval, setWaitingForUserApproval] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const paymentNetwork =
    expense.paymentNetwork.charAt(0).toUpperCase() +
    expense.paymentNetwork.slice(1);
  const paymentCurrency = expense.paymentCurrency;
  const walletAddress = expense.recipientPaymentDetails?.cryptoWallet?.address;
  const paymentAmount = Number(expense.totalAmount);

  const chainId = useMemo(
    () => NETWORK_TO_CHAIN_ID[paymentNetwork],
    [paymentNetwork]
  );
  const tokenAddress =
    erc20TokensData[paymentCurrency]?.erc20Address?.[paymentNetwork];
  const tokenName = erc20TokensData[paymentCurrency]?.name
    ? erc20TokensData[paymentCurrency]?.name
    : 'Unknown';
  const usdValue = useExchangeRate(
    paymentCurrency.toLowerCase(),
    paymentAmount
  );
  const { data: tokenBalance } = useBalance({
    address,
    chainId,
    token: paymentCurrency !== 'ETH' ? tokenAddress : undefined,
  });

  const paymentProps = useMemo(() => {
    const balance = tokenBalance?.formatted;
    return {
      walletAddress,
      network: paymentNetwork,
      token: paymentCurrency,
      amount: paymentAmount,
      balance,
      usdValue: usdValue.toFixed(2),
    };
  }, [
    paymentNetwork,
    walletAddress,
    paymentAmount,
    paymentCurrency,
    tokenBalance,
    usdValue,
  ]);

  const handleConfirmationChange = (confirmed) => {
    setIsConfirmed(confirmed);
  };

  const _handleClose = () => {
    if (!isExpenseUpdating && !waitingForUserApproval) {
      onClose();
    }
  };

  const _onReceiveTransactionHash = (txHash) => {
    setWaitingForUserApproval(false);
    setTransactionHash(txHash);

    updateExpense({
      expenseId: expense.id,
      txHash,
      paymentStatus: 'paid',
    }).finally(() => {
      refreshExpenses();
      setTransactionSuccessful(true);
    });
  };

  const _onUserApproval = (showWalletConfirmation) => {
    setWaitingForUserApproval(showWalletConfirmation);
  };

  // Renderers

  const _renderPayButton = () => {
    return expense?.paymentCurrency === 'ETH' ? (
      <PayETHButton
        addressTo={paymentProps.walletAddress}
        ethAmount={paymentProps.amount}
        network={paymentNetwork}
        disabled={!isConfirmed}
        payButtonStyles="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onUserApproval={_onUserApproval}
        setTransactionHash={_onReceiveTransactionHash}
        setTransactionInitiated={setTransactionInitiated}
      />
    ) : (
      <PayERC20Button
        addressTo={paymentProps.walletAddress}
        amountERC20={paymentProps.amount}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
        network={paymentNetwork}
        disabled={!isConfirmed}
        payButtonStyles="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onUserApproval={_onUserApproval}
        setTransactionHash={_onReceiveTransactionHash}
        setTransactionInitiated={setTransactionInitiated}
      />
    );
  };

  return (
    <ModalStyles
      show={show}
      cancelButtonRef={cancelButtonRef}
      onClose={_handleClose}>
      <ConfirmInWalletOverlay show={waitingForUserApproval} />
      <SuccessConfirmation
        show={transactionSuccessful}
        network={paymentNetwork}
        txHash={transactionHash}
        onClose={_handleClose}
        successLinkText="Expense Details"
        successDescription="We store Transaction Hash in Expense Details."
      />

      <div>
        <PaymentInformation {...paymentProps} />

        <WarningsSection
          connectedChain={chain}
          payeeNetwork={paymentNetwork}
          payeeAmount={paymentAmount}
          payeeToken={paymentCurrency}
          payeeWalletAddress={walletAddress}
          paymentProps={paymentProps}
          transactionInitiated={transactionInitiated}
          showPotentialDuplicate={false}
        />
        <ConfirmationSection
          isConfirmed={isConfirmed}
          setIsConfirmed={setIsConfirmed}
          onConfirmationChange={handleConfirmationChange}
        />
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <div className="w-full text-left">{_renderPayButton()}</div>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:order-first sm:mt-0"
          onClick={() => {
            _handleClose();
          }}
          ref={cancelButtonRef}>
          Cancel
        </button>
      </div>
    </ModalStyles>
  );
};
