import { useQuery } from '@tanstack/react-query';
import { useInvoiceActions } from './actions';
import { QUERY_KEY } from './constants';
import { useCurrentUser } from 'entities/user/model';

export const useInvoices = () => {
  const { fetchAllAction } = useInvoiceActions();
  const { data } = useCurrentUser();

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchAllAction,
    enabled: !!data.nickname,
  });
};

export const useInvoice = (invoiceId) => {
  const { fetchAction } = useInvoiceActions();

  return useQuery({
    queryKey: [QUERY_KEY, invoiceId],
    queryFn: () => fetchAction(invoiceId),
    initialData: undefined,
    enabled: !!invoiceId,
  });
};
