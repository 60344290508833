import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOrganizationUserActions } from './actions';
import { QUERY_KEY } from './constants';

export const useUserMutations = () => {
  const queryClient = useQueryClient();
  const { createUserAction, removeUserAction, changeUserRoleAction } =
    useOrganizationUserActions();

  const createUser = useMutation({
    mutationFn: createUserAction,
    onError: (error) => {
      console.error('Error adding user: ', error);
    },
    onSuccess: () => {
      console.log('User added successfully');
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const removeUser = useMutation({
    mutationFn: removeUserAction,
    onError: (error) => {
      console.error('Error deleting user: ', error);
    },
    onSuccess: () => {
      console.log('User deleted successfully');
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const changeUserRole = useMutation({
    mutationFn: changeUserRoleAction,
    onError: (error) => {
      console.error('Error updating user role: ', error);
    },
    onSuccess: () => {
      console.log('User role updated successfully');
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  return { createUser, removeUser, changeUserRole };
};
