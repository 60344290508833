import React from 'react';
import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { useCurrentUserDetails, useCurrentUser } from 'entities/user/model';
import { useBill, useBillMutations } from 'entities/bill/model';
import { Spinner } from 'shared/ui/Spinner';
import { useLocation } from 'react-router-dom';
import BillReconcileForm, {
  createBillReference,
} from 'widgets/Bills/BillReconcileForm';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';

const XERO_ZERO_TAX_IDS = ['NONE'];

export const createBillRecociliationPayload = (
  bill,
  companyAccountingData,
  reconcileInput
) => {
  const { baseCurrency, supportedCurrencies } = companyAccountingData;
  const {
    lineItemTaxAmount,
    lineItemTaxId,
    lineItemTaxName,
    newSupplierName,
    newSupplierEmail,
    newSupplier,
    supplierId,
    supplierName,
    nominalAccountId,
    nominalAccountName,
  } = reconcileInput;

  const determineCurrency = () => {
    if (
      bill.token === baseCurrency ||
      supportedCurrencies.some((currency) => currency === bill.token)
    ) {
      return bill.token;
    }

    return 'USD';
  };

  return {
    lineItemTaxAmount,
    lineItemTaxId,
    lineItemTaxName,
    newSupplierName,
    newSupplierEmail,
    newSupplier,
    supplierId,
    supplierName,
    nominalAccountId,
    nominalAccountName,

    // Get from bill or other source

    id: bill.uuid,
    reference: createBillReference(bill),
    baseCurrency: baseCurrency,
    issueDate: bill.billDate,
    dueDate: bill.dueDate,
    lineItemDescription: bill.services,
    lineItemUnitAmount: bill.subtotal,
    lineItemQuantity: 1,
    lineItemSubTotal: Number(bill.subtotal),
    lineItemTotal:
      bill.token === 'ETH' ? Number(bill.dollarAmount) : Number(bill.amount),
    withholdingTaxDescription: 'Withholding Tax',
    withholdingTaxRate: 0,
    status: 'Draft',
    currency: determineCurrency(),
    subTotal: Number(bill.subtotal),
    taxAmount:
      bill.token === 'ETH'
        ? new BigNumber(bill.dollarAmount)
            .minus(new BigNumber(bill.subtotal))
            .toNumber()
        : new BigNumber(bill.amount)
            .minus(new BigNumber(bill.subtotal))
            .toNumber(),
    totalAmount:
      bill.token === 'ETH' ? Number(bill.dollarAmount) : Number(bill.amount),
  };
};

const _reconcileBill = async (getToken, userDetails, billDetails) => {
  const token = await getToken();

  await axios.post(
    process.env.REACT_APP_SERVER_URL + '/codat/create-bill',
    {
      billDetails,
      userDetails,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const useReconcileBill = (billId) => {
  const { data } = useCurrentUser();
  const { getAccessTokenSilently } = useAuth0();
  const { invalidate: invalidateBills } = useBillMutations();

  const mutation = useMutation({
    mutationKey: ['reconcile-bill', billId],
    mutationFn: (payload) => {
      return _reconcileBill(getAccessTokenSilently, data, payload)
        .then(() => {
          toast.success('Successfully Reconciled Bill', {
            autoClose: 3000,
          });
          invalidateBills();
        })
        .catch(() => {
          toast.error(
            'Was not able to reconcile a Bill, please contact support: pavel@getfractal.xyz',
            {
              autoClose: 6000,
            }
          );
        });
    },
  });

  return {
    reconcile: mutation.mutateAsync,
    isReconciling: mutation.isLoading,
  };
};

const _fetchAccountingData = async (url, token, userId, orgId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/codat/${url}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        user_id: userId,
        selected_organization: orgId,
        organizationId: orgId._id,
      },
    }
  );
  return response.data;
};

const _fetchAllAccountingData = async (getAccessToken, userId, orgId) => {
  const token = await getAccessToken();
  const [
    taxRates,
    suppliers,
    accounts,
    supportedCurrencies,
    baseCurrency,
    customers,
  ] = await Promise.all([
    _fetchAccountingData('get-tax-rates', token, userId, orgId),
    _fetchAccountingData('get-suppliers', token, userId, orgId),
    _fetchAccountingData('get-accounts', token, userId, orgId),
    _fetchAccountingData('get-supported-currencies', token, userId, orgId),
    _fetchAccountingData('get-base-currency', token, userId, orgId),
    _fetchAccountingData('customers', token, userId, orgId),
  ]);
  return {
    taxRates,
    suppliers,
    accounts,
    supportedCurrencies,
    baseCurrency,
    customers: customers?.result,
  };
};

export const useAccountingData = () => {
  const { data: userData, hasAccountingIntegration } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();

  const integrationQuery = useQuery({
    queryKey: ['accounting-integration'],
    queryFn: () =>
      _fetchAllAccountingData(
        getAccessTokenSilently,
        userData.user_id,
        userData.selected_organization
      ),
    enabled:
      userData.user_id &&
      Object.keys(userData.selected_organization || {}).length &&
      hasAccountingIntegration(),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });

  return {
    taxRates: integrationQuery.data?.taxRates || [],
    zeroTaxRate: (integrationQuery.data?.taxRates || []).find(
      (taxRate) =>
        taxRate.status === 'Active' && XERO_ZERO_TAX_IDS.includes(taxRate.id)
    ),
    suppliers: integrationQuery.data?.suppliers || [],
    customers: integrationQuery.data?.customers || [],
    accounts: integrationQuery.data?.accounts || [],
    supportedCurrencies: integrationQuery.data?.supportedCurrencies || [],
    baseCurrency: integrationQuery.data?.baseCurrency || '',
  };
};

export default function BillReconcilePage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('bill_id');
  const { data: bill, isLoading, error } = useBill(id);
  const { taxRates, suppliers, accounts, supportedCurrencies, baseCurrency } =
    useAccountingData();

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-lg text-white">
        We could not find the bill you were looking for.
        <br />
        Please revisit the link that was sent to you.
      </div>
    );
  }

  return (
    bill && (
      <div className="mb-40">
        <div className="mx-auto mb-5 mt-10 max-w-7xl px-4 sm:px-6 md:px-8 "></div>
        <div className="mx-auto max-w-7xl px-4  sm:px-6 md:px-8">
          <div className="">
            <BillReconcileForm
              bill={bill}
              taxRates={taxRates}
              suppliers={suppliers}
              accounts={accounts}
              supportedCurrencies={supportedCurrencies}
              baseCurrency={baseCurrency}
            />
          </div>
        </div>
      </div>
    )
  );
}
