import React, { useEffect } from 'react';
import { Modal, Form, Form as AForm, Input, Select } from 'antd';
import { useMutation } from '@tanstack/react-query';
import {
  useInvalidateOrganizationClients,
  useOrganizationClients,
} from './useClients';
import { Button } from '../../../shared/ui/Button/Button';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { useCurrentUserDetails } from '../../../entities/user/model';
import { isValidEmail } from '../../../shared/lib/string';

const useEditClient = () => {
  const { invalidateOrganizationClients } = useInvalidateOrganizationClients();
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['edit-org-client'],
    mutationFn: async ({
      clientId,
      payload,
    }: {
      clientId: string;
      payload: {
        billingEmail: string;
        organizationId: string;
        invoiceRecipientCCEmails: string[];
      };
    }) => {
      const token = await getAccessTokenSilently();
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/clients/${clientId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await invalidateOrganizationClients();
    },
  });

  return { editClient: mutation.mutateAsync, isLoading: mutation.isPending };
};

export const EditClientModal: React.FC<{
  client: any;
  onClose: any;
}> = ({ client, onClose }) => {
  const { clients } = useOrganizationClients();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const [form] = AForm.useForm();
  const { editClient, isLoading } = useEditClient();

  const _handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue();
        const { billingEmail, invoiceRecipientCCEmails } = formData;
        return editClient({
          clientId: client?.id,
          payload: {
            billingEmail,
            organizationId: getCurrentOrganizationId(),
            invoiceRecipientCCEmails,
          },
        })
          .then(() => {
            onClose();
            toast.success('Successfully updated client details');
          })
          .catch(() => {
            toast.error('Failed to update client details');
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      billingEmail: client?.billingEmail || client?.email,
      invoiceRecipientCCEmails: client?.invoiceRecipientCCEmails || [],
    });
  }, [client]);

  return (
    <Modal
      title={'Edit Client Details'}
      open={!!client}
      destroyOnClose
      loading={isLoading}
      footer={null}
      onCancel={onClose}>
      <Form
        name="basic"
        form={form}
        requiredMark={false}
        style={{
          paddingTop: 20,
        }}
        initialValues={{
          billingEmail: client?.billingEmail || client?.email,
          invoiceRecipientCCEmails: client?.invoiceRecipientCCEmails || [],
        }}
        layout="vertical"
        autoComplete="off">
        <AForm.Item
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(new Error('Please enter valid email'));
                }

                if (!isValidEmail(value)) {
                  return Promise.reject(new Error('Please enter valid email'));
                }

                const existingClients = clients
                  .map((client: any) => [client.email, client.billingEmail])
                  .flat()
                  .filter(
                    (e: string) => e !== (client?.billingEmail || client?.email)
                  )
                  .filter((e: string) => !!e);

                console.log('existingClients', existingClients);

                if (existingClients.some((str: string) => str === value)) {
                  return Promise.reject(
                    new Error(
                      'You already have a client with such billing email'
                    )
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
          name="billingEmail"
          layout="vertical"
          label="Billing Email">
          <Input />
        </AForm.Item>

        <AForm.Item
          name="invoiceRecipientCCEmails"
          layout="vertical"
          rules={[
            {
              validator: (_, value) => {
                if (value.some((v: string) => !isValidEmail(v))) {
                  return Promise.reject(
                    new Error('Please enter valid CC email')
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
          label="CC Recipients"
          help={
            'Enter the email addresses of additional invoice recipients to be CC’d in emails.'
          }>
          <Select
            suffixIcon={null}
            mode="tags"
            style={{ width: '100%' }}
            notFoundContent={null}
            placeholder="Type email and press enter"
          />
        </AForm.Item>

        <div className="!mt-12 flex flex-row-reverse gap-x-4">
          <Button color="indigo" onClick={_handleSubmit}>
            Save Client
          </Button>
          <Button color="slate" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
