import { useAuth0 } from '@auth0/auth0-react';
import { BatchPaymentModalCryptoToCrypto } from 'widgets/Payments/BatchPaymentModal.CryptoToCrypto';
import { useBillsBatchPaymentChecker } from './useBillsBatchPayment';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useBillMutations } from 'entities/bill/model';

export const BATCH_PAYMENT_PROVIDER_TYPES = {
  GnosisSafe: 'gnosisSafe',
  Aragon: 'Aragon',
};

export const BillsTableBatchPayments = ({
  showModal,
  onModalClose,
  onDeselectRows,
  selectedBills,
  paymentProvider,
}) => {
  // TODO: Possible remove since stratgey desides about it
  const { prepareCryptoCryptoBatchItems } =
    useBillsBatchPaymentChecker(selectedBills);
  const { invalidate } = useBillMutations();

  const updateMultisigBillsStatus = useUpdateMultisigBillsStatus();

  const _handlePaymentSuccess = async ({ safeTxHash, aragonProposalId }) => {
    return updateMultisigBillsStatus.mutateAsync({
      safeTxHash,
      aragonProposalId,
      billIds: selectedBills.map((bill) => bill._id),
    });
  };

  const _onClose = () => {
    if (updateMultisigBillsStatus.isSuccess) {
      invalidate();
      onDeselectRows();
    }

    onModalClose();
  };

  return (
    <BatchPaymentModalCryptoToCrypto
      paymentProvider={paymentProvider}
      show={showModal}
      // TODO we work with Payouts
      batchItems={prepareCryptoCryptoBatchItems()}
      onClose={_onClose}
      onPaymentSuccessAsync={_handlePaymentSuccess}
      onPaymentSuccessAsyncLoading={updateMultisigBillsStatus.isPending}
    />
  );
};

const useUpdateMultisigBillsStatus = () => {
  const { getAccessTokenSilently } = useAuth0();
  const res = useMutation({
    mutationKey: ['update-multisig-bills-status'],
    mutationFn: async ({ billIds, safeTxHash, aragonProposalId }) => {
      const token = await getAccessTokenSilently();

      await Promise.all(
        billIds.map((billId) =>
          axios.put(
            process.env.REACT_APP_SERVER_URL + '/bills/update-bill/' + billId,
            {
              multisigUsed: true,
              paymentStatus: 'processingPayment',
              transactionHash: undefined,
              safeTxHash,
              aragonProposalId,
              paymentDate: new Date().toISOString().slice(0, 10),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      );
    },
  });

  return res;
};
