import { useVendors } from './useVendors';
import { useMemo, useState, useEffect, useRef } from 'react';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { EmptyVendors } from './EmptyVendors';
import { Table } from 'shared/ui/Table/TableBlocks';
import { VendorDetailsModal, resolveName } from './VendorDetailsModal';
import {
  useNotDiscardedVendorsNotifications,
  createTableRowStyleVariantsByNotifications,
} from 'features/userNotifications/useUserNotifications';

export const VendorsPage = () => {
  const discardTimerRef = useRef(null);
  const { vendors, isVendorsLoading } = useVendors();
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const { getNotifications, dicardNotifications, getNotificationsByEntityIds } =
    useNotDiscardedVendorsNotifications();

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        cell: ({ row }) => {
          return (
            <span className="font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {resolveName(row.original)}
            </span>
          );
        },
      },
      {
        header: 'Email',
        cell: ({ row }) => row.original.baseInfo?.email,
      },
      {
        header: 'Type',
        cell: ({ row }) =>
          row.original?.employeeInfo?.employeeCode ? 'Employee' : 'Vendor',
      },
      {
        header: 'Total Paid',
        cell: ({ row }) =>
          `$ ${row.original?.outgoingPayments?.totalPaidUsd?.toFixed(2) || 0}`,
      },
    ],
    []
  );

  const handleAddNewVendor = () => {
    console.log('Call');
  };

  const handleRowClick = (row) => {
    setSelectedVendorId(row.userId);

    const notificationsToDiscard = getNotificationsByEntityIds(
      row.userOrgRelIds || []
    );

    if (notificationsToDiscard.length) {
      dicardNotifications(notificationsToDiscard.map(({ id }) => id));
    }
  };

  const handleDeselectVendor = () => {
    setSelectedVendorId(null);
  };

  useEffect(() => {
    discardTimerRef.current = setTimeout(() => {
      discardTimerRef.current = 'fulfilled';
    }, 1000);

    return () => {
      if (discardTimerRef.current === 'fulfilled') {
        const ids = getNotifications().map((n) => n.id);

        if (ids.length) {
          dicardNotifications(ids);
        }
      } else {
        discardTimerRef.current && clearTimeout(discardTimerRef.current);
      }
    };
  }, []);

  if (isVendorsLoading) {
    return <ContentLoadingState />;
  }

  if (vendors.length === 0) {
    return (
      <ContentCotainer>
        <EmptyVendors />
      </ContentCotainer>
    );
  }

  return (
    <ContentCotainer>
      <Table
        title="Vendors and Employees"
        description="Manage all your existing vendors or add a new one"
        // primaryActionText="Add New Vendor"
        onPrimaryAction={handleAddNewVendor}
        onRowClick={handleRowClick}
        data={vendors}
        columns={columns}
        rowStyleVariants={createTableRowStyleVariantsByNotifications(
          vendors.map((vendor) => vendor.userOrgRelIds).flat(),
          getNotifications()
        )}
      />

      <VendorDetailsModal
        userId={selectedVendorId}
        onClose={handleDeselectVendor}
      />
    </ContentCotainer>
  );
};
