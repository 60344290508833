import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback } from 'react';
import { useCurrentUser } from 'entities/user/model';

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/bills';
const RESOURCE_URL_V1 = process.env.REACT_APP_SERVER_URL + '/api/public/bills';

async function createBillFromPaymentRequest(bill) {
  try {
    const response = await axios.post(
      `${RESOURCE_URL}/create-bill-from-payment-request`,
      bill,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function fetchBill(token, id) {
  try {
    const response = await axios.get(`${RESOURCE_URL}/get-bill`, {
      params: { bill_id: id },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateBill(token, billId, params) {
  try {
    const response = await axios.put(
      `${RESOURCE_URL}/update-bill/${billId}`,
      { requestedAt: new Date(), ...params },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function deleteBill(token, billId) {
  try {
    const response = await axios.delete(
      `${RESOURCE_URL}/delete-bill/${billId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function downloadBill(token, billId, resource) {
  try {
    const response = await axios.get(`${RESOURCE_URL}/${resource}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { id: billId },
      responseType: 'blob',
    });

    const file = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    return file;
  } catch (error) {
    throw error;
  }
}

async function downloadPublicInvoice(id, resource) {
  try {
    const response = await axios.get(`${RESOURCE_URL}/download-invoice`, {
      params: { id },
      responseType: 'blob',
    });

    const file = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    return file;
  } catch (error) {
    throw error;
  }
}

async function fetchBillAnalytics(token, params) {
  try {
    const response = await axios.get(`${RESOURCE_URL_V1}/analytics/monthly`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const useBillActions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data } = useCurrentUser();

  const createBillFromPaymentRequestAction = useCallback(async (bill) => {
    return createBillFromPaymentRequest(bill);
  }, []);

  const fetchAction = useCallback(
    async (billId) => {
      const token = await getAccessTokenSilently();
      return fetchBill(token, billId);
    },
    [getAccessTokenSilently]
  );

  const updateAction = useCallback(
    async (billId, params) => {
      const token = await getAccessTokenSilently();
      return updateBill(token, billId, params);
    },
    [getAccessTokenSilently]
  );

  const deleteAction = useCallback(
    async (billId) => {
      const token = await getAccessTokenSilently();
      return deleteBill(token, billId);
    },
    [getAccessTokenSilently]
  );

  const downloadAction = useCallback(
    async (billId, resource = 'download') => {
      if (resource === 'download-invoice') {
        return downloadPublicInvoice(billId, resource);
      }

      const token = await getAccessTokenSilently();
      return downloadBill(token, billId, resource);
    },
    [getAccessTokenSilently]
  );

  const fetchBillAnalyticsAction = useCallback(async () => {
    if (!data.nickname) {
      return;
    }

    const token = await getAccessTokenSilently();
    return fetchBillAnalytics(token, data);
  }, [getAccessTokenSilently]);

  return {
    createBillFromPaymentRequestAction,
    fetchAction,
    updateAction,
    deleteAction,
    downloadAction,
    fetchBillAnalyticsAction,
  };
};
