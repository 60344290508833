import { Dialog, Transition } from '@headlessui/react';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { MobileView } from 'react-device-detect';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export const UpdatesBanner = ({ className }) => {
  return (
    <div
      id="banner"
      tabindex="-1"
      class={twMerge(
        'flex lg:pl-72 z-50 justify-between py-3 px-4 w-full bg-gray-50 border border-b border-gray-200 dark:border-slate-700 dark:bg-slate-800',
        className
      )}>
      <div class="flex items-center mx-auto">
        <p class="text-sm font-medium text-gray-900 md:my-0 dark:text-white">
          <span class="hidden md:inline-flex bg-primary-100 text-primary-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-cyan-500 dark:text-slate-200">
            New
          </span>
          Buy & sell stablecoins for 0% fees.
          <Link
            to="https://fractal-payments.notion.site/Effortless-On-Off-Ramps-c75174a2d59a4a9aba2528eb9a0b503e"
            className="inline-flex items-center ml-0 text-sm font-medium text-primary-600 md:ml-2 dark:text-cyan-500 hover:underline">
            Learn more
            <svg
              class="ml-1 w-4 h-4 text-primary-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </Link>
        </p>
      </div>
    </div>
  );
};

const MobileExperienceBanner = () => (
  <MobileView>
    <div className="p-2 bg-gray-900">
      <div className="rounded-md bg-slate-800 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-amber-300"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-slate-100">
              Please use desktop for better user experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  </MobileView>
);

const MainLayout = ({ header, sidebar, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-slate-900/75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex dark:text-white">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col ring-1 ring-white/10">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className=" ml-1 flex h-10 w-10 items-center justify-center rounded-full bg-slate-900 outline-none ring-2 ring-inset ring-white"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                {sidebar}
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <UpdatesBanner />

      <div className="h-full overflow-hidden bg-gray-900 lg:pl-72">
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-[#1b2431] shadow-2xl">
          <button
            type="button"
            className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          {header}
        </div>

        <main className="h-[calc(100%-theme(space.16))] flex-1 overflow-auto py-6">
          {children}
        </main>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-9 lg:flex lg:w-72 lg:flex-col">
        <div className="overflow-y-autopt-5 flex flex-grow flex-col">
          {sidebar}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
