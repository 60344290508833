import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import SecondaryHeader from 'shared/ui/SecondaryHeader';
import AccountingIntegrationsPage from './organization/AccountingIntegrationsPage';
import CompanyDetailsPage from './organization/CompanyDetailsPage';
import TeamMembersPage from './organization/TeamMembersPage';
import PaymentDetails from './organization/PaymentDetails';
import { EmployeesPage } from '../features/organization/employees/EmployeesPage';
import { ROLES } from 'shared/hooks';
import { useCurrentUserDetails } from '../entities/user/model';

const ALL_ROLES = [ROLES.ADMIN, ROLES.MEMBER, ROLES.ACCOUNTANT];
const ADMIN_ONLY = [ROLES.ADMIN];

const UserSettingsPage = () => {
  const secondaryNavigation = [
    {
      name: 'Your Organization',
      href: '/dashboard/company',
      current: false,
      roles: ALL_ROLES,
    },
    {
      name: 'Employees',
      href: '/dashboard/company/employess',
      current: false,
      roles: ADMIN_ONLY,
    },
    {
      name: 'Team Members',
      href: '/dashboard/company/team',
      current: false,
      roles: [ROLES.ADMIN, ROLES.MEMBER],
    },
    {
      name: 'Accounting Integrations',
      href: '/dashboard/company/integrations',
      current: false,
      roles: ADMIN_ONLY,
    },
    {
      name: 'Payment Details',
      href: '/dashboard/company/payment-details',
      current: false,
      roles: [ROLES.ADMIN, ROLES.MEMBER],
    },
  ];

  const location = useLocation();
  const { getCurrentOrgRole } = useCurrentUserDetails();
  const [navigation, setNavigation] = useState(secondaryNavigation);

  useEffect(() => {
    setNavigation(
      secondaryNavigation
        .filter(({ roles = [] }) => roles.includes(getCurrentOrgRole()))
        .map((item) => {
          if (item.href === location.pathname) {
            return {
              ...item,
              current: true,
            };
          }
          return {
            ...item,
            current: false,
          };
        })
    );
  }, [getCurrentOrgRole, location]);

  return (
    <div className="mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <SecondaryHeader navItems={navigation} />
      <Routes>
        <Route path="/" element={<CompanyDetailsPage />} />
        <Route path="/employess" element={<EmployeesPage />} />
        <Route path="/team" element={<TeamMembersPage />} />
        <Route path="/integrations" element={<AccountingIntegrationsPage />} />
        <Route path="/payment-details" element={<PaymentDetails />} />
      </Routes>
    </div>
  );
};

export default UserSettingsPage;
