import React from 'react';

export const PageHeader: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="mb-4">
      <h1 className="text-md text-slate-200 font-semibold">{title}</h1>
      <h2 className="text-sm text-slate-400">{subtitle}</h2>
    </div>
  );
};
