import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';
import { useState } from 'react';
import { TextInput } from 'shared/ui/form/Input';

export const AddFilterModal = ({
  show,
  queryOptions,
  onClose,
  onSearchChange,
}) => {
  const [fromCreatedAtDate, setFromCreatedAtDate] = useState(
    queryOptions.fromCreatedAtDate || ''
  );
  const [toCreatedAtDate, setToCreatedAtDate] = useState(
    queryOptions.toCreatedAtDate || ''
  );

  const handleApplyFilter = () => {
    if (
      fromCreatedAtDate !== queryOptions.fromCreatedAtDate ||
      toCreatedAtDate !== queryOptions.toCreatedAtDate
    ) {
      onSearchChange({ fromCreatedAtDate, toCreatedAtDate });
      onClose();
    }
  };

  const handleResetFilter = () => {
    setFromCreatedAtDate('');
    setToCreatedAtDate('');
  };

  const _handleChangeFromDate = (evt) => {
    setFromCreatedAtDate(evt.target.value);
  };

  const _handleChangeToDate = (evt) => {
    setToCreatedAtDate(evt.target.value);
  };

  return (
    <Modal dismissible show={show} onClose={onClose}>
      <Modal.Header>Apply Bill Filters</Modal.Header>
      <Modal.Body>
        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
          <div className="col-span-6 flex-none sm:col-span-3">
            <label className="block text-sm text-[#CBD5E1] ">From Date</label>
            <TextInput
              id="fromCreatedAtDate"
              type="date"
              color={'darkSlate'}
              value={fromCreatedAtDate}
              className="mt-1"
              onChange={_handleChangeFromDate}
            />
          </div>
          <div className="col-span-6 flex-none sm:col-span-3">
            <label className="block text-sm text-[#CBD5E1] ">To Date</label>
            <TextInput
              id="toCreatedAtDate"
              type="date"
              color={'darkSlate'}
              value={toCreatedAtDate}
              className="mt-1"
              onChange={_handleChangeToDate}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <Button color="slate" onClick={handleResetFilter}>
          Reset
        </Button>
        <Button color="indigo" onClick={handleApplyFilter}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
