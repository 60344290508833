import React, { useMemo, useState } from 'react';

import { useCurrentUserDetails } from 'entities/user/model';
import CompanyManageUserModal from './CompanyManageUserModal';
import { useUsers } from 'entities/user/model';
import { ROLES } from 'shared/hooks';
import { Table } from '../../shared/ui/Table/TableBlocks';
import { ContentLoadingState } from '../../shared/ui/layouts/PageContent';

const TableOrganizationUsers = ({ fetchUsers }) => {
  const { data: users, isLoading, isFetching } = useUsers();
  const { getCurrentOrgRole } = useCurrentUserDetails();
  const [userToEdit, setUserToEdit] = useState(null);

  const formatUserRoles = (role) => {
    if (role === ROLES.ADMIN) {
      return 'Admin';
    } else if (role === ROLES.MEMBER) {
      return 'Member';
    } else if (role === ROLES.ACCOUNTANT) {
      return 'Accountant';
    }
  };

  const columns = useMemo(() => {
    const res = [
      {
        header: 'Email',
        cell: ({ row }) => {
          return (
            <span className="font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {row.original.email}
            </span>
          );
        },
      },
      {
        header: 'Role',
        cell: ({ row }) => formatUserRoles(row.original.role),
      },
    ];

    if (getCurrentOrgRole() === 'admin') {
      res.push({
        header: 'Email Notifications',
        cell: ({ row }) =>
          row.original.email_notifications ? 'Enabled ' : 'Disabled',
      });
    }

    res.push({
      header: 'actions',
    });

    return res;
  }, [getCurrentOrgRole]);

  const _getRowActions = (row) => {
    const actions = [];

    if (getCurrentOrgRole() === 'admin') {
      actions.push({
        label: 'Change Access Settings',
        onClick: () => {
          setUserToEdit(row);
        },
      });
    }

    return actions;
  };

  if (isLoading) {
    return <ContentLoadingState className="pt-6" />;
  }

  if (!users || !users.length) {
    return null;
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <Table
        isDataLoading={isFetching}
        title="Your current team members"
        description={
          getCurrentOrgRole() === 'admin'
            ? 'Manage their roles and notifications here'
            : 'If you would like to invite another member ask your admin'
        }
        onRowClick={(row) => {
          if (getCurrentOrgRole() === 'admin') {
            setUserToEdit(row);
          }
        }}
        data={users}
        columns={columns}
        getRowActions={_getRowActions}
      />

      <CompanyManageUserModal
        user={userToEdit}
        show={!!userToEdit}
        onClose={() => {
          setUserToEdit(null);
        }}
      />
    </div>
  );
};

export default TableOrganizationUsers;
