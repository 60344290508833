import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useInvoiceActions } from './actions';
import { QUERY_KEY } from './constants';

export const useInvoiceMutations = () => {
  const queryClient = useQueryClient();
  const { createAction, deleteAction, updatePaymentStatusAction } =
    useInvoiceActions();

  const create = useMutation({
    mutationFn: async (invoice) => {
      const response = await createAction(invoice);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const remove = useMutation({
    mutationFn: async (invoiceId) => {
      const response = await deleteAction(invoiceId);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const updatePaymentStatus = useMutation({
    mutationFn: async ({ invoiceId, newStatus, transactionHash }) => {
      const response = await updatePaymentStatusAction(
        invoiceId,
        newStatus,
        transactionHash
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);

  return { create, remove, updatePaymentStatus, invalidate };
};
