import { toast } from "react-toastify";

// format Date to DD/MM/YYYY
export function formatDate(date) {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

export function dateFormatter(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  return `${day} ${month} ${year}`;
}

// write a function that compares two dates in the following format "yyyy-mm-dd" and returns true if the first date is before the second date
export function compareDates(date1, date2) {
  const date1Array = date1.split("-");
  const date2Array = date2.split("-");
  const date1Year = date1Array[0];
  const date1Month = date1Array[1];
  const date1Day = date1Array[2];
  const date2Year = date2Array[0];
  const date2Month = date2Array[1];
  const date2Day = date2Array[2];
  if (date1Year < date2Year) {
    return true;
  } else if (date1Year === date2Year && date1Month < date2Month) {
    return true;
  } else if (
    date1Year === date2Year &&
    date1Month === date2Month &&
    date1Day < date2Day
  ) {
    return true;
  }
  return false;
}

// function setValue that takes in the paymentStatus and sets value to "paid", "unpaid", or "overdue". "overdue" should only be set if the bill is unpaid and the due date is before today
export function setValue(paymentStatus, dueDate) {
  const today = new Date().toISOString().slice(0, 10);

  if (paymentStatus === "paid") {
    return "paid";
  } else if (paymentStatus === "unpaid" && compareDates(dueDate, today)) {
    return "overdue";
  } else {
    return "unpaid";
  }
}

export function byDueDate(a, b) {
  const dateA = new Date(a.dueDate);
  const dateB = new Date(b.dueDate);
  return dateB - dateA;
}

export function notify() {
  return toast(`Link copied to clipboard!`, {
    position: "bottom-right",
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export function handleCopyInvoiceLink(e, billId) {
  e.preventDefault();
  // copy the url to the clipboard
  navigator.clipboard.writeText(
    window.location.origin + "/public/bills/invoices?id=" + billId
  );
  notify();
}

const status = {
  approved: {
    dot: "bg-green-400",
    color: "text-green-400",
    text: "Approved",
  },
  rejected: {
    dot: "bg-red-400",
    color: "text-red-400",
    text: "Rejected",
  },
  pending: {
    dot: "bg-gray-400",
    color: "text-gray-400",
    text: "Pending",
  },
  required: {
    dot: "bg-yellow-400",
    color: "text-yellow-400",
    text: "Approval Required",
  },
  unrequired: {
    dot: "bg-gray-400",
    color: "text-gray-400",
    text: "Not Required",
  },
};
export function renderApprovalStatus(bill, data) {
  const isApprovableByCurrentUser =
    data.email === bill?.approval?.approver &&
    bill?.approval?.status === "pending";

  const { text, color, dot } = bill?.approval?.required
    ? isApprovableByCurrentUser
      ? status.required
      : status[bill?.approval?.status]
    : status.unrequired;

  return (
    <span className="inline-flex items-center justify-end gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium">
      <span className={`h-1.5 w-1.5 rounded-full ${dot}`} aria-hidden="true" />
      <span className={`text-xs ${color}`}>{text}</span>
    </span>
  );
}

export function formatDataForCSV(bills) {
  return bills.map((bill) => ({
    billId: bill?._id,
    name: bill?.receiverName,
    email: bill?.receiverEmail,
    link: bill?.urlName,
    dueDate: bill?.dueDate,
    network: bill?.network,
    amount: bill?.amount,
    subtotal: bill?.subtotal,
    tax: bill?.tax,
    token: bill?.token,
    walletAddress:
      bill?.network !== "Fiat"
        ? bill.walletAddress
        : "Check Invoice For Bank Details",
    paymentStatus: bill?.paymentStatus,
    approvalStatus: bill?.approval?.status,
    approver: bill?.approval?.approver,
    invoicePDF:
      "https://app.getfractal.xyz/public/bills/invoices?id=" + bill?._id,
  }));
}
