export const slate100 = '#f1f5f9';
export const slate200 = '#e2e8f0';
export const slate300 = '#cbd5e1';
export const slate400 = '#94a3b8';
export const slate500 = '#64748b';
export const slate700 = '#334155';
export const slate800 = '#1e293b';
export const slate900 = '#0f172a';
export const indigo700 = '#4338ca';
export const indigo600 = '#4f46e5';
export const indigo500 = '#6366f1';
export const indigo400 = '#818cf8';

export const rose500 = '#f43f5e';
