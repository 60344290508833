import { Modal } from '../../../shared/ui/Modal/Modal';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button } from '../../../shared/ui/Button/Button';
import { ConfirmInWalletOverlay } from '../../../widgets/Payments/PaymentModal.ConfirmInWalletOverlay';
import { FailedPayment } from '../../../widgets/Payments/CryptoToFiatPaymentModal.SuccessConfirmation';
import { ConfirmationSection } from '../../../widgets/Payments/PaymentModal.ConfirmationSection';
import React, { useState } from 'react';
import { PaymentInformation } from '../../../widgets/Payments/PaymentModal.PaymentInformation';
import { SuccessConfirmation } from '../../../widgets/Payments/PaymentModal.SuccessConfirmation';
import { useTokenBalance } from '../../solana/useTokenBalance';
import { useTransferPayout } from '../../solana/useTransferPayout';

const SolanaPaymentModal = ({
  payouts,
  onTransactionSuccess,
  onClose,
  successRedirectLink,
}) => {
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const { connected, wallet } = useWallet();
  // We don't support batch
  /** @type {CryptoPayoutDetails} */
  const [payout] = useState(payouts[0]);
  const { data, isSuccess, transferPayout, isLoading, isError } =
    useTransferPayout(payout);
  const { balanceData } = useTokenBalance(payout.currency);

  const _handleConfirmationChange = (confirmed) => {
    setIsConfirmedByUser(confirmed);
  };
  const _handlePayment = async () => {
    transferPayout(null).then((res) => {
      onTransactionSuccess && onTransactionSuccess(res);
    });
  };

  return (
    <Modal popup size="lg" show onClose={onClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>
      <Modal.Body>
        <ConfirmInWalletOverlay show={isLoading} />
        <FailedPayment onClose={onClose} show={isError} />
        <SuccessConfirmation
          show={isSuccess}
          network={payout.network}
          txHash={data?.signature}
          onClose={onClose}
          successLink={successRedirectLink}
        />

        <PaymentInformation
          walletAddress={payout.walletAddress}
          network={payout.network}
          token={payout.currency}
          amount={payout.amountStr}
          balance={balanceData?.uiAmountString}
        />
        <ConfirmationSection
          isConfirmed={isConfirmedByUser}
          setIsConfirmed={setIsConfirmedByUser}
          onConfirmationChange={_handleConfirmationChange}
        />

        <div className="pt-6 flex flex-row gap-4 items-center justify-center">
          {connected ? (
            <>
              <Button className="flex-1" color={'gray'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color="indigo"
                className="flex-1"
                disabled={!isConfirmedByUser || isLoading}
                onClick={_handlePayment}>
                Pay {payout.amountStr} {payout.currency}
              </Button>
            </>
          ) : (
            <WalletMultiButton
              style={{ background: 'none', padding: '0', width: '100%' }}>
              <Button className="w-[100%]" color="indigo">
                {wallet ? 'Connect Wallet' : 'Select Wallet'}
              </Button>
            </WalletMultiButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const SolanaBillPaymentModal = ({
  payouts = [],
  onClose,
  onTransactionSuccess,
}) => {
  return (
    <SolanaPaymentModal
      payouts={payouts}
      successRedirectLink={'/dashboard/bills/allbills'}
      onClose={onClose}
      onTransactionSuccess={onTransactionSuccess}
    />
  );
};
