import {
  Modal,
  Select,
  Form as AForm,
  InputNumber,
  Flex,
  Input,
  Radio,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { useWizard, Wizard as Stepper } from 'react-use-wizard';
import {
  CURRENCY_TYPES,
  getTokenHumanReadableNameByChain,
  NETWORK_TYPES,
  TOKEN_TYPES,
  TokenCurrencyLogoMap,
} from '../bill/lib';
import { useCurrentUserDetails } from '../../entities/user/model';
import { Button } from '../../shared/ui/Button/Button';

import { NetworkLabel } from '../../widgets/Invoices/InvoiceGenerateForm.PaymentTypeSelection';
import { usePaymentDetails } from '../../entities/organization';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useCreateTransfer } from './useCreateTransfer';
import { toast } from 'react-toastify';
import { useTransfers } from './useTransfers';
import { PaymentDetailsTypes } from '../payments/paymentDetails/PaymentDetailsTypes';
import { ethereumWalletRegex } from '../../shared/lib/string';
import { countries } from '../../shared/ui/form/CountrySelect';
import isoCountries from 'i18n-iso-countries';
import { useAuth0 } from '@auth0/auth0-react';
import { sendTransactionLog } from '../analytics/sendTransactionLog';

const { Text } = Typography;
export const AVAILABLE_NETWORKS_BY_CURRENCIES = {
  [TOKEN_TYPES.USDC]: [
    NETWORK_TYPES.Ethereum,
    NETWORK_TYPES.Polygon,
    NETWORK_TYPES.Base,
  ],
  [TOKEN_TYPES.USDT]: [
    NETWORK_TYPES.Ethereum,
    NETWORK_TYPES.Polygon,
    NETWORK_TYPES.Base,
  ],
  [CURRENCY_TYPES.USD]: ['fiat'],
  [CURRENCY_TYPES.EUR]: ['fiat'],
};

export const AddNewTransferModal = ({ show, onClose, onTransferCreated }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const { isPending, createTransfer } = useCreateTransfer();
  const { invalidate } = useTransfers();
  const [transferData, setTransferData] = useState({});

  const _handleFinishStep = (partialFormData, isSubmit) => {
    const formData = {
      ...transferData,
      ...partialFormData,
    };

    _handlePreSaveDataStepData(formData);

    if (isSubmit) {
      createTransfer({
        ...formData,
        organizationId: getCurrentOrganizationId(),
        destinationNetwork: formData.destinationNetwork.toLowerCase(),
        transferAmount: parseFloat(formData.transferAmount),
      })
        .then((transfer) => {
          onTransferCreated(transfer);
          onClose();
          return invalidate();
        })
        .catch((err) => {
          sendTransactionLog(getAccessTokenSilently, {
            title: '[FAIL][ADD NEW TRANSFER]',
            err,
          });
          toast.error(
            `Oops, something went wrong, contact support: pavel@getfractal.xyz`
          );
        });
    }
  };

  const _handlePreSaveDataStepData = (partialFormData) => {
    setTransferData({
      ...transferData,
      ...partialFormData,
    });
  };

  return (
    <Modal
      title="Initiate Transfer"
      open={show}
      footer={null}
      centered
      destroyOnClose
      loading={isPending}
      onCancel={onClose}>
      <Stepper>
        <CurrencyAndAmountStep
          onClose={onClose}
          formData={transferData}
          onFinishStep={_handleFinishStep}
        />
        <RecipientPaymentDetailsStep
          formData={transferData}
          onFinishStep={_handleFinishStep}
          onPreSaveData={_handlePreSaveDataStepData}
        />
      </Stepper>
    </Modal>
  );
};

const CurrencyAndAmountStep = ({ formData, onClose, onFinishStep }) => {
  const { nextStep } = useWizard();
  const [form] = AForm.useForm();
  const _getDefaultNetworkByCurr = (cur) => {
    return AVAILABLE_NETWORKS_BY_CURRENCIES[cur][0];
  };
  const destinationNetworkInitial = formData?.destinationNetwork;

  const _handleNext = () => {
    form
      .validateFields({ validateOnly: false })
      .then(() => {
        const formDataToSave = form.getFieldsValue();
        onFinishStep({
          destinationNetwork: _getDefaultNetworkByCurr(
            formDataToSave.destinationCurrency
          ),
          ...formDataToSave,
        });
        return nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AForm
      name="basic"
      form={form}
      requiredMark={false}
      style={{
        paddingTop: 20,
      }}
      initialValues={{
        destinationCurrency:
          formData?.destinationCurrency || CURRENCY_TYPES.USD,
        destinationNetwork: destinationNetworkInitial,
        transferAmount: formData?.transferAmount || 0,
      }}
      layout="vertical"
      autoComplete="off">
      <AForm.Item shouldUpdate={true} noStyle>
        {() => (
          <AForm.Item
            rules={[
              {
                required: true,
                message: 'Please select currency',
              },
            ]}
            name="destinationCurrency"
            layout="vertical"
            label="Choose Destination Currency">
            <Select
              onChange={(val) => {
                form.setFieldValue('destinationCurrency', val);

                /** Update default network if you change currency */

                form.setFieldValue(
                  'destinationNetwork',
                  _getDefaultNetworkByCurr(val)
                );
              }}
              options={[
                CURRENCY_TYPES.USD,
                CURRENCY_TYPES.EUR,
                TOKEN_TYPES.USDC,
                TOKEN_TYPES.USDT,
              ].map((c) => {
                return {
                  value: c,
                  label: (
                    <Flex align={'center'}>
                      <Flex align={'center'} className="mr-2 h-4 w-4">
                        <img
                          src={TokenCurrencyLogoMap[c]}
                          alt=""
                          aria-hidden="true"
                        />
                      </Flex>

                      {getTokenHumanReadableNameByChain(
                        c,
                        form.getFieldValue('destinationNetwork') ||
                          destinationNetworkInitial
                      )}
                    </Flex>
                  ),
                };
              })}
            />
          </AForm.Item>
        )}
      </AForm.Item>

      <AForm.Item noStyle dependencies={['destinationCurrency']}>
        {() => {
          if (
            [CURRENCY_TYPES.USD, CURRENCY_TYPES.EUR].includes(
              form.getFieldValue('destinationCurrency')
            )
          ) {
            return null;
          }

          return (
            <AForm.Item
              rules={[
                {
                  required: true,
                  message: 'Please input name!',
                },
              ]}
              name="destinationNetwork"
              layout="vertical"
              label="Choose Destination Network">
              <Select
                options={AVAILABLE_NETWORKS_BY_CURRENCIES[
                  form.getFieldValue('destinationCurrency') ||
                    CURRENCY_TYPES.USD
                ].map((n) => {
                  return {
                    value: n,
                    label: <NetworkLabel network={n} />,
                  };
                })}
              />
            </AForm.Item>
          );
        }}
      </AForm.Item>

      <AForm.Item
        rules={[
          {
            required: true,
            message: 'Please enter amount',
          },
          {
            min: 0.000001,
            type: 'number',
            message: 'Please enter amount more then 0',
          },
        ]}
        name="transferAmount"
        layout="vertical"
        label="Amount">
        <InputNumber style={{ width: '100%' }} />
      </AForm.Item>

      <StepFooter
        previousText={'Cancel'}
        onPrevious={onClose}
        onNext={_handleNext}
      />
    </AForm>
  );
};

const RecipientPaymentDetailsStep = ({
  formData,
  onFinishStep,
  onPreSaveData,
}) => {
  const { previousStep } = useWizard();
  const { data: paymentDetailsData, isPending } = usePaymentDetails();
  const [form] = AForm.useForm();

  const _getPaymentDetailsList = () => {
    if (formData.destinationNetwork === 'fiat') {
      return (paymentDetailsData?.result || []).filter(
        (p) => p.type === 'bank'
      );
    }

    return (paymentDetailsData?.result || []).filter(
      (p) =>
        p.type === 'crypto_wallet' || p.type === 'fractal_abstract_bank_account'
    );
  };

  const _handlePrev = () => {
    onPreSaveData(form.getFieldsValue());
    previousStep();
  };

  const _createOuterFormData = (localFormData) => {
    const result = {};
    const pds = _findPdsById(localFormData.destinationPaymentDetailsId);

    if (formData.destinationNetwork === 'fiat') {
      if (pds && pds.details.bankAccount) {
        result.destinationPaymentDetails = {
          type: PaymentDetailsTypes.BankAccount,
          accountBeneficiary:
            localFormData.accountBeneficiary ||
            pds.details.bankAccount.accountBeneficiary,
          bankName: localFormData.bankName || pds.details.bankAccount.bankName,
          bankAddress: pds.details.bankAccount.bankAddress,
          bankAccountBeneficiaryType:
            localFormData.bankAccountBeneficiaryType ||
            pds.details.bankAccount.bankAccountBeneficiaryType,
          accountNumber:
            localFormData.accountNumber ||
            pds.details.bankAccount.accountNumber,
          accountACHRoutingNumber:
            localFormData.accountACHRoutingNumber ||
            pds.details.bankAccount.accountACHRoutingNumber,
          accountIBAN:
            localFormData.accountIBAN || pds.details.bankAccount.accountIBAN,
          bankBIC: localFormData.bankBIC || pds.details.bankAccount.bankBIC,
        };
        result.destinationPaymentDetailsMeta = {
          pdsType: pds.type,
          businessRelation: pds.businessRelation,
          pdsId: pds.id,
          pdsName: pds.name,
        };
      } else {
        result.destinationPaymentDetails = {
          type: PaymentDetailsTypes.BankAccount,
          accountBeneficiary: localFormData.accountBeneficiary,
          bankName: localFormData.bankName,
          bankAccountBeneficiaryType: localFormData.bankAccountBeneficiaryType,
          accountNumber: localFormData.accountNumber,
          accountACHRoutingNumber: localFormData.accountACHRoutingNumber,
          accountIBAN: localFormData.accountIBAN,
          bankBIC: localFormData.bankBIC,
        };
      }
      result.destinationPaymentDetails.bankAddressObj = {
        countryAlpha3: isoCountries.alpha2ToAlpha3(
          localFormData['bankAddressObj.countryAlpha2']
        ),
        countryAlpha2: localFormData['bankAddressObj.countryAlpha2'],
      };
      result.recipientAddress = {
        streetAddress: localFormData['recipientAddress.streetAddress'],
        postalCode: localFormData['recipientAddress.postalCode'],
        countryAlpha2: localFormData['recipientAddress.countryAlpha2'],
        city: localFormData['recipientAddress.city'],
      };
    } else {
      if (pds) {
        result.destinationPaymentDetails = {
          address:
            localFormData.walletAddress || pds.details.cryptoWallet.address,
          type: PaymentDetailsTypes.CryptoWallet,
        };
        result.destinationPaymentDetailsMeta = {
          pdsType: pds.type,
          businessRelation: pds.businessRelation,
          pdsId: pds.id,
          pdsName: pds.name,
        };
      } else {
        result.destinationPaymentDetails = {
          address: localFormData.walletAddress,
          type: PaymentDetailsTypes.CryptoWallet,
        };
      }
    }

    return result;
  };

  const _handleNext = () => {
    form
      .validateFields({ validateOnly: false })
      .then(() => {
        onFinishStep(_createOuterFormData(form.getFieldsValue()), true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _findPdsById = (id) => {
    return _getPaymentDetailsList().find((pds) => pds.id === id);
  };

  const _handleRecipientChange = (value) => {
    const values = {
      destinationPaymentDetailsId: value,
    };

    /** Prefill bank account data */
    if (formData.destinationNetwork === 'fiat' && value !== 'manual') {
      const pds = _findPdsById(value);
      if (pds) {
        const bankAccount = pds.details.bankAccount;
        values.accountBeneficiary = bankAccount.accountBeneficiary;
        values.bankBIC = bankAccount.bankBIC;
        values.accountACHRoutingNumber = bankAccount.accountACHRoutingNumber;
        values.accountIBAN = bankAccount.accountIBAN;
        values.accountNumber = bankAccount.accountNumber;
        values.bankName = bankAccount.bankName;
        values.bankAccountBeneficiaryType =
          bankAccount.bankAccountBeneficiaryType;
        values['bankAddressObj.countryAlpha2'] =
          bankAccount?.bankAddressObj?.countryAlpha2;

        if (bankAccount.accountIBAN) {
          values.bankTransferMethod = 'iban';
        } else {
          values.bankTransferMethod = 'ach';
        }
      }
    }

    form.setFieldsValue(values);
  };

  return (
    <AForm
      name="recipient"
      form={form}
      requiredMark={false}
      style={{
        paddingTop: 20,
      }}
      initialValues={{
        walletAddress: '',
        accountBeneficiary: '',
        bankTransferMethod:
          formData.destinationCurrency === 'USD' ? 'ach' : 'iban',
      }}
      layout="vertical">
      <AForm.Item
        rules={[
          {
            required: true,
            message: 'Please select recipient',
          },
        ]}
        name="destinationPaymentDetailsId"
        layout="vertical"
        label="Select Recipient Payment Details">
        <Select
          placeholder={'--'}
          loading={isPending}
          onChange={_handleRecipientChange}
          options={[
            {
              value: 'manual',
              label: (
                <Flex>
                  <PencilIcon width={12} className="mr-2" /> Enter Payment
                  Details Manually
                </Flex>
              ),
            },
            ..._getPaymentDetailsList().map((v) => ({
              value: v.id,
              label: v.name,
            })),
          ]}
        />
      </AForm.Item>

      <AForm.Item dependencies={['destinationPaymentDetailsId']} noStyle>
        {() => {
          if (!form.getFieldValue('destinationPaymentDetailsId')) {
            return null;
          }

          if (formData.destinationNetwork === 'fiat') {
            return (
              <RecipientBankAccountForm globalFormData={formData} form={form} />
            );
          }

          return (
            <RecipientWalletForm
              selectedDestinationPaymentDetails={_findPdsById(
                form.getFieldValue('destinationPaymentDetailsId')
              )}
              form={form}
            />
          );
        }}
      </AForm.Item>
      <StepFooter
        nextText={'Proceed to Payment'}
        onPrevious={_handlePrev}
        onNext={_handleNext}
      />
    </AForm>
  );
};

const RecipientBankAccountForm = ({ form, globalFormData }) => {
  return (
    <>
      {globalFormData.destinationCurrency === 'USD' && (
        <AForm.Item label="Transfer Method" name="bankTransferMethod">
          <Radio.Group size={'small'}>
            <Radio.Button value="ach">ACH</Radio.Button>
            <Radio.Button value="iban">IBAN</Radio.Button>
          </Radio.Group>
        </AForm.Item>
      )}

      <Flex gap={'middle'}>
        <AForm.Item
          className={'flex-1'}
          layout="vertical"
          rules={[
            {
              required: true,
              message: 'Enter beneficiary name',
            },
          ]}
          label="Beneficiary Name"
          name="accountBeneficiary">
          <Input placeholder={'e.g. "John Doe"'} />
        </AForm.Item>
        <AForm.Item
          className={'w-48'}
          rules={[
            {
              required: true,
              message: 'Select beneficiary type',
            },
          ]}
          name="bankAccountBeneficiaryType"
          layout="vertical"
          label="Beneficiary Type">
          <Select
            placeholder={'Select beneficiary type'}
            options={[
              {
                value: 'business',
                label: 'Business',
              },
              {
                value: 'individual',
                label: 'Individual',
              },
            ]}
          />
        </AForm.Item>
      </Flex>

      <AForm.Item dependencies={['bankTransferMethod']} noStyle>
        {() => {
          if (form.getFieldValue('bankTransferMethod') === 'ach') {
            return (
              <Flex gap={'middle'}>
                <AForm.Item
                  className={'flex-1'}
                  layout="vertical"
                  label="Account Number"
                  name="accountNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Enter account number',
                    },
                  ]}>
                  <Input />
                </AForm.Item>
                <AForm.Item
                  className={'w-48'}
                  layout="vertical"
                  label="Routing Number"
                  rules={[
                    {
                      required: true,
                      message: 'Enter routing number',
                    },
                  ]}
                  name="accountACHRoutingNumber">
                  <Input />
                </AForm.Item>
              </Flex>
            );
          }

          return (
            <Flex gap={'middle'}>
              <AForm.Item
                className={'flex-1'}
                layout="vertical"
                label="Account IBAN"
                name="accountIBAN">
                <Input />
              </AForm.Item>
              <AForm.Item
                className={'w-48'}
                layout="vertical"
                label="BIC / Swift Code"
                name="bankBIC">
                <Input />
              </AForm.Item>
            </Flex>
          );
        }}
      </AForm.Item>

      <Flex gap={'middle'}>
        <AForm.Item
          className={'flex-1'}
          layout="vertical"
          label="Bank Name"
          rules={[
            {
              required: true,
              message: 'Enter bank name',
            },
          ]}
          name="bankName">
          <Input placeholder={'e.g. "Chase"'} />
        </AForm.Item>
        <AForm.Item
          className={'w-48'}
          layout="vertical"
          rules={[
            {
              required: true,
              message: 'Select bank country',
            },
          ]}
          label="Bank Country"
          name="bankAddressObj.countryAlpha2">
          <Select
            placeholder={'--'}
            rules={[
              {
                required: true,
                message: 'Select country',
              },
            ]}
            options={countries.map(({ label, value, flag }) => ({
              value,
              label: (
                <>
                  {flag} {label}
                </>
              ),
            }))}
          />
        </AForm.Item>
      </Flex>

      <Text className={'!text-indigo-300 block mb-6'}>Recipient Address:</Text>

      <Flex gap={'middle'}>
        <AForm.Item
          className={'flex-1'}
          layout="vertical"
          label="Country"
          rules={[
            {
              required: true,
              message: 'Select country',
            },
          ]}
          name="recipientAddress.countryAlpha2">
          <Select
            placeholder={'--'}
            options={countries.map(({ label, value, flag }) => ({
              value,
              label: (
                <>
                  {flag} {label}
                </>
              ),
            }))}
          />
        </AForm.Item>
        <AForm.Item
          className={'w-48'}
          layout="vertical"
          label="City"
          rules={[
            {
              required: true,
              message: 'Enter city',
            },
          ]}
          name="recipientAddress.city">
          <Input />
        </AForm.Item>
      </Flex>
      <Flex gap={'middle'}>
        <AForm.Item
          className={'flex-1'}
          layout="vertical"
          label="Address Line"
          rules={[
            {
              required: true,
              message: 'Enter street address',
            },
          ]}
          name="recipientAddress.streetAddress">
          <Input />
        </AForm.Item>
        <AForm.Item
          className={'w-48'}
          layout="vertical"
          label="Zip Code"
          rules={[
            {
              required: true,
              message: 'Enter postal/zip code',
            },
          ]}
          name="recipientAddress.postalCode">
          <Input />
        </AForm.Item>
      </Flex>
    </>
  );
};

const RecipientWalletForm = ({ selectedDestinationPaymentDetails, form }) => {
  const isManualFlow = !selectedDestinationPaymentDetails;

  if (isManualFlow) {
    return (
      <AForm.Item
        rules={[
          {
            required: true,
            message: 'Please enter valid wallet address',
          },
          {
            pattern: ethereumWalletRegex,
            message: 'Please enter valid wallet address',
          },
        ]}
        layout="vertical"
        name="walletAddress"
        label="Wallet Address">
        <Input placeholder="0x..." />
      </AForm.Item>
    );
  }

  return (
    <AForm.Item layout="vertical" label="Wallet Address">
      <Input
        disabled
        value={selectedDestinationPaymentDetails.details?.cryptoWallet?.address}
      />
    </AForm.Item>
  );
};

const StepFooter = ({
  nextText = 'Next',
  previousText = 'Previous',
  showPrevious = true,
  onNext,
  onPrevious,
}) => {
  return (
    <Flex justify={'flex-end'} style={{ marginTop: 32 }} gap={'small'}>
      {showPrevious && (
        <Button size="sm" color="gray" className="w-38" onClick={onPrevious}>
          {previousText}
        </Button>
      )}
      <Button
        size="sm"
        color="indigo"
        className="bg-indigo-600 w-38"
        onClick={onNext}>
        {nextText}
      </Button>
    </Flex>
  );
};
