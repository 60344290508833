import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import { splTokensConfig } from './config';
import { useQuery } from '@tanstack/react-query';

export const useTokenBalance = (token) => {
  const splAddress = splTokensConfig[token].splAddress;
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const { data } = useQuery({
    queryKey: ['solana-token-balance', token],
    queryFn: async () => {
      const mintAddress = new PublicKey(splAddress);
      const accountsData = await connection.getParsedTokenAccountsByOwner(
        publicKey,
        {
          mint: mintAddress,
        }
      );
      return accountsData?.value[0]
        ? accountsData?.value[0].account?.data?.parsed?.info?.tokenAmount || {}
        : {};
    },
    enabled: !!splAddress && !!publicKey,
  });

  return {
    balanceData: data,
  };
};
