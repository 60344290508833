import React from 'react';

export const ConfirmationSection = ({
  isConfirmed,
  setIsConfirmed,
  onConfirmationChange,
}) => {
  const handleConfirmationChange = (event) => {
    setIsConfirmed(event.target.checked);
    onConfirmationChange(event.target.checked);
  };

  return (
    <div>
      {/* <div
        as="h3"
        className="mb-5 text-base font-semibold leading-6 text-slate-300">
        Confirmation
      </div> */}
      <div className="relative mt-5 flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="confirmation"
            aria-describedby="confirmation-description"
            name="confirmation"
            type="checkbox"
            checked={isConfirmed}
            onChange={handleConfirmationChange}
            className=" h-3.5 w-3.5 rounded border-gray-300 bg-slate-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div className="ml-3 text-left text-sm leading-6">
          <label htmlFor="confirmation" className="font-medium text-slate-300">
            I have checked the accuracy of payment details.
          </label>
        </div>
      </div>
    </div>
  );
};
