import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'shared/ui/Button/Button';
import {
  useAragonAppDetails,
  useAragonMultisigBatchPayment,
} from 'shared/hooks/useAragonSdk';
import { sendTransactionLog } from 'features/analytics/sendTransactionLog';

export const AragonAppWithdrawProposalButton = ({
  aragonAppAddress,
  onSuccess,
  onStart,
  onFail,
  payouts,
  disabled,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { getMultisigAddress } = useAragonAppDetails(aragonAppAddress);
  const { initTransaction } = useAragonMultisigBatchPayment(
    getMultisigAddress()
  );

  const _handleClick = () => {
    onStart();
    initTransaction({
      recipients: payouts.map((p) => ({
        to: p.walletAddress,
        token: p.currency,
        amount: p.amountStr,
      })),
    })
      .then((proposalId) => {
        return onSuccess({ proposalId });
      })
      .catch((err) => {
        console.log(err);
        sendTransactionLog(getAccessTokenSilently, {
          title: '[FAIL][ARAGON WITHDRAW PROPOSAL]',
          aragonContractAddress: aragonAppAddress,
          err,
        });
        onFail && onFail();
      });
  };

  return (
    <Button color="indigo" disabled={disabled} onClick={_handleClick}>
      Pay
    </Button>
  );
};
