import axios from 'axios';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { ModalStyles } from 'widgets/Payments/PaymentModal.ModalStyles';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Spinner } from 'shared/ui/Spinner/Spinner';
import { CountrySelect } from 'shared/ui/form/CountrySelect';
import { Button as ButtonF } from 'flowbite-react';
import { Wizard, useWizard } from 'react-use-wizard';
import { Dialog, Switch } from '@headlessui/react';
import { CryptoNetworkTokenSelect } from 'widgets/Invoices/InvoiceGenerateForm.PaymentTypeSelection';
import {
  NETWORK_TYPES,
  CURRENCIES,
  TOKEN_TYPES,
  NETWORKS,
  TOKENS,
  getTokenHumanReadableNameByChain,
} from 'features/bill/lib';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';
import { useBillMutations } from 'entities/bill/model';
import { Tooltip } from 'flowbite-react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useValidateIban } from 'features/bill/ui/CreatePaymentRequestForm.PaymentDetails';
import { useInvalidateNotifications } from 'features/userNotifications/useUserNotifications';
import { useInvalidateStandingOrders } from 'features/bill/standingOrders/useStandingOrders';
import {
  useOrgVendorsList,
  useOrgVendorPaymentDetails,
} from 'features/organization/vendors/useVendors';
import { Select } from 'shared/ui/form/Select/RichSelect';
import { v4 as uuidv4 } from 'uuid';
// import useToggle from 'shared/hooks/useToggle';
import { PencilIcon } from '@heroicons/react/24/outline';

export const BillFormSchema = Yup.object().shape({
  receiverName: Yup.string().required('Receiver name is required'),
  receiverEmail: Yup.string()
    .email('Invalid email address')
    .required('Receiver email is required'),

  billDescription: Yup.string()
    .required('Payment Reference / Services is required')
    .min(5, 'Please enter more then 5 characters'),
  amount: Yup.number().required('Amount is required'),
  taxAmount: Yup.number().required('Tax is required'),
});

async function createBillRequest(token, payload) {
  try {
    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + '/api/public/bills',
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

const parseFileRequest = async (token, payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/public/invoices/parser/pdf/parse`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return {
      result: {
        issuerDetails: null,
        paymentDetails: null,
      },
    };
  }
};

const _validateForm = (values) => {
  const errors = {};

  if (values.paymentMethod === 'crypto') {
    const ethereumRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    if (!values.walletAddress) {
      errors.walletAddress = 'Wallet address is required';
    } else if (
      values.network !== NETWORK_TYPES.Solana &&
      !ethereumRegex.test(values.walletAddress)
    ) {
      errors.walletAddress = 'Invalid Ethereum address';
    }
  } else {
    if (!values.receiverAddressLine) {
      errors.receiverAddressLine = 'Receiver address is required';
    }

    if (!values.receiverCountry) {
      errors.receiverCountry = 'Receiver country is required';
    }

    if (!values.receiverCity) {
      errors.receiverCity = 'Receiver city is required';
    }

    if (!values.receiverPostCode) {
      errors.receiverPostCode = 'Receiver post code is required';
    }

    if (!values.bankName) {
      errors.bankName = 'Bank name is required';
    }

    if (values.isACHTransfer) {
      if (!values.bankAchRoutingNumber) {
        errors.bankAchRoutingNumber = 'Routing number is required';
      }

      if (!values.bankAccountNumber) {
        errors.bankAccountNumber = 'Account number is required';
      }
    } else {
      if (!values.bankIban) {
        errors.bankIban = 'IBAN is required';
      }

      if (!values.bankSwift) {
        errors.bankSwift = 'Bank Swift is required';
      }
    }
  }

  if (
    values.attachmentFile &&
    values.attachmentFile.type !== 'application/pdf'
  ) {
    errors.attachmentFile = 'Please upload a pdf file';
  }

  return errors;
};

export const AddNewBillModal = ({ showModal, onModalClose }) => {
  const { invalidate } = useBillMutations();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const { invalidateNotifications } = useInvalidateNotifications();
  const { invalidateStandingOrders } = useInvalidateStandingOrders();
  const createBillMutation = useMutation({
    mutationKey: ['create-bill'],
    mutationFn: async (payload) => {
      const auth0token = await getAccessTokenSilently();
      await createBillRequest(auth0token, payload);
      await invalidateStandingOrders();
      await invalidateNotifications();
    },
  });

  function generateInvoiceId() {
    const date = new Date();
    const datePart = `${String(date.getDate()).padStart(2, '0')}${String(
      date.getMonth() + 1
    ).padStart(2, '0')}${String(date.getFullYear()).slice(-2)}`;
    const randomPart = uuidv4().substr(0, 5);
    return `INV-${datePart}-${randomPart}`.toUpperCase();
  }

  const _handleClose = () => {
    if (!createBillMutation.isPending) {
      onModalClose();
    }
  };

  const _handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const {
        receiverName,
        receiverEmail,
        receiverAddressLine,
        receiverCountry,
        receiverCity,
        receiverVat,
        receiverPostCode,
        network,
        token,
        walletAddress,

        bankAccountBeneficiaryName,
        bankAccountBeneficiaryType,
        bankAchRoutingNumber,
        bankAccountNumber,
        bankName,
        bankIban,
        bankSwift,
        billDescription,
        amount,
        taxAmount,
        totalAmount,
        attachmentFile,
        billReferenceId,
        billIssueDate,

        isRecurringBillEabled,
        recurrenceDuration,
      } = values;

      await createBillMutation.mutateAsync({
        organizationId: getCurrentOrganizationId(),
        receiverName,
        receiverEmail,
        receiverAddressLine,
        receiverCountry,
        receiverCity,
        receiverVat,
        receiverPostCode,
        network: network.toLowerCase(),
        token,
        walletAddress,

        bankAccountBeneficiaryName,
        bankAccountBeneficiaryType,
        bankAchRoutingNumber,
        bankAccountNumber,
        bankName,
        bankIban,
        bankSwift,

        billDescription,
        billReferenceId,
        billIssueDate,
        amount: String(amount),
        taxAmount: String(taxAmount),
        totalAmount: String(totalAmount),
        attachmentFile: attachmentFile ? attachmentFile : null,

        recurrence: isRecurringBillEabled
          ? {
              interval: 'MONTH',
              duration: Number(recurrenceDuration),
              issueDay: Number(new Date(billIssueDate).getDate()),
            }
          : undefined,
      });
      invalidate();
      setSubmitting(false);
      _handleClose();
    } catch (error) {
      console.error('Error', error);
      toast.error(`Something went wrong: ${error.response.data.error}`);
    }
  };

  return (
    <ModalStyles show={showModal} onClose={_handleClose}>
      <Dialog.Title
        as="h3"
        className="text-base font-semibold leading-6 text-slate-100 mb-8">
        Create New Bill
      </Dialog.Title>

      <Formik
        enableReinitialize
        onSubmit={_handleSubmit}
        validationSchema={BillFormSchema}
        validate={_validateForm}
        initialValues={{
          receiverName: '',
          receiverEmail: '',
          receiverAddressLine: '',
          receiverCountry: '',
          receiverCity: '',
          receiverVat: '',
          receiverPostCode: '',
          network: NETWORK_TYPES.Ethereum,
          token: TOKEN_TYPES.USDC,
          paymentMethod: 'crypto',
          walletAddress: '',

          bankAccountBeneficiaryName: '',
          bankAccountBeneficiaryType: 'business',
          bankAchRoutingNumber: '',
          bankAccountNumber: '',
          bankName: '',
          bankIban: '',
          bankSwift: '',
          isACHTransfer: false,

          billDescription: '',
          amount: 0,
          taxAmount: 0,
          totalAmount: '0',
          attachmentFile: '',
          billReferenceId: generateInvoiceId(),
          billIssueDate: new Date().toISOString().slice(0, 10),
          recurrenceDuration: '1',

          importFromInvoicePdf: false,
          isRecurringBillEabled: false,

          selectedVendorUserId: 'no-value',
        }}>
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <InnerForm
              isSubmitting={isSubmitting || createBillMutation.isPending}
              values={values}
              setFieldValue={setFieldValue}
              onClose={_handleClose}
            />
          );
        }}
      </Formik>
    </ModalStyles>
  );
};

const InnerForm = ({ isSubmitting, values, setFieldValue, onClose }) => {
  const {
    parse,
    parsed: parsedInvoice,
    isParsing,
    resetParsedInvoice,
  } = useParseInvoice();

  const _handleInvoiceParse = (file, cb) => {
    if (!isParsing) {
      parse(file).then(cb);
    }
  };

  useEffect(() => {
    if (parsedInvoice) {
      const issuerDetails = parsedInvoice.issuerDetails;
      const paymentDetails = parsedInvoice.paymentDetails;

      const getPaymentMethod = () => {
        if (paymentDetails?.wallet_address && paymentDetails?.crypto_network) {
          return 'crypto';
        }

        if (
          paymentDetails?.iban ||
          paymentDetails?.routing_number ||
          paymentDetails?.account_number
        ) {
          return 'fiat';
        }

        return 'crypto';
      };

      const getACHTransfer = () => {
        if (paymentDetails?.routing_number && paymentDetails?.account_number) {
          return true;
        }

        return false;
      };

      const getNetwork = () => {
        if (getPaymentMethod() === 'fiat') {
          return 'Fiat';
        }

        if (
          NETWORKS.map((n) => n.toLowerCase()).includes(
            paymentDetails?.crypto_network.toLowerCase()
          )
        ) {
          return (
            paymentDetails.crypto_network[0].toUpperCase() +
            paymentDetails.crypto_network.slice(1)
          );
        }

        return NETWORK_TYPES.Ethereum;
      };

      const determineCurrency = () => {
        if (
          [...CURRENCIES, ...TOKENS]
            .map((n) => n.toLowerCase())
            .includes(paymentDetails?.currency.toLowerCase())
        ) {
          return paymentDetails.currency.toUpperCase();
        }

        return null;
      };

      issuerDetails?.company_name &&
        setFieldValue('receiverName', issuerDetails?.company_name);
      issuerDetails?.company_email &&
        setFieldValue('receiverEmail', issuerDetails?.company_email);
      issuerDetails?.address_line &&
        setFieldValue('receiverAddressLine', issuerDetails?.address_line);
      issuerDetails?.country &&
        setFieldValue('receiverCountry', issuerDetails?.country);
      issuerDetails?.city && setFieldValue('receiverCity', issuerDetails?.city);
      issuerDetails?.post_code &&
        setFieldValue('receiverPostCode', issuerDetails?.post_code);

      setFieldValue('paymentMethod', getPaymentMethod());
      setFieldValue('network', getNetwork());

      paymentDetails?.wallet_address &&
        setFieldValue('walletAddress', paymentDetails?.wallet_address);
      issuerDetails?.company_name &&
        setFieldValue(
          'bankAccountBeneficiaryName',
          issuerDetails?.company_name
        );
      paymentDetails?.routing_number &&
        setFieldValue('bankAchRoutingNumber', paymentDetails?.routing_number);
      paymentDetails?.account_number &&
        setFieldValue('bankAccountNumber', paymentDetails?.account_number);
      paymentDetails?.bank_name &&
        setFieldValue('bankName', paymentDetails?.bank_name);
      paymentDetails?.iban && setFieldValue('bankIban', paymentDetails?.iban);
      determineCurrency() && setFieldValue('token', determineCurrency());
      paymentDetails?.swift &&
        setFieldValue('bankSwift', paymentDetails?.swift);
      setFieldValue('isACHTransfer', getACHTransfer());
    }
  }, [parsedInvoice, setFieldValue]);

  useEffect(() => {
    return () => {
      resetParsedInvoice();
    };
  }, []);

  return isSubmitting ? (
    <div className="mb-6 flex h-full w-full items-center justify-center">
      <Spinner size="xl" color="indigo" />
    </div>
  ) : (
    <Form className="w-[640px]">
      <LoadingOverlay show={isParsing} />
      <Wizard>
        <ReceiverDetailsStep
          values={values}
          setFieldValue={setFieldValue}
          onClose={onClose}
        />
        <PaymentDetailsStep
          values={values}
          setFieldValue={setFieldValue}
          onClose={onClose}
        />
        <DocumentStep
          values={values}
          setFieldValue={setFieldValue}
          onClose={onClose}
          onAttachmentChange={_handleInvoiceParse}
        />
      </Wizard>
    </Form>
  );
};

const ImportFromInvoiceToggle = ({ enabled, setFieldValue, onChange }) => {
  const _handleImportFromInvoiceToggle = () => {
    setFieldValue('importFromInvoicePdf', !enabled);
    onChange && onChange(!enabled);
  };

  return (
    <>
      <Switch.Group as="div" className="items-left items-center flex ">
        <Switch
          checked={enabled}
          onChange={_handleImportFromInvoiceToggle}
          className={classNames(
            enabled ? 'bg-indigo-600' : 'bg-gray-500',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>

        <Switch.Label
          as="span"
          className="ml-5 text-sm font-medium leading-6 text-secondary-200"
          passive>
          Import from invoice
        </Switch.Label>
      </Switch.Group>
    </>
  );
};

const LoadingOverlay = ({ show }) => {
  if (!show) return null;

  return (
    <div className="absolute left-0 top-0 z-10 flex h-[100%] w-[100%] items-center justify-center bg-[#101826]/[.6]">
      <Spinner size="xl" color="indigo" />
    </div>
  );
};

const ManualOption = {
  value: 'manual',
  label: (
    <span className="flex" id="manual-option">
      <PencilIcon width={12} className="mr-2" /> Enter Receiver Details Manually
    </span>
  ),
};

const ReceiverDetailsStep = ({ values, onClose, setFieldValue }) => {
  const { nextStep, goToStep } = useWizard();

  const { data, isLoading } = useOrgVendorsList();

  const fiatPayment = values.paymentMethod === 'fiat';

  const [showAddressFields, setShowAddressFields] = useState(fiatPayment);

  const _handleImportFromInvoiceToggle = (flag) => {
    if (flag) {
      goToStep(2);
    }
  };

  const _resolveValue = () => {
    if (values.selectedVendorUserId === 'manual') {
      return ManualOption;
    }

    if (values.selectedVendorUserId === 'no-value') {
      return undefined;
    }

    const vendor = data.find(
      (vend) => vend.userId === values.selectedVendorUserId
    );

    if (vendor) {
      return {
        value: vendor.userId,
        label: vendor.legalName || vendor.email,
      };
    }
  };

  useEffect(() => {
    if (values.importFromInvoicePdf || fiatPayment) {
      setShowAddressFields(true);
    }
  }, [fiatPayment, values.importFromInvoicePdf]);

  return (
    <div className="text-left space-y-8">
      <LoadingOverlay show={isLoading} />
      <div className="border-b border-white/10 pb-10">
        <h2 className="text-base font-semibold leading-7 text-white">
          Receiver Details
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-400">
          Input information to create a new bill.
        </p>
        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-6">
          {!values.importFromInvoicePdf && (
            <div className="sm:col-span-6">
              <Select
                name="selectedVendorUserId"
                inputId="selectedVendorUserId"
                id="selectedVendorUserId"
                isSearchable={true}
                value={_resolveValue()}
                onChange={(v) => {
                  setFieldValue('selectedVendorUserId', v.value);

                  const vendor = data.find((vend) => vend.userId === v.value);

                  if (vendor) {
                    setFieldValue('receiverName', vendor.legalName);
                    setFieldValue('receiverEmail', vendor.email);

                    const hasAnyAddressDetails =
                      vendor.primaryAddress &&
                      (vendor.primaryAddress.city ||
                        vendor.primaryAddress.country ||
                        vendor.primaryAddress.postalCode ||
                        vendor.primaryAddress.streetAddress ||
                        vendor.primaryAddress.vatNumber);

                    if (hasAnyAddressDetails) {
                      !showAddressFields && setShowAddressFields(true);

                      vendor.primaryAddress.city &&
                        setFieldValue(
                          'receiverCity',
                          vendor.primaryAddress.city
                        );
                      vendor.primaryAddress.streetAddress &&
                        setFieldValue(
                          'receiverAddressLine',
                          vendor.primaryAddress.streetAddress
                        );
                      vendor.primaryAddress.country &&
                        setFieldValue(
                          'receiverCountry',
                          vendor.primaryAddress.country
                        );
                      vendor.primaryAddress.vatNumber &&
                        setFieldValue(
                          'receiverVat',
                          vendor.primaryAddress.vatNumber
                        );
                      vendor.primaryAddress.postalCode &&
                        setFieldValue(
                          'receiverPostCode',
                          vendor.primaryAddress.postalCode
                        );
                    }
                  }
                }}
                maxMenuHeight={210}
                options={[
                  {
                    label: '',
                    options: [ManualOption],
                  },
                  {
                    label: 'Select from Vendors',
                    options: data.map((v) => ({
                      value: v.userId,
                      label: v.legalName || v.email,
                    })),
                  },
                ]}
                placeholder="Select Receiver"
              />
            </div>
          )}

          {values.importFromInvoicePdf ||
          values.selectedVendorUserId !== 'no-value' ? (
            <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm text-[#CBD5E1]">
                  Name *
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="receiverName"
                    id="receiverName"
                    autoComplete="off"
                    value={values.receiverName}
                    onChange={(e) => {
                      setFieldValue('receiverName', e.target.value);
                      setFieldValue(
                        'bankAccountBeneficiaryName',
                        e.target.value
                      );
                    }}
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                  />
                </div>
                <ErrorMessage
                  name="receiverName"
                  component="div"
                  className="mt-2 text-xs text-[#e91e63]"
                />
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="email" className="block text-sm text-[#CBD5E1]">
                  Email *
                </label>
                <div className="mt-2">
                  <Field
                    type="email"
                    name="receiverEmail"
                    id="receiverEmail"
                    autoComplete="off"
                    value={values.receiverEmail}
                    onChange={(e) => {
                      setFieldValue('receiverEmail', e.target.value);
                    }}
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] disabled:bg-slate-800 disabled:text-slate-400 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                  />
                </div>
                <ErrorMessage
                  name="receiverEmail"
                  component="div"
                  className="mt-2 text-xs text-[#e91e63]"
                />
              </div>

              {!fiatPayment && (
                <div className="col-span-6 sm:col-span-6 my-2">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between">
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium leading-6 text-[#CBD5E1]"
                        passive>
                        Add Address & Tax Details
                      </Switch.Label>
                    </span>

                    <Switch
                      checked={showAddressFields}
                      onChange={(checked) => setShowAddressFields(checked)}
                      className={classNames(
                        showAddressFields ? 'bg-indigo-600' : 'bg-gray-500',
                        'relative inline-flex h-6 w-11 mr-2 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                      )}>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          showAddressFields ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block mr-2 h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              )}

              {showAddressFields && (
                <>
                  {' '}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="street-address"
                      className="block text-sm text-[#CBD5E1]">
                      Address Line
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="receiverAddressLine"
                        id="receiverAddressLine"
                        autoComplete="off"
                        value={values.receiverAddressLine}
                        onChange={(e) => {
                          setFieldValue('receiverAddressLine', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <ErrorMessage
                      name="receiverAddressLine"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="street-address"
                      className="block text-sm text-[#CBD5E1]">
                      Country
                    </label>
                    <div className="mt-2">
                      <CountrySelect
                        value={values.receiverCountry}
                        onCountryChange={(val) => {
                          setFieldValue('receiverCountry', val);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="receiverCountry"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>
                  <div className="sm:col-span-2 sm:col-start-1">
                    <label
                      htmlFor="city"
                      className="block text-sm text-[#CBD5E1]">
                      City
                    </label>

                    <div className="mt-2">
                      <Field
                        type="text"
                        name="receiverCity"
                        id="receiverCity"
                        autoComplete="off"
                        value={values.receiverCity}
                        onChange={(e) => {
                          setFieldValue('receiverCity', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    <ErrorMessage
                      name="receiverCity"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="receiverPostCode"
                      className="block text-sm text-[#CBD5E1]">
                      ZIP / Postal code {values.fiat_payment && ' *'}
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="receiverPostCode"
                        id="receiverPostCode"
                        autoComplete="off"
                        value={values.receiverPostCode}
                        onChange={(e) => {
                          setFieldValue('receiverPostCode', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <ErrorMessage
                      name="receiverPostCode"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="region"
                      className="block text-sm text-[#CBD5E1]">
                      VAT / EIN
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="receiverVat"
                        id="receiverVat"
                        autoComplete="off"
                        value={values.receiverVat}
                        onChange={(e) => {
                          setFieldValue('receiverVat', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="h-[112px]" />
            </>
          )}
        </div>
      </div>

      <div className="flex flex-row-reverse gap-x-4 ">
        <ButtonF
          size="sm"
          color="indigo"
          className="bg-indigo-600 w-38"
          onClick={nextStep}>
          Next (Payment Details)
        </ButtonF>
        <ButtonF size="sm" color="gray" className="w-32" onClick={onClose}>
          Cancel
        </ButtonF>

        <div className="flex-1" />

        <ImportFromInvoiceToggle
          enabled={values.importFromInvoicePdf}
          setFieldValue={setFieldValue}
          onChange={_handleImportFromInvoiceToggle}
        />
      </div>
    </div>
  );
};

const useInjectVendorPaymentDetails = (pds = [], { values, setFieldValue }) => {
  const [isInjected, setIsInjected] = useState(false);
  const _getCryptoPD = () => {
    return pds.find((pd) => pd.address);
  };
  const _getBankIbanPD = () => {
    return pds.find((pd) => pd.accountIBAN);
  };
  const _getBankAchPD = () => {
    return pds.find((pd) => pd.accountACHRoutingNumber);
  };

  useEffect(() => {
    if (values.paymentMethod === 'crypto') {
      const pd = _getCryptoPD();
      setIsInjected(!!pd);
      if (pd) {
        setFieldValue('walletAddress', pd.address);
      }
    } else {
      if (values.isACHTransfer) {
        const pd = _getBankAchPD();
        setIsInjected(!!pd);
        if (pd) {
          setFieldValue(
            'bankAccountBeneficiaryName',
            pd.accountBeneficiary || ''
          );
          setFieldValue(
            'bankAccountBeneficiaryType',
            pd.bankAccountBeneficiaryType || ''
          );
          setFieldValue(
            'bankAchRoutingNumber',
            pd.accountACHRoutingNumber || ''
          );
          setFieldValue('bankAccountNumber', pd.accountNumber || '');
          setFieldValue('bankName', pd.bankName || '');
        }
      } else {
        const pd = _getBankIbanPD();
        setIsInjected(!!pd);
        if (pd) {
          setFieldValue(
            'bankAccountBeneficiaryName',
            pd.accountBeneficiary || ''
          );
          setFieldValue(
            'bankAccountBeneficiaryType',
            pd.bankAccountBeneficiaryType || ''
          );
          setFieldValue('bankSwift', pd.bankBIC || '');
          setFieldValue('bankIban', pd.accountIBAN || '');
          setFieldValue('bankName', pd.bankName || '');
        }
      }
    }
  }, [pds, values.paymentMethod, values.isACHTransfer]);

  return {
    isInjected,
  };
};

const PaymentDetailsStep = ({ values, setFieldValue }) => {
  const { previousStep, nextStep } = useWizard();

  const { data: paymentDetails, isFetching } = useOrgVendorPaymentDetails(
    ['no-value', 'manual'].includes(values.selectedVendorUserId)
      ? undefined
      : values.selectedVendorUserId
  );
  const { getBic, getBankName, isValidating } = useValidateIban(
    values.bankIban
  );

  const { isInjected } = useInjectVendorPaymentDetails(paymentDetails, {
    values,
    setFieldValue,
  });

  const validatedBankName = getBankName();
  const validatedBic = getBic();

  useEffect(() => {
    validatedBankName && setFieldValue('bankName', validatedBankName);
    validatedBic && setFieldValue('bankSwift', validatedBic);
  }, [setFieldValue, validatedBankName, validatedBic]);

  return (
    <div className="text-left space-y-8">
      <LoadingOverlay show={isFetching} />
      <div className="border-b border-white/10 pb-10">
        <h2 className="text-base font-semibold leading-7 text-white">
          Payment Details
        </h2>
        <p className="mt-1 text-sm leading-6 text-slate-400">
          Fill in the payment details to a bill request.
        </p>
        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-full">
            <label
              htmlFor="paymentMethod"
              className="block text-sm text-[#CBD5E1]">
              Is this a crypto bill or a fiat bill?
            </label>
            <div className="mt-2">
              <select
                id="paymentMethod"
                name="paymentMethod"
                value={values.paymentMethod}
                onChange={(event) => {
                  setFieldValue('paymentMethod', event.target.value);

                  if (event.target.value === 'crypto') {
                    setFieldValue('token', 'USDC');
                    setFieldValue('network', 'Ethereum');
                    setFieldValue(
                      'invoiceTotalText',
                      `${values.totalAmount} USDC`
                    );
                  } else {
                    setFieldValue('token', 'EUR');
                    setFieldValue('network', 'Fiat');
                    setFieldValue(
                      'invoiceTotalText',
                      `${values.totalAmount} EUR`
                    );
                  }
                }}
                className="block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] py-1.5 text-white shadow-sm ring-1  ring-white/10 focus:ring-0 focus:ring-inset focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black">
                <option value="crypto">Crypto Payment</option>
                <option value="fiat">Fiat Payment</option>
              </select>
            </div>
          </div>

          {values.paymentMethod === 'crypto' ? (
            <div className="sm:col-span-full">
              <CryptoNetworkTokenSelect
                network={values.network}
                token={values.token}
                onTokenChange={(t) => {
                  setFieldValue('token', t);
                }}
                onNetworkChange={(n, t) => {
                  setFieldValue('network', n);
                  setFieldValue('token', t);
                }}
              />
            </div>
          ) : (
            <>
              <div className="sm:col-span-3">
                <label
                  htmlFor="region"
                  className="block text-sm text-[#CBD5E1]">
                  Currency
                </label>
                <div className="mt-2">
                  <Field
                    as="select"
                    name="token"
                    id="token"
                    value={values.token}
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180]  bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => {
                      setFieldValue('token', event.target.value);
                    }}>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                    <option value="CHF">CHF</option>
                  </Field>
                </div>
                <ErrorMessage
                  name="token"
                  component="div"
                  className="mt-2 text-xs italic text-red-500"
                />
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="region"
                  className="block text-sm text-[#CBD5E1]">
                  Beneficiary Type
                </label>
                <div className="mt-2">
                  <Field
                    as="select"
                    name="bankAccountBeneficiaryType"
                    id="bankAccountBeneficiaryType"
                    value={values.bankAccountBeneficiaryType}
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180]  bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => {
                      setFieldValue(
                        'bankAccountBeneficiaryType',
                        event.target.value
                      );
                    }}>
                    <option value="business">Business</option>
                    <option value="individual">Individual</option>
                  </Field>
                </div>
              </div>
            </>
          )}

          {values.paymentMethod === 'crypto' ? (
            <div className="sm:col-span-full">
              <label
                htmlFor="walletAddress"
                className="block text-sm text-[#CBD5E1]">
                Wallet Address *
              </label>
              <div className="mt-2">
                <Field
                  type="text"
                  name="walletAddress"
                  id="walletAddress"
                  autoComplete="off"
                  value={values.walletAddress}
                  onChange={(e) => {
                    setFieldValue('walletAddress', e.target.value);
                  }}
                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                />
              </div>
              <ErrorMessage
                name="walletAddress"
                component="div"
                className="mt-2 text-xs text-[#e91e63]"
              />
            </div>
          ) : (
            <>
              {values.token === 'USD' && (
                <div className="sm:col-span-full">
                  <Switch.Group as="div" className="items-left flex ">
                    <Switch.Label
                      as="span"
                      className="mr-5 text-sm font-medium leading-6 text-secondary-200"
                      passive>
                      ACH Transfer
                    </Switch.Label>
                    <Switch
                      checked={values.isACHTransfer}
                      onChange={() => {
                        setFieldValue('isACHTransfer', !values.isACHTransfer);
                      }}
                      className={classNames(
                        values.isACHTransfer ? 'bg-indigo-600' : 'bg-gray-500',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                      )}>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          values.isACHTransfer
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              )}

              {values.token === 'USD' && values.isACHTransfer && (
                <>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="bankAchRoutingNumber"
                      className="block text-sm text-[#CBD5E1]">
                      Routing Number *
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="bankAchRoutingNumber"
                        id="bankAchRoutingNumber"
                        autoComplete="off"
                        value={values.bankAchRoutingNumber}
                        onChange={(e) => {
                          setFieldValue('bankAchRoutingNumber', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <ErrorMessage
                      name="bankAchRoutingNumber"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="bankAccountNumber"
                      className="block text-sm text-[#CBD5E1]">
                      Account Number *
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="bankAccountNumber"
                        id="bankAccountNumber"
                        autoComplete="off"
                        value={values.bankAccountNumber}
                        onChange={(e) => {
                          setFieldValue('bankAccountNumber', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <ErrorMessage
                      name="bankAccountNumber"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>
                </>
              )}

              {((values.token === 'USD' && !values.isACHTransfer) ||
                values.token !== 'USD') && (
                <>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="bankIban"
                      className="flex items-center text-sm text-[#CBD5E1]">
                      IBAN Number *
                      {isValidating && <Spinner className="ml-2" />}
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="bankIban"
                        id="bankIban"
                        autoComplete="off"
                        value={values.bankIban}
                        onChange={(e) => {
                          setFieldValue('bankIban', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <ErrorMessage
                      name="bankIban"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="bankSwift"
                      className="block text-sm text-[#CBD5E1]">
                      BIC / SWIFT *
                    </label>
                    <div className="mt-2">
                      <Field
                        type="text"
                        name="bankSwift"
                        id="bankSwift"
                        autoComplete="off"
                        value={values.bankSwift}
                        onChange={(e) => {
                          setFieldValue('bankSwift', e.target.value);
                        }}
                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />
                    </div>
                    <ErrorMessage
                      name="bankSwift"
                      component="div"
                      className="mt-2 text-xs text-[#e91e63]"
                    />
                  </div>
                </>
              )}

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm text-[#CBD5E1]">
                  Account Beneficiary Name *
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="bankAccountBeneficiaryName"
                    id="bankAccountBeneficiaryName"
                    autoComplete="off"
                    value={values.bankAccountBeneficiaryName}
                    onChange={(e) => {
                      setFieldValue(
                        'bankAccountBeneficiaryName',
                        e.target.value
                      );
                    }}
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="bankName"
                  className="block text-sm text-[#CBD5E1]">
                  Bank Name
                </label>
                <div className="mt-2">
                  <Field
                    type="text"
                    name="bankName"
                    id="bankName"
                    autoComplete="off"
                    value={values.bankName}
                    onChange={(e) => {
                      setFieldValue('bankName', e.target.value);
                    }}
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                  />
                </div>
                <ErrorMessage
                  name="bankName"
                  component="div"
                  className="mt-2 text-xs text-[#e91e63]"
                />
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-2 lg:col-span-2">
            <label className="block text-sm text-[#CBD5E1] ">Price *</label>
            <Field
              type="number"
              step="any"
              onWheel={(event) => event.currentTarget.blur()}
              name="amount"
              id="amount"
              value={values.amount}
              onChange={(e) => {
                const newAmount = e.target.value;
                const total = newAmount * (1 + values.taxAmount / 100);
                setFieldValue('amount', newAmount);
                setFieldValue(
                  'totalAmount',
                  values.token === 'ETH' ? total.toFixed(4) : total.toFixed(2)
                );
              }}
              className="mt-2 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <ErrorMessage
              name="amount"
              component="div"
              className="mt-2 text-xs text-[#e91e63]"
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label className="block text-sm text-[#CBD5E1] ">Tax (%)</label>
            <Field
              type="number"
              step="any"
              onWheel={(event) => event.currentTarget.blur()}
              name="taxAmount"
              id="taxAmount"
              value={values.taxAmount}
              onChange={(e) => {
                const newTaxAAmount = e.target.value;
                const total = values.amount * (1 + newTaxAAmount / 100);
                setFieldValue('taxAmount', newTaxAAmount);
                setFieldValue(
                  'totalAmount',
                  values.token === 'ETH' ? total.toFixed(4) : total.toFixed(2)
                );
              }}
              className="mt-2 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <ErrorMessage
              name="taxAmount"
              component="div"
              className="mt-2 text-xs text-[#e91e63]"
            />
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label className="block text-sm text-[#CBD5E1] ">
              Total Amount
            </label>
            <Field
              name="total"
              id="total"
              value={
                values.totalAmount +
                ' ' +
                getTokenHumanReadableNameByChain(values.token, values.network)
              }
              onChange={() => {}}
              type="text"
              step="any"
              readOnly
              disabled
              onWheel={(event) => event.currentTarget.blur()}
              className="mt-2 disabled:bg-secondary-800  disabled:text-slate-400 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d]  text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {isInjected && (
          <p className="pt-6 flex text-xs leading-6 text-slate-400">
            <InformationCircleIcon className="mr-2 w-4 text-amber-300" />
            We automatically pre-filled vendor details. Please verify their
            accuracy.
          </p>
        )}
      </div>

      <div className="flex flex-row-reverse gap-x-4 ">
        <ButtonF
          size="sm"
          color="indigo"
          className="bg-indigo-600 w-38"
          onClick={nextStep}>
          Next (Invoice File)
        </ButtonF>
        <ButtonF size="sm" color="gray" className="w-38" onClick={previousStep}>
          Back to Receiver Details
        </ButtonF>
      </div>
    </div>
  );
};

const useParseInvoice = () => {
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['parse-pdf-invoice'],
    mutationFn: async (file) => {
      const auth0token = await getAccessTokenSilently();
      const res = await parseFileRequest(auth0token, {
        attachmentFile: file,
      });
      return res.result;
    },
  });

  return {
    parse: mutation.mutateAsync,
    isParsing: mutation.isPending,
    parsed: mutation.data,
    resetParsedInvoice: mutation.reset,
  };
};

const DocumentStep = ({ values, setFieldValue, onAttachmentChange }) => {
  const { previousStep, goToStep } = useWizard();

  const handleDeleteFile = () => {
    setFieldValue('attachmentFile', '');
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFieldValue('attachmentFile', file);

    if (values.importFromInvoicePdf) {
      onAttachmentChange(file, () => {
        goToStep(0);
      });
    }
  };

  return (
    <div className="text-left space-y-8">
      <div className="border-b border-white/10 pb-10">
        <h2 className="text-base font-semibold leading-7 text-white">
          {values.importFromInvoicePdf
            ? 'Import bill from pdf invoice'
            : 'Details & Attachments'}
        </h2>
        <p className="mt-1 flex items-center text-sm leading-6 text-slate-400">
          {values.importFromInvoicePdf
            ? 'Attach an pdf invoice and we will automatically fill in the details of this bill'
            : 'Upload an attachment, add payment details & create recurring bills'}

          {values.importFromInvoicePdf && (
            <Tooltip
              content={
                <div className="w-[200px] text-slate-200">
                  When retrieving information from PDFs, we utilize third-party
                  services. For further details, please refer to our privacy
                  policy.
                </div>
              }>
              <InformationCircleIcon className="ml-2 w-4 cursor-pointer text-amber-300" />
            </Tooltip>
          )}
        </p>

        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
          <div className="col-span-full">
            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-[#4E6180]  px-6 pb-6 pt-5">
              {values.attachmentFile ? (
                <div className="space-y-1 text-center text-sm text-slate-400">
                  <p className="">{values.attachmentFile.name} uploaded</p>
                  <br />
                  <button
                    onClick={handleDeleteFile}
                    className="mr-2 inline-flex items-center rounded-lg border-2 border-[#4E6180] px-5  py-2.5 text-center text-xs font-medium text-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:bg-gray-800  dark:hover:bg-gray-700 dark:focus:ring-gray-600 ">
                    Reupload File
                  </button>
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-slate-400 "
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-slate-600 ">
                    <label
                      htmlFor="attachmentFile"
                      className="relative cursor-pointer rounded-md  font-medium text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500 dark:bg-slate-800">
                      <span>Upload a pdf file</span>
                      <input
                        onChange={handleFileUpload}
                        id="attachmentFile"
                        name="attachmentFile"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1 text-[#94A3B8]">or drag and drop</p>
                  </div>
                  <p className="text-xs text-[#94A3B8]">PDF up to 10MB</p>
                </div>
              )}
            </div>
            <ErrorMessage
              name="attachmentFile"
              component="div"
              className="mt-2 text-xs text-[#e91e63]"
            />
          </div>

          <div className="col-span-full">
            <label
              htmlFor="billDescription"
              className="block text-sm text-[#CBD5E1] ">
              Payment Reference / Services Provided *
            </label>
            <div className="mt-2">
              <Field
                name="billDescription"
                id="billDescription"
                rows={3}
                as="textarea"
                value={values.billDescription}
                placeholder="This information will be used as a payments reference for crypto-to-fiat payments and will be displayed under services provided on the invoice"
                className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) =>
                  setFieldValue('billDescription', e.target.value)
                }
              />
            </div>
            <ErrorMessage
              name="billDescription"
              component="div"
              className="mt-2 text-xs text-rose-500"
            />
            <p className="mt-2 text-xs text-slate-500 dark:text-slate-500">
              Min 5 characters
            </p>
          </div>

          <div className="col-span-6 flex-none sm:col-span-3">
            <label className="block text-sm text-[#CBD5E1] ">Invoice ID</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Field
                name="billReferenceId"
                id="billReferenceId"
                autoComplete="off"
                value={values.billReferenceId}
                onChange={(event) => {
                  setFieldValue('billReferenceId', event.target.value);
                }}
                className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="col-span-6 flex-none sm:col-span-3">
            <label className="block text-sm text-[#CBD5E1] ">Issue date</label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <Field
                type="date"
                name="billIssueDate"
                id="billIssueDate"
                autoComplete="off"
                value={values.billIssueDate}
                onChange={(event) => {
                  setFieldValue('billIssueDate', event.target.value);
                }}
                className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          {values.isRecurringBillEabled && (
            <>
              <div className="col-span-6 flex-none sm:col-span-3">
                <label className="block text-sm text-[#CBD5E1] ">
                  Repeat For
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <Field
                    as="select"
                    name="recurrenceDuration"
                    id="recurrenceDuration"
                    className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option value="1">1 Month</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>
                    <option value="7">7 Months</option>
                    <option value="8">8 Months</option>
                    <option value="9">9 Months</option>
                    <option value="10">10 Months</option>
                    <option value="11">11 Months</option>
                    <option value="12">12 Months</option>
                  </Field>
                </div>
              </div>
            </>
          )}

          <div
            className={classNames(
              'flex col-span-6 sm:col-span-3 lg:col-span-2 lg:col-span-6'
            )}>
            <Switch.Group
              as="div"
              className="flex items-center justify-between">
              <Switch
                checked={values.isRecurringBillEabled}
                onChange={() => {
                  setFieldValue(
                    'isRecurringBillEabled',
                    !values.isRecurringBillEabled
                  );
                }}
                className={classNames(
                  values.isRecurringBillEabled
                    ? 'bg-primary-600'
                    : 'bg-gray-500',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2'
                )}>
                <span
                  aria-hidden="true"
                  className={classNames(
                    values.isRecurringBillEabled
                      ? 'translate-x-5'
                      : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>

              <span className="ml-5 flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm leading-6 text-[#CBD5E1]"
                  passive>
                  Repeating Bill
                </Switch.Label>
                <Switch.Description
                  as="span"
                  className={classNames('text-sm text-[#94A3B8]')}>
                  Enable to repeat this bill every month
                </Switch.Description>
              </span>
            </Switch.Group>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse gap-x-4 ">
        <ButtonF
          size="sm"
          color="indigo"
          className="bg-indigo-600 w-38"
          type="submit">
          Create Bill
        </ButtonF>
        <ButtonF size="sm" color="gray" className="w-38" onClick={previousStep}>
          Back to payment
        </ButtonF>

        <div className="flex-1" />

        <ImportFromInvoiceToggle
          enabled={values.importFromInvoicePdf}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};
