import { toast } from "react-toastify";

function notify() {
  return toast(`Link copied to clipboard!`, {
    position: "bottom-right",
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export function handleCopy(e, invoiceId) {
  e.preventDefault();
  // copy the url to the clipboard
  navigator.clipboard.writeText(
    window.location.origin + "/pay?id=" + invoiceId
  );
  notify();
}

export function handleCopyInvoiceLink(e, invoiceId) {
  e.preventDefault();
  // copy the url to the clipboard
  navigator.clipboard.writeText(
    window.location.origin + "/public/invoices?id=" + invoiceId
  );
  notify();
}

export function formatDate(date) {
  let dateToFormat = new Date(date);
  let options = { day: "numeric", month: "long", year: "numeric" };
  let formattedDate = dateToFormat.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function formatDataForCSV(invoices) {
  return invoices.map((invoice) => ({
    id: invoice._id,
    client_name: invoice.client_name,
    payments_date: formatDate(invoice.payments_date),
    amount: invoice.amount.toFixed(2),
    status: invoice.payment.status,
    invoice_link: "http://app.getfractal.xyz/public/invoices?id=" + invoice._id,
    payment_link: "http://app.getfractal.xyz/pay?id=" + invoice._id,
  }));
}
