import axios from 'axios';
import { useEffect, useCallback } from 'react';
import { ErrorMessage, Field } from 'formik';
import { usePaymentDetails } from 'entities/organization/';
import { PaymentDetailsSummary } from 'widgets/Invoices/InvoiceGenerateForm.PaymentDetailsSummary';
import {
  extractFirstChar,
  extractLastChar,
  isValidIban,
} from 'shared/lib/string';
import { TextField } from 'shared/ui/form';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';
import { Spinner } from 'shared/ui/Spinner';

export const useValidateIban = (iban) => {
  const [debouncedIban] = useDebounce(iban, 1000);
  const { data, isFetching } = useQuery({
    queryKey: ['validate-iban', debouncedIban],
    queryFn: async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/public/iban/validate/${debouncedIban}`
      );

      return res?.data?.result;
    },
    enabled: !!debouncedIban && isValidIban(debouncedIban),
  });

  const getBic = useCallback(() => {
    return data?.data?.bank?.bic;
  }, [data]);

  const getBankName = useCallback(() => {
    return data?.data?.bank?.bank_name;
  }, [data]);

  return {
    getBic,
    getBankName,
    isValidating: isFetching,
    validationResult: data,
  };
};

export const PaymentDetails = ({ formValues, validateForm, onValueChange }) => {
  const { data: userPaymentDetails } = usePaymentDetails();
  const { isAuthenticated } = useAuth0();
  const { getBic, getBankName, isValidating } = useValidateIban(
    formValues.iban
  );

  const validatedBankName = getBankName();
  const validatedBic = getBic();

  const paymentDetailsList = (userPaymentDetails?.result || []).filter((p) =>
    formValues.crypto_payment ? p.type === 'crypto_wallet' : p.type === 'bank'
  );

  // Utils

  const _resetPaymentDetails = () => {
    onValueChange('bank_account_name_beneficiary', '');
    onValueChange('bank_name', '');
    onValueChange('bic', '');
    onValueChange('iban', '');
    onValueChange('ach_routing_number', '');
    onValueChange('account_number', '');
    onValueChange('wallet', '');
  };

  // Renderers

  const shouldShowPaymentDetailsSelect = () => isAuthenticated;

  const shouldShowCryptoForm = () =>
    formValues.crypto_payment &&
    (formValues.paymentDetails === 'new' || !isAuthenticated);
  const shouldShowBankForm = () =>
    formValues.fiat_payment &&
    (formValues.paymentDetails === 'new' || !isAuthenticated);

  const _renderPaymentDetailOption = (paymentDetail) => {
    return (
      <option key={paymentDetail.id} value={paymentDetail.id}>
        {paymentDetail.name}{' '}
        <span className="dark:text-gray-300">
          {formValues.paymentMethod === 'crypto' && (
            <>
              ({extractFirstChar(paymentDetail.details.cryptoWallet.address, 6)}{' '}
              ...{' '}
              {extractLastChar(paymentDetail.details.cryptoWallet.address, 6)})
            </>
          )}
        </span>
      </option>
    );
  };

  const renderAddOption = () => {
    return (
      <option value="new">
        {formValues.crypto_payment
          ? '+ Add New Wallet'
          : '+ Add New Bank Account'}
      </option>
    );
  };

  // Raw fix but for select we need to trigger validation manually in order to see correct errors right after change

  useEffect(() => {
    validateForm(formValues);
  }, [formValues.paymentDetails, formValues.token]);

  useEffect(() => {
    validatedBankName && onValueChange('bank_name', validatedBankName);
    validatedBic && onValueChange('bic', validatedBic);
  }, [onValueChange, validatedBankName, validatedBic]);

  return (
    <>
      {shouldShowPaymentDetailsSelect() && (
        <>
          <div className="col-span-8 sm:col-span-6">
            <label className="block text-sm text-gray-700 dark:text-[#CBD5E1]">
              Where do you want to receive your payment?
            </label>
            <Field
              name="paymentDetails"
              component="select"
              defaultValue="no-value"
              onChange={(event) => {
                onValueChange('paymentDetails', event.target.value);

                if (event.target.value === 'new') {
                  _resetPaymentDetails();
                } else {
                  const paymentDetail = paymentDetailsList.find(
                    (p) => p.id === event.target.value
                  )?.details;

                  if (formValues.crypto_payment) {
                    onValueChange(
                      'wallet',
                      paymentDetail?.cryptoWallet?.address || ''
                    );
                  } else {
                    onValueChange(
                      'bank_account_name_beneficiary',
                      paymentDetail?.bankAccount?.accountBeneficiary || ''
                    );
                    onValueChange(
                      'bank_name',
                      paymentDetail?.bankAccount?.bankName || ''
                    );
                    onValueChange(
                      'bic',
                      paymentDetail?.bankAccount?.bankBIC || ''
                    );
                    onValueChange(
                      'iban',
                      paymentDetail?.bankAccount?.accountIBAN || ''
                    );
                    onValueChange(
                      'ach_routing_number',
                      paymentDetail?.bankAccount?.accountACHRoutingNumber || ''
                    );
                    onValueChange(
                      'account_number',
                      paymentDetail?.bankAccount?.accountNumber || ''
                    );
                    onValueChange(
                      'bankAccountBeneficiaryType',
                      paymentDetail?.bankAccount?.bankAccountBeneficiaryType ||
                        'business'
                    );

                    if (paymentDetail?.bankAccount?.accountACHRoutingNumber) {
                      onValueChange('isACHTransfer', true);
                    }
                  }
                }
              }}
              className="text-[#FFFFFF mt-1 block w-full rounded-md  border-2 border-[#4E6180] bg-[#18222d] px-3 py-2 text-[#FFFFFF] shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
              <option disabled defaultValue value="no-value">
                {formValues.crypto_payment
                  ? 'Select Wallet'
                  : 'Select Bank Account'}
              </option>

              {paymentDetailsList.map((paymentDetail) =>
                _renderPaymentDetailOption(paymentDetail)
              )}

              <optgroup label="New">{renderAddOption()}</optgroup>
            </Field>
            <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
              {formValues.crypto_payment
                ? 'Find or add new wallet'
                : 'Find or add new bank account'}

              <Link
                className="ml-2 underline"
                target="_blank"
                to="/dashboard/company/payment-details">
                Manage Payment Details
              </Link>
            </p>
            {formValues.paymentDetails === 'no-value' && (
              <ErrorMessage
                name="paymentDetails"
                component="div"
                className="mt-2 text-xs italic text-rose-500"
              />
            )}
          </div>

          <PaymentDetailsSummary
            selectedPaymentDetailsId={formValues.paymentDetails}
            userPaymentDetailsList={userPaymentDetails?.result}
          />

          {formValues.paymentDetails === 'new' && (
            <div className="col-span-8 sm:col-span-6">
              <Field
                name="paymentDetailsName"
                component={TextField}
                label={
                  formValues.crypto_payment ? 'Wallet Name' : 'Your reference'
                }
                hint={
                  formValues.crypto_payment
                    ? 'This will be your reference the next time you want to use this wallet'
                    : 'This will be your reference the next time you want to use this bank details'
                }
              />
            </div>
          )}
        </>
      )}

      {shouldShowCryptoForm() && (
        <div className="col-span-6 sm:col-span-6 lg:col-span-6">
          <label className="block text-sm font-medium text-[#CBD5E1] ">
            Wallet Address *
          </label>
          <Field
            name="wallet"
            id="wallet"
            type="text"
            step="any"
            className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
          />
          <p className="mt-2 text-sm text-[#94A3B8] ">
            You can connect your wallet in the top right corner and
            automatically copy the wallet address.
          </p>
          <ErrorMessage
            name="wallet"
            component="div"
            className="mt-2 text-xs italic text-rose-500"
          />
        </div>
      )}

      {shouldShowBankForm() && (
        <>
          {formValues.token === 'USD' && (
            <div className="sm:col-span-full">
              <Switch.Group as="div" className="items-left flex ">
                <Switch.Label
                  as="span"
                  className="mr-5 text-sm font-medium leading-6 text-secondary-200"
                  passive>
                  ACH Transfer
                </Switch.Label>
                <Switch
                  checked={formValues.isACHTransfer}
                  onChange={() => {
                    onValueChange('isACHTransfer', !formValues.isACHTransfer);
                  }}
                  className={classNames(
                    formValues.isACHTransfer ? 'bg-indigo-600' : 'bg-gray-500',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formValues.isACHTransfer
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          )}

          {formValues.token === 'USD' && formValues.isACHTransfer && (
            <>
              <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                <label className="block text-sm text-[#CBD5E1] ">
                  Routing Number {formValues.token === 'USD' && '*'}
                </label>
                <Field
                  name="ach_routing_number"
                  id="ach_routing_number"
                  type="text"
                  step="any"
                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                />
                <ErrorMessage
                  name="ach_routing_number"
                  component="div"
                  className="mt-2 text-xs italic text-rose-500"
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                <label className="block text-sm text-[#CBD5E1] ">
                  Account Number {formValues.token === 'USD' && '*'}
                </label>
                <Field
                  name="account_number"
                  id="account_number"
                  type="text"
                  step="any"
                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                />
                <ErrorMessage
                  name="account_number"
                  component="div"
                  className="mt-2 text-xs italic text-rose-500"
                />
              </div>
            </>
          )}

          {((formValues.token === 'USD' && !formValues.isACHTransfer) ||
            formValues.token !== 'USD') && (
            <>
              <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                <label className="flex items-center text-sm text-[#CBD5E1] ">
                  IBAN *
                  {isValidating && (
                    <Spinner className="border-2 h-4 w-4 ml-2" />
                  )}
                </label>
                <Field
                  name="iban"
                  id="iban"
                  type="text"
                  step="any"
                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                />
                <ErrorMessage
                  name="iban"
                  component="div"
                  className="mt-2 text-xs italic text-rose-500"
                />
              </div>
              <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                <label className="block text-sm text-[#CBD5E1] ">
                  BIC / SWIFT Code *
                </label>
                <Field
                  name="bic"
                  id="bic"
                  type="text"
                  step="any"
                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                />
                <ErrorMessage
                  name="bic"
                  component="div"
                  className="mt-2 text-xs italic text-rose-500"
                />
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-6 lg:col-span-3">
            <label className="block text-sm text-[#CBD5E1] ">
              Account Beneficiary Name *
            </label>
            <Field
              name="bank_account_name_beneficiary"
              id="bank_account_name_beneficiary"
              type="text"
              step="any"
              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
            />
            <p className="mt-2 text-sm text-[#94A3B8] ">
              The name of the bank account holder.
            </p>
            <ErrorMessage
              name="bank_account_name_beneficiary"
              component="div"
              className="mt-2 text-xs italic text-rose-500"
            />
          </div>

          <div className="col-span-6 sm:col-span-6 lg:col-span-3">
            <label className="block text-sm text-[#CBD5E1] ">Bank Name *</label>
            <Field
              name="bank_name"
              id="bank_name"
              type="text"
              step="any"
              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
            />
            <p className="mt-2 text-sm text-[#94A3B8] ">
              The name of your bank.
            </p>
            <ErrorMessage
              name="bank_name"
              component="div"
              className="mt-2 text-xs italic text-rose-500"
            />
          </div>
        </>
      )}
    </>
  );
};
