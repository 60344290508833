import InvoicePay from 'widgets/Invoices/InvoicePay';

const InvoicePayPage = () => {
  return (
    <div className="mx-auto  mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <InvoicePay />
    </div>
  );
};

export default InvoicePayPage;
