import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOrganizationActions } from './actions';
import { QUERY_KEY, QUERY_KEY_PAYMENT_DETAILS } from './constants';
import { useCurrentUser } from 'entities/user/model';

export const useOrganizationMutations = () => {
  const { data, setData } = useCurrentUser();
  const queryClient = useQueryClient();
  const { createOrganizationAction } = useOrganizationActions();

  const create = useMutation({
    mutationFn: async (organization) => {
      const response = await createOrganizationAction(organization);
      return response;
    },
    onSuccess: (res) => {
      const organization = res.organization;
      setData({
        ...data,
        selected_organization: {
          name: organization.name,
          email: organization.email,
          address: organization.address,
          city: organization.city,
          country: organization.country,
          postcode: organization.postcode,
          vat: organization.vat,
          userRole:
            data?.selected_organization?.userRole == 'member'
              ? 'member'
              : 'admin',
          _id: organization._id,
        },
      });

      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  return { create };
};

export const usePaymentDetailsMutations = () => {
  const queryClient = useQueryClient();
  const {
    createPaymentDetailsAction,
    editPaymentDetailsAction,
    deletePaymentDetailsAction,
  } = useOrganizationActions();

  const createPaymentDetails = useMutation({
    mutationFn: async (data) => {
      const response = await createPaymentDetailsAction(data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PAYMENT_DETAILS] });
    },
  });

  const deletePaymentDetails = useMutation({
    mutationFn: async (id) => {
      const response = await deletePaymentDetailsAction(id);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PAYMENT_DETAILS] });
    },
  });

  const updatePaymentDetails = useMutation({
    mutationFn: async (data) => {
      const response = await editPaymentDetailsAction(data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PAYMENT_DETAILS] });
    },
  });

  return { createPaymentDetails, deletePaymentDetails, updatePaymentDetails };
};
