import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from '../../entities/user/model';

export const useFractalBankAccounts = () => {
  const { getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const query = useQuery({
    queryKey: ['fractal-bank-accounts'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/public/abstract-bank-accounts/list`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            organizationId: getCurrentOrganizationId(),
          },
        }
      );

      return response?.data?.result || [];
    },
    enabled: hasAnyOrganization,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

  return {
    bankAccounts: query?.data || [],
    isLoading: query.isPending,
    isFetching: query.isFetching,
  };
};

export const useCreateNewFractalBankAccount = () => {
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['create-new-fractal-bank-account'],
    mutationFn: async ({
      accountCurrency,
      accountName,
      accountType,
      blockchainSmartAccountData,
    }) => {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/abstract-bank-accounts`,
        {
          organizationId: getCurrentOrganizationId(),
          accountCurrency,
          accountName,
          accountType,
          blockchainSmartAccountData,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      await qc.invalidateQueries({
        queryKey: ['fractal-bank-accounts'],
      });
    },
  });

  return {
    createNewFractalBankAccount: mutation.mutateAsync,
    isPending: mutation.isPending,
  };
};
