import { useState, useEffect } from 'react';
import axios from 'axios';
import { SpinnerIcon } from 'shared/ui/Spinner';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';

export const CheckWalletInteractionWidget = ({
  address,
  bill,
  isConnected,
}) => {
  const [walletInteractedWithStatus, setWalletInteractedWithStatus] =
    useState('');
  const [
    walletInteractedWithStatusIsLoading,
    setWalletInteractedWithStatusIsLoading,
  ] = useState(false);

  const checkWalletInteractionStatus = async () => {
    setWalletInteractedWithStatusIsLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/aml/get-interacted-addresses',
        {
          params: {
            walletAddress: address,
            recepeintAddress: bill.walletAddress,
            network: bill.network,
          },
        }
      );

      if (response.data.message === true) {
        setWalletInteractedWithStatus(true);
      } else if (response.data.message === false) {
        setWalletInteractedWithStatus(false);
      }

      setWalletInteractedWithStatusIsLoading(false);
    } catch (err) {
      setWalletInteractedWithStatusIsLoading(false);
    }
  };

  useEffect(() => {
    if (isConnected) {
      checkWalletInteractionStatus();
    }
  }, [isConnected]);

  return (
    <div className="grid grid-cols-2 grid-rows-2 items-center py-2 text-left text-sm font-medium">
      <span className="text-slate-300">Interacted With</span>
      <span className="text-right text-gray-100">
        {walletInteractedWithStatusIsLoading ? (
          <div className="flex justify-end">
            <SpinnerIcon className="mr-3" />
          </div>
        ) : isConnected !== undefined && !isConnected ? (
          'N/A'
        ) : walletInteractedWithStatus ? (
          <span className="font-medium text-green-400">
            <CheckCircleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
            Yes
          </span>
        ) : (
          <span className="font-medium text-yellow-300">
            <ExclamationTriangleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
            No
          </span>
        )}
      </span>
      <span className="col-span-2 text-xs text-slate-400">
        {walletInteractedWithStatusIsLoading ? (
          <span>Loading...</span>
        ) : isConnected !== undefined && !isConnected ? (
          'Connect your wallet to check if you sent funds to this wallet before.'
        ) : walletInteractedWithStatus ? (
          'You sent funds to this wallet before.'
        ) : (
          'You have not sent funds to this wallet before.'
        )}
      </span>
    </div>
  );
};
