import axios from 'axios';
import { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const fetchInvoiceDetails = async (params, invoiceId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/invoices/${invoiceId}/shared`,
      {
        params,
        headers: { 'Content-Type': 'application/json' },
      }
    );
    return response.data.result;
  } catch (error) {
    throw error;
  }
};

export const useInvoiceViaPaymentAuthCode = (invoiceId, pac) => {
  const { data, isLoading, isError } = useQuery({
    queryFn: () => fetchInvoiceDetails({ pac }, invoiceId),
    queryKey: ['invoice-via-pac'],
  });

  return {
    invoiceData: data,
    isLoading,
    isError,
  };
};

export const useMarkInvoiceAsPaid = (invoiceId, pac) => {
  const { isSuccess, mutateAsync, isPending } = useMutation({
    mutationFn: ({ txHash }) =>
      axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/public/invoices/${invoiceId}/shared/mark-as-paid`,
        {
          cryptoPaymentTransactionHash: txHash,
        },
        {
          params: {
            pac,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ),
    mutationKey: ['mark-invoice-as-paid'],
  });

  return {
    markInvoiceAsPaid: mutateAsync,
    isLoading: isPending,
    isSuccess,
  };
};

export const useInvalidateInvoice = () => {
  const queryClient = useQueryClient();
  const invalidate = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['invoice-via-pac'] });
  }, [queryClient]);

  return {
    invalidate,
  };
};
