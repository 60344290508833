import classnames from 'classnames';
import { Spinner } from 'shared/ui/Spinner/Spinner';

export const ContentCotainer = ({ children, className }) => {
  return (
    <div
      className={classnames('mx-auto mb-40 px-4 sm:px-6 md:px-8', className)}>
      {children}
    </div>
  );
};

export const ContentLoadingState = ({ className }) => {
  return (
    <div
      className={classnames(
        'h-full md:py-16 w-full flex items-center justify-center',
        className
      )}>
      <Spinner size="xl" color="indigo" />
    </div>
  );
};
