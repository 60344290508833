import { useState } from 'react';
import { Link } from 'react-router-dom';
import logoPurple2 from 'shared/images/logoPurpleBlackBackground2.png';
import { classNames } from 'shared/lib';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';

export const Sidebar = ({ navigation }) => {
  const findCurrentMenuName = () =>
    navigation.find((item) => item.current)?.name;

  const [menuOpened, setMenuOpened] = useState(findCurrentMenuName());
  const [currentRouteMenuOpened, setCurrentRouteMenuOpened] = useState(true);

  const handleMenuOpen = (nextItemName) => {
    if (nextItemName === findCurrentMenuName()) {
      setCurrentRouteMenuOpened(!currentRouteMenuOpened);
    } else {
      setMenuOpened(menuOpened === nextItemName ? null : nextItemName);
    }
  };

  const handleLinkClick = (subItem) => {
    if (currentRouteMenuOpened) {
      return;
    }

    const current = navigation.find((item) => item.current);
    const next = navigation.find((item) =>
      (item.children || []).some((c) => c.href === subItem.href)
    );

    if (current && next && current.name !== next.name) {
      setCurrentRouteMenuOpened(true);
    }
  };

  const isPanelOpened = (menuItem) => {
    if (menuItem.current) {
      return currentRouteMenuOpened;
    }
    return menuOpened === menuItem.name;
  };

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-[#1b2431] bg-gray-900 px-6 shadow-2xl transition-all">
      <div className="flex h-16 shrink-0 items-center justify-center">
        <img className="w-[132px]" src={logoPurple2} alt="Fractal 3" />
      </div>

      <nav className="noselect flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  {!item.children ? (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-800 text-white'
                          : 'hover:bg-gray-800 hover:text-white',

                        'flex w-full cursor-pointer items-center gap-x-2 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-400'
                      )}>
                      <item.icon
                        className={classNames('h-6 w-6 flex-shrink-0 ')}
                        aria-hidden="true"
                      />
                      {item.name}

                      {!!item.notificationBadge && <item.notificationBadge />}
                    </Link>
                  ) : (
                    <div>
                      <div
                        onClick={() => handleMenuOpen(item.name)}
                        className={classNames(
                          item.current
                            ? ''
                            : 'hover:bg-gray-800 hover:text-white',
                          'flex w-full cursor-pointer items-center gap-x-2 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-400'
                        )}>
                        <item.icon
                          className="h-6 w-6 shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {item.name}

                        {!!item.notificationBadge && <item.notificationBadge />}
                        <ChevronRightIcon
                          className={classNames(
                            isPanelOpened(item)
                              ? 'rotate-90 text-gray-500'
                              : 'text-gray-400',
                            'ml-auto h-5 w-5 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                      </div>
                      <Transition
                        show={isPanelOpened(item)}
                        className="overflow-hidden transition-all duration-200"
                        enterFrom="transform opacity-0 max-h-0"
                        enterTo="transform opacity-100 max-h-[260px]"
                        leaveFrom="transform opacity-100 max-h-[260px]"
                        leaveTo="transform opacity-0 max-h-0">
                        <ul>
                          {item.children.map((subItem) => (
                            <li key={subItem.name}>
                              <Link
                                key={subItem.name}
                                to={subItem.href}
                                onClick={() => {
                                  handleLinkClick(subItem);
                                }}
                                className={classNames(
                                  subItem.current
                                    ? 'bg-gray-800 text-white'
                                    : 'hover:bg-gray-800 hover:text-white',
                                  'mb-1 flex items-center rounded-md py-2 pl-10 pr-2 text-sm leading-6 text-gray-400'
                                )}>
                                {subItem.name}

                                {!!subItem.notificationBadge && (
                                  <subItem.notificationBadge />
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </Transition>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};
