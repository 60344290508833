import {
  useCurrentUser,
  useUserMutations,
  useUsers,
} from 'entities/user/model';
import { useEffect, useState } from 'react';
import { ROLES } from 'shared/hooks';
import { Button } from '../../shared/ui/Button/Button';
import { toast } from 'react-toastify';
import { TextInput } from '../../shared/ui/form';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Checkbox } from '../../shared/ui/form/Checkbox';

const CreateCompanyUsersForm = ({ organization }) => {
  const { data } = useCurrentUser();
  const { data: users } = useUsers();
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [userIsAdmin, setUserIsAdmin] = useState(
    data?.selected_organization?.userRole === ROLES.ADMIN ? true : false
  );
  const [role, setRole] = useState(userIsAdmin ? ROLES.ADMIN : ROLES.MEMBER);
  const { createUser } = useUserMutations();

  useEffect(() => {
    setUserIsAdmin(
      data?.selected_organization?.userRole == ROLES.ADMIN ? true : false
    );
  }, [data]);

  useEffect(() => {
    setRole(userIsAdmin ? ROLES.ADMIN : ROLES.MEMBER);
  }, [userIsAdmin]);

  const handleUpdateUsers = async (e) => {
    e.preventDefault();

    if (!userEmail) {
      setEmailError('Please enter valid email');
      return;
    } else if ((users || []).some((u) => u.email === userEmail)) {
      setEmailError('User with such email already exists');
      return;
    }

    createUser
      .mutateAsync({
        data,
        email: userEmail,
        role,
      })
      .then(() => {
        toast.success('Successfully Invited User');
        setUserEmail('');
      })
      .catch(() => {
        toast.error('Failed to invite user', {
          autoClose: 6000,
        });
      });
  };

  const handleRoleChange = (e) => {
    setRole(e.target.id);
  };

  return (
    <div className="mt-10 sm:mt-0">
      <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-white ">
              Organization Members
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
              Add team members to your organization. They will be able to access
              your payments.
            </p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0 ">
            <form>
              <div className="sm:rounded-md">
                <div className="space-y-6 bg-white  dark:bg-slate-800 ">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-12">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-[#CBD5E1]">
                        Email
                      </label>
                      <div className="mt-1 flex text-rose-600 rounded-md shadow-sm">
                        <TextInput
                          id="email"
                          name="email"
                          color={'darkSlate'}
                          icon={(props) => (
                            <EnvelopeIcon
                              {...props}
                              className="w-5 text-slate-600"
                            />
                          )}
                          value={userEmail}
                          className="mt-1 flex-1"
                          onChange={(e) => {
                            setEmailError('');
                            setUserEmail(e.target.value);
                          }}
                          placeholder="Type user email..."
                        />
                      </div>
                      {!!emailError && (
                        <p className="mt-2 text-xs italic text-rose-500">
                          {emailError}
                        </p>
                      )}
                      <p className="mt-2 text-xs text-[#94A3B8]">
                        Email of the user you want to add to your organization.
                      </p>
                    </div>
                  </div>
                  <fieldset className="col-span-6">
                    <legend className="block text-sm font-medium text-[#CBD5E1]">
                      Assign Roles
                    </legend>
                    <div className="ml-1 ">
                      {userIsAdmin && (
                        <div className="mt-1 space-y-4">
                          <div className="flex items-center">
                            <div className="flex h-5 items-center">
                              <Checkbox
                                checked={role === ROLES.ADMIN}
                                onChange={(e) => handleRoleChange(e)}
                                id="admin"
                                name="admin"
                                color={'indigo'}
                              />
                            </div>

                            <div className="ml-3 mt-1 text-sm">
                              <label
                                htmlFor="comments"
                                className=" text-gray-700 dark:text-white">
                                Admin
                              </label>
                              <p className=" text-xs text-[#94A3B8]">
                                Admin can manage users, change organization
                                settings & view all bills.
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-1 space-y-4">
                        <div className="flex items-center">
                          <div className="flex h-5 items-center">
                            <Checkbox
                              checked={role === ROLES.MEMBER}
                              onChange={(e) => handleRoleChange(e)}
                              disabled={!userIsAdmin}
                              id="member"
                              name="member"
                              color={'indigo'}
                            />
                          </div>

                          <div className="ml-3 mt-1 text-sm">
                            <label
                              htmlFor="comments"
                              className=" text-gray-700 dark:text-white ">
                              Member
                            </label>
                            <p className=" text-xs text-[#94A3B8]">
                              {data?.role === ROLES.MEMBER
                                ? 'Add other members to an organization.'
                                : 'Member can only manage their own bills & bills that require their approval. They cannot manage users or organization settings.'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 space-y-4">
                        <div className="flex items-center">
                          <div className="flex h-5 items-center">
                            <Checkbox
                              checked={role === ROLES.ACCOUNTANT}
                              onChange={(e) => handleRoleChange(e)}
                              disabled={!userIsAdmin}
                              id={ROLES.ACCOUNTANT}
                              name={ROLES.ACCOUNTANT}
                              color={'indigo'}
                            />
                          </div>

                          <div className="ml-3 mt-1 text-sm">
                            <label
                              htmlFor="comments"
                              className=" text-gray-700 dark:text-white ">
                              Accountant
                            </label>
                            <p className=" text-xs text-[#94A3B8]">
                              Accountant role can only view and reconcile bills
                              and invoices.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className="bg-gray-50 flex justify-end  py-5 text-right dark:bg-slate-800">
                  <Button
                    isProcessing={createUser.isPending}
                    disabled={createUser.isPending}
                    color={'indigo'}
                    onClick={handleUpdateUsers}>
                    Invite User
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCompanyUsersForm;
