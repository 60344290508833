import {
  BanknotesIcon,
  CreditCardIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export default function EmptyBillsComponent({ onAddNewBill }) {
  return (
    <div className="mt-20 text-center">
      <svg className="mx-auto h-12 w-12 ">
        <BanknotesIcon
          className="mx-auto h-12 w-12 text-gray-400"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-400">No Bills</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new payment link. <br />
        Or simply make a payment directly through Fractal.
      </p>
      <div className="mt-6 flex justify-center gap-4">
        <Link to="/dashboard/bills/links/create-link">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <LinkIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Create Payment Link
          </button>
        </Link>

        <button
          type="button"
          onClick={onAddNewBill}
          className="inline-flex items-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
          <CreditCardIcon
            className="-ml-0.5 mr-1.5 h-5 w-5"
            aria-hidden="true"
          />
          Add New Bill
        </button>
      </div>
    </div>
  );
}
