import React from 'react';
import InvoiceGenerateForm from 'widgets/Invoices/InvoiceGenerateForm';
import { useInvoice } from 'entities/invoice/model';
import { useSearchParams } from 'react-router-dom';
import { ContentLoadingState } from 'shared/ui/layouts/PageContent';

const PageEditInvoice = () => {
  let [searchParams] = useSearchParams();
  const { data: invoice, isLoading } = useInvoice(
    searchParams.get('invoiceId')
  );

  if (isLoading) {
    return <ContentLoadingState />;
  }

  return (
    <div className="mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <InvoiceGenerateForm invoice={invoice} />
    </div>
  );
};

export default PageEditInvoice;
