import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCurrentUserDetails } from 'entities/user/model';
import { Button } from 'shared/ui/Button/Button';
import React, { useState } from 'react';
import { ConfirmationSection } from 'widgets/Payments/PaymentModal.ConfirmationSection';
import { FailedPayment } from 'widgets/Payments/CryptoToFiatPaymentModal.SuccessConfirmation';
import { Alert } from 'shared/ui/Alert/Alert';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import {
  TokenLogoMap,
  NetworkLogoMap,
  getTokenHumanReadableName,
} from '../../bill/lib';
import { useTransfers } from '../../transfers/useTransfers';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { paymentProviderIdMethodsMap } from '../PaymentProviderIdTypes';
import {
  Flex,
  Form as AForm,
  Select as ASelect,
  Modal as AModal,
  Typography,
} from 'antd';
const { Text } = Typography;

// const testOnrampData = {
//   depositAmount: 11,
//   depositCurrency: 'USD',
//   depositRails: 'ach_push',
//   depositRef: 'BRGBA7MZTZQSRJ4G8568',
//   feeAmount: '1.0',
//   depositBankAccount: {
//     accountACHRoutingNumber: '101206101',
//     accountBeneficiary: 'Bridge Ventures Inc',
//     accountNumber: '1000682035',
//     bankAccountBeneficiaryType: 'business',
//     bankAddress: '1801 Main St., Kansas City, MO 64108',
//     bankName: 'Lead Bank',
//   },
// };

const FiatCryptoPaymentModal = ({
  initOnrampDetails,
  onInitOnramp,
  onrampInitiating,
  onrampFailed = false,
  payouts,
  onClose,
  paymentProviderConfig,
}) => {
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const [depositCurrency, setDepositCurrency] = useState();
  const [depositRails, setDepositRails] = useState();

  const _handleConfirm = (val) => {
    setIsConfirmedByUser(val);
  };

  const _handleCopyParam = (val) => {
    navigator.clipboard.writeText(val);
    toast.success('Copied to clipboard!');
  };

  const renderDepositDetails = () => {
    if (!initOnrampDetails) {
      return null;
    }

    return (
      <div className={'pt-4'}>
        <Alert color="slate">
          <Text className={'!text-slate-300'} underline>
            Deposit instructions:
          </Text>
          <br />
          Please make an{' '}
          {initOnrampDetails.depositBankAccount.accountACHRoutingNumber
            ? 'ACH / Wire'
            : 'EUR'}{' '}
          transfer to the following address to complete an onramp. The
          transaction normally takes 1-3 working days to complete.
        </Alert>

        <dl className="divide-y mt-2 divide-white/10">
          <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
            <dt className="text-sm text-left leading-6 text-slate-400">
              Deposit Amount
            </dt>
            <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
              {initOnrampDetails.depositAmount}
            </dd>
          </div>
          <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
            <dt className="text-sm  text-left  leading-6 text-slate-400">
              Currency
            </dt>
            <dd className="mt-1 text-sm text-right  leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
              {initOnrampDetails.depositCurrency}
            </dd>
          </div>
          <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
            <dt className="text-sm  text-left  leading-6 text-slate-400">
              Rails
            </dt>
            <dd className="mt-1 text-sm text-right  leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
              {initOnrampDetails.depositRails}
            </dd>
          </div>

          <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
            <dt className="text-sm text-left leading-6 text-slate-400">
              Bank Address
            </dt>
            <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
              {initOnrampDetails.depositBankAccount.bankAddress}
            </dd>
          </div>

          <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
            <dt className="text-sm text-left leading-6 text-slate-400">
              Bank Name
            </dt>
            <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
              {initOnrampDetails.depositBankAccount.bankName}
            </dd>
          </div>

          <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
            <dt className="text-sm text-left leading-6 text-slate-400">
              Beneficiary Name
            </dt>
            <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
              {initOnrampDetails.depositBankAccount.accountBeneficiary}
            </dd>
          </div>

          {initOnrampDetails.depositBankAccount.accountACHRoutingNumber && (
            <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
              <dt className="text-sm text-left leading-6 text-slate-400">
                Routing Number
              </dt>
              <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
                {initOnrampDetails.depositBankAccount.accountACHRoutingNumber}

                <button
                  type="button"
                  className="ml-1 text-slate-400"
                  onClick={() =>
                    _handleCopyParam(
                      initOnrampDetails.depositBankAccount
                        .accountACHRoutingNumber
                    )
                  }>
                  <ClipboardDocumentIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </dd>
            </div>
          )}

          {initOnrampDetails.depositBankAccount.accountNumber && (
            <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
              <dt className="text-sm text-left leading-6 text-slate-400">
                Account Number
              </dt>
              <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
                {initOnrampDetails.depositBankAccount.accountNumber}

                <button
                  type="button"
                  className="ml-1 text-slate-400"
                  onClick={() =>
                    _handleCopyParam(
                      initOnrampDetails.depositBankAccount.accountNumber
                    )
                  }>
                  <ClipboardDocumentIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </dd>
            </div>
          )}

          {initOnrampDetails.depositBankAccount.accountIBAN && (
            <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
              <dt className="text-sm text-left leading-6 text-slate-400">
                IBAN
              </dt>
              <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
                {initOnrampDetails.depositBankAccount.accountIBAN}

                <button
                  type="button"
                  className="ml-1 text-slate-400"
                  onClick={() =>
                    _handleCopyParam(
                      initOnrampDetails.depositBankAccount.accountIBAN
                    )
                  }>
                  <ClipboardDocumentIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </dd>
            </div>
          )}

          {initOnrampDetails.depositBankAccount.bankBIC && (
            <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
              <dt className="text-sm text-left leading-6 text-slate-400">
                SWIFT Code
              </dt>
              <dd className="mt-1 flex justify-end text-sm text-right leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
                {initOnrampDetails.depositBankAccount.bankBIC}

                <button
                  type="button"
                  className="ml-1 text-slate-400"
                  onClick={() =>
                    _handleCopyParam(
                      initOnrampDetails.depositBankAccount.bankBIC
                    )
                  }>
                  <ClipboardDocumentIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </dd>
            </div>
          )}

          {initOnrampDetails.depositRef && (
            <>
              <div className="px-2 py-2 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
                <dt className="text-sm  text-left  leading-6 text-slate-400">
                  Deposit Message
                </dt>
                <dd className="mt-1 flex justify-end text-sm text-right  leading-6 text-slate-200 sm:col-span-2 sm:mt-0">
                  {initOnrampDetails.depositRef}

                  <button
                    type="button"
                    className="ml-1 text-slate-400"
                    onClick={() =>
                      _handleCopyParam(initOnrampDetails.depositRef)
                    }>
                    <ClipboardDocumentIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </dd>
              </div>
              <p className="pt-4 text-left flex text-xs  text-slate-400">
                <InformationCircleIcon className="mr-2 w-4 text-amber-300" />
                <span className="flex-1">
                  Please include "Deposit Message" as payment reference / memo
                  when making a bank transfer. Alternatively, the payment will
                  be refunded.
                </span>
              </p>
            </>
          )}
        </dl>
      </div>
    );
  };

  const _handleOnramp = () => {
    onInitOnramp({ depositCurrency, depositRails });
  };

  const renderRecipientDetails = () => {
    if (initOnrampDetails) {
      return null;
    }

    return (
      <div className={'pt-4'}>
        {payouts.map((p) => (
          <div className="mt-2">
            <div className="flex flex-col items-start">
              <div className="text-xs font-normal text-slate-400">
                Recipient
              </div>
              <div className="mt-1 mb-1 text-sm font-medium text-slate-300">
                {p.walletAddress}
              </div>
              <div className="flex items-center text-xs text-slate-400">
                <div className="mr-1 h-4 w-4">
                  <img
                    src={NetworkLogoMap[p.network]}
                    alt=""
                    aria-hidden="true"
                  />
                </div>
                {p.network}
              </div>
            </div>

            <div className="mt-4 flex flex-col">
              <div className="mb-1 flex items-end justify-between">
                <div>
                  <div className=" text-left text-xs font-normal text-slate-400">
                    Token
                  </div>

                  <div className="mt-1 flex items-center justify-between text-xs text-slate-400">
                    <div className="mr-1 h-4 w-4">
                      <img
                        src={TokenLogoMap[p.currency]}
                        alt=""
                        aria-hidden="true"
                      />
                    </div>
                    <div className=" text-sm font-medium text-slate-300">
                      {getTokenHumanReadableName(p.currency)}
                    </div>
                  </div>
                </div>
                <div className="text-sm font-medium text-slate-300">
                  {p.amountStr}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="my-4 border-b border-slate-600"></div>

        <Flex vertical={true}>
          <AForm.Item layout="vertical" label="Deposit Currency">
            <ASelect
              placeholder={'Select Currency'}
              value={depositCurrency}
              onChange={(value) => {
                setDepositCurrency(value);
              }}
              options={paymentProviderConfig.availableDepositCurrencies.map(
                (c) => ({
                  value: c,
                  label: c,
                })
              )}
            />
          </AForm.Item>

          <AForm.Item layout="vertical" label="Deposit Rails">
            <ASelect
              placeholder={'Select Rails'}
              value={depositRails}
              onChange={(value) => {
                setDepositRails(value);
              }}
              options={
                depositCurrency === 'USD'
                  ? [
                      {
                        value: 'ach_push',
                        label: 'ACH (2-4 working days)',
                      },
                      {
                        value: 'wire',
                        label: 'WIRE (1-2 working days)',
                      },
                    ]
                  : [
                      {
                        value: 'local',
                        label: 'LOCAL',
                      },
                    ]
              }
            />
          </AForm.Item>
        </Flex>

        <div className="my-4 border-b border-slate-600"></div>
      </div>
    );
  };

  return (
    <AModal
      title="Transfer Payment Completion"
      open
      footer={null}
      centered
      destroyOnClose
      onCancel={onClose}>
      <FailedPayment onClose={onClose} show={onrampFailed} />
      {renderRecipientDetails()}
      {renderDepositDetails()}

      {!initOnrampDetails && (
        <ConfirmationSection
          isConfirmed={isConfirmedByUser}
          setIsConfirmed={() => {}}
          onConfirmationChange={_handleConfirm}
        />
      )}

      {!!initOnrampDetails ? (
        <>
          <div className="mt-6 sm:grid sm:grid-cols-1">
            <Button color="indigo" onClick={onClose}>
              Back To App
            </Button>
          </div>
        </>
      ) : (
        <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-3">
          <Button color="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="indigo"
            isProcessing={onrampInitiating}
            disabled={!isConfirmedByUser || onrampInitiating}
            onClick={_handleOnramp}>
            Pay with Fiat
          </Button>
        </div>
      )}
    </AModal>
  );
};

const initOnramp = async (
  token,
  payload,
  entityType,
  entityId,
  providerId = 'payso'
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/public/onramp/${entityType}/${entityId}`,
    {
      ...payload,
      providerId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data?.result;
};

const useInitOnrampForPaymentEntity = (entityType, entityId) => {
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const [isError, setIsError] = useState(false);
  const { isPending, mutateAsync, data } = useMutation({
    mutationKey: ['init-onramp'],
    mutationFn: ({ depositCurrency, depositRails, providerId }) =>
      getAccessTokenSilently()
        .then((token) =>
          initOnramp(
            token,
            {
              depositCurrency,
              depositRails,
              organizationId: getCurrentOrganizationId(),
            },
            entityType,
            entityId,
            providerId
          )
        )
        .catch((err) => {
          console.log(err);
          setIsError(true);
        }),
  });

  return {
    onrampData: data,
    initOnramp: mutateAsync,
    isLoading: isPending,
    isError,
  };
};

export const FiatCryptoBillPaymentModal = ({
  billId,
  payouts = [],
  onClose,
  onOnrampInited,
  paymentProviderConfig,
}) => {
  const { initOnramp, onrampData, isLoading, isError } =
    useInitOnrampForPaymentEntity('bill', billId);

  const _handleInitOnramp = ({ depositCurrency, depositRails }) => {
    initOnramp({
      depositCurrency,
      depositRails,
      providerId: paymentProviderIdMethodsMap[paymentProviderConfig?.id],
    }).then(() => {
      onOnrampInited && onOnrampInited();
    });
  };

  return (
    <FiatCryptoPaymentModal
      initOnrampDetails={onrampData}
      onInitOnramp={_handleInitOnramp}
      onrampInitiating={isLoading}
      onrampFailed={isError}
      payouts={payouts}
      onClose={onClose}
      paymentProviderConfig={paymentProviderConfig}
    />
  );
};

export const FiatCryptoTransfersPaymentModal = ({
  transfers,
  onClose,
  paymentProviderConfig,
  payouts = [],
}) => {
  const { initOnramp, onrampData, isLoading, isError } =
    useInitOnrampForPaymentEntity('transfer', transfers[0].id);
  const { invalidate } = useTransfers();
  const providerIdMap = {
    'payso-onramp': 'payso',
    'easy-pay-onramp': 'easy_pay',
    'red-envelope-onramp': 'red_envelope',
    'bridge-onramp': 'bridge',
  };

  const _handleInitOnramp = ({ depositCurrency, depositRails }) => {
    initOnramp({
      depositCurrency,
      depositRails,
      providerId: providerIdMap[paymentProviderConfig?.id],
    }).then(() => {
      return invalidate();
    });
  };

  return (
    <FiatCryptoPaymentModal
      initOnrampDetails={onrampData}
      onInitOnramp={_handleInitOnramp}
      onrampInitiating={isLoading}
      onrampFailed={isError}
      payouts={payouts}
      onClose={onClose}
      paymentProviderConfig={paymentProviderConfig}
    />
  );
};
