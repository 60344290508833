import EurLogo from 'shared/images/eur.png';

export const FiatPaymentInformation = ({
  title = 'Recipient details',

  beneficiaryName,
  accountNumber,
  bankRoutingNumber,
  bankSwiftCode,
  bankIban,

  moneriumBalance,
}) => {
  return (
    <div className=" text-center ">
      <h3
        as=""
        className="mb-5 text-base font-semibold leading-6 text-slate-300">
        {title}
      </h3>

      {beneficiaryName && (
        <div className="mt-2">
          <div className="flex flex-col items-start">
            <div className="text-xs font-normal text-slate-400">
              Beneficiary Name
            </div>
            <div className="mb-1 text-sm font-semibold text-slate-300">
              {beneficiaryName}
            </div>
          </div>
        </div>
      )}

      {accountNumber && (
        <div className="mt-2">
          <div className="flex flex-col items-start">
            <div className="text-xs font-normal text-slate-400">
              Account Number
            </div>
            <div className="mb-1 text-sm font-semibold text-slate-300">
              {accountNumber}
            </div>
          </div>
        </div>
      )}

      {bankRoutingNumber && (
        <div className="mt-2">
          <div className="flex flex-col items-start">
            <div className="text-xs font-normal text-slate-400">
              Routing Number
            </div>
            <div className="mb-1 text-sm font-semibold text-slate-300">
              {bankRoutingNumber}
            </div>
          </div>
        </div>
      )}

      {bankIban && (
        <div className="mt-2">
          <div className="flex flex-col items-start">
            <div className="text-xs font-normal text-slate-400">IBAN</div>
            <div className="mb-1 text-sm font-semibold text-slate-300">
              {bankIban}
            </div>
          </div>
        </div>
      )}

      {bankSwiftCode && (
        <div className="mt-2">
          <div className="flex flex-col items-start">
            <div className="text-xs font-normal text-slate-400">SWIFT/BIC</div>
            <div className="mb-1 text-sm font-semibold text-slate-300">
              {bankSwiftCode}
            </div>
          </div>
        </div>
      )}

      {moneriumBalance && (
        <div className="mt-2">
          <div className="flex flex-col items-start">
            <div className="text-xs font-normal text-slate-400">
              Current Monerium balance:
            </div>
            <div className="mb-1 flex items-center text-sm font-semibold text-slate-300">
              {moneriumBalance.amount}

              <div className="w-4">
                <img src={EurLogo} alt="Euro" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="my-4 border-b border-slate-600" />
    </div>
  );
};

export const MultipleBankPaymentInformation = ({
  title = 'Recipient details',
  multipleBankDetails,
}) => {
  return (
    <>
      <h3
        as=""
        className="mb-5 text-base font-semibold leading-6 text-slate-300">
        {title}
      </h3>

      {multipleBankDetails.map(
        ({ beneficiaryName, bankIban, accountNumber, bankRoutingNumber }) => (
          <>
            {beneficiaryName && (
              <>
                <div className="mt-2">
                  <div className="flex flex-row items-center">
                    <div className="text-xs mr-1 font-normal text-slate-400">
                      Beneficiary Name:
                    </div>
                    <div className="text-xs font-normal text-slate-300">
                      {beneficiaryName}
                    </div>
                  </div>
                </div>
                {bankIban && (
                  <>
                    <div className="mt-2">
                      <div className="flex flex-row items-center">
                        <div className="text-xs mr-1 font-normal text-slate-400">
                          IBAN:
                        </div>
                        <div className="text-xs font-normal text-slate-300">
                          {bankIban}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {accountNumber && (
                  <>
                    <div className="mt-2">
                      <div className="flex flex-row items-center">
                        <div className="text-xs mr-1 font-normal text-slate-400">
                          Account Number:
                        </div>
                        <div className="text-xs font-normal text-slate-300">
                          {accountNumber}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {bankRoutingNumber && (
                  <>
                    <div className="mt-2">
                      <div className="flex flex-row items-center">
                        <div className="text-xs mr-1 font-normal text-slate-400">
                          Routing Number:
                        </div>
                        <div className="text-xs font-normal text-slate-300">
                          {bankRoutingNumber}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="my-4 border-b border-slate-600" />
              </>
            )}
          </>
        )
      )}
    </>
  );
};
