class OrgSettings {
  #settings;
  constructor(settings) {
    this.#settings = settings;
  }

  getPreferredOnrampMethod() {
    return this.#settings?.payments?.preferredOnrampMethod || 'bridge';
  }
}

export const createOrgSettingsFactory = (data) => {
  return new OrgSettings(data);
};
