import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';

const typeStyles = {
  success: 'text-success-300',
  warning: 'text-warning-300',
  danger: 'text-danger-300',
  info: 'text-info-300',
};

const typeIcons = {
  success: (
    <CheckCircleIcon
      className={twMerge('h-5 w-5', typeStyles.success)}
      aria-hidden
    />
  ),
  warning: (
    <ExclamationTriangleIcon
      className={twMerge('h-5 w-5', typeStyles.warning)}
      aria-hidden
    />
  ),
  danger: (
    <XCircleIcon
      className={twMerge('h-5 w-5', typeStyles.danger)}
      aria-hidden
    />
  ),
  info: (
    <InformationCircleIcon
      className={twMerge('h-5 w-5', typeStyles.info)}
      aria-hidden
    />
  ),
};

export const Alert = ({ type = 'info', children, className }) => {
  return (
    <div className={twMerge('rounded-md bg-gray-700 p-4', className)}>
      <div className="flex">
        <div className="flex-shrink-0">{typeIcons[type]}</div>
        <div className={twMerge('ml-3', typeStyles[type])}>{children}</div>
      </div>
    </div>
  );
};
