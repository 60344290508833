import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import * as amplitude from "@amplitude/analytics-browser";

function DMFounderButton() {
  const location = window.location.origin + window.location.pathname;

  const validURLStarts = [
    "http://localhost:3000/links/",
    "https://app.getfractal.xyz/links/",
    "https://development.getfractal.xyz/links/",
  ];

  const isPaymentRequestPage = validURLStarts.some((start) =>
    location.startsWith(start)
  );

  const handleClick = () => {
    // Log the event to Amplitude
    amplitude.logEvent("DM Founder Button Clicked");

    window.open("https://t.me/codingfractal", "_blank", "noopener,noreferrer");
  };

  if (!isPaymentRequestPage) {
    return null;
  }

  return (
    <button
      onClick={handleClick}
      className="ml-4 hidden transform items-center rounded-xl bg-slate-800 px-4 py-[9px] font-semibold text-white transition-transform duration-300 hover:scale-105 md:flex">
      <PaperAirplaneIcon className="mr-2 h-4 w-4" />
      DM Founder
    </button>
  );
}

export default DMFounderButton;
