import { KeyIcon } from '@heroicons/react/24/solid';
import { Schema, useLinkMutations } from 'entities/link/model';
import { useCurrentUser, useUsers } from 'entities/user/model';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { generateRandomPassword } from 'shared/lib';
import { Button } from 'shared/ui';
import { Spinner } from 'shared/ui/Spinner';
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import useToggle from 'shared/hooks/useToggle';
import { Form as AForm, Input as AInput, Select } from 'antd';
import React from 'react';

const initialValues = {
  urlname: '',
  companyname: '',
  companyemail: '',
  companyaddress: '',
  companycity: '',
  companycountry: '',
  companypostcode: '',
  companyvat: '',
  password_required: false,
  password: undefined,
  approval_required: false,
  approver: undefined,
  tokens: ['USDC', 'USDT', 'DAI', 'BUSD', 'ETH', 'ANT', 'EURS', 'OP', 'ARB'],
  networks: ['Ethereum', 'Polygon', 'Optimism', 'Arbitrum', 'Base'],
  isForEmployee: false,
};

const CreateBillingLinkForm = () => {
  const { create, invalidate } = useLinkMutations();
  const naviage = useNavigate();
  const { data: users, isLoading } = useUsers();
  const { data } = useCurrentUser();

  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      await create.mutateAsync(values);
      invalidate();

      toast.success('Billing link created successfully');

      naviage('/dashboard/bills/links');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  if (isLoading)
    return (
      <div className="min-w-screen flex min-h-screen items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <PayLinkForm
      title={'Create Pay Link'}
      subTitle={
        'Pay Links enable vendors and employees to request payments from\n' +
        '            your company.'
      }
      submitButtonText={'Create Pay Link'}
      users={users}
      onSubmit={handleSubmit}
      formInitialValues={{
        ...initialValues,
        ...mapOrgToForm(data?.selected_organization),
      }}
    />
  );
};

export const PayLinkForm = ({
  title,
  subTitle,
  onSubmit,
  users,
  submitButtonText,
  formInitialValues,
  isAdvancedViewInitialValue = false,
}) => {
  const [showAvancedDetails, toggleShowAdvancedDetails] = useToggle(
    isAdvancedViewInitialValue
  );
  return (
    <div className="mt-10 sm:mt-0">
      <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-white ">
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
              {subTitle}
            </p>
          </div>

          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={Schema}
            onSubmit={onSubmit}>
            {({ isValid, isSubmitting, values, setFieldValue }) => (
              <Form className="mt-5 md:col-span-2 md:mt-0">
                <div className=" grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6 ">
                    <AForm.Item
                      layout="vertical"
                      label="Link Name"
                      help="This is a name for your reference. Your employees and
                      vendors will not see this.">
                      <AInput
                        type="text"
                        name="urlname"
                        id="urlname"
                        autoComplete="off"
                        value={values.urlname}
                        onChange={(evt) => {
                          setFieldValue('urlname', evt.target.value);
                        }}
                      />
                    </AForm.Item>

                    <ErrorMessage
                      name="urlname"
                      component="div"
                      className="mt-2 text-xs italic text-rose-500"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 fg ">
                    <AForm.Item layout="vertical" label="Company Name *">
                      <AInput
                        type="text"
                        name="companyname"
                        id="companyname"
                        autoComplete="off"
                        value={values.companyname}
                        onChange={(evt) => {
                          setFieldValue('companyname', evt.target.value);
                        }}
                      />
                    </AForm.Item>

                    <ErrorMessage
                      name="companyname"
                      component="div"
                      className="mt-2 text-xs italic text-rose-500"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <AForm.Item layout="vertical" label="Company Email *">
                      <AInput
                        type="text"
                        name="companyemail"
                        id="companyemail"
                        autoComplete="off"
                        value={values.companyemail}
                        onChange={(evt) => {
                          setFieldValue('companyemail', evt.target.value);
                        }}
                      />
                    </AForm.Item>

                    <ErrorMessage
                      name="companyemail"
                      component="div"
                      className="mt-2 text-xs italic text-rose-500"
                    />
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3 pt-2 pb-6">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between">
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium leading-6 text-slate-300"
                        passive>
                        Show Advanced Settings
                      </Switch.Label>
                      <Switch.Description
                        as="span"
                        className="text-sm text-slate-400">
                        Include company address, enable approvals and password
                        protect links.
                      </Switch.Description>
                    </span>

                    <Switch
                      checked={showAvancedDetails}
                      onChange={toggleShowAdvancedDetails}
                      className={classNames(
                        showAvancedDetails ? 'bg-indigo-600' : 'bg-gray-500',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                      )}>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          showAvancedDetails
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>

                {showAvancedDetails && (
                  <>
                    {' '}
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <AForm.Item layout="vertical" label="Company Address">
                          <AInput
                            type="text"
                            name="companyaddress"
                            id="companyaddress"
                            autoComplete="off"
                            value={values.companyaddress}
                            onChange={(evt) => {
                              setFieldValue('companyaddress', evt.target.value);
                            }}
                          />
                        </AForm.Item>
                        <ErrorMessage
                          name="companyaddress"
                          component="div"
                          className="mt-2 text-xs italic text-rose-500"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <AForm.Item layout="vertical" label="Company City">
                          <AInput
                            type="text"
                            name="companycity"
                            id="companycity"
                            autoComplete="off"
                            value={values.companycity}
                            onChange={(evt) => {
                              setFieldValue('companycity', evt.target.value);
                            }}
                          />
                        </AForm.Item>
                        <ErrorMessage
                          name="companycity"
                          component="div"
                          className="mt-2 text-xs italic text-rose-500"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-2">
                        <AForm.Item layout="vertical" label="Company Country">
                          <AInput
                            type="text"
                            name="companycountry"
                            id="companycountry"
                            autoComplete="off"
                            value={values.companycountry}
                            onChange={(evt) => {
                              setFieldValue('companycountry', evt.target.value);
                            }}
                          />
                        </AForm.Item>

                        <ErrorMessage
                          name="companycountry"
                          component="div"
                          className="mt-2 text-xs italic text-rose-500"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <AForm.Item layout="vertical" label="Company Zip Code">
                          <AInput
                            type="text"
                            name="companypostcode"
                            id="companypostcode"
                            autoComplete="off"
                            value={values.companypostcode}
                            onChange={(evt) => {
                              setFieldValue(
                                'companypostcode',
                                evt.target.value
                              );
                            }}
                          />
                        </AForm.Item>

                        <ErrorMessage
                          name="companypostcode"
                          component="div"
                          className="mt-2 text-xs italic text-rose-500"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <AForm.Item layout="vertical" label="Company VAT / EIN">
                          <AInput
                            type="text"
                            name="companyvat"
                            id="companyvat"
                            autoComplete="off"
                            value={values.companyvat}
                            onChange={(evt) => {
                              setFieldValue('companyvat', evt.target.value);
                            }}
                          />
                        </AForm.Item>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-0">
                      <div className="col-span-6 sm:col-span-6 mb-6">
                        <AForm.Item
                          layout="vertical"
                          help="Your vendors will be able to use these networks in
                          order to send you payment requests."
                          label="Allowed Networks">
                          <Select
                            mode="multiple"
                            allowClear
                            showSearch={false}
                            value={values.networks}
                            onChange={(val) => {
                              if (val.includes('all')) {
                                if (
                                  values.networks.length ===
                                  initialValues.networks.length
                                ) {
                                  setFieldValue('networks', []);
                                } else {
                                  setFieldValue(
                                    'networks',
                                    initialValues.networks
                                  );
                                }
                              } else {
                                setFieldValue('networks', val);
                              }
                            }}
                            options={[
                              {
                                value: 'all',
                                label: 'Select / Deselect All',
                              },
                              {
                                value: 'Ethereum',
                                label: 'Ethereum',
                              },
                              {
                                value: 'Polygon',
                                label: 'Polygon',
                              },
                              {
                                value: 'Optimism',
                                label: 'Optimism',
                              },
                              {
                                value: 'Arbitrum',
                                label: 'Arbitrum',
                              },
                              {
                                value: 'Base',
                                label: 'Base',
                              },
                            ]}
                          />
                        </AForm.Item>
                        <ErrorMessage
                          name="networks"
                          component="div"
                          className="mt-2 text-xs italic text-rose-500"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-6 mb-6">
                        <AForm.Item
                          layout="vertical"
                          help="Select tokens that you want a payee to invoice in.
                          Only selected tokens will be available to payee."
                          label="Allowed Tokens">
                          <Select
                            mode="multiple"
                            allowClear
                            showSearch={false}
                            value={values.tokens}
                            onChange={(val) => {
                              console.log(val);
                              if (val.includes('all')) {
                                if (
                                  values.tokens.length ===
                                  initialValues.tokens.length
                                ) {
                                  setFieldValue('tokens', []);
                                } else {
                                  setFieldValue('tokens', initialValues.tokens);
                                }
                              } else {
                                setFieldValue('tokens', val);
                              }
                            }}
                            options={[
                              {
                                value: 'all',
                                label: 'Select / Deselect All',
                              },
                              ...initialValues.tokens.map((token) => ({
                                value: token,
                                label: token,
                              })),
                            ]}
                          />
                        </AForm.Item>

                        <ErrorMessage
                          name="tokens"
                          component="div"
                          className="mt-2 text-xs italic text-rose-500"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-6 ">
                        <AForm.Item
                          layout="vertical"
                          label="Is this link for Employee/Contractor?">
                          <div className="flex rounded-md shadow-sm">
                            <Field
                              type="checkbox"
                              name="isForEmployee"
                              id="isForEmployee"
                              checked={values.isForEmployee}
                              onChange={() => {
                                setFieldValue(
                                  'isForEmployee',
                                  !values.isForEmployee
                                );
                              }}
                              className="mt-1 h-4  w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <p className="ml-2 mt-0.5 text-sm text-slate-200">
                              This allows your employees/contractors to see pay
                              checks in the{' '}
                              <a
                                className="text-gray-300 underline"
                                target="_blank"
                                rel="noreferrer"
                                href="https://apps.apple.com/us/app/fractal-payments/id6463490414">
                                Fractal Mobile App
                              </a>{' '}
                              as well as to have personalised payment request
                              creation flow. You need to{' '}
                              <Link
                                className="text-amber-300 underline"
                                target="_blank"
                                to="/dashboard/company/employess">
                                Register Employee
                              </Link>{' '}
                              first.
                            </p>
                          </div>
                        </AForm.Item>
                      </div>

                      <div className="col-span-6 sm:col-span-6 ">
                        <AForm.Item layout="vertical" label="Password Required">
                          <div className="h-[34px] flex rounded-md shadow-sm">
                            <Field
                              type="checkbox"
                              name="password_required"
                              id="password_required"
                              checked={values.password_required}
                              onChange={() => {
                                setFieldValue(
                                  'password_required',
                                  !values.password_required
                                );
                                setFieldValue('password', undefined);
                              }}
                              className="mt-1 h-4  w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <p className="ml-2 mt-0.5 text-sm text-slate-200">
                              Tick if you want to make the link
                              password-protected.
                            </p>
                          </div>

                          {values.password_required && (
                            <div className="col-span-6 mt-6 sm:col-span-6 ">
                              <AForm.Item
                                layout="vertical"
                                label="Set Password">
                                <AInput
                                  type="text"
                                  name="password"
                                  id="password"
                                  autoComplete="off"
                                  value={values.password}
                                  onChange={(evt) => {
                                    setFieldValue('password', evt.target.value);
                                  }}
                                />
                              </AForm.Item>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="mt-2 text-xs italic text-rose-500"
                              />
                              <div className="group flex items-center">
                                <KeyIcon className="inline h-3 w-3 text-[#94A3B8] group-hover:text-primary-600 " />
                                <Button
                                  ui="link"
                                  size="xs"
                                  id="auto-generate"
                                  className="text-[#94A3B8] hover:underline"
                                  onClick={() => {
                                    setFieldValue(
                                      'password',
                                      generateRandomPassword()
                                    );
                                  }}>
                                  Auto Generate Password
                                </Button>
                              </div>
                            </div>
                          )}
                        </AForm.Item>
                      </div>

                      <div className="col-span-6 sm:col-span-6 ">
                        <AForm.Item layout="vertical" label="Approval Required">
                          <div className="h-[34px] flex rounded-md shadow-sm">
                            <Field
                              type="checkbox"
                              name="approval_required"
                              id="approval_required"
                              checked={values.approval_required}
                              onChange={(e) => {
                                setFieldValue(
                                  'approval_required',
                                  !values.approval_required
                                );
                                setFieldValue('approver', '');
                              }}
                              className="mt-1 h-4  w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <p className="ml-2 mt-0.5 text-sm text-slate-200">
                              Tick if a team member's approval is required for
                              bills submitted via this link.
                            </p>
                          </div>
                        </AForm.Item>
                      </div>

                      {values.approval_required && (
                        <div className="col-span-6 mb-5 sm:col-span-6">
                          <AForm.Item
                            layout="vertical"
                            label="Select Approver *"
                            help={
                              'Select a team member. You can manage team members in\n' +
                              '                            the Manage Organization page.'
                            }>
                            <Select
                              value={values.approver}
                              placeholder={'Select Approver'}
                              onChange={(val) => {
                                setFieldValue('approver', val);
                              }}
                              options={users?.map((user) => ({
                                value: user.email,
                                label: user.email,
                              }))}
                            />
                          </AForm.Item>

                          <ErrorMessage
                            name="approver"
                            component="div"
                            className="mt-2 text-xs italic text-rose-500"
                          />
                        </div>
                      )}

                      <div className="col-span-6 sm:col-span-6 ">
                        <AForm.Item
                          layout="vertical"
                          help={
                            'Please select the appropriate document type based on your contractor status'
                          }
                          label="Request additional information:">
                          <Select
                            value={values.additionalDetailsRequestType}
                            placeholder={'Select a document type'}
                            onChange={(val) => {
                              setFieldValue(
                                'additionalDetailsRequestType',
                                val
                              );
                            }}
                            options={[
                              {
                                value: 'fw9_form',
                                label:
                                  'W-9 Form: Required for US companies paying local contractors',
                              },
                              {
                                value: 'fw8ben_form',
                                label:
                                  'W-8BEN Required for US companies paying international contractors',
                              },
                            ]}
                          />
                        </AForm.Item>
                      </div>
                    </div>
                  </>
                )}

                <div className="mb-3 mt-6 flex justify-end">
                  <Button
                    type="submit"
                    size="lg"
                    disabled={!isValid || isSubmitting}
                    LeadingIcon={
                      isSubmitting
                        ? () => <Spinner className="h-5 w-5 text-white" />
                        : undefined
                    }>
                    {submitButtonText}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateBillingLinkForm;

function mapOrgToForm(organization) {
  return {
    companyname: organization?.name || '',
    companyemail: organization?.email || '',
    companyaddress: organization?.address || '',
    companycity: organization?.city || '',
    companycountry: organization?.country || '',
    companypostcode: organization?.postcode || '',
    companyvat: organization?.vat || '',
  };
}
