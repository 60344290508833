import axios from 'axios';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'entities/user/model';
import { useAuth0 } from '@auth0/auth0-react';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/bills/recurrence';

const fetchOrders = async (getToken, organizationId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/list-standing-orders`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const useStandingOrders = () => {
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: userData } = useCurrentUser();
  const organizationId = userData?.selected_organization?._id;
  const { data: orders, isFetching: isFetchingOrders } = useQuery({
    queryKey: ['standing-orders'],
    queryFn: () => fetchOrders(getToken, organizationId),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  return {
    orders: orders || [],
    isFetchingOrders,
  };
};

export const useInvalidateStandingOrders = () => {
  const queryClient = useQueryClient();

  const invalidateStandingOrders = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['standing-orders'] });
  }, [queryClient]);

  return {
    invalidateStandingOrders,
  };
};
