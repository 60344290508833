import React, { FC, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Flex, Form as AForm } from 'antd';
import { PencilIcon } from '@heroicons/react/20/solid';
import { Button } from '../../../shared/ui/Button/Button';

const useTaxFormStatus = ({
  vendorEmail,
  paymentLinkId,
}: {
  vendorEmail: string;
  paymentLinkId: string;
}) => {
  const query = useQuery({
    queryKey: ['vendor-tax-form-status', vendorEmail],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/public/payment-link/${paymentLinkId}/vendor-provided-details-status`,
        {
          params: {
            vendorEmail,
          },
        }
      );

      return response.data.result;
    },
    enabled: !!vendorEmail && !!paymentLinkId,
  });

  return {
    statusData: query.data,
    isPending: query.isPending,
  };
};

export const CreatePaymentRequestFormUSTaxForm: FC<{
  vendorEmail: string;
  paymentLink: any;
  onFieldChange: any;
}> = ({ vendorEmail, paymentLink, onFieldChange }) => {
  const paymentLinkId = paymentLink._id;
  const { statusData } = useTaxFormStatus({ vendorEmail, paymentLinkId });
  const signatureCanvasRef = useRef(null);

  if (
    !paymentLink ||
    !paymentLink.additionalDetailsRequest ||
    !paymentLink.additionalDetailsRequest.detailsType ||
    !statusData ||
    statusData?.status === 'ok'
  ) {
    return null;
  }

  return (
    <div className="mt-5 rounded-lg bg-white px-4 py-5 shadow dark:bg-slate-800 sm:p-6">
      <h2 className="text-base font-semibold leading-7 text-white">
        Signature Required
      </h2>
      <text className="mt-1 text-sm leading-6 text-slate-400">
        Your signature will be included on the W-8/W-9 form alongside the data
        provided in this form.
      </text>

      <div className="mt-10">
        <AForm.Item
          layout="vertical"
          label={
            <Flex gap={'small'}>
              <PencilIcon className={'w-5'} /> Signature
            </Flex>
          }>
          <div className="flex justify-center rounded-md border-2 border-dashed border-[#4E6180]">
            <div
              style={{ width: '100%', height: '220px', position: 'relative' }}>
              <div className={'absolute right-2 top-2'}>
                <Button
                  size="xs"
                  color="slate"
                  onClick={() => {
                    signatureCanvasRef?.current?.clear();
                  }}>
                  Clear
                </Button>
              </div>
              <SignatureCanvas
                canvasProps={{
                  className: 'contractor-signature',
                }}
                ref={signatureCanvasRef}
                onEnd={() => {
                  onFieldChange(
                    'signature',
                    signatureCanvasRef?.current?.getTrimmedCanvas().toDataURL()
                  );
                }}
              />
            </div>
          </div>
        </AForm.Item>
      </div>
    </div>
  );
};
