import RSelect from 'react-select';

const customStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: '#18222d',
    border: '2px solid #4E6180',
    borderRadius: '0.375rem',
    color: 'white',
    padding: '',
    fontSize: '0.875rem',
    marginTop: '4px',
    width: '100%',
    boxShadow: state.isFocused ? '0 0 0 1px #6366f1' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#6366f1' : '#4E6180',
    },
    outline: 'none',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: '#4E6180',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: '#18222d',
    color: 'white',
    fontSize: '0.875rem',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#475569',
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#e2e8f0',
    backgroundColor: '#334155',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#e2e8f0',
    backgroundColor: '#334155',
    // ':hover': {
    //   backgroundColor: data.color,
    //   color: 'white',
    // },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#6366f1' : '#18222d',
    color: state.isFocused ? 'white' : 'white',
    fontSize: '0.875rem',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'white',
  }),

  placeholder: (base) => ({
    ...base,
    color: '#CBD5E1',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    // display: 'none',
    backgroundColor: '#4E6180',
  }),
  input: (base) => ({
    ...base,
    outline: 'none',
    color: 'white',
    fontSize: '0.875rem',
  }),
};

const customStylesThin = {
  ...customStyles,
  control: (base, state) => ({
    ...base,
    backgroundColor: '#18222d',
    border: '1px solid #4E6180',
    borderRadius: '0.375rem',
    color: 'white',
    padding: '',
    fontSize: '0.875rem',
    marginTop: '4px',
    width: '100%',
    boxShadow: state.isFocused ? '0 0 0 1px #6366f1' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#6366f1' : '#4E6180',
    },
    outline: 'none',
  }),
};

export const Select = ({ variant, ...props }) => (
  <RSelect
    styles={variant === 'thin-border' ? customStylesThin : customStyles}
    {...props}
  />
);
