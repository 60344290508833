import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export const ModalStyles = ({ show, children, cancelButtonRef, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog
      as="div"
      className="relative z-10"
      initialFocus={cancelButtonRef}
      onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-350"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-250"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="fixed inset-0 bg-slate-900 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-350"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-250"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-slate-800 shadow-xl transition-all sm:my-8  sm:p-6 sm:px-6 sm:pb-6 sm:pt-10">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
