import { useState } from 'react';
import { Modal } from 'shared/ui/Modal/Modal';
import { Spinner } from 'shared/ui/Spinner/Spinner';
import { useEffect } from 'react';
import { useMeshTransfer } from './useMeshTransfer';
import {
  SuccessConfirmation,
  FailedPayment,
} from 'widgets/Payments/CryptoToFiatPaymentModal.SuccessConfirmation';
import { sendTransactionLog } from '../../analytics/sendTransactionLog';
import { useAuth0 } from '@auth0/auth0-react';

export const MeshPaymentModal = ({ payouts, onClose, onPaymentSuccess }) => {
  const [isTrxSuccess, setTrxSuccess] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { initiateMeshTransfer, isLoading, isError, reset, isSuccess } =
    useMeshTransfer({
      // on exit
      onTransferSuccess: (payload) => {
        setTrxSuccess(true);
        onPaymentSuccess && onPaymentSuccess(undefined, false);
        sendTransactionLog(getAccessTokenSilently, {
          title: '🟢 [MESH][Transfer Success] 🟢',
          payouts,
        });
      },
    });

  const _handleClose = () => {
    if (!isLoading) {
      reset();
      onClose();
    }
  };

  useEffect(() => {
    if (!isLoading && !isError && !isSuccess) {
      initiateMeshTransfer({ payouts });
    }
  }, [initiateMeshTransfer, isError, isLoading, payouts, isSuccess]);

  return (
    <Modal popup size="lg" show onClose={_handleClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>
      <Modal.Body>
        <FailedPayment onClose={_handleClose} show={isError} />

        <SuccessConfirmation
          title="Your transfer has been initiated"
          description="Settlement times may vary based on the exchange used and network traffic."
          show={isTrxSuccess}
          onClose={onClose}
        />

        <div className="p-4 h-[400px] flex items-center justify-center">
          <Spinner color="indigo" size="lg" />
        </div>
      </Modal.Body>
    </Modal>
  );
};
