import { useState, useEffect } from 'react';
import axios from 'axios';

const cryptoCurrencies = [
  {
    id: 'usd-coin',
    symbol: 'usdc',
    name: 'USD Coin',
  },
  {
    id: 'bridged-usdc-polygon-pos-bridge',
    symbol: 'usdc.native_polygon',
    name: 'Bridged USDC (Polygon PoS Bridge)',
  },
  {
    id: 'ethereum',
    symbol: 'eth',
    name: 'Ethereum',
  },
  {
    id: 'tether',
    symbol: 'usdt',
    name: 'Tether',
  },
  {
    id: 'dai',
    symbol: 'dai',
    name: 'Dai',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    name: 'Binance USD',
  },
  {
    id: 'aragon',
    symbol: 'ant',
    name: 'Aragon',
  },
  {
    id: 'eur-stablecoin',
    symbol: 'eurs',
    name: 'STASIS EURO',
  },
  {
    id: 'the-graph',
    symbol: 'grt',
    name: 'The Graph',
  },
  {
    id: 'polygon',
    symbol: 'matic',
    name: 'Polygon',
  },
  {
    id: 'optimism',
    symbol: 'op',
    name: 'Optimism',
  },
  {
    id: 'arbitrum',
    symbol: 'arb',
    name: 'Arbitrum',
  },
  {
    id: 'vela-token',
    symbol: 'vela',
    name: 'Vela Token',
  },
  {
    id: 'vela-token',
    symbol: 'esvela',
    name: 'Vela Token',
  },
];

const findCurrencyId = (token) => {
  const currency = cryptoCurrencies.find((item) => item.symbol === token);
  return currency ? currency.id : null;
};

const useExchangeRate = (token, amount) => {
  const [exchangeRate, setExchangeRate] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const currencyId = findCurrencyId(token);

  useEffect(() => {
    if (currencyId) {
      axios
        .get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${currencyId}&vs_currencies=usd`
        )
        .then((response) => {
          if (response.data[currencyId]) {
            setExchangeRate(response.data[currencyId].usd);
          } else {
            console.log(
              `Error: ${currencyId} not found in response:`,
              response.data
            );
          }
        })
        .catch((error) => {
          console.log(
            `Error fetching ${currencyId} to USD exchange rate:`,
            error
          );
        });
    } else {
      console.log(`Error: Unable to find ID for ${token}`);
    }
  }, [currencyId]);

  useEffect(() => {
    setUsdValue(amount * exchangeRate);
  }, [amount, exchangeRate]);

  return usdValue;
};

export default useExchangeRate;
