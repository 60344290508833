import { Button } from 'shared/ui/Button/Button';
import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

export const DepositMethodSelect = ({ onMethodSelect }) => {
  return (
    <div className="mt-6 mb-8 flex flex-col gap-4 items-center">
      <Text className={'!text-slate-400'}>Select Deposit Method:</Text>
      <Button
        color="slate"
        className="w-[280px]"
        onClick={() => {
          onMethodSelect('wallet-connect');
        }}>
        <img
          className="w-5 mr-2"
          src={require('shared/images/eth.png')}
          alt=""
          aria-hidden="true"
        />
        Your Wallet
      </Button>
      <Button
        color="slate"
        className="w-[280px]"
        onClick={() => {
          onMethodSelect('aragon-app');
        }}>
        <img
          className="w-5 mr-2"
          src={require('shared/images/aragonLogo.png')}
          alt=""
          aria-hidden="true"
        />
        Aragon App
      </Button>
    </div>
  );
};
