import { BrowserRouter, Route, Routes } from "react-router-dom";
import WithAuthentication from "widgets/Authentication/WithAuthentication";
import { WithOrganization } from "features/organization/WithOrganization";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboard/*"
          element={
            <WithAuthentication>
              <WithOrganization>
                <PrivateRoutes />
              </WithOrganization>
            </WithAuthentication>
          }
        />

        <Route path="/*" element={<PublicRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}
