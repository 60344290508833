import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import useToggle from 'shared/hooks/useToggle';
import { twMerge } from 'tailwind-merge';
import { TextInput as TextInputF } from 'flowbite-react';

const InputWithIcon = ({ type = 'text', className, Icon, ...props }) => {
  return (
    <div className="relative w-full">
      <input type={type} className={className} {...props} />
      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
        <Icon className="h-4 w-4 text-white" />
      </span>
    </div>
  );
};

const PasswordInput = ({ className, ...props }) => {
  const [showPassword, togglePassword] = useToggle(false);

  return (
    <div className="relative w-full">
      <input
        type={showPassword ? 'text' : 'password'}
        className={className}
        {...props}
      />
      <span
        className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
        onClick={togglePassword}>
        {showPassword ? (
          <EyeIcon className="h-4 w-4 text-white" />
        ) : (
          <EyeSlashIcon className="h-4 w-4 text-white" />
        )}
      </span>
    </div>
  );
};

const styles = {
  base: 'mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
  radio: 'mt-6 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500',
  checkbox:
    'mt-6 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500',
  date: '',
  password: '',
  text: '',
};

export const Input = ({ className, type, ...props }) => {
  if (['radio', 'checkbox'].includes(type)) {
    return (
      <input
        type={type}
        className={twMerge(styles[type], className)}
        {...props}
      />
    );
  }

  if (type === 'date') {
    return (
      <input
        type="date"
        className={twMerge(styles.base, className)}
        {...props}
      />
    );
  }

  if (props.icon) {
    return (
      <InputWithIcon
        type={type}
        className={twMerge(styles.base)}
        Icon={props.icon}
        {...props}
      />
    );
  }

  if (type === 'password') {
    return (
      <PasswordInput className={twMerge(styles.base, className)} {...props} />
    );
  }

  return (
    <input type="text" className={twMerge(styles.base, className)} {...props} />
  );
};

const customFlowBiteTheme = {
  field: {
    base: 'relative w-full',
    input: {
      base: 'rounded-lg overflow-hidden block w-full border disabled:cursor-not-allowed disabled:opacity-50',
      sizes: {
        sm: 'p-2',
        md: 'text-sm',
        lg: 'sm:text-md p-3',
      },
      colors: {
        gray: 'dark:border-gray-600 dark:bg-gray-700/50  dark:text-white dark:placeholder-gray-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
        slate:
          'dark:border-slate-600 dark:bg-slate-700/50  dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
        lightSlate:
          'dark:border-slate-600 dark:bg-slate-700  dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
        darkSlate:
          'dark:border-[#4E6180] dark:bg-[#18222d]  dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
      },
    },
  },
};

export const TextInput = ({ ...props }) => (
  <TextInputF theme={customFlowBiteTheme} {...props} />
);
