import { useLinks } from 'entities/link/model';
import { PaymentLinkDetailsSlideOver } from 'features/link/ui';
import { useState, useMemo } from 'react';
import { configureUrlPath, getRelativeTime } from 'shared/lib';
import EmptyLinksComponent from 'widgets/Bills/EmptyLinksComponent.jsx';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { Table } from 'shared/ui/Table/TableBlocks';
import { toast } from 'react-toastify';
import {
  EyeIcon,
  EyeSlashIcon,
  PaperClipIcon,
} from '@heroicons/react/20/solid';
import useToggle from 'shared/hooks/useToggle';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const PageBillingLinks = () => {
  const { data: links, isPending } = useLinks();
  const [viewLinkDetails, setViewLinkDetails] = useState(null);
  const navigate = useNavigate();
  const handleCopyLink = (event, url) => {
    event.preventDefault();
    event.stopPropagation();

    navigator.clipboard.writeText(
      configureUrlPath(url, window.location.origin)
    );
    toast.info('Copied to clipboard');
  };

  const handleAddNewLink = () => {
    navigate(`/dashboard/bills/links/create-link`);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        cell: ({ row: { original: link } }) => (
          <div className="whitespace-nowrap">
            <a
              rel="noreferrer"
              key={link.url}
              href={`${configureUrlPath(link.url, window.location.origin)}`}
              className="text-sm text-primary-400 hover:underline"
              target="_blank"
              onClick={(e) => e.stopPropagation()}>
              {link.name ? link.name : 'Link'}
            </a>

            <button
              className=" block text-[10px] hover:text-primary-400"
              onClick={(e) => handleCopyLink(e, link.url)}>
              <PaperClipIcon className="mr-0.5 inline h-3 w-3" />
              Copy Link
            </button>
          </div>
        ),
      },
      {
        header: 'Company',
        cell: ({ row: { original: link } }) => link.companyname,
      },
      {
        header: 'Password',
        cell: ({ row: { original: link } }) => (
          <PaymentLinksTablePasswordViewer password={link.password} />
        ),
      },
      {
        header: 'Approver',
        cell: ({ row: { original: link } }) => {
          return link.approver ? (
            <div className="flex items-center  gap-1">
              <UserCircleIcon className="inline h-3 w-3" />
              <span>{link.approver}</span>
            </div>
          ) : (
            <span>No approver</span>
          );
        },
      },
      {
        header: 'Payments Methods',
        cell: ({ row: { original: link }, ...props }) => {
          return (
            <div className="flex flex-col">
              <span>{link.tokens.join(', ')}</span>
              <span>{link.networks.join(', ')}</span>
            </div>
          );
        },
      },
      {
        header: 'Created',
        cell: ({ row: { original: link } }) => getRelativeTime(link.createdAt),
      },
    ],
    []
  );

  if (isPending) {
    return <ContentLoadingState />;
  }

  if (!links || links?.length === 0) {
    return (
      <ContentCotainer>
        <EmptyLinksComponent />
      </ContentCotainer>
    );
  }

  return (
    <ContentCotainer>
      <Table
        title="Your Pay Links"
        description="Pay Links enable vendors and employees to request payments from your company"
        primaryActionText="Add Pay Link"
        onPrimaryAction={handleAddNewLink}
        onRowClick={setViewLinkDetails}
        data={links}
        columns={columns}
      />

      <PaymentLinkDetailsSlideOver
        show={!!viewLinkDetails}
        link={viewLinkDetails}
        onClose={() => setViewLinkDetails(null)}
      />
    </ContentCotainer>
  );
};

export default PageBillingLinks;

const PaymentLinksTablePasswordViewer = ({ password }) => {
  const [showPassword, togglePassword] = useToggle(false);

  const handleTogglePassword = (event) => {
    event.stopPropagation();
    togglePassword();
  };

  const handleCopy = (event) => {
    event.stopPropagation();
    event.preventDefault();

    navigator.clipboard.writeText(password);
    toast.info('Copied to clipboard');
  };

  return password ? (
    <div className="flex flex-col">
      <div className="flex items-center">
        <span className="mr-2 text-xs">
          {showPassword ? password : '•'.repeat(password.length)}
        </span>
        {showPassword ? (
          <EyeSlashIcon
            className="h-3 w-3 cursor-pointer"
            onClick={handleTogglePassword}
          />
        ) : (
          <EyeIcon
            className="h-3 w-3 cursor-pointer"
            onClick={handleTogglePassword}
          />
        )}
      </div>

      <button
        className={`flex items-center text-[10px] hover:text-indigo-500 hover:underline ${
          showPassword ? '' : 'hidden'
        }`}
        onClick={handleCopy}>
        <PaperClipIcon className="h-3 w-3 mr-1 text-indigo-500 hover:text-indigo-700" />
        Copy Password
      </button>
    </div>
  ) : (
    <span>No password</span>
  );
};
