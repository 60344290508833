import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ContentCotainer } from 'shared/ui/layouts/PageContent';
import { usePaymentsReport } from './usePaymentsReport';
import { Spinner } from 'shared/ui/Spinner/Spinner';
import { Button } from 'shared/ui/Button/Button';
import { formatDollarAmount } from 'shared/lib/string';
import { Link } from 'react-router-dom';
import { Badge } from 'flowbite-react';
import { useUserBills } from 'entities/bill/model/useUserBills';
import { INIT_PAGINATION } from 'features/bill/BillsPage';
import { WithOrgRole } from 'shared/ui/WithOrgPermissions';
import { FractalBankAccountsWidget } from '../fractalBankAccounts/FractalBankAccountsWidget';
import { getTokenHumanReadableNameByChain } from '../bill/lib';
import { useOrganizationInvoices } from '../invoice/useOrganizationInvoices';
import { PaymentsOnboardingWidget } from '../payments/paymentModals/PaymentsOnboardingModal';

export const DashBoardPage = () => {
  return (
    <ContentCotainer>
      <PaymentsOnboardingWidget />
      <WithOrgRole
        admin={<TopWidget />}
        member={null}
        accountant={<TopWidget />}
      />

      <div className="my-4 grid grid-cols-1 gap-4 xl:grid-cols-2">
        <LatestInvoices />
        <LatestBills />
      </div>
    </ContentCotainer>
  );
};

const TopWidget = () => {
  return (
    <div className="my-4 grid grid-cols-1 gap-4 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="2xl:col-span-2">
        <PaymentsWidget />
      </div>

      <FractalBankAccountsWidget />
    </div>
  );
};

const PaymentsWidget = function () {
  const { reportData, isReportLoading } = usePaymentsReport();

  const _getPaymentsInThisMonth = () => {
    if (
      reportData &&
      reportData.values &&
      reportData.values[0]?.invoices?.totalValueUsd !== undefined
    ) {
      return `$${formatDollarAmount(
        reportData?.values[0]?.invoices?.totalValueUsd,
        {
          maximumFractionDigits: 2,
        }
      )}`;
    }
    return '--';
  };

  const _getPaymentsOutThisMonth = () => {
    if (
      reportData &&
      reportData.values &&
      reportData?.values[0]?.bills?.totalValueUsd !== undefined
    ) {
      return `$${formatDollarAmount(
        reportData?.values[0]?.bills?.totalValueUsd,
        {
          maximumFractionDigits: 2,
        }
      )}`;
    }
    return '--';
  };

  return (
    <div className="rounded-lg bg-white p-4 shadow dark:bg-slate-800 sm:p-6 xl:p-6">
      <div className="flex gap-6">
        <div className="mb-4 flex items-center justify-between">
          <div className="shrink-0">
            <span className="text-2xl font-medium leading-none text-gray-900 dark:text-white sm:text-3xl">
              {isReportLoading ? '--' : _getPaymentsInThisMonth()}
            </span>
            <h3 className="text-base font-normal flex gap-2 items-center text-gray-600 dark:text-gray-400">
              <div className="bg-[#1A56DB] w-3 h-3 rounded-full" />
              Payments In this month
            </h3>
          </div>
        </div>
        <div className="mb-4 flex items-center justify-between">
          <div className="shrink-0">
            <span className="text-2xl font-medium leading-none text-gray-900 dark:text-white sm:text-3xl">
              {isReportLoading ? '--' : _getPaymentsOutThisMonth()}
            </span>
            <h3 className="text-base font-normal flex gap-2 items-center text-gray-600 dark:text-gray-400">
              <div className="bg-[#FDBA8C] w-3 h-3 rounded-full" />
              Payments Out this month
            </h3>
          </div>
        </div>
      </div>

      {isReportLoading ? (
        <div className="h-[400px] flex items-center justify-center">
          <Spinner size="xl" color="indigo" />
        </div>
      ) : (
        <PaymentsChart data={reportData} />
      )}
      {/* <div className="mt-4 flex items-center justify-between border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6">
        <span className="text-sm text-gray-600">Last 7 months</span>
      </div> */}
    </div>
  );
};

const PaymentsChart = function ({ data }) {
  const isDarkTheme = true;

  const borderColor = isDarkTheme ? '#374151' : '#F3F4F6';
  const labelColor = isDarkTheme ? '#93ACAF' : '#6B7280';
  const opacityFrom = isDarkTheme ? 0 : 0.45;
  const opacityTo = isDarkTheme ? 0.15 : 0;

  if (!data.values) {
    return null;
  }

  const values = _.reverse([...data.values]);

  const options = {
    stroke: {
      curve: 'straight',
    },
    chart: {
      type: 'area',
      fontFamily: 'Inter, sans-serif',
      foreColor: labelColor,
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom,
        opacityTo,
        type: 'vertical',
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      style: {
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif',
      },
    },
    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 1,
      padding: {
        left: 35,
        bottom: 15,
      },
    },
    markers: {
      size: 5,
      strokeColors: '#ffffff',
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    xaxis: {
      categories: values.map(({ aggregationDateContext }) => {
        return dayjs(aggregationDateContext).format('MMM YY');
      }),
      labels: {
        style: {
          colors: [labelColor],
          fontSize: '14px',
          fontWeight: 500,
        },
      },
      axisBorder: {
        color: borderColor,
      },
      axisTicks: {
        color: borderColor,
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 10,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: [labelColor],
          fontSize: '14px',
          fontWeight: 500,
        },
        formatter: function (value) {
          return '$' + formatDollarAmount(value);
        },
      },
    },
    legend: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      labels: {
        colors: [labelColor],
      },
      itemMargin: {
        horizontal: 10,
      },
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };
  const series = [
    {
      name: 'Payments In',
      data: values.map(({ invoices }) => invoices.totalValueUsd.toFixed(2)),
      color: '#1A56DB',
    },
    {
      name: 'Payments Out',
      data: values.map(({ bills }) => bills.totalValueUsd.toFixed(2)),
      color: '#FDBA8C',
    },
  ];

  return <Chart height={400} options={options} series={series} type="area" />;
};

const LatestInvoices = function () {
  const { invoices, isLoading } = useOrganizationInvoices();

  return (
    <div className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-slate-800 sm:p-6">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          Latest Invoices
        </h3>

        <Link
          to={`/dashboard/invoices`}
          className="inline-flex items-center rounded-lg p-2 text-sm font-medium text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
          View all
        </Link>
      </div>
      <div className="flow-root">
        {isLoading ? (
          <div className="h-[324px] flex items-center justify-center">
            <Spinner size="xl" color="indigo" />
          </div>
        ) : (
          <>
            {invoices?.length === 0 ? (
              <div className="h-[324px] gap-3 flex flex-col items-center justify-center">
                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                  You don't have any invoices yet!
                </p>
                <p className="truncate text-sm text-gray-500 dark:text-slate-400">
                  Start using Fractal to generate invoices and get paid faster.
                </p>
                <Button color="gray" as={Link} to="/dashboard/create-invoice">
                  + Create an Invoice
                </Button>
              </div>
            ) : (
              <>
                <ul className="divide-y divide-slate-200 dark:divide-slate-700">
                  {(invoices || []).slice(0, 5).map((inv) => (
                    <li className="py-3 sm:py-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-slate-500  min-w-[48px] text-sm">
                          {dayjs(inv.createdAt).format('DD MMM')}
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                            {inv.client_name}{' '}
                          </p>
                          <p className="truncate text-xs text-gray-500 dark:text-slate-400">
                            {inv.client_email}
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base text-gray-900 dark:text-white">
                          {formatDollarAmount(inv.amount)}{' '}
                          <span className="ml-2 font-normal text-sm dark:text-slate-400">
                            {getTokenHumanReadableNameByChain(
                              inv.token,
                              inv.network
                            )}
                          </span>
                        </div>
                        <div>
                          <>
                            {inv.payment.status === 'Paid' ? (
                              <Badge className="inline !bg-slate-700 !text-emerald-400">
                                Paid
                              </Badge>
                            ) : inv.payment.status === 'Sent' ? (
                              <Badge className="inline !bg-slate-700 !text-indigo-300">
                                Sent
                              </Badge>
                            ) : (
                              <Badge className="inline !bg-slate-700 !text-slate-200">
                                Unpaid
                              </Badge>
                            )}
                          </>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const LatestBills = function () {
  const { bills, isLoading } = useUserBills({
    pageNumber: INIT_PAGINATION.pageIndex + 1,
    pageLimit: INIT_PAGINATION.pageSize,
  });

  return (
    <div className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-slate-800 sm:p-6">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          Latest Bills
        </h3>

        <Link
          to={`/dashboard/bills/allbills`}
          className="inline-flex items-center rounded-lg p-2 text-sm font-medium text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700">
          View all
        </Link>
      </div>
      <div className="flow-root">
        {isLoading ? (
          <div className="h-[324px] flex items-center justify-center">
            <Spinner size="xl" color="indigo" />
          </div>
        ) : (
          <>
            {bills.length === 0 ? (
              <div className="h-[324px] gap-3 flex flex-col items-center justify-center">
                <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                  You don't have any bills yet!
                </p>
                <p className="truncate text-sm text-gray-500 dark:text-slate-400">
                  Start using Fractal to generate bills.
                </p>
                <Button color="gray" as={Link} to="/dashboard/bills/allbills">
                  + Create a Bill
                </Button>
              </div>
            ) : (
              <>
                <ul className="divide-y divide-slate-200 dark:divide-slate-700">
                  {(bills || []).slice(0, 5).map((bill) => (
                    <li className="py-3 sm:py-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-slate-500  min-w-[48px] text-sm">
                          {dayjs(bill.createdAt).format('DD MMM')}
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                            {bill.receiverName}
                          </p>
                          <p className="truncate text-xs text-gray-500 dark:text-slate-400">
                            {bill.receiverEmail}
                          </p>
                        </div>
                        <div className="inline-flex items-center text-base  text-gray-900 dark:text-white">
                          {formatDollarAmount(bill.amount)}{' '}
                          <span className="ml-2 font-normal text-sm dark:text-slate-400">
                            {getTokenHumanReadableNameByChain(
                              bill.token,
                              bill.network
                            )}
                          </span>
                        </div>
                        <div>
                          {bill.paymentStatus === 'paid' ? (
                            <Badge className="inline !bg-slate-700 !text-emerald-400">
                              Paid
                            </Badge>
                          ) : bill.paymentStatus === 'processingPayment' ? (
                            <Badge className="inline !bg-slate-700 !text-indigo-300">
                              Processing
                            </Badge>
                          ) : (
                            <Badge className="inline !bg-slate-700 !text-slate-200">
                              Unpaid
                            </Badge>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
