import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { PlusIcon, TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import useToggle from 'shared/hooks/useToggle';
import { Dialog } from '@headlessui/react';
import { Modal } from 'shared/ui';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, NumberField } from 'shared/ui/form';
import BigNumber from 'bignumber.js';
import useExchangeRate from 'widgets/Payments/useExchangeRate';
import { Button } from 'shared/ui/Button/Button';
import { getTokenHumanReadableName } from '../../features/bill/lib';

const getTotals = (items) => {
  const totalAmount = items.reduce((accumulator, currentValue) => {
    return accumulator.plus(new BigNumber(currentValue.totalAmount));
  }, new BigNumber(0));

  const subTotal = items.reduce((accumulator, currentValue) => {
    const temp = new BigNumber(currentValue.amount).times(
      new BigNumber(currentValue.quantity)
    );

    return accumulator.plus(temp);
  }, new BigNumber(0));

  return {
    subTotal: subTotal.toString(),
    totalTaxAmount: totalAmount.minus(subTotal).toString(),
    totalAmount: totalAmount.toString(),
  };
};

const nonStablecoinCurrencies = ['ETH', 'BTC', 'OP', 'ANT', 'GRT'];

export const NewItemSchema = Yup.object().shape({
  description: Yup.string().min(5).required('Description is required'),
  quantity: Yup.number().min(1).required('Quantity is required'),
  amount: Yup.number().min(0.00001).required('Amount is required'),
  taxRate: Yup.string(),
});

export const InvoiceLineItems = ({ items, currency, onItemsChange }) => {
  const { subTotal, totalTaxAmount, totalAmount } = getTotals(items);
  const [newItemPopupOpened, toggleAddNewPopup] = useToggle(false);
  const [editLineItemIndex, setEditLineItemIndex] = useState(null);

  const isNonStablecoinCurrency = nonStablecoinCurrencies.includes(currency);

  const [conversionRate, setConversionRate] = useState(0);

  let exchangeRate;

  exchangeRate = useExchangeRate(currency.toLowerCase(), 1);

  useEffect(() => {
    setConversionRate(exchangeRate);
  }, [newItemPopupOpened]);

  const handleAddNew = () => {
    toggleAddNewPopup();
  };

  const handleDelete = (index) => {
    const newItems = items.filter((item, i) => i !== index);
    onItemsChange(newItems);
  };

  const handleEdit = (index) => {
    setEditLineItemIndex(index);
    toggleAddNewPopup();
  };

  const handleSubmit = async (values) => {
    const totalAmountRes = new BigNumber(values.amount)
      .times(new BigNumber(values.quantity))
      .times(new BigNumber(1).plus(new BigNumber(values.taxRate).div(100)));

    const newItem = {
      description: values.description,
      quantity: values.quantity,
      amount: values.amount,
      taxRate: values.taxRate,
      totalAmount: totalAmountRes.toString(),
    };

    let update = [...items, newItem];

    if (editLineItemIndex !== null) {
      items[editLineItemIndex] = newItem;
      update = [...items];
    }

    handleClose();
    onItemsChange(update);
  };

  const getInitialValues = () => {
    const itemToEdit = items.find((_, index) => index === editLineItemIndex);

    if (itemToEdit) {
      const { description, amount, quantity, taxRate } = itemToEdit;
      return {
        description,
        amount,
        quantity,
        taxRate,
        usdAmount: '',
      };
    }

    return {
      description: '',
      amount: '0',
      quantity: '1',
      taxRate: '0',
      usdAmount: '',
      currency,
    };
  };

  const handleClose = () => {
    toggleAddNewPopup();
    setEditLineItemIndex(null);
  };

  return (
    <>
      <table className="w-[100%] ">
        <thead className="text-sm text-[#CBD5E1]">
          <tr className="">
            <th className="rounded-l-md bg-slate-700 py-3 pl-3 text-left font-normal">
              Description
            </th>
            <th
              className="bg-slate-700 text-center font-normal"
              style={{ width: '75px' }}>
              Quantity
            </th>
            <th className="bg-slate-700 font-normal" style={{ width: '100px' }}>
              Unit Price
            </th>
            <th
              className="bg-slate-700 text-center font-normal"
              style={{ width: '75px' }}>
              Tax
            </th>
            <th
              className="rounded-r-md bg-slate-700 pr-3 text-right font-normal"
              style={{ width: '100px' }}>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 ? (
            items.map((item, index) => (
              <tr
                key={index}
                className="border-b border-slate-700 text-sm text-[#CBD5E1]">
                <td className="py-3 pl-3 text-left font-normal">
                  <div className="flex">
                    <TrashIcon
                      onClick={() => handleDelete(index)}
                      width={18}
                      className="mr-2 text-red-500 hover:cursor-pointer"
                    />

                    <PencilIcon
                      onClick={() => handleEdit(index)}
                      width={16}
                      className="mr-2  hover:cursor-pointer"
                    />

                    {item.description}
                  </div>
                </td>
                <td className="text-center">{item.quantity}</td>
                <td className="text-center">
                  {item.amount}{' '}
                  <span className="text-xs">
                    {getTokenHumanReadableName(currency)}
                  </span>
                </td>
                <td className="text-center">{item.taxRate} %</td>
                <td className="pr-3 text-right">
                  {item.totalAmount}{' '}
                  <span className="text-xs">
                    {getTokenHumanReadableName(currency)}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr
              key={'bill.id'}
              className="border-b border-slate-700 text-sm text-slate-400">
              <td colSpan={5} className="py-3 pl-3 text-center font-normal">
                <div className="flex flex-col items-center justify-center">
                  <span>No services</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex items-start justify-between">
        {items.length < 4 ? (
          <Button
            onClick={handleAddNew}
            size="sm"
            color="slate"
            className="mt-3">
            <PlusIcon width={16} className="text-indigo-400 mr-2" />
            Add an item
          </Button>
        ) : (
          <span />
        )}

        <div className="pt-3 text-left text-sm text-[#CBD5E1]">
          <div className="mb-3 flex items-center justify-between text-[#9aa8ba]">
            <span className="mr-5">Amount Without Tax:</span>
            <span className="pr-3">
              {subTotal}{' '}
              <span className="text-xs">
                {getTokenHumanReadableName(currency)}
              </span>
            </span>
          </div>
          <div className="mb-3 flex items-center justify-between text-[#9aa8ba]">
            <span className="">Total Tax Amount:</span>
            <span className="pr-3">
              {totalTaxAmount}{' '}
              <span className="text-xs">
                {getTokenHumanReadableName(currency)}
              </span>
            </span>
          </div>
          <div className="flex items-center justify-between font-semibold">
            <span className="">Total Amount</span>
            <span className="pr-3">
              {totalAmount} {getTokenHumanReadableName(currency)}
            </span>
          </div>
        </div>
      </div>

      <Modal show={newItemPopupOpened} handleClose={handleClose}>
        <Dialog.Panel className="relative w-[420px]  transform overflow-hidden rounded-lg bg-gray-800 px-6 pb-6 pt-10 text-left text-white shadow-xl transition-all">
          <Dialog.Title
            as="h3"
            className="mb-8 text-center text-base font-semibold leading-6">
            Add New Item
          </Dialog.Title>

          <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={NewItemSchema}
            initialValues={getInitialValues()}>
            {({ setFieldValue, values }) => {
              return (
                <Form className="space-y-6">
                  <Field
                    name="description"
                    component={TextField}
                    label="Description"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-xs text-rose-500"
                  />

                  {isNonStablecoinCurrency ? (
                    <>
                      <Field
                        name="amount"
                        component={NumberField}
                        label={`Price in ${currency}`}
                        onChange={(e) => {
                          const tokenAmount = e.target.value;
                          const usdAmount = new BigNumber(tokenAmount)
                            .times(new BigNumber(conversionRate))
                            .toFixed(2);
                          setFieldValue('amount', tokenAmount);
                          setFieldValue('usdAmount', usdAmount);
                        }}
                      />

                      <Field
                        name="usdAmount"
                        component={NumberField}
                        label={`Price in USD: 1 ${currency} = ${conversionRate} USD`}
                        onChange={(e) => {
                          const usdAmount = e.target.value;
                          const tokenAmount = new BigNumber(usdAmount)
                            .dividedBy(new BigNumber(conversionRate))
                            .toFixed(2);
                          setFieldValue('usdAmount', usdAmount);
                          setFieldValue('amount', tokenAmount);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Field
                        name="amount"
                        component={NumberField}
                        label={`Price (${getTokenHumanReadableName(currency)})`}
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-xs text-rose-500"
                      />
                    </>
                  )}

                  <Field
                    name="quantity"
                    component={NumberField}
                    label="Quantity"
                  />
                  <ErrorMessage
                    name="quantity"
                    component="div"
                    className="text-xs text-rose-500"
                  />
                  <Field
                    name="taxRate"
                    component={NumberField}
                    label="Tax Rate (%)"
                  />
                  <ErrorMessage
                    name="taxRate"
                    component="div"
                    className="text-xs text-rose-500"
                  />
                  <div className="!mt-12 flex flex-row-reverse gap-x-4">
                    <Button
                      color="indigo"
                      type="submit"
                      className="flex-1 justify-center">
                      Add
                    </Button>
                    <Button
                      color="gray"
                      className="flex-1 justify-center"
                      onClick={toggleAddNewPopup}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Dialog.Panel>
      </Modal>
    </>
  );
};
