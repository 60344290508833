import { useLinks } from 'entities/link/model';
import { useLinkMutations } from 'entities/link/model';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'shared/ui/Spinner';
import { toast } from 'react-toastify';
import React from 'react';
import { PayLinkForm } from '../../widgets/Bills/GeneratePaymentLinkForm';
import { useUsers } from '../../entities/user/model';

export const PaymentLinkPage = () => {
  const { id } = useParams();
  const { data: links, isLoading } = useLinks();
  const { updateLink } = useLinkMutations();
  const { data: users } = useUsers();
  const link = links?.find((link) => link._id === id);
  const navigate = useNavigate();

  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      await updateLink.mutateAsync({ urlId: link._id, values });
      toast.success('Billing link updated successfully');
      navigate('/dashboard/bills/links');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  if (isLoading || !link) {
    return (
      <div className="-mt-6  flex h-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="create-pay-link-page-container mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <PayLinkForm
        title={'Update Pay Link'}
        subTitle={
          'Pay Links enable vendors and employees to request payments from\n' +
          '            your company.'
        }
        users={users}
        onSubmit={handleSubmit}
        submitButtonText="Update Pay Link"
        isAdvancedViewInitialValue={true}
        formInitialValues={{
          ...link,
          urlname: link.name,
          password_required: !!link.password,

          additionalDetailsRequestType:
            link.additionalDetailsRequest?.detailsType,
        }}
      />
    </div>
  );
};
