// ENSWidget.js
import { useEnsName } from 'wagmi';

export const EnsWidget = ({ walletAddress }) => {
  const ensName = useEnsName({
    address: walletAddress,
    chainId: 1,
  });

  const walletIsEns = walletAddress?.toLowerCase().endsWith('.eth');

  const displayAddress = walletIsEns
    ? walletAddress
    : ensName.data || 'Not Found';

  return (
    <div className="grid grid-cols-2  items-center py-2 text-left text-sm font-medium">
      <span className="text-slate-300">ENS Name</span>
      <span className="text-right text-slate-300">{displayAddress}</span>
      {!ensName.data && !walletIsEns && (
        <span className={`col-span-2 text-xs font-normal text-slate-400 `}>
          Verify wallet address if expecting ENS name.
        </span>
      )}
      {ensName.data && !walletIsEns && (
        <span className={`col-span-2 text-xs font-normal text-slate-400 `}>
          ENS name found for this address.
        </span>
      )}
    </div>
  );
};
