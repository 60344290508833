import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';
import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Wizard, useWizard } from 'react-use-wizard';
import {
  TOKEN_TYPES,
  CURRENCY_TYPES,
  NETWORK_TYPES,
  CURRENCIES,
  upperCaseNetworkType,
} from 'features/bill/lib';
import { useAccount, useEnsAddress, useEnsName } from 'wagmi';
import { Select } from 'shared/ui/form/Select/RichSelect';
import { PencilIcon } from '@heroicons/react/24/outline';
import { TextField } from 'shared/ui/form';
import { CryptoNetworkTokenSelect } from 'widgets/Invoices/InvoiceGenerateForm.PaymentTypeSelection';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useValidateIban } from 'features/bill/ui/CreatePaymentRequestForm.PaymentDetails';
import { Spinner } from 'shared/ui/Spinner/Spinner';
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Label } from 'flowbite-react';
export const PaymentDetailsCollectionStepper = ({
  show,
  onClose,
  allowedManualInput = true,
  walletList = [],
  bankAcountList = [],
  selectedPymentDetails = {},
  onPaymentDetailsSelect,
  pdsTypes = [],
}) => {
  const _handleSubmit = (values) => {
    const { cryptoWallet, bankAccount } = values;

    if (
      pdsTypes.includes('crypto-wallet') &&
      pdsTypes.includes('bank-account')
    ) {
      onPaymentDetailsSelect({
        cryptoWallet,
        bankAccount,
      });
    } else if (pdsTypes.includes('crypto-wallet')) {
      onPaymentDetailsSelect({
        cryptoWallet,
      });
    } else if (pdsTypes.includes('bank-account')) {
      onPaymentDetailsSelect({
        bankAccount,
      });
    }

    onClose();
  };

  const _getInitialValues = () => {
    const { cryptoWallet, bankAccount } = selectedPymentDetails;
    return {
      cryptoWallet: {
        newWalletReference: '',
        currency: TOKEN_TYPES.USDC,
        network: NETWORK_TYPES.Ethereum,
        optionalNetworksCurrencies: [],
        address: '',
        ensAddress: '',
        ...cryptoWallet,
      },
      bankAccount: {
        newAccountReference: '',
        currency: CURRENCY_TYPES.USD,
        accountBeneficiary: '',
        accountNumber: '',
        accountIBAN: '',
        accountACHRoutingNumber: '',
        bankName: '',
        bankAddress: '',
        bankBIC: '',
        bankAccountBeneficiaryType: 'business',
        ...bankAccount,
      },
    };
  };

  return (
    <Modal dismissible show={show} onClose={onClose}>
      <Modal.Header className="dark:border-none">
        Add Payment Details
      </Modal.Header>

      <Formik initialValues={_getInitialValues()} onSubmit={_handleSubmit}>
        {({ values, setFieldValue, submitForm }) => {
          return (
            <Form>
              <Wizard>
                {pdsTypes.map((type, index) => {
                  if (type === 'crypto-wallet')
                    return (
                      <CryptoPDStep
                        allowedManualInput={allowedManualInput}
                        walletList={walletList}
                        selectedPymentDetails={values.cryptoWallet}
                        onPymentDetailsChange={(partialVal) => {
                          setFieldValue('cryptoWallet', {
                            ...values.cryptoWallet,
                            ...partialVal,
                          });
                        }}
                        setFieldValue={setFieldValue}
                        onClose={onClose}
                        onSubmit={submitForm}
                      />
                    );
                  if (type === 'bank-account')
                    return (
                      <BankAccountPDStep
                        allowedManualInput={allowedManualInput}
                        bankAcountList={bankAcountList}
                        onPymentDetailsChange={(partialVal) => {
                          setFieldValue('bankAccount', {
                            ...values.bankAccount,
                            ...partialVal,
                          });
                        }}
                        selectedPymentDetails={values.bankAccount}
                        setFieldValue={setFieldValue}
                        onClose={onClose}
                        onSubmit={submitForm}
                      />
                    );
                  return null;
                })}
              </Wizard>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const ManualOption = {
  value: 'manual',
  label: (
    <span className="flex" id="manual-option">
      <PencilIcon width={12} className="mr-2" /> Enter Payment Details Manually
    </span>
  ),
};

const CryptoPDStep = ({
  allowedManualInput,
  selectedPymentDetails,
  onPymentDetailsChange,
  walletList,
  onClose,
  onSubmit,
}) => {
  const [cryptoWalletManualInput, setCryptoWalletManualInput] = useState(false);
  const { address } = useAccount();

  const ensObject = useEnsName({
    address: `${selectedPymentDetails.address}`,
    chainId: 1,
  });
  const ensName = useEnsAddress({
    name: `${selectedPymentDetails.ensAddress}`,
    chainId: 1,
  });

  useEffect(() => {
    if (address) {
      onPymentDetailsChange({ address });
    }
  }, [address]);

  useEffect(() => {
    if (ensObject.data) {
      onPymentDetailsChange({ ensAddress: ensObject.data });
    }
  }, [ensObject.data]);

  useEffect(() => {
    if (ensName.data) {
      onPymentDetailsChange({ address: ensName.data });
    }
  }, [ensName.data]);

  const _resolveValue = () => {
    if (cryptoWalletManualInput) {
      return ManualOption;
    }

    const wallet = walletList.find(
      (w) => w.address === selectedPymentDetails.address
    );

    if (wallet) {
      return {
        value: wallet.address,
        label: wallet.name,
      };
    }

    return undefined;
  };

  const _resolveWalletHints = () => {
    let hint =
      'Connect to your wallet in the top right corner to fill in automatically.';
    let error = '';

    if (selectedPymentDetails.ensAddress !== '') {
      if (selectedPymentDetails.ensAddress === ensObject.data) {
        hint = 'Wallet & ENS Matched.';
        error = '';
      } else {
        hint = '';
        error = 'Wallet & ENS do not match.';
      }
    }

    if (address && selectedPymentDetails.address.length > 30) {
      if (address === selectedPymentDetails.address) {
        hint =
          'This wallet address is the same as your connected wallet address.';
        error = '';
      } else {
        hint = '';
        error =
          'This address does not match the wallet address connected to this app. Make sure you put the right address in.';
      }
    }

    return {
      hint,
      error,
    };
  };

  const _resolveEnsHints = () => {
    let hint = 'If you own ENS, input it here.';
    let error = '';

    if (selectedPymentDetails.ensAddress !== '') {
      if (selectedPymentDetails.ensAddress === ensObject.data) {
        hint = 'Wallet & ENS Matched.';
        error = '';
      } else {
        error = 'Wallet & ENS do not match.';
        hint = '';
      }
    }

    return {
      hint,
      error,
    };
  };

  const _handleOptionalChange = (network, currency, index) => {
    selectedPymentDetails.optionalNetworksCurrencies[index] = {
      network,
      currency,
    };

    onPymentDetailsChange({
      optionalNetworksCurrencies: [
        ...selectedPymentDetails.optionalNetworksCurrencies,
      ],
    });
  };

  const _handleOptionalRemove = (index) => {
    onPymentDetailsChange({
      optionalNetworksCurrencies:
        selectedPymentDetails.optionalNetworksCurrencies.filter(
          (_, idx) => index !== idx
        ),
    });
  };

  const _formOptions = () => {
    const res = [];
    if (allowedManualInput) {
      res.push({
        label: '',
        options: [ManualOption],
      });
    }

    res.push({
      label: 'Select from Organization Wallets',
      options: walletList.map((v) => ({
        value: v.address,
        label: v.name,
      })),
    });
    return res;
  };

  return (
    <>
      <Modal.Body className="min-h-[342px] space-y-6">
        <CryptoNetworkTokenSelect
          network={selectedPymentDetails.network}
          token={selectedPymentDetails.currency}
          onNetworkChange={(network, currency) => {
            onPymentDetailsChange({ network, currency });
          }}
          onTokenChange={(currency) => {
            onPymentDetailsChange({ currency });
          }}
        />

        <Select
          name="wallet"
          inputId="wallet"
          id="wallet"
          isSearchable={false}
          value={_resolveValue()}
          onChange={(v) => {
            if (v.value === 'manual') {
              setCryptoWalletManualInput(true);
            } else {
              setCryptoWalletManualInput(false);
              onPymentDetailsChange({
                address: v.value,
                newWalletReference: '',
              });
            }
          }}
          maxMenuHeight={210}
          options={_formOptions()}
          placeholder="Select Wallet"
        />

        {cryptoWalletManualInput && (
          <>
            <div className="col-span-8 sm:col-span-6">
              <Field
                name="newWalletReference"
                component={TextField}
                label={'Your reference'}
                onChange={(event) => {
                  onPymentDetailsChange({
                    newWalletReference: event.target.value,
                  });
                }}
                hint={
                  'This will be your reference the next time you want to use this wallet'
                }
              />
            </div>
          </>
        )}

        {(cryptoWalletManualInput || selectedPymentDetails.address) && (
          <>
            <div className="col-span-6 sm:col-span-4">
              <Field
                name="walletAddress"
                value={selectedPymentDetails.address}
                component={TextField}
                onChange={(event) => {
                  onPymentDetailsChange({ address: event.target.value });
                }}
                label={'Wallet Address'}
                hint={_resolveWalletHints().hint}
                error={_resolveWalletHints().error}
              />
            </div>
          </>
        )}

        {(cryptoWalletManualInput || selectedPymentDetails.ensAddress) && (
          <>
            <div className="col-span-6 sm:col-span-4">
              <Field
                name="ensAddress"
                value={selectedPymentDetails.ensAddress}
                component={TextField}
                onChange={(event) => {
                  onPymentDetailsChange({ ensAddress: event.target.value });
                }}
                label={'ENS Address'}
                hint={_resolveEnsHints().hint}
                error={_resolveEnsHints().error}
              />
            </div>
          </>
        )}

        <div className="col-span-8 sm:col-span-6">
          <Label className="!text-slate-300 font-normal">
            Optional Payment Networks, Tokens
          </Label>
        </div>

        {selectedPymentDetails.optionalNetworksCurrencies.map(
          ({ currency, network }, index) => (
            <div key={index} className="col-span-8 sm:col-span-6">
              <div className="flex items-end">
                <div className="flex-1">
                  <CryptoNetworkTokenSelect
                    network={network}
                    token={currency}
                    allowedNetworks={[
                      NETWORK_TYPES.Ethereum,
                      NETWORK_TYPES.Polygon,
                      NETWORK_TYPES.Base,
                      NETWORK_TYPES.Arbitrum,
                    ]}
                    allowedTokens={[
                      TOKEN_TYPES.USDC,
                      TOKEN_TYPES.USDT,
                      TOKEN_TYPES['USDC.native_polygon'],
                    ]}
                    onNetworkChange={(newNetwork, currency) => {
                      _handleOptionalChange(newNetwork, currency, index);
                    }}
                    onTokenChange={(currency) => {
                      _handleOptionalChange(network, currency, index);
                    }}
                  />
                </div>

                <Button
                  outline
                  color="transparentSlate"
                  onClick={() => _handleOptionalRemove(index)}
                  className={'ml-2 mb-1'}
                  size="xs">
                  <XMarkIcon className="w-5 text-slate-300" />
                </Button>
              </div>
            </div>
          )
        )}

        {selectedPymentDetails.optionalNetworksCurrencies.length < 4 && (
          <div className="col-span-8 sm:col-span-6">
            <Button
              onClick={() => {
                onPymentDetailsChange({
                  optionalNetworksCurrencies: [
                    ...selectedPymentDetails.optionalNetworksCurrencies,
                    {
                      currency: TOKEN_TYPES.USDC,
                      network: NETWORK_TYPES.Ethereum,
                    },
                  ],
                });
              }}
              color="slate"
              className={'mt-2'}
              size="xs">
              <PlusIcon className="w-5 " />
            </Button>
          </div>
        )}

        {!!selectedPymentDetails.optionalNetworksCurrencies.length && (
          <div className={`h-[70px]`} />
        )}
      </Modal.Body>

      <StepFooter onClose={onClose} onSubmit={onSubmit} />
    </>
  );
};

const BankAccountPDStep = ({
  allowedManualInput,
  onPymentDetailsChange,
  selectedPymentDetails,
  onClose,
  bankAcountList,
  onSubmit,
}) => {
  const [manualInput, setManualInput] = useState(false);
  const [isACHTransfer, setIsACHTransfer] = useState(false);

  const { getBic, getBankName, isValidating } = useValidateIban(
    selectedPymentDetails.accountIBAN
  );

  const validatedBankName = getBankName();
  const validatedBic = getBic();

  const _resolveValue = () => {
    if (manualInput) {
      return ManualOption;
    }

    const bankIdx = bankAcountList.findIndex((w) => {
      return (
        (selectedPymentDetails.accountNumber &&
          w.accountNumber === selectedPymentDetails.accountNumber) ||
        (selectedPymentDetails.accountIBAN &&
          w.accountIBAN === selectedPymentDetails.accountIBAN)
      );
    });

    if (bankIdx !== -1) {
      return {
        value: bankIdx,
        label: bankAcountList[bankIdx].name,
      };
    }

    return undefined;
  };

  useEffect(() => {
    if (
      !isACHTransfer &&
      (selectedPymentDetails.accountACHRoutingNumber ||
        selectedPymentDetails.accountNumber)
    ) {
      setIsACHTransfer(true);
    }
  }, [
    isACHTransfer,
    selectedPymentDetails.accountACHRoutingNumber,
    selectedPymentDetails.accountNumber,
  ]);

  useEffect(() => {
    validatedBankName &&
      onPymentDetailsChange({ accountIBAN: validatedBankName });
    validatedBic && onPymentDetailsChange({ bankBIC: validatedBic });
  }, [validatedBankName, validatedBic]);

  const _formOptions = () => {
    const res = [];
    if (allowedManualInput) {
      res.push({
        label: '',
        options: [ManualOption],
      });
    }

    res.push({
      label: 'Select from Bank Accounts',
      options: bankAcountList.map((v, idx) => ({
        value: idx,
        label: v.name,
      })),
    });
    return res;
  };

  return (
    <>
      <Modal.Body className="min-h-[342px] space-y-6">
        <div className="flex flex-row gap-4">
          <Select
            name="currency"
            inputId="currency"
            id="currency"
            isSearchable={false}
            value={
              selectedPymentDetails.currency
                ? {
                    value: selectedPymentDetails.currency,
                    label: selectedPymentDetails.currency,
                  }
                : undefined
            }
            onChange={(v) => {
              onPymentDetailsChange({ currency: v.value });
            }}
            maxMenuHeight={210}
            options={CURRENCIES.map((currency) => ({
              value: currency,
              label: currency,
            }))}
            placeholder="Currency"
          />

          <Select
            name="bankAccountBeneficiaryType"
            inputId="bankAccountBeneficiaryType"
            id="bankAccountBeneficiaryType"
            isSearchable={false}
            value={{
              value: selectedPymentDetails.bankAccountBeneficiaryType,
              label: upperCaseNetworkType(
                selectedPymentDetails.bankAccountBeneficiaryType
              ),
            }}
            onChange={(v) => {
              onPymentDetailsChange({ bankAccountBeneficiaryType: v.value });
            }}
            maxMenuHeight={210}
            options={[
              {
                value: 'business',
                label: 'Business',
              },
              {
                value: 'individual',
                label: 'Individual',
              },
            ]}
            placeholder="Beneficiary Type"
          />
        </div>

        <div>
          <Select
            name="wallet"
            inputId="wallet"
            id="wallet"
            isSearchable={false}
            value={_resolveValue()}
            onChange={(v) => {
              if (v.value === 'manual') {
                setManualInput(true);
              } else {
                const bank = bankAcountList[v.value];
                if (bank) {
                  setIsACHTransfer(
                    !!selectedPymentDetails.accountACHRoutingNumber ||
                      !!selectedPymentDetails.accountNumber
                  );
                  onPymentDetailsChange({
                    accountBeneficiary: bank.accountBeneficiary || '',
                    bankAccountBeneficiaryType:
                      bank.bankAccountBeneficiaryType || 'businness',
                    accountNumber: bank.accountNumber || '',
                    accountIBAN: bank.accountIBAN || '',
                    accountACHRoutingNumber: bank.accountACHRoutingNumber || '',
                    bankName: bank.bankName || '',
                    bankAddress: bank.bankAddress || '',
                    bankBIC: bank.bankBIC || '',
                  });
                }
              }
            }}
            maxMenuHeight={210}
            options={_formOptions()}
            placeholder="Select Bank Account"
          />
        </div>

        {manualInput && (
          <>
            <div className="col-span-8 sm:col-span-6">
              <Field
                name="newAccountReference"
                component={TextField}
                label={'Your reference'}
                onChange={(event) => {
                  onPymentDetailsChange({
                    newAccountReference: event.target.value,
                  });
                }}
                hint={
                  'This will be your reference the next time you want to use this bank details'
                }
              />
            </div>
          </>
        )}

        {selectedPymentDetails.currency === CURRENCY_TYPES.USD &&
          (manualInput ||
            selectedPymentDetails.accountACHRoutingNumber ||
            selectedPymentDetails.accountNumber) && (
            <div className="sm:col-span-full">
              <Switch.Group as="div" className="items-left flex ">
                <Switch.Label
                  as="span"
                  className="mr-5 text-sm font-medium leading-6 text-secondary-200"
                  passive>
                  ACH Transfer
                </Switch.Label>
                <Switch
                  id="isACHTransfer"
                  name="isACHTransfer"
                  checked={isACHTransfer}
                  onChange={() => {
                    setIsACHTransfer(!isACHTransfer);
                  }}
                  className={classNames(
                    isACHTransfer ? 'bg-indigo-600' : 'bg-gray-500',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isACHTransfer ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          )}

        {selectedPymentDetails.currency === CURRENCY_TYPES.USD &&
          isACHTransfer &&
          (manualInput ||
            selectedPymentDetails.accountACHRoutingNumber ||
            selectedPymentDetails.accountNumber) && (
            <>
              <div className="flex flex-row gap-4">
                <Field
                  label="Routing Number"
                  name="accountACHRoutingNumber"
                  id="accountACHRoutingNumber"
                  type="text"
                  step="any"
                  className="flex-1"
                  value={selectedPymentDetails.accountACHRoutingNumber}
                  component={TextField}
                  onChange={(event) => {
                    onPymentDetailsChange({
                      accountACHRoutingNumber: event.target.value,
                    });
                  }}
                />

                <Field
                  label="Account Number"
                  component={TextField}
                  value={selectedPymentDetails.accountNumber}
                  onChange={(event) => {
                    onPymentDetailsChange({
                      accountNumber: event.target.value,
                    });
                  }}
                  name="accountNumber"
                  id="accountNumber"
                  type="text"
                  className="flex-1"
                  step="any"
                />
              </div>
            </>
          )}

        {((selectedPymentDetails.currency === CURRENCY_TYPES.USD &&
          !isACHTransfer) ||
          selectedPymentDetails.currency !== CURRENCY_TYPES.USD) &&
          (manualInput ||
            selectedPymentDetails.accountIBAN ||
            selectedPymentDetails.bankBIC) && (
            <>
              <div className="flex flex-row gap-4">
                <Field
                  label={
                    <span className="flex items-center">
                      IBAN
                      {isValidating && <Spinner size="md" className="ml-2" />}
                    </span>
                  }
                  component={TextField}
                  onChange={(event) => {
                    onPymentDetailsChange({ accountIBAN: event.target.value });
                  }}
                  className="flex-1"
                  name="accountIBAN"
                  id="accountIBAN"
                  value={selectedPymentDetails.accountIBAN}
                  type="text"
                />
                <Field
                  label="BIC / SWIFT Code"
                  component={TextField}
                  onChange={(event) => {
                    onPymentDetailsChange({
                      bankBIC: event.target.value,
                    });
                  }}
                  className="flex-1"
                  name="bankBIC"
                  id="bankBIC"
                  value={selectedPymentDetails.bankBIC}
                  type="text"
                  step="any"
                />
              </div>
            </>
          )}

        {(manualInput ||
          selectedPymentDetails.accountBeneficiary ||
          selectedPymentDetails.bankName) && (
          <div className="flex flex-row gap-4">
            <Field
              label="Account Beneficiary Name"
              name="accountBeneficiary"
              id="accountBeneficiary"
              type="text"
              onChange={(event) => {
                onPymentDetailsChange({
                  accountBeneficiary: event.target.value,
                });
              }}
              step="any"
              className="flex-1"
              value={selectedPymentDetails.accountBeneficiary}
              hint="The name of the bank account holder."
              component={TextField}
            />

            <Field
              label="Bank Name"
              name="bankName"
              id="bankName"
              type="text"
              onChange={(event) => {
                onPymentDetailsChange({
                  bankName: event.target.value,
                });
              }}
              value={selectedPymentDetails.bankName}
              className="flex-1"
              component={TextField}
              step="any"
              hint="The name of your bank."
            />
          </div>
        )}
      </Modal.Body>
      <StepFooter onClose={onClose} onSubmit={onSubmit} />
    </>
  );
};

const StepFooter = ({ onClose, onSubmit }) => {
  const { nextStep, isLastStep, stepCount, previousStep } = useWizard();

  const renderCancelButtonText = () => {
    if (stepCount === 1) {
      return 'Cancel';
    }

    return isLastStep ? 'Previous' : 'Cancel';
  };

  const handleCancelButtonClick = () => {
    if (stepCount === 1) {
      return onClose();
    }
    return isLastStep ? previousStep() : onClose();
  };

  const handleNextButtonClick = () => {
    if (stepCount === 1) {
      return onSubmit();
    }
    return isLastStep ? onSubmit() : nextStep();
  };

  return (
    <Modal.Footer className="dark:border-none justify-end">
      <Button onClick={handleCancelButtonClick} color="slate">
        {renderCancelButtonText()}
      </Button>
      <Button color="indigo" onClick={handleNextButtonClick}>
        {isLastStep ? 'Submit' : 'Next'}
      </Button>
    </Modal.Footer>
  );
};
