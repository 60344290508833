import { BillDetails } from 'entities/bill/ui';
import { useRef } from 'react';
import SlideOver from 'shared/ui/SlideOver';

export const BillDetailsSlideOver = ({ show, onPayBill, bill, onClose }) => {
  const bottomRef = useRef(null);

  return (
    <SlideOver show={show} onClose={onClose}>
      <SlideOver.Header>
        <SlideOver.Title>
          <h2>{bill?.receiverName}</h2>
        </SlideOver.Title>
        <SlideOver.Actions>
          {bill?._id && <BillDetails.Actions id={bill._id} ref={bottomRef} />}
        </SlideOver.Actions>
      </SlideOver.Header>
      {bill?._id && (
        <BillDetails
          onPayBill={onPayBill}
          id={bill._id}
          ref={bottomRef}
          onClose={onClose}
        />
      )}
    </SlideOver>
  );
};
