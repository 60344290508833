import React, { useState } from 'react';
import { BatchPaymentModalCryptoToCrypto } from 'widgets/Payments/BatchPaymentModal.CryptoToCrypto';
import { useExpensesBatchPaymentChecker } from './useExpensesBatchPayment';
import { useExpenses } from './useExpenses';

export const BATCH_PAYMENT_PROVIDER_TYPES = {
  Aragon: 'Aragon',
};

export const ExpensesTableBatchPayments = ({
  showPaymentModal,
  onModalClose,
  onDeselectRows,
  selectedExpenses,
  paymentProvider,
}) => {
  const [isUpdatingExpenses, setIsUpdatingExpenses] = useState(false);
  const { cryptoCryptoBatchAvailable, prepareCryptoCryptoBatchItems } =
    useExpensesBatchPaymentChecker(selectedExpenses);
  const { refreshExpenses, updateExpense } = useExpenses();

  const _handlePaymentSuccess = async ({ aragonProposalId }) => {
    setIsUpdatingExpenses(true);
    for (const expenseId of selectedExpenses.reduce(
      (prev, cur) => [...prev, ...cur.expenses.map((e) => e.id)],
      []
    )) {
      await updateExpense({
        expenseId,
        aragonProposalId,
        paymentStatus: 'waiting_aragon_provider_unknown_action',
      });
    }
    setIsUpdatingExpenses(false);
  };

  const _onClose = () => {
    refreshExpenses();
    onDeselectRows();
    onModalClose();
  };

  if (cryptoCryptoBatchAvailable) {
    return (
      <BatchPaymentModalCryptoToCrypto
        paymentProvider={paymentProvider}
        show={showPaymentModal}
        batchItems={prepareCryptoCryptoBatchItems()}
        onClose={_onClose}
        onPaymentSuccessAsync={_handlePaymentSuccess}
        onPaymentSuccessAsyncLoading={isUpdatingExpenses}
      />
    );
  }

  return null;
};
