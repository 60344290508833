import { NETWORK_TYPES, TOKEN_TYPES } from 'features/bill/lib';
import { useCallback } from 'react';

export const useExpensesBatchPaymentChecker = (expenses) => {
  const cryptoCryptoBatchAvailable =
    expenses.length > 0 &&
    (expenses.every(
      (exp) => exp.paymentNetwork === NETWORK_TYPES.Ethereum.toLowerCase()
    ) ||
      expenses.every(
        (exp) => exp.paymentNetwork === NETWORK_TYPES.Polygon.toLowerCase()
      )) &&
    (expenses.every((exp) => exp.paymentCurrency === TOKEN_TYPES.USDC) ||
      expenses.every((exp) => exp.paymentCurrency === TOKEN_TYPES.USDT)) &&
    expenses.every((exp) => exp.paymentStatus === 'unpaid');

  const _prepareCryptoCryptoBatchItems = useCallback(() => {
    return expenses.map((exp) => ({
      to: exp.recipientPaymentDetails?.cryptoWallet?.address,
      amount: exp.paymentAmount,
      token: exp.paymentCurrency,
      network: exp.paymentNetwork, // possibly uppercase
    }));
  }, [expenses]);

  return {
    batchAvailable: cryptoCryptoBatchAvailable,
    cryptoCryptoBatchAvailable,

    prepareCryptoCryptoBatchItems: _prepareCryptoCryptoBatchItems,
  };
};
