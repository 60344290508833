import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import EthLogo from 'shared/images/eth.png';
import UsdcLogo from 'shared/images/usdc.png';
import UsdtLogo from 'shared/images/usdt.png';
import BusdLogo from 'shared/images/busd.png';
import DaiLogo from 'shared/images/dai.png';
import AntLogo from 'shared/images/ant.png';
import EursLogo from 'shared/images/eurs.png';
import OpLogo from 'shared/images/optimism.png';
import VelaLogo from 'shared/images/vela.png';
import EsVelaLogo from 'shared/images/esvela.webp';
import ArbLogo from 'shared/images/arb.png';

export * from './utils';

export function triggerFileDownload(name, file) {
  // create a link element to trigger the download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = name;
  link.click();
}

export function openFileInNewTab(file) {
  window.URL.createObjectURL(file);
  const blob = new Blob([file], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
}

const imgClasses = 'mr-0.5 inline-block h-5 w-5';
export function tokenToImage(token) {
  switch (token) {
    case 'ETH':
      return <img src={EthLogo} alt={token} className={imgClasses} />;
    case 'OP':
      return (
        <img
          src={OpLogo}
          alt={token}
          className={classnames(imgClasses, '-mt-0.5 mr-1 h-2 w-2')}
        />
      );
    case 'VELA':
      return <img src={VelaLogo} alt={token} className={imgClasses} />;
    case 'ARB':
      return <img src={ArbLogo} alt={token} className={imgClasses} />;
    case 'esVELA':
      return <img src={EsVelaLogo} alt={token} className={imgClasses} />;
    case 'USDC':
    case 'USDC.native_polygon':
      return <img src={UsdcLogo} alt={token} className={imgClasses} />;
    case 'USDT':
      return <img src={UsdtLogo} alt={token} className={imgClasses} />;
    case 'BUSD':
      return <img src={BusdLogo} alt={token} className={imgClasses} />;
    case 'DAI':
      return <img src={DaiLogo} alt={token} className={imgClasses} />;
    case 'ANT':
      return <img src={AntLogo} alt={token} className={imgClasses} />;
    case 'EURS':
      return (
        <img
          src={EursLogo}
          alt={token}
          className={classnames(imgClasses, '-mt-0.5 mr-1 h-4 w-4')}
        />
      );
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    default:
      console.warn('Unknown token!');
      return ' ';
  }
}

const imgClassesV1 = 'mr-0.5 h-5 w-5';
export function tokenToImageV1(token) {
  switch (token) {
    case 'ETH':
      return <img src={EthLogo} alt={token} className={imgClassesV1} />;
    case 'OP':
      return <img src={OpLogo} alt={token} className={imgClassesV1} />;
    case 'ARB':
      return <img src={ArbLogo} alt={token} className={imgClassesV1} />;
    case 'VELA':
      return <img src={VelaLogo} alt={token} className={imgClassesV1} />;
    case 'esVELA':
      return <img src={EsVelaLogo} alt={token} className={imgClassesV1} />;
    case 'USDC':
    case 'USDC.native_polygon':
      return <img src={UsdcLogo} alt={token} className={imgClassesV1} />;
    case 'USDT':
      return <img src={UsdtLogo} alt={token} className={imgClassesV1} />;
    case 'BUSD':
      return <img src={BusdLogo} alt={token} className={imgClassesV1} />;
    case 'DAI':
      return <img src={DaiLogo} alt={token} className={imgClassesV1} />;
    case 'ANT':
      return <img src={AntLogo} alt={token} className={imgClassesV1} />;
    case 'EURS':
      return (
        <img
          src={EursLogo}
          alt={token}
          className={twMerge(imgClassesV1, ' mr-1 h-4 w-4')}
        />
      );
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'CHF':
      return 'CHF';
    default:
      console.warn('Unknown token!');
      return ' ';
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
