import { useCurrentUser, useUserActions } from 'entities/user/model';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { TextField } from 'shared/ui/form';

const UserUpdatePersonalInfo = () => {
  const [userUpdatedSuccessfully, setUserUpdatedSuccessfully] = useState(false);

  const { data: user, setData: setUserData } = useCurrentUser();

  const { updateUserAction } = useUserActions();

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    return errors;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('Handle Submit');
    try {
      const response = updateUserAction(values);

      if (response.status === 200) {
        setUserData({
          ...user,
          firstName: values.firstName,
          lastName: values.lastName,
        });
        setUserUpdatedSuccessfully(true);
        setSubmitting(false);
      }
    } catch (error) {
      console.error('Failed to update user', error);
    }
    setSubmitting(false);
  };

  return (
    <div className="">
      <div className="mt-10 sm:mt-0">
        <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-white ">
                Personal Information
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                Your name & email address.
              </p>
            </div>
            <Formik
              initialValues={{
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
              }}
              enableReinitialize
              validate={validate}
              onSubmit={handleSubmit}>
              {({ errors, touched, setSubmitting }) => (
                <Form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <Field
                      name="firstName"
                      component={TextField}
                      label="First Name"
                      error={touched.firstName && errors.firstName}
                    />

                    <Field
                      name="lastName"
                      component={TextField}
                      label="Last Name"
                      error={touched.lastName && errors.lastName}
                    />
                  </div>

                  <div className="mt-2 flex">
                    {userUpdatedSuccessfully ? (
                      <p className="text-sm italic text-green-500">
                        User updated successfully!
                      </p>
                    ) : (
                      <button
                        type="submit"
                        disabled={userUpdatedSuccessfully}
                        className=" rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800">
                        Save Details
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserUpdatePersonalInfo;
