import { useCurrentUser } from "entities/user/model";
import { useCallback } from "react";
import { Button } from "shared/ui";
import { useBillMutations } from "entities/bill/model";
import { toast } from "react-toastify";
import { useBill } from "entities/bill/model";

export const BillApprovalButtons = ({ id }) => {
  const { data: bill } = useBill(id);
  const { data } = useCurrentUser();
  const { update } = useBillMutations();

  const handleStatusUpdate = useCallback(
    async (status) => {
      try {
        await update.mutateAsync({
          billId: bill?._id,
          params: {
            requester: data.email,
            approver: data.email,
            approvedAt: new Date(),
            approvalStatus: status,
            organization: data?.selected_organization?._id,
          },
        });

        toast.success(`Bill ${status}!`);
      } catch (error) {
        console.error(error);
      }
    },
    [bill?._id, data, update]
  );

  if (data.email !== bill?.approval?.approver) {
    return null;
  }

  return (
    <div className="flex items-start justify-start">
      <div className="space-x-2 py-2">
        <Button ui="success" onClick={() => handleStatusUpdate("approved")}>
          Approve
        </Button>
        <Button ui="danger" onClick={() => handleStatusUpdate("rejected")}>
          Reject
        </Button>
      </div>
    </div>
  );
};
