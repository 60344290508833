export const EmptyVendors = () => {
  return (
    <div className="mt-20 text-center">
      <h3 className="mt-2 text-sm font-semibold text-slate-300">No Vendors</h3>
      <p className="mt-1 text-sm text-slate-500">
        Vendors vill be added automatically if you create bill or receieve
        payment request
      </p>
    </div>
  );
};
