import { useOrganizationMutations } from 'entities/organization';
import { useCurrentUser } from 'entities/user/model';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button } from 'shared/ui';
import { Spinner } from 'shared/ui/Spinner';
import * as Yup from 'yup';
import { twMerge } from 'tailwind-merge';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import useToggle from 'shared/hooks/useToggle';
import { Checkbox } from '../../shared/ui/form/Checkbox';
import { PaymentsOnboardingWidget } from '../../features/payments/paymentModals/PaymentsOnboardingModal';

const initialValues = {
  name: '',
  email: '',
  address: '',
  city: '',
  country: '',
  postcode: '',
  vat: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  termsAccepted: Yup.bool()
    .oneOf([true], 'Please accept terms and conditions')
    .required('Please accept terms and conditions')
    .nullable(),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

const CreateCompanyForm = ({ variant }) => {
  const isConfirmDetails = variant === 'confirm-details';
  const { data } = useCurrentUser();
  const { create } = useOrganizationMutations();

  const organizationWithDetailsExists = data?.selected_organization?.address;

  const [includeAdvancedDetails, toggleIncludeAdvancedDetails] = useToggle(
    organizationWithDetailsExists
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await create.mutateAsync({ data, ...values });
      toast.success('Company information updated successfully!');
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const renderSubmitButton = (isSubmitting) => {
    if (data?.role === 'member') {
      return (
        <Button
          type="submit"
          className="mt-2 rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
          disabled>
          Cannot Change Company Settings
        </Button>
      );
    }

    const btnText = data?.selected_organization?._id
      ? 'Update Details'
      : isConfirmDetails
      ? 'Continue to Application'
      : 'Create Company';
    return (
      <Button
        type="submit"
        className="mt-2 rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
        disabled={isSubmitting}
        LeadingIcon={
          isSubmitting
            ? () => <Spinner className="h-5 w-5 text-white" />
            : undefined
        }>
        {btnText}
      </Button>
    );
  };

  return (
    <div className="mt-10 sm:mt-0">
      <PaymentsOnboardingWidget />
      <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div
            className={twMerge(
              'md:col-span-1',
              isConfirmDetails && 'md:col-span-3 mb-4'
            )}>
            <h3 className="text-lg font-medium leading-6 text-white">
              {isConfirmDetails ? 'Confirm Your Details' : 'Your Organization'}
            </h3>
            <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
              {isConfirmDetails
                ? 'These details will be used for bills & invoices. You can change your details later.'
                : 'Manage organization details. '}
            </p>
          </div>
          <div
            className={twMerge(
              'md:col-span-2 md:mt-0 ',
              isConfirmDetails && 'md:col-span-3 text-left'
            )}>
            <Formik
              enableReinitialize
              initialValues={{
                ...initialValues,
                email: data.email || '',
                ...data.selected_organization,
                termsAccepted: isConfirmDetails ? false : null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                  <div className="sm:rounded-md">
                    <div className="space-y-6 bg-white dark:bg-slate-800 ">
                      {data?.role === 'member' && (
                        <div
                          className="mb-4 flex rounded-lg border border-blue-300 bg-blue-50 p-4 text-sm text-blue-800 dark:border-blue-800 dark:bg-gray-800 dark:text-blue-400"
                          role="alert">
                          <svg
                            aria-hidden="true"
                            className="mr-3 inline h-5 w-5 flex-shrink-0"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"></path>
                          </svg>
                          <span className="sr-only">Info</span>
                          <div>
                            <span className="font-medium">
                              You are not an admin.{' '}
                            </span>
                            Only admins can change company settings.
                          </div>
                        </div>
                      )}

                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <div>
                            <label
                              htmlFor="name"
                              className="block text-sm text-[#CBD5E1]">
                              {isConfirmDetails ? 'Name' : 'Company Name'}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="mt-2 text-xs text-rose-500"
                              />
                            </div>
                            <p className="mt-2 text-xs text-[#94A3B8] ">
                              This can be your name or name of your company.
                            </p>
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm text-[#CBD5E1] ">
                              {isConfirmDetails ? 'Email' : 'Company Email'}
                            </label>
                            <div className="mt-1">
                              <Field
                                type="email"
                                name="email"
                                disabled
                                id="email"
                                className="mt-1 block w-full disabled:bg-slate-800 disabled:text-slate-400 rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="mt-2 text-xs text-rose-500"
                              />
                            </div>
                            <p className="mt-2 text-sm text-[#94A3B8] ">
                              Your email or company email.
                            </p>
                          </div>
                        </div>

                        {isConfirmDetails && (
                          <div className="col-span-6">
                            <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <Checkbox
                                  checked={values.termsAccepted}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'termsAccepted',
                                      e.target.checked
                                    );
                                  }}
                                  id="terms"
                                  color={'indigo'}
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor="terms"
                                  className="font-light text-gray-500 dark:text-slate-300">
                                  I accept the{' '}
                                  <a
                                    className="font-medium text-primary-600 hover:underline dark:text-indigo-500"
                                    href="/terms"
                                    target="_blank">
                                    Terms and Conditions
                                  </a>
                                </label>
                              </div>
                            </div>

                            <ErrorMessage
                              name="termsAccepted"
                              component="div"
                              className="mt-2 text-xs text-rose-500"
                            />
                          </div>
                        )}

                        {!isConfirmDetails && (
                          <>
                            {/* Only show toggle if organization with details doesn't already exist */}
                            {!organizationWithDetailsExists && (
                              <div className="col-span-6 sm:col-span-6">
                                <Switch.Group
                                  as="div"
                                  className="flex items-center justify-between">
                                  <span className="flex flex-grow flex-col">
                                    <Switch.Label
                                      as="span"
                                      className="text-sm font-medium leading-6 text-[#CBD5E1]"
                                      passive>
                                      Add Address & Tax Details
                                    </Switch.Label>
                                    <Switch.Description
                                      as="span"
                                      className="text-xs text-[#94A3B8]">
                                      Add address and tax details. These details
                                      will be used for bills & invoices.
                                    </Switch.Description>
                                  </span>

                                  <Switch
                                    checked={includeAdvancedDetails}
                                    onChange={toggleIncludeAdvancedDetails}
                                    className={classNames(
                                      includeAdvancedDetails
                                        ? 'bg-indigo-600'
                                        : 'bg-gray-500',
                                      'relative inline-flex h-6 w-11 mr-2 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                    )}>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        includeAdvancedDetails
                                          ? 'translate-x-5'
                                          : 'translate-x-0',
                                        'pointer-events-none inline-block mr-2 h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            )}

                            {includeAdvancedDetails && (
                              <>
                                {' '}
                                <div className="col-span-6 sm:col-span-3">
                                  <div>
                                    <label
                                      htmlFor="address"
                                      className="block text-sm text-[#CBD5E1] ">
                                      Company Address
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="address"
                                        id="address"
                                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <div>
                                    <label
                                      htmlFor="city"
                                      className="block text-sm text-[#CBD5E1] ">
                                      Company City
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="city"
                                        id="city"
                                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-6 sm:col-span-2">
                                  <div>
                                    <label
                                      htmlFor="country"
                                      className="block text-sm text-[#CBD5E1] ">
                                      Company Country
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="country"
                                        id="country"
                                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-6 sm:col-span-2">
                                  <div>
                                    <label
                                      htmlFor="postcode"
                                      className="block text-sm text-[#CBD5E1] ">
                                      Company ZIP Code
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="postcode"
                                        id="postcode"
                                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-6 sm:col-span-2">
                                  <div>
                                    <label
                                      htmlFor="vat"
                                      className="block text-sm text-[#CBD5E1] ">
                                      Company VAT / EIN
                                    </label>
                                    <div className="mt-1">
                                      <Field
                                        type="text"
                                        name="vat"
                                        id="vat"
                                        className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="bg-gray-50 pb-3 pt-5 text-right dark:bg-slate-800 ">
                      {renderSubmitButton(isSubmitting)}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCompanyForm;
