import { useMemo, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Formik, Form, Field } from 'formik';
import { useCurrentUser, useCurrentUserDetails } from 'entities/user/model';
import { TextField } from 'shared/ui/form';
import {
  PlusCircleIcon,
  WalletIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/outline';
import {
  usePaymentDetails,
  usePaymentDetailsMutations,
} from 'entities/organization';
import { Button, Table, Modal } from 'shared/ui';
import { Spinner } from 'shared/ui/Spinner';
import useToggle from 'shared/hooks/useToggle';
import { toast } from 'react-toastify';
import { useDialog } from 'shared/components/Dialog';
import { useAccount, useEnsAddress, useEnsName } from 'wagmi';
import { ROLES } from '../../shared/hooks';

const PaymentDetails = () => {
  const { data: paymentDetailsData, isPending } = usePaymentDetails();
  const items = (paymentDetailsData?.result || [])
    .filter((pd) => ['crypto_wallet', 'bank'].includes(pd.type))
    .sort((a, b) => (a.type === 'crypto_wallet' ? -1 : 1));

  const [modalOpened, toggleModalOpened] = useToggle(false);
  const [editableItem, setEditableItem] = useState(null);
  const { createPaymentDetails, updatePaymentDetails, deletePaymentDetails } =
    usePaymentDetailsMutations();
  const { data: userData } = useCurrentUser();
  const { getCurrentOrgRole } = useCurrentUserDetails();
  const dialog = useDialog();

  const { address, isConnected } = useAccount();
  const [walletAddress, setWalletAddress] = useState(
    isConnected ? address : ''
  );
  const ensObject = useEnsName({
    address: `${walletAddress}`,
    chainId: 1,
  });
  const [ensAddress, setEnsAddress] = useState('');
  const ensName = useEnsAddress({ name: `${ensAddress}`, chainId: 1 });

  // Getting Wallet Address
  useEffect(() => {
    async function getWalletAddress() {
      address ? setWalletAddress(address) : setWalletAddress('');
    }
    getWalletAddress();
  }, [address]);

  // Create a function that fetches ensObject.data every time there is a change in walletAddress
  useEffect(() => {
    async function getEnsAddress() {
      const resolvedEnsAddress = await ensObject.data;
      if (resolvedEnsAddress) {
        setEnsAddress(resolvedEnsAddress);
      }
    }

    getEnsAddress();
  }, [walletAddress, ensObject.data]);

  // Create a function that fetches ensName.data every time there is a change in ensAddress
  useEffect(() => {
    async function getEnsName() {
      const resolvedEnsName = await ensName.data;
      if (resolvedEnsName) {
        setWalletAddress(resolvedEnsName);
      }
    }

    getEnsName();
  }, [ensAddress, ensName.data]);

  const _onClose = () => {
    setEditableItem(null);
    setWalletAddress('');
    setEnsAddress('');
    toggleModalOpened(false);
  };

  const _handleDelete = (item) => {
    const confirmationText =
      'Are you sure you want to delete this Payment Details?';

    dialog.confirm({
      title: 'Delete Payment Details',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await deletePaymentDetails.mutateAsync(item.id);
          toast.success('Payment Details Deleted!');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const _handleEdit = (item) => {
    setEditableItem(item);
    item.details?.cryptoWallet?.address &&
      setWalletAddress(item.details.cryptoWallet.address);
    item.details?.cryptoWallet?.ensAddress &&
      setEnsAddress(item.details.cryptoWallet.ensAddress);
    toggleModalOpened(true);
  };

  const columns = useMemo(() => {
    const res = [
      {
        header: () => <span className="block p-5 text-left">Name</span>,
        accessorKey: 'name',
        enableSorting: false,
        cell: ({ row: { original: item } }) => (
          <div className="px-5 py-3 text-left font-medium text-white">
            {item.name}
          </div>
        ),
      },
      {
        header: () => <span className="block p-5 text-left">Type</span>,
        accessorKey: 'type',
        enableSorting: false,
        cell: ({ row: { original: item } }) => (
          <div className="p-3 text-left font-normal">
            {item.type === 'crypto_wallet' ? (
              <div className="flex items-center">
                <WalletIcon className="text-gray mr-2 h-4 w-4 text-gray-400" />{' '}
                Crypto Wallet
              </div>
            ) : (
              <div className="flex items-center">
                <BuildingLibraryIcon className="mr-2 h-4 w-4 text-gray-400" />{' '}
                Bank Account
              </div>
            )}
          </div>
        ),
      },
      {
        header: () => <span className="block p-5 text-left">Details</span>,
        accessorKey: 'details',
        enableSorting: false,
        cell: ({ row: { original: item } }) => (
          <div className="p-3 text-left">
            {item.type === 'crypto_wallet' ? (
              <>
                <span className="mr-1 text-gray-400">Address: </span>
                <span>{item.details.cryptoWallet.address}</span>

                {!!item.details.cryptoWallet.ensAddress && (
                  <>
                    <br />{' '}
                    <span className="mr-1 text-gray-400">ENS Address:</span>{' '}
                    <span className="dark:text-gray-300">
                      {item.details.cryptoWallet.ensAddress}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                {!!item.details.bankAccount.accountBeneficiary && (
                  <>
                    <span className="mr-1 text-gray-400">Beneficiary: </span>
                    <span>{item.details.bankAccount.accountBeneficiary}</span>
                  </>
                )}
                {!!item.details.bankAccount.accountNumber && (
                  <>
                    <br />{' '}
                    <span className="mr-1 text-gray-400">Account Number: </span>
                    <span>{item.details.bankAccount.accountNumber}</span>
                  </>
                )}
                {!!item.details.bankAccount.accountIBAN && (
                  <>
                    <br /> <span className="mr-1 text-gray-400">IBAN: </span>
                    <span>{item.details.bankAccount.accountIBAN}</span>
                  </>
                )}
                {!!item.details.bankAccount.accountACHRoutingNumber && (
                  <>
                    <br />{' '}
                    <span className="mr-1 text-gray-400">Routing Number: </span>
                    <span>
                      {item.details.bankAccount.accountACHRoutingNumber}
                    </span>
                  </>
                )}
                {!!item.details.bankAccount.bankName && (
                  <>
                    <br />{' '}
                    <span className="mr-1 text-gray-400">Bank Name: </span>
                    <span>{item.details.bankAccount.bankName}</span>
                  </>
                )}
              </>
            )}
          </div>
        ),
      },
    ];

    if (getCurrentOrgRole() === ROLES.ADMIN) {
      res.push({
        header: () => <span className="block p-5 text-right">Actions</span>,
        accessorKey: 'actions',
        enableSorting: false,
        cell: ({ row: { original: item } }) => (
          <div className=" p-3 text-right font-medium">
            <Button
              onClick={() => _handleEdit(item)}
              className={'mr-2 bg-transparent'}
              ui="secondary"
              size="xs">
              Edit
            </Button>
            <Button
              id={`delete-${item.name}`}
              onClick={() => _handleDelete(item)}
              className={'bg-transparent text-rose-500'}
              ui="secondary"
              size="xs">
              Delete
            </Button>
          </div>
        ),
      });
    }

    return res;
  }, [getCurrentOrgRole]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      if (editableItem) {
        await updatePaymentDetails.mutateAsync({
          id: editableItem.id,
          name: values.name,
          type: values.type,
          details:
            values.type === 'crypto_wallet'
              ? {
                  cryptoWallet: {
                    address: walletAddress,
                    ensAddress: ensAddress,
                  },
                }
              : {
                  bankAccount: {
                    accountBeneficiary: values.accountBeneficiary,
                    bankAccountBeneficiaryType:
                      values.bankAccountBeneficiaryType,
                    accountNumber: values.accountNumber,
                    accountIBAN: values.accountIBAN,
                    accountACHRoutingNumber: values.accountACHRoutingNumber,
                    bankName: values.bankName,
                    bankAddress: values.bankAddress,
                    bankBIC: values.bankBIC,
                  },
                },
        });
      } else {
        await createPaymentDetails.mutateAsync({
          name: values.name,
          type: values.type,
          businessRelation: 'client',
          details:
            values.type === 'crypto_wallet'
              ? {
                  cryptoWallet: {
                    address: walletAddress,
                    ensAddress: ensAddress,
                  },
                }
              : {
                  bankAccount: {
                    accountBeneficiary: values.accountBeneficiary,
                    bankAccountBeneficiaryType:
                      values.bankAccountBeneficiaryType,
                    accountNumber: values.accountNumber,
                    accountIBAN: values.accountIBAN,
                    accountACHRoutingNumber: values.accountACHRoutingNumber,
                    bankName: values.bankName,
                    bankAddress: values.bankAddress,
                    bankBIC: values.bankBIC,
                  },
                },
          m_ownerUserId: userData.user_id,
          m_ownerOrganisationId: userData.selected_organization?._id,
        });
      }

      toast.success('Successfully added payment details.');
      _onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (isPending) {
    return (
      <div className="flex h-full w-full items-center justify-center py-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {!isPending && items.length === 0 ? (
        <div className="mt-20 text-center">
          <h3 className="mt-2 text-sm font-semibold text-gray-400">
            No Payment Details
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {getCurrentOrgRole() === ROLES.ADMIN
              ? 'Get started by adding a wallet or bank account.'
              : 'Your Organization Admin did not add any payment details yet'}
          </p>
          {getCurrentOrgRole() === ROLES.ADMIN && (
            <div className="mt-6 flex justify-center gap-4">
              <Button
                onClick={() => toggleModalOpened(true)}
                LeadingIcon={PlusCircleIcon}>
                New Payment Details
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="py-8">
          <div className=" md:grid md:grid-cols-3 md:gap-6">
            <div className="mb-4 md:col-span-3">
              <div className="px-4 sm:px-0">
                <div className="flex items-end justify-between">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Organization Payment Details
                    </h3>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      Access and manage your payment details here for use in
                      future invoices.
                    </p>
                  </div>
                  {getCurrentOrgRole() === ROLES.ADMIN && (
                    <div className="">
                      <Button
                        onClick={() => toggleModalOpened(true)}
                        LeadingIcon={PlusCircleIcon}>
                        New Payment Details
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Table data={items} columns={columns} onRowClick={() => {}} />
        </div>
      )}

      <Modal show={modalOpened} handleClose={_onClose}>
        <Dialog.Panel className="relative w-[620px]  transform overflow-hidden rounded-lg bg-gray-800 px-6 pb-6 pt-10 text-left text-white shadow-xl transition-all">
          <Dialog.Title
            as="h3"
            className="mb-8 text-center text-base font-semibold leading-6">
            {!!editableItem
              ? 'Edit Payment Details'
              : 'Add New Payment Details'}
          </Dialog.Title>

          <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={
              editableItem
                ? {
                    type: editableItem.type,
                    name: editableItem.name,

                    bankAccountBeneficiaryType:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount
                            .bankAccountBeneficiaryType || 'business'
                        : '',

                    accountBeneficiary:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount.accountBeneficiary
                        : '',

                    accountNumber:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount.accountNumber
                        : '',

                    accountIBAN:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount.accountIBAN
                        : '',

                    accountACHRoutingNumber:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount
                            .accountACHRoutingNumber
                        : '',

                    bankName:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount.bankName
                        : '',

                    bankAddress:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount.bankAddress
                        : '',

                    bankBIC:
                      editableItem.type === 'bank'
                        ? editableItem.details.bankAccount.bankBIC
                        : '',
                  }
                : {
                    type: 'crypto_wallet',
                    name: '',
                    walletAddress: '',
                    walletEnsAddress: '',
                    accountBeneficiary: '',
                    bankAccountBeneficiaryType: 'business',
                    accountNumber: '',
                    accountIBAN: '',
                    accountACHRoutingNumber: '',
                    bankName: '',
                    bankAddress: '',
                    bankBIC: '',
                  }
            }>
            {({ isSubmitting, values, setFieldValue }) => {
              return isSubmitting ? (
                <div className="flex h-full w-full items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <Form className="space-y-6">
                  <div className="col-span-8 sm:col-span-6">
                    <label className="block text-sm font-medium text-gray-600 dark:text-[#CBD5E1]">
                      Type of payment details
                    </label>
                    <Field
                      name="type"
                      component="select"
                      onChange={(event) => {
                        setFieldValue('type', event.target.value);
                      }}
                      className="text-[#FFFFFF mt-1 block w-full rounded-md  border-2 border-[#64748b] bg-[#18222d] px-3 py-2 text-[#FFFFFF] shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option value="crypto_wallet">Crypto Wallet</option>
                      <option value="bank">Bank Account</option>
                    </Field>
                  </div>

                  <Field
                    name="name"
                    component={TextField}
                    label={
                      values.type === 'crypto'
                        ? 'Wallet Name'
                        : 'Your reference name'
                    }
                    hint={
                      values.type === 'crypto'
                        ? 'This will be your reference the next time you want to use this wallet'
                        : 'This will be your reference the next time you want to use this bank details'
                    }
                  />

                  {values.type === 'crypto_wallet' ? (
                    <>
                      <div className="col-span-8 sm:col-span-6">
                        <Field
                          name="walletAddress"
                          value={walletAddress}
                          onChange={(event) => {
                            setWalletAddress(event.target.value);
                          }}
                          component={TextField}
                          label="Wallet Address"
                        />

                        {ensAddress !== '' &&
                          (ensAddress === ensObject.data ? (
                            <p className="mt-2 text-sm text-green-800 dark:text-green-400">
                              Wallet & ENS Matched.
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-red-800 dark:text-red-400">
                              Wallet & ENS do not match.
                            </p>
                          ))}

                        {address && walletAddress.length > 30 ? (
                          address === walletAddress ? (
                            <p className="mt-2 text-sm text-green-800 dark:text-green-400">
                              This wallet address is the same as your connected
                              wallet address.
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                              This address does not match the wallet address
                              connected to this app. Make sure you put the right
                              address in.
                            </p>
                          )
                        ) : (
                          <p className=" mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
                            Connect to your wallet in the top right corner to
                            fill in automatically.
                          </p>
                        )}
                      </div>

                      <div className="col-span-8 sm:col-span-6">
                        <Field
                          name="walletEnsAddress"
                          component={TextField}
                          value={ensAddress}
                          onChange={(event) => {
                            setEnsAddress(event.target.value);
                          }}
                          label="ENS Address"
                          hint="If you own ENS, input it here."
                        />

                        {ensAddress !== '' &&
                          (ensAddress === ensObject.data ? (
                            <p className="mt-2 text-sm text-green-800 dark:text-green-400">
                              Wallet & ENS Matched.
                            </p>
                          ) : (
                            <p className="mt-2 text-sm text-red-800 dark:text-red-400">
                              Wallet & ENS do not match.
                            </p>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-between gap-x-4">
                        <Field
                          name="bankName"
                          className="flex-1"
                          component={TextField}
                          label="Bank Name"
                          hint="Name of the bank."
                        />

                        <Field
                          name="accountBeneficiary"
                          className="flex-1"
                          component={TextField}
                          label="Account Beneficiary Name"
                          hint="Name of the account holder."
                        />
                      </div>

                      <div>
                        <div className="flex justify-between gap-x-4">
                          <Field
                            name="accountACHRoutingNumber"
                            className="flex-1"
                            component={TextField}
                            label="Routing Number"
                          />

                          <Field
                            name="accountNumber"
                            className="flex-1"
                            component={TextField}
                            label="Account Number"
                          />
                        </div>
                        <p className="text-sm text-gray-500 dark:text-[#B2BDCC]">
                          Used for local USD transfers.
                        </p>
                      </div>
                      <div>
                        <div className="flex justify-between gap-x-4">
                          <Field
                            name="bankBIC"
                            component={TextField}
                            className="flex-1"
                            label="BIC / SWIFT"
                            hint="BIC / SWIFT of the bank."
                          />

                          <Field
                            name="bankAddress"
                            component={TextField}
                            className="flex-1"
                            label="Bank Address"
                            hint="Address of the bank."
                          />
                        </div>
                      </div>

                      <Field
                        name="accountIBAN"
                        component={TextField}
                        label="IBAN Number"
                        hint="IBAN number of the bank account."
                      />
                    </>
                  )}

                  <div className="!mt-12 flex flex-row-reverse gap-x-4">
                    <Button type="submit" className="flex-1 justify-center">
                      {editableItem ? 'Save' : 'Create'}
                    </Button>
                    <Button
                      ui="secondary"
                      className="flex-1 justify-center"
                      onClick={_onClose}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Dialog.Panel>
      </Modal>
    </>
  );
};

export default PaymentDetails;
