import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useLinkMutations, useLinks } from "entities/link/model";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DetailSection } from "shared/components";
import { useDialog } from "shared/components/Dialog";
import { configureUrlPath, getRelativeTime } from "shared/lib";
import { Button } from "shared/ui";
import { Spinner } from "shared/ui/Spinner";

export const LinkDetails = ({ id, onClose }) => {
  const dialog = useDialog();
  const { data: links } = useLinks(id);
  const link = links?.find((link) => link._id === id);
  const { deleteLink } = useLinkMutations();

  const handleDelete = async () => {
    const confirmationText =
      "Are you sure you want to delete this pay link? This action cannot be undone and you and your team members will not able to see this pay link anymore.";

    dialog.confirm({
      title: "Delete Pay Link",
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await deleteLink.mutateAsync(link._id);
          toast.success("Pay Link Deleted!");
          onClose();
        } catch (error) {
          toast.error("Deleting Pay Link Failed");
          console.error(error);
        }
      },
    });
  };

  const handleCopyLink = (event, url) => {
    event.preventDefault();
    event.stopPropagation();

    navigator.clipboard.writeText(
      configureUrlPath(url, window.location.origin)
    );
    toast.info("Copied to clipboard");
  };

  return link?._id ? (
    <div className="relative mb-40 flex-1 space-y-6 px-6 pt-6">
      <DetailSection header="General">
        <DetailSection.Item term="Name">{link.name}</DetailSection.Item>
        <DetailSection.Item term="Link">
          <button
            className="text-white hover:text-primary-400"
            onClick={(e) => handleCopyLink(e, link.url)}>
            <ClipboardDocumentIcon className="mr-0.5 inline h-3 w-3" />
            Copy Link
          </button>
        </DetailSection.Item>
        <DetailSection.Item term="Tokens">
          {link.tokens.join(", ")}
        </DetailSection.Item>
        <DetailSection.Item term="Networks">
          {link.networks.join(", ")}
        </DetailSection.Item>
        <DetailSection.Item term="Created">
          {getRelativeTime(link.createdAt)}
        </DetailSection.Item>
      </DetailSection>

      <DetailSection header="Company">
        <DetailSection.Item term="Name">{link.companyname}</DetailSection.Item>
        <DetailSection.Item term="Email">
          {link.companyemail}
        </DetailSection.Item>
        <DetailSection.Item term="Location">
          {`${link.companyaddress}, ${link.companycity}, ${link.companycountry}, ${link.companypostcode}`}
        </DetailSection.Item>
      </DetailSection>

      <DetailSection header="Approval">
        <DetailSection.Item term="Approver">
          {link.approver || "No approver"}
        </DetailSection.Item>
      </DetailSection>

      <div className="flex gap-x-2">
        <Button size="lg">
          <Link
            to={`/dashboard/bills/links/${link._id}`}
            state={{ linkId: link._id }}>
            Edit Pay Link
          </Link>
        </Button>
        <Button size="lg" ui="danger" onClick={handleDelete}>
          Delete Pay Link
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner />
    </div>
  );
};
