import React from 'react';
import { ClockIcon, FaceFrownIcon } from '@heroicons/react/24/outline';

export const SuccessConfirmation = ({
  title = 'Transaction Initiation',
  description = 'Your payment is in progress. Transactions typically complete in 1-3 working days.',
  show,
  onClose,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-slate-800">
      <div className="flex flex-col items-center">
        <ClockIcon className="text-emerald-600 inline h-16 w-16" />

        <div className="z-50 my-6 max-w-lg space-y-4 px-6 text-center">
          <h3 className="text-2xl font-semibold leading-6 text-slate-300">
            {title}
          </h3>
          <div className="text-slate-400">{description}</div>
        </div>

        <div className="flex justify-between space-x-2 px-2">
          <button
            onClick={onClose}
            className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2  font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Continue to App
          </button>
        </div>
      </div>
    </div>
  );
};

export const FailedPayment = ({
  title = 'Payment Not Complete',
  description = "Something went wrong. Don't worry, your funds are safe. We will look into this with our utmost priority. Should you have any questions, please contact our CEO at pavel@getfractal.xyz.",
  show,
  onClose,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-slate-800">
      <div className="flex flex-col items-center">
        <FaceFrownIcon color="#e25757" className="inline h-16 w-16" />

        <div className="z-50 my-6 max-w-lg space-y-4 px-6 text-center">
          <h3 className="text-2xl font-semibold leading-6 text-slate-300">
            {title}
          </h3>
          <div className="text-slate-400">{description}</div>
        </div>

        <div className="flex justify-between space-x-2 px-2">
          <button
            onClick={onClose}
            className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2  font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Continue to App
          </button>
        </div>
      </div>
    </div>
  );
};
