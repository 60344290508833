// Overlay.jsx
import React from 'react';
import EthLogo from 'shared/images/eth.png';

export const ConfirmInWalletOverlay = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="absolute left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-slate-800 pl-3 pr-3">
      <div className="flex flex-col items-center">
        <div className="mx-auto  flex h-24 w-24 items-center justify-center  ">
          <span className="animate-bounce  text-6xl">
            <img src={EthLogo} className={'h-10 w-10'} />
          </span>
        </div>
        <h3 className="mb-5 text-base font-semibold leading-6 text-slate-300">
          Please confirm transaction
        </h3>
        <p className="text-slate-400 text-center">
          Please stay on the page while transaction is loading. Sometimes
          transactions can take a while to appear in your wallet.
        </p>
      </div>
    </div>
  );
};
