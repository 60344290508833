import React from 'react';
import { useOrgPermissions } from 'shared/hooks';
import { useCurrentUser } from 'entities/user/model';

import { ROLES } from 'shared/hooks';

export const WithOrgPermissions = ({ children }) => {
  const { hasPermissions } = useOrgPermissions();

  if (hasPermissions()) {
    return <>{children}</>;
  }

  return null;
};

export const WithOrgRole = ({ admin, member, accountant, fallback }) => {
  const { data: userData } = useCurrentUser();
  const role = userData?.selected_organization?.userRole;

  if (accountant !== undefined && role === ROLES.ACCOUNTANT) {
    return <>{accountant}</>;
  }

  if (admin !== undefined && role === ROLES.ADMIN) {
    return <>{admin}</>;
  }

  if (member !== undefined && role === ROLES.MEMBER) {
    return <>{member}</>;
  }

  if (fallback !== undefined) {
    return <>{fallback}</>;
  }

  return null;
};
