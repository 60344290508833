import { Link } from 'react-router-dom';

export default function SecondaryHeader({ navItems }) {
  return (
    <header className="border-b border-white/5">
      <nav className="flex overflow-x-auto py-4">
        <ul className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-slate-300">
          {navItems.map((item) => (
            <li key={item.name}>
              <Link
                to={item.href}
                className={item.current ? 'text-indigo-400' : ''}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
