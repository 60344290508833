import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useInvoiceMutations, useInvoices } from 'entities/invoice/model';
import { useCurrentUserDetails } from 'entities/user/model';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Input } from 'shared/ui/form';
import useToggle from 'shared/hooks/useToggle';
import {
  usePaymentDetails,
  usePaymentDetailsMutations,
} from 'entities/organization/';
import { Alert } from 'shared/ui/Alert/Alert';
import { InvoiceLineItems } from './InvoiceGenerateForm.LineItems';
import { useWeb3Options } from 'widgets/Users/UserBusinessTypeSetting';
import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';
import { v4 as uuidv4 } from 'uuid';
import { useEmailActions } from 'entities/email/model';
import {
  useOrganizationClients,
  useInvalidateOrganizationClients,
} from 'features/organization/clients/useClients';
import { toast } from 'react-toastify';
import {
  FaceFrownIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { CountrySelect } from 'shared/ui/form/CountrySelect';
import { useInvoiceActions } from 'entities/invoice/model';
import { triggerFileDownload } from 'shared/lib';
import { PaymentDetailsCollectionStepper } from 'features/payments/paymentDetails/PaymentDetailsCollectionStepper';

import { useInvalidateRecurringInvoices } from 'features/invoice/recurring/useRecurringInvoices';
import {
  getTokenHumanReadableNameByChain,
  upperCaseNetworkType,
} from '../../features/bill/lib';
import { ROLES } from '../../shared/hooks';
import { ScrollToError } from '../../shared/ui/form/ScrollToError';
import { Form as AForm, Radio, Typography } from 'antd';
const { Text } = Typography;
function generateInvoiceId() {
  const date = new Date();
  const datePart = `${String(date.getDate()).padStart(2, '0')}${String(
    date.getMonth() + 1
  ).padStart(2, '0')}${String(date.getFullYear()).slice(-2)}`;
  const randomPart = uuidv4().substr(0, 5);
  return `INV-${datePart}-${randomPart}`.toUpperCase();
}

const FIAT_PAYMENT_FORM_VALUES = {
  paymentMethod: 'fiat',
};

export const BillFormSchema = Yup.object().shape({
  clientName: Yup.string().required('Client name is required'),
  clientEmail: Yup.string()
    .email('Invalid client email address format')
    .required('Client email is required'),

  note: Yup.string().max(550, 'Please enter less then 550 characters'),
});

const DEFAULT_FORM_VALUES = {
  // user_data
  userName: '',
  userEmail: '',
  userCountry: '',
  userCity: '',
  userStreetAddress: '',

  // client_data
  clientName: '',
  clientEmail: '',
  clientCountry: '',
  clientCity: '',
  clientId: 'no-value',

  // invoice_data
  invoiceID: generateInvoiceId(),
  invoiceDate: new Date().toISOString().slice(0, 10),
  invoiceDueDate: new Date().toISOString().slice(0, 10),
  paymentMethod: 'crypto',
  selectedPaymentDetails: {},

  recurrenceRuleEnabled: false,
  recurrenceRuleInterval: 'month',
  recurrenceRuleDuration: 2,
  lineItems: [],
  note: '',
  includeNote: false,
};

const preparePaymwntDetailsPayload = (
  userData,
  { cryptoWallet, bankAccount }
) => {
  const { newWalletReference, address, ensAddress } = cryptoWallet || {};
  const {
    newAccountReference,

    accountBeneficiary,
    bankAccountBeneficiaryType,
    accountNumber,
    accountIBAN,
    accountACHRoutingNumber,
    bankName,
    bankAddress,
    bankBIC,
  } = bankAccount || {};

  return {
    name: newWalletReference || newAccountReference,
    type: cryptoWallet ? 'crypto_wallet' : 'bank',
    businessRelation: 'client',
    details: cryptoWallet
      ? {
          cryptoWallet: {
            address,
            ensAddress,
          },
        }
      : {
          bankAccount: {
            accountBeneficiary,
            bankAccountBeneficiaryType,
            accountNumber,
            accountIBAN,
            accountACHRoutingNumber,
            bankName,
            bankAddress,
            bankBIC,
          },
        },
    m_ownerUserId: userData.user_id,
    m_ownerOrganisationId: userData.selected_organization?._id,
  };
};

const CreateInvoiceForm = ({ invoice: invoiceToUpdate }) => {
  const isEditMode = !!invoiceToUpdate;

  const { updateAction: updateInvoice } = useInvoiceActions();
  const { web3OptionsEnabled } = useWeb3Options();
  const { invalidateRecurringInvoices } = useInvalidateRecurringInvoices();
  const { data, getCurrentOrganizationId, getCurrentOrgRole } =
    useCurrentUserDetails();
  const { clients } = useOrganizationClients();
  const { invalidateOrganizationClients } = useInvalidateOrganizationClients();

  const { data: paymentDetails } = usePaymentDetails();
  const { createPaymentDetails } = usePaymentDetailsMutations();

  const { data: invoices } = useInvoices();
  const { create } = useInvoiceMutations();
  const [pdSelectionModalOpened, setPdSelectionModalOpened] = useState(false);

  const initialValues = useMemo(() => {
    if (invoiceToUpdate) {
      return mapInvoiceToFormValues(invoiceToUpdate, clients);
    }

    const preFillData = data.selected_organization
      ? mapOrganationToFormDTO(data.selected_organization)
      : invoices && invoices[invoices.length - 1]
      ? mapInvoiceToFormDTO(invoices[invoices.length - 1])
      : {};

    return {
      ...DEFAULT_FORM_VALUES,
      invoiceID: generateInvoiceId(),
      ...preFillData,
      ...(web3OptionsEnabled ? {} : FIAT_PAYMENT_FORM_VALUES),
    };
  }, [
    clients,
    invoiceToUpdate,
    data.selected_organization,
    invoices,
    web3OptionsEnabled,
  ]);

  // Design state
  const [includeUserDetails, toggleIncludeUserDetails] = useToggle(
    !!invoiceToUpdate?.user_first_line_address || false
  );
  const [includeClientDetails, toggleIncludeClientDetails] = useToggle(
    !!invoiceToUpdate?.client_first_line_address || false
  );
  const [invoiceSuccessData, setInvoiceSuccessData] = useState(null);
  const [invoiceFailFlag, setInvoiceFailFlag] = useState(false);

  const mapFormDTO2Body = useCallback(
    (invoice) => {
      const { cryptoWallet, bankAccount } = invoice.selectedPaymentDetails;
      const res = {
        organizationId: getCurrentOrganizationId(),
        user_data: {
          name: invoice.userName,
          email: invoice.userEmail,
          street_address: includeUserDetails ? invoice.userStreetAddress : '',
          city: includeUserDetails ? invoice.userCity : '',
          country: includeUserDetails ? invoice.userCountry : '',
          postcode: includeUserDetails ? invoice.userPostcode : '',
          vat_number: includeUserDetails ? invoice.userVatNumber : '',
        },
        client_data: {
          name: invoice.clientName,
          email: invoice.clientEmail,
          street_address: invoice.clientStreetAddress || '',
          city: invoice.clientCity || '',
          country: invoice.clientCountry || '',
          postcode: invoice.clientPostcode || '',
          vat_number: invoice.clientVatNumber || '',
        },
        invoice_data: {
          id: invoice.invoiceID,
          date: invoice.invoiceDate,
          due_date: invoice.invoiceDueDate,
          token: cryptoWallet?.currency || bankAccount?.currency,
          network: cryptoWallet?.network || 'Fiat',
          wallet_address: cryptoWallet?.address,
          optionalWallets: cryptoWallet?.optionalNetworksCurrencies.map(
            ({ network, currency }) => ({
              address: cryptoWallet?.address,
              network: network.toLowerCase(),
              token: currency,
            })
          ),
          bank_account_name_beneficiary: bankAccount?.accountBeneficiary,
          bank_name: bankAccount?.bankName,
          bank_address: bankAccount?.bankAddress,
          bic: bankAccount?.bankBIC,
          iban: bankAccount?.accountIBAN,
          ach_routing_number: bankAccount?.accountACHRoutingNumber,
          account_number: bankAccount?.accountNumber,

          ens_check: !!cryptoWallet?.ensAddress,
          ens_name: cryptoWallet?.ensAddress,

          lineItems: invoice.lineItems.map((item) => ({
            description: item.description,
            quantity: String(item.quantity),
            amount: String(item.amount),
            taxRate: String(item.taxRate),
            totalAmount: String(item.totalAmount),
          })),
        },

        note: invoice.note,
      };

      if (invoice.recurrenceRuleEnabled) {
        res.recurrenceRule = {
          interval: invoice.recurrenceRuleInterval,
          duration: Number(invoice.recurrenceRuleDuration),
        };
      }

      return res;
    },
    [getCurrentOrganizationId, includeClientDetails, includeUserDetails]
  );

  const _resetPaymentDetails = (setFieldValue) => {
    setFieldValue('selectedPaymentDetails', {});
  };

  const _getPdsTypesForStepper = (paymentMethod) => {
    switch (paymentMethod) {
      case 'crypto':
        return ['crypto-wallet'];
      case 'fiat':
        return ['bank-account'];
      case 'multiple':
        return ['crypto-wallet', 'bank-account'];
      default:
        return [];
    }
  };

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setFieldValue }) => {
      setSubmitting(true);

      const formData = mapFormDTO2Body(values);

      try {
        if (
          values?.selectedPaymentDetails?.cryptoWallet?.newWalletReference ||
          values?.selectedPaymentDetails?.bankAccount?.newAccountReference
        ) {
          const promises = [];

          if (
            values?.selectedPaymentDetails?.cryptoWallet?.newWalletReference
          ) {
            promises.push(
              createPaymentDetails.mutateAsync(
                preparePaymwntDetailsPayload(data, {
                  cryptoWallet: values?.selectedPaymentDetails?.cryptoWallet,
                })
              )
            );
          }

          if (
            values?.selectedPaymentDetails?.bankAccount?.newAccountReference
          ) {
            promises.push(
              createPaymentDetails.mutateAsync(
                preparePaymwntDetailsPayload(data, {
                  bankAccount: values?.selectedPaymentDetails?.bankAccount,
                })
              )
            );
          }

          await Promise.all(promises);
        }

        let res;

        if (isEditMode) {
          res = await updateInvoice(invoiceToUpdate._id, formData);
        } else {
          res = await create.mutateAsync(formData);
        }

        if (
          values?.selectedPaymentDetails?.cryptoWallet?.newWalletReference ||
          values?.selectedPaymentDetails?.bankAccount?.newAccountReference
        ) {
          await invalidateOrganizationClients();
        }

        if (formData.recurrenceRule) {
          await invalidateRecurringInvoices();
        }

        if (res.status === 200) {
          setInvoiceSuccessData(
            res.data?.result
              ? {
                  ...res?.data?.result,
                  clientEmail: values.clientEmail,
                  issuerName: values.userName,
                  isEditMode,
                }
              : {
                  id: invoiceToUpdate._id,
                  clientEmail: values.clientEmail,
                  issuerName: values.userName,
                  isEditMode,
                }
          );
          setFieldValue('invoiceID', generateInvoiceId());
        }
      } catch (error) {
        console.error(error);
        setInvoiceFailFlag(true);
      } finally {
        setSubmitting(false);
      }
    },
    [mapFormDTO2Body, create]
  );

  const resolveInvoiceCurrency = (values) => {
    let cur =
      values.selectedPaymentDetails?.cryptoWallet?.currency ||
      values.selectedPaymentDetails?.bankAccount?.currency;

    if (cur) {
      return cur;
    }

    if (
      values.paymentMethod === 'crypto' ||
      values.paymentMethod === 'multiple'
    ) {
      return 'USDC';
    }

    return 'USD';
  };

  return (
    <>
      <div className="mb-10 md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-3">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-slate-900 dark:text-white ">
              {isEditMode ? 'Edit Invoice' : 'Create Invoice'}
            </h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-slate-400">
              {isEditMode
                ? 'Please change the details of the invoice below.'
                : `Fill in this form to create an invoice. All invoices follow US &
              European accounting guidelines.`}
            </p>
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={BillFormSchema}
        validate={(values) => {
          const errors = {};
          if (!values.lineItems?.length) {
            errors.lineItems = 'At least one service item is required';
          }

          if (Object.keys(values.selectedPaymentDetails).length === 0) {
            errors.selectedPaymentDetails = 'Payment details are required';
          }

          return errors;
        }}
        onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, validateForm }) => (
          <Form className="invoice-form space-y-6">
            {/* *************** COMPANY DETAILS ********************** */}
            <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    Your Details
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                    Please input details about yourself or your company. This
                    information will be displayed in the invoice.
                  </p>
                </div>
                <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                  <div className="grid grid-cols-6 gap-6">
                    <Field
                      name="userName"
                      // placeholder="Enter Name"
                      component={TextField}
                      label="Name"
                      hint="This can be your name or name of your company."
                    />
                    <Field
                      name="userEmail"
                      // placeholder="Enter Email"
                      component={TextField}
                      label="Email"
                      hint="This will be displayed on the invoice."
                    />
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Switch.Group as="div" className="items-left flex ">
                        <Switch.Label
                          as="span"
                          className="mr-5 text-sm font-medium leading-6 text-secondary-200"
                          passive>
                          Include Your Address
                        </Switch.Label>
                        <Switch
                          checked={includeUserDetails}
                          onChange={toggleIncludeUserDetails}
                          className={classNames(
                            includeUserDetails
                              ? 'bg-indigo-600'
                              : 'bg-gray-500',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                          )}>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              includeUserDetails
                                ? 'translate-x-5'
                                : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </Switch.Group>
                    </div>
                  </div>

                  {includeUserDetails && (
                    <>
                      <div className="grid grid-cols-6 gap-6">
                        <Field
                          name="userStreetAddress"
                          // placeholder="Enter House & Street Name"
                          component={TextField}
                          label="House & Street Name"
                          hint="First line of address."
                        />
                        <Field
                          name="userCity"
                          // placeholder="Enter City"
                          component={TextField}
                          label="City"
                          hint="City where business is registered."
                        />
                      </div>

                      <div className="grid grid-cols-6 gap-6">
                        <Field
                          name="userCountry"
                          // placeholder="Enter Country"
                          component={TextField}
                          label="Country"
                          hint="Country of business."
                        />
                        <Field
                          name="userPostcode"
                          component={TextField}
                          label="ZIP Code"
                          hint="Business ZIP / post code."
                        />
                      </div>

                      <div className="grid grid-cols-6 gap-6">
                        <Field
                          name="userVatNumber"
                          // placeholder="Enter VAT Number"
                          component={TextField}
                          label="VAT / EIN Number"
                          hint="Please input the VAT / EIN number. If you don't have one, leave"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* *************** CLIENT DETAILS ********************** */}
            <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    Client Details
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                    Please input details of the client that you want to invoice.
                  </p>
                </div>
                <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
                  <SelectClient
                    includeClientDetails={includeClientDetails}
                    toggleIncludeClientDetails={toggleIncludeClientDetails}
                    clients={clients}
                    values={values}
                    setFieldValue={setFieldValue}
                    validateForm={validateForm}
                    isEditMode={isEditMode}
                  />
                </div>
              </div>
            </div>

            {/* *************** Invoice Details ********************** */}
            <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    Invoice Details
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                    Use this space to input the details of the invoice.
                  </p>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="grid grid-cols-6 gap-6">
                    <Field name="invoiceID">
                      {({ field }) => (
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="invoiceid"
                            className="block text-sm font-medium text-gray-700 dark:text-[#CBD5E1]">
                            Invoice ID
                          </label>
                          <input
                            type="text"
                            id="invoiceid"
                            className="mt-1 block w-full rounded-md border-2 border-[#64748b] bg-[#18222d] text-[#FFFFFF] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            {...field}
                          />
                          <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
                            Autogenerated Invoice ID. You can change it if you
                            require a custom ID.
                          </p>
                        </div>
                      )}
                    </Field>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="paymentTerms"
                        className="block text-sm font-medium text-gray-700  dark:text-[#CBD5E1]">
                        Payment Terms
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Field
                          as="select"
                          id="paymentTerms"
                          name="paymentTerms"
                          className="block w-full rounded-md border-2 border-[#64748b] bg-[#18222d] py-2 pl-3 pr-10 text-base text-[#FFFFFF] focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          onChange={(event) => {
                            const invoiceDate = new Date(values.invoiceDate);
                            invoiceDate.setDate(
                              invoiceDate.getDate() + Number(event.target.value)
                            );

                            const dueDate = invoiceDate
                              .toISOString()
                              .split('T')[0];

                            setFieldValue('invoiceDueDate', dueDate);
                            setFieldValue('paymentTerms', event.target.value);
                          }}>
                          <option value="">Select Payment Terms</option>
                          <option value="0">Due on Receipt</option>
                          <option value="7">7 Days</option>
                          <option value="10">10 Days</option>
                          <option value="14">14 Days</option>
                          <option value="30">30 Days</option>
                        </Field>
                      </div>
                      <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
                        Select payment terms.
                      </p>
                    </div>

                    {!isEditMode && (
                      <div className="col-span-6">
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-between">
                          <Switch
                            checked={values.recurrenceRuleEnabled}
                            onChange={(checked) =>
                              setFieldValue('recurrenceRuleEnabled', checked)
                            }
                            className={classNames(
                              values.recurrenceRuleEnabled
                                ? 'bg-primary-600'
                                : 'bg-gray-500',
                              'relative mr-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2'
                            )}>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                values.recurrenceRuleEnabled
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                            />
                          </Switch>
                          <span className="flex flex-grow flex-col">
                            <Switch.Label
                              as="span"
                              className="text-sm font-medium leading-6 text-[#CBD5E1]"
                              passive>
                              Repeat Invoice
                            </Switch.Label>
                            <Switch.Description
                              as="span"
                              className="text-sm text-[#94A3B8]">
                              Enable to repeat this invoice every month or week
                            </Switch.Description>
                          </span>
                        </Switch.Group>
                      </div>
                    )}

                    {values.recurrenceRuleEnabled && (
                      <>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="recurrenceRuleInterval"
                            className="block text-sm font-medium text-[#CBD5E1]">
                            Frequency
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Field
                              as="select"
                              name="recurrenceRuleInterval"
                              id="recurrenceRuleInterval"
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                              <option value="month">Every Month</option>
                              <option value="week">Every Week</option>
                            </Field>
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="recurrenceRuleDuration"
                            className="block text-sm font-medium text-[#CBD5E1]">
                            Repeat For
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <Field
                              as="select"
                              name="recurrenceRuleDuration"
                              id="recurrenceRuleDuration"
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                              <option value="2">
                                2{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="3">
                                3{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="4">
                                4{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="5">
                                5{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="6">
                                6{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="7">
                                7{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="8">
                                8{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="9">
                                9{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="10">
                                10{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="11">
                                11{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                              <option value="12">
                                12{' '}
                                {getRecurrenceUnit(
                                  values.recurrenceRuleInterval
                                )}
                              </option>
                            </Field>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="invoiceDate"
                        className="block text-sm font-medium text-gray-700  dark:text-[#CBD5E1]">
                        Invoice Date
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Field
                          as={Input}
                          id="invoiceDate"
                          name="invoiceDate"
                          type="date"
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
                        Date when you issued the invoice.
                      </p>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="invoiceDueDate"
                        className="block text-sm font-medium text-gray-700  dark:text-[#CBD5E1]">
                        Payment Date
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <Field
                          as={Input}
                          id="invoiceDueDate"
                          name="invoiceDueDate"
                          type="date"
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
                        Date when the invoice is due.
                      </p>
                    </div>

                    <div className="col-span-6 pt-2" />

                    {/* *************** Payent Types ********************** */}
                    {web3OptionsEnabled && (
                      <fieldset className="col-span-6">
                        <AForm.Item
                          label="How would you like to be paid?"
                          help={
                            <Text
                              className={'!text-slate-400 mt-2 block'}
                              italic={true}>
                              {values.paymentMethod === 'crypto' &&
                                'For crypto payments (e.g. USDC), your wallet details\n' +
                                  '                          will be displayed on the invoice.'}
                              {values.paymentMethod === 'fiat' &&
                                'For fiat payments (e.g., USD, GBP), your bank\n' +
                                  '                                details will appear on the invoice.'}

                              {values.paymentMethod === 'multiple' &&
                                'Both your crypto and bank details will be\n' +
                                  '                                displayed on the invoice.'}
                            </Text>
                          }
                          layout={'vertical'}>
                          <Radio.Group
                            value={values.paymentMethod}
                            onChange={(evt) => {
                              setFieldValue('paymentMethod', evt.target.value);
                              _resetPaymentDetails(setFieldValue);
                            }}>
                            <Radio.Button value="crypto">
                              Crypto Payment
                            </Radio.Button>
                            <Radio.Button value="fiat">
                              Fiat Payment
                            </Radio.Button>
                            <Radio.Button value="multiple">
                              Multiple Methods
                            </Radio.Button>
                          </Radio.Group>
                        </AForm.Item>
                      </fieldset>
                    )}

                    {/* *************** Selected Payment Methods Summary ********************** */}

                    {values.selectedPaymentDetails.cryptoWallet ||
                    values.selectedPaymentDetails.bankAccount ? (
                      <div className="col-span-6">
                        <Alert color="slate">
                          <span className="text-slate-400">Currency: </span>
                          <span>
                            {getTokenHumanReadableNameByChain(
                              resolveInvoiceCurrency(values),
                              values?.selectedPaymentDetails?.cryptoWallet
                                ?.network
                            )}
                          </span>
                          <br />{' '}
                          {values.selectedPaymentDetails.cryptoWallet && (
                            <>
                              <span className="text-slate-400">Network: </span>
                              <span>
                                {
                                  values.selectedPaymentDetails.cryptoWallet
                                    .network
                                }
                              </span>
                              <br />{' '}
                              <span className="text-slate-400">Address: </span>
                              <span>
                                {
                                  values.selectedPaymentDetails.cryptoWallet
                                    .address
                                }
                              </span>
                              {!!values.selectedPaymentDetails.cryptoWallet
                                .ensAddress && (
                                <>
                                  <br />{' '}
                                  <span className="text-slate-400">
                                    ENS Address:
                                  </span>{' '}
                                  <span className="dark:text-slate-300">
                                    {
                                      values.selectedPaymentDetails.cryptoWallet
                                        .ensAddress
                                    }
                                  </span>
                                </>
                              )}
                              {values.selectedPaymentDetails.bankAccount && (
                                <>
                                  <br />{' '}
                                </>
                              )}
                            </>
                          )}
                          {values.selectedPaymentDetails.bankAccount && (
                            <>
                              {!!values.selectedPaymentDetails.bankAccount
                                .accountBeneficiary && (
                                <>
                                  <span className="text-slate-400">
                                    Beneficiary:{' '}
                                  </span>
                                  <span className="dark:text-slate-300">
                                    {
                                      values.selectedPaymentDetails.bankAccount
                                        .accountBeneficiary
                                    }
                                  </span>
                                </>
                              )}
                              {!!values.selectedPaymentDetails.bankAccount
                                .accountNumber && (
                                <>
                                  <br />{' '}
                                  <span className="text-slate-400">
                                    Account Number:{' '}
                                  </span>
                                  <span className="dark:text-slate-300">
                                    {
                                      values.selectedPaymentDetails.bankAccount
                                        .accountNumber
                                    }
                                  </span>
                                </>
                              )}
                              {!!values.selectedPaymentDetails.bankAccount
                                .accountIBAN && (
                                <>
                                  <br />{' '}
                                  <span className="text-slate-400">IBAN: </span>
                                  <span className="dark:text-slate-300">
                                    {
                                      values.selectedPaymentDetails.bankAccount
                                        .accountIBAN
                                    }
                                  </span>
                                </>
                              )}
                              {!!values.selectedPaymentDetails.bankAccount
                                .accountACHRoutingNumber && (
                                <>
                                  <br />{' '}
                                  <span className="text-slate-400">
                                    Routing Number:{' '}
                                  </span>
                                  <span className="dark:text-slate-300">
                                    {
                                      values.selectedPaymentDetails.bankAccount
                                        .accountACHRoutingNumber
                                    }
                                  </span>
                                </>
                              )}
                              {!!values.selectedPaymentDetails.bankAccount
                                .bankName && (
                                <>
                                  <br />{' '}
                                  <span className="text-slate-400">
                                    Bank Name:{' '}
                                  </span>
                                  <span className="dark:text-slate-300">
                                    {
                                      values.selectedPaymentDetails.bankAccount
                                        .bankName
                                    }
                                  </span>
                                </>
                              )}
                            </>
                          )}
                          <Button
                            onClick={() => setPdSelectionModalOpened(true)}
                            size="xs"
                            color="gray"
                            className="mt-3">
                            <PencilIcon width={12} className="mr-2" />
                            Edit Payment Details
                          </Button>
                        </Alert>
                      </div>
                    ) : (
                      <div className="col-span-6">
                        <Button
                          onClick={() => setPdSelectionModalOpened(true)}
                          size="sm"
                          color="slate"
                          className="mt-3">
                          <PlusIcon
                            width={16}
                            className="text-indigo-400 mr-2"
                          />
                          Add Payment Details
                        </Button>
                      </div>
                    )}

                    <PaymentDetailsCollectionStepper
                      allowedManualInput={getCurrentOrgRole() === ROLES.ADMIN}
                      show={pdSelectionModalOpened}
                      walletList={(paymentDetails?.result || [])
                        .filter((p) => p.type === 'crypto_wallet')
                        .map((pd) => ({
                          ...pd.details.cryptoWallet,
                          name: pd.name,
                        }))}
                      bankAcountList={(paymentDetails?.result || [])
                        .filter((p) => p.type === 'bank')
                        .map((pd) => ({
                          ...pd.details.bankAccount,
                          name: pd.name,
                        }))}
                      selectedPymentDetails={values.selectedPaymentDetails}
                      onPaymentDetailsSelect={(vl) => {
                        setFieldValue('selectedPaymentDetails', vl);
                      }}
                      onClose={() => setPdSelectionModalOpened(false)}
                      pdsTypes={_getPdsTypesForStepper(values.paymentMethod)}
                    />

                    <div className="col-span-6">
                      <ErrorMessage
                        name="selectedPaymentDetails"
                        component="div"
                        className="mt-2 text-xs text-rose-500"
                      />
                    </div>

                    <div className="col-span-6">
                      <Switch.Group
                        as="div"
                        className="flex items-center justify-between">
                        <Switch
                          checked={values.includeNote}
                          onChange={(checked) => {
                            setFieldValue('includeNote', checked);
                          }}
                          className={classNames(
                            values.includeNote
                              ? 'bg-primary-600'
                              : 'bg-gray-500',
                            'relative mr-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2'
                          )}>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              values.includeNote
                                ? 'translate-x-5'
                                : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                        <span className="flex flex-grow flex-col">
                          <Switch.Label
                            as="span"
                            className="text-sm font-medium leading-6 text-[#CBD5E1]"
                            passive>
                            Invoice Memo
                          </Switch.Label>
                          <Switch.Description
                            as="span"
                            className="text-sm text-[#94A3B8]">
                            Add custom information that you would like to
                            display in an invoice
                          </Switch.Description>
                        </span>
                      </Switch.Group>

                      {values.includeNote && (
                        <div className="mt-5 md:col-span-2">
                          <div className="col-span-8 sm:col-span-6">
                            <Field
                              name="note"
                              id="note"
                              rows={2}
                              as="textarea"
                              value={values.note}
                              placeholder="Text here will be displayed in the invoice"
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) =>
                                setFieldValue('note', e.target.value)
                              }
                            />
                          </div>
                          <ErrorMessage
                            name="note"
                            component="div"
                            className="mt-2 text-xs text-rose-500"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                    Services Provided
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                    Input name, quantity, price and tax rate for each item.
                  </p>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-200"></p>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="col-span-8 sm:col-span-6">
                    <InvoiceLineItems
                      currency={resolveInvoiceCurrency(values)}
                      items={values.lineItems || []}
                      onItemsChange={(udaptedItems) => {
                        setFieldValue('lineItems', udaptedItems);
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name="lineItems"
                    component="div"
                    className="mt-2 text-xs text-rose-500"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                color="indigo"
                type="submit"
                disabled={isSubmitting}
                isProcessing={isSubmitting}>
                {isEditMode ? 'Update Invoice' : 'Create Invoice'}
              </Button>
            </div>

            <FailModal
              show={invoiceFailFlag}
              onClose={() => {
                setInvoiceFailFlag(false);
              }}
            />

            <SuccessModal
              invoiceSuccessData={invoiceSuccessData}
              invoiceData={values}
              onClose={() => {
                setInvoiceSuccessData(null);
              }}
            />

            <ScrollToError />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateInvoiceForm;

const SelectClient = ({
  values,
  clients,
  setFieldValue,
  validateForm,
  includeClientDetails,
  toggleIncludeClientDetails,
  isEditMode,
}) => {
  const [updateClientMode, setUpdateClientMode] = useState(false);
  useEffect(() => {
    validateForm(values);
  }, [values.clientName, values.clientEmail]);

  return (
    <>
      {!updateClientMode && (
        <div className="col-span-8 sm:col-span-6">
          <Field
            name="clientId"
            component="select"
            defaultValue="no-value"
            onChange={(event) => {
              setFieldValue('clientId', event.target.value);

              if (event.target.value === 'new') {
                setFieldValue('clientName', '');
                setFieldValue('clientEmail', '');
                setFieldValue('clientStreetAddress', '');
                setFieldValue('clientCity', '');
                setFieldValue('clientCountry', '');
                setFieldValue('clientPostcode', '');
                setFieldValue('clientVatNumber', '');
              } else {
                const client = clients.find((p) => p.id === event.target.value);
                setFieldValue('clientName', client.name);
                setFieldValue(
                  'clientEmail',
                  client.billingEmail || client.email
                );
                setFieldValue(
                  'clientStreetAddress',
                  client.address?.streetAddress
                );
                setFieldValue('clientCity', client.address?.city);
                setFieldValue('clientCountry', client.address?.country);
                setFieldValue('clientPostcode', client.address?.postalCode);
                setFieldValue('clientVatNumber', client.address?.vatNumber);
              }
            }}
            className="text-[#FFFFFF mt-1 block w-full rounded-md  border-2 border-[#64748b] bg-[#18222d] px-3 py-2 text-[#FFFFFF] shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
            <option disabled defaultValue value="no-value">
              Select or Create New
            </option>

            {clients.map((client) => (
              <>
                <option key={client.id} value={client.id}>
                  {client.name} - {client.billingEmail || client.email}
                </option>
              </>
            ))}

            <optgroup label="New">
              <option value="new">+ Add New Client</option>
            </optgroup>
          </Field>
        </div>
      )}

      {values.clientId &&
        values.clientId !== 'new' &&
        values.clientId !== 'no-value' && (
          <div className="col-span-8 sm:col-span-6">
            <div
              id="alert-5"
              className="rounded-lg bg-gray-50 p-4  text-left dark:bg-slate-700"
              role="alert">
              <div className="text-sm text-gray-800 dark:text-slate-400">
                {!!values.clientName && (
                  <>
                    <span>Name: </span>
                    <span className="dark:text-slate-300">
                      {values.clientName}
                    </span>
                  </>
                )}

                {!!values.clientEmail && (
                  <>
                    <br /> <span>Email: </span>
                    <span className="dark:text-slate-300">
                      {values.clientEmail}
                    </span>
                  </>
                )}

                {!!values.clientStreetAddress && (
                  <>
                    <br /> <span>Address: </span>
                    <span className="dark:text-slate-300">
                      {values.clientStreetAddress}
                      {!!values.clientCity && <>, {values.clientCity}</>}
                      {!!values.clientCountry && <>, {values.clientCountry}</>}
                      {!!values.clientPostcode && (
                        <>, {values.clientPostcode}</>
                      )}
                    </span>
                  </>
                )}

                {!!values.clientVatNumber && (
                  <>
                    <br /> <span>VAT / EIN: </span>
                    <span className="dark:text-slate-300">
                      {values.clientVatNumber}
                    </span>
                  </>
                )}

                {isEditMode && (
                  <Button
                    onClick={() => {
                      setUpdateClientMode(true);
                      setFieldValue('clientId', 'new');
                    }}
                    size="xs"
                    color="gray"
                    className="mt-3">
                    <PencilIcon width={12} className="mr-2" />
                    Edit Client
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

      {values.clientId === 'new' && (
        <>
          <div className="grid grid-cols-6 gap-6">
            <Field
              name="clientName"
              component={TextField}
              label="Client Name"
              hint="This can be your name or name of your company."
            />
            <Field
              name="clientEmail"
              component={TextField}
              label="Client Email"
              hint="This will be displayed on the invoice."
            />
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <div className="col-span-6 sm:col-span-3">
                <Switch.Group as="div" className="items-left flex ">
                  <Switch.Label
                    as="span"
                    className="mr-5 text-sm font-medium leading-6 text-secondary-200"
                    passive>
                    Include Client Address
                  </Switch.Label>
                  <Switch
                    checked={includeClientDetails}
                    onChange={toggleIncludeClientDetails}
                    className={classNames(
                      includeClientDetails ? 'bg-indigo-600' : 'bg-gray-500',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        includeClientDetails
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
          </div>

          {includeClientDetails && (
            <>
              <div className="grid grid-cols-6 gap-6">
                <Field
                  name="clientStreetAddress"
                  // placeholder="Enter Client House & Street Name"
                  component={TextField}
                  label="Client House & Street Name"
                  hint="First line of address."
                />
                <Field
                  name="clientCity"
                  // placeholder="Enter Client City"
                  component={TextField}
                  label="Client City"
                  hint="City where business is registered."
                />
              </div>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    // htmlFor={field.name}
                    className={`block text-sm font-medium dark:text-[#CBD5E1]`}>
                    Client Country
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <CountrySelect
                      value={values.clientCountry}
                      onCountryChange={(val) => {
                        setFieldValue('clientCountry', val);
                      }}
                    />
                  </div>

                  <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">
                    Country of business.
                  </p>
                </div>

                <Field
                  name="clientPostcode"
                  component={TextField}
                  label="Client ZIP Code"
                  hint="Business ZIP / post code."
                />
              </div>

              <div className="grid grid-cols-6 gap-6">
                <Field
                  name="clientVatNumber"
                  component={TextField}
                  label="Client VAT / EIN Number"
                  hint="Please input the VAT / EIN number. If you don't have one, leave it blank."
                />
              </div>
            </>
          )}
        </>
      )}

      <div>
        <ErrorMessage
          name="clientName"
          component="div"
          className="formik-error-message mt-2 text-xs text-rose-500"
        />

        <ErrorMessage
          name="clientEmail"
          component="div"
          className="formik-error-message mt-2 text-xs text-rose-500"
        />
      </div>
    </>
  );
};

const SuccessModal = ({ invoiceSuccessData, onClose }) => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { sendAction } = useEmailActions();
  const { downloadAction } = useInvoiceActions();

  const _handleSendEmail = async () => {
    try {
      setIsSendingEmail(true);
      await sendAction({
        invoice_data: {
          _id: invoiceSuccessData.id,
          name: invoiceSuccessData.issuerName,
        },
        email: invoiceSuccessData.clientEmail,
      });

      toast.success('Email sent to client');
    } catch (error) {
      toast.error('Sending Email Failed');
      console.error(error);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const _handleInvoiceDownload = async () => {
    try {
      setIsDownloading(true);

      const file = await downloadAction(invoiceSuccessData.id);

      triggerFileDownload(`invoice_${invoiceSuccessData.id}.pdf`, file);
      toast.success('Downloading Success!');
    } catch (error) {
      toast.error('Downloading Failed');
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Modal popup dismissible show={!!invoiceSuccessData} onClose={onClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>

      <Modal.Body>
        <div className="text-center">
          <div className="mx-auto my-4 flex h-24 w-24 items-center justify-center rounded-full bg-gray-700 shadow-md ">
            <span className="p-4 text-6xl">🎊</span>
          </div>
          <div className="my-6 space-y-4 text-center">
            <h3 className="text-2xl font-semibold leading-6 text-white">
              {invoiceSuccessData?.isEditMode
                ? 'Invoice Updated'
                : 'Invoice Created'}
            </h3>

            {!invoiceSuccessData?.isEditMode && (
              <>
                <p className="text-base text-slate-300">
                  You can now send this invoice to{' '}
                  {invoiceSuccessData?.clientEmail
                    ? invoiceSuccessData?.clientEmail
                    : 'your client'}
                  .
                </p>

                <p className="text-base text-slate-300 underline">
                  <a href="#" onClick={_handleInvoiceDownload}>
                    Download Invoice File
                  </a>
                </p>
              </>
            )}

            <div className="flex justify-center p-5">
              <div className="px-2">
                {invoiceSuccessData?.isEditMode ? (
                  <Button
                    color="indigo"
                    isProcessing={isDownloading}
                    onClick={_handleInvoiceDownload}>
                    Download Invoice File
                  </Button>
                ) : (
                  <Button
                    isProcessing={isSendingEmail}
                    color="indigo"
                    onClick={_handleSendEmail}>
                    Send Invoice To Client
                  </Button>
                )}
              </div>

              <div className="px-2">
                <Button
                  color="slate"
                  as={Link}
                  to="/dashboard/invoices"
                  onClick={onClose}>
                  View All Invoices
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const FailModal = ({ show, onClose }) => {
  return (
    <Modal popup dismissible show={show} onClose={onClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>

      <Modal.Body>
        <div className="text-center">
          <FaceFrownIcon color="#e25757" className="inline h-16 w-16" />
          <div className="my-6 space-y-4 text-center">
            <h3 className="text-2xl font-semibold leading-6 text-white">
              Invoice Creation Not Complete
            </h3>

            <p className="text-base text-slate-300">
              Something went wrong. We will look into this with our utmost
              priority. Should you have any questions, please contact our CEO at
              pavel@getfractal.xyz.
            </p>

            <div className="flex justify-center p-5">
              <div className="px-2">
                <Button color="indigo" onClick={onClose}>
                  Continue to App
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

function getRecurrenceUnit(interval) {
  return interval === 'month' ? 'Months' : 'Weeks';
}

function mapOrganationToFormDTO(organization) {
  return {
    userName: organization.name,
    userEmail: organization.email,
    userStreetAddress: organization.address,
    userCity: organization.city,
    userCountry: organization.country,
    userPostcode: organization.postcode,
    userVatNumber: organization.user_vat_number,
  };
}

function mapInvoiceToFormDTO(invoice) {
  return {
    userName: invoice.name,
    userEmail: invoice.user_email,
    userStreetAddress: invoice.user_first_line_address,
    userCity: invoice.user_city,
    userCountry: invoice.user_country,
    userPostcode: invoice.user_postal_code,
    userVatNumber: invoice.user_vat_number,
  };
}

function mapInvoiceToFormValues(invoice, clients) {
  const client = clients.find((cl) => cl.email === invoice.client_email);
  const selectedPaymentDetails = {};

  if (invoice.wallet_address) {
    selectedPaymentDetails.cryptoWallet = {
      address: invoice.wallet_address,
      currency: invoice.token,
      network: invoice.network,
      optionalNetworksCurrencies: invoice.optionalWallets
        ? invoice.optionalWallets.map((w) => {
            return {
              currency: w.token,
              network: upperCaseNetworkType(w.network),
            };
          })
        : [],
    };
  }

  if (invoice.bank_iban || invoice.bank_account_number) {
    selectedPaymentDetails.bankAccount = {
      currency: invoice.network === 'Fiat' ? invoice.token : undefined,
      accountBeneficiary: invoice.bank_account_beneficiary || '',
      bankName: invoice.bank_name || '',
      bankAddress: invoice.bank_address || '',
      bankBIC: invoice.bank_bic || '',
      accountIBAN: invoice.bank_iban || '',
      accountACHRoutingNumber: invoice.bank_ach_routing_number || '',
      accountNumber: invoice.bank_account_number || '',
    };
  }

  let paymentMethod = 'crypto';

  if (
    invoice.wallet_address &&
    (invoice.bank_iban || invoice.bank_account_number)
  ) {
    paymentMethod = 'multiple';
  } else if (invoice.bank_iban || invoice.bank_account_number) {
    paymentMethod = 'fiat';
  }

  return {
    userName: invoice.name,
    userEmail: invoice.user_email,
    userStreetAddress: invoice.user_first_line_address,
    userCity: invoice.user_city,
    userCountry: invoice.user_country,
    userPostcode: invoice.user_postal_code,
    userVatNumber: invoice.user_vat_number,

    clientName: invoice.client_name,
    clientEmail: invoice.client_email,
    clientStreetAddress: invoice.client_first_line_address,
    clientPostcode: invoice.client_postal_code,
    clientVatNumber: invoice.client_vat_number,
    clientCountry: invoice.client_country,
    clientCity: invoice.client_city,
    clientId: client ? client.id : 'no-value',

    lineItems: invoice.lineItems,

    selectedPaymentDetails,
    paymentMethod,

    includeNote: !!invoice.note,
    note: invoice.note || '',

    invoiceID: invoice.invoice_number,
    invoiceDate: dayjs(invoice.invoice_date).format('YYYY-MM-DD'),
    invoiceDueDate: dayjs(invoice.payments_date).format('YYYY-MM-DD'),
  };
}
