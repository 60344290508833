import { BankTransferPaymentProvidersEvaluatorStrategy } from './BankTransferPaymentProvidersEvaluatorStrategy';
import { CryptoPaymentProvidersEvaluatorStrategy } from './CryptoPaymentProvidersEvaluatorStrategy';
import { getPaymentsConfig } from './config';
import { createPayoutDetailsFromTransfer, PayoutTypes } from './PayoutDetails';
import {
  createPayoutDetailsFromBill,
  createPayoutDetailsFromExpense,
} from 'features/payments/PayoutDetails';
import { getTransferPaymentsConfig } from './transferPaymentsConfig';

export const getPaymentProvidersEvaluatorStrategy = (
  bills,
  platformIntegrations,
  orgSettings
) => {
  if (bills.length === 0 || !bills.every((b) => b.paymentStatus === 'unpaid')) {
    return null;
  }

  const payoutDetailsList = bills.map((b) => createPayoutDetailsFromBill(b));

  if (payoutDetailsList.some((i) => i === null)) {
    return null;
  }

  if (
    payoutDetailsList.every(
      (payoutDetails) => payoutDetails.type === PayoutTypes.CryptoTransfer
    )
  ) {
    return new CryptoPaymentProvidersEvaluatorStrategy(
      getPaymentsConfig(),
      payoutDetailsList,
      platformIntegrations,
      orgSettings
    );
  }

  if (
    payoutDetailsList.every(
      (payoutDetails) => payoutDetails.type === PayoutTypes.BankTransfer
    )
  ) {
    return new BankTransferPaymentProvidersEvaluatorStrategy(
      getPaymentsConfig(),
      payoutDetailsList,
      platformIntegrations,
      orgSettings
    );
  }

  return null;
};

export const getExpensePaymentProvidersEvaluatorStrategy = (
  expenses,
  platformIntegrations,
  orgSettings
) => {
  if (
    expenses.length === 0 ||
    !expenses.every((b) => b.paymentStatus === 'unpaid')
  ) {
    return null;
  }

  const payoutDetailsList = expenses.map((b) =>
    createPayoutDetailsFromExpense(b)
  );

  if (payoutDetailsList.some((i) => i === null)) {
    return null;
  }

  if (
    payoutDetailsList.every(
      (payoutDetails) => payoutDetails.type === PayoutTypes.CryptoTransfer
    )
  ) {
    return new CryptoPaymentProvidersEvaluatorStrategy(
      getPaymentsConfig(),
      payoutDetailsList,
      platformIntegrations,
      orgSettings
    );
  }

  if (
    payoutDetailsList.every(
      (payoutDetails) => payoutDetails.type === PayoutTypes.BankTransfer
    )
  ) {
    return new BankTransferPaymentProvidersEvaluatorStrategy(
      getPaymentsConfig(),
      payoutDetailsList,
      platformIntegrations,
      orgSettings
    );
  }

  return null;
};

export const getTransfersPaymentProvidersEvaluatorStrategy = (
  transfers,
  platformIntegrations,
  orgSettings
) => {
  if (
    transfers.length === 0 ||
    transfers.some(
      (tr) => tr.payablePaymentStatus && tr.payablePaymentStatus !== 'initiated'
    )
  ) {
    return null;
  }

  const payoutDetailsList = transfers.map((b) =>
    createPayoutDetailsFromTransfer(b)
  );

  if (payoutDetailsList.some((i) => i === null)) {
    return null;
  }

  if (
    payoutDetailsList.every(
      (payoutDetails) => payoutDetails.type === PayoutTypes.CryptoTransfer
    )
  ) {
    return new CryptoPaymentProvidersEvaluatorStrategy(
      getTransferPaymentsConfig(),
      payoutDetailsList,
      platformIntegrations,
      orgSettings
    );
  }

  if (
    payoutDetailsList.every(
      (payoutDetails) => payoutDetails.type === PayoutTypes.BankTransfer
    )
  ) {
    return new BankTransferPaymentProvidersEvaluatorStrategy(
      getTransferPaymentsConfig(),
      payoutDetailsList,
      platformIntegrations,
      orgSettings
    );
  }

  return null;
};
