import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { pick } from 'lodash';
import {
  useSimulateContract,
  useWriteContract,
  useWaitForTransactionReceipt,
} from 'wagmi';
import { useContractRead } from 'wagmi';

import { erc20Abi } from 'viem';
import { toast } from 'react-toastify';
import { useCurrentUser } from 'entities/user/model';
import { useSafeWallet } from 'shared/hooks/safeWallet';
import { sendTransactionLog } from 'features/analytics/sendTransactionLog';
import { useAuth0 } from '@auth0/auth0-react';
import {
  erc20ABI_USDT,
  NETWORK_TO_CHAIN_ID_MAP,
  TOKEN_TYPES,
} from '../../features/bill/lib';

export default function PayERC20Button({
  addressTo,
  amountERC20,
  handleSuccess,

  tokenName,
  tokenAddress,

  network,
  disabled,
  payButtonStyles,
  onUserApproval,
  setTransactionSuccessful,
  setTransactionHash,
  setTransactionInitiated,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { data: userData, setData: setUserData } = useCurrentUser();
  const { isConnectedWalletSafe } = useSafeWallet();

  const [to, setTo] = useState(addressTo);
  const [debouncedTo] = useDebounce(to, 500);
  const [amount, setAmount] = useState('0.00');
  const [debouncedAmount] = useDebounce(amount, 500);
  const chainId = NETWORK_TO_CHAIN_ID_MAP[network];
  const { data: dataDecimals } = useContractRead({
    address: tokenAddress,
    abi: erc20Abi,
    functionName: 'decimals',
    chainId: chainId,
  });

  const updateLastPaymentInfoInUserContext = () => {
    setUserData({
      ...userData,
      last_payment: {
        amount: amountERC20,
        addressTo: addressTo,
      },
    });
  };

  // update to and amount when props change
  useEffect(() => {
    setTo(addressTo);
    const amount = amountERC20 * 10 ** dataDecimals;
    const formattedAmount = amount.toLocaleString('fullwide', {
      useGrouping: false,
    });

    setAmount(formattedAmount);
  }, [addressTo, amountERC20, dataDecimals]);

  const { data: config } = useSimulateContract({
    address: tokenAddress,
    abi: tokenName === TOKEN_TYPES.USDT ? erc20ABI_USDT : erc20Abi,
    functionName: 'transfer',
    args: [debouncedTo, debouncedAmount],
    chainId,
    value: 0,
  });

  const {
    data: hash,
    isSuccess,
    isPending: waitingForUserApproval,
    writeContractAsync,
  } = useWriteContract();

  const waitForTransactionReceipt = useWaitForTransactionReceipt({
    hash,
    chainId,
    confirmations: 1,
    onReplaced(replacedData) {
      sendTransactionLog(getAccessTokenSilently, {
        title: '🔸 [CRYPTO <-> CRYPTO][Replaced] 🔸',
        ...pick(replacedData || {}, 'reason'),
        hash,
      });
    },
  });

  const handleClick = async (event) => {
    event.preventDefault();
    setTransactionInitiated && setTransactionInitiated(true);
    sendTransactionLog(getAccessTokenSilently, {
      title: '➡️[CRYPTO <-> CRYPTO][Starting Contract Write] ➡️',
      addressTo,
      amountERC20,
    });
    writeContractAsync(config.request)
      .then((hash) => {
        sendTransactionLog(getAccessTokenSilently, {
          title: '🟢[CRYPTO <-> CRYPTO][Contract Write Success] 🟢',
          hash,
          addressTo,
          amountERC20,
        });

        toast.success('Transaction successful.');
        onUserApproval(false);
        setTransactionHash(hash);
        setTransactionSuccessful && setTransactionSuccessful(true);
        handleSuccess && handleSuccess(hash, isConnectedWalletSafe());
        updateLastPaymentInfoInUserContext();
      })
      .catch((error) => {
        if (error?.message?.startsWith('User rejected')) {
          toast.error('User rejected the request.', {
            autoClose: 6000,
          });
        } else {
          toast.error('Transaction failed. Please try again later.', {
            autoClose: 6000,
          });
        }

        onUserApproval(false);

        sendTransactionLog(getAccessTokenSilently, {
          title: '🔸 [CRYPTO <-> CRYPTO][Execution Error] 🔸',
          error,
        });
      });
    onUserApproval(true);
  };

  useEffect(() => {
    if (waitForTransactionReceipt.isSuccess) {
      sendTransactionLog(getAccessTokenSilently, {
        title: '🟢 [CRYPTO <-> CRYPTO][Transaction Receipt Success] 🟢',
        ...pick(
          waitForTransactionReceipt.data || {},
          'transactionHash',
          'status',
          'to'
        ),
      });
    }
  }, [waitForTransactionReceipt.isSuccess]);

  useEffect(() => {
    if (waitForTransactionReceipt.isError) {
      sendTransactionLog(getAccessTokenSilently, {
        title: '🔸 [CRYPTO <-> CRYPTO][Error] 🔸',
        error: waitForTransactionReceipt.error,
      });
    }
  }, [waitForTransactionReceipt.isError]);

  return (
    <>
      <button
        className={
          !config?.request ||
          waitForTransactionReceipt.isLoading ||
          isSuccess ||
          waitingForUserApproval ||
          disabled
            ? `${payButtonStyles} cursor-not-allowed opacity-50`
            : payButtonStyles
        }
        disabled={
          !config?.request ||
          waitForTransactionReceipt.isLoading ||
          isSuccess ||
          waitingForUserApproval ||
          disabled
        }
        onClick={handleClick}>
        {waitingForUserApproval
          ? 'Check Wallet'
          : `Pay ${amountERC20} ${tokenName}`}
      </button>
    </>
  );
}
