import { useAuth0 } from '@auth0/auth0-react';
import { useBillActions } from 'entities/bill/model';
import { CreatePaymentRequstForm } from 'features/bill/ui';
import { PaymentLinkAuthenticationForm } from 'features/link/ui';
import { useCallback, useEffect, useState } from 'react';
import useToggle from 'shared/hooks/useToggle';
import { triggerFileDownload } from 'shared/lib';
import { Modal, Button } from 'shared/ui';
import LoginButton from 'widgets/Authentication/LoginButton';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import { useCurrentUser } from 'entities/user/model';
import * as amplitude from '@amplitude/analytics-browser';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { registerUser } from '../Authentication/WithAuthentication';

const CreatePaymentRequestForm = ({ url }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } =
    useAuth0();
  const { data, setData } = useCurrentUser();
  const { downloadAction } = useBillActions();
  const { updateBillIussedBy } = useUpdateBillIussedBy();
  const [
    { billId, email, password, company_name, emailExist },
    setRequestFormData,
  ] = useState({});

  const [showAuthForm, setShowAuthForm] = useState(true);
  const [showLogInModal, setShowLogInModal] = useState(false);
  const [showRequestSuccessfulModal, toggleRequestSuccessfulModal] =
    useToggle(false);

  const [showMakeAnotherPaymentRequest, toggleMakeAnotherPaymentRequest] =
    useToggle(false);

  const handleAuthFormSubmit = ({ isGranted, password }) => {
    setShowAuthForm(!isGranted);
    setRequestFormData((prev) => ({ ...prev, password }));
  };

  const handlePressSkipAuth = () => {
    setShowLogInModal(false);
  };

  const handleCreateFormSubmit = (response, data) => {
    if (response.status === 201) {
      setRequestFormData(data);
      toggleRequestSuccessfulModal();

      if (isAuthenticated) {
        updateBillIussedBy({ billId: data.billId });
      }
    }
  };

  useEffect(() => {
    try {
      if (isAuthenticated && user) {
        amplitude.setUserId(user.email);
      }
    } catch (error) {
      console.error('Error setting user in amplitude:', error);
    }
  }, [isAuthenticated, user]);

  const handleDownload = useCallback(async () => {
    try {
      const file = await downloadAction(billId, 'download-invoice');

      triggerFileDownload(`Invoice_For_${company_name}_ID_${billId}.pdf`, file);
    } catch (error) {
      console.error(error);
    }
  }, [billId, company_name, downloadAction]);

  const onModalClose = () => {
    toggleRequestSuccessfulModal();
    toggleMakeAnotherPaymentRequest();
  };

  const handleAnotherPaymentRequest = () => {
    toggleMakeAnotherPaymentRequest();
  };

  const handleNoAnotherPaymentRequest = () => {
    window.location.href = '/';
  };

  const onAnotherRequestModalClose = () => {
    toggleMakeAnotherPaymentRequest();
    window.location.reload();
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      setShowLogInModal(true);
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (isAuthenticated && !data.sub) {
      registerUser(user, null, getAccessTokenSilently).then((userData) => {
        if (showLogInModal) {
          setShowLogInModal(false);
        }

        setData({
          ...data,
          ...userData,
        });
      });
    }
  }, [
    getAccessTokenSilently,
    showLogInModal,
    setData,
    data,
    user,
    isAuthenticated,
  ]);

  if (url.password && showAuthForm) {
    return (
      <PaymentLinkAuthenticationForm
        url={url}
        onSubmit={handleAuthFormSubmit}
      />
    );
  }

  return (
    <div>
      <CreatePaymentRequstForm
        url={url}
        password={password}
        onSubmit={handleCreateFormSubmit}
      />
      <Modal handleClose={onModalClose} show={showRequestSuccessfulModal}>
        <div className=" bg-gray-800 p-4 text-white">
          <div className="relative bg-gray-800 p-4 text-white">
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className="rounded-md bg-gray-800 text-gray-400 hover:bg-gray-500 hover:text-gray-800"
                onClick={onModalClose}>
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mx-auto my-4 flex h-24 w-24 items-center justify-center rounded-full bg-gray-700 shadow-md ">
              <span className="p-4 text-6xl">🎊</span>
            </div>

            <div className="z-50 my-6 max-w-lg space-y-4 text-center">
              <h3 className="text-2xl font-semibold leading-6 text-white">
                Payment Request Sent!
              </h3>
              <div className="text-base text-gray-300">
                {isAuthenticated ? (
                  <p>
                    Track payment status of this invoice by logging in to your
                    Fractal Payments account.
                  </p>
                ) : emailExist ? (
                  <p>
                    We added the invoice to your Fractal Payments account.
                    Please log in using{' '}
                    <span className="font-semibold"> {email}</span> to see all
                    of your invoices.
                  </p>
                ) : (
                  <p>
                    Track payments status of this invoice by creating a Fractal
                    Payments account. Please use your email
                    <span className="font-semibold"> {email}</span> to sign up.
                  </p>
                )}
              </div>

              <div className="flex justify-center">
                <div className="px-2">
                  <LoginButton
                    ctaText={
                      isAuthenticated
                        ? 'Continue To App'
                        : emailExist
                        ? 'Log In Now'
                        : 'Sign Up Now'
                    }
                    email={email}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  />
                </div>
                <div className="px-2">
                  <button
                    onClick={handleDownload}
                    className="inline-flex items-center rounded-md bg-gray-300 px-6 py-3 text-sm font-semibold text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Get Invoice
                  </button>
                </div>
              </div>

              <div className="text-base text-gray-400">
                <p className="py-1 font-semibold">
                  Fractal Payments allows you to:
                </p>
                <p className="py-1">💸 Send & Track Payment Requests</p>
                <p className="py-1">📄 Manage Crypto & Fiat Invoices</p>
                <p className="py-1">⏳ Save Time On Admin</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        handleClose={onAnotherRequestModalClose}
        show={showMakeAnotherPaymentRequest}>
        {/* Create modal to ask the user if they want to create another payment request */}
        <div className=" bg-gray-800 p-4 text-white">
          <div className="relative bg-gray-800 p-4 text-white">
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className="rounded-md bg-gray-800 text-gray-400 hover:bg-gray-500 hover:text-gray-800"
                onClick={onAnotherRequestModalClose}>
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mx-auto my-4 flex h-24 w-24 items-center justify-center rounded-full bg-gray-700 shadow-md ">
              <span className="p-4 text-6xl">🎊</span>
            </div>

            <div className="z-50 my-6 max-w-lg space-y-4 text-center">
              <h3 className="text-2xl font-semibold leading-6 text-white">
                Would you like to create another payment request?
              </h3>

              <p className="text-base text-gray-300">
                Send another payment request to {company_name}.
              </p>

              <div className="flex justify-center p-5">
                <div className="px-2">
                  <button
                    onClick={handleAnotherPaymentRequest}
                    className="inline-flex items-center rounded-md bg-indigo-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Yes
                  </button>
                </div>
                <div className="px-2">
                  <button
                    onClick={handleNoAnotherPaymentRequest}
                    className="inline-flex items-center rounded-md bg-gray-300 px-6 py-3 text-sm font-semibold text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <AuthModal
        url={url}
        showLogInModal={showLogInModal}
        onPressSkipAuth={handlePressSkipAuth}
      />
    </div>
  );
};

export default CreatePaymentRequestForm;

const AuthModal = ({ url, showLogInModal, onPressSkipAuth }) => {
  const { loginWithPopup } = useAuth0();

  return (
    <Modal handleClose={() => {}} show={showLogInModal}>
      <Dialog.Panel className="relative mx-auto w-full max-w-[90%] transform overflow-hidden rounded-lg bg-gray-800 px-6 pb-6 pt-10 text-left text-white shadow-xl transition-all sm:w-[520px] md:max-w-[520px]">
        <Dialog.Title
          as="h2"
          className="mb-8 text-center text-base font-semibold leading-6">
          Request a Payment
        </Dialog.Title>

        <Dialog.Description as="h3" className="mb-8 text-center text-base ">
          <b className="text-amber-300">{url.companyname}</b> invited you to
          create a payment request.
          <span className="hidden sm:inline">
            <br />
          </span>{' '}
          We recommend logging in, as it ensures an improved user experience and
          effortlessly establishes the legitimacy of your payment request to
          your client.
        </Dialog.Description>
        <div className="mb-4 flex justify-center gap-x-6">
          <Button
            className="h-[40px] w-[220px] justify-center text-center focus:ring-0 focus:ring-offset-0 focus-visible:outline-transparent"
            onClick={() => loginWithPopup()}>
            Log in
          </Button>
        </div>
        <span className="mb-2 flex justify-center text-xs text-gray-500">
          or
        </span>

        <div className="flex justify-center">
          <Button
            className="bg-transparent font-normal text-gray-400 hover:bg-transparent hover:text-gray-300"
            onClick={onPressSkipAuth}>
            Continue without logging in
          </Button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

const useUpdateBillIussedBy = () => {
  const { getAccessTokenSilently } = useAuth0();
  const res = useMutation({
    mutationKey: ['update-bill-iussed-by'],
    mutationFn: async ({ billId }) => {
      try {
        const token = await getAccessTokenSilently();
        await axios.put(
          process.env.REACT_APP_SERVER_URL +
            '/api/public/bills/' +
            billId +
            '/update-issued-by',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        //
      }
    },
  });
  return {
    updateBillIussedBy: res.mutateAsync,
  };
};
