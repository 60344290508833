import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid';
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const Table = ({
  data,
  columns,
  rowSelection,
  onRowSelectionChange,
  onRowClick,
  className,
  tableStyles,
}) => {
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),

    enableRowSelection: true,
    onRowSelectionChange: onRowSelectionChange,
    onSortingChange: setSorting,

    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel({}),

    state: {
      rowSelection,
      sorting,
    },

    // debugTable: true,
  });

  return (
    <div
      className={twMerge(
        'relative w-full overflow-x-auto shadow-md sm:rounded-lg ',
        className
      )}>
      <table className={'w-full text-xs text-gray-300'} style={tableStyles}>
        <thead className="h-[70px] border-b border-slate-700 bg-slate-800 text-center">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  scope="col"
                  className="h-[70px]"
                  style={{
                    width: isNaN(header.getSize())
                      ? undefined
                      : header.getSize(),
                  }}>
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none flex items-center'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort() &&
                      ({
                        asc: (
                          <ChevronUpIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        ),
                        desc: (
                          <ChevronDownIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        ),
                      }[header.column.getIsSorted()] ?? (
                        <ChevronUpDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      ))}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="cursor-pointer border-b border-slate-700 bg-slate-800 text-center hover:bg-slate-700"
              onClick={() => onRowClick(row.original)}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td key={cell.id} className="">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination table={table} total={data.length} />
    </div>
  );
};

function Pagination({ table, total }) {
  const { pageIndex, pageSize } = table.getState().pagination;

  const from = pageIndex * table.getState().pagination.pageSize + 1;
  const to =
    (pageIndex + 1) * table.getState().pagination.pageSize > total
      ? total
      : (pageIndex + 1) * table.getState().pagination.pageSize;
  const pageCount = Math.ceil(total / pageSize);

  return (
    <div className="flex items-center justify-between bg-slate-800 px-4 py-3 text-white sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="relative inline-flex items-center rounded-md border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700">
          Previous
        </button>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-white hover:bg-gray-700">
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm">
            Showing <span className="font-medium">{from}</span> to{' '}
            <span className="font-medium">{to}</span> of{' '}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <nav className="isolate inline-flex shadow-sm">
          <button
            className={twMerge(
              'rounded-l-md border border-gray-700 px-4 py-2 text-sm font-medium text-gray-500',
              !table.getCanPreviousPage() ? 'opacity-50' : 'hover:bg-gray-700'
            )}
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}>
            <span className="sr-only">First</span>
            <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className={twMerge(
              'border border-gray-700 px-4 py-2 text-sm font-medium text-gray-500',
              !table.getCanPreviousPage() ? 'opacity-50' : 'hover:bg-gray-700'
            )}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}>
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <span className="select-none border border-gray-700 px-4 py-2 text-sm font-medium text-gray-300">
            {pageIndex + 1} of {pageCount}
          </span>
          <button
            className={twMerge(
              'border border-gray-700 px-4 py-2 text-sm font-medium text-gray-500',
              !table.getCanNextPage() ? 'opacity-50' : 'hover:bg-gray-700'
            )}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}>
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            className={twMerge(
              'rounded-r-md border border-gray-700 px-4 py-2 text-sm font-medium text-gray-500',
              !table.getCanNextPage() ? 'opacity-50' : 'hover:bg-gray-700'
            )}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}>
            <span className="sr-only">Last</span>
            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
}
