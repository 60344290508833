import * as Yup from "yup";

export const Schema = Yup.object().shape({
  companyname: Yup.string().required("Company name is required"),
  companyemail: Yup.string()
    .email("Invalid email address")
    .required("Company email is required"),
  networks: Yup.array().min(1, "At least one network is required"),
  tokens: Yup.array().min(1, "At least one token is required"),
  approval_required: Yup.boolean(),
  approver: Yup.string().when("approval_required", {
    is: true,
    then: (schema) =>
      schema
        .email("Invalid email address")
        .required("Approver email is required"),
  }),
});
