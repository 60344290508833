import React, { useState } from 'react';
import { Button } from 'shared/ui/Button/Button';

import { Form as AForm, Select as ASelect } from 'antd';

export const DepositDetailsSelection = ({
  onSelect,
  depositNetworksOptions = [
    { value: 'ethereum', label: 'Ethereum' },
    { value: 'polygon', label: 'Polygon' },
  ],
}) => {
  const [network, setNetwork] = useState();

  const _handleConfirm = () => {
    if (network) {
      onSelect({ depositTokenChain: network });
    }
  };

  return (
    <div className="flex flex-col pt-1">
      <AForm.Item layout="vertical" label="Deposit Network">
        <ASelect
          placeholder={'Select Network'}
          value={network}
          onChange={(value) => {
            setNetwork(value);
          }}
          options={depositNetworksOptions}
        />
      </AForm.Item>

      <div className="h-[120px]" />

      <Button color="indigo" onClick={_handleConfirm}>
        Confirm
      </Button>
    </div>
  );
};
