import { Dialog } from "@headlessui/react";
import { createContext, useContext, useReducer, useRef } from "react";
import { Button, Modal } from "shared/ui";

const Context = createContext();
const Dispatch = createContext();

export const useDialog = () => {
  const dispatch = useContext(Dispatch);

  const confirm = (options) => {
    dispatch({ ...options, open: true });
  };

  return { confirm };
};

const DialogCmp = () => {
  const { title, desc, open, onConfirm, onCancel } = useContext(Context);
  const dispatch = useContext(Dispatch);

  const cancelButtonRef = useRef(null);

  const setOpen = (val) => dispatch({ open: val });

  const handleConfirm = () => {
    onConfirm();
    dispatch({ open: false });
  };

  const handleCancel = () => {
    onCancel();
    dispatch({ open: false });
  };

  return (
    <Modal show={open} handleClose={() => setOpen(false)}>
      <Dialog.Panel className="relative max-w-lg transform overflow-hidden rounded-lg bg-gray-800 px-6 pb-6 pt-10 text-left text-white shadow-xl transition-all">
        <div className="text-center">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6">
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-300">{desc}</p>
          </div>
        </div>
        <div className="mt-8 flex flex-row-reverse gap-x-2">
          <Button className="flex-1 justify-center" onClick={handleConfirm}>
            OK
          </Button>
          <Button
            ui="secondary"
            className="flex-1 justify-center"
            onClick={handleCancel}
            ref={cancelButtonRef}>
            Cancel
          </Button>
        </div>
      </Dialog.Panel>
    </Modal>
  );
};

export default function DialogProvider({ children }) {
  const [value, dispatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      title: "",
      desc: "",
      open: false,
      onConfirm: () => undefined,
      onCancel: () => undefined,
    }
  );

  return (
    <Context.Provider value={value}>
      <Dispatch.Provider value={dispatch}>
        {children}
        <DialogCmp />
      </Dispatch.Provider>
    </Context.Provider>
  );
}
