import { useCallback, useState } from 'react';
import { useCurrentUserDetails } from '../../entities/user/model';
import { useAuth0 } from '@auth0/auth0-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '../../entities/invoice/model/constants';
import _ from 'lodash';
import axios from 'axios';

async function fetchInvoicesRequest(
  token: string,
  organizationId: string,
  queryOptions: QueryOptions
) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/invoices/list`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          filter: {
            generalSearchString: queryOptions.generalSearchString,
          },
          pageNumber: queryOptions.pageNumber,
          pageLimit: queryOptions.pageLimit,
          organizationId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

type QueryOptions = {
  pageNumber: number;
  pageLimit: number;
  generalSearchString?: string;
};

export const INIT_INVOICES_PAGINATION = { pageIndex: 0, pageSize: 10 };

export const useOrganizationInvoices = (
  initQueryOptions: QueryOptions = {
    pageNumber: INIT_INVOICES_PAGINATION.pageIndex + 1,
    pageLimit: INIT_INVOICES_PAGINATION.pageSize,
  }
) => {
  const [queryOptions, setQueryOptions] =
    useState<QueryOptions>(initQueryOptions);
  const { getCurrentOrganizationId, hasLoggedInUser } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const { data, isPending, isFetching } = useQuery({
    queryKey: [
      QUERY_KEY,
      queryOptions.pageNumber,
      queryOptions.generalSearchString,
    ],
    queryFn: () =>
      getAccessTokenSilently().then((token) =>
        fetchInvoicesRequest(token, getCurrentOrganizationId(), queryOptions)
      ),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
    enabled: hasLoggedInUser,
  });

  const _updateQueryOptions = useCallback(
    ({ generalSearchString, pageNumber, pageLimit }: Partial<QueryOptions>) => {
      const update: Partial<QueryOptions> = {};

      if (pageNumber !== undefined) {
        update.pageNumber = pageNumber;
      }

      if (pageLimit !== undefined) {
        update.pageLimit = pageLimit;
      }

      if (generalSearchString !== undefined) {
        update.generalSearchString = generalSearchString;
      }

      setQueryOptions({
        ...queryOptions,
        ...update,
      });
    },
    [setQueryOptions, queryOptions]
  );

  const _hasFilterOptions = useCallback(() => {
    return !(
      _.isUndefined(queryOptions.generalSearchString) ||
      queryOptions.generalSearchString === ''
    );
  }, [queryOptions]);

  return {
    invoices: data?.result || [],
    invoicesTotal: data?.total || -1,
    isLoading: isPending,
    isFetching,
    queryOptions,
    hasFilterOptions: _hasFilterOptions,
    updateQueryOptions: _updateQueryOptions,
  };
};
