import { CheckIcon } from '@heroicons/react/24/outline';
import {
  useAccountingData,
  useReconcileBill,
  createBillRecociliationPayload,
} from 'pages/bill/BillReconcilePage';
import {
  filterAccountsByLink,
  findSupplier,
} from '../../../widgets/Bills/BillReconcileForm';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Spinner } from 'shared/ui/Spinner';

const _fetchReconciledBill = async (getToken, orgId, billId) => {
  const token = await getToken();
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/codat/get-bill/${billId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        selected_organization: orgId,
      },
    }
  );
  return response.data;
};

export const _useReconciledBill = (billd) => {
  const { data: userData, hasAccountingIntegration } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();

  const integrationQuery = useQuery({
    queryKey: ['reconciled-bill', billd || ''],
    queryFn: () =>
      _fetchReconciledBill(
        getAccessTokenSilently,
        userData.selected_organization,
        billd
      ),
    enabled:
      !!Object.keys(userData.selected_organization || {}).length &&
      hasAccountingIntegration() &&
      !!billd,

    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });

  const _getReconciledCategory = (billAccountingData) => {
    return (
      billAccountingData?.lineItems &&
      billAccountingData?.lineItems.length &&
      billAccountingData?.lineItems[0]?.accountRef?.name
    );
  };

  return {
    billData: integrationQuery.data,
    reconciledCategory: _getReconciledCategory(integrationQuery.data),
    isBillDataFetching: integrationQuery.isFetching,
  };
};

const _resolveTax = (taxRate, zeroTaxRate, taxRates) => {
  if (Number(taxRate) === 0 && zeroTaxRate) {
    return {
      id: zeroTaxRate.id,
      name: zeroTaxRate.name,
    };
  }

  const matchedTax = taxRates.find(
    (tax) => tax.effectiveTaxRate === Number(taxRate)
  );

  return {
    id: matchedTax?.id,
    name: matchedTax?.name,
  };
};

const _resolveReconcileInput = (selectedAccount, accountingData, bill) => {
  const { suppliers, zeroTaxRate, taxRates } = accountingData;
  const { matchedSupplier } = findSupplier(suppliers, bill);

  const shouldCreateNewSupplier = !matchedSupplier?.id;
  const tax = _resolveTax(Number(bill.tax), zeroTaxRate, taxRates);

  return {
    supplierId: matchedSupplier?.id || '',
    supplierName: matchedSupplier?.supplierName || '',
    lineItemTaxAmount: Number(bill.tax),
    lineItemTaxId: tax.id,
    lineItemTaxName: tax.name,
    newSupplierName: shouldCreateNewSupplier ? bill.receiverName : '',
    newSupplierEmail: shouldCreateNewSupplier ? bill.receiverEmail : '',
    newSupplier: shouldCreateNewSupplier,
    nominalAccountId: selectedAccount.id,
    nominalAccountName: selectedAccount.name,
  };
};

export const ReconciliationCell = ({ bill }) => {
  const accountingData = useAccountingData();
  const { reconcile, isReconciling } = useReconcileBill(bill._id.toString());
  const { isBillDataFetching, reconciledCategory } = _useReconciledBill(
    bill.accountingStatus.reconciliationId
  );
  const accountsRes = filterAccountsByLink(accountingData.accounts);

  const handleStatusUpdate = async (catId) => {
    const selectedAccount = accountsRes.find((acc) => acc.id === catId);

    if (selectedAccount) {
      reconcile(
        createBillRecociliationPayload(
          bill,
          accountingData,
          _resolveReconcileInput(selectedAccount, accountingData, bill)
        )
      );
    }
  };

  if (isBillDataFetching || isReconciling) {
    return (
      <>
        <Spinner className="h-5 w-5 border-2" />
      </>
    );
  }

  if (bill.accountingStatus.reconciled) {
    return (
      <div className="flex flex-col items-center">
        <span className="flex items-center">
          <CheckIcon className="mr-1 w-[20px] text-emerald-600" /> Reconciled
        </span>
        {!!reconciledCategory && (
          <span className="text-[10px] text-gray-400">
            {reconciledCategory}
          </span>
        )}
      </div>
    );
  }

  return (
    <>
      <select
        onClick={(e) => e.stopPropagation()}
        className="border-1 w-[126px] cursor-pointer rounded border-[#3e495b] bg-slate-800 px-2 py-1 pr-8 text-left text-xs"
        onChange={(e) => {
          handleStatusUpdate(e.target.value);
        }}>
        <option value="default">Reconcile Now</option>
        {accountsRes.map(({ name, id }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </>
  );
};
