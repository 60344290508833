import axios from 'axios';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';

const fetchEnrichedVendors = async (token, organizationId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/user-roles/list`,
      {
        params: {
          filter: {
            roles: ['vendor', 'employee'],
          },
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

const fetchVendors = async (token, organizationId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/vendors/list`,
      {
        params: {
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

const fetchVendorDetails = async (token, organizationId, userId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/user-roles/describe/${userId}`,
      {
        params: {
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const useVendors = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { hasLoggedInUser, getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const vendorsQuery = useQuery({
    queryKey: ['my-vendors'],
    queryFn: () =>
      getAccessTokenSilently().then((token) =>
        fetchEnrichedVendors(token, getCurrentOrganizationId())
      ),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
    enabled: hasLoggedInUser && hasAnyOrganization,
  });

  return {
    vendors: vendorsQuery.data || [],
    isVendorsLoading: vendorsQuery.isLoading,
  };
};

export const useDescribeVendor = (userId) => {
  const { getAccessTokenSilently } = useAuth0();
  const { hasLoggedInUser, getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const vendorQuery = useQuery({
    queryKey: ['vendor', userId],
    queryFn: () =>
      getAccessTokenSilently().then((token) =>
        fetchVendorDetails(token, getCurrentOrganizationId(), userId)
      ),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
    enabled: hasLoggedInUser && hasAnyOrganization && !!userId,
  });

  return {
    vendor: vendorQuery.data,
    isFetching: vendorQuery.isFetching,
  };
};

// light version, simple list
export const useOrgVendorsList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { hasLoggedInUser, getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const { data, isLoading } = useQuery({
    queryKey: ['organization-vendors'],
    queryFn: () =>
      getAccessTokenSilently().then((token) =>
        fetchVendors(token, getCurrentOrganizationId())
      ),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
    enabled: hasLoggedInUser && hasAnyOrganization,
  });

  return {
    data: data || [],
    isLoading,
  };
};

const fetchVendorPaymentDetails = async (
  token,
  organizationId,
  vendorUserId
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/vendors/user/${vendorUserId}/payment-details/list`,
      {
        params: {
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.log(error);
  }
};

export const useOrgVendorPaymentDetails = (vendorUserId) => {
  const { getAccessTokenSilently } = useAuth0();
  const { hasLoggedInUser, getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['organization-vendor-payment-details', vendorUserId],
    queryFn: () =>
      getAccessTokenSilently().then((token) =>
        fetchVendorPaymentDetails(
          token,
          getCurrentOrganizationId(),
          vendorUserId
        )
      ),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
    enabled: !!vendorUserId && hasLoggedInUser && hasAnyOrganization,
  });

  return {
    data: data || [],
    isLoading,
    isFetching,
  };
};

export const useDownloadVendorTaxForm = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['download-vendor-tax-form'],
    mutationFn: async ({ vendorId }) => {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/vendors/${vendorId}/download-tax-form`,
        {
          responseType: 'arraybuffer',
          params: {
            organizationId: getCurrentOrganizationId(),
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    },
  });

  return {
    downloadTaxForm: mutateAsync,
    isDownloading: isPending,
  };
};
