import _ from 'lodash';

import { PayoutTypes } from './PayoutDetails';

export const paymentsConfig = {
  providers: [
    {
      name: 'Your Wallet',
      id: 'wallet-connect',
      enabled: true,
      batchAvailable: false,
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
      suitableForCryptoNetworks: [
        'Polygon',
        'Ethereum',
        'Optimism',
        'Arbitrum',
        'Base',
      ],
      suitableForCurrencies: [
        'ETH',
        'USDC',
        'USDC.native_polygon',
        'USDT',
        'BUSD',
        'DAI',
        'ANT',
        'EURS',
        'GRT',
        'OP',
        'VELA',
        'esVELA',
        'ARB',
      ],
    },
    {
      name: 'Your Wallet',
      id: 'solana-wallet-connect',
      enabled: true,
      batchAvailable: false,
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
      suitableForCryptoNetworks: ['Solana'],
      suitableForCurrencies: ['SOL', 'USDC'],
    },
    {
      name: 'Pay With Exchange',
      id: 'mesh',
      enabled: true,
      batchAvailable: false,
      onboardingType: 'app-flow',
      suitableForCryptoNetworks: [
        'Polygon',
        'Ethereum',
        'Optimism',
        'Arbitrum',
        // 'Base',
      ],
      suitableForCurrencies: [
        'ETH',
        'USDC',
        'USDC.native_polygon',
        'USDT',
        'BUSD',
        'DAI',
        'ANT',
        'EURS',
        'GRT',
        'OP',
      ],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
    {
      name: 'Fractal Account',
      id: 'fractal-abstract-bank-account',
      enabled: true,
      batchAvailable: false,
      onboardingType: 'customer-service',
      suitableForPayouts: [
        PayoutTypes.CryptoTransfer,
        PayoutTypes.BankTransfer,
      ],
      suitableForCryptoNetworks: ['Polygon'],
      suitableForCurrencies: ['USDC', 'USD', 'EUR'],
    },
    {
      name: 'Gnosis Safe',
      id: 'gnosis-safe',
      enabled: true,
      batchAvailable: true,
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
      suitableForCryptoNetworks: [
        'Polygon',
        'Ethereum',
        'Optimism',
        'Arbitrum',
        'Base',
      ],
      suitableForCurrencies: [
        'ETH',
        'USDC.native_polygon',
        'USDC',
        'USDT',
        'BUSD',
        'DAI',
        'ANT',
        'EURS',
        'GRT',
        'OP',
        'VELA',
        'esVELA',
        'ARB',
      ],
    },
    {
      name: 'Aragon App',
      id: 'aragon-app',
      enabled: true,
      batchAvailable: true,
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForCurrencies: [
        'ETH',
        'USDC',
        'USDC.native_polygon',
        'USDT',
        'BUSD',
        'DAI',
        'ANT',
        'EURS',
        'GRT',
      ],
    },
    {
      name: 'Settle With Crypto',
      id: 'payso',
      enabled: true,
      onboardingType: 'customer-service',
      batchAvailable: true,
      suitableForCurrencies: ['EUR', 'USD', 'GBP', 'CHF'],
      suitableForPayouts: [PayoutTypes.BankTransfer],
    },
    {
      name: 'Settle With Fiat',
      id: 'payso-onramp',
      enabled: true,
      isOnrampMethod: true,
      onboardingType: 'customer-service',
      batchAvailable: false,
      suitableForCurrencies: ['USDC', 'USDC.native_polygon', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
      availableDepositCurrencies: ['USD', 'EUR'],
    },
    {
      name: 'Settle with Fiat',
      id: 'easy-pay-onramp',
      isOnrampMethod: true,
      enabled: true,
      onboardingType: 'customer-service',
      batchAvailable: false,
      availableDepositCurrencies: ['USD', 'EUR'],
      suitableForCurrencies: ['USDC', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
    {
      name: 'Settle with Fiat',
      id: 'red-envelope-onramp',
      enabled: true,
      isOnrampMethod: true,
      batchAvailable: false,
      onboardingType: 'customer-service',
      availableDepositCurrencies: ['USD'],
      suitableForCurrencies: ['USDC', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
    {
      name: 'Monerium',
      id: 'monerium',
      enabled: true,
      batchAvailable: false,
      onboardingType: 'app-flow',
      suitableForCurrencies: ['EUR'],
      suitableForPayouts: [PayoutTypes.BankTransfer],
    },
  ],
};

class PaymentsConfig {
  #config;

  constructor(config) {
    this.#config = config;
  }

  get(path, fallback) {
    return _.get(this.#config, path, fallback);
  }
}

export const getPaymentsConfig = () => {
  return new PaymentsConfig(paymentsConfig);
};
