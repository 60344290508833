const erc20TokensData = {
  USDC: {
    name: 'USDC',
    erc20Address: {
      Ethereum: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // NATIVE
      Polygon: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // POS
      Arbitrum: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8', // POS
      Optimism: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85', // NATIVE
      Base: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913', // NATIVE
    },
    decimals: 1e6,
    decimalsInteger: 6,
  },
  /** We have got requirements to have native support for Polygon USDC
   * old USDC identification reflects a list of several existing tokens which a mix of bridged and native.
   * Let's just treat them it as new token with understandable for us naming native_polygon
   * If somebody comes to us who develops new USDC "LOL" token for example we just treat it as a new token and name it again
   * understandable for us and our system. But in order to comply with real world naming we will just use name resolvers
   * By this we make sure, regardless how many tokens we introduce -- old data works respectively and users can continue pay
   * to the right addresses
   * */
  'USDC.native_polygon': {
    name: 'USDC',
    erc20Address: {
      Polygon: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    },
    decimals: 1e6,
    decimalsInteger: 6,
  },
  USDT: {
    name: 'USDT',
    erc20Address: {
      Ethereum: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      Polygon: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      Arbitrum: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      Optimism: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    },
    decimals: 1e6,
    decimalsInteger: 6,
  },
  BUSD: {
    name: 'BUSD',
    erc20Address: {
      Ethereum: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
      Polygon: '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  DAI: {
    name: 'DAI',
    erc20Address: {
      Ethereum: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      Polygon: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      Arbitrum: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      Optimism: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  ANT: {
    name: 'ANT',
    erc20Address: {
      Ethereum: '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  EURS: {
    name: 'EURS',
    erc20Address: {
      Ethereum: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    },
    decimals: 1e2,
    decimalsInteger: 2,
  },
  GRT: {
    name: 'GRT',
    erc20Address: {
      Ethereum: '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
      Polygon: '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  OP: {
    name: 'OP',
    erc20Address: {
      Optimism: '0x4200000000000000000000000000000000000042',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  VELA: {
    name: 'VELA',
    erc20Address: {
      Arbitrum: '0x088cd8f5eF3652623c22D48b1605DCfE860Cd704',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  esVELA: {
    name: 'esVELA',
    erc20Address: {
      Arbitrum: '0xeFD5A713C5bd85e9Ced46070b2532E4a47a18102',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
  ARB: {
    name: 'ARB',
    erc20Address: {
      Arbitrum: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    },
    decimals: 1e18,
    decimalsInteger: 18,
  },
};

export default erc20TokensData;
