import { Alert } from 'shared/ui';

export const ErrorInformationAlert = ({ show, errors }) => {
  // Check if the errors object is empty
  const hasErrors = Object.keys(errors).length > 0;

  return (
    show &&
    hasErrors && (
      <div className="col-span-6 mt-5 sm:col-span-6">
        <Alert type={'warning'}>
          <div className="space-y-0.5 text-sm text-slate-300">
            <p className="text-amber-300">
              Please ensure you input the right information.
            </p>
            {Object.keys(errors).map((key) => (
              <p key={key}>{errors[key]}</p>
            ))}
          </div>
        </Alert>
      </div>
    )
  );
};
