import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SecondaryHeader from "shared/ui/SecondaryHeader";
import UserChangeDetails from "widgets/Users/UserChangeDetails";

const UserSettingsPage = () => {
  const secondaryNavigation = [
    {
      name: "Your Details",
      href: "/dashboard/settings/user",
      current: false,
    },
  ];

  const location = useLocation();
  const [navigation, setNavigation] = useState(secondaryNavigation);

  useEffect(() => {
    const currentLocation = window.location.pathname;

    setNavigation((prevNav) =>
      prevNav.map((item) => {
        if (item.href === currentLocation) {
          return {
            ...item,
            current: true,
          };
        }
        return {
          ...item,
          current: false,
        };
      })
    );
  }, [location]);

  return (
    <div className="mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <SecondaryHeader navItems={navigation} />
      <Routes>
        <Route path="/" element={<UserChangeDetails />} />
      </Routes>
    </div>
  );
};

export default UserSettingsPage;
