import { ShieldCheckIcon, WalletIcon } from "@heroicons/react/24/outline";

import AboutPage from "pages/AboutPage";
import InvoicePayPage from "pages/invoice/InvoicePayPage";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Sidebar } from "shared/ui//Sidebar";
import { Header } from "shared/ui/Header";
import MainLayout from "shared/ui/layouts/MainLayout";

const navigation_links = [
  {
    name: "Make Payment",
    href: "/pay" + window.location.search,
    icon: WalletIcon,
    current: true,
  },
  {
    name: "About Fractal",
    href: "/pay/about",
    icon: ShieldCheckIcon,
    current: false,
  },
];

const userNavigation = [{ name: "Sign out", href: "#" }];

export default function DashboradPublicInvoicePayment() {
  const location = useLocation();
  const [navigation, setNavigation] = useState(navigation_links);

  useEffect(() => {
    const currentLocation = window.location.pathname + window.location.search;
    const updatedNavigation = navigation.map((item) => {
      if (item.href === currentLocation) {
        return {
          ...item,
          current: true,
        };
      }
      return {
        ...item,
        current: false,
      };
    });
    setNavigation(updatedNavigation);
  }, [location]);

  return (
    <MainLayout
      header={<Header navigation={userNavigation} />}
      sidebar={<Sidebar navigation={navigation} />}>
      <Routes>
        <Route path="/" element={<InvoicePayPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
    </MainLayout>
  );
}
