import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { twMerge } from "tailwind-merge";

const LogoutButton = ({ className }) => {
  const { logout } = useAuth0();

  return (
    <button
      className={twMerge("b-2 w-20", className)}
      onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </button>
  );
};

export default LogoutButton;
