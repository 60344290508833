import { useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser } from 'entities/user/model';

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/organization';
const RESOURCE_URL_V2 =
  process.env.REACT_APP_SERVER_URL + '/api/public/organisation';

const createOrganization = async (organizationData, token) => {
  try {
    const response = await axios.post(
      `${RESOURCE_URL}/create-organization`,
      organizationData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

async function fetchOrganizations(userId) {
  try {
    const response = await axios.get(
      `${RESOURCE_URL}/get-organizations?user_id=${userId}`
    );

    return response.data.result;
  } catch (error) {
    throw error;
  }
}

async function fetchOrganization(userId) {
  try {
    const response = await axios.get(
      `${RESOURCE_URL}/get-organization?id=${userId}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function fetchPaymentDetails(
  token,
  ownerUserId,
  ownerOrganisationId = undefined
) {
  try {
    const response = await axios.get(
      `${RESOURCE_URL_V2}/payment-details/list`,
      {
        params: {
          ownerUserId,
          ownerOrganisationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function createPaymentDetails(token, payload) {
  try {
    const response = await axios.post(
      `${RESOURCE_URL_V2}/payment-details`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function deletePaymentDetails(token, id) {
  try {
    const response = await axios.delete(
      `${RESOURCE_URL_V2}/payment-details/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function editPaymentDetails(token, payload) {
  try {
    const response = await axios.put(
      `${RESOURCE_URL_V2}/payment-details/${payload.id}`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const useOrganizationActions = () => {
  const { data } = useCurrentUser();
  const { getAccessTokenSilently } = useAuth0();

  const createOrganizationAction = useCallback(
    async (organizationData) => {
      if (!data.nickname) {
        return null;
      }

      const token = await getAccessTokenSilently();
      return createOrganization(organizationData, token);
    },
    [data, getAccessTokenSilently]
  );

  const fetchAllAction = useCallback(async () => {
    if (!data.nickname) {
      return null;
    }

    return fetchOrganizations(data.user_id);
  }, [data]);

  const fetchAction = useCallback(async () => {
    if (!data.nickname) {
      return null;
    }

    return fetchOrganization(data.user_id);
  }, [data]);

  const fetchPaymentDetailsAction = useCallback(async () => {
    if (!data.nickname) {
      return null;
    }

    const token = await getAccessTokenSilently();
    return fetchPaymentDetails(
      token,
      data.user_id,
      data.selected_organization?._id
    );
  }, [data, getAccessTokenSilently]);

  const createPaymentDetailsAction = useCallback(
    async (payload) => {
      if (!data.nickname) {
        return null;
      }

      const token = await getAccessTokenSilently();
      return createPaymentDetails(token, payload);
    },
    [data, getAccessTokenSilently]
  );

  const deletePaymentDetailsAction = useCallback(
    async (id) => {
      if (!data.nickname) {
        return null;
      }

      const token = await getAccessTokenSilently();
      return deletePaymentDetails(token, id);
    },
    [data, getAccessTokenSilently]
  );

  const editPaymentDetailsAction = useCallback(
    async (payload) => {
      if (!data.nickname) {
        return null;
      }

      const token = await getAccessTokenSilently();
      return editPaymentDetails(token, payload);
    },
    [data, getAccessTokenSilently]
  );

  return {
    createOrganizationAction,
    fetchAllAction,
    fetchAction,
    fetchPaymentDetailsAction,
    createPaymentDetailsAction,
    deletePaymentDetailsAction,
    editPaymentDetailsAction,
  };
};
