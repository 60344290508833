import { Link } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';

export default function EmptyOrders() {
  return (
    <div className="mt-20 text-center">
      <h3 className="mt-2 font-semibold text-slate-200">
        You have no standing orders.
      </h3>
      <p className="text-slate-400">
        Please add a bill and select a recurrence schedule to create a standing
        order.
      </p>
      <Button
        color="gray"
        className="mt-2 inline-flex items-center justify-center"
        as={Link}
        to="/dashboard/bills/allbills">
        Go to Bills
      </Button>
    </div>
  );
}
