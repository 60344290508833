import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { useSafeApiKit } from 'shared/hooks/safeWallet';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Spinner } from 'shared/ui/Spinner';
import { Dialog } from '@headlessui/react';
import { Button } from 'shared/ui';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const useSafeOwners = () => {
  const apiKit = useSafeApiKit();
  const { address } = useAccount();
  const dataQuery = useQuery({
    queryFn: () => (apiKit ? apiKit.getSafesByOwner(address) : { safes: [] }),
    queryKey: ['safe-owners', address],
    enabled: !!apiKit && !!address,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });

  return {
    owners: dataQuery.data?.safes || [],
    isLoading: dataQuery.isLoading,
  };
};

export const SafeAddressPicker = ({ show, onAddressSelect }) => {
  const { address: accountAddress } = useAccount();
  const { owners, isLoading } = useSafeOwners();
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (owners.length) {
      setAddress(owners[0]);
    }
  }, [owners]);

  if (!show) {
    return null;
  }

  const _handleSelectAddress = () => {
    onAddressSelect(address);
  };

  const _handleAddressChange = (e) => {
    setAddress(e.currentTarget.value);
  };

  if (!accountAddress) {
    return (
      <div className="absolute left-0 top-0 z-30 flex flex-col h-full items-center justify-center w-full bg-slate-800 p-4">
        <ConnectButton.Custom>
          {({ openConnectModal }) => (
            <Button onClick={openConnectModal} fullSized color="indigo">
              Connect Signer Wallet
            </Button>
          )}
        </ConnectButton.Custom>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="absolute left-0 top-0 z-30 flex flex-col h-full w-full bg-slate-800 p-2">
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </div>
    );
  }

  if (!isLoading && owners.length === 0) {
    return (
      <div className="absolute left-0 top-0 z-30 flex flex-col h-full w-full bg-slate-800 p-2">
        <div className="flex h-full w-full items-center justify-center text-sm text-slate-300">
          You don't have any safes for current connected wallet.
        </div>
      </div>
    );
  }

  return (
    <div className="absolute left-0 top-0 z-30 flex flex-col h-full w-full bg-slate-800 p-4">
      <Dialog.Title
        as="h3"
        className="mb-5 text-base font-semibold leading-6 text-slate-300">
        Select a Safe Address
      </Dialog.Title>
      <div className="flex mb-4 items-center justify-between text-left text-sm">
        <span className="text-slate-400">
          Please select a safe address to send the payment from.
        </span>
      </div>

      {owners.map((to) => (
        <>
          <div class="flex items-center mb-4">
            <input
              id={to}
              type="radio"
              value={to}
              onChange={_handleAddressChange}
              checked={to === address}
              class="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              for={to}
              class="ml-2 text-xs font-medium text-gray-900 dark:text-slate-300">
              {to}
            </label>
          </div>
        </>
      ))}

      <div className="flex-1" />

      <Button className={'justify-center'} onClick={_handleSelectAddress}>
        Confirm
      </Button>
    </div>
  );
};
