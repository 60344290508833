import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { twMerge } from "tailwind-merge";

const LoginButton = ({ ctaText = "Log In", email, className }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      type="button"
      className={twMerge(
        "focus:shadow-outline  rounded border-indigo-500 bg-indigo-500 px-4 py-2 font-bold text-white hover:bg-indigo-800 focus:outline-none",
        className
      )}
      onClick={() =>
        loginWithRedirect({
          login_hint: email,
          screen_hint: email ? "signup" : "login",
        })
      }>
      {ctaText}
    </button>
  );
};

export default LoginButton;
