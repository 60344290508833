import { Field, ErrorMessage } from 'formik';

import { getNetworksByPayLink, getTokensByPayLink } from '../lib';
import { CryptoNetworkTokenSelect } from '../../../widgets/Invoices/InvoiceGenerateForm.PaymentTypeSelection';

export const PaymentTypeCrypto = ({ payLink, formValues, onValueChange }) => {
  return (
    <div className="sm:col-span-full">
      <CryptoNetworkTokenSelect
        allowedNetworks={getNetworksByPayLink(payLink)}
        allowedTokens={getTokensByPayLink(payLink, formValues.network)}
        network={formValues.network}
        token={formValues.token}
        onTokenChange={(t) => {
          onValueChange('token', t);
          onValueChange('ach_routing_number', '');
          onValueChange('account_number', '');
        }}
        onNetworkChange={(n, t) => {
          onValueChange('network', n);
          const firstToken = getTokensByPayLink(payLink, n)[0];
          onValueChange('token', firstToken);
        }}
      />
    </div>
  );
};

export const PaymentTypeFiat = ({ onValueChange }) => {
  return (
    <>
      <div className="col-span-6 sm:col-span-3">
        <label className="block text-sm text-[#CBD5E1] ">Currency *</label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <Field
            as="select"
            name="token"
            id="token"
            className="mt-1 block w-full rounded-md border-2 border-[#4E6180]    bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => {
              onValueChange('token', event.target.value);
              onValueChange('ach_routing_number', '');
              onValueChange('account_number', '');
            }}>
            <option value="EUR">EUR</option>
            <option value="USD">USD</option>
            <option value="GBP">GBP</option>
            <option value="CHF">CHF</option>
          </Field>
        </div>
        <ErrorMessage
          name="token"
          component="div"
          className="mt-2 text-xs italic text-rose-500"
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label htmlFor="region" className="block text-sm text-[#CBD5E1]">
          Beneficiary Type
        </label>
        <div className="mt-2">
          <Field
            as="select"
            name="bankAccountBeneficiaryType"
            id="bankAccountBeneficiaryType"
            className="mt-1 block w-full rounded-md border-2 border-[#4E6180]  bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            onChange={(event) => {
              onValueChange('bankAccountBeneficiaryType', event.target.value);
            }}>
            <option value="business">Business</option>
            <option value="individual">Individual</option>
          </Field>
        </div>
      </div>
    </>
  );
};
