import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCurrentUserDetails } from '../../entities/user/model';
import { useAuth0 } from '@auth0/auth0-react';

export const useQuickBooksAuthorise = () => {
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['quick-book-authorise'],
    mutationFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await axios.get<string, any>(
        `${process.env.REACT_APP_SERVER_URL}/api/public/quick-books-online/authorize`,
        {
          params: { organizationId: getCurrentOrganizationId() },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return resp.data?.result;
    },
  });

  return {
    authUri: mutation?.data?.authUri,
    authorise: mutation.mutateAsync,
    isAuthorisationPending: mutation.isPending,
  };
};
