import { Textarea } from 'flowbite-react';

// const customFlowBiteTheme = {
//   field: {
//     base: 'relative w-full',
//     input: {
//       base: 'rounded-lg overflow-hidden block w-full border disabled:cursor-not-allowed disabled:opacity-50',
//       sizes: {
//         sm: 'p-2',
//         md: 'text-sm',
//         lg: 'sm:text-md p-3',
//       },
//       colors: {
//         gray: 'dark:border-gray-600 dark:bg-gray-700/50  dark:text-white dark:placeholder-gray-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
//         slate:
//           'dark:border-slate-600 dark:bg-slate-700/50  dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
//         lightSlate:
//           'dark:border-slate-600 dark:bg-slate-700  dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
//         darkSlate:
//           'dark:border-[#4E6180] dark:bg-[#18222d]  dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500 p-2.5 text-sm rounded-lg',
//       },
//     },
//   },
// };

const customFlowBiteTheme = {
  base: 'block w-full rounded-lg border text-sm disabled:cursor-not-allowed disabled:opacity-50',
  colors: {
    gray: 'border-gray-300 bg-gray-50 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500',
    info: 'border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-cyan-500 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500',
    failure:
      'border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-red-100 dark:focus:border-red-500 dark:focus:ring-red-500',
    darkSlate:
      'border-gray-300 bg-gray-50 text-gray-900 focus:border-indigo-500 focus:ring-indigo-500 dark:border-[#4E6180] dark:bg-[#18222d] dark:text-white dark:placeholder-slate-400 dark:focus:border-indigo-500 dark:focus:ring-indigo-500',
    warning:
      'border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500',
    success:
      'border-green-500 bg-green-50 text-green-900 placeholder-green-700 focus:border-green-500 focus:ring-green-500 dark:border-green-400 dark:bg-green-100 dark:focus:border-green-500 dark:focus:ring-green-500',
  },
  withShadow: {
    on: 'shadow-sm dark:shadow-sm-light',
    off: '',
  },
};

export const TextArea = ({ ...props }) => (
  <Textarea theme={customFlowBiteTheme} {...props} />
);
