import React from 'react';
import { PaymentMethodTypes } from '../payments/PaymentProviderIdTypes';
import { DetailSection } from '../../shared/components';
import { formatDollarAmount } from '../../shared/lib/string';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

export const OnrampDepositDetailsSlideOverWidget: React.FC<{
  onrampPaymentStatus: 'executed' | 'success' | 'initiated' | null;
  onrampPaymentMethod: PaymentMethodTypes;
  paymentProviderResponse: Record<string, any>;
}> = ({
  onrampPaymentStatus,
  onrampPaymentMethod,
  paymentProviderResponse,
}) => {
  if (['executed', 'success', null].includes(onrampPaymentStatus)) {
    return null;
  }

  const _resolveDepositDetails = () => {
    let depositAmount;
    let depositCurrency;
    let depositRails;
    let depositBankAddress;
    let depositBankName;
    let depositBankBeneficiaryName;
    let depositMessage;
    let depositBankRoutingNumber;
    let depositBankAccountNumber;
    let depositBankAccountIban;
    let depositBankAccountSwift;

    if (onrampPaymentMethod === PaymentMethodTypes.Payso) {
      const onramp = paymentProviderResponse.onramp;

      if (!onramp) {
        return null;
      }
      depositAmount = onramp.deposit_amount;
      depositCurrency = onramp.fiat_currency.toUpperCase();
      depositRails = onramp.fiat_rails;
      depositBankAddress = onramp.deposit_instructions.bank_address;
      depositBankName = onramp.deposit_instructions.bank_name;
      depositBankBeneficiaryName =
        onramp.deposit_instructions.bank_beneficiary_name;
      depositMessage = onramp.deposit_instructions.deposit_message;
      depositBankRoutingNumber =
        onramp.deposit_instructions.bank_routing_number;
      depositBankAccountNumber =
        onramp.deposit_instructions.bank_account_number;
      depositBankAccountIban = onramp.deposit_instructions.bank_iban;
      depositBankAccountSwift = onramp.deposit_instructions.bank_swift;
    } else if (onrampPaymentMethod === PaymentMethodTypes.RedEnvelope) {
      const onramp = paymentProviderResponse;
      depositAmount = onramp.depositAmount;
      depositCurrency = onramp.depositCurrency;
      depositRails = onramp.depositRails;
      depositBankAddress = onramp.depositBankAccount.bankAddress;
      depositBankName = onramp.depositBankAccount.bankName;
      depositBankBeneficiaryName = onramp.depositBankAccount.accountBeneficiary;
      depositMessage = onramp.depositRef;
      depositBankRoutingNumber =
        onramp.depositBankAccount.accountACHRoutingNumber;
      depositBankAccountNumber = onramp.depositBankAccount.accountNumber;
    } else if (onrampPaymentMethod === PaymentMethodTypes.Bridge) {
      const onramp = paymentProviderResponse;
      depositAmount = onramp.source_deposit_instructions.amount;
      depositCurrency = onramp.source_deposit_instructions.currency;
      depositRails = onramp.source_deposit_instructions.payment_rail;
      depositBankAddress = onramp.source_deposit_instructions.bank_address;
      depositBankName = onramp.source_deposit_instructions.bank_name;
      depositBankBeneficiaryName =
        onramp.source_deposit_instructions.bank_beneficiary_name;
      depositMessage = onramp.source_deposit_instructions.deposit_message;
      depositBankRoutingNumber =
        onramp.source_deposit_instructions.bank_routing_number;
      depositBankAccountNumber =
        onramp.source_deposit_instructions.bank_account_number;
    }

    if (depositAmount && depositCurrency) {
      return {
        depositAmount,
        depositCurrency,
        depositRails,
        depositBankAddress,
        depositBankName,
        depositBankBeneficiaryName,
        depositMessage,
        depositBankRoutingNumber,
        depositBankAccountNumber,
        depositBankAccountIban,
        depositBankAccountSwift,
      };
    }

    return null;
  };

  const depositDetails = _resolveDepositDetails();

  if (!depositDetails) {
    return null;
  }

  const _handleCopyParam = (val: string) => {
    navigator.clipboard.writeText(val);
    toast.success('Copied to clipboard!');
  };

  return (
    <DetailSection header="Deposit Information">
      <DetailSection.Item term="Deposit Amount">
        {formatDollarAmount(depositDetails.depositAmount)}
      </DetailSection.Item>
      <DetailSection.Item term="Currency">
        {depositDetails.depositCurrency}
      </DetailSection.Item>
      <DetailSection.Item term="Rails">
        {depositDetails.depositRails}
      </DetailSection.Item>
      <DetailSection.Item term="Bank Address">
        {depositDetails.depositBankAddress}
      </DetailSection.Item>
      <DetailSection.Item term="Bank Name">
        {depositDetails.depositBankName}
      </DetailSection.Item>
      <DetailSection.Item term="Beneficiary Name">
        {depositDetails.depositBankBeneficiaryName}
      </DetailSection.Item>
      {depositDetails.depositMessage && (
        <DetailSection.Item term="Deposit Message">
          <div className="flex">
            {depositDetails.depositMessage}

            <button
              type="button"
              className="ml-1 text-slate-400"
              onClick={() => _handleCopyParam(depositDetails.depositMessage)}>
              <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </DetailSection.Item>
      )}
      {depositDetails.depositBankRoutingNumber && (
        <DetailSection.Item term="Routing Number">
          <div className="flex">
            {depositDetails.depositBankRoutingNumber}

            <button
              type="button"
              className="ml-1 text-slate-400"
              onClick={() =>
                _handleCopyParam(depositDetails.depositBankRoutingNumber)
              }>
              <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </DetailSection.Item>
      )}

      {depositDetails.depositBankAccountNumber && (
        <DetailSection.Item term="Account Number">
          <div className="flex">
            {depositDetails.depositBankAccountNumber}

            <button
              type="button"
              className="ml-1 text-slate-400"
              onClick={() =>
                _handleCopyParam(depositDetails.depositBankAccountNumber)
              }>
              <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </DetailSection.Item>
      )}

      {depositDetails.depositBankAccountIban && (
        <DetailSection.Item term="Account IBAN">
          <div className="flex">
            {depositDetails.depositBankAccountIban}

            <button
              type="button"
              className="ml-1 text-slate-400"
              onClick={() =>
                _handleCopyParam(depositDetails.depositBankAccountIban)
              }>
              <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </DetailSection.Item>
      )}

      {depositDetails.depositBankAccountSwift && (
        <DetailSection.Item term="Swift Code">
          <div className="flex">
            {depositDetails.depositBankAccountSwift}

            <button
              type="button"
              className="ml-1 text-slate-400"
              onClick={() =>
                _handleCopyParam(depositDetails.depositBankAccountSwift)
              }>
              <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </DetailSection.Item>
      )}
    </DetailSection>
  );
};
