import React, { useState } from 'react';
import {
  Flex,
  Form as AForm,
  Input,
  Modal as AModal,
  Select,
  Typography,
  Checkbox,
} from 'antd';
import { Button } from 'shared/ui/Button/Button';
import { useCurrentUserDetails } from '../../../entities/user/model';
import {
  InformationCircleIcon,
  IdentificationIcon,
  ClockIcon,
} from '@heroicons/react/20/solid';
import { ROLES } from '../../../shared/hooks';

import {
  BanknotesIcon,
  CurrencyDollarIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';
import { countries } from '../../../shared/ui/form/CountrySelect';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { sendTransactionLog } from '../../analytics/sendTransactionLog';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const { Text } = Typography;

export const usePaymentsOnboardingState = () => {
  const { hasBridgeIntegration, hasEasyPayIntegration } =
    useCurrentUserDetails();
  return {
    isOnboarded: hasBridgeIntegration() || hasEasyPayIntegration(),
  };
};

const useKybcApplicationStatus = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const query = useQuery({
    queryKey: ['kyc-application-status'],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/kybc-application/list`,
        {
          params: {
            organizationId: getCurrentOrganizationId(),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data?.result || [];
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
    enabled: hasAnyOrganization,
  });
  const currentOrgKYC = query.data?.find(
    (a) => a.applicantEntityId === getCurrentOrganizationId()
  );
  return {
    status: currentOrgKYC?.status,
    isPending: query.isPending,
  };
};

const useCreateKybcApplication = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['create-kyc-application'],
    mutationFn: async (payload) => {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/kybc-application`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await queryClient.invalidateQueries({
        queryKey: ['kyc-application-status'],
      });
    },
  });
  return {
    isCreating: mutation.isPending,
    createApplication: mutation.mutateAsync,
  };
};

export const PaymentsOnboardingModal = ({ show, onClose }) => {
  const [form] = AForm.useForm();
  const { data, currentOrganization, getCurrentOrganizationId } =
    useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();

  const { createApplication, isCreating } = useCreateKybcApplication();

  const _handleCreateApplication = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue();
        const {
          customerName,
          customerType,
          email,
          termsConsent,
          servicesOfInterest,
        } = formData;

        createApplication({
          organizationId: getCurrentOrganizationId(),
          application: {
            customerName,
            servicesOfInterest,
            customerAddress: {
              streetAddress: '-',
              postalCode: '-',
              countryAlpha2: formData['customerAddress.countryAlpha2'],
              city: formData['customerAddress.city'],
            },
            customerType,
            email,
            termsConsent,
          },
        })
          .then(() => {
            onClose();
            toast.success('Success');
          })
          .catch(() => {
            sendTransactionLog(getAccessTokenSilently, {
              title: '[FAIL][KYC]',
              formData,
            });
            toast.error(
              'Was not able to create KYC application, please contact support: pavel@getfractal.xyz',
              {
                autoClose: 6000,
              }
            );
          });
      })
      .catch((res) => {
        console.log(res);
      });
  };

  return (
    <AModal
      title={'Tell us more about you'}
      open={show}
      loading={isCreating}
      centered
      footer={null}
      destroyOnClose
      onCancel={onClose}>
      <AForm
        name="basic"
        form={form}
        requiredMark={false}
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
        initialValues={{
          customerType: 'business',
          email: data.email,
          customerName: currentOrganization.name,
        }}
        layout="vertical"
        autoComplete="off">
        <AForm.Item
          rules={[
            {
              required: true,
              message: 'Select type',
            },
          ]}
          name="customerType"
          layout="vertical"
          label="Are you registering as an individual or a business?">
          <Select
            options={[
              {
                value: 'business',
                label: 'Business',
              },
              {
                value: 'individual',
                label: 'Individual',
              },
            ]}
          />
        </AForm.Item>

        <AForm.Item dependencies={['customerType']} noStyle>
          {() => {
            return (
              <Flex gap={'middle'}>
                <AForm.Item
                  className={'flex-1'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter name',
                    },
                  ]}
                  name="customerName"
                  layout="vertical"
                  label={`${
                    form.getFieldValue('customerType') === 'individual'
                      ? 'Customer'
                      : 'Business'
                  } Name`}>
                  <Input placeholder={'e.g. "John Doe"'} />
                </AForm.Item>
                <AForm.Item
                  className={'flex-1'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email',
                      type: 'email',
                    },
                  ]}
                  name="email"
                  layout="vertical"
                  label="Contact Email">
                  <Input />
                </AForm.Item>
              </Flex>
            );
          }}
        </AForm.Item>

        <AForm.Item
          layout="vertical"
          rules={[
            {
              required: true,
              message: 'Select service of interest',
            },
          ]}
          label="Choose Services"
          name="servicesOfInterest">
          <Checkbox.Group>
            <Flex vertical={true} gap={'middle'}>
              <Checkbox value={'transfers'}>
                <Flex align={'center'} gap={'small'}>
                  Exchange{' '}
                  <ArrowsRightLeftIcon className={'w-4 text-indigo-300'} />
                </Flex>

                <span className={'text-slate-500'}>
                  Seamlessly buy and sell stablecoins. Transfers are restricted
                  to accounts under your name only.
                </span>
              </Checkbox>

              <Checkbox value={'3d_party_payments'}>
                <Flex align={'center'} gap={'small'}>
                  Payments <BanknotesIcon className={'w-4 text-indigo-300'} />
                </Flex>

                <span className={'text-slate-500'}>
                  Easily make crypto-to-fiat and fiat-to-crypto payments to
                  third parties, including employees, contractors, and vendors.
                </span>
              </Checkbox>
            </Flex>
          </Checkbox.Group>
        </AForm.Item>

        <AForm.Item dependencies={['customerType']} noStyle>
          {() => (
            <Flex gap={'middle'}>
              <AForm.Item
                className={'flex-1'}
                layout="vertical"
                label={`${
                  form.getFieldValue('customerType') === 'individual'
                    ? 'Residence'
                    : 'Business'
                } Country`}
                rules={[
                  {
                    required: true,
                    message: 'Select country',
                  },
                ]}
                name="customerAddress.countryAlpha2">
                <Select
                  showSearch={false}
                  placeholder={'Select country'}
                  options={countries.map(({ label, value, flag }) => ({
                    value,
                    label: (
                      <>
                        {flag} {label}
                      </>
                    ),
                  }))}
                />
              </AForm.Item>
              <AForm.Item
                className={'flex-1'}
                layout="vertical"
                label={`${
                  form.getFieldValue('customerType') === 'individual'
                    ? 'Residence'
                    : 'Business'
                } City`}
                rules={[
                  {
                    required: true,
                    message: 'Enter city',
                  },
                ]}
                name="customerAddress.city">
                <Input />
              </AForm.Item>
            </Flex>
          )}
        </AForm.Item>

        <AForm.Item
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error('To continue accept terms and conditions')
                    ),
            },
          ]}
          name="termsConsent"
          layout="vertical">
          <Checkbox>
            I agree for the
            <a
              className="ml-1 font-medium text-primary-600 hover:underline dark:text-indigo-500"
              href="/terms"
              target="_blank">
              Terms and Conditions
            </a>
          </Checkbox>
        </AForm.Item>
      </AForm>

      <div className="sm:grid sm:grid-cols-2 sm:gap-3">
        <Button color="gray" size={'sm'} onClick={onClose}>
          Close
        </Button>
        <Button size={'sm'} color="emerald" onClick={_handleCreateApplication}>
          <IdentificationIcon className="w-4 mr-2" />
          Start Verification
        </Button>
      </div>
    </AModal>
  );
};

export const PaymentsOnboardingWidget = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { status } = useKybcApplicationStatus();
  const { isOnboarded } = usePaymentsOnboardingState();
  const { hasAnyOrganization, getCurrentOrgRole, data } =
    useCurrentUserDetails();
  const [showPaymentsOnboardingModal, setShowPaymentsOnboardingModal] =
    useState(false);
  const _handleClosePaymentsOnboarding = () => {
    setShowPaymentsOnboardingModal(false);
  };

  if (
    status === 'approved' ||
    isOnboarded ||
    !hasAnyOrganization ||
    getCurrentOrgRole() !== ROLES.ADMIN
  ) {
    return null;
  }

  const renderInner = () => {
    if (!status) {
      return (
        <>
          <Flex align={'center'} className={'mb-6'} justify={'space-between'}>
            <Flex gap={'small'}>
              <InformationCircleIcon className="w-4 text-amber-300" />
              <Text className={'!text-slate-400'}>
                To enable payments and money movement, please verify your
                identity.
              </Text>
            </Flex>

            <Button
              size={'xs'}
              color="emerald"
              onClick={() => {
                sendTransactionLog(getAccessTokenSilently, {
                  event: '👤 KYC FORM OPENED',
                  email: data.email,
                });
                setShowPaymentsOnboardingModal(true);
              }}>
              <IdentificationIcon className="w-4 mr-2" />
              Verify
            </Button>
          </Flex>

          <PaymentFeaturesDescription />
        </>
      );
    }

    return (
      <>
        <Flex align={'center'} justify={'space-between'}>
          <Flex gap={'small'}>
            <ClockIcon className="w-4 text-amber-300" />
            <Text className={'!text-slate-400'}>
              Identity verification is under review
            </Text>
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <Flex vertical={true} className="p-4 rounded-lg dark:bg-slate-800 mb-4">
        {renderInner()}
      </Flex>

      <PaymentsOnboardingModal
        show={showPaymentsOnboardingModal}
        onClose={_handleClosePaymentsOnboarding}
      />
    </>
  );
};

const features = [
  {
    name: 'Crypto-to-Fiat Payments',
    description: 'Pay in USD & EUR straight from your wallet.',
    icon: BanknotesIcon,
  },
  {
    name: 'Fiat-to-Crypto Payments',
    description: 'Settle your USDC & USDT bills with USD & EUR.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'On/Off-Ramp',
    description: 'Move money between stablecoins, USD, EUR & AED.',
    icon: ArrowsRightLeftIcon,
  },
];

const PaymentFeaturesDescription = () => {
  return (
    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-3">
      {features.map((feature) => (
        <div key={feature.name} className="flex flex-col">
          <dt className="flex items-center gap-x-2 text-base font-semibold leading-7 text-slate-200">
            <feature.icon className="h-6 w-6 flex-none text-indigo-400" />
            {feature.name}
          </dt>
          <dd className="mt-2 flex flex-auto flex-col text-sm leading-7 text-slate-400">
            <p className="flex-auto">{feature.description}</p>
          </dd>
        </div>
      ))}
    </dl>
  );
};
