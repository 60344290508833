import type { ThemeConfig } from 'antd';
import {
  indigo400,
  indigo500,
  indigo600,
  rose500,
  slate200,
  slate300,
  slate400,
  slate500,
  slate700,
  slate800,
  slate900,
} from '../tailwind/colors';

const makeImportant = (css: string) => `${css} !important`;

export const theme: ThemeConfig = {
  token: {
    colorIcon: slate200,
    colorIconHover: slate300,
  },
  components: {
    Modal: {
      contentBg: slate800,
      footerBg: slate800,
      headerBg: slate800,
      titleColor: slate200,
    },
    Form: {
      labelColor: slate300,
      colorIcon: slate300,
      colorPrimaryBorderHover: indigo500,
      colorBorder: '#4E6180',
      colorError: rose500,
      colorErrorText: rose500,
      colorTextDescription: slate500,
    },
    Checkbox: {
      colorBgContainer: '#18222d',
      colorBorder: '#4E6180',
      colorPrimaryHover: indigo500,
      colorPrimary: indigo500,
      colorText: slate300,
    },
    Menu: {
      // colorPrimaryBg: '#ec0000',
      // colorBgBase: '#ec0000',
    },
    Drawer: {
      colorBgElevated: slate800,
      colorText: slate300,
    },
    Dropdown: {
      // colorPrimaryBg: '#ec0000',
      // colorBgBase: '#ec0000',
      // colorBgContainer: '#ec0000',
      // colorPrimary: '#ec0000',
      // colorBorderBg: '#ec0000',
    },
    Select: {
      selectorBg: '#18222d',
      clearBg: '#18222d',
      optionSelectedColor: slate300,
      optionSelectedBg: '#1f2a3d',
      optionActiveBg: '#222d42',
      colorBorder: '#4E6180',
      colorPrimaryBorderHover: indigo500,
      colorPrimary: indigo500,
      colorBgContainer: '#18222d',
      colorText: slate300,
      colorBgElevated: '#18222d',
      colorTextPlaceholder: slate500,
      colorTextLabel: slate300,
      colorPrimaryHover: indigo500,
      colorTextQuaternary: '#4E6180',
      multipleItemBg: 'rgb(51 65 85 / 40%)',

      // did not work
      // colorPrimaryText: '#ec0000',
      // colorIcon: '#ec0000',
      // colorBgBlur: '#ec0000',
      // colorHighlight: '#ec0000',
      // colorBgContainerDisabled: '#ec0000',
      // colorBgBase: '#ec0000',
      // colorIconHover: '#ec0000',
      // colorFillContentHover: '#ec0000',
      // colorFillAlter: '#ec0000',
      // colorFillContent: '#ec0000',
      // controlItemBgHover: '#ec0000',
      // colorBgTextActive: '#ec0000',
      // colorBgTextHover: '#ec0000',
      // controlItemBgActiveHover: '#ec0000',
      // colorBorderBg: '#ec0000',
      // controlItemBgActive: '#ec0000',
      // multipleItemBorderColor: '#ec0000',
      // colorPrimaryTextActive: '#ec0000',
      // colorPrimaryBorder: '#ec0000',
      // colorPrimaryBg: '#ec0000',
      // colorPrimaryActive: '#ec0000',
    },
    Radio: {
      colorBorder: '#4E6180',
      colorPrimary: indigo400,
      colorPrimaryHover: indigo500,
      // buttonColor: indigo500,
      colorBgContainer: slate800,
      controlItemBgActive: slate800,
      colorBgTextActive: indigo500,
      colorPrimaryActive: indigo500,
      colorPrimaryTextActive: indigo500,
      buttonSolidCheckedColor: indigo500,
      colorText: slate300,
    },
    Input: {
      activeBg: makeImportant('#18222d'),
      colorBgBase: makeImportant('#18222d'),
      colorBgContainer: makeImportant('#18222d'),
      hoverBorderColor: makeImportant(indigo500),
      colorBorder: makeImportant('#4E6180'),
      activeBorderColor: makeImportant(indigo500),
      colorPrimaryHover: makeImportant(indigo500),
      colorIcon: slate300,
      colorPrimaryBorderHover: makeImportant(indigo500),
      colorError: makeImportant(rose500),
      colorErrorText: rose500,
      errorActiveShadow: 'rgba(244, 63, 94, 0.2) 0px 0px 0px 2px',
      activeShadow: 'rgba(99, 102, 241, 0.2) 0px 0px 0px 2px',
      colorText: slate300,
      colorTextPlaceholder: slate500,
      colorPrimary: slate300,
      colorTextDisabled: slate400,
      colorErrorBorder: makeImportant(rose500),
    },
    InputNumber: {
      activeBg: '#18222d',
      colorBgBase: '#18222d',
      hoverBg: '#18222d',
      hoverBorderColor: indigo500,
      colorBorder: '#4E6180',
      activeBorderColor: indigo500,
      colorErrorBorder: rose500,
      colorError: rose500,
      colorErrorText: rose500,
      errorActiveShadow: 'rgba(244, 63, 94, 0.2) 0px 0px 0px 2px',
      activeShadow: 'rgba(99, 102, 241, 0.2) 0px 0px 0px 2px',
      colorBgContainer: '#18222d',
      colorIcon: slate200,
      colorPrimary: slate200,
      colorText: slate300,
      colorTextDescription: '#4E6180',
    },
  },
};
