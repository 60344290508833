export const extractLastChar = (value: string, amount: number) =>
  value.substring(value.length - amount);

export const extractFirstChar = (value: string, amount: number) =>
  value.substring(0, amount);

export const isValidIban = (rawIban: string) => {
  const ibanRegex =
    /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/;
  return ibanRegex.test(rawIban);
};

export function isValidEmail(email: string) {
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(email);
}

export const ethereumWalletRegex = /^(0x)?[0-9a-fA-F]{40}$/;

// export const formatDollarAmount = (amount, opt = {}) => {
//   return Number(amount).toLocaleString('en-US', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: opt.maximumFractionDigits || 5,
//   });
// };

export const formatDollarAmount = (
  amount: string,
  opt: { maximumFractionDigits?: number } = {}
) => {
  return Number(amount).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: opt.maximumFractionDigits || 5,
  });
};
