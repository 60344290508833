export class AbstractPaymentProvidersEvaluatorStrategy {
  /**
   * @type {PaymentProvidersConfig}
   * @protected
   * */
  paymentProvidersConfig;
  /**
   * @type {PlatformIntegrations}
   * @protected
   * */
  platformIntegrations;

  /**
   * @type {OrgSettings}
   * @protected
   * */
  organizationSettings;

  constructor(
    paymentProvidersConfig,
    platformIntegrations,
    organizationSettings
  ) {
    this.paymentProvidersConfig = paymentProvidersConfig;
    this.platformIntegrations = platformIntegrations;
    this.organizationSettings = organizationSettings;
  }

  /**
   * @param {object} paymentProvider
   * @public
   * @returns {'eligible' | 'requiresCustomerServiceOnboarding'}
   */
  getProviderEligibilityStateForPayout(paymentProvider) {
    if (
      this.platformIntegrations.isPaymentProviderRequiresCSOnboarding(
        paymentProvider
      )
    ) {
      return 'requiresCustomerServiceOnboarding';
    }

    return 'eligible';
  }

  isBatchPayout() {
    throw new Error('Not implemented');
  }

  getPayouts() {
    throw new Error('Not implemented');
  }

  isProviderEligibleForPayout() {
    throw new Error('Not implemented');
  }

  getAllPossiblePaymentProviders() {
    throw new Error('Not implemented');
  }
}
