import { useQuery } from "@tanstack/react-query";
import { useLinkActions } from "./actions";
import { useCurrentUser } from "entities/user/model";
import { QUERY_KEY } from "./constants";

export const useLinks = () => {
  const { fetchAllAction } = useLinkActions();
  const { data } = useCurrentUser();

  return useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => fetchAllAction(),
    enabled: !!data.nickname,
  });
};
