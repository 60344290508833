import axios from 'axios';
import { useCallback } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/organisation/clients';

const fetchClients = async (getToken, organizationId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/list`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useInvalidateOrganizationClients = () => {
  const queryClient = useQueryClient();

  const invalidateOrganizationClients = useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: ['organization-clients'],
    });
  }, [queryClient]);

  return {
    invalidateOrganizationClients,
  };
};

export const useOrganizationClients = () => {
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const {
    data: clientsData,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ['organization-clients'],
    queryFn: () => fetchClients(getToken, getCurrentOrganizationId()),
    enabled: hasAnyOrganization,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });

  return {
    clients: clientsData?.result || [],
    isFetching,
    isLoading,
  };
};
