import _ from 'lodash';

import { PayoutTypes } from './PayoutDetails';

type TPaymentsConfig = {
  providers: {
    // eslint-disable-next-line no-restricted-globals
    name: string;
    id: string;
    isOnrampMethod: boolean;
    enabled: boolean;
    batchAvailable: boolean;
    onboardingType: 'customer-service';
    availableDepositCurrencies?: ('USD' | 'EUR')[];
    availableDepositNetworks?: ('Ethereum' | 'Polygon' | 'Base')[];
    suitableForCurrencies: ('USDC' | 'USDT' | 'EUR' | 'USD')[];
    suitableForCryptoNetworks?: ('Polygon' | 'Ethereum' | 'Base')[];
    suitableForPayouts: string[];
  }[];
};

export const paymentsConfig: TPaymentsConfig = {
  providers: [
    {
      name: 'Pay with Fiat Currency',
      id: 'payso-onramp',
      isOnrampMethod: true,
      enabled: true,
      onboardingType: 'customer-service',
      batchAvailable: false,
      availableDepositCurrencies: ['USD', 'EUR'],
      suitableForCurrencies: ['USDC', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
    {
      name: 'Pay with Fiat Currency',
      id: 'bridge-onramp',
      isOnrampMethod: true,
      enabled: true,
      onboardingType: 'customer-service',
      batchAvailable: false,
      availableDepositCurrencies: ['USD'],
      suitableForCurrencies: ['USDC', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum', 'Base'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
    {
      name: 'Settle With Crypto',
      id: 'bridge-offramp',
      enabled: true,
      isOnrampMethod: false,
      onboardingType: 'customer-service',
      batchAvailable: false,
      suitableForCurrencies: ['EUR', 'USD'],
      availableDepositNetworks: ['Ethereum', 'Polygon', 'Base'],
      suitableForPayouts: [PayoutTypes.BankTransfer],
    },
    {
      name: 'Pay with Fiat Currency',
      id: 'easy-pay-onramp',
      isOnrampMethod: true,
      enabled: true,
      onboardingType: 'customer-service',
      batchAvailable: false,
      availableDepositCurrencies: ['USD', 'EUR'],
      suitableForCurrencies: ['USDC', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
    {
      name: 'Pay with Fiat Currency',
      id: 'red-envelope-onramp',
      enabled: true,
      isOnrampMethod: true,
      batchAvailable: false,
      onboardingType: 'customer-service',
      availableDepositCurrencies: ['USD'],
      suitableForCurrencies: ['USDC', 'USDT'],
      suitableForCryptoNetworks: ['Polygon', 'Ethereum'],
      suitableForPayouts: [PayoutTypes.CryptoTransfer],
    },
  ],
};

class PaymentsConfig {
  private readonly config: TPaymentsConfig;

  constructor(config: TPaymentsConfig) {
    this.config = config;
  }

  get(path: string, fallback: any) {
    return _.get(this.config, path, fallback);
  }
}

export const getTransferPaymentsConfig = () => {
  return new PaymentsConfig(paymentsConfig);
};
