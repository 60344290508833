import { useEffect, useState } from 'react';
import axios from 'axios';
import { SpinnerIcon } from 'shared/ui/Spinner';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';

export const CheckWalletAmlWidget = ({ bill }) => {
  const [walletAmlStatus, setWalletAmlStatus] = useState('');
  const [amlMessage, setAmlMessage] = useState(null);
  const [walletAmlStatusIsLoading, setWalletAmlStatusIsLoading] =
    useState(false);

  const checkWalletAmlStatus = async () => {
    try {
      setWalletAmlStatusIsLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_SERVER_URL + '/aml/check-wallet',
        {
          params: {
            address: bill.walletAddress,
          },
        }
      );

      if (response.data.message === 'High Risk') {
        setWalletAmlStatus('High Risk');
        setAmlMessage(response.data.data[0].name);
      } else if (response.data.message === 'Low Risk') {
        setWalletAmlStatus('Low Risk');
      }

      setWalletAmlStatusIsLoading(false);
    } catch (err) {
      setWalletAmlStatusIsLoading(false);
    }
  };

  useEffect(() => {
    checkWalletAmlStatus();
  }, []);

  return (
    <div className="grid grid-cols-2 grid-rows-2 items-center py-2 text-left text-sm font-medium ">
      <span className="text-slate-300">Wallet Address AML</span>
      <span className="flex items-center justify-end ">
        {walletAmlStatusIsLoading ? (
          <SpinnerIcon className="mr-3" />
        ) : walletAmlStatus === 'High Risk' ? (
          <span className="font-medium text-red-500">
            <ExclamationCircleIcon className="mr-1 inline h-4 w-4" />
            High Risk
          </span>
        ) : (
          <span className="font-medium text-green-400">
            <CheckCircleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
            Low Risk
          </span>
        )}
      </span>
      <div className="col-span-2 text-xs text-gray-500">
        {walletAmlStatusIsLoading ? (
          <span>Loading...</span>
        ) : walletAmlStatus === 'High Risk' ? (
          <span className="text-xs font-normal text-red-400/80">
            {amlMessage} <br />
            Your wallet could be flagged if you send funds to this address.
          </span>
        ) : (
          <span className="font-normal text-slate-400">
            No evidence of this wallet address being on a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-blue-500"
              href="https://sanctionssearch.ofac.treas.gov/">
              SDN Sanctions List
            </a>
            .
          </span>
        )}
      </div>
    </div>
  );
};
