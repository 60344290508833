import dayjs from 'dayjs';
import SlideOver from 'shared/ui/SlideOver';
import { Spinner } from 'shared/ui/Spinner';
import { WithOrgPermissions } from 'shared/ui/WithOrgPermissions';
import { DetailSection } from 'shared/components/DetailSection';
import { useDialog } from 'shared/components/Dialog';
import {
  getTokenHumanReadableNameByChain,
  upperCaseNetworkType,
} from 'features/bill/lib';
import { toast } from 'react-toastify';
import { tokenToImage } from 'shared/lib';
import classNames from 'classnames';
import { useRecurringInvoiceDetails } from './useRecurringInvoices';
import { formatDollarAmount } from '../../../shared/lib/string';
import { Badge } from 'flowbite-react';

const paymentStatusCopyMap = {
  paid: 'Paid',
  unpaid: 'Not Paid',
  processingPayment: 'Processing Payment',
};

export const RecurringInvoiceSlideOver = ({ show, ruleId, onClose }) => {
  const {
    data: recurrentRule,
    isLoading,
    deleteRecurrence,
  } = useRecurringInvoiceDetails(ruleId);
  const dialog = useDialog();

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    const confirmationText =
      'Are you sure you want to delete this recurrent invoice? ';

    dialog.confirm({
      title: 'Delete Recurrent Invoice',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await deleteRecurrence({
            ruleId,
          });
          toast.success('Recurrent Invoice Deleted!');
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return (
    <SlideOver show={show} onClose={handleClose}>
      {recurrentRule && !isLoading ? (
        <>
          <SlideOver.Header>
            <SlideOver.Title>
              <h2>Recurrent Invoice</h2>
            </SlideOver.Title>
          </SlideOver.Header>

          <div className="relative mb-40 flex-1 space-y-6 px-6 pt-6">
            <DetailSection header="General">
              <DetailSection.Item term="Receiver Name">
                {recurrentRule?.receiverName}
              </DetailSection.Item>

              <DetailSection.Item term="Receiver Email">
                {recurrentRule?.receiverEmail}
              </DetailSection.Item>

              <DetailSection.Item term="Payment Amount">
                {tokenToImage(recurrentRule?.currency)}
                <span>
                  {formatDollarAmount(recurrentRule?.totalAmount)}{' '}
                  {getTokenHumanReadableNameByChain(
                    recurrentRule?.currency,
                    recurrentRule?.paymentNetwork
                  )}
                </span>
              </DetailSection.Item>

              <DetailSection.Item term="Network">
                <span>
                  {recurrentRule?.paymentNetwork
                    ? upperCaseNetworkType(recurrentRule?.paymentNetwork)
                    : ''}
                </span>
              </DetailSection.Item>

              <DetailSection.Item term="Status">
                <div className="flex flex-col">
                  <Badge
                    className={classNames('inline', {
                      '!bg-slate-700 !text-emerald-400':
                        recurrentRule.status === 'active',
                      '!bg-slate-700 !text-indigo-300':
                        recurrentRule.status === 'expired',
                      '!bg-slate-700 !text-slate-200':
                        recurrentRule.status === 'cancelled',
                    })}>
                    {recurrentRule.status === 'active' && 'Active'}
                    {recurrentRule.status === 'expired' && 'Fulfilled'}
                    {recurrentRule.status === 'cancelled' && 'Cancelled'}
                  </Badge>
                </div>
              </DetailSection.Item>
            </DetailSection>

            <DetailSection header={`Issued Invoices Overview`}>
              <table className="w-[100%] bg-[#232e40]">
                <thead className="text-sm  text-slate-300">
                  <tr className="">
                    <th className="py-2 pl-3 text-left font-normal">
                      Issue Date
                    </th>
                    <th className="text-center font-normal">Due Date</th>
                    <th className="pr-3 font-normal">Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {recurrentRule.invoices.map((invoice) => (
                    <tr key={invoice.id} className=" text-sm text-slate-300">
                      <td className=" py-2 pl-3 text-left font-normal">
                        {dayjs(invoice.issueDate).format('YYYY-MM-DD')}
                      </td>
                      <td className="text-center">
                        {invoice.dueDate
                          ? dayjs(invoice.dueDate).format('YYYY-MM-DD')
                          : ''}
                      </td>
                      <td className=" pr-3">
                        <span
                          className={classNames(
                            'rounded  px-2 py-0.5 text-white',
                            {
                              'bg-emerald-600':
                                invoice.paymentStatus === 'paid',
                              'bg-slate-500':
                                invoice.paymentStatus === 'unpaid',
                            }
                          )}>
                          {paymentStatusCopyMap[invoice.paymentStatus]}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </DetailSection>

            <WithOrgPermissions>
              <DetailSection header="Other Actions">
                <DetailSection.Item term="Delete Recurrent Invoice">
                  <button
                    onClick={handleDelete}
                    type="button"
                    className="font-medium text-rose-500  hover:text-rose-400">
                    Delete
                  </button>
                </DetailSection.Item>
              </DetailSection>
            </WithOrgPermissions>
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    </SlideOver>
  );
};
