import React, { useEffect, useState } from 'react';
import { CodatLink } from '@codat/link-sdk';
import '@codat/link-sdk/index.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCurrentUser, useCurrentUserDetails } from 'entities/user/model';

import { Synaps } from '@synaps-io/verify-sdk';
import { useQuickBooksAuthorise } from '../../features/quickBooksOnline/useQuickBooksAuthorise';
import { Button } from '../../shared/ui/Button/Button';
import { features, useFeatureFlags } from '../../shared/hooks';

const CREATE_COMPANY_ENDPOINT =
  process.env.REACT_APP_SERVER_URL + '/codat/create-company';
const UPDATE_CONNECTION_ENDPOINT =
  process.env.REACT_APP_SERVER_URL + '/codat/update-connection';

const useEasyPayKyc = () => {
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { hasEasyPayIntegration, getCurrentOrganizationId } =
    useCurrentUserDetails();
  const kycQuery = useQuery({
    queryKey: ['monerium-balances'],
    queryFn: async () => {
      const token = await getToken();

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/public/easypay/kyc`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              organizationId: getCurrentOrganizationId(),
            },
          }
        );
        return response.data?.result;
      } catch (error) {
        console.log(error);
      }
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
    enabled: hasEasyPayIntegration() && !!getCurrentOrganizationId(),
  });

  return {
    kyc: kycQuery.data,
  };
};

const Codat = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { data } = useCurrentUser();
  const { getAccessTokenSilently } = useAuth0();
  const { kyc } = useEasyPayKyc();
  const {
    authUri: quickBooksAuthUri,
    isAuthorisationPending,
    authorise,
  } = useQuickBooksAuthorise();

  const getInitialState = (key) =>
    data?.selected_organization?.integrations?.[0]?.codat?.[key] || '';

  const [companyId, setCompanyId] = useState(getInitialState('id'));
  const [connectionId, setConnectionId] = useState(
    getInitialState('connectionId')
  );
  const [connected, setConnected] = useState(Boolean(connectionId));
  const [showCodatLink, setShowCodatLink] = useState(false);

  useEffect(() => {
    setCompanyId(getInitialState('id'));
    setConnectionId(getInitialState('connectionId'));
    setConnected(Boolean(connectionId));
  }, [data.selected_organization]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && kyc?.ongoingSessionId) {
      Synaps.init({
        sessionId: kyc?.ongoingSessionId,
        service: 'corporate',
        onFinish: () => {
          // hide button, discard kyc state
        },
        mode: 'modal',
      });
    }

    return () => {
      ignore = true;
    };
  }, [kyc?.ongoingSessionId]);

  useEffect(() => {
    if (quickBooksAuthUri) {
      window.location.href = quickBooksAuthUri;
    }
  }, [quickBooksAuthUri]);

  console.log(quickBooksAuthUri);

  const handleAccountingConnect = async () => {
    try {
      const token = await getAccessTokenSilently();
      const { data: response } = await axios.post(
        CREATE_COMPANY_ENDPOINT,
        {
          name: data?.selected_organization?.name,
          email: data?.selected_organization?.email,
          id: data?.selected_organization?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCompanyId(response.response.id);
      setShowCodatLink(true);
    } catch (error) {
      console.error('Error connecting to accounting', error);
    }
  };

  const handleUpdateConnection = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      const { data: response } = await axios.post(
        UPDATE_CONNECTION_ENDPOINT,
        {
          id: data?.selected_organization?._id,
          connectionId: id.connectionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setConnectionId(response.organization.integrations[0].codat.connectionId);
      setShowCodatLink(false);
      setConnected(true);
    } catch (error) {
      console.error('Error updating connection', error);
    }
  };

  const isEmptyOrganization = !Object.keys(data?.selected_organization)?.length;

  const renderEasyPayIntegration = () => {
    if (kyc && kyc.ongoingSessionId) {
      return (
        <div className="mt-10">
          <div className="bg-white  shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-white ">
                  Crypto to Fiat Integration
                </h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                  Finish KYC process in order to start using offramp
                </p>
              </div>
              <div className="mt-5 sm:overflow-hidden sm:rounded-md md:col-span-2 md:mt-0">
                <Button
                  size="sm"
                  color="indigo"
                  isProcessing={isAuthorisationPending}
                  disabled={isAuthorisationPending}
                  onClick={() => {
                    Synaps.show();
                  }}>
                  Initiate KYC session
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderQuickBooksIntegration = () => {
    if (!isFeatureEnabled(features.QUICK_BOOKS_ONLINE)) {
      return null;
    }

    return (
      <div className="mt-10">
        <div className="bg-white  shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-white ">
                Quick Books Online Integration
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                Connect to your QuickBooks or Xero to easily sync your bills and
                invoices.
              </p>
            </div>
            <div className="mt-5 flex items-center justify-end sm:rounded-md md:col-span-2 md:mt-0">
              <Button
                size="sm"
                color="indigo"
                isProcessing={isAuthorisationPending || !!quickBooksAuthUri}
                disabled={isAuthorisationPending || !!quickBooksAuthUri}
                onClick={() => {
                  authorise();
                }}>
                Connect Quick Books Online
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <div className="mt-10 sm:mt-0">
        <div className="bg-white  shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-white ">
                Accounting Integrations
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                Connect to your QuickBooks or Xero to easily sync your bills and
                invoices.
              </p>
            </div>
            <div className="mt-5 sm:overflow-hidden sm:rounded-md md:col-span-2 md:mt-0">
              <div className="space-y-6 bg-white  dark:bg-slate-800 ">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12 sm:col-span-12">
                    <h2 className="block pb-2 text-sm font-medium leading-6 text-white">
                      Connect Your Accounting Platform
                    </h2>
                    {connected && (
                      <div className="text-white">
                        Your accounting software is connected.
                        <br />
                        If you just got connected, your data may take a few
                        minutes to sync.
                      </div>
                    )}

                    {!connected && !companyId && (
                      <div className="bg-gray-50 text-left dark:bg-slate-800">
                        {isEmptyOrganization || companyId ? (
                          <Button size="sm" color="indigo" disabled>
                            Add Organisation Above To Connect To Accounting
                            Software
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            color="indigo"
                            onClick={(e) => {
                              e.preventDefault();
                              handleAccountingConnect();
                            }}>
                            Connect Now
                          </Button>
                        )}
                      </div>
                    )}

                    {(showCodatLink || companyId) && !connected && (
                      <div className="text-black">
                        <CodatLink
                          companyId={companyId}
                          onConnection={(id) => {
                            handleUpdateConnection(id);
                            setConnected(true);
                          }} // Called each time a connection is established
                          onFinish={() => setShowCodatLink(false)} // Called when the flow is completed
                          onClose={() => setShowCodatLink(false)} // Called when the user clicks 'X' or completes the whole flow
                          onError={(err) => alert('Error: ' + err)} // Called when an error is reached
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderEasyPayIntegration()}

      {renderQuickBooksIntegration()}
    </div>
  );
};

export default Codat;
