import { Alert } from 'shared/ui';
import { useState, useEffect } from 'react';

export const PayeeInformationAlert = ({ formValues }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (
      formValues.fiat_payment &&
      (formValues.youraddress === '' ||
        formValues.yourcity === '' ||
        formValues.yourcountry === '' ||
        formValues.yourpostcode === '')
    ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [formValues]);

  return (
    showAlert && (
      <div className="col-span-6 sm:col-span-6">
        <Alert type={'warning'}>
          <div className="space-y-0.5 text-sm text-slate-300">
            <p className="text-amber-300">
              Your personal details are required to create a fiat bill.
            </p>
            <p>Please input your address, city, country, and zip code above.</p>
          </div>
        </Alert>
      </div>
    )
  );
};
