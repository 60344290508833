import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { createLink } from '@meshconnect/web-link-sdk';

const clientId = '3512f4a3-188d-4d41-0094-08dc31a618a9';

const initMeshTransfer = async (token, payouts) => {
  const res = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/public/mesh/init-crypto-transfer`,
    {
      amount: payouts[0].amountNumber, // FIX when we support multi trx
      recipients: payouts.map((payout) => ({
        currency: payout.currency,
        network: payout.network,
        walletAddress: payout.walletAddress,
      })),
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return new Promise((resolve, reject) => {
    const link = createLink({
      clientId,
      onTransferFinished: (payload) => {
        if (payload.status === 'success') {
          resolve(payload);
        } else if (payload.status === 'error') {
          reject(payload);
        }
      },
      onExit: () => {
        resolve('exit');
      },
    });

    return link.openLink(res.data.result.content.linkToken);
  });
};

export const useMeshTransfer = ({ onTransferSuccess }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { mutateAsync, isPending, isError, reset, isSuccess } = useMutation({
    mutationKey: ['mesh-transfer'],
    mutationFn: ({ payouts }) =>
      getAccessTokenSilently()
        .then((token) => initMeshTransfer(token, payouts))
        .then((res) => (res === 'exit' ? null : onTransferSuccess(res))),
  });

  return {
    initiateMeshTransfer: mutateAsync,
    isLoading: isPending,
    isError,
    reset,
    isSuccess,
  };
};
