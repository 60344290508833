import NotFoundPage from "pages/404";
import LegalPrivacyPage from "pages/LegalPrivacyPage";
import LegalTermsPage from "pages/LegalTermsPage";
import LoginPage from "pages/LoginPage";
import AuthPage from "pages/AuthPage";
import BillUpdateStatusPage from "pages/bill/BillUpdateStatusPage";
import { Route, Routes } from "react-router-dom";
import BillPDFViewever from "widgets/Bills/BillPDFViewever";
import InvoicePDFViewer from "widgets/Invoices/InvoicePDFViewer";
import DashboardPublicGeneratePaymentRequest from "./DashboardPublicGeneratePaymentRequest";
import DashboradPublicInvoicePayment from "./DashboradPublicInvoicePayment";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/auth" element={<AuthPage />} />

      <Route path="/pay/*" element={<DashboradPublicInvoicePayment />} />
      <Route
        path="/links/*"
        element={<DashboardPublicGeneratePaymentRequest />}
      />

      <Route
        path="/public/update-bill-status/*"
        element={<BillUpdateStatusPage />}
      />
      <Route path="/public/invoices/*" element={<InvoicePDFViewer />} />
      <Route path="/public/bills/invoices/*" element={<BillPDFViewever />} />

      <Route path="/terms" element={<LegalTermsPage />} />
      <Route path="/privacy" element={<LegalPrivacyPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
