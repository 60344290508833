import React from 'react';
import { DetailSection } from 'shared/components/DetailSection';

const BankDetails = ({ bill }) => {
  const bankAccountDetails = [
    { key: 'accountBeneficiary', term: 'Account Beneficiary' },
    { key: 'accountIBAN', term: 'IBAN' },
    { key: 'bankBIC', term: 'Bank BIC' },
    { key: 'accountNumber', term: 'Account Number' },
    { key: 'accountACHRoutingNumber', term: 'Routing Number' },
    { key: 'bankName', term: 'Bank Name' },
    { key: 'bankAccountBeneficiaryType', term: 'Beneficiary Type' },
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      {bill?.network === 'Fiat' && (
        <DetailSection header="Payment Details">
          {bankAccountDetails.map(({ key, term }) => {
            let value = bill?.invoice?.paymentDetails?.bankAccount?.[key];

            // Capitalize the first letter if the key is bankAccountBeneficiaryType
            if (key === 'bankAccountBeneficiaryType' && value) {
              value = capitalizeFirstLetter(value);
            }

            return (
              value && (
                <DetailSection.Item term={term} key={key}>
                  <span>{value}</span>
                </DetailSection.Item>
              )
            );
          })}
        </DetailSection>
      )}
    </>
  );
};

export default BankDetails;
