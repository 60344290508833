import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useCurrentUserDetails } from 'entities/user/model';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import userIcon from 'shared/images/user.jpeg';
import { classNames } from 'shared/lib';
import LogoutButton from 'widgets/Authentication/LogoutButton';
import { WithOrgPermissions } from 'shared/ui/WithOrgPermissions';
import { useWeb3Options } from 'widgets/Users/UserBusinessTypeSetting';
import DMFounderButton from './DMFounderButton';
import { Badge } from 'flowbite-react';

export const Header = ({ navigation }) => {
  const { data, currentOrganization, hasLoggedInUser, getCurrentOrgRole } =
    useCurrentUserDetails();
  const { web3OptionsEnabled } = useWeb3Options();

  const shouldRenderConnectButton = () => web3OptionsEnabled;

  return (
    <div className="flex flex-1 justify-end px-4">
      <div className="ml-4 flex items-center lg:ml-6">
        {shouldRenderConnectButton() && (
          <WithOrgPermissions>
            <div href="/metamask" className="flex items-center">
              <ConnectButton accountStatus="address" />
            </div>
          </WithOrgPermissions>
        )}

        <DMFounderButton />

        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-6">
          {hasLoggedInUser && (
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={userIcon}
                alt="User icon"
              />
              <span className="flex items-center">
                <span
                  className="ml-4 text-xs md:text-sm font-semibold capitalize leading-6 text-white"
                  aria-hidden="true">
                  {data.nickname}{' '}
                  {!!currentOrganization?.name &&
                    `(${currentOrganization.name})`}
                  <Badge
                    className={
                      'hidden md:inline !bg-slate-700 ml-2 !text-indigo-300'
                    }>
                    {getCurrentOrgRole()}
                  </Badge>
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 z-10 mt-2.5 min-w-[120px] origin-top-right rounded-md bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              {navigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      className={classNames(
                        active ? 'bg-gray-700' : '',
                        'flex px-4 py-2 text-sm text-gray-300'
                      )}>
                      {item.name === 'Sign out' ? (
                        <LogoutButton className="w-auto" />
                      ) : (
                        item.name
                      )}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
