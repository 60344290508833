import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Loading from "shared/ui/Loading";

async function downloadInvoice(id) {
  try {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_URL + `/bills/download-invoice?id=${id}`,
      {
        responseType: "arraybuffer",
      }
    );

    let contentType = res.headers["content-type"];
    if (!contentType || contentType === "undefined") {
      contentType = "application/pdf";
    }

    const buffer = new Uint8Array(res.data);
    const blob = new Blob([buffer], { type: contentType });
    const url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    console.log(error);
    throw new Error("Error while downloading invoice");
  }
}

function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

function BillPDF() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchInvoice() {
      setIsLoading(true);
      try {
        const url = await downloadInvoice(id);
        setPdfUrl(url);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchInvoice();
  }, [id]);

  if (isLoading) {
    return (
      <div className="grid h-screen place-items-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return null;
  }

  if (!pdfUrl) {
    return null;
  }

  return (
    <div>
      {isMobileDevice() ? (
        <div className="flex h-screen items-center justify-center">
          <button
            onClick={() => window.open(pdfUrl, "_blank")}
            className=" rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
            Open PDF
          </button>
        </div>
      ) : (
        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="1000px">
          <embed src={pdfUrl} type="application/pdf" />
        </object>
      )}
    </div>
  );
}

export default BillPDF;
