import { useMemo } from 'react';
import { useWalletClient, useAccount } from 'wagmi';
import { providers } from 'ethers';

const _walletClientToSigner = (walletClient) => {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
};

export const useEthersSigner = () => {
  const { chain } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: chain?.id });
  return useMemo(
    () =>
      walletClient && chain?.id
        ? _walletClientToSigner(walletClient)
        : undefined,
    [walletClient, chain?.id]
  );
};
