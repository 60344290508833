import { AbstractPaymentProvidersEvaluatorStrategy } from './AbstractPaymentProvidersEvaluatorStrategy';
import { PayoutTypes } from './PayoutDetails';
import { createPaymentProvider } from './PaymentProvider';

export class CryptoPaymentProvidersEvaluatorStrategy extends AbstractPaymentProvidersEvaluatorStrategy {
  #cryptoPayouts;

  constructor(
    paymentProvidersConfig,
    cryptoPayouts,
    platformIntegrations,
    organizationSettings
  ) {
    super(paymentProvidersConfig, platformIntegrations, organizationSettings);

    this.#cryptoPayouts = cryptoPayouts;
  }

  isBatchPayout() {
    return this.#cryptoPayouts.length > 1;
  }

  getPayouts() {
    return this.#cryptoPayouts;
  }

  _getConfiguredProviders() {
    const configuredProvidersAll = this.paymentProvidersConfig.get(
      'providers',
      []
    );

    const preferredOnrampMethod =
      this.organizationSettings.getPreferredOnrampMethod();
    const configuredOnrampProviders = configuredProvidersAll
      .filter((provider) => provider.isOnrampMethod)
      .filter(({ id }) => {
        const _methodIdMap = {
          payso: 'payso-onramp',
          easy_pay: 'easy-pay-onramp',
          red_envelope: 'red-envelope-onramp',
          bridge: 'bridge-onramp',
        };
        return id === _methodIdMap[preferredOnrampMethod];
      });

    const configuredProvidersRest = configuredProvidersAll.filter(
      (provider) => !provider.isOnrampMethod
    );

    return [...configuredProvidersRest, ...configuredOnrampProviders];
  }

  getAllPossiblePaymentProviders() {
    if (this.isPayoutEligible()) {
      const configuredProviders = this._getConfiguredProviders();

      return configuredProviders
        .filter((providerConfig) => {
          const isSuitableForPayouts =
            providerConfig.enabled &&
            providerConfig.suitableForPayouts?.includes(
              PayoutTypes.CryptoTransfer
            ) &&
            this.#cryptoPayouts.every((p) =>
              providerConfig.suitableForCurrencies?.includes(p.currency)
            ) &&
            this.#cryptoPayouts.every((p) =>
              providerConfig.suitableForCryptoNetworks?.includes(p.network)
            );

          if (this.isBatchPayout()) {
            return isSuitableForPayouts && providerConfig.batchAvailable;
          }

          return isSuitableForPayouts;
        })
        .map((providerConfig) => createPaymentProvider(providerConfig));
    }

    return [];
  }

  isPayoutEligible() {
    if (
      this.#cryptoPayouts.length &&
      this.#cryptoPayouts.every(
        (payout) =>
          payout.network === this.#cryptoPayouts[0].network &&
          payout.currency === this.#cryptoPayouts[0].currency
      )
    ) {
      return true;
    }

    return false;
  }
}
