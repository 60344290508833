import EmptyInvoicesComponent from 'widgets/Invoices/EmptyInvoicesComponent.jsx';
import { useInvoiceMutations } from 'entities/invoice/model';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { Table } from 'shared/ui/Table/TableBlocks';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDialog } from 'shared/components/Dialog';
import { formatDataForCSV } from 'entities/invoice/lib';
import { InvoiceDetailsSlideOver } from './ui/InvoiceDetailsSlideOver';
import { CSVLink } from 'react-csv';
import {
  XCircleIcon,
  DocumentIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { tokenToImageV1 } from 'shared/lib';
import { Badge } from 'flowbite-react';
import classnames from 'classnames';
import { formatDollarAmount } from 'shared/lib/string';
import { useCurrentUserDetails } from 'entities/user/model';
import { ROLES } from 'shared/hooks';
import { getTokenHumanReadableNameByChain } from '../bill/lib';
import { PageHeader } from '../../shared/ui/layouts/PageHeader';
import { UpdateInvoicePaymentStatusModal } from './UpdatePaymentStatusModal';
import { useOrganizationInvoices } from './useOrganizationInvoices';
import { useCustomers } from '../quickBooksOnline/useCustomers';
import { customFlowBiteTheme, TabItem, Tabs } from '../../shared/ui/Tabs/Tabs';
import { RecurringInvoicesPage } from './recurring/RecurringInvoicesPage';

export const InvoicesPage = () => {
  useCustomers();

  const {
    invoices,
    invoicesTotal,
    isLoading,
    isFetching,
    hasFilterOptions,
    updateQueryOptions,
    queryOptions,
  } = useOrganizationInvoices();
  const dialog = useDialog();
  const navigate = useNavigate();
  const [viewInvoiceDetails, setViewInvoiceDetails] = useState(null);
  const { remove } = useInvoiceMutations();
  const { getCurrentOrgRole } = useCurrentUserDetails();
  const [invoiceToUpdatePaymentStatus, setInvoiceToUpdatePaymentStatus] =
    useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  const today = new Date().toISOString().slice(0, 10);
  const csvExportData = formatDataForCSV(
    Object.keys(rowSelection).map((idx) => invoices[idx])
  );

  const _formSecondaryActionList = () => {
    const res = [];

    if (!!Object.keys(rowSelection).length) {
      res.push({
        label: (
          <CSVLink data={csvExportData} filename={today + '-invoices.csv'}>
            Export to CSV
          </CSVLink>
        ),
        icon: () => <DocumentIcon className="w-6 mr-2" />,
      });

      res.push({
        label: <span className="text-rose-500">Delete Selected Invoices</span>,
        icon: () => <XCircleIcon className="text-rose-500 w-6 mr-2" />,
        onClick: () => handleBatchDelete(),
      });
    }

    return res;
  };

  const _handlePaginationChange = (state) => {
    updateQueryOptions({
      pageNumber: state.pageIndex + 1,
      pageLimit: state.pageSize,
    });
  };

  const _handleTableSearchChange = (change) => {
    updateQueryOptions({
      generalSearchString: change.searchString,
    });
  };

  const handleBatchDelete = useCallback(async () => {
    const confirmationText =
      'Are you sure you want to delete this invoices(s)? This action cannot be undone and you and your team members will not able to see this invoice anymore.';

    dialog.confirm({
      title: 'Delete Invoice(s)',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          setDeleteLoading(true);
          await Promise.all(
            Object.keys(rowSelection).map((idx) =>
              remove.mutate(invoices[idx]._id)
            )
          );
        } catch (error) {
          console.error(error);
        } finally {
          setRowSelection({});
          setDeleteLoading(false);
        }
      },
    });
  }, [dialog, rowSelection, remove, invoices]);

  const _getRowActions = (row) => {
    const actions = [];

    actions.push({
      label: 'Update Payment Status',
      onClick: () => {
        setInvoiceToUpdatePaymentStatus(row);
      },
    });

    return actions;
  };

  const columns = useMemo(() => {
    const res = [
      {
        id: 'selection',
      },
      {
        header: 'Client Name',
        cell: ({ row: { original: invoice } }) => (
          <div className="flex flex-col text-left">
            <span className="text-sm text-white font-medium">
              {invoice.client_name}
            </span>
            <span className="mt-0.5 flex items-center text-xs font-normal text-slate-400">
              {invoice.recurrenceRuleId && (
                <ArrowPathIcon className="mr-1 w-4 " />
              )}
              {invoice.client_email}
            </span>
          </div>
        ),
      },
      {
        header: 'Amount',
        cell: ({ row: { original: invoice } }) => (
          <>
            <div className="flex items-center">
              <span className="mr-1">{tokenToImageV1(invoice.token)}</span>
              <span className="mr-2 dark:text-white">
                {formatDollarAmount(invoice.amount)}
              </span>
            </div>

            <div className="leading-none">
              <span className="text-[10px] xl:text-xs">
                {getTokenHumanReadableNameByChain(
                  invoice.token,
                  invoice.network
                )}{' '}
                {' / ' + invoice.network}
              </span>
            </div>
          </>
        ),
      },
      {
        header: 'Due Date',
        cell: ({ row: { original: invoice } }) => (
          <span>{dayjs(invoice.payments_date).format('DD MMM YY')}</span>
        ),
      },
      {
        header: 'Accounting Status',
        cell: ({ row: { original: invoice } }) => (
          <>
            <ReconciliationCell invoice={invoice} />
          </>
        ),
      },
      {
        header: 'Status',
        cell: ({ row: { original: inv } }) => <StatusCell invoice={inv} />,
      },
      {
        header: 'actions',
      },
    ];

    if (getCurrentOrgRole() === ROLES.ACCOUNTANT) {
      res.splice(5, 0, {
        header: 'Invoice ID',
        cell: ({ row: { original: invoice } }) => (
          <InvoiceReference invoice={invoice} />
        ),
      });
    }

    return res;
  }, []);

  if (isLoading) {
    return <ContentLoadingState />;
  }

  return (
    <ContentCotainer className="invoice-page-container">
      {!hasFilterOptions() && invoices.length === 0 && !isFetching ? (
        <EmptyInvoicesComponent />
      ) : (
        <>
          <PageHeader
            title="Payments In"
            subtitle="Overview of your paid & unpaid invoices"
          />

          <Table
            primaryActionText="Add New Invoice"
            onSearchChange={_handleTableSearchChange}
            onPrimaryAction={() => {
              navigate('/dashboard/create-invoice');
            }}
            manualPagination
            data={invoices}
            columns={columns}
            isDataLoading={isFetching || deleteLoading}
            rowSelection={rowSelection}
            onRowSelectionChange={setRowSelection}
            onRowClick={setViewInvoiceDetails}
            onPaginationChange={_handlePaginationChange}
            getRowActions={_getRowActions}
            initPagination={{
              pageIndex: queryOptions.pageNumber - 1,
              pageSize: queryOptions.pageLimit,
            }}
            totalCount={invoicesTotal}
            secondaryActionList={_formSecondaryActionList()}
          />
        </>
      )}

      <UpdateInvoicePaymentStatusModal
        invoice={invoiceToUpdatePaymentStatus}
        onClose={() => {
          setInvoiceToUpdatePaymentStatus(null);
        }}
      />

      <InvoiceDetailsSlideOver
        show={!!viewInvoiceDetails}
        invoice={viewInvoiceDetails}
        onClose={() => setViewInvoiceDetails(null)}
      />
    </ContentCotainer>
  );
};

export const InvoicesDashboard = () => {
  return (
    <Tabs
      theme={{
        ...customFlowBiteTheme,
        base: `${customFlowBiteTheme.base} h-full`,
        tablist: {
          ...customFlowBiteTheme.tablist,
          styles: {
            ...customFlowBiteTheme.tablist.styles,
            default: `${customFlowBiteTheme.tablist.styles.default} mx-4 sm:mx-6 md:mx-8`,
          },
        },
        tabitemcontainer: {
          ...customFlowBiteTheme.tabitemcontainer,
          base: `${customFlowBiteTheme.tabitemcontainer.base} h-full`,
        },
        tabpanel: `${customFlowBiteTheme.tabpanel} h-full`,
      }}
      aria-label="Invoices tabs"
      variant="default">
      <TabItem active title="Invoices">
        <InvoicesPage />
      </TabItem>
      <TabItem active title="Recurring Invoices" icon={ArrowPathIcon}>
        <RecurringInvoicesPage />
      </TabItem>
    </Tabs>
  );
};

const StatusCell = ({ invoice }) => {
  const _getPaymentStatus = () => {
    if (invoice.payment.status === 'Paid') {
      return 'Paid';
    }

    if (invoice.payment.status === 'Unpaid') {
      return 'Unpaid';
    }

    if (invoice.payment.status === 'Sent') {
      return 'Sent';
    }
  };

  const _getBgColorClassName = () => {
    if (invoice.payment.status === 'Paid') {
      return '!bg-slate-700 !text-emerald-400';
    }

    if (invoice.payment.status === 'Sent') {
      return '!bg-slate-700 !text-indigo-300';
    }

    return '!bg-slate-700 !text-slate-200';
  };

  return (
    <Badge className={classnames('inline', _getBgColorClassName())}>
      {_getPaymentStatus()}
    </Badge>
  );
};

const ReconciliationCell = ({ invoice }) => {
  if (invoice?.accountingStatus?.reconciled) {
    return (
      <div className="flex flex-col">
        <span>Reconciled</span>
        {!!invoice?.accountingStatus?.accountName && (
          <span className="text-xs text-slate-500">
            {invoice?.accountingStatus?.accountName}
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <span>Not Reconciled</span>
      {!!invoice?.accountingStatus?.accountName && (
        <span className="text-xs text-slate-500">
          {invoice?.accountingStatus?.accountName}
        </span>
      )}
    </div>
  );
};

const InvoiceReference = ({ invoice }) => {
  return (
    <span>
      {invoice?.referenceId ? invoice.referenceId : 'Reference ID not found'}
    </span>
  );
};
