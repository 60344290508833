import { InvoiceDetails } from 'entities/invoice/ui';
import { useInvoice } from '../../../entities/invoice/model';
import { Drawer } from 'antd';

export const InvoiceDetailsSlideOver = ({ show, invoice, onClose }) => {
  const { isPending } = useInvoice(invoice?._id);

  return (
    <Drawer
      closable
      destroyOnClose
      width={540}
      zIndex={10}
      title={'Invoice Details'}
      placement="right"
      open={show}
      loading={isPending}
      onClose={onClose}>
      {invoice?._id && <InvoiceDetails id={invoice._id} onClose={onClose} />}
    </Drawer>
  );
};
