const Item = ({ term, children }) => {
  return (
    <div className="flex justify-between items-center py-2 text-sm font-medium">
      <dt className="text-left text-slate-400">{term}</dt>
      <dd className="text-slate-100">{children}</dd>
    </div>
  );
};

export const DetailSection = ({ header, children }) => {
  return (
    <div>
      <h3 className="text-left font-medium text-slate-100">{header}</h3>
      <dl className="mt-2 divide-y divide-[#2c3849] border-b border-t border-[#2c3849] text-right">
        {children}
      </dl>
    </div>
  );
};

DetailSection.Item = Item;
