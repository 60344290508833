import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { useCurrentUserDetails } from 'entities/user/model';
import { useCallback } from 'react';

const ENTITY_TYPES = {
  Bill: 'bill',
  Invoice: 'invoice',
  Expense: 'expense',
  UserOrganizationVendorRelation: 'userOrganizationVendorRelation',
  ExpenseReport: 'expenseReport',
};

const ACTION_TYPES = {
  Created: 'created',
};

const _fetchNotifications = async (getToken, params) => {
  const token = await getToken();

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/users/notifications/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return res?.data?.result || [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

const _discardNotifications = async (getToken, ids) => {
  const token = await getToken();

  try {
    await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/public/users/notifications/discard`,
      {
        ids,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.log(err);
  }
};

export const useUserNotifications = (query) => {
  const queryClient = useQueryClient();
  const { entityType, actionType, discarded } = query || {};
  const { hasLoggedInUser, getCurrentOrganizationId } = useCurrentUserDetails();
  const { getAccessTokenSilently } = useAuth0();
  const notificationsQuery = useQuery({
    queryKey: ['user-notifications', entityType, actionType, discarded],
    queryFn: () =>
      _fetchNotifications(getAccessTokenSilently, {
        entityType,
        actionType,
        discarded,
        organizationId: getCurrentOrganizationId(),
      }),

    enabled: hasLoggedInUser,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
  });

  const dicardNotificationsMutation = useMutation({
    mutationKey: ['discard-notifications'],
    mutationFn: async (ids) => {
      await _discardNotifications(getAccessTokenSilently, ids);
      await queryClient.invalidateQueries({ queryKey: ['user-notifications'] });
    },
  });

  const getNotifications = useCallback(() => {
    return notificationsQuery.data || [];
  }, [notificationsQuery]);

  const getNotificationsByEntityIds = useCallback(
    (ids) => {
      return (notificationsQuery.data || []).filter(({ entityId }) =>
        ids.includes(entityId)
      );
    },
    [notificationsQuery]
  );

  return {
    getNotifications,
    getNotificationsByEntityIds,
    dicardNotifications: dicardNotificationsMutation.mutateAsync,
  };
};

export const useInvalidateNotifications = (query) => {
  const queryClient = useQueryClient();

  const invalidateNotifications = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['user-notifications'] });
  }, [queryClient]);

  return {
    invalidateNotifications,
  };
};

export const useNotDiscardedBillsNotifications = () => {
  return useUserNotifications({
    entityType: ENTITY_TYPES.Bill,
    actionType: ACTION_TYPES.Created,
    discarded: false,
  });
};

export const useNotDiscardedVendorsNotifications = () => {
  return useUserNotifications({
    entityType: ENTITY_TYPES.UserOrganizationVendorRelation,
    actionType: ACTION_TYPES.Created,
    discarded: false,
  });
};

export const useNotDiscardedExpensesNotifications = () => {
  return useUserNotifications({
    entityType: ENTITY_TYPES.ExpenseReport,
    actionType: ACTION_TYPES.Created,
    discarded: false,
  });
};

export const createTableRowStyleVariantsByNotifications = (
  rowEntityIds,
  notifications
) => {
  const res = {};

  rowEntityIds.forEach((id, index) => {
    const hasActiveStatus = notifications.some(
      ({ entityId, actionType, discarded }) =>
        entityId === id && actionType === ACTION_TYPES.Created && !discarded
    );

    if (hasActiveStatus) {
      res[index] = 'active';
    }
  });

  return res;
};
