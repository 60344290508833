import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback } from 'react';
import { useCurrentUser, useCurrentUserDetails } from 'entities/user/model';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from './constants';

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/invoice';

async function createInvoice(token, invoice) {
  try {
    const response = await axios.post(`${RESOURCE_URL}/addinvoice`, invoice, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (error) {
    throw error;
  }
}

async function fetchInvoices(token, organizationId, filter) {
  try {
    const response = await axios.get(`${RESOURCE_URL}/allinvoices`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        filter,
        organizationId,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

async function fetchInvoice(id, token) {
  try {
    const response = await axios.get(`${RESOURCE_URL}/get-invoice`, {
      params: { id },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateInvoice(token, id, body) {
  try {
    const response = await axios.put(`${RESOURCE_URL}/edit-invoice`, body, {
      params: { id },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
}

async function deleteInvoice(token, id, organizationId) {
  try {
    const response = await axios.delete(`${RESOURCE_URL}/delete`, {
      params: { id, organizationId },
      headers: { Authorization: `Bearer ${token}` },
    });

    return response;
  } catch (error) {
    throw error;
  }
}

async function updateInvoicePaymentStatus(token, invoiceId, status, hash) {
  try {
    const response = await axios.put(
      `${RESOURCE_URL}/update-payment-status?id=${invoiceId}`,
      { status, hash },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function downloadInvoice(id, token, organizationId) {
  try {
    const response = await axios.get(`${RESOURCE_URL}/download-invoice`, {
      params: { id, organizationId },
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` },
    });

    const file = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    return file;
  } catch (error) {
    throw error;
  }
}

export const useInvoiceActions = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { data } = useCurrentUser();
  const { getCurrentOrganizationId } = useCurrentUserDetails();

  const createAction = useCallback(
    async (invoice) => {
      const token = await getAccessTokenSilently();
      return createInvoice(token, invoice);
    },
    [getAccessTokenSilently]
  );

  const fetchAllAction = useCallback(async () => {
    if (!data.nickname) {
      return null;
    }

    const token = await getAccessTokenSilently();
    return fetchInvoices(token, getCurrentOrganizationId());
  }, [getAccessTokenSilently, getCurrentOrganizationId, data]);

  const fetchAction = useCallback(
    async (invoiceId) => {
      const token = await getAccessTokenSilently();
      return fetchInvoice(invoiceId, token);
    },
    [getAccessTokenSilently]
  );

  const updateAction = useCallback(
    async (invoiceId, params) => {
      const token = await getAccessTokenSilently();
      return updateInvoice(token, invoiceId, params);
    },
    [getAccessTokenSilently]
  );

  const deleteAction = useCallback(
    async (invoiceId) => {
      const token = await getAccessTokenSilently();
      return deleteInvoice(token, invoiceId, getCurrentOrganizationId());
    },
    [getCurrentOrganizationId, getAccessTokenSilently]
  );

  const updatePaymentStatusAction = useCallback(
    async (invoiceId, status, hash) => {
      const token = await getAccessTokenSilently();
      return updateInvoicePaymentStatus(token, invoiceId, status, hash);
    },
    [getAccessTokenSilently]
  );

  const downloadAction = useCallback(
    async (invoiceId) => {
      const token = await getAccessTokenSilently();
      return downloadInvoice(invoiceId, token, getCurrentOrganizationId());
    },
    [getCurrentOrganizationId, getAccessTokenSilently]
  );

  const invalidateInvoice = useCallback(
    (invoiceId) => {
      return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY, invoiceId],
      });
    },
    [queryClient]
  );

  return {
    createAction,
    fetchAllAction,
    fetchAction,
    updateAction,
    deleteAction,
    updatePaymentStatusAction,
    downloadAction,
    invalidateInvoice,
  };
};
