import { useMemo, useState } from 'react';
import { useRecurringInvoices } from './useRecurringInvoices';
import EmptyItems from './EmptyItems';
import { Badge } from 'flowbite-react';
import { tokenToImage } from 'shared/lib';
import { dateFormatter } from 'entities/bill/lib';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { Table } from 'shared/ui/Table/TableBlocks';
import { RecurringInvoiceSlideOver } from './RecurringInvoiceSlideOver';
import { formatDollarAmount } from 'shared/lib/string';
import {
  getTokenHumanReadableNameByChain,
  upperCaseNetworkType,
} from 'features/bill/lib';
import classNames from 'classnames';

export const RecurringInvoicesPage = () => {
  const { items, isFetching } = useRecurringInvoices();
  const [ruleId, setRuleId] = useState();
  const columns = useMemo(
    () => [
      {
        header: 'Receiver',
        cell: ({ row: { original } }) => (
          <div className="flex flex-col text-left">
            <span className="text-sm font-semibold text-white">
              {original.receiverName}
            </span>
            <span className="mt-0.5 flex items-center text-xs font-normal text-gray-400">
              {original.receiverEmail}
            </span>
          </div>
        ),
      },
      {
        header: 'Amount',
        cell: ({ row: { original } }) => (
          <>
            <div className="flex items-center">
              <span className="mr-1">{tokenToImage(original.currency)}</span>
              <span className="mr-2 dark:text-white">
                {formatDollarAmount(original.totalAmount)}
              </span>
            </div>

            <div className="leading-none">
              <span className="text-[10px] xl:text-xs">
                {getTokenHumanReadableNameByChain(
                  original.currency,
                  original.paymentNetwork
                )}{' '}
                {' / ' + upperCaseNetworkType(original.paymentNetwork)}
              </span>
            </div>
          </>
        ),
      },
      {
        header: 'Next Issue Date',
        cell: ({ row: { original } }) =>
          original.nextIssueDate
            ? dateFormatter(original.nextIssueDate)
            : 'N/A',
      },
      {
        header: 'Issued / Expected',
        cell: ({ row: { original } }) => (
          <>
            {original.generatedInvoiceCount} {' / '}
            {original.expectedInvoiceCount}
          </>
        ),
      },

      {
        header: 'Status',
        cell: ({ row: { original } }) => (
          <>
            <Badge
              className={classNames('inline', {
                '!bg-slate-700 !text-emerald-400': original.status === 'active',
                '!bg-slate-700 !text-indigo-300': original.status === 'expired',
                '!bg-slate-700 !text-slate-200':
                  original.status === 'cancelled',
              })}>
              {original.status === 'active' && 'Active'}
              {original.status === 'expired' && 'Fulfilled'}
              {original.status === 'cancelled' && 'Cancelled'}
            </Badge>
          </>
        ),
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    setRuleId(row.id);
  };

  if (isFetching) {
    return <ContentLoadingState />;
  }

  if (!isFetching && items.length === 0) {
    return (
      <ContentCotainer>
        <EmptyItems />
      </ContentCotainer>
    );
  }

  return (
    <ContentCotainer>
      <>
        <Table
          title="Recurring Invoices"
          description="Access your recurring invoices and manage them."
          onRowClick={handleRowClick}
          data={items}
          columns={columns}
        />

        <RecurringInvoiceSlideOver
          show={!!ruleId}
          ruleId={ruleId}
          onClose={() => setRuleId(null)}
        />
      </>
    </ContentCotainer>
  );
};
