import { BanknotesIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Button } from '../../shared/ui/Button/Button';

export const EmptyTransfers = ({ onAddNewTransfer }) => {
  return (
    <div className="mt-20 text-center">
      <svg className="mx-auto h-12 w-12 ">
        <BanknotesIcon
          className="mx-auto h-12 w-12 text-slate-300"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-slate-300">
        No Transfers
      </h3>
      <p className="mt-1 text-sm text-slate-400">
        This feature enables the transfer of funds across various currencies,
        including stablecoins. <br /> If you are looking to on/off-ramp funds,
        create a new transfer.
      </p>
      <div className="mt-6 flex justify-center gap-4">
        <Button color={'indigo'} onClick={onAddNewTransfer}>
          <PlusIcon className="w-5 mr-1" />
          Add New Transfer
        </Button>
      </div>
    </div>
  );
};
