import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function AuthPage() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      login_hint: "",
      screen_hint: "login",
    });
  }, []);

  return <div className="bg-gray-900 lg:h-screen"></div>;
}
