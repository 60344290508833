import axios from 'axios';
import { useCallback } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';

const fetchPendingChainActions = async (getToken, organizationId) => {
  try {
    const token = await getToken();
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/bills/pending-chain-actions`,
      {
        params: {
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response?.data?.result || [];
  } catch (error) {
    console.log(error);
  }
};

export const useInvalidatePendingChainActions = () => {
  const queryClient = useQueryClient();

  const invalidatePendingChainActions = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['bills-pending-chain-actions'],
    });
  }, [queryClient]);

  return {
    invalidatePendingChainActions,
  };
};

export const usePendingChainActions = () => {
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { hasLoggedInUser, getCurrentOrganizationId, hasAnyOrganization } =
    useCurrentUserDetails();
  const dataQuery = useQuery({
    queryKey: ['bills-pending-chain-actions'],
    queryFn: () =>
      fetchPendingChainActions(getToken, getCurrentOrganizationId()),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
    enabled: hasLoggedInUser && hasAnyOrganization,
  });

  const getPendingActionByBillId = useCallback(
    (billId) => {
      return (dataQuery.data || []).find((a) => a.billIds.includes(billId));
    },
    [dataQuery.data]
  );

  return {
    getPendingActionByBillId,
    isPendingChainActionsFetching: dataQuery.isFetching,
  };
};
