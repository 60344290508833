import { useLinkActions } from "entities/link/model";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";

export const PaymentLinkAuthenticationForm = ({ url, onSubmit }) => {
  const { grantAccessToPaymentLinkAction } = useLinkActions();

  const [password, setPassword] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);

  async function handlePasswordSubmit(values, { setSubmitting }) {
    setShowPasswordError(false);

    try {
      const isGranted = await grantAccessToPaymentLinkAction(
        values.password,
        url._id
      );

      if (!isGranted) {
        setShowPasswordError(true);
      }

      onSubmit({ isGranted, password });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="text-lg">
      <Formik
        initialValues={{
          password: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = "Required";
          }
          return errors;
        }}
        onSubmit={handlePasswordSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form className="mt-5 md:col-span-2 md:mt-0">
            <div className="mt-10 sm:mt-0">
              <div className="bg-white px-4 pt-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Password Protected
                    </h3>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                      {url.companyname} has set a password to protect this form.
                      Please check your email.
                    </p>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <div className="grid grid-cols-6 gap-6 ">
                      <div className="col-span-6 sm:col-span-6 ">
                        <Field
                          type="text"
                          name="password"
                          className="mt-2 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setFieldValue("password", e.target.value);
                            setShowPasswordError(false);
                          }}
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="mt-2 text-xs italic text-red-500"
                        />
                        {showPasswordError && (
                          <div className="mt-2 text-xs italic text-red-500">
                            Wrong password
                          </div>
                        )}
                        <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                          Please enter the password that was sent to your email.
                        </p>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="mb-2 mr-2 mt-5 rounded-lg bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-br focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800">
                          Get Access
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
