import { useQuery } from '@tanstack/react-query';
import { useBillActions } from './actions';
import { useCurrentUser } from 'entities/user/model';
import { QUERY_KEY } from './constants';

export const useBill = (billId) => {
  const { fetchAction } = useBillActions();

  const queryInfo = useQuery({
    queryKey: [QUERY_KEY, billId],
    queryFn: () => fetchAction(billId),
    initialData: undefined,
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 1000 * 60,
  });

  return queryInfo;
};

/** We use this hook for payments initiation, let's not use cache to avoid old data clash */
export const useBills = (billIds) => {
  const { fetchAction } = useBillActions();
  return useQuery({
    queryKey: [QUERY_KEY, 'multiple-bills', billIds.join('|')],
    queryFn: () => Promise.all(billIds.map((billId) => fetchAction(billId))),
    enabled: !!billIds.length,
  });
};

export const useBillsAnalytics = () => {
  const { fetchBillAnalyticsAction } = useBillActions();
  const { data } = useCurrentUser();

  const queryEnabled = !!data.nickname;

  const queryInfo = useQuery({
    queryKey: [QUERY_KEY, 'monthly-analytics'],
    queryFn: fetchBillAnalyticsAction,
    enabled: queryEnabled,
    initialData: undefined,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return queryInfo;
};
