import { useCurrentUser } from 'entities/user/model';
import { OrgRequiredWall } from 'features/organization/ui';
import CompanyAddUsers from 'widgets/Organization/CompanyAddUsers';
import CompanyUsersTable from 'widgets/Organization/CompanyUsersTable';
import { WithOrgRole } from '../../shared/ui/WithOrgPermissions';

const TeamMembersPage = () => {
  const { data } = useCurrentUser();

  if (!data?.selected_organization?._id) {
    return <OrgRequiredWall />;
  }

  return (
    <div className="py-8">
      <WithOrgRole
        admin={
          <div className="mb-8">
            <CompanyAddUsers />
          </div>
        }
        member={null}
        accountant={null}
      />

      <CompanyUsersTable />
    </div>
  );
};

export default TeamMembersPage;
