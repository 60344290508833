import React from 'react';
import { Link } from 'react-router-dom';
import { BLOCKSCAN_URLS } from 'features/bill/lib';

export const SuccessConfirmation = ({
  show,
  network,
  title = 'Payment Successful!',
  txHash,
  onClose,
  successLink = '',
  showSuccessButton = true,
  successLinkText = 'Bill Details',
  successDescription = 'We store Transaction Hash in Bill Details.',
}) => {
  if (!show) {
    return null;
  }

  const getBlockExplorerUrl = (network, txHash) => {
    if (!BLOCKSCAN_URLS[network]) {
      return null;
    }

    return `${BLOCKSCAN_URLS[network]}/tx/${txHash}`;
  };

  return (
    <div className="absolute left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-slate-800 pl-3 pr-3">
      <div className="flex flex-col items-center">
        <div className="mx-auto my-4 flex h-24 w-24 items-center justify-center rounded-full bg-gray-700 shadow-md ">
          <span className="p-4 text-6xl">🎊</span>
        </div>

        <div className="z-50 my-6 max-w-lg space-y-4 text-center">
          <h3 className="text-2xl font-semibold leading-6 text-slate-300">
            {title}
          </h3>
          <div className="text-slate-400">{successDescription}</div>
        </div>

        <div className="flex justify-between space-x-2 px-2">
          {!!getBlockExplorerUrl(network, txHash) && (
            <a
              href={getBlockExplorerUrl(network, txHash)}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center rounded-md bg-gray-300 px-4 py-2  font-semibold text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Confirmation
            </a>
          )}
          {showSuccessButton && (
            <Link to={successLink}>
              <button
                onClick={onClose}
                className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2  font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                {successLinkText}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
