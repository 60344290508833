import { LinkDetails } from "entities/link/ui";
import SlideOver from "shared/ui/SlideOver";

export const PaymentLinkDetailsSlideOver = ({ show, link, onClose }) => {
  return (
    <SlideOver show={show} onClose={onClose}>
      <SlideOver.Header>
        <SlideOver.Title>
          <h2>{link?.name ? link.name : "Payment Link"}</h2>
        </SlideOver.Title>
      </SlideOver.Header>
      {link?._id && <LinkDetails id={link._id} onClose={onClose} />}
    </SlideOver>
  );
};
