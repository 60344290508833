import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUser } from 'entities/user/model';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/organisation/employees';

const fetchEmployees = async (getToken, organizationId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/list`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createEmployee = async (getToken, payload) => {
  const token = await getToken();

  try {
    const response = await axios.post(`${RESOURCE_URL}`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const editEmployee = async (getToken, payload) => {
  const token = await getToken();

  try {
    const response = await axios.put(`${RESOURCE_URL}`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteEmployee = async (getToken, employeeId, organizationId) => {
  const token = await getToken();

  try {
    const response = await axios.delete(
      `${RESOURCE_URL}/${employeeId}?organizationId=${organizationId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useEmployees = () => {
  const queryClient = useQueryClient();
  const { data: userData } = useCurrentUser();
  const organizationId = userData?.selected_organization?._id;
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: employeesData, isFetching: isFetchingEmployees } = useQuery({
    queryKey: ['employees'],
    queryFn: () => fetchEmployees(getToken, organizationId),
    enabled: !!organizationId,
  });
  const createMutation = useMutation({
    mutationFn: (payload) =>
      createEmployee(getToken, {
        ...payload,
        organizationId,
      }).then(() => queryClient.invalidateQueries({ queryKey: ['employees'] })),
    mutationKey: ['create-employee'],
  });

  const editMutation = useMutation({
    mutationFn: (payload) =>
      editEmployee(getToken, {
        ...payload,
        organizationId,
      }).then(() => queryClient.invalidateQueries({ queryKey: ['employees'] })),
    mutationKey: ['edit-employee'],
  });

  const deleteMutation = useMutation({
    mutationFn: (employeeId) =>
      deleteEmployee(getToken, employeeId, organizationId).then(() =>
        queryClient.invalidateQueries({ queryKey: ['employees'] })
      ),
    mutationKey: ['delete-employee'],
  });

  return {
    employeesEnabled: !!organizationId,
    employees: employeesData?.result || [],
    isFetchingEmployees,

    createEmployee: createMutation.mutateAsync,
    editEmployee: editMutation.mutateAsync,
    isEditingEmployee: editMutation.isPending,
    isCreatingEmployee: createMutation.isPending,
    deletePayments: deleteMutation.mutateAsync,
    isDeletingEmployee: deleteMutation.isPending,
  };
};
