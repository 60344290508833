import { useMemo, useState, useEffect, useRef } from 'react';
import { useExpenses } from './useExpenses';
import { Table } from 'shared/ui/Table/TableBlocks';

import { Badge } from 'flowbite-react';
import { ExpenseDetailsSlideOver } from './ExpenseDetailsSlideOver';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { tokenToImage } from 'shared/lib';
import {
  ExpensesTableBatchPayments,
  BATCH_PAYMENT_PROVIDER_TYPES,
} from './ExpensesPage.BatchPayment';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import {
  useNotDiscardedExpensesNotifications,
  createTableRowStyleVariantsByNotifications,
} from 'features/userNotifications/useUserNotifications';
import { PaymentProviderSelection } from 'features/payments/PaymentProviderSelection';
import { useCurrentUserDetails } from 'entities/user/model';
import { getExpensePaymentProvidersEvaluatorStrategy } from 'features/payments/getPaymentProvidersEvaluatorStrategy';
import { createPlatformIntegrations } from 'features/platformIntegrations/PlatformIntegration';
import { createOrgSettingsFactory } from '../orgSettings/OrgSettings';
import { getTokenHumanReadableNameByChain } from '../../bill/lib';

export const ExpensesPage = () => {
  const discardTimerRef = useRef(null);
  const [expenseForPay, setExpenseForPay] = useState(null);
  const [showPaymentProviderSelection, setShowPaymentProviderSelection] =
    useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedExpense, setExpenseReport] = useState(null);
  const { expenses, isLoadingExpenses, expensesEnabled } = useExpenses();
  const [paymentProvider, setPaymentProvider] = useState(null);
  const { getNotifications, dicardNotifications, getNotificationsByEntityIds } =
    useNotDiscardedExpensesNotifications();

  const { isPaymentAvailable, paymentProvidersEvaluator } = usePaymentProviders(
    expenseForPay
      ? [expenses.find((b) => b.reportId === expenseForPay.reportId)]
      : Object.keys(rowSelection).map((idx) => expenses[idx])
  );

  const handleRowClick = (row) => {
    setExpenseReport(row.reportId);

    const notificationsToDiscard = getNotificationsByEntityIds([row.reportId]);

    if (notificationsToDiscard.length) {
      dicardNotifications(notificationsToDiscard.map(({ id }) => id));
    }
  };

  const columns = useMemo(
    () => [
      {
        header: 'selection',
      },
      {
        header: 'From',
        cell: ({ row: { original: item } }) => {
          return (
            <span className="font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {item.employeeFirstName} {item.employeeLastName}
            </span>
          );
        },
      },

      {
        header: 'Amount to Pay',
        cell: ({ row: { original: item } }) => (
          <AmountCell expenseReport={item} />
        ),
      },
      {
        header: 'Title',
        cell: ({ row }) => row.original.reportTitle,
      },
      {
        header: 'Status',
        cell: ({ row: { original: item } }) => (
          <>
            {item.paymentStatus === 'paid' ? (
              <Badge className="inline !bg-slate-700 !text-emerald-400">
                Paid
              </Badge>
            ) : item.paymentStatus ===
              'waiting_aragon_provider_unknown_action' ? (
              <Badge className="inline !bg-slate-700 !text-indigo-300">
                Processing
              </Badge>
            ) : (
              <Badge className="inline !bg-slate-700 !text-slate-200">
                Unpaid
              </Badge>
            )}
          </>
        ),
      },
    ],
    []
  );

  const handlePayExpense = (expense) => {
    setExpenseForPay(expense);
    setShowPaymentProviderSelection(true);
  };

  const _handleSelectPaymentProvider = (provider) => {
    setShowPaymentProviderSelection(false);
    setPaymentProvider(provider);
  };

  const formSecondaryActionList = () => {
    const res = [];

    if (isPaymentAvailable()) {
      res.push({
        label: 'Pay Expenses',
        icon: () => <CreditCardIcon className="w-6 mr-2" />,
        onClick: () => setShowPaymentProviderSelection(true),
      });
    }

    return res;
  };

  useEffect(() => {
    discardTimerRef.current = setTimeout(() => {
      discardTimerRef.current = 'fulfilled';
    }, 1000);

    return () => {
      if (discardTimerRef.current === 'fulfilled') {
        const ids = getNotifications().map((n) => n.id);

        if (ids.length) {
          dicardNotifications(ids);
        }
      } else {
        discardTimerRef.current && clearTimeout(discardTimerRef.current);
      }
    };
  }, []);

  if (!expensesEnabled) {
    return null;
  }

  if (isLoadingExpenses) {
    return <ContentLoadingState />;
  }

  if (expenses.length === 0) {
    return (
      <ContentCotainer>
        <div className="flex flex-col pt-4 items-center">
          <h3 className="text-sm font-semibold text-slate-300">
            Your Company Expenses
          </h3>
          <p className="mt-1 text-sm text-slate-400">
            You have no expenses yet from your employees.
          </p>
        </div>
      </ContentCotainer>
    );
  }

  return (
    <ContentCotainer>
      <Table
        title="Company Expenses"
        description="View and manage your company's expenses"
        onRowClick={handleRowClick}
        data={expenses}
        secondaryActionList={formSecondaryActionList()}
        columns={columns}
        rowSelection={rowSelection}
        onRowSelectionChange={setRowSelection}
        rowStyleVariants={createTableRowStyleVariantsByNotifications(
          expenses.map((exp) => exp.reportId),
          getNotifications()
        )}
      />
      <ExpenseDetailsSlideOver
        expenseId={selectedExpense}
        show={!!selectedExpense}
        onPayExpense={handlePayExpense}
        onClose={() => setExpenseReport(null)}
      />

      <PaymentsModalManager
        paymentProvider={paymentProvider}
        expenses={
          expenseForPay
            ? [expenses.find((b) => b.reportId === expenseForPay.reportId)]
            : Object.keys(rowSelection).map((idx) => expenses[idx])
        }
        onRowSelectionChange={setRowSelection}
        onClose={() => {
          setPaymentProvider(null);
          setExpenseForPay(null);
        }}
      />

      <PaymentProviderSelection
        show={showPaymentProviderSelection}
        onSelectProvider={_handleSelectPaymentProvider}
        paymentProvidersEvaluator={paymentProvidersEvaluator}
        onClose={() => {
          setShowPaymentProviderSelection(false);
          setExpenseForPay(null);
        }}
      />
    </ContentCotainer>
  );
};

const AmountCell = ({ expenseReport }) => {
  return (
    <>
      <div className="mb-1 flex leading-none items-center">
        <span className="mr-1">
          {tokenToImage(expenseReport.paymentCurrency)}
        </span>
        <span className="mr-2 dark:text-white">
          {expenseReport.paymentAmount || 0}
        </span>
      </div>

      <div className="text-xs leading-none">
        {getTokenHumanReadableNameByChain(
          expenseReport.paymentCurrency,
          expenseReport.paymentNetwork
        )}{' '}
        /{' '}
        {expenseReport.paymentNetwork[0].toUpperCase() +
          expenseReport.paymentNetwork.substring(1)}
      </div>
    </>
  );
};

const usePaymentProviders = (expenses) => {
  const { getOrgIntegrations, getOrgSettings } = useCurrentUserDetails();
  const evaluator = getExpensePaymentProvidersEvaluatorStrategy(
    expenses,
    createPlatformIntegrations(getOrgIntegrations()),
    createOrgSettingsFactory(getOrgSettings())
  );

  return {
    isPaymentAvailable: () =>
      !!evaluator && evaluator.getAllPossiblePaymentProviders().length > 0,

    paymentProvidersEvaluator: evaluator,
  };
};

const PaymentsModalManager = ({
  expenses,
  paymentProvider,
  onRowSelectionChange,
  onClose,
}) => {
  if (!paymentProvider) {
    return null;
  }

  const renderModal = () => {
    if (paymentProvider.id === 'aragon-app') {
      return (
        <ExpensesTableBatchPayments
          paymentProvider={BATCH_PAYMENT_PROVIDER_TYPES.Aragon}
          showPaymentModal
          onModalClose={onClose}
          onDeselectRows={() => onRowSelectionChange({})}
          selectedExpenses={expenses}
        />
      );
    }

    return null;
  };

  return <>{renderModal()}</>;
};
