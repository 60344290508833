import { Modal } from '../../../shared/ui/Modal/Modal';
import React, { useEffect, useState } from 'react';
import { ConfirmInWalletOverlay } from '../../../widgets/Payments/PaymentModal.ConfirmInWalletOverlay';
import { FailedPayment } from '../../../widgets/Payments/CryptoToFiatPaymentModal.SuccessConfirmation';
import { SuccessConfirmation } from '../../../widgets/Payments/PaymentModal.SuccessConfirmation';
import { SuccessConfirmation as FiatSuccessConfirmation } from '../../../widgets/Payments/CryptoToFiatPaymentModal.SuccessConfirmation';
import { Label } from 'flowbite-react';
import { Select } from '../../../shared/ui/form/Select/Select';
import { Button } from '../../../shared/ui/Button/Button';
import { ConfirmationSection } from '../../../widgets/Payments/PaymentModal.ConfirmationSection';
import { PaymentInformation } from '../../../widgets/Payments/PaymentModal.PaymentInformation';
import { useBalance } from 'wagmi';
import erc20TokensData from '../../../shared/constants/erc20TokensData';
import { useFractalBankAccounts } from '../../fractalBankAccounts/useFractalBankAccounts';
import { useTransferAccountAbstractionWithPassKeySigner } from '../../fractalBankAccounts/useZeroDevAccountAbstraction';
import { createCryptoPayoutDetails, PayoutTypes } from '../PayoutDetails';
import { FiatPaymentInformation } from '../../../widgets/Payments/CryptoToFiatPaymentModal.PaymentInformation';
import { Spinner } from '../../../shared/ui/Spinner/Spinner';
import { NETWORK_TYPES, TOKEN_TYPES } from '../../bill/lib';
import { isDev } from '../../../shared/lib/env';
import { useExecuteBillOfframp, useInitiateBillOfframp } from './OfframpModal';
import { useCurrentUserDetails } from '../../../entities/user/model';

const FractalBankAccountCryptoPaymentModal = ({
  payouts,
  onClose,
  bankAccount,
  successRedirectLink,
  onTransactionSuccess,
}) => {
  /** Depends on which type of account user selects we render different flows, but for MVP we render only one default */
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);

  /** @type {CryptoPayoutDetails} */
  const payout = payouts[0];

  const {
    transferPayoutResult,
    transferPayout,
    isSuccess,
    isError,
    isPending,
  } = useTransferAccountAbstractionWithPassKeySigner();

  const { data: tokenBalance } = useBalance({
    address: bankAccount?.paymentDetails?.cryptoWallet.address,
    chainId: 137,
    token: erc20TokensData.USDC.erc20Address.Polygon,
  });

  const _handleConfirmationChange = (confirmed) => {
    setIsConfirmedByUser(confirmed);
  };

  const _handlePayment = () => {
    transferPayout({
      payout,
      accountName: bankAccount.name,
      accountCurrency: bankAccount.currency,
    }).then((res) => {
      onTransactionSuccess && onTransactionSuccess(res);
    });
  };

  return (
    <>
      <ConfirmInWalletOverlay show={isPending} />
      <FailedPayment onClose={onClose} show={isError} />
      <SuccessConfirmation
        show={isSuccess}
        network={payout.network}
        txHash={transferPayoutResult?.hash}
        onClose={onClose}
        successLink={successRedirectLink}
      />

      <PaymentInformation
        walletAddress={payout.walletAddress}
        network={payout.network}
        token={payout.currency}
        amount={payout.amountStr}
        balance={tokenBalance?.formatted ? tokenBalance.formatted : undefined}
      />
      <ConfirmationSection
        isConfirmed={isConfirmedByUser}
        setIsConfirmed={setIsConfirmedByUser}
        onConfirmationChange={_handleConfirmationChange}
      />
      <div className="pt-6 flex flex-row gap-4 items-center justify-center">
        <Button className="flex-1" color={'gray'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="indigo"
          className="flex-1"
          disabled={!isConfirmedByUser || isPending}
          isProcessing={isPending}
          onClick={_handlePayment}>
          Pay {payout.amountStr} {payout.currency}
        </Button>
      </div>
    </>
  );
};

const FractalBankAccountBankPaymentModal = ({
  payouts,
  onClose,
  bankAccount,
  onTransactionSuccess,
  /** Temp solution since API is coupled with bill */
  paymentContext,
}) => {
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  /** @type {BankTransferPayoutDetails} */
  const payout = payouts[0];
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const initiateBillOfframpMutation = useInitiateBillOfframp();
  const executeBillOfframpMutation = useExecuteBillOfframp();
  const { transferPayout, isError, isPending } =
    useTransferAccountAbstractionWithPassKeySigner();
  const _handleClose = () => {
    if (
      !initiateBillOfframpMutation.isPending &&
      !executeBillOfframpMutation.isPending
    ) {
      onClose();
    }
  };

  const _handleConfirmationChange = (confirmed) => {
    setIsConfirmedByUser(confirmed);
  };

  const _handlePayment = () => {
    transferPayout({
      /** We deposit to ourselfs in dev mode for testing purposes */
      payout: createCryptoPayoutDetails(
        isDev()
          ? '0.01'
          : initiateBillOfframpMutation.data?.depositInfo?.amount,
        TOKEN_TYPES.USDC,
        NETWORK_TYPES.Polygon,
        isDev()
          ? '0x4baB3904C07933F190Eb9d8ffd17214aD703147f'
          : initiateBillOfframpMutation.data?.depositInfo?.depositWallet
              ?.address
      ),
      accountName: bankAccount.name,
      accountCurrency: bankAccount.currency,
    })
      .then((res) => {
        return executeBillOfframpMutation.mutateAsync({
          bills: [{ _id: paymentContext.billId }],
          organizationId: getCurrentOrganizationId(),
          transactionHash: res.hash,
        });
      })
      .then(() => {
        onTransactionSuccess({
          scheduledToPaymentProvider: true,
        });
      });
  };

  useEffect(() => {
    if (!initiateBillOfframpMutation.isPending) {
      initiateBillOfframpMutation.mutate({
        depositTokenChain: 'polygon',
        bills: [{ _id: paymentContext.billId }],
        organizationId: getCurrentOrganizationId(),
      });
    }
  }, []);

  return (
    <>
      <ConfirmInWalletOverlay
        show={isPending || executeBillOfframpMutation.isPending}
      />
      <FailedPayment
        onClose={_handleClose}
        show={executeBillOfframpMutation.isError || isError}
      />
      <FiatSuccessConfirmation
        onClose={_handleClose}
        show={executeBillOfframpMutation.isSuccess}
      />

      <FiatPaymentInformation
        beneficiaryName={payout.accountBeneficiary}
        accountNumber={payout.accountNumber}
        bankRoutingNumber={payout.accountACHRoutingNumber}
        bankSwiftCode={payout.bankBIC}
        bankIban={payout.accountIBAN}
      />

      <div className="text-center">
        {initiateBillOfframpMutation.isPending ? (
          <Spinner size="lg" color="indigo" />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="text-sm text-slate-400">Amount:</div>
              <div className="text-sm text-slate-400">
                {payout.amountNumber}{' '}
                <span className="text-slate-400">{payout.currency}</span>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-sm text-slate-400">Payment Fee:</div>
              <div className="text-sm text-slate-400">
                {initiateBillOfframpMutation.data?.depositInfo?.fees}{' '}
                <span className="text-slate-400">{bankAccount.currency}</span>
              </div>
            </div>

            <div className="mt-2 flex justify-between">
              <div className="text-sm text-slate-200">Total Cost:</div>
              <div className="text-slate-200">
                {initiateBillOfframpMutation.data?.depositInfo?.amount}{' '}
                <span className="">{bankAccount.currency}</span>
              </div>
            </div>
          </>
        )}
      </div>

      <ConfirmationSection
        isConfirmed={isConfirmedByUser}
        setIsConfirmed={setIsConfirmedByUser}
        onConfirmationChange={_handleConfirmationChange}
      />

      <div className="pt-6 flex flex-row gap-4 items-center justify-center">
        <Button className="flex-1" color={'gray'} onClick={_handleClose}>
          Cancel
        </Button>
        <Button
          color="indigo"
          className="flex-1"
          disabled={
            !isConfirmedByUser ||
            isPending ||
            executeBillOfframpMutation.isPending ||
            initiateBillOfframpMutation.isPending
          }
          isProcessing={executeBillOfframpMutation.isPending}
          onClick={_handlePayment}>
          Pay {payout.amountStr} {payout.currency}
        </Button>
      </div>
    </>
  );
};

const FractalBankAccountPaymentModal = ({
  payouts,
  onClose,
  successRedirectLink,
  onTransactionSuccess,
  paymentContext,
}) => {
  /** @type {[CryptoPayoutDetails[]]} */
  const payoutState = useState(payouts);
  const payout = payoutState[0][0];
  const [bankAccount, setBankAccount] = useState(null);
  const { bankAccounts } = useFractalBankAccounts();

  const renderInner = () => {
    if (!bankAccount) {
      return (
        <>
          <div className="grid text-left gap-y-2 pb-4">
            <Label className="!text-slate-400" value="Select Account" />
            <Select
              sizing="md"
              color="slate"
              value={bankAccount?.id || 'no-val'}
              onChange={(evt) => {
                setBankAccount(
                  bankAccounts.find((acc) => acc.id === evt.target.value)
                );
              }}>
              <option value="no-val" disabled>
                --
              </option>

              {bankAccounts.map((acc) => (
                <option value={acc.id} key={acc.id}>
                  {acc.name}
                </option>
              ))}
            </Select>
          </div>
        </>
      );
    }

    if (payout.type === PayoutTypes.CryptoTransfer) {
      return (
        <FractalBankAccountCryptoPaymentModal
          payouts={payoutState[0]}
          onClose={onClose}
          bankAccount={bankAccount}
          successRedirectLink={successRedirectLink}
          onTransactionSuccess={onTransactionSuccess}
        />
      );
    }

    return (
      <FractalBankAccountBankPaymentModal
        payouts={payoutState[0]}
        onClose={onClose}
        bankAccount={bankAccount}
        successRedirectLink={successRedirectLink}
        onTransactionSuccess={onTransactionSuccess}
        paymentContext={paymentContext}
      />
    );
  };

  return (
    <Modal popup size="lg" show onClose={onClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>
      <Modal.Body>{renderInner()}</Modal.Body>
    </Modal>
  );
};

export const FractalBankAccountBillPaymentModal = ({
  billId,
  payouts = [],
  onClose,
  onTransactionSuccess,
}) => {
  /** Bill related logic might be included in the future, for now jus an abstraction */
  return (
    <FractalBankAccountPaymentModal
      payouts={payouts}
      onClose={onClose}
      onTransactionSuccess={onTransactionSuccess}
      successRedirectLink={'/dashboard/bills/allbills'}
      /** Temp solution since API is coupled with bill */
      paymentContext={{
        billId,
      }}
    />
  );
};
