import { useMemo, useState } from 'react';
import { ModalStyles } from './PaymentModal.ModalStyles';
import { useBalance, useAccount } from 'wagmi';
import { ConfirmInWalletOverlay } from './PaymentModal.ConfirmInWalletOverlay';
import { ConfirmationSection } from './PaymentModal.ConfirmationSection';
import { WarningsSection } from './PaymentModal.WarningsSection';
import { useSafeTransaction } from 'shared/hooks/safeWallet';
import {
  getTokenContractAddressByChainId,
  getTokenHumanReadableNameByChain,
  NETWORK_TO_CHAIN_ID_MAP,
  NetworkLogoMap,
  TokenLogoMap,
  upperCaseNetworkType,
} from 'features/bill/lib';
import { SuccessConfirmation as WaitingSuccessConfirmation } from './CryptoToFiatPaymentModal.SuccessConfirmation';
import { Button } from 'shared/ui';
import { FailedPayment } from './CryptoToFiatPaymentModal.SuccessConfirmation';
import { SafeAddressPicker } from './BatchPaymentModal.SafeAddressPicker';
import { AragonAddressPicker } from './BatchPaymentModal.AragonAddressPicker';
import { Dialog } from '@headlessui/react';
import BigNumber from 'bignumber.js';
import { sendTransactionLog } from 'features/analytics/sendTransactionLog';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useAragonAppDetails,
  useAragonMultisigBatchPayment,
} from 'shared/hooks/useAragonSdk';
import { BATCH_PAYMENT_PROVIDER_TYPES } from 'features/bill/ui/BillsTable.BatchPayments';
import erc20TokensData from '../../shared/constants/erc20TokensData';
import { formatDollarAmount } from '../../shared/lib/string';

export const BatchPaymentModalCryptoToCrypto = ({
  show,
  batchItems,
  onClose,
  onPaymentSuccessAsync,
  onPaymentSuccessAsyncLoading,
  paymentProvider,
}) => {
  const [isClosingBlocked, setIsClosingBlocked] = useState(false);

  const _handleClose = () => {
    if (!isClosingBlocked && !onPaymentSuccessAsyncLoading) {
      onClose();
    }
  };

  const handleBlockClosing = (val) => {
    setIsClosingBlocked(val);
  };

  const renderInnerByProvider = () => {
    if (paymentProvider === BATCH_PAYMENT_PROVIDER_TYPES.GnosisSafe) {
      return (
        <BatchPaymentInnerGnosis
          batchItems={batchItems}
          onClose={_handleClose}
          onBlockClosing={handleBlockClosing}
          onPaymentSuccessAsync={onPaymentSuccessAsync}
          onPaymentSuccessAsyncLoading={onPaymentSuccessAsyncLoading}
        />
      );
    }

    if (paymentProvider === BATCH_PAYMENT_PROVIDER_TYPES.Aragon) {
      return (
        <BatchPaymentInnerAragon
          batchItems={batchItems}
          onClose={_handleClose}
          onBlockClosing={handleBlockClosing}
          onPaymentSuccessAsync={onPaymentSuccessAsync}
          onPaymentSuccessAsyncLoading={onPaymentSuccessAsyncLoading}
        />
      );
    }

    return null;
  };

  return (
    <ModalStyles show={show} onClose={_handleClose}>
      {renderInnerByProvider()}
    </ModalStyles>
  );
};

const BatchPaymentInnerGnosis = ({
  batchItems,
  onClose,
  onBlockClosing,
  onPaymentSuccessAsync,
  onPaymentSuccessAsyncLoading,
}) => {
  const { chain, address, connector } = useAccount();
  const { getAccessTokenSilently } = useAuth0();
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const [safeContractAddress, setSafeContractAddress] = useState('');
  const [showFailedMessage, setShowFailedMessage] = useState(false);
  const { isTransferTransactionLoading, initSafeTransferTransaction } =
    useSafeTransaction({ safeContractAddress });
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);

  const _handleConfirmationChange = (confirmed) => {
    setIsConfirmedByUser(confirmed);
  };

  const _handlePay = () => {
    onBlockClosing(true);
    initSafeTransferTransaction({ recipients: batchItems })
      .then(({ safeTxHash }) => {
        return onPaymentSuccessAsync({ safeTxHash });
      })
      .then(() => {
        setTransactionSuccessful(true);
      })
      .catch((err) => {
        console.log(err);
        setShowFailedMessage(true);
        sendTransactionLog(getAccessTokenSilently, {
          title: '[FAIL][BATCH CRYPTO CRYPTO]',
          address,
          chainId: chain?.id,
          chainName: chain?.name,
          err,
          connector: {
            id: connector.id,
            name: connector.name,
          },
        });
      })
      .finally(() => {
        onBlockClosing(false);
      });
  };

  const { data: tokenBalance } = useBalance({
    address: safeContractAddress,
    chainId: chain?.id,
    token: getTokenContractAddressByChainId(batchItems[0].token, chain?.id),
  });

  const paymentProps = useMemo(() => {
    const balance = tokenBalance?.formatted;
    const totalAmount = batchItems.reduce((accumulator, currentValue) => {
      return accumulator.plus(new BigNumber(currentValue.amount));
    }, new BigNumber(0));
    return {
      balance,
      totalAmount: totalAmount.toString(),
    };
  }, [tokenBalance, batchItems]);

  return (
    <>
      <ConfirmInWalletOverlay
        show={isTransferTransactionLoading || onPaymentSuccessAsyncLoading}
      />
      <WaitingSuccessConfirmation
        title="Multisig Payment Initiated"
        description="We will update the payment status once Safe Transaction is signed by required owners."
        show={transactionSuccessful}
        onClose={onClose}
      />
      <FailedPayment onClose={onClose} show={showFailedMessage} />
      <SafeAddressPicker
        onAddressSelect={(a) => setSafeContractAddress(a)}
        show={!safeContractAddress}
      />

      <div>
        <div className="text-center">
          <Dialog.Title
            as="h3"
            className="mb-5 text-base font-semibold leading-6 text-slate-300">
            Payment Summary
          </Dialog.Title>

          <table className="w-[100%]">
            <thead className="text-xs font-normal text-slate-400 ">
              <tr className="">
                <th className="py-0 text-left font-medium">Recipients</th>
                <th className="font-normal text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {batchItems.map(({ to, amount, token }, index) => (
                <tr key={index} className=" text-slate-300 ">
                  <td className="text-xs text-left font-medium mt-2 py-1">
                    {to}
                  </td>
                  <td className="text-right text-xs text-slate-300">
                    <div className="ml-8 flex items-center justify-end">
                      <span className="mr-1 font-medium">{amount}</span>{' '}
                      <span className=" font-medium">{token}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="my-4 border-b border-slate-600"></div>

          <div className="flex items-center justify-between text-left text-sm">
            <span className="text-xs text-slate-400">Total Amount To Pay</span>

            <div className="flex items-center text-xs text-slate-300">
              <div className="mr-1 h-4 w-4">
                <img
                  src={TokenLogoMap[batchItems[0].token]}
                  alt=""
                  aria-hidden="true"
                />
              </div>
              <span className="text-xs mr-1">{paymentProps.totalAmount}</span>{' '}
              <span className="text-slate-300">
                {getTokenHumanReadableNameByChain(
                  batchItems[0].token,
                  batchItems[0].network
                )}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between pt-1 text-left text-sm">
            <span className="text-xs text-slate-400">Payment Network</span>
            <div className="flex items-center text-xs text-slate-300">
              <div className="mr-1 h-4 w-4">
                <img
                  src={NetworkLogoMap[batchItems[0].network]}
                  alt=""
                  aria-hidden="true"
                />
              </div>
              <span className="text-slate-400">{batchItems[0].network}</span>
            </div>
          </div>

          <div className="flex items-center justify-between pt-1.5 text-left text-sm">
            <span className="text-xs text-slate-400">Current Balance</span>
            <div className="flex items-center text-xs text-slate-300">
              <span className="text-slate-400">
                {parseFloat(tokenBalance?.formatted).toFixed(4)}
              </span>
            </div>
          </div>
        </div>

        <WarningsSection
          connectedChain={chain}
          payeeNetwork={batchItems[0].network}
          payeeAmount={paymentProps.totalAmount}
          payeeToken={batchItems[0].token}
          paymentProps={paymentProps}
          showPotentialDuplicate={false}
        />
        <ConfirmationSection
          isConfirmed={isConfirmedByUser}
          setIsConfirmed={setIsConfirmedByUser}
          onConfirmationChange={_handleConfirmationChange}
        />
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <Button
          className={'justify-center'}
          disabled={!isConfirmedByUser}
          onClick={_handlePay}>
          Complete Payment
        </Button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:order-first sm:mt-0"
          onClick={() => {
            onClose();
          }}>
          Cancel
        </button>
      </div>
    </>
  );
};

const BatchPaymentInnerAragon = ({
  batchItems,
  onClose,
  onBlockClosing,
  onPaymentSuccessAsync,
  onPaymentSuccessAsyncLoading,
}) => {
  const { chain } = useAccount();
  const { getAccessTokenSilently } = useAuth0();
  const [aragonContractAddress, setAragonContractAddress] = useState('');
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const { getMultisigAddress } = useAragonAppDetails(aragonContractAddress);

  const { initTransaction, isTransferTransactionLoading } =
    useAragonMultisigBatchPayment(getMultisigAddress());
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [showFailedMessage, setShowFailedMessage] = useState(false);

  const _handleConfirmationChange = (confirmed) => {
    setIsConfirmedByUser(confirmed);
  };

  const { data: tokenBalance } = useBalance({
    address: aragonContractAddress,
    chainId: NETWORK_TO_CHAIN_ID_MAP[batchItems[0].network],
    token:
      erc20TokensData[batchItems[0].token]?.erc20Address?.[
        upperCaseNetworkType(batchItems[0].network)
      ],
  });

  const paymentProps = useMemo(() => {
    const totalAmount = batchItems.reduce((accumulator, currentValue) => {
      return accumulator.plus(new BigNumber(currentValue.amount));
    }, new BigNumber(0));
    return {
      balance: tokenBalance?.formatted,
      totalAmount: totalAmount.toString(),
    };
  }, [batchItems, tokenBalance]);

  const _handlePay = () => {
    onBlockClosing(true);

    initTransaction({ recipients: batchItems })
      .then((proposalId) => {
        return onPaymentSuccessAsync({ aragonProposalId: proposalId });
      })
      .then(() => {
        setTransactionSuccessful(true);
      })
      .catch((err) => {
        console.log(err);
        setShowFailedMessage(true);
        sendTransactionLog(getAccessTokenSilently, {
          title: '[FAIL][BATCH CRYPTO CRYPTO]',
          aragonContractAddress,
          chainId: chain?.id,
          batchItems,
          chainName: chain?.name,
          err,
        });
      })
      .finally(() => {
        onBlockClosing(false);
      });
  };

  return (
    <>
      <ConfirmInWalletOverlay
        show={isTransferTransactionLoading || onPaymentSuccessAsyncLoading}
      />
      <WaitingSuccessConfirmation
        title="Multisig Payment Initiated"
        description="We will update the payment status once Aragon Transaction is signed by required owners."
        show={transactionSuccessful}
        onClose={onClose}
      />
      <FailedPayment onClose={onClose} show={showFailedMessage} />
      <AragonAddressPicker
        onAddressSelect={(a) => setAragonContractAddress(a)}
        show={!aragonContractAddress}
      />

      <div>
        <div className="text-center">
          <Dialog.Title
            as="h3"
            className="mb-5 text-base font-semibold leading-6 text-slate-400">
            Payment Summary
          </Dialog.Title>

          <table className="w-[100%]">
            <thead className="text-xs font-normal text-slate-400">
              <tr className="">
                <th className="py-0 text-left font-normal">Recipient</th>
                <th className="font-normal text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              {batchItems.map(({ to, amount, token }, index) => (
                <tr key={index} className="text-sm text-slate-300">
                  <td className=" text-left font-normal">
                    {to.slice(0, 6)} ... {to.slice(to.length - 6)}
                  </td>
                  <td className="text-right text-xs text-slate-300">
                    <div className="flex items-center justify-end">
                      <span className="text-sm mr-1">{amount}</span>{' '}
                      <span className="text-slate-400">
                        {getTokenHumanReadableNameByChain(
                          token,
                          batchItems[0].network
                        )}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="my-4 border-b border-slate-600"></div>

          <div className="flex items-center justify-between text-left text-sm">
            <span className="text-xs text-slate-400">Total Amount To Pay</span>

            <div className="flex items-center text-xs text-slate-300">
              <div className="mr-1 h-4 w-4">
                <img
                  src={TokenLogoMap[batchItems[0].token]}
                  alt=""
                  aria-hidden="true"
                />
              </div>
              <span className="text-sm mr-1">{paymentProps.totalAmount}</span>{' '}
              <span className="text-slate-400">
                {getTokenHumanReadableNameByChain(
                  batchItems[0].token,
                  batchItems[0].network
                )}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between pt-1 text-left text-sm">
            <span className="text-xs text-slate-400">Payment Network</span>
            <div className="flex items-center text-xs text-slate-300">
              <div className="mr-1 h-4 w-4">
                <img
                  src={NetworkLogoMap[batchItems[0].network]}
                  alt=""
                  aria-hidden="true"
                />
              </div>
              <span className="text-slate-400">{batchItems[0].network}</span>
            </div>
          </div>

          <div className="flex items-center justify-between pt-1.5 text-left text-sm">
            <span className="text-xs text-slate-400">Current Balance</span>
            <div className="flex items-center text-xs text-slate-300">
              <span className="text-slate-400">
                {formatDollarAmount(parseFloat(tokenBalance?.formatted))}
              </span>
            </div>
          </div>
        </div>

        {!!chain && (
          <WarningsSection
            connectedChain={chain}
            payeeNetwork={batchItems[0].network}
            payeeAmount={paymentProps.totalAmount}
            payeeToken={batchItems[0].token}
            paymentProps={paymentProps}
            showPotentialDuplicate={false}
          />
        )}

        <ConfirmationSection
          isConfirmed={isConfirmedByUser}
          setIsConfirmed={setIsConfirmedByUser}
          onConfirmationChange={_handleConfirmationChange}
        />
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <Button
          className={'justify-center'}
          disabled={!isConfirmedByUser}
          onClick={_handlePay}>
          Complete Payment
        </Button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:order-first sm:mt-0"
          onClick={() => {
            onClose();
          }}>
          Cancel
        </button>
      </div>
    </>
  );
};
