import { twMerge } from 'tailwind-merge';
import {
  getTokensByNetwork,
  NETWORKS,
  TokenLogoMap,
  NetworkLogoMap,
  upperCaseNetworkType,
  getTokenHumanReadableNameByChain,
} from 'features/bill/lib';
import { Select } from 'shared/ui/form/Select/RichSelect';

export const NetworkLabel = ({ network }) => {
  return (
    <div className="flex items-center">
      <div className="mr-2 h-4 w-4">
        <img
          src={NetworkLogoMap[upperCaseNetworkType(network)]}
          alt=""
          aria-hidden="true"
        />
      </div>
      <span>{upperCaseNetworkType(network)}</span>
    </div>
  );
};

export const TokenLabel = ({ token, tokenLabel }) => {
  return (
    <div className="flex items-center">
      <div className="mr-2 h-4 w-4">
        <img src={TokenLogoMap[token]} alt="" aria-hidden="true" />
      </div>
      <span>{tokenLabel}</span>
    </div>
  );
};

export const CryptoNetworkTokenSelect = ({
  network,
  allowedNetworks,
  token,
  allowedTokens,
  onNetworkChange,
  onTokenChange,
}) => {
  const _getNetworkList = () => {
    let res = NETWORKS;

    if (allowedNetworks) {
      res = res.filter((n) => allowedNetworks.includes(n));
    }

    return res;
  };

  const _getTokenList = (nVal) => {
    let res = getTokensByNetwork(nVal);

    if (allowedTokens) {
      res = res.filter((t) => allowedTokens.includes(t));
    }

    return res;
  };

  return (
    <div className="flex gap-4 flex-row">
      <div className="flex-1">
        <label className={twMerge('mb-1 block text-sm  dark:text-[#CBD5E1]')}>
          Network
        </label>
        <Select
          name="network"
          inputId="network"
          id="network"
          isSearchable={false}
          value={{
            value: network,
            label: <NetworkLabel network={network} />,
          }}
          onChange={(v) => {
            onNetworkChange(v.value, _getTokenList(v.value)[0]);
          }}
          maxMenuHeight={210}
          options={_getNetworkList().map((network) => ({
            value: network,
            label: <NetworkLabel network={network} />,
          }))}
          placeholder="Select Network"
        />
      </div>

      <div className="flex-1">
        <label className={twMerge('mb-1 block text-sm  dark:text-[#CBD5E1]')}>
          Token
        </label>
        <Select
          name="token"
          inputId="token"
          id="token"
          isSearchable={false}
          value={{
            value: token,
            label: (
              <TokenLabel
                tokenLabel={getTokenHumanReadableNameByChain(token, network)}
                token={token}
              />
            ),
          }}
          onChange={(v) => {
            onTokenChange(v.value);
          }}
          maxMenuHeight={210}
          options={_getTokenList(network).map((token) => ({
            value: token,
            label: (
              <TokenLabel
                tokenLabel={getTokenHumanReadableNameByChain(token, network)}
                token={token}
              />
            ),
          }))}
          placeholder="Select Token"
        />
      </div>
    </div>
  );
};
