import { useCurrentUserDetails } from 'entities/user/model';
import React, { useEffect, useState } from 'react';
import { Modal } from 'shared/ui';
import CompanyCreateForm from 'widgets/Organization/CompanyCreateForm';

import { PaymentsOnboardingModal } from '../payments/paymentModals/PaymentsOnboardingModal';

export const WithOrganization = ({ children }) => {
  const { hasLoggedInUser, hasAnyOrganization } = useCurrentUserDetails();
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showPaymentsOnboardingModal, setShowPaymentsOnboardingModal] =
    useState(false);

  useEffect(() => {
    if (hasLoggedInUser && !hasAnyOrganization) {
      setShowOrgModal(true);
    } else if (showOrgModal && hasAnyOrganization) {
      setShowOrgModal(false);
      setShowPaymentsOnboardingModal(true);
    }
  }, [hasLoggedInUser, hasAnyOrganization, showOrgModal]);

  const _handleClose = () => {
    if (hasAnyOrganization) {
      setShowOrgModal(false);
    }
  };

  const _handleClosePaymentsOnboarding = () => {
    setShowPaymentsOnboardingModal(false);
  };

  return (
    <>
      {children}

      <Modal show={showOrgModal} handleClose={_handleClose}>
        <div className="pt-6 px-4 max-w-5xl dark:bg-slate-800">
          <CompanyCreateForm variant="confirm-details" />
        </div>
      </Modal>

      <PaymentsOnboardingModal
        show={showPaymentsOnboardingModal}
        onClose={_handleClosePaymentsOnboarding}
      />
    </>
  );
};
