import { Link } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';

export default function EmptyItems() {
  return (
    <div className="mt-20 text-center">
      <h3 className="mt-2 font-semibold text-slate-200">
        You have no recurrent invoices.
      </h3>
      <p className="text-slate-400">
        Please add an invoice and select a recurrence schedule to view it here.
      </p>
      <Button
        color="gray"
        className="mt-2 inline-flex items-center justify-center"
        as={Link}
        to="/dashboard/invoices">
        Go to Invoices
      </Button>
    </div>
  );
}
