import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { useCurrentUser } from "entities/user/model";
import { useAuth0 } from "@auth0/auth0-react";
import { useBillMutations } from "entities/bill/model";

const FileUploadForm = ({ setShowForm, bill }) => {
  const { data } = useCurrentUser();
  const { invalidate } = useBillMutations();

  const { getAccessTokenSilently } = useAuth0();

  const handleSubmit = async (values, { setSubmitting }) => {
    // Make a POST axios request to the  // POST /bills/attach-file endpoint
    // with the file_name and file as the body
    // and then setSubmitting to false
    const formData = new FormData();
    formData.append("user_email", data.email);
    formData.append("file_name", values.file_name);
    formData.append("bill_id", bill._id);
    formData.append("file", values.file);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/bills/attach-file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        invalidate();
        // update the bills state with the new bill data
        // setBills((prevBills) => {
        //   const newBills = prevBills.map((prevBill) => {
        //     if (prevBill._id === bill._id) {
        //       return response.data;
        //     }
        //     return prevBill;
        //   });
        //   return newBills;
        // });
        setShowForm(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{ file_name: "", file: null }}
      validate={(values) => {
        const errors = {};
        if (!values.file_name) {
          errors.file_name = "Required";
        }
        // check if the file is a pdf
        if (values.file && values.file.type !== "application/pdf") {
          errors.file = "Please upload a pdf file";
        }
        return errors;
      }}
      onSubmit={handleSubmit}>
      {({ isSubmitting, values, handleChange, setFieldValue }) => (
        <Form>
          <div>
            <div className="">
              <label className="block text-left text-sm font-bold text-[#CBD5E1]">
                File Name
              </label>
              <input
                id="file_name"
                name="file_name"
                value={values.file_name}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] py-1 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <p className="mt-2 text-left text-xs text-[#94A3B8]">
                The name of the file you want to upload.
              </p>
              <ErrorMessage
                name="file_name"
                component="div"
                className="mt-2 text-left text-xs italic text-red-500"
              />
            </div>
            <div className="col-span-6 mt-4">
              <label className="block text-left text-sm font-medium text-gray-700 dark:text-white">
                Attach File
              </label>
              <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                {values.file ? (
                  <div className="space-y-1 text-center">
                    {/* Checkmark svg */}
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-200"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true">
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className="flex justify-center text-sm text-gray-600 dark:text-gray-200">
                      <label className="relative cursor-pointer rounded-md font-medium text-indigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-300">
                        <span>Remove File</span>
                        <button
                          onClick={(e) => {
                            setFieldValue("file", null);
                          }}
                          id="file"
                          name="file"
                          className="sr-only"
                        />
                      </label>
                    </div>
                    <p className="text-xs  text-white">{values.file.name}</p>
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="mt-2 text-xs italic text-red-500"
                    />
                  </div>
                ) : (
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-200"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true">
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className="flex text-sm text-gray-600 dark:text-gray-200">
                      <label className="relative cursor-pointer rounded-md   font-medium text-indigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-300">
                        <span>Upload a file</span>
                        <input
                          onChange={(e) => {
                            setFieldValue("file", e.currentTarget.files[0]);
                          }}
                          id="file"
                          name="file"
                          type="file"
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PDF up to 10MB</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 text-left ">
            <button
              type="submit"
              //   disabled={isSubmitting}
              className="mr-2 rounded-lg bg-indigo-500 px-5 py-2.5 text-center text-xs font-medium text-white hover:bg-indigo-400 focus:outline-none focus:ring-4 focus:ring-blue-800 ">
              Upload File
            </button>{" "}
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setShowForm(false);
              }}
              className="rounded-lg bg-gray-500 px-5 py-2.5 text-center text-xs font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-800 ">
              Cancel
            </button>{" "}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FileUploadForm;
