import { EmptyClientsComponent } from './EmptyClientsComponent';
import { useOrganizationClients } from './useClients';
import { Table } from 'shared/ui/Table/TableBlocks';
import { useMemo, useState } from 'react';
import {
  ContentCotainer,
  ContentLoadingState,
} from 'shared/ui/layouts/PageContent';
import { EditClientModal } from './EditClientModal';

const MyClientsPage = () => {
  const { clients, isLoading } = useOrganizationClients();
  const [clientToEdit, setClientToEdit] = useState(null);

  const _getRowActions = (row) => {
    const actions = [
      {
        label: 'Edit Client Details',
        onClick: () => {
          setClientToEdit(row);
        },
      },
    ];

    if (
      row.clientOrganizationDetails?.paymentRequestLinks &&
      row.clientOrganizationDetails?.paymentRequestLinks.length &&
      row.clientOrganizationDetails?.paymentRequestLinks[0].url
    ) {
      actions.push({
        label: 'Request Payment',
        onClick: () => {
          const newWindow = window.open(
            row.clientOrganizationDetails.paymentRequestLinks[0].url,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) newWindow.opener = null;
        },
      });
    }

    return actions;
  };

  const columns = useMemo(
    () => [
      {
        header: 'Client Name',
        cell: ({ row: { original: item } }) => (
          <div className="font-medium text-white">{item.name}</div>
        ),
      },
      {
        header: 'Billing Email',
        cell: ({ row: { original: item } }) => item.billingEmail || item.email,
      },
      {
        header: 'actions',
      },
    ],
    []
  );

  if (isLoading) {
    return <ContentLoadingState />;
  }

  if (!clients || clients.length === 0) {
    return <EmptyClientsComponent />;
  }

  return (
    <ContentCotainer>
      <Table
        title="Clients"
        description="Access and manage your clients here."
        data={clients}
        columns={columns}
        getRowActions={_getRowActions}
        onRowClick={() => {}}
      />

      <EditClientModal
        client={clientToEdit}
        onClose={() => {
          setClientToEdit(null);
        }}
      />
    </ContentCotainer>
  );
};

export default MyClientsPage;
