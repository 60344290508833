import { ClockIcon, CreditCardIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import SlideOver from 'shared/ui/SlideOver';
import { WithOrgPermissions } from 'shared/ui/WithOrgPermissions';
import { DetailSection } from 'shared/components/DetailSection';
import { tokenToImage } from 'shared/lib';
import { useDialog } from 'shared/components/Dialog';
import { toast } from 'react-toastify';
import { useExpenses, useDownloadExpenseAttachment } from './useExpenses';
import useToggle from 'shared/hooks/useToggle';
import { ExpensePaymentModal } from 'widgets/Payments/ExpensePaymentModal';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { triggerFileDownload } from 'shared/lib';
import { Button as UIKitButton } from 'shared/ui/Button/Button';

const generateTransactionUrl = (network, transactionHash) => {
  const baseUrls = {
    ethereum: 'https://etherscan.io/tx/',
    polygon: 'https://polygonscan.com/tx/',
  };
  return `${baseUrls[network]}${transactionHash}`;
};

export const ExpenseDetailsSlideOver = ({
  show,
  onPayExpense,
  expenseId,
  onClose,
}) => {
  const dialog = useDialog();
  const { deleteExpense, refreshExpenses, getExpense } = useExpenses();
  const [cryptoModalOpened, toggleCryptoPaymentModal] = useToggle(false);
  const { download, isDownloading } = useDownloadExpenseAttachment();
  const expenseData = getExpense(expenseId);

  const handleDelete = async () => {
    const confirmationText = 'Are you sure you want to delete this expense?';

    dialog.confirm({
      title: 'Delete Expense',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await deleteExpense(expenseId);
          await refreshExpenses();
          toast.success('Expense Deleted!');
          onClose();
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const handleDownload = (fileId, contentType) => {
    if (!isDownloading) {
      download({ fileId, contentType }).then((data) => {
        triggerFileDownload(fileId, data);
      });
    }
  };

  // Renderers

  const renderPaymentSection = () => {
    return (
      <DetailSection header="Payment">
        {expenseData.paymentStatus === 'paid' && (
          <>
            <DetailSection.Item term="Status">
              <span className="flex items-center justify-end text-green-500">
                <CheckCircleIcon className="mr-1 inline h-4 w-4" />
                Paid
              </span>
            </DetailSection.Item>

            {expenseData.paymentDetails?.txHash && (
              <DetailSection.Item term="Tx Hash">
                <a
                  href={generateTransactionUrl(
                    expenseData.paymentNetwork.network,
                    expenseData.paymentDetails?.txHash
                  )}
                  rel="noreferrer"
                  target="_blank"
                  className="hover:underline">
                  {expenseData.paymentNetwork === 'ethereum'
                    ? 'Etherscan'
                    : 'Polygonscan'}
                </a>
              </DetailSection.Item>
            )}
          </>
        )}

        {expenseData.paymentStatus !== 'paid' &&
          ![
            'waiting_aragon_provider_unknown_action',
            'processing_payment',
          ].includes(expenseData.paymentStatus) && (
            <div className="py-2">
              <UIKitButton
                color="indigo"
                onClick={() => {
                  onPayExpense(expenseData);
                  onClose();
                }}>
                <CreditCardIcon className="w-5 mr-2" /> Pay{' '}
                {expenseData?.paymentAmount} {expenseData?.paymentCurrency}
              </UIKitButton>
            </div>
          )}

        {[
          'waiting_aragon_provider_unknown_action',
          'processing_payment',
        ].includes(expenseData.paymentStatus) && (
          <div className="flex items-center">
            <ClockIcon className="inline h-4 w-4 mr-1" />
            Processing
          </div>
        )}
      </DetailSection>
    );
  };

  return (
    <SlideOver show={show} onClose={onClose}>
      <SlideOver.Header>
        <SlideOver.Title>
          <h2>Expense Report</h2>
        </SlideOver.Title>
        <SlideOver.Actions></SlideOver.Actions>
      </SlideOver.Header>

      {!!expenseData && (
        <div className="relative mb-40 flex-1 space-y-6 px-6 pt-6">
          <DetailSection header="General">
            <DetailSection.Item term="Employee">
              {expenseData?.employeeFirstName} {expenseData?.employeeLastName}
            </DetailSection.Item>

            <DetailSection.Item term="Title">
              {expenseData?.reportTitle}
            </DetailSection.Item>

            <DetailSection.Item term="Payment Amount">
              {tokenToImage(expenseData?.paymentCurrency)}
              <span>
                {expenseData?.paymentAmount} {expenseData?.paymentCurrency}
              </span>
            </DetailSection.Item>

            <DetailSection.Item term="Payment Network">
              <span>
                {expenseData.paymentNetwork[0].toUpperCase() +
                  expenseData.paymentNetwork.slice(1)}
              </span>
            </DetailSection.Item>
          </DetailSection>

          <DetailSection header="Expense Items">
            <table className="w-[100%] ">
              <thead className="text-sm bg-slate-700  text-slate-300">
                <tr className="">
                  <th className="py-2 pl-3 text-left font-normal">Note</th>
                  <th className="py-2 pl-3 text-left font-normal">Amount</th>
                  <th className="py-2 pl-3 text-left font-normal">Category</th>
                  <th className="pr-3 font-normal">Attachment</th>
                </tr>
              </thead>
              <tbody className="bg-slate-60">
                {expenseData.expenses.map(
                  (
                    {
                      expenseCurrency,
                      attachments = [],
                      totalAmount,
                      note,
                      category,
                    },
                    i
                  ) => {
                    return (
                      <tr key={i} className="text-sm text-slate-300">
                        <td className="py-2 pl-3 text-left font-normal">
                          {note}
                        </td>
                        <td className="pl-3 text-left">
                          <span className="mr-1">
                            {tokenToImage(expenseCurrency)}
                          </span>
                          <span className="mr-2 dark:text-white">
                            {totalAmount}
                          </span>
                        </td>
                        <td className="pl-3 text-left">{category}</td>
                        <td className="pr-3">
                          {attachments.length && attachments[0].fileId ? (
                            <span
                              onClick={(e) =>
                                handleDownload(
                                  attachments[0].fileId,
                                  attachments[0].contentType
                                )
                              }
                              className="flex cursor-pointer justify-end items-center underline hover:text-blue-500">
                              <DocumentCheckIcon className="mr-0.5 inline h-3 w-3" />
                              Download
                            </span>
                          ) : (
                            <>n/a</>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </DetailSection>

          {renderPaymentSection()}
          <WithOrgPermissions>
            <DetailSection header="Other Actions">
              <DetailSection.Item term="Delete Expense">
                <button
                  onClick={handleDelete}
                  type="button"
                  className="font-medium text-rose-500  hover:text-rose-400">
                  Delete Now
                </button>
              </DetailSection.Item>
            </DetailSection>
          </WithOrgPermissions>

          <ExpensePaymentModal
            expense={expenseData}
            onClose={toggleCryptoPaymentModal}
            show={cryptoModalOpened}
          />
        </div>
      )}
    </SlideOver>
  );
};
