import { useQuery } from "@tanstack/react-query";
import { useOrganizationActions } from "./actions";
import { useCurrentUser } from "entities/user/model";
import { QUERY_KEY, QUERY_KEY_PAYMENT_DETAILS } from "./constants";

export const useOrganizations = () => {
  const { fetchAllAction } = useOrganizationActions();
  const { data } = useCurrentUser();

  return useQuery({
    queryKey: [QUERY_KEY, data],
    queryFn: fetchAllAction,
    enabled: !!data.nickname,
  });
};

export const useOrganization = () => {
  const { fetchAction } = useOrganizationActions();
  const { data } = useCurrentUser();

  return useQuery({
    queryKey: [QUERY_KEY, data],
    queryFn: () => fetchAction(),
    enabled: !!data.nickname,
  });
};

export const usePaymentDetails = () => {
  const { fetchPaymentDetailsAction } = useOrganizationActions();
  const { data } = useCurrentUser();

  return useQuery({
    queryKey: [QUERY_KEY_PAYMENT_DETAILS, data],
    queryFn: () => fetchPaymentDetailsAction(),
    enabled: !!data.nickname,
  });
};
