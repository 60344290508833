import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export const useCreateTransfer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    queryKey: ['create-transfer'],
    mutationFn: async (body) => {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/asset-transfers`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return res.data?.result;
    },
  });

  return {
    createTransfer: mutation.mutateAsync,
    isPending: mutation.isPending,
  };
};
