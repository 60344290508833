import { Link } from 'react-router-dom';
import logoPurple2 from 'shared/images/logoPurpleBlackBackground2.png';
import { Button } from 'shared/ui';
import { useEffect } from 'react';

const LegalTermsPage = () => {
  useEffect(() => {}, []);
  window.location.href =
    'https://fractal-payments.notion.site/Terms-Conditions-90be201bfcb04cb683b00fc3e81c4a14';
  return null;
  return (
    <div className="flex justify-center bg-gray-900 ">
      <div className="w-full max-w-2xl px-5 ">
        <div className="mb-4 text-left ">
          <img
            src={logoPurple2}
            alt="Company logo"
            className="h-25 mx-auto my-auto w-auto max-w-sm "
          />{' '}
          <p className="py-4 text-sm text-gray-300">
            <strong>Fractal 3 Ltd - Terms of Service</strong>
            <br />
            Last Updated: 12th of December, 2023
            <br />
            <br />
            These Terms of Service ("Terms") govern your use of our platform
            (the "Platform") and the services provided by Fractal 3 Ltd ("we",
            "us", or "our"). By registering, accessing and/or using the
            Platform, you ("you", "your", or "User") agree to be bound by these
            Terms. If you do not agree with these Terms, you may not access or
            use the Platform.
            <br />
            <br />
            By accepting, or otherwise registering and/or accessing this
            service, you understand that the we are not a bank, money
            transmitter or custodian and that we don’t manage customer assets on
            their behalf or on behalf of third parties. We do not provide
            payments, tax or legal advice. You assume full responsibility to
            ensuring they are in good standing with the payment regulator and/or
            tax authorities of relevant to your jurisdiction.
            <br />
            <br />
            <strong>1. Service Overview</strong>
            <br />
            Fractal 3 Ltd aims to provide a secure and efficient platform for
            automating invoice management and financial reconciliation. Our
            system is designed to simplify and enhance your financial workflows,
            focusing on efficient invoice management and financial
            reconciliation.
            <br />
            <br />
            <strong>Security & Privacy</strong>
            <br />
            We prioritize the security and privacy of your data, adhering to the
            highest standards. Please note, while we facilitate the management
            of financial transactions, we do not directly process payments.
            <br />
            <br />
            <strong>Joining Fractal 3 Ltd</strong>
            <br />
            We highly value your trust and adherence to regulations. As such, we
            conduct a clear and straightforward verification process for every
            business, as mandated by financial regulators and institutions.
            <br />
            <br />
            <strong>Transaction Management</strong>
            <br />
            Transactions once processed are final, but our dedicated team is
            always ready to assist with any discrepancies.
            <br />
            <br />
            <strong>Service Adaptability</strong>
            <br /> Our service is designed with flexibility in mind. Whether
            you're looking to modify your service or transition away, we respect
            your decisions and require only a standard notice as per our
            agreement, ensuring a smooth and secure data handling process.
            <br />
            <br />
            <strong>2. Account Registration and Security Agreement</strong>
            <br />
            To access and use the Platform, you must register and create an
            account ("Account"). By creating an Account, you explicitly
            represent and warrant that:
            <br />
            a) You have the legal authority to enter into these Terms on behalf
            of your company or organization.
            <br />
            b) All information provided during the registration process is
            accurate and up-to-date.
            <br />
            c) You will maintain the security of your Account and are
            responsible for all activities that occur under your Account. You
            explicitly agree that Fractal 3 Ltd shall not be held responsible
            for any unauthorized access or activity related to your Account.
            <br />
            If you believe that your account is no longer secure, then you
            should immediately notify us at pavel@getfractal.xyz. We reserve the
            right to suspend or terminate your Account if we suspect any
            unauthorized access or activity.
            <br />
            <br />
            <strong>3. Platform Access and Restrictions Agreement</strong>
            <br />
            Subject to these Terms, we grant you a limited, non-exclusive,
            non-transferable, revocable license to access and use the Platform
            solely for your internal business purposes. By using the Platform,
            you explicitly agree not to:
            <br />
            a) Use the Platform for any illegal or unauthorized purpose.
            <br />
            b) Attempt to gain unauthorized access to the Platform or its
            systems or networks.
            <br />
            c) Modify, copy, reproduce, or reverse engineer any part of the
            Platform. You further agree that Fractal 3 Ltd shall not be held
            responsible for any damages or losses resulting from your
            non-compliance with these restrictions.
            <br />
            <br />
            <strong>4. Third-Party Integrations Agreement</strong>
            <br />
            The Platform may be integrated with third-party services such as
            Xero, Intuit, or other accounting software. By using the Platform,
            you explicitly agree that your use of these third-party services is
            subject to their respective terms of service, and you are
            responsible for complying with any applicable terms. You further
            agree that Fractal 3 Ltd shall not be held responsible for any
            issues or liabilities arising from your use of or interaction with
            these third-party services.
            <br />
            <br />
            <strong>5. Data Protection and Privacy Agreement</strong>
            <br />
            We prioritize your privacy and data protection. By using the
            Platform, you explicitly agree to the collection, use, and
            disclosure of your information in accordance with our Privacy
            Policy, which is incorporated into these Terms by reference. We
            adhere to the principles of the General Data Protection Regulation
            (GDPR) and employ robust physical, electronic, and procedural
            safeguards to protect your information. However, Fractal 3 Ltd shall
            not be held responsible for any data breaches or other
            privacy-related issues beyond our reasonable control.
            <br />
            <br />
            <strong>6. Intellectual Property Rights Agreement</strong>
            <br />
            All intellectual property rights in the Platform, including but not
            limited to patents, copyrights, trademarks, and trade secrets, are
            owned by Fractal 3 Ltd or its licensors. Your use of the Platform
            does not grant you any ownership rights in the Platform or any
            related intellectual property. Any user-generated content remains
            the intellectual property of the respective user. Fractal 3 Ltd may
            use such user-generated content only as necessary for the provision
            of services on the Platform, unless explicitly agreed otherwise. You
            agree not to infringe upon any intellectual property rights owned by
            Fractal 3 Ltd or its licensors and to indemnify Fractal 3 Ltd from
            any claims, liabilities, or actions related to such infringement.
            <br />
            <br />
            <strong>7. Payment Terms Agreement</strong>
            <br />
            You agree to pay all applicable fees for your use of the Platform as
            specified during your subscription process. You explicitly agree
            that Fractal 3 Ltd reserves the right to modify its pricing and
            billing terms at any time upon prior notice to you.
            <br />
            <br />
            <strong>8. Term and Termination Agreement</strong>
            <br />
            These Terms will remain in effect for the duration of your
            subscription to the Platform. We reserve the right to terminate or
            suspend your access to the Platform due to non-payment, unlawful
            activities, violation of these Terms, or any conduct that harms the
            interests of Fractal 3 Ltd or our users. You will be given a
            reasonable notice and opportunity to rectify the situation, where
            possible, before such action is taken. Fractal 3 Ltd shall not be
            held responsible for any consequences resulting from the termination
            or suspension of your access to the Platform.
            <br />
            <br />
            <strong>9. Indemnification Agreement</strong>
            <br />
            You agree to indemnify, defend, and hold harmless Fractal 3 Ltd, its
            affiliates, and their respective officers, directors, employees, and
            agents from and against any and all claims, liabilities, damages,
            losses, or expenses arising out of or in connection with your use of
            the Platform or your violation of these Terms. You explicitly agree
            that this indemnification obligation shall apply to any claims or
            actions related to your misuse of the Platform, your non-compliance
            with these Terms, or any other liabilities arising from your use of
            the Platform.
            <br />
            <br />
            <strong>
              10. Limitation of Liability and Responsibility for Errors
              Agreement
            </strong>
            <br />
            Fractal 3 Ltd, its affiliates, officers, directors, employees,
            agents, and licensors expressly disclaim any and all liability for
            any indirect, incidental, special, consequential, or punitive
            damages, or any loss of profits, revenue, data, or use, arising out
            of or in connection with these Terms or your use of the Platform,
            whether in an action in contract, tort, or otherwise, even if we
            have been advised of the possibility of such damages. By using the
            Platform, you unequivocally acknowledge and agree that Fractal 3 Ltd
            shall not be responsible, under any circumstances, for any errors,
            inaccuracies, or mistakes in the bills and invoices generated by the
            software, payment errors, data loss, or any other issues or damages
            that may arise from your use of the Platform. You further
            acknowledge and agree that it is your exclusive responsibility to
            meticulously review and double-check all information contained in
            the bills and invoices generated by the software, as well as any
            payment calculations, transactions, and data storage, to ensure
            their accuracy, correctness, and completeness. By using the
            Platform, you irrevocably waive any and all claims, liabilities, and
            actions, present or future, against Fractal 3 Ltd related to errors,
            inaccuracies, or mistakes, including but not limited to errors in
            bills, errors in invoices, payment errors, data loss, or any other
            issues arising from your use of the Platform. You agree to
            indemnify, defend, and hold harmless Fractal 3 Ltd from any and all
            such claims, liabilities, or actions, including any associated
            costs, legal fees, and expenses. Your agreement to these Terms
            signifies that you fully understand and accept this comprehensive
            limitation of liability clause, covering errors in bills, errors in
            invoices, payment errors, data loss, and more. You knowingly and
            voluntarily agree to release and absolve Fractal 3 Ltd from any
            liability for such errors, mistakes, or issues arising from your use
            of the Platform. Fractal 3 Ltd's total liability under or in
            connection with these Terms whether in contract, tort (including
            negligence), breach of statutory duty, or otherwise shall in no
            circumstances exceed the amount paid by you for the services in the
            preceding twelve (12) months.
            <br />
            <br />
            <strong>11. Governing Law</strong>
            <br />
            These Terms shall be governed by and construed in accordance with
            the laws of the United Kingdom, without regard to its conflict of
            law principles. Any disputes arising out of or in connection with
            these Terms or your use of the Platform shall be subject to the
            exclusive jurisdiction of the courts located within the United
            Kingdom.
            <br />
            <br />
            <strong>12. Compliance with Laws</strong>
            <br />
            You agree to comply with all applicable laws, regulations, and rules
            in connection with your use of the Platform, including but not
            limited to the UK, U.S. and European data protection laws and
            regulations, as well as any applicable regulations and standards set
            forth by Xero and Intuit.
            <br />
            <br />
            <strong>13. Modifications to the Platform and Terms</strong>
            <br />
            We reserve the right, at our sole discretion, to modify or
            discontinue, temporarily or permanently, the Platform or any part
            thereof, with or without notice. We may also modify these Terms at
            any time, and such modifications shall be effective upon posting the
            updated Terms on our website. You agree to review the Terms
            periodically to be aware of any modifications and your continued use
            of the Platform shall be deemed your conclusive acceptance of the
            modified Terms.
            <br />
            <br />
            <strong>14. Force Majeure </strong>
            <br />
            Neither party shall be liable for any delay or failure to perform
            any obligation under these Terms due to causes beyond its reasonable
            control, including but not limited to labor disputes, war, acts of
            terrorism, civil unrest, or interruptions in telecommunications,
            transportation, or utilities.
            <br />
            <br />
            <strong>15. Severability</strong>
            <br />
            If any provision of these Terms is held to be unenforceable or
            invalid, that provision shall be enforced to the maximum extent
            permissible, and the remaining provisions of these Terms shall
            remain in full force and effect.
            <br />
            <br />
            <strong>16. No Waiver</strong>
            <br />
            No waiver of any provision of these Terms by Fractal 3 Ltd shall be
            deemed a further or continuing waiver of such provision or any other
            provision, and Fractal 3 Ltd’s failure to assert any right or
            provision under these Terms shall not constitute a waiver of such
            right or provision.
            <br />
            <br />
            <strong>
              17. Anti-Money Laundering (AML) and Know Your Customer (KYC)
              Policies
            </strong>
            <br />
            You explicitly agree and acknowledge that Fractal 3 Ltd is not
            responsible for carrying out AML and KYC processes. As the User, you
            are responsible for ensuring all relevant AML and KYC checks are
            carried out according to applicable laws and regulations in relation
            to all transactions initiated using our Platform. Fractal 3 Ltd
            shall not be held liable for any breaches of AML or KYC regulations
            committed by you or any other parties involved in transactions
            initiated using the Platform.
            <br />
            <br />
            <strong>18. Prohibited Activities Agreement</strong>
            <br />
            You explicitly agree not to use the Platform for any prohibited or
            high-risk activities. Prohibited activities include, but are not
            limited to:
            <br />
            <br />
            <ul>
              <li>
                &#x2022; Making payments through Fractal 3 Ltd for any illicit,
                fraudulent, or unlawful activities. This includes but is not
                limited to payments suspected to relate to proceeds of crime and
                /or on behalf of people or organisations in the Sanctions Lists.
              </li>
              <br />
              <li>
                &#x2022; Engaging in any activities that involve the use of the
                Platform for gambling, betting, or other similar games of
                chance.
              </li>
              <br />
              <li>
                &#x2022; Using the Platform in connection with the production,
                distribution, or promotion of adult content or services,
                including but not limited to pornography and adult live chat
                services.
              </li>
              <br />
              <li>
                &#x2022; Using the Platform in connection with the sale or
                distribution of tobacco products, alcoholic beverages, or any
                other controlled substances.
              </li>
              <br />
              <li>
                &#x2022; Engaging in any activities that involve the sale or
                distribution of weapons, including firearms, ammunition, or
                other related items.
              </li>
              <br />
              <li>
                &#x2022; Using the Platform in connection with any pyramid
                schemes, multi-level marketing businesses, or other similar
                ventures.
              </li>
              <br />
              <li>
                &#x2022; Using the Platform for any purpose or in any manner
                that involves the transmission of unsolicited commercial emails
                or "spam."
              </li>
              <br />
              <li>
                &#x2022; Using the Platform for any high-risk payment activities
                or any activities that may potentially pose a significant risk
                to the security or integrity of the Platform or its users.
              </li>
              <br />
              <li>
                &#x2022; Using the Platform for any purpose or in any manner
                that violates any applicable local, state, national, or
                international law or regulation, or that is otherwise prohibited
                under these Terms.
              </li>
              <br />
              <li>
                Support or perform any fraudulent activity including but not
                limited to impersonating any person or entity, claiming a false
                affiliation or identity, accessing the Platform account without
                permission.
              </li>
              <br />
              <li>
                Attempt to do any of the acts described in the Prohibited
                Activities Agreement or assist or permit any person in engaging
                in any of the acts in the Prohibited Activities Agreement.
              </li>
            </ul>
            <br />
            You explicitly agree that Fractal 3 Ltd reserves the right to
            monitor your use of the Platform to ensure compliance with this
            Prohibited Activities Agreement. If you engage in any of the
            prohibited activities, we may, at our sole discretion, terminate
            your access to the Platform, remove any offending content, and take
            appropriate legal actions.
            <br />
            <br />
            This list is not exhaustive, and we reserve the right to add to or
            modify this list of prohibited activities at any time, without
            notice. If you are unsure whether your activity is prohibited under
            these Terms, please contact us at pavel@getfractal.xyz before
            proceeding.
            <br />
            <br />
            <strong>19. Entire Agreement</strong>
            <br />
            These Terms, together with any applicable Privacy Policy and any
            other agreements expressly incorporated by reference, constitute the
            entire agreement between you and Fractal 3 Ltd regarding your use of
            the Platform, and supersede all prior or contemporaneous
            communications, agreements, or understandings between you and
            Fractal 3 Ltd with respect to such subject matter.
            <br />
            <br />
            <strong>20. Contact Information</strong>
            <br />
            If you have any questions or concerns regarding these Terms, please
            contact us at pavel@getfractal.xyz.
            <br />
            <br />
            Thank you for reading our Terms and Conditions. By using the Fractal
            3 Ltd Platform, you acknowledge that you have read, understood, and
            agreed to be bound by these Terms. We look forward to providing you
            with a secure and efficient payment processing solution.
            <br />
            <br /> If you have any questions or concerns regarding these Terms,
            please contact us at pavel@getfractal.xyz.
          </p>
          {/* Button to go back to previous page */}
          <div className="flex justify-center py-4">
            <Link to="/">
              <Button size="xl">Back To Login</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalTermsPage;
