import {
  createPublicClient,
  encodeFunctionData,
  erc20Abi,
  http,
  zeroAddress,
} from 'viem';
import { polygon } from 'viem/chains';
import {
  createPasskeyValidator,
  getPasskeyValidator,
} from '@zerodev/passkey-validator';
import {
  createKernelAccount,
  createKernelAccountClient,
  createZeroDevPaymasterClient,
} from '@zerodev/sdk';
import { useMutation } from '@tanstack/react-query';
import erc20TokensData from '../../shared/constants/erc20TokensData';
import { ethers } from 'ethers';
import { bundlerActions, ENTRYPOINT_ADDRESS_V07 } from 'permissionless';
import { pimlicoBundlerActions } from 'permissionless/actions/pimlico';

const entryPoint = ENTRYPOINT_ADDRESS_V07;

const BUNDLER_URL =
  'https://rpc.zerodev.app/api/v2/bundler/5b892b35-4c5a-40d0-baee-f80ec752b499?provider=PIMLICO';
const PAYMASTER_URL =
  'https://rpc.zerodev.app/api/v2/paymaster/5b892b35-4c5a-40d0-baee-f80ec752b499?provider=PIMLICO';
const PASSKEY_SERVER_URL =
  'https://passkeys.zerodev.app/api/v3/5b892b35-4c5a-40d0-baee-f80ec752b499';

const publicClient = createPublicClient({
  transport: http(BUNDLER_URL),
});

const CHAIN = polygon;

const formPasskeyName = ({ accountName, accountCurrency }) =>
  `${accountName.trim()} - ${accountCurrency}`;

export const useCreateAccountAbstractionWithPassKeySigner = () => {
  const createAccountMutation = useMutation({
    mutationKey: ['zerodev-create-aa'],
    mutationFn: async ({ accountName, accountCurrency }) => {
      /** basic account preparations */
      const passkeyValidator = await createPasskeyValidator(publicClient, {
        // should be unique for every new bank account
        passkeyName: formPasskeyName({ accountName, accountCurrency }),
        passkeyServerUrl: PASSKEY_SERVER_URL,
      });

      const kernelAccount = await createKernelAccount(publicClient, {
        plugins: {
          sudo: passkeyValidator,
        },
      });

      const kernelClient = createKernelAccountClient({
        account: kernelAccount,
        chain: CHAIN,
        transport: http(BUNDLER_URL),
        sponsorUserOperation: async ({ userOperation }) => {
          const zerodevPaymaster = createZeroDevPaymasterClient({
            chain: CHAIN,
            transport: http(PAYMASTER_URL),
          });
          return zerodevPaymaster.sponsorUserOperation({
            userOperation,
          });
        },
      });

      /** Deploy contract via Fake operation */

      const bundlerClient = kernelClient
        .extend(bundlerActions(entryPoint))
        .extend(pimlicoBundlerActions(entryPoint));

      const gasPriceResult = await bundlerClient.getUserOperationGasPrice();

      await kernelClient.sendTransaction({
        to: zeroAddress,
        // eslint-disable-next-line no-undef
        value: BigInt(0),
        data: '0x',
        maxPriorityFeePerGas: gasPriceResult.standard.maxPriorityFeePerGas,
        maxFeePerGas: gasPriceResult.standard.maxFeePerGas,
        // maxPriorityFeePerGas: 34300320918,
      });

      return {
        accountAddress: kernelClient.account.address,
        accountNetwork: 'polygon',
        signerType: 'passkey',
        providerId: 'zero-dev',
      };
    },
  });

  return {
    createAccount: createAccountMutation.mutateAsync,
    isCreatingAccount: createAccountMutation.isPending,
  };
};

export const useTransferAccountAbstractionWithPassKeySigner = () => {
  const mutation = useMutation({
    mutationKey: ['zerodev-transfer-aa'],
    mutationFn: async ({ payout, accountName, accountCurrency }) => {
      const passkeyValidator = await getPasskeyValidator(publicClient, {
        passkeyName: formPasskeyName({ accountName, accountCurrency }),
        passkeyServerUrl: PASSKEY_SERVER_URL,
      });

      /** @type {CryptoPayoutDetails} */
      const payoutTyped = payout;
      const kernelAccount = await createKernelAccount(publicClient, {
        plugins: {
          sudo: passkeyValidator,
        },
      });

      const kernelClient = createKernelAccountClient({
        account: kernelAccount,
        chain: CHAIN,
        transport: http(BUNDLER_URL),
        sponsorUserOperation: async ({ userOperation }) => {
          const zerodevPaymaster = createZeroDevPaymasterClient({
            chain: CHAIN,
            transport: http(PAYMASTER_URL),
          });
          return zerodevPaymaster.sponsorUserOperation({
            userOperation,
          });
        },
      });

      const bundlerClient = kernelClient
        .extend(bundlerActions(entryPoint))
        .extend(pimlicoBundlerActions(entryPoint));

      const gasPriceResult = await bundlerClient.getUserOperationGasPrice();

      const hash = await kernelClient.sendTransaction({
        // dynamic in future
        to: erc20TokensData.USDC.erc20Address.Polygon,
        // eslint-disable-next-line no-undef
        value: BigInt(0),
        maxPriorityFeePerGas: gasPriceResult.standard.maxPriorityFeePerGas,
        maxFeePerGas: gasPriceResult.standard.maxFeePerGas,
        data: encodeFunctionData({
          abi: erc20Abi,
          functionName: 'transfer',
          args: [
            payoutTyped.walletAddress,
            ethers.utils.parseUnits(
              payoutTyped.amountStr,
              erc20TokensData.USDC.decimalsInteger
            ),
          ],
        }),
      });

      return {
        hash,
      };
    },
  });

  return {
    transferPayoutResult: mutation.data,
    transferPayout: mutation.mutateAsync,
    isPending: mutation.isPending,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};
