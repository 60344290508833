import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { isDev, isProd } from '../lib/env';

const BILLS_ANALYTICS = 'bills-analytics';
const ROLE_PERMISSIONS = 'role-permissions';
const PAY_LINK_AUTH_FLOW = 'pay-link-auth';
const SIMPLE_RECONCILIATION = 'simple-reconciliation';
const SIMPLE_RECONCILIATION_AUTO_FILLING = 'simple-reconciliation-auto-filling';
const RECONCILE_PAID_BILL = 'reconcile-paid-bills';
const RECONCILE_ATTACH_TRX_HASH = 'reconcile/attach-trx-hash';
const PAY_FIAT_BILLS = 'pay-fiat-bills';
const EASYPAY_ONRAMP = 'easypay-onramp';
const QUICK_BOOKS_ONLINE = 'quick-books-online';

const TEAM_USER_GOOGLE = 'google-oauth2|104069912629823869039';
const INTERNAL_USERS = [
  'auth0|6419acea68011b5232bc9bf5', // xero
  'auth0|646b9e8e6d3547d19f2a8e3f', // Illia
  'auth0|63ab64996b625aacfaf561a1', // Pavel
  'auth0|64a80070bb372f7bd88033e3', // Demo acc
  'auth0|63e22aeb4f8d40714b6eca55', // Demo acc
  'auth0|64c26728a9c248f782417668', // Team
];
const EAGLE_USERS = [
  'auth0|6401f05b3903c5cc387658f4',
  'auth0|6421be828cca2db234b5c37c',
  'auth0|642431b919607078dd5ba1f6',
];

const ZERION_USERS = ['auth0|64ad8ffac359b5dc9d467fa7'];

const featureFlags = {
  [BILLS_ANALYTICS]: {
    enabled: false,
    enabledInternally: true,
  },
  [ROLE_PERMISSIONS]: {
    enabled: false,
    enabledInternally: true,
  },
  [PAY_LINK_AUTH_FLOW]: {
    dev: {
      enabled: true,
    },
    prod: {
      enabled: true,
    },
  },
  [SIMPLE_RECONCILIATION]: {
    dev: {
      enabled: true,
    },
    prod: {
      enabled: true,
    },
  },
  [SIMPLE_RECONCILIATION_AUTO_FILLING]: {
    enabled: false,
    enabledForUsers: [...INTERNAL_USERS, ...EAGLE_USERS],
  },
  [RECONCILE_PAID_BILL]: {
    enabled: false,
    enabledForUsers: [...INTERNAL_USERS, ...ZERION_USERS],
  },
  [RECONCILE_ATTACH_TRX_HASH]: {
    enabled: false,
    enabledForUsers: [...INTERNAL_USERS, ...ZERION_USERS],
  },
  [PAY_FIAT_BILLS]: {
    enabled: false,
    enabledForUsers: [...INTERNAL_USERS],
  },
  [EASYPAY_ONRAMP]: {
    enabled: false,
    enabledForUsers: [TEAM_USER_GOOGLE],
  },

  [QUICK_BOOKS_ONLINE]: {
    enabled: false,
    enabledForUsers: [TEAM_USER_GOOGLE],
  },
};

export const useFeatureFlags = () => {
  const { user } = useAuth0();

  const _isFeatureEnabled = useCallback(
    (feature) => {
      if (!featureFlags[feature]) {
        throw new Error(`Feature flag ${feature} does not exist`);
      }

      const config = featureFlags[feature];

      if (config.dev && isDev()) {
        return config.dev.enabled;
      }

      if (config.prod && isProd()) {
        return config.prod.enabled;
      }

      return (
        config.enabled ||
        (config.enabledInternally && INTERNAL_USERS.includes(user?.sub)) ||
        (config.enabledForUsers && config.enabledForUsers.includes(user?.sub))
      );
    },
    [user]
  );

  return {
    isFeatureEnabled: _isFeatureEnabled,
  };
};

export const features = {
  BILLS_ANALYTICS,
  PAY_FIAT_BILLS,
  ROLE_PERMISSIONS,
  PAY_LINK_AUTH_FLOW,
  SIMPLE_RECONCILIATION,
  SIMPLE_RECONCILIATION_AUTO_FILLING,
  RECONCILE_PAID_BILL,
  RECONCILE_ATTACH_TRX_HASH,
  EASYPAY_ONRAMP,
  QUICK_BOOKS_ONLINE,
};
