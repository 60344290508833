import axios from 'axios';

import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useInvoiceActions } from './model';

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/api/public/invoices';

const remindInvoicePaymentRequest = async (token, invoiceId) => {
  try {
    const response = await axios.get(
      `${RESOURCE_URL}/${invoiceId}/remind-payment`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useRemindInvoicePayment = (invoiceId) => {
  const { getAccessTokenSilently } = useAuth0();
  const { invalidateInvoice } = useInvoiceActions();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['remind-invoice-payment'],
    mutationFn: () =>
      getAccessTokenSilently()
        .then((token) => remindInvoicePaymentRequest(token, invoiceId))
        .then(() => invalidateInvoice(invoiceId)),
  });

  return {
    remindInvoicePayment: mutateAsync,
    isLoading: isPending,
  };
};
