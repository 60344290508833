import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCurrentUser } from 'entities/user/model';
import { useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import Loading from 'shared/ui/Loading';
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';

export const useCurrentOrganizationCache = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['current-organization-id'],
    queryFn: () => localStorage.getItem('currentOrganizationId'),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  const saveCurrentOrganizationId = useCallback(
    (orgId) => {
      localStorage.setItem('currentOrganizationId', orgId);
      queryClient.invalidateQueries({ queryKey: ['current-organization-id'] });
    },
    [queryClient]
  );

  return {
    isCurrentOrganizationIdFetched: query.isFetched,
    currentOrganizationId: typeof query.data === 'string' ? query.data : null,
    saveCurrentOrganizationId,
  };
};

export const registerUser = async (
  auth0User,
  currentOrganizationId,
  getToken
) => {
  const token = await getToken();
  return axios
    .post(process.env.REACT_APP_SERVER_URL + '/user/create-user', auth0User, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const userData = auth0User;

      userData['user_id'] = response.data.user_id;
      userData['userId'] = response.data._id;
      userData['firstName'] = response.data.firstName;
      userData['lastName'] = response.data.lastName;
      userData['registered'] = true;

      if (response.data?.organizations?.length > 0) {
        const defaultOrg = response?.data?.organizations[0];
        const orgFromResponse = currentOrganizationId
          ? response.data.organizations.find(
              (o) => o._id === currentOrganizationId
            ) || defaultOrg
          : defaultOrg;

        userData['organizations'] = response.data.organizations;
        userData['selected_organization'] = orgFromResponse || {};
        userData['role'] = orgFromResponse.role;
      }

      return userData;
    })
    .catch((error) => {
      console.error(error);
    });
};

const WithAuthentication = ({ children }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
  } = useAuth0();
  const { data, setData } = useCurrentUser();
  const { isCurrentOrganizationIdFetched, currentOrganizationId } =
    useCurrentOrganizationCache();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    } else if (isAuthenticated && user && isCurrentOrganizationIdFetched) {
      // get token silently
      // get user with protected route
      registerUser(user, currentOrganizationId, getAccessTokenSilently).then(
        (userData) => {
          setData({
            ...data,
            ...userData,
          });
        }
      );
    }
  }, [
    isAuthenticated,
    currentOrganizationId,
    isCurrentOrganizationIdFetched,
    isLoading,
    loginWithRedirect,
    user,
    setData,
  ]);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default WithAuthentication;
