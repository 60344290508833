import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from '../../shared/ui/Button/Button';
import { Form, Formik } from 'formik';
import React from 'react';
import { Label } from 'flowbite-react';
import { Select } from '../../shared/ui/form/Select/Select';
import { TextArea } from '../../shared/ui/form/TextArea';

import * as Yup from 'yup';
import { useCurrentUserDetails } from '../../entities/user/model';
import { toast } from 'react-toastify';
import { useInvoiceMutations } from '../../entities/invoice/model';
export const FormSchema = Yup.object().shape({
  paymentStatus: Yup.string().oneOf(['Sent', 'Unpaid', 'Paid']).required(),
  cryptoTransactionHash: Yup.string().nullable(),
});

type Params = {
  invoice: Record<string, any>;
  onClose: any;
};

export const UpdateInvoicePaymentStatusModal: React.FC<Params> = ({
  invoice,
  onClose,
}) => {
  const { getCurrentOrganizationId } = useCurrentUserDetails();

  const { updatePaymentStatus } = useInvoiceMutations();
  const _handleSubmit = async (values: any) => {
    try {
      // @ts-ignore
      await updatePaymentStatus.mutateAsync({
        invoiceId: invoice._id,
        newStatus: values.paymentStatus,
        transactionHash: values.cryptoTransactionHash,
        organization: getCurrentOrganizationId(),
      });

      onClose();
      toast.success('Updated Invoice Payment Status');
    } catch (err) {
      toast.error('Failed to Update Invoice Payment Status');
    }
  };

  return (
    <Modal dismissible show={!!invoice} onClose={onClose}>
      <Formik
        validationSchema={FormSchema}
        initialValues={{
          paymentStatus: invoice?.payment?.status || 'Unpaid',
          cryptoTransactionHash: invoice?.payment?.txHash || '',
        }}
        onSubmit={_handleSubmit}>
        {({ isSubmitting, values, validateForm, setFieldValue, errors }) => {
          return (
            <Form>
              <Modal.Header className="dark:border-none">
                Update Payment Status
              </Modal.Header>
              <Modal.Body>
                <div className="grid gap-y-4">
                  <div className="grid text-left gap-y-2">
                    <Label className="!text-slate-400" value="Payment Status" />
                    <Select
                      name={'paymentStatus'}
                      id={'paymentStatus'}
                      disabled={isSubmitting}
                      sizing="md"
                      color="darkSlate"
                      value={values.paymentStatus}
                      onChange={(evt: any) => {
                        setFieldValue('paymentStatus', evt.target.value);
                      }}>
                      <option value="" disabled>
                        --
                      </option>

                      <option value="Paid">Paid</option>
                      <option value="Sent">Sent</option>
                      <option value="Unpaid">Unpaid</option>
                    </Select>
                  </div>
                  <div className="grid text-left gap-y-2">
                    <Label
                      className="!text-slate-400"
                      value="Transaction Hash (Optional)"
                    />

                    <TextArea
                      rows="4"
                      name={'cryptoTransactionHash'}
                      id={'cryptoTransactionHash'}
                      value={values.cryptoTransactionHash}
                      disabled={isSubmitting}
                      onChange={(evt: any) => {
                        setFieldValue(
                          'cryptoTransactionHash',
                          evt.target.value
                        );
                      }}
                      sizing="sm"
                      placeholder={'0x...'}
                      color="darkSlate"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-end dark:border-none">
                <Button color="slate" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isProcessing={isSubmitting}
                  type="submit"
                  color="indigo">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
