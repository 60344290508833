import SlideOver from '../../shared/ui/SlideOver';
import { DetailSection } from '../../shared/components';
import { dateFormatter } from '../../entities/bill/lib';
import { tokenToImage } from '../../shared/lib';
import { formatDollarAmount } from '../../shared/lib/string';
import { TransferStatusBadge } from './TransfersPage';
import { OnrampDepositDetailsSlideOverWidget } from '../bill/OnrampDepositDetails.SlideOverWidget';
import { toast } from 'react-toastify';
import { useDialog } from 'shared/components/Dialog';
import { useDeleteTransfer, useTransfers } from './useTransfers';
import { isCryptoCurrency } from '../bill/lib';

export const TransferOverviewSlideOver = ({ onClose, transfer }) => {
  const dialog = useDialog();
  const { invalidate } = useTransfers();
  const { deleteTransfer } = useDeleteTransfer();
  const handleDelete = async () => {
    const confirmationText = 'Are you sure you want to delete this transfer?';

    dialog.confirm({
      title: 'Delete transfer',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await deleteTransfer(transfer.id);
          await invalidate();
          toast.success('Transfer Deleted!');
          onClose();
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  return (
    <SlideOver show={!!transfer} onClose={onClose}>
      <SlideOver.Header>
        <SlideOver.Title>Transfer Overview</SlideOver.Title>
      </SlideOver.Header>

      {!!transfer && (
        <div className="relative mb-40 flex-1 space-y-6 px-6 pt-6">
          <DetailSection header="General">
            <DetailSection.Item term="Date">
              {dateFormatter(transfer?.createdAt)}
            </DetailSection.Item>

            <DetailSection.Item term="Amount">
              <div className="flex items-center">
                {tokenToImage(transfer?.destinationCurrency)}
                <span>
                  {formatDollarAmount(transfer?.transferAmount)}{' '}
                  {transfer?.destinationCurrency}
                </span>
              </div>
            </DetailSection.Item>
          </DetailSection>

          {isCryptoCurrency(transfer?.destinationCurrency) && (
            <OnrampDepositDetailsSlideOverWidget
              onrampPaymentStatus={transfer?.payablePaymentStatus}
              onrampPaymentMethod={transfer?.payablePaymentMethod}
              paymentProviderResponse={
                transfer?.payablePaymentPaymentProviderResponse
              }
            />
          )}

          <DetailSection header="Payment">
            <DetailSection.Item term="Status">
              <TransferStatusBadge transfer={transfer} />
            </DetailSection.Item>
          </DetailSection>

          <DetailSection header="Other Actions">
            <DetailSection.Item term="Delete Transfer">
              <button
                onClick={handleDelete}
                type="button"
                className="font-medium text-rose-500  hover:text-rose-400">
                Delete Now
              </button>
            </DetailSection.Item>
          </DetailSection>
        </div>
      )}
    </SlideOver>
  );
};
