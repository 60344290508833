import React, { useState } from 'react';
import { useCurrentUser } from 'entities/user/model';
import stringSimilarity from 'string-similarity';
import { Spinner } from 'shared/ui/Spinner';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import { useBillMutations } from 'entities/bill/model';

// sort through a list of suppliers, compare the supplierName to the bill.receiverName, calculate similarity score, return the sorted list of suppliers with the highest similarity score
export const findSupplier = (suppliers, bill) => {
  const sortedSuppliers = [];

  for (let supplier of suppliers) {
    // get similarity score

    const similarityScore = stringSimilarity.compareTwoStrings(
      supplier.supplierName.toLowerCase(),
      bill.receiverName.toLowerCase()
    );

    const newSupplier = {
      ...supplier,
      similarityScore: similarityScore,
    };

    // push supplier to sortedSuppliers array
    sortedSuppliers.push(newSupplier);
  }

  // sort suppliers by similarity score and then alphabetically
  sortedSuppliers.sort((a, b) => {
    if (a.similarityScore === b.similarityScore) {
      // If similarity scores are equal, sort alphabetically
      return a.supplierName.localeCompare(b.supplierName);
    } else {
      // Sort by similarity score
      return b.similarityScore - a.similarityScore;
    }
  });

  // Separate top 3 and the rest
  const top5Suppliers = sortedSuppliers.slice(0, 5);
  const remainingSuppliers = sortedSuppliers.slice(5);

  // Sort the remaining suppliers alphabetically
  remainingSuppliers.sort((a, b) =>
    a.supplierName.localeCompare(b.supplierName)
  );

  // Merge top 3 and the rest
  const finalSortedSuppliers = [...top5Suppliers, ...remainingSuppliers];

  const matchedSuppliers = finalSortedSuppliers.filter(
    (supplier) => supplier.emailAddress === bill.receiverEmail
  );

  return {
    matchedSupplier: matchedSuppliers.length === 1 ? matchedSuppliers[0] : null,
    sortedSuppliers: finalSortedSuppliers,
    top5Suppliers,
    remainingSuppliers,
  };
};

export const findCustomer = (customers, invoice) => {
  const sortedSuppliers = [];

  for (let supplier of customers) {
    // get similarity score
    const similarityScore = stringSimilarity.compareTwoStrings(
      supplier.customerName.toLowerCase(),
      invoice.client_email.toLowerCase()
    );

    const newSupplier = {
      ...supplier,
      similarityScore: similarityScore,
    };

    // push supplier to sortedSuppliers array
    sortedSuppliers.push(newSupplier);
  }

  // sort suppliers by similarity score and then alphabetically
  sortedSuppliers.sort((a, b) => {
    if (a.similarityScore === b.similarityScore) {
      // If similarity scores are equal, sort alphabetically
      return a.customerName.localeCompare(b.customerName);
    } else {
      // Sort by similarity score
      return b.similarityScore - a.similarityScore;
    }
  });

  // Separate top 3 and the rest
  const top5 = sortedSuppliers.slice(0, 5);
  const remaining = sortedSuppliers.slice(5);

  // Sort the remaining suppliers alphabetically
  remaining.sort((a, b) => a.customerName.localeCompare(b.customerName));

  // Merge top 3 and the rest
  const finalSortedSuppliers = [...top5, ...remaining];

  const matchedSuppliers = finalSortedSuppliers.filter(
    (supplier) => supplier.emailAddress === invoice.client_email
  );

  return {
    matched: matchedSuppliers.length === 1 ? matchedSuppliers[0] : null,
    sorted: finalSortedSuppliers,
    top5,
    remaining,
  };
};

export const filterTaxRatesForBills = (taxRates) => {
  const filteredTaxRates = taxRates.filter((taxRate) => {
    return taxRate.validDatatypeLinks.some((link) => {
      return link.links.includes('Bill.LineItems.TaxRateRef.Id');
    });
  });

  return filteredTaxRates;
};

export const filterAccountsByLink = (accounts) => {
  return accounts.filter((account) => {
    if (account.type === 'Expense' && account.validDatatypeLinks) {
      return account.validDatatypeLinks.some((link) => {
        return link.links.includes('Bill.LineItems.AccountRef.Id');
      });
    }
    return false;
  });
};

export const filterIncomeAccounts = (accounts) => {
  return accounts.filter((account) => account.type === 'Income');
};

export const filterBankAccounts = (accounts) => {
  return accounts.filter((account) => {
    return account.isBankAccount === true;
  });
};

export const createBillReference = (bill) => {
  const recieverName = bill.receiverName;
  const amount = bill.amount;
  const currency = bill.token;
  const reference = `${currency}-${amount}-${recieverName}`;
  return reference;
};

export default function BillReconcileForm({
  bill,
  taxRates,
  suppliers,
  accounts,
  supportedCurrencies,
  baseCurrency,
}) {
  const { invalidate: invalidateBills } = useBillMutations();
  const { getAccessTokenSilently } = useAuth0();
  const { sortedSuppliers, top5Suppliers, remainingSuppliers } = findSupplier(
    suppliers,
    bill
  );

  // set state variables from codat
  const [id, setId] = useState(bill.uuid);
  const [reference, setReference] = useState(createBillReference(bill));
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [supplierRef, setSupplierRef] = useState();
  const [supplierId, setSupplierId] = useState(sortedSuppliers[0]?.id || '');
  const [supplierName, setSupplierName] = useState(
    sortedSuppliers[0]?.supplierName || ''
  );
  const [issueDate, setIssueDate] = useState(bill.billDate);
  const [dueDate, setDueDate] = useState(bill.dueDate);

  // line items state variables
  const [lineItemDescription, setLineItemDescription] = useState(bill.services);
  const [lineItemUnitAmount, setLineItemUnitAmount] = useState(bill.subtotal);
  const [lineItemQuantity, setLineItemQuantity] = useState(1);
  const [lineItemSubTotal, setLineItemSubTotal] = useState(
    Number(bill.subtotal)
  );
  const [selectedTaxRate, setSelectedTaxRate] = useState({});
  const [lineItemTaxAmount, setLineItemTaxAmount] = useState();
  const [lineItemTaxId, setLineItemTaxId] = useState();
  const [lineItemTaxName, setLineItemTaxName] = useState('');
  const [lineItemTotal, setLineItemTotal] = useState(
    bill.token === 'ETH' ? Number(bill.dollarAmount) : Number(bill.amount)
  );

  // nominal account
  const [nominalAccount, setNominalAccount] = useState('');
  const [nominalAccountId, setNominalAccountId] = useState();
  const [nominalAccountName, setNominalAccountName] = useState();

  // withholding tax state variables
  const [withholdingTaxDescription, setWithholdingTaxDescription] =
    useState('Withholding Tax');
  const [withholdingTaxRate, setWithholdingTaxRate] = useState(0);

  // Bill information state variables
  const [status, setStatus] = useState('Draft');
  const [currency, setCurrency] = useState('USD');
  const [subTotal, setSubTotal] = useState(Number(bill.subtotal));
  const [taxAmount, setTaxAmount] = useState(
    bill.token === 'ETH'
      ? Number(bill.dollarAmount) - Number(bill.subtotal)
      : Number(bill.amount) - Number(bill.subtotal)
  );
  const [totalAmount, setTotalAmount] = useState(
    bill.token === 'ETH' ? Number(bill.dollarAmount) : Number(bill.amount)
  );

  // Bill payment state variables [paymentId, paymentNote, paymentReference, accountRefId, accountRefName, paymentCurrency, paymentCurrencyRate, paidOnDate, totalAmount]
  const [paymentId, setPaymentId] = useState();
  const [paymentNote, setPaymentNote] = useState();
  const [paymentReference, setPaymentReference] = useState();
  const [accountRefId, setAccountRefId] = useState();
  const [accountRefName, setAccountRefName] = useState();
  const [paymentCurrency, setPaymentCurrency] = useState();
  const [paymentCurrencyRate, setPaymentCurrencyRate] = useState();
  const [paidOnDate, setPaidOnDate] = useState();
  const [totalAmountPaid, setTotalAmountPaid] = useState();

  // Payment allocation state variables [paymentAllocationCurrency, paymentAllocationCurrencyRate, paymentAllocationDate, paymentAllocationTotalAmount]
  const [paymentAllocationCurrency, setPaymentAllocationCurrency] = useState();
  const [paymentAllocationCurrencyRate, setPaymentAllocationCurrencyRate] =
    useState();
  const [paymentAllocationDate, setPaymentAllocationDate] = useState();
  const [paymentAllocationTotalAmount, setPaymentAllocationTotalAmount] =
    useState();

  const [apiResponseRecieved, setApiResponseRecieved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // supplier state variables
  const [addSupplierManually, setAddSupplierManually] = useState(false);
  const [supplierNameInput, setSupplierNameInput] = useState(bill.receiverName);
  const [supplierEmailInput, setSupplierEmailInput] = useState(
    bill.receiverEmail
  );

  // api response
  const [apiResponse, setApiResponse] = useState();

  const { data } = useCurrentUser();

  // handle add supplier
  const handleAddSupplierManually = () => {
    setAddSupplierManually(true);
    setSupplierId('');
    setSupplierName('');
  };

  const filteredTaxRates = filterTaxRatesForBills(taxRates);

  // create bill reference function

  const expenseAccounts = filterAccountsByLink(accounts);

  const handleFormikSubmit = async (values) => {
    setIsLoading(true);
    const billDetails = {
      id: values.id,
      reference: values.reference,
      supplierRef: values.supplierRef,
      supplierId: values.supplierId,
      supplierName: values.supplierName,
      newSupplier: addSupplierManually,
      newSupplierName: values.newSupplierName,
      newSupplierEmail: values.newSupplierEmail,
      issueDate: values.issueDate,
      dueDate: values.dueDate,
      lineItemDescription: values.lineItemDescription,
      lineItemUnitAmount: values.lineItemUnitAmount,
      lineItemQuantity: values.lineItemQuantity,
      lineItemSubTotal: values.lineItemSubTotal,
      lineItemTaxAmount: values.lineItemTaxAmount,
      lineItemTaxId: values.lineItemTaxId,
      lineItemTaxName: values.lineItemTaxName,
      lineItemTotal: values.lineItemTotal,
      nominalAccountId: values.nominalAccountId,
      nominalAccountName: values.nominalAccountName,
      withholdingTaxDescription: values.withholdingTaxDescription,
      withholdingTaxRate: values.withholdingTaxRate,
      status: values.status,
      currency: values.currency,
      baseCurrency: baseCurrency,
      subTotal: values.subTotal,

      taxAmount: values.taxAmount,
      totalAmount: values.totalAmount,
      paymentId: values.paymentId,
      paymentNote: values.paymentNote,
      paymentReference: values.paymentReference,
      accountRefId: values.accountRefId,
      accountRefName: values.accountRefName,
      paymentCurrency: values.paymentCurrency,
      paymentCurrencyRate: values.paymentCurrencyRate,
      paidOnDate: values.paidOnDate,
      totalAmountPaid: values.totalAmountPaid,
      paymentAllocationCurrency: values.paymentAllocationCurrency,
      paymentAllocationCurrencyRate: values.paymentAllocationCurrencyRate,
      paymentAllocationDate: values.paymentAllocationDate,
      paymentAllocationTotalAmount: values.paymentAllocationTotalAmount,
    };

    const token = await getAccessTokenSilently();

    // use axios to post the bill details to the backend
    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + '/codat/create-bill',
      {
        billDetails: billDetails,
        userDetails: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setApiResponse(response.data);

    if (response.data.statusCode === 200) {
      setShowSuccessMessage(true);
      setShowErrorMessage(false);
      setIsLoading(false);
      setApiResponseRecieved(true);

      invalidateBills();
    } else {
      setShowSuccessMessage(false);
      setShowErrorMessage(true);
      setIsLoading(false);
      setApiResponseRecieved(false);
    }
  };

  return (
    <Formik
      initialValues={{
        id: id,
        reference: reference,
        supplierRef: supplierRef,
        supplierId: supplierId,
        supplierName: supplierName,
        newSupplier: addSupplierManually,
        newSupplierName: supplierNameInput,
        newSupplierEmail: supplierEmailInput,
        issueDate: issueDate,
        dueDate: dueDate,
        lineItemDescription: lineItemDescription,
        lineItemUnitAmount: lineItemUnitAmount,
        lineItemQuantity: lineItemQuantity,
        lineItemSubTotal: lineItemSubTotal,
        lineItemTaxAmount: lineItemTaxAmount,
        lineItemTaxId: lineItemTaxId,
        lineItemTaxName: lineItemTaxName,
        lineItemTotal: lineItemTotal,
        nominalAccountId: nominalAccountId,
        nominalAccountName: nominalAccountName,
        withholdingTaxDescription: withholdingTaxDescription,
        withholdingTaxRate: withholdingTaxRate,
        status: status,
        currency: 'USD',
        subTotal: subTotal,
        taxAmount: taxAmount,
        totalAmount: totalAmount,
        paymentId: paymentId,
        paymentNote: paymentNote,
        paymentReference: paymentReference,
        accountRefId: accountRefId,
        accountRefName: accountRefName,
        paymentCurrency: paymentCurrency,
        paymentCurrencyRate: paymentCurrencyRate,
        paidOnDate: paidOnDate,
        totalAmountPaid: totalAmountPaid,
        paymentAllocationCurrency: paymentAllocationCurrency,
        paymentAllocationCurrencyRate: paymentAllocationCurrencyRate,
        paymentAllocationDate: paymentAllocationDate,
        paymentAllocationTotalAmount: paymentAllocationTotalAmount,
      }}
      validate={(values) => {
        const errors = {};
        if (!values.reference) {
          errors.reference = 'Required';
        }
        if (!values.supplierId && values.newSupplier === false) {
          errors.supplierId = 'Required';
        }
        if (!values.supplierName && values.newSupplier === false) {
          errors.supplierName = 'Required';
        }
        if (!values.newSupplierName && values.newSupplier === true) {
          errors.newSupplierName = 'Required';
        }
        if (!values.newSupplierEmail && values.newSupplier === true) {
          errors.newSupplierEmail = 'Valid email required';
        }
        if (!values.issueDate) {
          errors.issueDate = 'Required';
        }
        if (!values.dueDate) {
          errors.dueDate = 'Required';
        }
        if (!values.lineItemDescription) {
          errors.lineItemDescription = 'Required';
        }
        if (!values.lineItemUnitAmount) {
          errors.lineItemUnitAmount = 'Required';
        }
        if (!values.lineItemQuantity) {
          errors.lineItemQuantity = 'Required';
        }
        if (!values.lineItemSubTotal) {
          errors.lineItemSubTotal = 'Required';
        }

        if (!values.lineItemTaxId) {
          errors.lineItemTaxId = 'Required';
        }
        if (!values.lineItemTaxName) {
          errors.lineItemTaxName = 'Required';
        }
        if (!values.lineItemTotal) {
          errors.lineItemTotal = 'Required';
        }
        if (!values.nominalAccountId) {
          errors.nominalAccountId = 'Required';
        }
        if (!values.status) {
          errors.status = 'Required';
        }
        if (!values.subTotal) {
          errors.subTotal = 'Required';
        }
        if (!values.taxAmount && values.taxAmount !== 0) {
          errors.taxAmount = 'Required';
        }
        if (!values.totalAmount) {
          errors.totalAmount = 'Required';
        }

        return errors;
      }}
      onSubmit={handleFormikSubmit}>
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Form>
          <div className="h-full sm:mt-0">
            <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-white ">
                    Make Payment
                  </h3>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                    Please fill in the form below to make a payment. Your
                    payment will be displayed in all payments section.
                  </p>
                </div>

                <div className="md:col-span-2 md:mt-0 ">
                  <form className="">
                    <div className="overflow-hidden shadow sm:rounded-md ">
                      <div className="   ">
                        <div className="grid grid-cols-6 gap-6">
                          <p className="col-span-6 font-medium text-[#CBD5E1]  ">
                            Bill Details
                            <br />
                          </p>
                          {/* <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium  ">
                        Bill ID *{" "}
                      </label>
                      <input
                        id="id"
                        name="id"
                        type="text"
                        value={id}
                        onChange={(event) => setId(event.target.value)}
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Identifier for the bill, unique for the company in the
                        accounting platform.
                      </p>
                    </div> */}

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Bill Reference *{' '}
                            </label>
                            <Field
                              id="reference"
                              name="reference"
                              type="text"
                              value={values.reference}
                              onChange={(event) => {
                                setFieldValue('reference', event.target.value);
                                setReference(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              User-friendly reference for the bill.
                            </p>
                            <ErrorMessage
                              name="reference"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          {!addSupplierManually && (
                            <div className="col-span-3">
                              <label className="block text-sm font-medium  text-[#CBD5E1]">
                                Choose Supplier *{' '}
                              </label>
                              <Field
                                as="select"
                                id="supplier"
                                name="supplier"
                                value={JSON.stringify(selectedSupplier)}
                                onChange={(e) => {
                                  const supplier = JSON.parse(e.target.value);
                                  setSelectedSupplier(supplier);
                                  setFieldValue('supplierId', supplier.id);
                                  setSupplierId(supplier.id);
                                  setFieldValue(
                                    'supplierName',
                                    supplier.supplierName
                                  );
                                  setSupplierName(supplier.supplierName);
                                }}
                                className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                                <option value="">Select Supplier</option>
                                <optgroup label="Top 5 Matches">
                                  {top5Suppliers.map((supplier) => (
                                    <option value={JSON.stringify(supplier)}>
                                      {supplier.supplierName}
                                    </option>
                                  ))}
                                </optgroup>
                                <optgroup label="Other Suppliers">
                                  {remainingSuppliers.map((supplier) => (
                                    <option value={JSON.stringify(supplier)}>
                                      {supplier.supplierName}
                                    </option>
                                  ))}
                                </optgroup>
                              </Field>
                              <p className="mt-2 text-sm  text-[#CBD5E1]">
                                Choose an existing supplier from the dropdown
                                list.
                              </p>
                              <ErrorMessage
                                name="supplier"
                                component="div"
                                className="mt-2 text-xs italic text-red-500"
                              />
                            </div>
                          )}

                          {/* Button to add supplier manually */}

                          {!addSupplierManually && (
                            <div
                              className={`col-span-${
                                addSupplierManually ? '6' : '3'
                              }`}>
                              {' '}
                              <label className="block text-sm font-medium  text-[#CBD5E1]">
                                Or Add New Supplier
                              </label>
                              <button
                                type="button"
                                className="mt-1 block w-full rounded-md border border-gray-300 p-1.5 text-left text-sm font-medium text-blue-700 shadow-sm hover:bg-blue-800 hover:text-white focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500 dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-600 dark:hover:text-white dark:focus:ring-blue-800 sm:text-sm"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setFieldValue('supplierId', '');
                                  setFieldValue('supplierName', '');
                                  setFieldValue('newSupplier', true);
                                  handleAddSupplierManually();
                                }}>
                                Add Supplier Manually
                              </button>
                              <p className="mt-1 text-sm  text-[#CBD5E1]">
                                Add a new supplier to the system.
                              </p>
                            </div>
                          )}

                          {/* Supplier ID and Name */}

                          {addSupplierManually && (
                            <>
                              <div className="col-span-6">
                                <label className="block text-sm font-medium  text-[#CBD5E1]">
                                  Supplier Name *{' '}
                                </label>
                                <Field
                                  id="newSupplierName"
                                  name="newSupplierName"
                                  type="text"
                                  value={values.newSupplierName}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'newSupplierName',
                                      event.target.value
                                    );
                                    setSupplierNameInput(event.target.value);
                                  }}
                                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <p className="mt-2 text-sm  text-[#CBD5E1]">
                                  Name of the supplier the bill was received
                                  from. Please use a unique name.
                                </p>
                                <ErrorMessage
                                  name="newSupplierName"
                                  component="div"
                                  className="mt-2 text-xs italic text-red-500"
                                />
                              </div>

                              <div className="col-span-6">
                                <label className="block text-sm font-medium  text-[#CBD5E1]">
                                  Supplier Email *{' '}
                                </label>
                                <Field
                                  id="newSupplierEmail"
                                  name="newSupplierEmail"
                                  type="text"
                                  value={values.newSupplierEmail}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'newSupplierEmail',
                                      event.target.value
                                    );
                                    setSupplierEmailInput(event.target.value);
                                  }}
                                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <p className="mt-2 text-sm  text-[#CBD5E1]">
                                  Name of the supplier the bill was received
                                  from. Email must be valid.
                                </p>
                                <ErrorMessage
                                  name="newSupplierEmail"
                                  component="div"
                                  className="mt-2 text-xs italic text-red-500"
                                />
                              </div>
                            </>
                          )}

                          {!addSupplierManually && (
                            <>
                              <div className="col-span-6">
                                <label className="block text-sm font-medium  text-[#CBD5E1]">
                                  Supplier ID (Read Only) *{' '}
                                </label>
                                <Field
                                  readOnly
                                  id="supplierId"
                                  name="supplierId"
                                  type="text"
                                  value={supplierId}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'supplierId',
                                      event.target.value
                                    );
                                    setSupplierId(event.target.value);
                                  }}
                                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <ErrorMessage
                                  name="supplierId"
                                  component="div"
                                  className="mt-2 text-xs italic text-red-500"
                                />
                                <p className="mt-2 text-sm  text-[#CBD5E1]">
                                  ID of the supplier the bill was received from.
                                </p>
                              </div>

                              <div className="col-span-6">
                                <label className="block text-sm font-medium  text-[#CBD5E1]">
                                  Supplier Name (Read Only) *{' '}
                                </label>
                                <Field
                                  readOnly
                                  id="supplierName"
                                  name="supplierName"
                                  type="text"
                                  value={supplierName}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'supplierName',
                                      event.target.value
                                    );
                                    setSupplierName(event.target.value);
                                  }}
                                  className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                <p className="mt-2 text-sm  text-[#CBD5E1]">
                                  Name of the supplier the bill was received
                                  from.
                                </p>
                                <ErrorMessage
                                  name="supplierName"
                                  component="div"
                                  className="mt-2 text-xs italic text-red-500"
                                />
                              </div>
                            </>
                          )}

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Issue Date *{' '}
                            </label>
                            <Field
                              id="issueDate"
                              name="issueDate"
                              type="date"
                              value={issueDate}
                              onChange={(event) => {
                                setFieldValue('issueDate', event.target.value);
                                setIssueDate(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Date of the bill as recorded in the accounting
                              platform.
                            </p>
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Due Date *{' '}
                            </label>
                            <Field
                              id="dueDate"
                              name="dueDate"
                              type="date"
                              value={dueDate}
                              onChange={(event) => {
                                setFieldValue('dueDate', event.target.value);
                                setDueDate(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Date the supplier is due to be paid.
                            </p>
                          </div>

                          {/* <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Currency
                      * </label>
                      <input
                        id="field"
                        name="field"
                        type="text"
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Currency of the bill.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Dollar Value
                      * </label>
                      <input
                        id="field"
                        name="field"
                        type="text"
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Dollar value of the bill.
                      </p>
                    </div> */}

                          <p className="col-span-6 font-medium text-[#CBD5E1]">
                            Bill Items
                          </p>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Description *{' '}
                            </label>
                            <Field
                              id="lineItemDescription"
                              name="lineItemDescription"
                              type="text"
                              value={lineItemDescription}
                              onChange={(event) => {
                                setFieldValue(
                                  'lineItemDescription',
                                  event.target.value
                                );
                                setLineItemDescription(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Friendly name of the goods or services received.
                            </p>
                            <ErrorMessage
                              name="lineItemDescription"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Unit Amount *{' '}
                            </label>
                            <Field
                              id="lineItemUnitAmount"
                              name="lineItemUnitAmount"
                              type="text"
                              value={lineItemUnitAmount}
                              onChange={(event) => {
                                setFieldValue(
                                  'lineItemUnitAmount',
                                  event.target.value
                                );
                                setLineItemUnitAmount(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Price of each unit of goods or services.
                            </p>
                            <ErrorMessage
                              name="lineItemUnitAmount"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Quantity *{' '}
                            </label>
                            <Field
                              id="lineItemQuantity"
                              name="lineItemQuantity"
                              type="text"
                              value={lineItemQuantity}
                              onChange={(event) => {
                                setFieldValue(
                                  'lineItemQuantity',
                                  event.target.value
                                );
                                setLineItemQuantity(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Number of units of goods or services received.
                            </p>
                            <ErrorMessage
                              name="lineItemQuantity"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Sub Total *{' '}
                            </label>
                            <Field
                              id="lineItemSubTotal"
                              name="lineItemSubTotal"
                              type="text"
                              value={lineItemSubTotal}
                              onChange={(event) => {
                                setFieldValue(
                                  'lineItemSubTotal',
                                  event.target.value
                                );
                                setLineItemSubTotal(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Amount of the line, inclusive of discounts but
                              exclusive of tax.
                            </p>
                            <ErrorMessage
                              name="lineItemSubTotal"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Tax Rate *{' '}
                            </label>
                            <Field
                              as="select"
                              id="lineItemTaxName"
                              name="lineItemTaxName"
                              type="select"
                              value={JSON.stringify(selectedTaxRate)}
                              onChange={(e) => {
                                const taxRate = JSON.parse(e.target.value);
                                setSelectedTaxRate(taxRate);
                                setFieldValue('lineItemTaxName', taxRate.name);
                                setLineItemTaxName(taxRate.name);
                                setFieldValue(
                                  'lineItemTaxAmount',
                                  taxRate.effectiveTaxRate
                                );
                                setLineItemTaxAmount(taxRate.effectiveTaxRate);
                                setFieldValue('lineItemTaxId', taxRate.id);
                                setLineItemTaxId(taxRate.id);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                              <option value="">Select Tax Rate</option>
                              {/* Iterate through taxRates array */}
                              {/* {taxRates.map((taxRate) => {
                          if (taxRate.id === "NONE") {
                            return (
                              <option value={JSON.stringify(taxRate)}>
                                {taxRate.name}
                              </option>
                            );
                          } else if (taxRate.id === "INPUT2") {
                            return (
                              <option value={JSON.stringify(taxRate)}>
                                {taxRate.name}
                              </option>
                            );
                          }
                        })} */}
                              {filteredTaxRates.map((taxRate) => {
                                return (
                                  <option value={JSON.stringify(taxRate)}>
                                    <span className="font-medium text-gray-900">
                                      {'\u200BName: '}
                                    </span>
                                    {taxRate.name}. <br />
                                    <span className="font-medium text-gray-900">
                                      {'\u200BTax Rate: '}
                                    </span>
                                    {taxRate.effectiveTaxRate}%
                                  </option>
                                );
                              })}
                            </Field>

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Amount of tax for the line.
                            </p>
                            <ErrorMessage
                              name="lineItemTaxName"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Total Amount *{' '}
                            </label>
                            <Field
                              id="lineItemTotal"
                              name="lineItemTotal"
                              type="text"
                              value={lineItemTotal}
                              onChange={(event) => {
                                setFieldValue(
                                  'lineItemTotal',
                                  event.target.value
                                );
                                setLineItemTotal(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Total amount of the line, including tax.
                            </p>
                            <ErrorMessage
                              name="lineItemTotalAmount"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          {/* <p className="font-medium col-span-6 text-[#CBD5E1]">
                      Select Nominal Account
                    </p> */}

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Category *
                            </label>
                            <Field
                              as="select"
                              type="select"
                              name="nominalAccount"
                              value={JSON.stringify(nominalAccount)}
                              onChange={(e) => {
                                const account = JSON.parse(e.target.value);

                                setNominalAccount(account);
                                setFieldValue('nominalAccountId', account.id);
                                setNominalAccountId(account.id);
                                setFieldValue(
                                  'nominalAccountName',
                                  account.name
                                );
                                setNominalAccountName(account.name);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                              <option value="">Please Select Account</option>
                              {expenseAccounts.map((account) => (
                                <option value={JSON.stringify(account)}>
                                  {account.name}
                                </option>
                              ))}
                            </Field>

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              {nominalAccount === ''
                                ? 'Select the nominal account to be used for the bill.'
                                : nominalAccount?.description}
                            </p>
                            <ErrorMessage
                              name="nominalAccountId"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          {/* <p className="font-medium col-span-6 text-[#CBD5E1]">
                      Withholding Tax
                    </p>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Withholding Tax Name *{" "}
                      </label>
                      <input
                        id="withholdingTaxDescription"
                        name="withholdingTaxDescription"
                        type="text"
                        value={withholdingTaxDescription}
                        onChange={(event) =>
                          setWithholdingTaxDescription(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Name of the withholding tax.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Withholding Tax Value *{" "}
                      </label>
                      <input
                        id="withholdingTaxValue"
                        name="withholdingTaxValue"
                        type="text"
                        value={withholdingTaxRate}
                        onChange={(event) =>
                          setWithholdingTaxRate(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Value of the withholding tax.
                      </p>
                    </div> */}

                          <p className="col-span-6 font-medium text-[#CBD5E1]">
                            Bill Information
                          </p>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Status *{' '}
                            </label>
                            <Field
                              as="select"
                              id="status"
                              name="status"
                              value={status}
                              onChange={(event) => {
                                setFieldValue('status', event.target.value);
                                setStatus(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                              {/* <option value="Unknown">Unknown</option> */}
                              {/* <option value="Open">Open</option> */}
                              {/* <option value="PartiallyPaid">PartiallyPaid</option>
                        <option value="Paid">Paid</option> */}
                              {/* <option value="Void">Void</option> */}
                              <option value="Draft">Draft</option>
                            </Field>

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Current state of the bill.
                            </p>
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Currency *{' '}
                            </label>
                            <Field
                              as="select"
                              id="currency"
                              name="currency"
                              value={currency}
                              onChange={(event) => {
                                setFieldValue('currency', event.target.value);
                                setCurrency(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                              {supportedCurrencies.map((currency) => (
                                <option value={currency}>{currency}</option>
                              ))}
                            </Field>

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Currency of the bill. Your accounting base
                              currency is {baseCurrency}. Base and selected
                              currencies are the same{' '}
                              {baseCurrency === currency ? 'true' : 'false'}
                            </p>
                            <ErrorMessage
                              name="currency"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Bill Sub Total *{' '}
                            </label>
                            <Field
                              id="subTotal"
                              name="subTotal"
                              type="text"
                              value={subTotal}
                              onChange={(event) => {
                                setFieldValue('subTotal', event.target.value);
                                setSubTotal(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Total amount of the bill, excluding any taxes.
                            </p>
                            <ErrorMessage
                              name="subTotal"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Bill Tax Amount *{' '}
                            </label>
                            <Field
                              id="taxAmount"
                              name="taxAmount"
                              type="text"
                              value={taxAmount}
                              onChange={(event) => {
                                setFieldValue('taxAmount', event.target.value);
                                setTaxAmount(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Amount of tax on the bill.
                            </p>
                            <ErrorMessage
                              name="taxAmount"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          <div className="col-span-6">
                            <label className="block text-sm font-medium  text-[#CBD5E1]">
                              Bill Total Amount *{' '}
                            </label>
                            <Field
                              id="totalAmount"
                              name="totalAmount"
                              type="number"
                              step="0.01"
                              value={totalAmount}
                              onChange={(event) => {
                                setFieldValue(
                                  'totalAmount',
                                  event.target.value
                                );
                                setTotalAmount(event.target.value);
                              }}
                              className="mt-1 block w-full rounded-md border-2 border-[#4E6180]   bg-[#18222d] text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                            />

                            <p className="mt-2 text-sm  text-[#CBD5E1]">
                              Amount of the bill, including tax.
                            </p>
                            <ErrorMessage
                              name="totalAmount"
                              component="div"
                              className="mt-2 text-xs italic text-red-500"
                            />
                          </div>

                          {/* <p className="font-medium col-span-6 text-[#CBD5E1]">
                      Payment Information
                    </p>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Payment ID *{" "}
                      </label>
                      <input
                        id="paymentId"
                        name="paymentId"
                        type="text"
                        value={paymentId}
                        onChange={(event) => setPaymentId(event.target.value)}
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Identifier of the allocated payment.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Payment Notes *{" "}
                      </label>
                      <input
                        id="paymentNote"
                        name="paymentNote"
                        type="text"
                        value={paymentNote}
                        onChange={(event) => setPaymentNote(event.target.value)}
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Notes attached to the allocated payment.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Reference *{" "}
                      </label>
                      <input
                        id="paymentReference"
                        name="paymentReference"
                        type="text"
                        value={paymentReference}
                        onChange={(event) =>
                          setPaymentReference(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Reference to the allocated payment.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Payment Account ID *{" "}
                      </label>
                      <input
                        id="accountRefId"
                        name="accountRefId"
                        type="text"
                        value={accountRefId}
                        onChange={(event) =>
                          setAccountRefId(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        The account ID that the allocated payment is made from
                        or to.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Payment Account Name *{" "}
                      </label>
                      <input
                        id="accountRefName"
                        name="accountRefName"
                        type="text"
                        value={accountRefName}
                        onChange={(event) =>
                          setAccountRefName(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        The account name that the allocated payment is made from
                        or to.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Currency *{" "}
                      </label>
                      <input
                        id="paymentCurrency"
                        name="paymentCurrency"
                        type="text"
                        value={paymentCurrency}
                        onChange={(event) =>
                          setPaymentCurrency(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Currency the payment has been made in.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Currency Rate *{" "}
                      </label>
                      <input
                        id="paymentCurrencyRate"
                        name="paymentCurrencyRate"
                        type="text"
                        value={paymentCurrencyRate}
                        onChange={(event) =>
                          setPaymentCurrencyRate(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Rate to convert the total amount of the payment into the
                        base currency for the company at the time of the
                        payment.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Payment Date *{" "}
                      </label>
                      <input
                        id="paidOnDate"
                        name="paidOnDate"
                        type="text"
                        value={paidOnDate}
                        onChange={(event) => setPaidOnDate(event.target.value)}
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        The date the payment was paid.
                      </p>
                    </div>

                    <p className="font-medium col-span-6 text-[#CBD5E1]">
                      Payment Allocation
                    </p> */}

                          {/* <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Transaction Currency *{" "}
                      </label>
                      <input
                        id="paymentAllocationCurrency"
                        name="paymentAllocationCurrency"
                        type="text"
                        value={paymentAllocationCurrency}
                        onChange={(event) =>
                          setPaymentAllocationCurrency(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        The currency of the transaction.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Currency Rate *{" "}
                      </label>
                      <input
                        id="paymentAllocationCurrencyRate"
                        name="paymentAllocationCurrencyRate"
                        type="text"
                        value={paymentAllocationCurrencyRate}
                        onChange={(event) =>
                          setPaymentAllocationCurrencyRate(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        Rate to convert the total amount of the payment into the
                        base currency for the company at the time of the
                        payment.
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Date Allocated *{" "}
                      </label>
                      <input
                        id="paymentAllocationDate"
                        name="paymentAllocationDate"
                        type="text"
                        value={paymentAllocationDate}
                        onChange={(event) =>
                          setPaymentAllocationDate(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        The date the payment was allocated.{" "}
                      </p>
                    </div>

                    <div className="col-span-6">
                      <label className="text-[#CBD5E1] block text-sm font-medium ">
                        Allocation Amount *{" "}
                      </label>
                      <input
                        id="paymentAllocationTotalAmount"
                        name="paymentAllocationTotalAmount"
                        type="text"
                        value={paymentAllocationTotalAmount}
                        onChange={(event) =>
                          setPaymentAllocationTotalAmount(event.target.value)
                        }
                        className="mt-1 p-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                      />

                      <p className="text-[#CBD5E1] mt-2 text-sm ">
                        The total amount that has been allocated.
                      </p>
                    </div> */}
                        </div>
                      </div>

                      <div className=" py-4 text-right ">
                        {!isLoading ? (
                          !apiResponseRecieved ? (
                            <button
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   handleReconcileBill();
                              // }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue(
                                  'lineItemTaxName',
                                  lineItemTaxName
                                );
                                setFieldValue('lineItemTaxId', lineItemTaxId);
                                setFieldValue(
                                  'lineItemTaxAmount',
                                  lineItemTaxAmount
                                );

                                handleSubmit();
                              }}
                              type="submit"
                              className="mb-2 mr-2 rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white  ">
                              Reconcile Bill
                            </button>
                          ) : (
                            <></>
                          )
                        ) : (
                          <Spinner />
                        )}

                        {/* Button for development */}
                        {/* <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleReconcileBill();
                          }}
                          type="submit"
                          className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Reconcile Bill
                        </button> */}

                        {/* <p className="text-white">
                    apiResponse: {JSON.stringify(apiResponse)}
                  </p> */}
                        {showSuccessMessage && (
                          <div
                            className="mb-4 flex rounded-lg bg-green-50 p-4 text-sm text-green-800 dark:bg-gray-800 dark:text-green-400"
                            role="alert">
                            <svg
                              aria-hidden="true"
                              className="mr-3 inline h-5 w-5 flex-shrink-0"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"></path>
                            </svg>
                            <span className="sr-only">Info</span>
                            <div>
                              <span className="font-medium">Bill Synced!</span>{' '}
                              Bill synced successfully. Check "Draft" tab in
                              your accounting platform to view the bill.
                              {/* <pre>{JSON.stringify(apiResponse, null, 2)}</pre> */}
                            </div>
                          </div>
                        )}

                        {showErrorMessage && (
                          <div
                            className="mb-4 flex rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400"
                            role="alert">
                            <svg
                              aria-hidden="true"
                              className="mr-3 inline h-5 w-5 flex-shrink-0"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"></path>
                            </svg>
                            <span className="sr-only">Info</span>
                            <div className="truncate">
                              <span className="text-left font-medium">
                                Could not sync bill.
                              </span>{' '}
                              {apiResponse?.validation?.errors[0]?.message}
                              {/* <pre className="text-left ">
                                {JSON.stringify(apiResponse, null, 2)}
                              </pre> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
