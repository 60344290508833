import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { Dropdown } from 'flowbite-react';
import { useState } from 'react';
import { Spinner } from 'shared/ui/Spinner/Spinner';
import { Button } from 'shared/ui/Button/Button';

const ROW_STYLE_VARIANTS = {
  Active: 'active',
};

const TableTop = ({
  title,
  description,
  primaryActionText,
  onPrimaryAction,
  onSearchChange,
  actions = [],
  onFilterChange,
  filterState,
}) => {
  const [searchString, setSearchString] = useState('');
  const handleBillsGeneralQueryStringKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchChange({ searchString });
    }
  };

  const handleBillsGeneralQueryStringChange = (e) => {
    setSearchString(e.target.value);
  };

  const renderLeft = () => {
    if (title && description) {
      return (
        <div>
          <h5 className="mr-3 font-semibold dark:text-white">{title}</h5>
          <p className="text-gray-500 dark:text-slate-400">{description}</p>
        </div>
      );
    }

    return (
      <form className="flex items-center">
        <label for="simple-search" className="sr-only">
          Search
        </label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              ariaHidden="true"
              className="w-5 h-5 text-gray-500 dark:text-slate-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            className="bg-slate-50 border border-slate-300 text-slate-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Search"
            onChange={handleBillsGeneralQueryStringChange}
            onKeyDown={handleBillsGeneralQueryStringKeyDown}
          />
        </div>
      </form>
    );
  };

  const renderFilter = () => {
    if (filterState?.type === 'external') {
      return (
        <Button onClick={onFilterChange} size="sm" color="slate">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="h-4 w-4 mr-2 text-slate-400"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fillRule="evenodd"
              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
              clipRule="evenodd"
            />
          </svg>
          Filter
        </Button>
      );
    }

    return null;
  };

  const renderRight = () => {
    return (
      <>
        {renderFilter()}

        {actions.length > 0 && (
          <Dropdown
            color="gray"
            size="sm"
            label="Actions"
            className="bg-slate-800 dark:bg-[#232d3e] dark:text-slate-400"
            placement="bottom-end"
            dismissOnClick={true}>
            {actions.map(({ label, icon, onClick }) => (
              <Dropdown.Item
                className="dark:bg-[#232d3e] dark:hover:bg-slate-700 dark:focus:bg-slate-700"
                icon={icon}
                onClick={onClick}>
                {label}
              </Dropdown.Item>
            ))}
          </Dropdown>
        )}

        {!!primaryActionText && (
          <Button onClick={onPrimaryAction} size="sm" color="indigo">
            <svg
              className="h-3.5 w-3.5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              ariaHidden="true">
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              />
            </svg>
            {primaryActionText}
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
      <div className="w-full md:w-1/2">{renderLeft()}</div>
      <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
        {renderRight()}
      </div>
    </div>
  );
};

const TableAwareHeader = ({ table }) => {
  return (
    <thead className="text-[10px] xl:text-xs text-gray-700 uppercase bg-gray-50 dark:bg-slate-700/90 dark:text-slate-400">
      {table.getHeaderGroups().map((headerGroup) => {
        return (
          <tr>
            {headerGroup.headers.map((header) => {
              if (header.id === 'selection') {
                return (
                  <HeaderCellSelection
                    checked={table.getIsAllPageRowsSelected()}
                    onChange={table.getToggleAllPageRowsSelectedHandler()}
                    id="checkbox-all"
                  />
                );
              }

              if (header.id === 'actions') {
                return <HeaderCellActions />;
              }

              return (
                <HeaderCell>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </HeaderCell>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};

const HeaderCell = ({ children }) => {
  return (
    <th scope="col" className="px-4 py-3">
      {children}
    </th>
  );
};

const HeaderCellSelection = ({ checked, onChange, id }) => {
  return (
    <td className="px-4 py-3 w-4">
      <div className="flex items-center">
        <input
          id="checkbox-table-search-1"
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-slate-800 focus:ring-2 dark:bg-slate-700 dark:border-slate-600"
        />
        <label htmlFor="checkbox-table-search-1" className="sr-only">
          checkbox
        </label>
      </div>
    </td>
  );
};

const HeaderCellActions = () => {
  return (
    <th scope="col" className="px-4 py-3">
      <span className="sr-only">Actions</span>
    </th>
  );
};

const RowSelectionCell = ({ checked, onChange, id }) => {
  return (
    <th scope="col" className="p-4">
      <div className="flex items-center">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
          className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-slate-800 focus:ring-2 dark:bg-slate-700 dark:border-slate-600"
        />
        <label for={id} className="sr-only">
          checkbox
        </label>{' '}
      </div>
    </th>
  );
};

const RowCell = ({ children }) => {
  return <td className="px-4 font-normal py-3">{children}</td>;
};

const ActionsCell = ({ original, rowIndex, getRowActions }) => {
  return (
    <td onClick={(e) => e.stopPropagation()} className="pr-4 py-3">
      <div className="flex justify-end">
        <Dropdown
          label="lol"
          dismissOnClick={true}
          onClick={(e) => e.stopPropagation()}
          className="bg-slate-800 dark:bg-[#232d3e] dark:text-slate-400"
          renderTrigger={() => (
            <EllipsisHorizontalIcon
              data-testid={`row-actions-${rowIndex}`}
              className="w-7 dark:hover:text-white"
            />
          )}>
          {getRowActions(original).length ? (
            getRowActions(original).map(({ label, onClick }) => (
              <Dropdown.Item
                className="dark:bg-[#232d3e] dark:hover:bg-slate-700 dark:focus:bg-slate-700"
                onClick={onClick}>
                {label}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item className="dark:bg-[#232d3e] dark:hover:bg-[#232d3e] dark:text-slate-400 dark:hover:text-slate-400 dark:focus:bg-[#232d3e]">
              <span className="dark:text-slate-400">
                No Row Actions Available
              </span>
            </Dropdown.Item>
          )}
        </Dropdown>
      </div>
    </td>
  );
};

const TableAwareBody = ({
  table,
  onRowClick,
  getRowActions,
  rowStyleVariants,
}) => {
  const _isActive = (rowIndex) => {
    return (
      rowStyleVariants &&
      rowStyleVariants[rowIndex] === ROW_STYLE_VARIANTS.Active
    );
  };

  return (
    <tbody>
      {table.getRowModel().rows.map((row, index) => {
        return (
          <tr
            key={index}
            onClick={() => onRowClick && onRowClick(row.original)}
            className={twMerge(
              'border-b dark:border-slate-700 hover:bg-gray-200 dark:hover:bg-slate-700/40 cursor-pointer',
              _isActive(index) &&
                'dark:bg-slate-700/40 border-l-2 dark:border-l-amber-300'
            )}>
            {row.getVisibleCells().map((cell, cellInxex) => {
              if (cell.column.id === 'selection') {
                return (
                  <RowSelectionCell
                    key={cellInxex}
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                );
              }

              if (cell.column.id === 'actions') {
                return (
                  <ActionsCell
                    rowIndex={index}
                    getRowActions={getRowActions}
                    original={row.original}
                  />
                );
              }

              return (
                <RowCell key={cellInxex}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </RowCell>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export const Table = ({
  title,
  description,
  data,
  totalCount = data.length,
  columns,
  manualPagination = false,
  primaryActionText,
  onPrimaryAction,
  secondaryActionList,
  onSearchChange,
  onFilterChange,
  filterState,
  onRowClick,
  rowStyleVariants,
  rowSelection,
  onRowSelectionChange,
  isDataLoading,
  getRowActions,
  onPaginationChange,
  initPagination = { pageIndex: 0, pageSize: 10 },
}) => {
  const [pagination, setPagination] = useState(initPagination);

  const handlePaginationChange = (getState) => {
    const newState = getState(pagination);
    setPagination(newState);
    onPaginationChange(newState);
  };

  const table = useReactTable(
    manualPagination
      ? {
          data,
          columns,
          pageCount: Math.ceil(totalCount / pagination.pageSize),

          getCoreRowModel: getCoreRowModel(),
          getPaginationRowModel: getPaginationRowModel(),

          state: {
            rowSelection,
            pagination,
          },
          enableRowSelection: true,
          onRowSelectionChange,

          manualPagination,
          onPaginationChange: handlePaginationChange,
        }
      : {
          data,
          columns,
          getCoreRowModel: getCoreRowModel(),

          enableRowSelection: true,
          onRowSelectionChange: onRowSelectionChange,
          getPaginationRowModel: getPaginationRowModel(),
          state: {
            rowSelection,
          },
        }
  );

  const { pageIndex } = table.getState().pagination;

  const showingFrom = pageIndex * table.getState().pagination.pageSize + 1;
  const pageCount = Math.ceil(
    totalCount / table.getState().pagination.pageSize
  );
  const showingTo =
    (pageIndex + 1) * table.getState().pagination.pageSize > totalCount
      ? totalCount
      : (pageIndex + 1) * table.getState().pagination.pageSize;

  return (
    <div className="bg-white dark:bg-slate-800 relative shadow-md sm:rounded-lg overflow-hidden">
      <TableTop
        title={title}
        onFilterChange={onFilterChange}
        filterState={filterState}
        onSearchChange={onSearchChange}
        actions={secondaryActionList}
        description={description}
        primaryActionText={primaryActionText}
        onPrimaryAction={onPrimaryAction}
      />

      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-slate-400">
          <TableAwareHeader table={table} />
          <TableAwareBody
            getRowActions={getRowActions}
            rowStyleVariants={rowStyleVariants}
            onRowClick={onRowClick}
            table={table}
          />
        </table>
      </div>

      <TableBottom
        showingFrom={showingFrom}
        showingTo={showingTo}
        totalCount={totalCount}
        pageIndex={pageIndex}
        pageCount={pageCount}
        firstEnabled={table.getCanPreviousPage()}
        prevEnabled={table.getCanPreviousPage()}
        nextEnabled={table.getCanNextPage()}
        lastEnabled={table.getCanNextPage()}
        onPreviousPage={table.previousPage}
        onNextPage={table.nextPage}
        onPageIndexChange={table.setPageIndex}
      />

      {isDataLoading && <LoadingOverlay />}
    </div>
  );
};

const TableBottom = ({
  showingFrom,
  showingTo,
  totalCount,
  pageIndex,
  pageCount,

  prevEnabled,
  firstEnabled,
  nextEnabled,
  lastEnabled,

  onPreviousPage,
  onNextPage,
  onPageIndexChange,
}) => {
  return (
    <nav
      className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
      ariaLabel="Table navigation">
      <span className="text-sm font-normal text-gray-500 dark:text-slate-400">
        Showing{' '}
        <span className="font-semibold text-gray-900 dark:text-white">
          {showingFrom}-{showingTo}{' '}
        </span>
        of{' '}
        <span className="font-semibold text-gray-900 dark:text-white">
          {totalCount}
        </span>
      </span>
      <ul className="inline-flex items-stretch -space-x-px">
        <li>
          <button
            onClick={() => onPageIndexChange(0)}
            className={twMerge(
              'flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300  dark:bg-slate-800 dark:border-slate-700 dark:text-slate-400',
              firstEnabled
                ? 'dark:hover:bg-slate-700 dark:hover:text-white hover:bg-slate-100 hover:text-slate-700'
                : 'opacity-50'
            )}>
            <span className="sr-only">First</span>
            <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </li>
        <li>
          <button
            onClick={onPreviousPage}
            className={twMerge(
              'flex items-center justify-center h-full py-1.5 px-3 ml-0 text-slate-500 bg-white border border-slate-300  dark:bg-slate-800 dark:border-slate-700 dark:text-slate-400',
              prevEnabled
                ? 'dark:hover:bg-slate-700 dark:hover:text-white hover:bg-slate-100 hover:text-slate-700'
                : 'opacity-50'
            )}>
            <span className="sr-only">Previous</span>

            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </li>

        <li>
          <span className="flex items-center justify-center text-sm py-2 px-3 leading-tight text-slate-500 bg-white border border-slate-300 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-400">
            {pageIndex + 1} of {pageCount}
          </span>
        </li>

        <li>
          <button
            onClick={onNextPage}
            className={twMerge(
              'flex items-center justify-center h-full py-1.5 px-3 leading-tight text-slate-500 bg-white border border-slate-300 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-400',

              nextEnabled
                ? 'dark:hover:bg-slate-700 dark:hover:text-white hover:bg-slate-100 hover:text-slate-700'
                : 'opacity-50'
            )}>
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </li>
        <li>
          <button
            onClick={() => onPageIndexChange(pageCount - 1)}
            className={twMerge(
              'flex items-center justify-center h-full py-1.5 px-3 leading-tight text-slate-500 bg-white rounded-r-lg border border-slate-300 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-400',
              lastEnabled
                ? 'dark:hover:bg-slate-700 dark:hover:text-white hover:bg-slate-100 hover:text-slate-700'
                : 'opacity-50'
            )}>
            <span className="sr-only">Last</span>
            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

const LoadingOverlay = () => {
  return (
    <div className="absolute left-0 top-0 z-10 flex h-[100%] w-[100%] items-center justify-center bg-[#101826]/[.6]">
      <Spinner size="xl" color="indigo" />
    </div>
  );
};
