import { Link } from "react-router-dom";
import logoPurple2 from "shared/images/logoPurpleBlackBackground2.png";
import { Button } from "shared/ui";

const PagePrivacyPolicy = () => {
  return (
    <div className="flex justify-center bg-gray-900 ">
      <div className="w-full max-w-2xl px-5 ">
        <div className="mb-4 text-left ">
          <img
            src={logoPurple2}
            alt="Company logo"
            className="h-25 mx-auto my-auto w-auto max-w-sm "
          />

          <p className="py-4 text-sm text-gray-300">
            <strong>Fractal 3 Ltd - Privacy Policy</strong>
            <br />
            Last Updated: 1st of March, 2023
            <br />
            <br />
            This Privacy Policy ("Policy") describes how Fractal 3 Ltd ("we",
            "us", or "our") collects, uses, and discloses your information when
            you access or use our platform (the "Platform"). By using the
            Platform, you agree to the collection, use, and disclosure of your
            information in accordance with this Policy.
            <br />
            <br />
            <strong>1. Information We Collect</strong>
            <br />
            We collect information about you in various ways when you use the
            Platform. The information we collect may include:
            <br />
            a) Personal Information: This may include your name, email address,
            phone number, company name, and other information that you provide
            when you create an account or use the Platform.
            <br />
            b) Usage Information: We automatically collect information about
            your interactions with the Platform, such as the pages you visit,
            features you use, and the actions you take.
            <br />
            c) Device and Technical Information: We collect information about
            your device, such as your browser type, operating system, and other
            technical details.
            <br />
            d) Billing Information: We collect billing and payment information
            when you subscribe to our Platform or make a purchase.
            <br />
            e) Information from Third-Party Services: If you choose to integrate
            the Platform with third-party services, we may receive information
            from these services, such as your account information, transaction
            data, and other relevant details.
            <br />
            <br />
            <strong>2. How We Use Your Information</strong>
            <br />
            We may use your information for various purposes, including to:
            <br />
            a) Provide, maintain, and improve the Platform and our services.
            <br />
            b) Process transactions and send you related information, including
            confirmations, invoices, and updates.
            <br />
            c) Communicate with you about your account, our services,
            promotions, and news.
            <br />
            d) Personalize and improve your experience on the Platform.
            <br />
            e) Respond to your inquiries, requests, or feedback.
            <br />
            f) Monitor and analyze trends, usage, and activities in connection
            with the Platform.
            <br />
            g) Enforce our Terms of Service, protect our rights, and prevent
            fraud or illegal activities.
            <br />
            <br />
            <strong>3. How We Share Your Information</strong>
            <br />
            We may share your information with third parties in the following
            circumstances:
            <br />
            a) Service Providers: We may share your information with third-party
            service providers who perform functions on our behalf, such as
            payment processing, analytics, or customer support.
            <br />
            b) Third-Party Integrations: If you choose to integrate the Platform
            with third-party services, we may share your information with these
            services to facilitate the integration.
            <br />
            c) Legal Compliance and Protection of Rights: We may disclose your
            information if required to do so by law or if we believe that such
            action is necessary to comply with legal obligations, protect our
            rights, or defend against legal claims.
            <br />
            d) Business Transfers: In the event of a merger, acquisition, or
            sale of all or a portion of our assets, we may transfer your
            information as part of the transaction.
            <br />
            <br />
            <strong>4. Security</strong>
            <br />
            We take reasonable measures to protect your information from
            unauthorized access, disclosure, or loss. However, no method of
            transmission or storage is completely secure, and we cannot
            guarantee the absolute security of your information.
            <br />
            <br />
            <strong>5. Your Choices</strong>
            <br />
            You may update, correct, or delete your personal information at any
            time by logging into your account or contacting us at
            pavel@getfractal.xyz. You may also opt-out of receiving promotional
            communications by following the unsubscribe instructions in the
            email or contacting us directly.
            <br />
            <br />
            <strong>6. International Data Transfers</strong>
            <br />
            Your information may be processed, stored, and transferred to
            countries outside of your country of residence, which may have
            different data protection laws than your country. By using the
            Platform, you consent to the transfer of your information to these
            countries.
            <br />
            <br />
            <strong>7. Changes to this Policy</strong>
            <br />
            We may update this Policy from time to time. We will notify you of
            any significant changes by posting the updated Policy on our website
            and indicating the "Last Updated" date. Your continued use of the
            Platform after any changes to this Policy are posted constitutes
            your acceptance of the updated Policy.
            <br />
            <br />
            <strong>8. Children's Privacy</strong>
            <br />
            The Platform is not intended for use by children under the age of
            13, and we do not knowingly collect personal information from
            children under 13. If we become aware that a child under 13 has
            provided us with personal information, we will take steps to delete
            such information from our records.
            <br />
            <br />
            <strong>9. Third-Party Websites and Services</strong>
            <br />
            Our Platform may contain links to third-party websites or services.
            This Policy does not apply to any information collected by these
            third parties, and we are not responsible for their privacy
            practices. We encourage you to review the privacy policies of any
            third-party websites or services that you visit or use.
            <br />
            <br />
            <strong>10. Data Retention</strong>
            <br />
            We retain your information for as long as necessary to fulfill the
            purposes for which it was collected, comply with our legal
            obligations, resolve disputes, and enforce our agreements. When we
            no longer need your information for these purposes, we will securely
            delete or anonymize it in accordance with applicable laws and
            regulations.
            <br />
            <br />
            <strong>11. Your Rights under Data Protection Laws</strong>
            <br />
            Depending on your location and applicable data protection laws, you
            may have certain rights with respect to your personal information,
            such as the right to access, correct, delete, or restrict the
            processing of your information, or object to its use for certain
            purposes. To exercise these rights, please contact us at
            pavel@getfractal.xyz. We will respond to your request in accordance
            with applicable data protection laws.
            <br />
            <br />
            <strong>12. Contact Us</strong>
            <br />
            If you have any questions or concerns about this Policy or our
            privacy practices, please contact us using the information provided
            below:
            <br />
            Fractal 3 Ltd
            <br />
            London, United Kingdom
            <br />
            pavel@getfractal.xyz
            <br />
            <br />
            We will make every effort to address your inquiries and resolve any
            concerns you may have about our privacy practices.
          </p>

          {/* Button to go back to previous page */}
          <div className="flex justify-center py-8">
            <Link to="/">
              <Button size="xl">Back To Login</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePrivacyPolicy;
