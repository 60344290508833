import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useCurrentUserDetails } from '../../../entities/user/model';
import { useState } from 'react';
import { DepositMethodSelect } from '../PaymentModal.DepositMethodSelect';
import { AragonAddressPicker } from '../../../widgets/Payments/BatchPaymentModal.AragonAddressPicker';
import { DepositDetailsSelection } from '../Payso.DepositDetailsSelection';
import { ConfirmInWalletOverlay } from '../../../widgets/Payments/PaymentModal.ConfirmInWalletOverlay';
import {
  FailedPayment,
  SuccessConfirmation,
} from '../../../widgets/Payments/CryptoToFiatPaymentModal.SuccessConfirmation';
import { Spinner } from '../../../shared/ui/Spinner/Spinner';
import { PaymentInformation } from '../../../widgets/Payments/PaymentModal.PaymentInformation';
import { WarningsSection } from '../../../widgets/Payments/PaymentModal.WarningsSection';
import { useAccount, useBalance } from 'wagmi';
import { MultipleBankPaymentInformation } from '../../../widgets/Payments/CryptoToFiatPaymentModal.PaymentInformation';
import { NETWORK_TO_CHAIN_ID_MAP } from '../../bill/lib';
import erc20TokensData from '../../../shared/constants/erc20TokensData';
import { ConfirmationSection } from '../../../widgets/Payments/PaymentModal.ConfirmationSection';
import { Button } from '../../../shared/ui/Button/Button';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { createCryptoPayoutDetails } from '../PayoutDetails';
import PayERC20Button from '../../../widgets/Payments/PayERC20Button';
import { AragonAppWithdrawProposalButton } from '../../../widgets/Payments/CryptoToFiatPaymentModal';
import { useSafeWallet } from '../../../shared/hooks/safeWallet';
import { isDev } from '../../../shared/lib/env';
import { useBillMutations } from '../../../entities/bill/model';
import { useTransfers } from '../../transfers/useTransfers';
import { paymentProviderIdMethodsMap } from '../PaymentProviderIdTypes';
import { Modal as AModal } from 'antd';

const OfframpModal = ({
  show,
  title,
  payouts: payoutsParam,
  onClose,
  initiatingOfframp,
  initiateOfframpData,
  initiateOfframpError,
  onOnrampInitiate,
  onExecuteOfframp,
  executingOfframp,
  executionOfframpSuccess,
  executionOfframpError,
  allowedDepositNetworks,
}) => {
  /** @type {[BankTransferPayoutDetails[]]} */
  const payoutsState = useState(payoutsParam);
  const payouts = payoutsState[0];
  const [depositMethod, setDepositMethod] = useState('');
  const [depositTokenChain, setDepositTokenChain] = useState('');
  const [aragonAppAddress, setAragonAppAddress] = useState('');
  const [
    waitingForUserWalletConfirmation,
    setWaitingForUserWalletConfirmation,
  ] = useState(false);
  const { isConnectedWalletSafe } = useSafeWallet();
  const { address, chain, isConnected } = useAccount();
  const chainId = NETWORK_TO_CHAIN_ID_MAP[depositTokenChain];
  const [isConfirmedByUser, setIsConfirmedByUser] = useState(false);
  const tokenAddress =
    erc20TokensData[initiateOfframpData?.depositInfo?.token]?.erc20Address[
      initiateOfframpData?.depositInfo?.chain
    ];

  const { data: tokenBalance } = useBalance({
    address,
    chainId,
    token: tokenAddress,
  });

  const _handleDepositDetailsSelect = ({ depositTokenChain }) => {
    setDepositTokenChain(depositTokenChain);

    onOnrampInitiate({ depositTokenChain });
  };

  const _handleConfirm = (val) => {
    setIsConfirmedByUser(val);
  };

  const _handleCreateProposal = ({ proposalId }) => {
    setWaitingForUserWalletConfirmation(false);
    onExecuteOfframp({
      aragonProposalId: proposalId,
    });
  };

  const _handleStartCreatingProposal = () => {
    setWaitingForUserWalletConfirmation(true);
  };

  const _onReceiveTransactionHash = (transactionHash) => {
    if (isConnectedWalletSafe()) {
      onExecuteOfframp({
        safeTransactionHash: transactionHash,
      });
    } else {
      onExecuteOfframp({
        transactionHash,
      });
    }
  };

  const _onWaitingForUserWalletConfirmation = (flag) => {
    setWaitingForUserWalletConfirmation(flag);
  };

  const _renderMiddleStepScreen = () => {
    if (!depositMethod) {
      return (
        <DepositMethodSelect onMethodSelect={(m) => setDepositMethod(m)} />
      );
    }

    if (depositMethod === 'aragon-app' && !aragonAppAddress) {
      return (
        <AragonAddressPicker
          containerClassname="flex flex-col pt-1"
          onAddressSelect={(a) => setAragonAppAddress(a)}
          show
        />
      );
    }

    if (!depositTokenChain) {
      return (
        <DepositDetailsSelection
          depositNetworksOptions={allowedDepositNetworks?.map((n) => ({
            value: n.toLowerCase(),
            label: n,
          }))}
          onSelect={_handleDepositDetailsSelect}
        />
      );
    }

    if (executingOfframp || waitingForUserWalletConfirmation) {
      return <ConfirmInWalletOverlay show />;
    }

    if (executionOfframpSuccess) {
      return <SuccessConfirmation onClose={onClose} show />;
    }

    if (executionOfframpError || initiateOfframpError) {
      return <FailedPayment onClose={onClose} show />;
    }
  };

  const _renderExecuteOfframpButton = () => {
    if (depositMethod === 'aragon-app') {
      return (
        <AragonAppWithdrawProposalButton
          disabled={!isConfirmedByUser}
          onStart={_handleStartCreatingProposal}
          aragonAppAddress={aragonAppAddress}
          payouts={[
            createCryptoPayoutDetails(
              initiateOfframpData?.depositInfo?.amount,
              initiateOfframpData?.depositInfo?.token,
              initiateOfframpData?.depositInfo?.chain,
              initiateOfframpData?.depositInfo?.depositWallet?.address
            ),
          ]}
          onSuccess={_handleCreateProposal}
        />
      );
    }

    return (
      <PayERC20Button
        addressTo={initiateOfframpData?.depositInfo?.depositWallet?.address}
        amountERC20={initiateOfframpData?.depositInfo?.amount}
        tokenName={initiateOfframpData?.depositInfo?.token}
        tokenAddress={tokenAddress}
        network={initiateOfframpData?.depositInfo?.chain}
        disabled={!isConfirmedByUser}
        payButtonStyles="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onUserApproval={_onWaitingForUserWalletConfirmation}
        setTransactionHash={_onReceiveTransactionHash}
      />
    );
  };

  const _renderTransactionDetails = () => {
    if (!depositTokenChain) {
      return null;
    }

    if (initiatingOfframp) {
      return (
        <div className="flex items-center justify-center px-6 py-4">
          <Spinner size="xl" color="indigo" />
        </div>
      );
    }

    return (
      <>
        <MultipleBankPaymentInformation
          multipleBankDetails={payouts.map((p, index) => ({
            beneficiaryName: p.accountBeneficiary,
            accountNumber: p.accountNumber,
            bankRoutingNumber: p.accountACHRoutingNumber,
            bankSwiftCode: p.bankBIC,
            bankIban: p.accountIBAN,
          }))}
        />

        {initiateOfframpData && (
          <>
            <PaymentInformation
              title="Deposit Information"
              walletAddress={
                initiateOfframpData?.depositInfo?.depositWallet?.address
              }
              network={initiateOfframpData?.depositInfo?.chain}
              token={initiateOfframpData?.depositInfo?.token}
              amount={initiateOfframpData?.depositInfo?.amount}
              balance={tokenBalance?.formatted}
            />
            <WarningsSection
              connectedChain={chain}
              payeeToken={initiateOfframpData?.depositInfo?.token}
              payeeNetwork={initiateOfframpData?.depositInfo?.chain}
              payeeAmount={initiateOfframpData?.depositInfo?.amount}
              payeeWalletAddress={
                initiateOfframpData?.depositInfo?.depositWallet?.address
              }
              paymentProps={{
                balance: tokenBalance?.formatted,
              }}
            />
          </>
        )}

        <ConfirmationSection
          isConfirmed={isConfirmedByUser}
          setIsConfirmed={() => {}}
          onConfirmationChange={_handleConfirm}
        />

        {isConnected ? (
          <>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              {_renderExecuteOfframpButton()}

              <Button color="gray" className="sm:order-first" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <div className="mt-5">
            <ConnectButton.Custom>
              {({ openConnectModal }) => (
                <Button onClick={openConnectModal} fullSized cl color="indigo">
                  Connect Your Wallet
                </Button>
              )}
            </ConnectButton.Custom>
          </div>
        )}
      </>
    );
  };

  return (
    <AModal
      title={title}
      open={show}
      footer={null}
      centered
      destroyOnClose
      onCancel={onClose}>
      {_renderMiddleStepScreen()}
      {_renderTransactionDetails()}
    </AModal>
  );
};

export const BillPaymentViaOfframpModal = ({
  bills,
  payouts,
  show,
  onClose,
}) => {
  const { invalidate } = useBillMutations();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const initiateBillOfframpMutation = useInitiateBillOfframp();
  const executeBillOfframpMutation = useExecuteBillOfframp();
  const onOnrampInit = ({ depositTokenChain }) => {
    if (!initiateBillOfframpMutation.isPending) {
      initiateBillOfframpMutation.mutate({
        depositTokenChain,
        bills,
        organizationId: getCurrentOrganizationId(),
      });
    }
  };

  const onExecuteOfframp = ({
    transactionHash,
    safeTransactionHash,
    aragonProposalId,
  }) => {
    if (!executeBillOfframpMutation.isPending) {
      executeBillOfframpMutation
        .mutateAsync({
          bills,
          organizationId: getCurrentOrganizationId(),
          transactionHash,
          safeTransactionHash,
          aragonProposalId,
        })
        .then(() => {
          invalidate();
        });
    }
  };

  return (
    <OfframpModal
      title={'Bill Payment'}
      onOnrampInitiate={onOnrampInit}
      onExecuteOfframp={onExecuteOfframp}
      initiateOfframpData={initiateBillOfframpMutation.data}
      show={show}
      onClose={onClose}
      payouts={payouts}
      initiatingOfframp={initiateBillOfframpMutation.isPending}
      initiateOfframpError={initiateBillOfframpMutation.isError}
      executingOfframp={executeBillOfframpMutation.isPending}
      executionOfframpError={executeBillOfframpMutation.isError}
      executionOfframpSuccess={executeBillOfframpMutation.isSuccess}
    />
  );
};

export const TransferPaymentViaOfframpModal = ({
  transfers,
  payouts,
  show,
  onClose,
  paymentProviderConfig,
}) => {
  const { invalidate } = useTransfers();
  const { getCurrentOrganizationId } = useCurrentUserDetails();
  const initiateOfframpMutation = useInitiateTransferOfframp(
    paymentProviderIdMethodsMap[paymentProviderConfig?.id]
  );
  const executeOfframpMutation = useExecuteTransferOfframp();

  const onOnrampInit = ({ depositTokenChain }) => {
    if (!initiateOfframpMutation.isPending) {
      initiateOfframpMutation.mutate({
        depositTokenChain,
        transferIds: transfers.map((tr) => tr.id),
        organizationId: getCurrentOrganizationId(),
      });
    }
  };

  const onExecuteOfframp = ({
    transactionHash,
    safeTransactionHash,
    aragonProposalId,
  }) => {
    if (!executeOfframpMutation.isPending) {
      executeOfframpMutation
        .mutateAsync({
          transferIds: transfers.map((tr) => tr.id),
          organizationId: getCurrentOrganizationId(),
          transactionHash,
          safeTransactionHash,
          aragonProposalId,
        })
        .then(() => {
          return invalidate();
        });
    }
  };

  return (
    <OfframpModal
      title={'Transfer Payment Completion'}
      onOnrampInitiate={onOnrampInit}
      allowedDepositNetworks={paymentProviderConfig.getAvailableDepositNetworks()}
      onExecuteOfframp={onExecuteOfframp}
      initiateOfframpData={initiateOfframpMutation.data}
      show={show}
      onClose={onClose}
      payouts={payouts}
      initiatingOfframp={initiateOfframpMutation.isPending}
      initiateOfframpError={initiateOfframpMutation.isError}
      executingOfframp={executeOfframpMutation.isPending}
      executionOfframpError={executeOfframpMutation.isError}
      executionOfframpSuccess={executeOfframpMutation.isSuccess}
    />
  );
};

export const useInitiateBillOfframp = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationKey: ['initiate-bill-offramp'],
    mutationFn: async ({ depositTokenChain, bills, organizationId }) => {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/offramp/bills/initiate`,
        {
          depositTokenChain,
          billIds: bills.map((b) => b._id),
          providerId: 'payso',
          organizationId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (isDev()) {
        return {
          depositInfo: {
            amount: 0.001,
            token: 'USDC',
            chain: 'Polygon',
            depositWallet: {
              address: '0x4baB3904C07933F190Eb9d8ffd17214aD703147f',
            },
          },
        };
      }

      return res.data?.result;
    },
  });
};

export const useInitiateTransferOfframp = (providerId) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationKey: ['initiate-transfer-offramp'],
    mutationFn: async ({ depositTokenChain, transferIds, organizationId }) => {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/offramp/transfer/initiate`,
        {
          depositTokenChain,
          transferIds,
          providerId,
          organizationId,
          depositToken: 'USDC',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (isDev()) {
        return {
          depositInfo: {
            amount: 0.001,
            token: 'USDC',
            chain: 'Polygon',
            depositWallet: {
              address: '0x4baB3904C07933F190Eb9d8ffd17214aD703147f',
            },
          },
        };
      }

      return res.data?.result;
    },
  });
};

export const useExecuteBillOfframp = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationKey: ['execute-bill-offramp'],
    mutationFn: async ({
      bills,
      organizationId,
      transactionHash,
      safeTransactionHash,
      aragonProposalId,
    }) => {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/offramp/bills/execute`,
        {
          billIds: bills.map((b) => b._id),
          organizationId,
          transactionHash,
          safeTransactionHash,
          aragonProposalId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return res.data?.result;
    },
  });
};

export const useExecuteTransferOfframp = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationKey: ['execute-transfer-offramp'],
    mutationFn: async ({
      transferIds,
      organizationId,
      transactionHash,
      safeTransactionHash,
      aragonProposalId,
    }) => {
      const token = await getAccessTokenSilently();
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/public/offramp/transfer/execute`,
        {
          transferIds,
          organizationId,
          transactionHash,
          safeTransactionHash,
          aragonProposalId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return res.data?.result;
    },
  });
};
