import { twMerge } from 'tailwind-merge';

export const TextField = ({ form, field, className, ...props }) => {
  const { label, hint, error, ...inputProps } = props;

  return (
    <div className={twMerge('col-span-6 sm:col-span-3', className)}>
      <label
        htmlFor={field.name}
        className={`block text-sm dark:text-[#CBD5E1]`}>
        {label}
      </label>
      <div className=" flex rounded-md shadow-sm">
        <input
          className={`mt-1 block w-full rounded-md border-2 border-[#4E6180] bg-[#18222d] text-[#FFFFFF] shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
          id={field.name}
          type="text"
          {...field}
          {...inputProps}
        />
      </div>
      {error && <p className="mt-1 text-xs italic text-rose-500">{error}</p>}
      <p className="mt-2 text-xs text-salte-500 dark:text-slate-400">{hint}</p>
    </div>
  );
};
