import { useEmailActions } from 'entities/email/model';
import { formatDate } from 'entities/invoice/lib';
import {
  useInvoice,
  useInvoiceActions,
  useInvoiceMutations,
} from 'entities/invoice/model';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DetailSection } from 'shared/components';
import { useDialog } from 'shared/components/Dialog';
import { tokenToImage } from 'shared/lib';
import { Button } from 'shared/ui/Button/Button';
import { Spinner } from 'shared/ui/Spinner';
import {
  InformationCircleIcon,
  CheckCircleIcon,
  BellAlertIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import {
  BLOCKSCAN_URLS,
  getTokenHumanReadableNameByChain,
} from 'features/bill/lib';
import { ReconciliationModal } from '../../../features/invoice/ReconciliationModal';
import useToggle from 'shared/hooks/useToggle';
import { ROLES } from 'shared/hooks';
import { useCurrentUserDetails } from 'entities/user/model';
import { useRemindInvoicePayment } from '../useRemindInvoicePayment';
import { TextInput } from '../../../shared/ui/form';
import { formatDollarAmount } from '../../../shared/lib/string';

const getBlockExplorerUrl = (network, txHash) =>
  `${BLOCKSCAN_URLS[network]}/tx/${txHash}`;

const useInvoiceDetails = (id) => {
  const { data: invoice } = useInvoice(id);

  const isAutogeneratedInvoice =
    !!invoice?.paymentRequestId &&
    !invoice?.paymentRequestId.includes('direct_client_invoice') &&
    !invoice?.paymentRequestId.includes('direct_vendor_payment');

  return {
    canBeEdited: !isAutogeneratedInvoice,
    isAutogeneratedInvoice,
    isPaid: invoice?.payment?.status === 'Paid',
    cryptoInvoice: invoice?.network !== 'Fiat',
    invoice,
  };
};

export const InvoiceDetails = ({ id, onClose }) => {
  const { getCurrentOrgRole, hasAccountingIntegration } =
    useCurrentUserDetails();
  const dialog = useDialog();
  const {
    invoice,
    canBeEdited,
    isAutogeneratedInvoice,
    isPaid,
    cryptoInvoice,
  } = useInvoiceDetails(id);
  const { remove } = useInvoiceMutations();
  const { downloadAction, invalidateInvoice } = useInvoiceActions();
  const { isSending, sendAction } = useEmailActions();
  const { isLoading, remindInvoicePayment } = useRemindInvoicePayment(id);
  const [isDownloading, setIsDownloading] = useState(false);
  const [reconciliationModalOpened, toggleReconciliationModal] =
    useToggle(false);

  const [clientEmail, setClientEmail] = useState('');

  const _handleDownload = () => {
    setIsDownloading(true);
    handleDownload(invoice._id, `${invoice.client_name}_File_ID_`).finally(
      () => {
        setIsDownloading(false);
      }
    );
  };

  const handleDelete = useCallback(async () => {
    const confirmationText = isAutogeneratedInvoice
      ? `Are you sure you want to delete this invoice? This invoice has associated payment request for "${invoice.client_name}". Consider to contact your client "${invoice.client_email}" to avoid inconsistency`
      : 'Are you sure you want to delete this invoice? This action cannot be undone and you and your team members will not able to see this invoice anymore.';

    dialog.confirm({
      title: 'Delete Invoice',
      desc: confirmationText,
      onConfirm: async () => {
        try {
          await remove.mutateAsync(invoice?._id);
          toast.success('Invoice Deleted!');
          onClose();
        } catch (error) {
          toast.error('Deleting Invoice Failed');
          console.error(error);
        }
      },
    });
  }, [
    dialog,
    invoice?.client_name,
    invoice?.client_email,
    invoice?._id,
    onClose,
    remove,
    isAutogeneratedInvoice,
  ]);

  const handleDownload = useCallback(
    async (invoiceId, fileName) => {
      try {
        const res = await downloadAction(invoiceId);
        const blob = new Blob([res], {
          type: 'application/pdf',
        });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
      }
    },
    [downloadAction]
  );

  const handleSend = useCallback(async () => {
    try {
      await sendAction({
        invoice_data: invoice,
        email: clientEmail,
      });
      await invalidateInvoice(id);
      toast.success('Email sent successfully');
    } catch (error) {
      toast.error('Sending Email Failed');
      console.error(error);
    }
  }, [clientEmail, invoice, sendAction]);

  const _handleSendPaymentReminder = useCallback(async () => {
    try {
      await remindInvoicePayment();
      toast.success('Reminder email sent successfully');
    } catch (error) {
      toast.error('Sending Email Failed');
      console.error(error);
    }
  }, [clientEmail, invoice, sendAction]);

  const _renderReconciliationSection = () => {
    let statusContent = null;

    if (invoice?.accountingStatus?.reconciled) {
      statusContent = (
        <span className="flex items-center justify-end text-green-500">
          <CheckCircleIcon className="mr-1 inline h-4 w-4" />
          Reconciled
        </span>
      );
    } else if (
      [ROLES.ADMIN, ROLES.ACCOUNTANT].includes(getCurrentOrgRole()) &&
      !invoice?.accountingStatus?.reconciled &&
      hasAccountingIntegration()
    ) {
      statusContent = (
        <div className="flex justify-end">
          <button
            className="font-medium text-indigo-400 hover:text-indigo-300"
            onClick={() => toggleReconciliationModal()}>
            Reconcile Now
          </button>
        </div>
      );
    }

    if (statusContent) {
      return (
        <DetailSection header="Reconcilliation">
          <div className="grid grid-cols-2 items-center gap-y-1 py-2 text-left text-sm font-medium">
            <span className="text-slate-400">Status</span>
            {statusContent}
          </div>
        </DetailSection>
      );
    }

    return null;
  };

  useEffect(() => {
    setClientEmail(invoice?.client_email);
  }, [invoice?.client_email]);

  return invoice?._id ? (
    <div className="relative flex-1 space-y-6">
      <DetailSection header="General">
        <DetailSection.Item term="Client Name">
          {invoice.client_name}
        </DetailSection.Item>

        <DetailSection.Item term="Amount">
          <div className="flex items-center">
            {tokenToImage(invoice?.token)}
            <span>
              {formatDollarAmount(invoice?.amount)}{' '}
              {getTokenHumanReadableNameByChain(
                invoice?.token,
                invoice?.network
              )}
            </span>
          </div>
        </DetailSection.Item>

        <DetailSection.Item term="Network">
          {invoice.network}
        </DetailSection.Item>

        <DetailSection.Item term="Due Date">
          {formatDate(invoice.payments_date)}
        </DetailSection.Item>

        {invoice?.invoice_number && (
          <DetailSection.Item term="Invoice ID">
            {invoice?.invoice_number.slice(0, 46)}
          </DetailSection.Item>
        )}
      </DetailSection>

      {isPaid && cryptoInvoice && (
        <DetailSection header="Payment Details">
          {invoice.payment?.txHash ? (
            <DetailSection.Item term="Tx Hash">
              <Link
                to={getBlockExplorerUrl(
                  invoice.network,
                  invoice.payment.txHash
                )}
                target="_blank"
                rel="noopener noreferrer">
                <button className="font-medium text-indigo-400 hover:text-indigo-300">
                  View on Block Explorer
                </button>
              </Link>
            </DetailSection.Item>
          ) : (
            <DetailSection.Item term="Tx Hash">
              <div className="flex items-center">
                Not Available
                <Tooltip
                  content={
                    <div className="w-[200px] text-slate-200 text-left">
                      Transaction hash unavailable; payment made outside the
                      platform.
                    </div>
                  }>
                  <InformationCircleIcon className="ml-1 w-4 cursor-pointer" />
                </Tooltip>
              </div>
            </DetailSection.Item>
          )}
        </DetailSection>
      )}

      {!isAutogeneratedInvoice && (
        <DetailSection header="Payment Request">
          <DetailSection.Item term="Payment Link">
            <Link
              to={`/pay?id=${invoice._id}&pac=${invoice.pac}`}
              target="_blank"
              rel="noopener noreferrer">
              <button className="font-medium text-indigo-400 hover:text-indigo-300">
                Preview
              </button>
            </Link>
          </DetailSection.Item>

          {!isPaid && invoice.isSentToReceiver ? (
            <DetailSection.Item term="Payment Reminder">
              <Button
                color="indigo"
                size="xs"
                isProcessing={isLoading}
                onClick={_handleSendPaymentReminder}>
                <BellAlertIcon width={12} className="mr-2" />
                Send Reminder
              </Button>
            </DetailSection.Item>
          ) : (
            <div className="flex-col space-y-1 py-3 text-left text-sm font-medium">
              <label htmlFor="location" className="text-slate-400">
                Send Payment Request
              </label>
              <div className="flex items-center gap-x-2">
                <TextInput
                  id="clientEmail"
                  color={'darkSlate'}
                  size="sm"
                  placeholder="client@mail.com"
                  value={clientEmail}
                  className="flex-1"
                  onChange={(e) => setClientEmail(e.target.value)}
                />

                <Button
                  isProcessing={isSending}
                  disabled={isSending}
                  color="indigo"
                  size="sm"
                  onClick={handleSend}>
                  <PaperAirplaneIcon width={12} className="mr-2" />
                  Send
                </Button>
              </div>
              <p className="mt-3 pt-1 text-xs text-slate-400">
                A payment request will be sent to this address.
              </p>
            </div>
          )}
        </DetailSection>
      )}

      <DetailSection header="Actions">
        <DetailSection.Item term="Invoice">
          <Button
            size="smNoPadding"
            outline
            color="transparentSlate"
            className={'enabled:hover:bg-slate-800'}
            disabled={isDownloading}
            onClick={_handleDownload}>
            <span className="text-indigo-400">Download</span>
          </Button>
        </DetailSection.Item>

        {canBeEdited && (
          <DetailSection.Item term="Change">
            <Link to={`/dashboard/invoices/edit?invoiceId=${invoice._id}`}>
              <button className="font-medium text-indigo-400 hover:text-indigo-300">
                Edit Invoice
              </button>
            </Link>
          </DetailSection.Item>
        )}

        <DetailSection.Item term="Delete">
          <button
            className="font-medium text-rose-500  hover:text-rose-400"
            onClick={handleDelete}>
            Delete Invoice
          </button>
        </DetailSection.Item>
      </DetailSection>

      {isAutogeneratedInvoice && (
        <>
          <div className="rounded-md bg-slate-700 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-amber-300"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-slate-200">
                  This invoice was generated automatically when you submitted
                  payment request via pay link for{' '}
                  <span className="font-bold text-slate-300">
                    {invoice.client_name}
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {_renderReconciliationSection()}

      <ReconciliationModal
        invoice={invoice}
        show={reconciliationModalOpened}
        onClose={() => {
          toggleReconciliationModal(false);
        }}
      />
    </div>
  ) : (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner />
    </div>
  );
};
