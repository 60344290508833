import { NETWORK_TYPES, TOKEN_TYPES } from 'features/bill/lib';
import { useCallback } from 'react';

export const useBillsBatchPaymentChecker = (bills) => {
  const cryptoCryptoBatchAvailable =
    bills.length > 0 &&
    (bills.every((bill) => bill.network === NETWORK_TYPES.Ethereum) ||
      bills.every((bill) => bill.network === NETWORK_TYPES.Polygon)) &&
    (bills.every((bill) => bill.token === TOKEN_TYPES.USDC) ||
      bills.every((bill) => bill.token === TOKEN_TYPES.USDT)) &&
    bills.every((bill) => bill.paymentStatus === 'unpaid');

  const _prepareCryptoCryptoBatchItems = useCallback(() => {
    return bills.map((bill) => ({
      to: bill.walletAddress,
      amount: bill.amount,
      token: bill.token,
      network: bill.network,
    }));
  }, [bills]);

  return {
    batchAvailable: cryptoCryptoBatchAvailable,
    cryptoCryptoBatchAvailable,

    prepareCryptoCryptoBatchItems: _prepareCryptoCryptoBatchItems,
  };
};
