import axios from 'axios';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';

const _fetchPaymentsReport = async (token, params) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/public/organisation/reporting/payable-values`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    return res?.data?.result || {};
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const usePaymentsReport = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { hasAnyOrganization, getCurrentOrganizationId } =
    useCurrentUserDetails();
  const query = useQuery({
    queryKey: ['payments-report'],
    queryFn: () =>
      getAccessTokenSilently().then((t) =>
        _fetchPaymentsReport(t, {
          payableTargets: ['invoice', 'bill'],
          organizationId: getCurrentOrganizationId(),
          aggregationSpan: '7',
          aggregateBy: 'month',
        })
      ),

    enabled: hasAnyOrganization,
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 5,
  });
  return {
    reportData: query.data,
    isReportLoading: query.isPending,
  };
};
