class PaymentProvider {
  #name;
  #id;
  #onboardingType;
  #availableDepositCurrencies;
  availableDepositNetworks;

  constructor(config) {
    this.#name = config.name;
    this.#id = config.id;
    this.#onboardingType = config.onboardingType;
    this.#availableDepositCurrencies = config.availableDepositCurrencies;
    this.availableDepositNetworks = config.availableDepositNetworks;
  }

  get name() {
    return this.#name;
  }

  get id() {
    return this.#id;
  }

  get onboardingType() {
    return this.#onboardingType;
  }

  get availableDepositCurrencies() {
    return this.#availableDepositCurrencies || [];
  }

  getAvailableDepositNetworks() {
    return this.availableDepositNetworks;
  }

  requiresCustomerServiceOnboarding() {
    return this.#onboardingType === 'customer-service';
  }
}

export const createPaymentProvider = (c) => {
  return new PaymentProvider(c);
};
