export const GnosisSafeLogoWhite = ({ className, width = 64, height = 24 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 126 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M62.3794 22.9917C61.2313 22.5164 59.8289 22.1202 58.1725 21.8024H58.1373C56.528 21.5099 55.3207 21.2414 54.516 20.9969C53.7114 20.7538 53.0785 20.4243 52.6167 20.0093C52.1549 19.5949 51.9247 19.0216 51.9247 18.2895C51.9247 17.2162 52.3154 16.3991 53.0961 15.8382C53.8775 15.2773 54.9067 14.9964 56.1844 14.9964C57.5811 14.9964 58.7103 15.3622 59.5748 16.0943C60.3506 16.7518 60.8258 17.587 61.0003 18.5986C61.0348 18.8003 61.2003 18.9491 61.3988 18.9513L65.327 18.9817C65.5678 18.9839 65.7606 18.7706 65.7367 18.5231C65.6051 17.1698 65.1721 15.9391 64.4386 14.8317C63.622 13.6003 62.4977 12.6424 61.0658 11.9588C59.6339 11.276 58.007 10.9342 56.1844 10.9342C54.4569 10.9342 52.9011 11.2637 51.5164 11.9225C50.1323 12.5814 49.0553 13.4958 48.2858 14.667C47.5164 15.8382 47.132 17.1553 47.132 18.6189C47.132 20.2291 47.4748 21.5223 48.1612 22.4975C48.8476 23.4735 49.7466 24.2115 50.8588 24.7115C51.9711 25.2115 53.308 25.6077 54.8701 25.9009L55.0475 25.9372C56.7033 26.2543 57.9409 26.5351 58.7575 26.7789C59.5741 27.0235 60.2182 27.3645 60.692 27.8035C61.1651 28.2426 61.4016 28.8405 61.4016 29.5966C61.4016 30.6706 60.9757 31.5116 60.1239 32.1212C59.2721 32.7315 58.1711 33.0363 56.8222 33.0363C55.2122 33.0363 53.9113 32.6154 52.9173 31.7736C52.0127 31.008 51.4805 30.0248 51.3207 28.8245C51.2932 28.617 51.1257 28.4595 50.9229 28.4566L46.9996 28.3957C46.761 28.392 46.5681 28.5996 46.5864 28.8449C46.6976 30.3208 47.1454 31.651 47.9296 32.8352C48.7934 34.1407 49.9824 35.1653 51.4974 35.9091C53.0116 36.6536 54.7511 37.0252 56.7159 37.0252C58.5378 37.0252 60.1711 36.6841 61.6149 36.0006C63.0581 35.3177 64.1887 34.3722 65.0053 33.1647C65.8219 31.9572 66.2302 30.5973 66.2302 29.0843C66.2302 27.4741 65.8747 26.1752 65.1651 25.1868C64.4555 24.1992 63.5262 23.467 62.3787 22.991L62.3794 22.9917Z"
      fill="white"></path>
    <path
      d="M86.5026 32.8541H85.8894C85.5346 32.8541 85.2558 32.7569 85.0552 32.5609C84.8538 32.3665 84.7532 32.0486 84.7532 31.6096V24.6201C84.7532 22.4003 84.0668 20.711 82.6947 19.5521C81.322 18.394 79.7056 17.8033 77.1734 17.8033C74.7827 17.8033 72.9151 18.3388 71.448 19.3874C70.1104 20.3438 69.3044 21.6536 69.0284 23.319C68.9855 23.578 69.1756 23.8153 69.4297 23.8153H73.0376C73.2185 23.8153 73.3804 23.6927 73.4283 23.5127C73.593 22.8967 73.9563 22.3938 74.518 22.0041C75.1685 21.5527 75.9415 21.3271 76.9594 21.3271C78.8792 21.3271 80.2442 22.5469 80.2442 24.3639V24.9321C80.2442 25.1643 80.0619 25.3523 79.8366 25.3523H76.5687C73.9183 25.3523 71.9063 25.8893 70.5342 26.9625C69.1615 28.0365 68.4751 29.5611 68.4751 31.5363C68.4751 33.2445 69.1023 34.5863 70.3568 35.5615C71.298 36.293 72.423 36.7153 73.581 36.9004C74.4659 37.0419 75.3748 37.0934 76.2618 36.9468C77.191 36.7937 77.9457 36.3989 78.7011 35.8358C79.3797 35.3308 79.9457 34.6835 80.4624 33.894C80.7144 33.4477 81.3755 33.6269 81.3755 34.1429V34.7633C81.3748 35.891 82.2611 36.8053 83.3551 36.8053H86.5026C86.7278 36.8053 86.9102 36.6174 86.9102 36.3852V33.2735C86.9102 33.0413 86.7278 32.8534 86.5026 32.8534V32.8541ZM80.2449 29.0851C80.2449 29.9878 80.0612 30.7744 79.6944 31.4449C78.9256 32.8512 77.5501 33.5856 76.002 33.5856C75.1023 33.5856 74.3808 33.3664 73.8366 32.9274C73.2917 32.4884 73.02 31.9028 73.02 31.1706C73.02 30.3419 73.322 29.7011 73.9253 29.2498C74.5286 28.7991 75.386 28.5727 76.499 28.5727H79.8373C80.0626 28.5727 80.2449 28.7607 80.2449 28.9929V29.0851Z"
      fill="white"></path>
    <path
      d="M99.6551 11.202H94.9603C92.4837 11.202 90.4759 13.2715 90.4759 15.8244V17.8555C90.4759 18.0877 90.2936 18.2757 90.0683 18.2757H87.3876C87.1623 18.2757 86.98 18.4636 86.98 18.6958V21.8437C86.98 22.0759 87.1623 22.2639 87.3876 22.2639H90.0683C90.2936 22.2639 90.4759 22.4518 90.4759 22.684V36.3859C90.4759 36.6181 90.6583 36.806 90.8835 36.806H94.5414C94.7667 36.806 94.949 36.6181 94.949 36.3859V22.684C94.949 22.4518 95.1313 22.2639 95.3566 22.2639H99.6192C99.8445 22.2639 100.027 22.0759 100.027 21.8437V18.6958C100.027 18.4636 99.8445 18.2757 99.6192 18.2757H95.3566C95.1313 18.2757 94.949 18.0877 94.949 17.8555V16.0305C94.949 15.5661 95.3144 15.1902 95.7642 15.1902H99.6544C99.8797 15.1902 100.062 15.0022 100.062 14.77V11.6221C100.062 11.3899 99.8797 11.202 99.6544 11.202H99.6551Z"
      fill="white"></path>
    <path
      d="M117.575 22.0592C116.783 20.6935 115.699 19.6442 114.327 18.9121C112.955 18.1806 111.368 17.804 109.571 17.804C107.773 17.804 106.221 18.2111 104.849 19.0035C103.476 19.7966 102.411 20.9185 101.654 22.3698C100.897 23.8219 100.518 25.5228 100.518 27.4748C100.518 29.4268 100.909 31.0552 101.689 32.5065C102.471 33.9578 103.589 35.0739 105.044 35.8547C106.499 36.6355 108.209 37.0259 110.174 37.0259C111.688 37.0259 113.043 36.7393 114.239 36.166C115.433 35.5935 116.398 34.8061 117.131 33.8062C117.759 32.9499 118.188 32.0123 118.417 30.9942C118.477 30.7301 118.284 30.4761 118.02 30.4761H114.346C114.17 30.4761 114.015 30.5937 113.959 30.7657C113.7 31.5545 113.243 32.1778 112.588 32.635C111.854 33.1473 110.943 33.4034 109.854 33.4034C108.955 33.4034 108.168 33.2082 107.494 32.8178C106.819 32.4281 106.287 31.8788 105.896 31.1713C105.742 30.8919 105.616 30.5966 105.516 30.2925C105.408 29.966 105.33 29.6293 105.277 29.2882C105.259 29.1692 105.242 29.0488 105.228 28.9283C105.2 28.6801 105.391 28.4632 105.633 28.4632H118.305C118.511 28.4632 118.687 28.3064 118.71 28.0952C118.728 27.9407 118.74 27.7832 118.746 27.6214C118.758 27.329 118.764 27.0605 118.764 26.8166C118.764 25.0119 118.367 23.4264 117.575 22.06L117.575 22.0592ZM113.768 25.279H105.831C105.571 25.279 105.374 25.0301 105.432 24.7681C105.559 24.1941 105.778 23.676 106.091 23.2116C106.493 22.6144 107.014 22.1565 107.653 21.8394C108.292 21.5222 108.99 21.3633 109.747 21.3633C110.978 21.3633 111.996 21.7051 112.8 22.388C113.491 22.9743 113.946 23.7667 114.166 24.7659C114.224 25.0286 114.029 25.279 113.768 25.279Z"
      fill="white"></path>
    <path
      d="M37.4599 23.9975H33.9808C32.9417 23.9975 32.0998 24.8654 32.0998 25.9364V31.1416C32.0998 32.2126 31.2578 33.0805 30.2188 33.0805H16.3779C15.3388 33.0805 14.4968 33.9484 14.4968 35.0195V38.6057C14.4968 39.6767 15.3388 40.5446 16.3779 40.5446H31.0199C32.059 40.5446 32.8889 39.6767 32.8889 38.6057V35.7284C32.8889 34.6574 33.7309 33.8976 34.77 33.8976H37.4592C38.4982 33.8976 39.3402 33.0297 39.3402 31.9586V25.9139C39.3402 24.8429 38.4982 23.9975 37.4592 23.9975H37.4599Z"
      fill="white"></path>
    <path
      d="M14.4962 16.873C14.4962 15.8019 15.3382 14.934 16.3773 14.934H30.2097C31.2488 14.934 32.0907 14.0661 32.0907 12.9951V9.40886C32.0907 8.33779 31.2488 7.46991 30.2097 7.46991H15.5754C14.5364 7.46991 13.6944 8.33779 13.6944 9.40886V12.1722C13.6944 13.2432 12.8525 14.1111 11.8134 14.1111H9.13615C8.09708 14.1111 7.25513 14.979 7.25513 16.0501V22.1013C7.25513 23.1724 8.1006 23.9967 9.13968 23.9967H12.6187C13.6578 23.9967 14.4998 23.1289 14.4998 22.0578L14.4962 16.8737V16.873Z"
      fill="white"></path>
    <path
      d="M21.6605 20.2277H25.0023C26.0914 20.2277 26.9749 21.1391 26.9749 22.261V25.7057C26.9749 26.8282 26.0907 27.7389 25.0023 27.7389H21.6605C20.5715 27.7389 19.688 26.8275 19.688 25.7057V22.261C19.688 21.1384 20.5722 20.2277 21.6605 20.2277Z"
      fill="white"></path>
  </svg>
);
