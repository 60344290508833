import React, { useRef, useMemo, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import PayETHButton from 'widgets/Payments/PayETHButton';
import PayERC20Button from 'widgets/Payments/PayERC20Button';
import { ConfirmInWalletOverlay } from './PaymentModal.ConfirmInWalletOverlay';
import { SuccessConfirmation } from './PaymentModal.SuccessConfirmation';
import { ConfirmationSection } from './PaymentModal.ConfirmationSection';
import useExchangeRate from './useExchangeRate';
import { WarningsSection } from './PaymentModal.WarningsSection';
import { PaymentInformation } from './PaymentModal.PaymentInformation';
import erc20TokensData from 'shared/constants/erc20TokensData';
import {
  NETWORK_TO_CHAIN_ID_MAP,
  upperCaseNetworkType,
} from 'features/bill/lib';
import { useMarkInvoiceAsPaid } from 'features/invoice/useInvoiceViaPaymentAuthCode';
import { Modal } from 'shared/ui/Modal/Modal';
import { Button } from 'shared/ui/Button/Button';
import { ErrorMessage, Form, Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Label } from 'flowbite-react';
import { TextArea } from '../../shared/ui/form/TextArea';

const nativeTokens = ['ETH', 'MATIC', 'ARBETH'];

export const InvoicePaymentModal = ({
  pac,
  invoiceId,
  show,
  onClose,
  invoice,
}) => {
  const cancelButtonRef = useRef(null);
  const { address, chain } = useAccount();
  const [transactionInitiated, setTransactionInitiated] = useState(false);
  const [waitingForUserApproval, setWaitingForUserApproval] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { markInvoiceAsPaid, isLoading } = useMarkInvoiceAsPaid(invoiceId, pac);

  const chainId = useMemo(
    () => NETWORK_TO_CHAIN_ID_MAP[upperCaseNetworkType(invoice.paymentNetwork)],
    [invoice.paymentNetwork]
  );
  const tokenAddress =
    erc20TokensData[invoice.currency]?.erc20Address?.[
      upperCaseNetworkType(invoice.paymentNetwork)
    ];
  const usdValue = useExchangeRate(
    invoice.currency.toLowerCase(),
    invoice.totalAmount
  );

  const isNativeToken = nativeTokens.includes(invoice.currency);

  const { data: tokenBalance } = useBalance({
    address,
    chainId,
    token: invoice.token !== 'ETH' ? tokenAddress : undefined,
  });

  const paymentProps = useMemo(() => {
    const balance = tokenBalance?.formatted;
    const { paymentDetails, paymentNetwork, currency, totalAmount } = invoice;

    return {
      walletAddress: paymentDetails.address,
      network: upperCaseNetworkType(paymentNetwork),
      token: currency,
      amount: Number(totalAmount),
      balance,
      usdValue: usdValue.toFixed(2),
    };
  }, [invoice, tokenBalance, usdValue]);

  const handleConfirmationChange = (confirmed) => {
    setIsConfirmed(confirmed);
  };

  const _handleClose = () => {
    if (!isLoading && !waitingForUserApproval) {
      onClose();
    }
  };

  const _onReceiveTransactionHash = (txHash) => {
    setWaitingForUserApproval(false);
    setTransactionHash(txHash);

    markInvoiceAsPaid({
      txHash,
    }).finally(() => {
      setTransactionSuccessful(true);
    });
  };

  const _onUserApproval = (showWalletConfirmation) => {
    setWaitingForUserApproval(showWalletConfirmation);
  };

  // Renderers

  const _renderPayButton = () => {
    return isNativeToken ? (
      <PayETHButton
        addressTo={paymentProps.walletAddress}
        ethAmount={paymentProps.amount}
        tokenName={invoice.currency}
        network={upperCaseNetworkType(invoice.paymentNetwork)}
        disabled={!isConfirmed}
        payButtonStyles="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onUserApproval={_onUserApproval}
        setTransactionHash={_onReceiveTransactionHash}
        setTransactionInitiated={setTransactionInitiated}
      />
    ) : (
      <PayERC20Button
        addressTo={paymentProps.walletAddress}
        amountERC20={paymentProps.amount}
        tokenName={invoice.currency}
        tokenAddress={tokenAddress}
        network={upperCaseNetworkType(invoice.paymentNetwork)}
        disabled={!isConfirmed}
        payButtonStyles="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onUserApproval={_onUserApproval}
        setTransactionHash={_onReceiveTransactionHash}
        setTransactionInitiated={setTransactionInitiated}
      />
    );
  };

  return (
    <Modal
      popup
      size="lg"
      show={show}
      cancelButtonRef={cancelButtonRef}
      onClose={_handleClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>
      <Modal.Body>
        <ConfirmInWalletOverlay show={waitingForUserApproval} />

        <SuccessConfirmation
          show={transactionSuccessful}
          network={upperCaseNetworkType(invoice.paymentNetwork)}
          txHash={transactionHash}
          onClose={_handleClose}
          successDescription={`We have sent ${invoice.issuerName} an email confirming that the payment has been made.`}
          showSuccessButton={false}
        />

        <div>
          <PaymentInformation {...paymentProps} />

          <WarningsSection
            connectedChain={chain}
            payeeNetwork={upperCaseNetworkType(invoice.paymentNetwork)}
            payeeAmount={invoice.totalAmount}
            payeeToken={invoice.currency}
            payeeWalletAddress={invoice.paymentDetails.address}
            paymentProps={paymentProps}
            transactionInitiated={transactionInitiated}
            showPotentialDuplicate={false}
          />
          <ConfirmationSection
            isConfirmed={isConfirmed}
            setIsConfirmed={setIsConfirmed}
            onConfirmationChange={handleConfirmationChange}
          />
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          {_renderPayButton()}

          <Button
            color="gray"
            className="sm:order-first"
            onClick={_handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MarkInvoiceAsPaidFormSchema = yup.object({
  txHash: yup.string().required('Valid Transaction Hash is required'),
});
export const MarkInvoiceAsPaidModal = ({
  show,
  invoiceId,
  invoice,
  pac,
  onClose,
}) => {
  const { isSuccess, markInvoiceAsPaid, isLoading } = useMarkInvoiceAsPaid(
    invoiceId,
    pac
  );
  const [transactionHash, setTransactionHash] = useState('');
  const _handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await markInvoiceAsPaid(values);

      setTransactionHash(values.txHash);
    } catch (err) {
      toast.error(
        `Oops, something went wrong, contact support: pavel@getfractal.xyz`,
        {
          autoClose: 6000,
        }
      );
    }
  };

  return (
    <Modal size="lg" show={show} onClose={onClose}>
      <Modal.Header className="dark:border-slate-600">
        Mark Invoice As Paid
      </Modal.Header>

      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={_handleSubmit}
          validationSchema={MarkInvoiceAsPaidFormSchema}
          initialValues={{
            txHash: '',
          }}>
          {({ isSubmitting, values, validateForm, setFieldValue, errors }) => {
            return (
              <Form>
                <div className="text-left space-y-8">
                  <div className="min-h-[260px] grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                    <div className="sm:col-span-full">
                      <Label
                        className="!text-slate-400"
                        value="Transaction Hash"
                      />

                      <div className="mt-2">
                        <TextArea
                          rows="4"
                          value={values.txHash}
                          disabled={isSubmitting || isLoading}
                          onChange={(evt) => {
                            setFieldValue('txHash', evt.target.value);
                          }}
                          sizing="sm"
                          placeholder={'0x...'}
                          color="darkSlate"
                        />
                      </div>

                      <ErrorMessage
                        name="txHash"
                        component="div"
                        className="mt-2 text-xs text-rose-500"
                      />
                    </div>
                  </div>

                  <div className="flex flex-row-reverse gap-x-4 ">
                    <Button
                      size="sm"
                      type="submit"
                      color="indigo"
                      className="bg-indigo-600 w-38"
                      disabled={isSubmitting || isLoading}
                      isProcessing={isSubmitting || isLoading}>
                      Save
                    </Button>
                    <Button
                      size="sm"
                      color="gray"
                      className="w-38"
                      onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>

      <SuccessConfirmation
        show={isSuccess}
        title={'Invoice Marked as Paid!'}
        network={upperCaseNetworkType(invoice.paymentNetwork)}
        txHash={transactionHash}
        onClose={onClose}
        successDescription={`We have sent ${invoice.issuerName} an email confirming that the payment has been made.`}
        showSuccessButton={false}
      />
    </Modal>
  );
};
