import {
  TokenLogoMap,
  NetworkLogoMap,
  getTokenHumanReadableNameByChain,
} from 'features/bill/lib';

export function PaymentInformation({
  title = 'Payment Information',
  walletAddress,
  network,
  token,
  amount,
  balance,
  usdValue,
}) {
  return (
    <div className=" text-center ">
      <h3
        as=""
        className="mb-5 text-base font-semibold leading-6 text-slate-300">
        {title}
      </h3>

      {/* Payment Details */}
      <div className="mt-2">
        <div className="flex flex-col items-start">
          <div className="text-xs font-normal text-slate-400">Recipient</div>
          <div className="mt-1 mb-1 text-sm font-medium text-slate-300">
            {walletAddress}
          </div>
          <div className="flex items-center text-xs text-slate-400">
            <div className="mr-1 h-4 w-4">
              <img src={NetworkLogoMap[network]} alt="" aria-hidden="true" />
            </div>
            {network}
          </div>
        </div>

        <div className="mt-4 flex flex-col">
          <div className="mb-1 flex items-end justify-between">
            <div>
              <div className=" text-left text-xs font-normal text-slate-400">
                Token
              </div>

              <div className="mt-1 flex items-center justify-between text-xs text-slate-400">
                <div className="mr-1 h-4 w-4">
                  <img src={TokenLogoMap[token]} alt="" aria-hidden="true" />
                </div>
                <div className=" text-sm font-medium text-slate-300">
                  {getTokenHumanReadableNameByChain(token, network)}
                </div>
              </div>
            </div>
            <div className="text-sm font-medium text-slate-300">{amount}</div>
          </div>

          {balance && (
            <div className="flex items-start justify-between">
              <div className="text-xs font-normal text-slate-400">
                Balance: {parseFloat(balance).toFixed(4)}
              </div>
              {!!usdValue && (
                <div className="text-xs font-normal text-slate-400">
                  ${usdValue}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="my-4 border-b border-slate-600"></div>
    </div>
  );
}
