/** Mostly FE abstraction */
export enum PaymentProviderIdTypes {
  PaysoOnramp = 'payso-onramp',
  BridgeOnramp = 'bridge-onramp',
  BridgeOfframp = 'bridge-offramp',
  EasyPayOnramp = 'easy-pay-onramp',
  RedEnvelopeOnramp = 'red-envelope-onramp',
}

export enum PaymentMethodTypes {
  Payso = 'payso',
  EasyPay = 'easy_pay',
  RedEnvelope = 'red_envelope',
  Bridge = 'bridge',
}

export const paymentProviderIdMethodsMap: Record<
  PaymentProviderIdTypes,
  PaymentMethodTypes
> = {
  [PaymentProviderIdTypes.EasyPayOnramp]: PaymentMethodTypes.EasyPay,
  [PaymentProviderIdTypes.PaysoOnramp]: PaymentMethodTypes.Payso,
  [PaymentProviderIdTypes.RedEnvelopeOnramp]: PaymentMethodTypes.RedEnvelope,
  [PaymentProviderIdTypes.BridgeOnramp]: PaymentMethodTypes.Bridge,
  [PaymentProviderIdTypes.BridgeOfframp]: PaymentMethodTypes.Bridge,
};
