import { useCallback, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient, keepPreviousData } from '@tanstack/react-query';

export const useWeb3Options = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['web3-options'],
    queryFn: () => localStorage.getItem('web3OptionsEnabled'),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  const toggleWeb3Options = useCallback(() => {
    localStorage.setItem(
      'web3OptionsEnabled',
      query.data === 'true' ? 'false' : 'true'
    );
    queryClient.invalidateQueries({ queryKey: ['web3-options'] });
  }, [query.data, queryClient]);

  useEffect(() => {
    if (query.isFetched) {
      // if no data set, show the web3 options by default
      if (query.data === null) {
        toggleWeb3Options();
      }
    }
  }, [query, toggleWeb3Options]);

  return {
    web3OptionsEnabled: query.data === 'true',
    toggleWeb3Options,
  };
};

const UserBusinessTypeSetting = () => {
  const { web3OptionsEnabled, toggleWeb3Options } = useWeb3Options();

  return (
    <div className="">
      <div className="mt-10 sm:mt-0">
        <div className="bg-white px-4 py-5 shadow dark:bg-slate-800 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-white">
                Stablecoin Payments
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                Enable this option if you would like to send/receive funds in
                stablecoins.
              </p>
            </div>

            <div className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-6 sm:col-span-6">
                  <Switch.Group as="div" className="items-left flex flex-col">
                    <Switch.Label
                      as="span"
                      className="mb-2 text-sm font-medium leading-6 text-secondary-200"
                      passive>
                      Stablecoin Payments Enabled
                    </Switch.Label>
                    <Switch
                      checked={web3OptionsEnabled}
                      onChange={toggleWeb3Options}
                      className={classNames(
                        web3OptionsEnabled ? 'bg-indigo-600' : 'bg-gray-500',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                      )}>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          web3OptionsEnabled
                            ? 'translate-x-5'
                            : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBusinessTypeSetting;
