import { PlusIcon } from "@heroicons/react/20/solid";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function EmptyInvoicesComponent() {
  return (
    <div className="mt-20 text-center">
      <svg className="mx-auto h-12 w-12 ">
        <DocumentPlusIcon
          className="mx-auto h-12 w-12 text-gray-400"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-400">No invoices</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new invoice. <br />
        You can choose between crypto or fiat.
      </p>
      <div className="mt-6">
        <Link to="/dashboard/create-invoice">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New Invoice
          </button>
        </Link>
      </div>
    </div>
  );
}
