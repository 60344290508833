// For now simple abstraction over raw integrations
// In future specific structure for integrations PaysoIntegration, MoneriumIntegration, etc

import { PaymentProviderIdTypes } from '../payments/PaymentProviderIdTypes';

export class PlatformIntegrations {
  #rawIntegrations;

  constructor(rawIntegrations) {
    this.#rawIntegrations = rawIntegrations;
  }

  /** @param {PaymentProvider} paymentProvider */
  isPaymentProviderRequiresCSOnboarding(paymentProvider) {
    const requiresPaysoOnboarding =
      ['payso', 'payso-onramp'].includes(paymentProvider.id) &&
      paymentProvider.requiresCustomerServiceOnboarding() &&
      !this.#rawIntegrations.find((i) => i.payso);

    const requiresEasyPayOnboarding =
      [PaymentProviderIdTypes.EasyPayOnramp, 'easy-pay-offramp'].includes(
        paymentProvider.id
      ) &&
      paymentProvider.requiresCustomerServiceOnboarding() &&
      !this.#rawIntegrations.find((i) => i.easyPay);

    const requiresRedEnvelopeOnboarding =
      [
        PaymentProviderIdTypes.RedEnvelopeOnramp,
        'red-envelope-offramp',
      ].includes(paymentProvider.id) &&
      paymentProvider.requiresCustomerServiceOnboarding() &&
      !this.#rawIntegrations.find((i) => i.redEnvelope);

    const requiresBridgeOnboarding =
      [
        PaymentProviderIdTypes.BridgeOfframp,
        PaymentProviderIdTypes.BridgeOnramp,
      ].includes(paymentProvider.id) &&
      paymentProvider.requiresCustomerServiceOnboarding() &&
      !this.#rawIntegrations.find((i) => i.bridge);

    return (
      requiresPaysoOnboarding ||
      requiresEasyPayOnboarding ||
      requiresRedEnvelopeOnboarding ||
      requiresBridgeOnboarding
    );
  }
}

export const createPlatformIntegrations = (rawIntegrations) => {
  return new PlatformIntegrations(rawIntegrations);
};
