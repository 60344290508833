export const EmptyClientsComponent = () => {
  return (
    <div className="mt-20 text-center">
      <h3 className="mt-2 text-sm font-semibold text-gray-400">
        You have no clients yet
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        My clients management is upcoming soon
      </p>
    </div>
  );
};
