import axios from 'axios';
import { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentUser } from 'entities/user/model';
import { useAuth0 } from '@auth0/auth0-react';

const RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL + '/api/public/organisation/expenses';

const EXPENSE_REPORTS_RESOURCE_URL =
  process.env.REACT_APP_SERVER_URL +
  '/api/public/organisation/expenses/reports';

const fetchExpenses = async (getToken, organizationId) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${EXPENSE_REPORTS_RESOURCE_URL}/list`, {
      params: {
        organizationId,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const deleteExpense = async (getToken, organizationId, expenseId) => {
  const token = await getToken();

  try {
    const response = await axios.delete(
      `${EXPENSE_REPORTS_RESOURCE_URL}/${expenseId}`,
      {
        params: {
          organizationId,
        },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const updateExpense = async (getToken, organizationId, expenseId, data) => {
  const token = await getToken();

  try {
    const response = await axios.put(
      `${RESOURCE_URL}/${expenseId}`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          organizationId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const useExpenses = () => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: userData } = useCurrentUser();
  const organizationId = userData?.selected_organization?._id;
  const {
    data: expensesData,
    isFetching: isFetchingExpenses,
    isLoading: isLoadingExpenses,
  } = useQuery({
    queryKey: ['expenses'],
    queryFn: () => fetchExpenses(getToken, organizationId),
    enabled: !!organizationId,
    staleTime: 1000 * 60 * 5,
  });

  const deleteMutation = useMutation({
    mutationKey: ['delete-expense'],
    mutationFn: (expenseId) =>
      deleteExpense(getToken, organizationId, expenseId),
  });

  const updateMutation = useMutation({
    mutationKey: ['udpate-expense'],
    mutationFn: ({ expenseId, txHash, aragonProposalId, paymentStatus }) =>
      updateExpense(getToken, organizationId, expenseId, {
        txHash,
        paymentStatus,
        aragonProposalId,
      }),
  });

  const refreshExpenses = useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ['expenses'] });
  }, [queryClient]);

  const getExpense = useCallback(
    (id) => {
      return (expensesData?.result || []).find(
        (expense) => expense.reportId === id
      );
    },
    [expensesData?.result]
  );

  return {
    expenses: expensesData?.result || [],
    getExpense,
    expensesEnabled: !!organizationId,
    isFetchingExpenses,
    isDeletingExpense: deleteMutation.isLoading,
    deleteExpense: deleteMutation.mutateAsync,
    updateExpense: updateMutation.mutateAsync,
    isExpenseUpdating: updateMutation.isLoading,
    isLoadingExpenses,
    refreshExpenses,
  };
};

const download = async (getToken, { contentType, fileId }, organizationId) => {
  try {
    const token = await getToken();
    const response = await axios.get(
      `${RESOURCE_URL}/attachments/download/${fileId}`,
      {
        params: {
          organizationId,
          contentType,
        },
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useDownloadExpenseAttachment = () => {
  const { data: userData } = useCurrentUser();
  const organizationId = userData?.selected_organization?._id;
  const { getAccessTokenSilently: getToken } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['download-expense-attachments'],
    mutationFn: (data) => download(getToken, data, organizationId),
  });

  return {
    download: mutation.mutateAsync,
    isDownloading: mutation.isLoading,
  };
};
