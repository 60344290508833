import { Button } from '../../shared/ui/Button/Button';
import { useBalance } from 'wagmi';
import erc20TokensData from '../../shared/constants/erc20TokensData';
import {
  useCreateNewFractalBankAccount,
  useFractalBankAccounts,
} from './useFractalBankAccounts';
import { Spinner } from '../../shared/ui/Spinner/Spinner';
import { formatDollarAmount } from '../../shared/lib/string';
import { Label } from 'flowbite-react';
import { Modal } from '../../shared/ui/Modal/Modal';
import { useState } from 'react';
import { Select } from '../../shared/ui/form/Select/Select';
import { TextInput } from '../../shared/ui/form';
import { CURRENCY_TYPES, TOKEN_TYPES } from '../bill/lib';
import { useCreateAccountAbstractionWithPassKeySigner } from './useZeroDevAccountAbstraction';
import { Formik, Form, ErrorMessage } from 'formik';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  accountCurrency: Yup.string().required(),
  accountName: Yup.string().required('Required').max(50),
});

function formatWalletAddress(address) {
  if (!address) return '';

  if (address.startsWith('0x')) {
    return address.slice(-8).toUpperCase();
  }

  return address;
}

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      toast.success('Copied to clipboard!');
    },
    (err) => {
      toast.error('Failed to copy!');
      console.error('Could not copy text: ', err);
    }
  );
};

const FractalBankAccountLine = ({ account }) => {
  // from bank accounts
  const { data: tokenBalance } = useBalance({
    address: account?.paymentDetails?.cryptoWallet.address,
    chainId: 137,
    token: erc20TokensData.USDC.erc20Address.Polygon,
  });

  return (
    <li className="py-3 sm:py-3">
      <div className="flex items-center space-x-4">
        <div className="min-w-0 flex-1">
          <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
            🇺🇸 {account.name}
          </p>
          <p className="truncate text-sm text-gray-500 dark:text-slate-400">
            Account Number:{' '}
            {formatWalletAddress(account.paymentDetails?.cryptoWallet.address)}
            <button
              onClick={() =>
                copyToClipboard(account.paymentDetails?.cryptoWallet.address)
              }
              className="ml-1  hover:text-gray-700 dark:hover:text-white">
              <ClipboardDocumentIcon className="h-3 w-3" aria-hidden="true" />
            </button>
          </p>
        </div>
        <div className="inline-flex items-center text-base  text-gray-900 dark:text-white">
          $
          {tokenBalance?.formatted
            ? formatDollarAmount(tokenBalance?.formatted)
            : '--'}{' '}
        </div>
      </div>
    </li>
  );
};

export const FractalBankAccountsWidget = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { bankAccounts, isFetching, isLoading } = useFractalBankAccounts();
  const _handleCreateNewBankAccount = () => {
    setShowCreateModal(true);
  };

  return (
    <>
      <div className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-slate-800 sm:p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white py-2">
            Fractal Global Accounts
          </h3>
        </div>
        <div className="flow-root">
          {isFetching || isLoading ? (
            <div className="h-[324px] flex items-center justify-center">
              <Spinner size="xl" color="indigo" />
            </div>
          ) : (
            <>
              {bankAccounts?.length === 0 ? (
                <div className="h-[324px] gap-3 flex flex-col items-center justify-center">
                  <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                    You don't have an account with Fractal yet.
                  </p>
                  <p className="text-sm text-center text-gray-500 dark:text-slate-400">
                    Fractal accounts are self-hosted wallets that allow you to
                    make both crypto & fiat payments.
                  </p>
                  <Button color="gray" onClick={_handleCreateNewBankAccount}>
                    + Create an Account
                  </Button>
                </div>
              ) : (
                <>
                  <ul className="divide-y divide-slate-200 dark:divide-slate-700">
                    {(bankAccounts || []).map((acc) => (
                      <FractalBankAccountLine account={acc} key={acc.id} />
                    ))}
                  </ul>
                  <div className="flex justify-center p-4">
                    <Button
                      size={'sm'}
                      color={'slate'}
                      onClick={_handleCreateNewBankAccount}>
                      + Add New
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <CreateNewAccountModal
        show={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
        }}
      />
    </>
  );
};

const CreateNewAccountModal = ({ show, onClose }) => {
  const { isPending, createNewFractalBankAccount } =
    useCreateNewFractalBankAccount();
  const { isCreatingAccount, createAccount } =
    useCreateAccountAbstractionWithPassKeySigner();

  const handleSubmit = async (values) => {
    const { accountName, accountCurrency } = values;
    const { accountAddress, accountNetwork, signerType, providerId } =
      await createAccount({ accountName, accountCurrency });
    await createNewFractalBankAccount({
      accountCurrency,
      accountName,
      accountType: 'blockchain_smart_account',
      blockchainSmartAccountData: {
        address: accountAddress,
        network: accountNetwork,
        token: TOKEN_TYPES.USDC,
        providerId,
        signerType,
      },
    });

    onClose();
  };

  return (
    <Modal popup size="lg" show={show} onClose={onClose}>
      <Modal.Header className="dark:border-slate-600"></Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
          initialValues={{
            accountCurrency: CURRENCY_TYPES.USD,
            accountName: '',
          }}>
          {({ setFieldValue, values }) => {
            return (
              <Form>
                <div className="grid gap-y-4">
                  <div className="grid text-left gap-y-2">
                    <Label className="!text-slate-400" value="Account Name" />
                    <TextInput
                      disabled={isCreatingAccount || isPending}
                      value={values.accountName}
                      onChange={(evt) => {
                        setFieldValue('accountName', evt.target.value);
                      }}
                      sizing="md"
                      color="lightSlate"
                    />
                    <ErrorMessage
                      name="accountName"
                      component="div"
                      className="text-xs text-rose-500"
                    />
                  </div>
                  <div className="grid text-left gap-y-2">
                    <Label
                      className="!text-slate-400"
                      value="Account Currency"
                    />
                    <Select
                      disabled={isCreatingAccount || isPending}
                      sizing="md"
                      color="slate"
                      value={values.accountCurrency}
                      onChange={(evt) => {
                        setFieldValue('accountCurrency', evt.target.value);
                      }}>
                      <option value="" disabled>
                        --
                      </option>
                      <option value={CURRENCY_TYPES.USD}>
                        {CURRENCY_TYPES.USD}
                      </option>
                    </Select>
                  </div>
                </div>

                <div className="mt-8  flex gap-4">
                  <Button onClick={onClose} className="flex-1" color="slate">
                    Cancel
                  </Button>
                  <Button
                    type={'submit'}
                    isProcessing={isCreatingAccount || isPending}
                    disabled={isCreatingAccount || isPending}
                    className="flex-1"
                    color="indigo">
                    Create
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
