import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ContentLoadingState } from 'shared/ui/layouts/PageContent';
import { useLocation } from 'react-router-dom';

export default function LoginPage() {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');

  useEffect(() => {
    loginWithRedirect({ login_hint: email ? email : '' });
  }, [loginWithRedirect]);

  return (
    <div className="bg-slate-900 lg:h-screen">
      <ContentLoadingState />
    </div>
  );
}
