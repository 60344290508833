import React from "react";
import { InlineWidget } from "react-calendly";

import {
  BoltIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
  ScaleIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  WalletIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Automated Bill Payments",
    description:
      "Automate bill payments, never miss a payment and save time on bookkeeping.",
    icon: WalletIcon,
  },
  {
    name: "Compliant Invoices",
    description:
      "Generate compliant invoices for both crypto & fiat in less than 10 seconds. ",
    icon: ClipboardDocumentCheckIcon,
  },

  {
    name: "Keep Track of Payments",
    description:
      "Keep track of all your paid and unpaid bills in one place. Send payment requests to your clients.",
    icon: ListBulletIcon,
  },
  {
    name: "Get Paid Faster",
    description:
      "Save hours of time on admin and get paid faster. Avoid payment errors and simplify your accounting.",
    icon: BoltIcon,
  },
];

const PageAbout = () => {
  return (
    <div className="mx-auto mb-40 max-w-7xl px-4 sm:px-6 md:px-8">
      <div className="rounded-lg bg-white py-24 dark:bg-slate-800 sm:py-10 lg:py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="sm:text-center">
            {/* <h2 className="text-lg font-semibold leading-8 text-indigo-600 ">
              Crypto Payments
            </h2> */}
            <p className="text-gradient-straight mt-2 text-3xl font-bold tracking-tight dark:text-[#2B89DC] sm:text-4xl">
              Seamless Crypto
            </p>
            <p className="text-gradient-straight mt-2 text-3xl font-bold tracking-tight dark:text-[#2B89DC] sm:text-4xl">
              Invoicing & Payments
            </p>
            <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 dark:text-white">
              Fractal streamlines billing and payment processing for Web3
              businesses, DAOs, and freelancers. Get paid faster, avoid payment
              errors and simplify your accounting.
              <br></br>For more information, please visit our{" "}
              <a
                href="https://getfractal.xyz"
                className=" underline hover:text-sky-600"
                target="_blank">
                website
              </a>
              .
            </p>
          </div>

          <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
            <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                  <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-slate-900 text-white sm:shrink-0">
                    <feature.icon className="h-8 w-8" aria-hidden="true" />
                  </div>
                  <div className="sm:min-w-0 sm:flex-1">
                    <p className="text-lg font-semibold leading-8 text-gray-900 dark:text-[#2B89DC]">
                      {feature.name}
                    </p>
                    <p className="mt-2 text-base leading-7 text-gray-600 dark:text-white">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-10 sm:text-center">
              {/* <h2 className="text-lg font-semibold leading-8 text-indigo-600 ">
              Crypto Payments
            </h2> */}
              <p className="text-gradient mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                Get Started
              </p>
              <p className="max-w-auto mx-auto mt-6 text-lg leading-8 text-gray-600 dark:text-white">
                Schedule a call with our founder to learn how you can streamline
                your onchain & offchain financial operations.
              </p>

              <div>
                <InlineWidget
                  url="https://calendly.com/pavel_fractal/30min"
                  styles={{ height: "630px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
