import React from 'react';
import { twMerge } from 'tailwind-merge';

const sizeStyles = {
  xs: 'px-2 py-1 gap-x-1.5 rounded text-xs',
  sm: 'px-2 py-1 gap-x-1.5 rounded text-sm',
  md: 'px-2.5 py-1.5 gap-x-1.5 rounded-md text-sm',
  lg: 'px-3 py-2 gap-x-1.5 rounded-md text-sm',
  xl: 'px-3.5 py-2.5 gap-x-2 rounded-md text-sm',
};

const colorStyles = {
  primary: {
    base: 'bg-indigo-600 hover:bg-indigo-500 focus-visible:bg-indigo-600 active:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2',
    active: 'bg-primary-100 text-black',
  },
  secondary: {
    base: 'bg-secondary-500 hover:bg-secondary-400 focus-visible:outline-secondary-500 active:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2',
    active: 'bg-secondary-100 text-black',
  },
  success: {
    base: 'bg-emerald-600 hover:bg-emerald-500 focus-visible:outline-success-500 active:bg-success-600 focus:outline-none focus:ring-2 focus:ring-offset-2',
    active: 'bg-success-100 text-black',
  },
  warning: {
    base: 'bg-warning-500 hover:bg-warning-400 focus-visible:outline-warning-500 active:bg-warning-600 focus:outline-none focus:ring-2 focus:ring-offset-2',
    active: 'bg-warning-100 text-black',
  },
  danger: {
    base: 'bg-danger-500 hover:bg-danger-400 focus-visible:outline-danger-500 active:bg-danger-600 focus:outline-none focus:ring-2 focus:ring-offset-2',
    active: 'bg-danger-100 text-black',
  },
  info: {
    base: 'bg-info-500 hover:bg-info-400 focus-visible:outline-info-500 active:bg-info-600 focus:outline-none focus:ring-2 focus:ring-offset-2',
    active: 'bg-info-100 text-black',
  },
  link: {
    base: 'shadow-none bg-transparent text-secondary-600 hover:text-primary-600 hover:bg-transparent focus-visible:none active:bg-transparent focus:outline-none focus:ring-none',
    active: 'bg-transparent text-black',
  },
};

export const Button = ({
  children,
  className,
  type = 'button',
  size = 'md',
  ui = 'primary',
  LeadingIcon,
  TrailingIcon,
  onClick,
  active,
  ...props
}) => {
  const sizeStyle = sizeStyles[size];
  const colorStyle = twMerge(
    colorStyles[ui].base,
    active && colorStyles[ui].active
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(
        'inline-flex items-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        sizeStyle,
        colorStyle,
        props.disabled && 'disabled:pointer-events-none disabled:opacity-50',
        className
      )}
      {...props}>
      {LeadingIcon && (
        <LeadingIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
      )}
      {children}
      {TrailingIcon && (
        <TrailingIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      )}
    </button>
  );
};
