import { twMerge } from "tailwind-merge";

export const NumberField = ({ form, field, className, ...props }) => {
  const { label, hint, hintCmp, ...inputProps } = props;
  return (
    <div className={twMerge("col-span-6 sm:col-span-2", className)}>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700 dark:text-[#CBD5E1]">
        {label}
      </label>
      <input
        id={field.name}
        type="number"
        className="mt-1 block w-full rounded-md border-2 border-[#64748b] bg-[#18222d] text-[#FFFFFF]  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        {...field}
        {...inputProps}
      />
      {hint && (
        <p className="mt-2 text-sm text-gray-500 dark:text-[#B2BDCC]">{hint}</p>
      )}
      {hintCmp && hintCmp}
    </div>
  );
};
