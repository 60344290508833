import { useCurrentUser } from "entities/user/model";

export const ROLES = {
  ADMIN: "admin",
  MEMBER: "member",
  ACCOUNTANT: "accountant",
};

export const useOrgPermissions = () => {
  const { data: userData } = useCurrentUser();

  return {
    hasPermissions: (permission = "") => {
      // We will have permissions in future like: "bills.create, bills.edit, bills.delete"
      // Every role will have different permissions from BE and we will check if cur user role in ORG has particular permission
      // For MVP we just return false for all permissions for readonly users

      if (userData?.selected_organization?.userRole === ROLES.ACCOUNTANT) {
        return false;
      }

      return true;
    },
  };
};
