export const PaymentDetailsSummary = ({
  userPaymentDetailsList,
  selectedPaymentDetailsId,
}) => {
  const selectedPaymentDetails = (userPaymentDetailsList || []).find(
    (d) => d.id === selectedPaymentDetailsId
  );

  if (!selectedPaymentDetails) {
    return null;
  }

  // Renderers

  const renderWalletDetails = (cryptoWallet) => {
    return (
      <>
        <span>Address: </span>
        <span className="dark:text-slate-300">{cryptoWallet.address}</span>

        {!!cryptoWallet.ensAddress && (
          <>
            <br /> <span>ENS Address:</span>{' '}
            <span className="dark:text-slate-300">
              {cryptoWallet.ensAddress}
            </span>
          </>
        )}
      </>
    );
  };

  const renderBankDetails = (bankAccount) => {
    return (
      <>
        {!!bankAccount.accountBeneficiary && (
          <>
            <span>Beneficiary: </span>
            <span className="dark:text-slate-300">
              {bankAccount.accountBeneficiary}
            </span>
          </>
        )}
        {!!bankAccount.accountNumber && (
          <>
            <br /> <span>Account Number: </span>
            <span className="dark:text-slate-300">
              {bankAccount.accountNumber}
            </span>
          </>
        )}
        {!!bankAccount.accountIBAN && (
          <>
            <br /> <span>IBAN: </span>
            <span className="dark:text-slate-300">
              {bankAccount.accountIBAN}
            </span>
          </>
        )}
        {!!bankAccount.accountACHRoutingNumber && (
          <>
            <br /> <span>Routing Number: </span>
            <span className="dark:text-slate-300">
              {bankAccount.accountACHRoutingNumber}
            </span>
          </>
        )}
        {!!bankAccount.bankName && (
          <>
            <br /> <span>Bank Name: </span>
            <span className="dark:text-slate-300">{bankAccount.bankName}</span>
          </>
        )}
      </>
    );
  };

  return (
    <div className="col-span-8 sm:col-span-6">
      <div
        id="alert-5"
        className="rounded-lg bg-gray-50 p-4  text-left dark:bg-slate-700"
        role="alert">
        <div className="text-sm text-gray-800 dark:text-slate-400">
          {!!selectedPaymentDetails.details.cryptoWallet ? (
            <>
              {renderWalletDetails(selectedPaymentDetails.details.cryptoWallet)}
            </>
          ) : (
            <>{renderBankDetails(selectedPaymentDetails.details.bankAccount)}</>
          )}
        </div>
      </div>
    </div>
  );
};
