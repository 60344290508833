import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLinkActions } from './actions.js';
import { QUERY_KEY } from './constants';

export const useLinkMutations = () => {
  const queryClient = useQueryClient();
  const { createAction, deleteAction, updateAction } = useLinkActions();

  const create = useMutation({
    mutationFn: async (values) => {
      const response = await createAction(values);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const deleteLink = useMutation({
    mutationFn: async (urlId) => {
      const response = await deleteAction(urlId);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const updateLink = useMutation({
    mutationFn: async ({ urlId, values }) => {
      const response = await updateAction(urlId, values);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  }, [queryClient]);

  return { create, deleteLink, updateLink, invalidate };
};
