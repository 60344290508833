import { createContext, useContext, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useOrganizationUserActions } from './actions';
import { QUERY_KEY } from './constants';

export const UserContext = createContext();

export const useCurrentUser = () => {
  return useContext(UserContext);
};

export const useCurrentUserDetails = () => {
  const { data } = useCurrentUser();

  const hasAccountingIntegration = () => {
    return (data?.selected_organization?.integrations || []).some(
      (i) => i.codat && i.codat.connectionId
    );
  };

  const hasMoneriumIntegration = () => {
    return (data?.selected_organization?.integrations || []).some(
      (i) => i.monerium
    );
  };

  const hasPaysoIntegration = () => {
    return (data?.selected_organization?.integrations || []).some(
      (i) => i.payso
    );
  };

  const hasBridgeIntegration = useCallback(() => {
    return (data?.selected_organization?.integrations || []).some(
      (i) => i.bridge
    );
  }, [data]);

  const hasEasyPayIntegration = useCallback(() => {
    return (data?.selected_organization?.integrations || []).some(
      (i) => i.easyPay
    );
  }, [data]);

  const getCurrentOrganizationId = useCallback(() => {
    return data?.selected_organization?._id;
  }, [data]);

  const getOrgIntegrations = useCallback(() => {
    return data?.selected_organization?.integrations || [];
  }, [data]);

  const getOrgSettings = useCallback(() => {
    return data?.selected_organization?.orgSettings;
  }, [data]);

  const getCurrentOrgRole = useCallback(() => {
    return data?.selected_organization?.userRole;
  }, [data]);

  return {
    data,
    hasLoggedInUser: !!data?.user_id,
    userId: data?.userId,
    currentOrganization: data.selected_organization,
    getCurrentOrganizationId,
    hasAnyOrganization: !!data?.selected_organization?._id,
    getOrgIntegrations,
    getOrgSettings,
    hasMoneriumIntegration,
    hasPaysoIntegration,
    hasAccountingIntegration,
    hasEasyPayIntegration,
    hasBridgeIntegration,

    getCurrentOrgRole: getCurrentOrgRole,
  };
};

export const useUsers = () => {
  const { fetchUsersAction } = useOrganizationUserActions();
  const { data } = useCurrentUser();

  return useQuery({
    queryKey: [QUERY_KEY, data],
    queryFn: () => fetchUsersAction(),
    enabled: !!data.nickname,
  });
};
