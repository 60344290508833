import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/email';

export const useEmailActions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const mutation = useMutation({
    mutationKey: ['send-invoice-email'],
    mutationFn: async (data) =>
      getAccessTokenSilently().then((token) =>
        axios.post(`${RESOURCE_URL}/send-email`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ),
  });

  return {
    sendAction: mutation.mutateAsync,
    isSending: mutation.isPending,
  };
};
