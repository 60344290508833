import axios from 'axios';
import _ from 'lodash';
import { useState, useCallback } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCurrentUserDetails } from 'entities/user/model';
import { USER_BILLS_QUERY_KEY } from './constants';

const RESOURCE_URL = process.env.REACT_APP_SERVER_URL + '/api/public/bills';

const fetchBills = async (getToken, params) => {
  const token = await getToken();

  try {
    const response = await axios.get(`${RESOURCE_URL}/list`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createQueryParams = ({
  billType,
  pageNumber,
  pageLimit,
  organizationId,
  generalSearchString,
  fromCreatedAtDate,
  toCreatedAtDate,
}) => {
  return {
    filter: {
      billType,
      generalSearchString,

      fromCreatedAtDate,
      toCreatedAtDate,
    },
    pageNumber,
    pageLimit,
    organizationId,
  };
};

export const useUserBills = (initQueryOptions) => {
  const [queryOptions, setQueryOptions] = useState(initQueryOptions);
  const { getAccessTokenSilently: getToken } = useAuth0();
  const { data: userData, hasLoggedInUser } = useCurrentUserDetails();
  const organizationId = userData?.selected_organization?._id;

  const dataQuery = useQuery({
    queryKey: [
      USER_BILLS_QUERY_KEY,
      queryOptions.pageNumber,
      queryOptions.generalSearchString,
      queryOptions.fromCreatedAtDate,
      queryOptions.toCreatedAtDate,
    ],
    queryFn: () =>
      fetchBills(
        getToken,
        createQueryParams({
          pageNumber: queryOptions.pageNumber,
          pageLimit: queryOptions.pageLimit,
          generalSearchString: queryOptions.generalSearchString,
          fromCreatedAtDate: queryOptions.fromCreatedAtDate,
          toCreatedAtDate: queryOptions.toCreatedAtDate,
          organizationId,
        })
      ),
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60,
    enabled: hasLoggedInUser,
  });

  const _updateQueryOptions = useCallback(
    ({
      pageNumber,
      pageLimit,
      generalSearchString,
      fromCreatedAtDate,
      toCreatedAtDate,
    }) => {
      const update = {};

      if (pageNumber !== undefined) {
        update.pageNumber = pageNumber;
      }

      if (pageLimit !== undefined) {
        update.pageLimit = pageLimit;
      }

      if (generalSearchString !== undefined) {
        update.generalSearchString = generalSearchString;
      }

      if (fromCreatedAtDate !== undefined) {
        update.fromCreatedAtDate = fromCreatedAtDate;
      }

      if (toCreatedAtDate !== undefined) {
        update.toCreatedAtDate = toCreatedAtDate;
      }

      setQueryOptions({
        ...queryOptions,
        ...update,
      });
    },
    [setQueryOptions, queryOptions]
  );

  const _hasFilterOptions = useCallback(() => {
    return !(
      (_.isUndefined(queryOptions.generalSearchString) ||
        queryOptions.generalSearchString === '') &&
      (_.isUndefined(queryOptions.fromCreatedAtDate) ||
        queryOptions.fromCreatedAtDate === '') &&
      (_.isUndefined(queryOptions.toCreatedAtDate) ||
        queryOptions.toCreatedAtDate === '')
    );
  }, [queryOptions]);

  return {
    bills: dataQuery.data?.result || [],
    billsTotal: dataQuery.data?.total || -1,
    isLoading: dataQuery.isPending,
    isFetching: dataQuery.isFetching,
    isSuccess: dataQuery.isSuccess,
    isInitialLoading: dataQuery.isInitialLoading,
    queryOptions,
    updateQueryOptions: _updateQueryOptions,
    hasFilterOptions: _hasFilterOptions,
  };
};
