import React, { useState, useEffect } from "react";
import logoPurple2 from "shared/images/logoPurpleBlackBackground2.png";
import LoginButton from "widgets/Authentication/LoginButton";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import Loading from "shared/ui/Loading";

const PageUpdateBillStatus = () => {
  let [searchParams] = useSearchParams();

  const billId = searchParams.get("billId");
  const userEmail = searchParams.get("userEmail");
  const status = searchParams.get("status");
  const urlId = searchParams.get("urlId");
  const organizationId = searchParams.get("organizationId");
  const [isLoading, setIsLoading] = useState(true);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    async function updateBillStatus() {
      try {
        const res = await axios.post(
          process.env.REACT_APP_SERVER_URL + `/email/handle-bill-status-update`,
          {
            billId: billId,
            userEmail: userEmail,
            status: status,
            organizationId: organizationId,
            urlId: urlId,
          }
        );
        setIsLoading(false);
        if (res.status === 200) {
          console.log("Bill status updated successfully");
          setAdmins(res.data.admins);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        throw new Error("Error while updating bill status");
      }
    }

    updateBillStatus();
  }, []);

  if (isLoading) {
    return (
      <div className="grid h-screen place-items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex h-screen items-center justify-center bg-gray-900 ">
      <div className="w-full max-w-lg px-5 ">
        <div className="mb-4 text-center ">
          <img
            src={logoPurple2}
            alt="Company logo"
            className="h-25 mx-auto my-auto w-auto max-w-sm "
          />
          <p className="text-md py-4 text-gray-300">
            Bill status updated to{" "}
            <strong
              className={
                status === "approved" ? "text-green-500" : "text-red-500"
              }>
              {status}
            </strong>
            .
            <br />
          </p>

          <p className="text-md py-4 text-gray-300">
            Your bill status was successfully updated to{" "}
            <strong>{status}</strong>.
            <br />
            {admins && (
              <>
                An email has been sent to the following admins in your
                organization:{" "}
                {admins.map((admin, index) => (
                  <React.Fragment key={index}>
                    {admin}
                    {index < admins.length - 1 ? ", " : ""}
                  </React.Fragment>
                ))}
              </>
            )}
          </p>
        </div>

        <form className="">
          <div className="flex items-center justify-center">
            <LoginButton email={userEmail || ""} />
          </div>
        </form>

        <div className="mb-4 text-center ">
          <p className="py-8  text-xs text-gray-300">
            By using Fractal, you agree to our{" "}
            <Link to="/terms" className="text-blue-500">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to="/privacy" className="text-blue-500">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageUpdateBillStatus;
