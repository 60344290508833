import { useRef } from 'react';
import { ModalStyles } from './PaymentModal.ModalStyles';
import React, { useState, useMemo } from 'react';
import { PaymentInformation } from './PaymentModal.PaymentInformation';
import { TransactionValidation } from './PaymentModal.TransactionValidation';
import { WarningsSection } from './PaymentModal.WarningsSection';
import { ConfirmationSection } from './PaymentModal.ConfirmationSection';
import { ConfirmInWalletOverlay } from './PaymentModal.ConfirmInWalletOverlay';
import { SuccessConfirmation } from './PaymentModal.SuccessConfirmation';
import { SuccessConfirmation as WaitingSuccessConfirmation } from './CryptoToFiatPaymentModal.SuccessConfirmation';
import PayBillButton from 'widgets/Payments/PaymentModal.PayBillButton.jsx';
import useExchangeRate from './useExchangeRate';
import { useAccount, useBalance } from 'wagmi';
import { useSafeWallet } from 'shared/hooks/safeWallet';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Button } from 'shared/ui/Button/Button';
import {
  NETWORK_TO_CHAIN_ID_MAP,
  upperCaseNetworkType,
} from '../../features/bill/lib';
import erc20TokensData from '../../shared/constants/erc20TokensData';

export default function PaymentModal({
  show,
  onClose,
  bill,
  handleSuccess,
  handleSuccessIsLoading = false,
  successLink,
}) {
  const cancelButtonRef = useRef(null);
  const { isConnectedWalletSafe } = useSafeWallet();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [transactionInitiated, setTransactionInitiated] = useState(false);
  const [waitingForUserApproval, setWaitingForUserApproval] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionHash, setTransactionHash] = useState('');

  const { address, chain, isConnected } = useAccount();

  const chainId = useMemo(
    () => NETWORK_TO_CHAIN_ID_MAP[bill.network],
    [bill.network]
  );

  const { data: tokenBalance } = useBalance({
    address,
    chainId,
    token:
      bill.token !== 'ETH'
        ? erc20TokensData[bill?.token]?.erc20Address?.[
            upperCaseNetworkType(bill?.network)
          ]
        : undefined,
  });

  const usdValue = useExchangeRate(bill.token.toLowerCase(), bill.amount);

  const paymentProps = useMemo(() => {
    const balance = tokenBalance?.formatted;
    const { walletAddress, network, token, amount } = bill;

    return {
      walletAddress,
      network,
      token,
      amount,
      balance,
      usdValue: usdValue.toFixed(2),
    };
  }, [bill, tokenBalance, usdValue]);

  const handleConfirmationChange = (confirmed) => {
    setIsConfirmed(confirmed);
  };

  const handleUserApproval = (flag) => {
    setWaitingForUserApproval(flag);
  };

  const _handleModalClose = () => {
    if (!waitingForUserApproval && !handleSuccessIsLoading) {
      onClose();
    }
  };

  return (
    <ModalStyles
      show={show}
      cancelButtonRef={cancelButtonRef}
      onClose={_handleModalClose}>
      <div className="relative">
        <ConfirmInWalletOverlay
          show={waitingForUserApproval || handleSuccessIsLoading}
        />
        {isConnectedWalletSafe() ? (
          <WaitingSuccessConfirmation
            title="Multisig Payment Initiated"
            description="We will update the payment status once Safe Transaction is signed by required owners."
            show={transactionSuccessful}
            onClose={_handleModalClose}
          />
        ) : (
          <SuccessConfirmation
            show={transactionSuccessful}
            network={bill.network}
            txHash={transactionHash}
            onClose={_handleModalClose}
            successLink={successLink}
          />
        )}

        <div>
          <PaymentInformation {...paymentProps} />
          <TransactionValidation
            bill={bill}
            address={address}
            isConnected={isConnected}
          />
          <WarningsSection
            connectedChain={chain}
            payeeNetwork={bill.network}
            payeeAmount={bill.amount}
            payeeToken={bill.token}
            payeeWalletAddress={bill.walletAddress}
            paymentProps={paymentProps}
            transactionInitiated={transactionInitiated}
          />
          <ConfirmationSection
            isConfirmed={isConfirmed}
            setIsConfirmed={setIsConfirmed}
            onConfirmationChange={handleConfirmationChange}
          />
        </div>
        {/* Buttons */}

        {isConnected ? (
          <>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <PayBillButton
                bill={bill}
                disabled={!isConfirmed}
                onUserApproval={handleUserApproval}
                handleSuccess={handleSuccess}
                payButtonStyles={
                  'inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                }
                setTransactionSuccessful={setTransactionSuccessful}
                setTransactionHash={setTransactionHash}
                setTransactionInitiated={setTransactionInitiated}
              />
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:order-first sm:mt-0"
                onClick={_handleModalClose}
                ref={cancelButtonRef}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <div className="mt-5">
            <ConnectButton.Custom>
              {({ openConnectModal }) => (
                <Button onClick={openConnectModal} fullSized cl color="indigo">
                  Connect Your Wallet
                </Button>
              )}
            </ConnectButton.Custom>
          </div>
        )}
      </div>
    </ModalStyles>
  );
}
