export function generateRandomPassword() {
  const length = 12;
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }

  return password;
}

export function getRelativeTime(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const secondsAgo = Math.floor((now - date) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  if (daysAgo > 1) {
    return daysAgo + " days ago";
  } else if (daysAgo === 1) {
    return daysAgo + " day ago";
  } else if (hoursAgo > 1) {
    return hoursAgo + " hours ago";
  } else if (hoursAgo === 1) {
    return hoursAgo + " hour ago";
  } else if (minutesAgo > 1) {
    return minutesAgo + " mins ago";
  } else if (minutesAgo === 1) {
    return minutesAgo + " min ago";
  } else {
    return "Just now";
  }
}

export const configureUrlPath = (originalUrl, newOrigin) => {
  const urlObj = new URL(originalUrl);
  const newPath = urlObj.pathname + urlObj.search + urlObj.hash;
  return newOrigin + newPath;
};

export function shortenString(str, maxLength) {
  const dots = "...";

  if (str.length <= maxLength) {
    return str;
  }

  const leftSideLength = Math.floor((maxLength - dots.length) / 2);
  const leftPart = str.slice(0, leftSideLength);

  const rideSideLength = maxLength - leftSideLength - dots.length;
  const rightPart = str.slice(str.length - rideSideLength);

  return `${leftPart}${dots}${rightPart}`;
}
