import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useAccount, useSwitchChain } from 'wagmi';
import { useCurrentUser } from 'entities/user/model';
import { getTokenHumanReadableNameByChain } from '../../features/bill/lib';

const chains = [
  {
    id: 1,
    network: 'homestead',
    name: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: {
      alchemy: {
        http: ['https://eth-mainnet.g.alchemy.com/v2'],
        webSocket: ['wss://eth-mainnet.g.alchemy.com/v2'],
      },
      infura: {
        http: ['https://mainnet.infura.io/v3'],
        webSocket: ['wss://mainnet.infura.io/ws/v3'],
      },
      default: {
        http: [
          'https://eth-mainnet.g.alchemy.com/v2/LWZpZydwTJ3NMf62wDeB4YweT0yust1u',
        ],
      },
      public: {
        http: ['https://cloudflare-eth.com'],
      },
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://etherscan.io',
      },
      default: {
        name: 'Etherscan',
        url: 'https://etherscan.io',
      },
    },
    contracts: {
      ensRegistry: {
        address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
      },
      ensUniversalResolver: {
        address: '0xc0497E381f536Be9ce14B0dD3817cBcAe57d2F62',
        blockCreated: 16966585,
      },
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 14353601,
      },
    },
  },
  {
    id: 137,
    name: 'Polygon',
    network: 'matic',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: {
      alchemy: {
        http: ['https://polygon-mainnet.g.alchemy.com/v2'],
        webSocket: ['wss://polygon-mainnet.g.alchemy.com/v2'],
      },
      infura: {
        http: ['https://polygon-mainnet.infura.io/v3'],
        webSocket: ['wss://polygon-mainnet.infura.io/ws/v3'],
      },
      default: {
        http: [
          'https://polygon-mainnet.g.alchemy.com/v2/LWZpZydwTJ3NMf62wDeB4YweT0yust1u',
        ],
      },
      public: {
        http: ['https://polygon-rpc.com'],
      },
    },
    blockExplorers: {
      etherscan: {
        name: 'PolygonScan',
        url: 'https://polygonscan.com',
      },
      default: {
        name: 'PolygonScan',
        url: 'https://polygonscan.com',
      },
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 25770160,
      },
    },
  },
];

export const WarningsSection = ({
  connectedChain,
  payeeNetwork,
  payeeWalletAddress,
  payeeToken,
  payeeAmount,
  paymentProps,
  showPotentialDuplicate = true,
}) => {
  const { chain } = useAccount();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchChain();

  const { data: userData } = useCurrentUser();

  //Network Mismatch Check
  const networkAliases = {
    'op mainnet': 'optimism',
    optimism: 'optimism',
    'arbitrum one': 'arbitrum',
    'arbitrum mainnet': 'arbitrum',
  };

  // Normalize network names to a standard format
  const normalizeNetworkName = (name) =>
    networkAliases[name?.toLowerCase()] || name?.toLowerCase();

  const isNetworkMismatch =
    normalizeNetworkName(connectedChain?.name) !==
    normalizeNetworkName(payeeNetwork);

  // Not Enough Tokens Check
  const notEnoughTokens =
    parseFloat(payeeAmount) > parseFloat(paymentProps.balance);

  // Duplicate Payment Check
  const this_payment = {
    addressTo: payeeWalletAddress,
    amount: payeeAmount,
  };
  const last_payment = userData?.last_payment;
  const potentialDuplicate =
    showPotentialDuplicate &&
    this_payment.addressTo === last_payment.addressTo &&
    this_payment.amount === last_payment.amount;

  if (!isNetworkMismatch && !notEnoughTokens && !potentialDuplicate)
    return null;

  return (
    <div className="text-center ">
      <div
        as="h3"
        className="mb-5 text-base font-semibold leading-6 text-slate-300">
        Warnings
      </div>

      {/* Comment this code out for now */}

      {/* {isNotStableCoin && (
        <div className="grid grid-cols-2  items-center pb-2 text-left text-sm font-medium">
          <span className="text-yellow-300">
            <span className="font-medium text-yellow-300">
              <ExclamationTriangleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
            </span>
            Financial Promotion Laws
          </span>

          <span className={`col-span-2 text-xs font-normal text-slate-400 `}>
            You are sending a token that is not a stablecoin. Please ensure it
            doesn't fall under securities & financial promotion laws.
          </span>
        </div>
      )} */}

      {potentialDuplicate && (
        <div className="grid grid-cols-2  items-center pb-2 text-left text-sm font-medium">
          <span className="text-yellow-300">
            {' '}
            <span className="font-medium text-yellow-300">
              <ExclamationTriangleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
            </span>
            Possible Duplicate Payment
          </span>

          <span className={`col-span-2 text-xs font-normal text-slate-400 `}>
            You have recently attempted to make the same payment. Please ensure
            you do not unintentionally make a duplicate payment.
          </span>
        </div>
      )}

      {notEnoughTokens && (
        <div className="grid grid-cols-2  items-center pb-2 text-left text-sm font-medium">
          <span className="text-red-400">
            {' '}
            <span className="font-medium text-red-400">
              <ExclamationTriangleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
            </span>
            Not Enough Tokens
          </span>

          <span className={`col-span-2 text-xs font-normal text-slate-400 `}>
            You do not have enough tokens to pay this bill. Your balance is{' '}
            {paymentProps.balance}{' '}
            {getTokenHumanReadableNameByChain(payeeToken, payeeNetwork)}.
          </span>
        </div>
      )}

      {isNetworkMismatch && (
        <>
          <div className="grid grid-cols-2  items-center pt-2 text-left text-sm font-medium">
            <span className="text-red-400">
              {' '}
              <span className="font-medium text-red-400">
                <ExclamationTriangleIcon className="mb-0.5 mr-0.5 inline h-3 w-3" />
              </span>
              Network Mismatch
            </span>
            <span className={`col-span-2 text-xs font-normal text-slate-400 `}>
              This payment is on {payeeNetwork} network. You are connected to{' '}
              {connectedChain?.name}.
            </span>
          </div>
          <div className="flex flex-col items-start text-sm">
            <span className=" text-left text-slate-300">
              {' '}
              {chains
                .filter(
                  (x) => x.name.toLowerCase() === payeeNetwork.toLowerCase()
                ) // filter based on the condition
                .map((x) => (
                  <button
                    disabled={!switchNetwork || x.id === chain?.id}
                    key={x.id}
                    onClick={() => switchNetwork?.(x.id)}
                    className={` text-xs font-medium text-indigo-300 transition duration-150 ease-in-out hover:text-indigo-400 focus:underline focus:outline-none disabled:cursor-not-allowed disabled:text-slate-300 ${
                      x.id === chain?.id ? 'opacity-50' : ''
                    }`}>
                    Switch Network To {x.name}
                    {isLoading &&
                      pendingChainId === x.id &&
                      ' (Switching. Please confirm in wallet.)'}
                  </button>
                ))}
            </span>
          </div>
        </>
      )}

      {/* <div className="my-4 border-b border-slate-500"></div> */}
    </div>
  );
};
