import { useState } from 'react';
import { Spinner } from 'shared/ui/Spinner';
import { Button } from 'shared/ui/Button/Button';
import { useAragonAppDetails } from 'shared/hooks/useAragonSdk';
import { TextInput } from 'shared/ui/form/Input';
import { useAccount } from 'wagmi';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export const AragonAddressPicker = ({
  show,
  onAddressSelect,
  containerClassname = 'absolute left-0 top-0 z-30 flex flex-col h-full w-full bg-slate-800 p-4',
}) => {
  const { isConnected } = useAccount();
  const [address, setAddress] = useState('');
  const [wrongAddress, setWrongAddress] = useState(false);
  const { isLoading, isSuccess, getAddress } = useAragonAppDetails(address);

  if (!show) {
    return null;
  }

  const _handleAddressChange = (e) => {
    setAddress(e.target.value);
    setWrongAddress(false);
  };

  const _handleAddressConfirm = () => {
    const addr = getAddress();

    if (isSuccess) {
      if (addr) {
        onAddressSelect(addr);
      } else {
        setWrongAddress(true);
      }
    }
  };

  return (
    <div className={containerClassname}>
      <h3 className="mb-5 text-base font-semibold leading-6 text-slate-300">
        Input Aragon App Address
      </h3>

      <TextInput
        id="address"
        type="text"
        color={'gray'}
        size="sm"
        placeholder="Enter an AA address to create a payment proposal "
        value={address}
        onChange={_handleAddressChange}
        rightIcon={() =>
          isLoading ? <Spinner className="h-4 w-4 border-2" /> : null
        }
        helperText={
          wrongAddress ? (
            <span className="block text-left text-amber-300">
              The address you entered is not an Aragon Multisig App. Please copy
              Aragon App address which has multisig plugin.
            </span>
          ) : null
        }
      />
      <div className="h-[120px]" />
      <div className="flex-1" />

      {isConnected ? (
        <Button size="sm" color="indigo" onClick={_handleAddressConfirm}>
          Confirm
        </Button>
      ) : (
        <ConnectButton.Custom>
          {({ openConnectModal }) => (
            <Button onClick={openConnectModal} fullSized cl color="indigo">
              Connect Your Wallet
            </Button>
          )}
        </ConnectButton.Custom>
      )}
    </div>
  );
};
