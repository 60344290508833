import React from "react";
import Select from "react-select";
import { ErrorMessage } from "formik";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import countriesIso from "i18n-iso-countries";

countriesIso.registerLocale(require("i18n-iso-countries/langs/en.json"));

const countries = Object.entries(countriesIso.getNames("en")).map(
  ([code, name]) => ({
    label: name,
    value: code,
    flag: getUnicodeFlagIcon(code),
  })
);

const customStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#18222d",
    border: "2px solid #4E6180",
    borderRadius: "0.375rem",
    color: "white",
    padding: "",
    fontSize: "0.875rem",
    marginTop: "4px",
    width: "100%",
    boxShadow: state.isFocused ? "0 0 0 1px #6366f1" : "none",
    "&:hover": {
      borderColor: state.isFocused ? "#6366f1" : "#4E6180",
    },
    outline: "none",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#4E6180",
  }),
  input: (base) => ({
    ...base,
    color: "white",
    fontSize: "0.875rem",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#18222d",
    color: "white",
    fontSize: "0.875rem",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#6366f1" : "#18222d",
    color: state.isFocused ? "white" : "white",
    fontSize: "0.875rem",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#CBD5E1",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (base) => ({
    ...base,
    outline: "none",
    color: "white",
    fontSize: "0.875rem",
  }),
};

export const CountrySelect = ({ url, formValues, setFieldValue }) => {
  countries.sort((a, b) => a.label.localeCompare(b.label));

  const handleChange = async (selectedOption) => {
    const country = countries.find(
      (country) => country.label === selectedOption.value
    );

    setFieldValue("yourcountry", country ? country.value : "");
  };

  const selectedCountry = countries.find(
    (country) => country.value === formValues.yourcountry
  );

  const countryOptions = countries.map((country) => ({
    value: country.label,
    label: (
      <div>
        {country.flag + " "} {country.label}
      </div>
    ),
  }));

  return (
    <>
      <div className="col-span-6 sm:col-span-3">
        <label className="block text-sm text-[#CBD5E1]">
          {url?.isForEmployee ? "Country " : "Company Country "}
          {formValues.fiat_payment && "*"}
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <Select
            value={{
              value: selectedCountry ? selectedCountry.label : "",
              label: selectedCountry ? (
                <div>
                  {selectedCountry.flag + " "} {selectedCountry.label}
                </div>
              ) : (
                ""
              ),
            }}
            onChange={handleChange}
            options={countryOptions}
            styles={customStyles}
            isSearchable
            placeholder="Type your country name..."
          />
        </div>
        <ErrorMessage
          name="yourcountry"
          component="div"
          className="mt-2 text-xs italic text-red-500"
        />
      </div>
    </>
  );
};
