import React, { useEffect, useState } from "react";
import { useInvoice } from "entities/invoice/model";
import { useLocation } from "react-router-dom";
import Loading from "shared/ui/Loading";

function InvoicePDF() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const { data: invoice, isLoading, error } = useInvoice(id);

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (invoice?.download?.data) {
      const buffer = new Uint8Array(invoice.download.data.data);
      const blob = new Blob([buffer], { type: invoice.download.contentType });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    }
  }, [invoice]);

  if (isLoading) {
    return (
      <div className="grid h-screen place-items-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return null;
  }

  if (!pdfUrl) {
    return null;
  }

  return (
    <div>
      <iframe
        src={pdfUrl}
        width="100%"
        height="1000px"
        frameBorder="0"
        title="Invoice PDF"
        allowFullScreen></iframe>
    </div>
  );
}

export default InvoicePDF;
